import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  NavDropdown,
  Nav,
  Col,
  Dropdown,
  DropdownButton,
  Table,
} from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import DisplayPastGames from "./TeampastMatches";
import Team_image from "../../images/team_images.json";
import "../../css/ThemeCss.css";
import { Errorlog } from "../Utils";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
function Get_years(league_info_list) {
  const years = {};
  ////console.log(league_info_list);
  for (let lt_1 = 0; lt_1 < league_info_list?.length; lt_1++) {
    const seasons = league_info_list?.[lt_1]["season"];
    const id_season = getDictWithHighestYear(seasons)["id"];
    ////console.log(id_season);
    const year_list = [];
    for (let item = 0; item < seasons.length; item++) {
      const year_joined = String(seasons[item]["year"]);
      const year_split = year_joined.slice(0, 4) + "/" + year_joined.slice(4);
      year_list.push({ year: year_split, id: seasons[item]["id"] });
    }
    years[String([id_season])] = year_list;
  }
  return years;
}
function getDictWithHighestYear(dictList) {
  //this function is used to loop through the season list inside the leagues dict to //get the most recent season and its ID
  if (!dictList || dictList.length === 0) {
    return null;
  }

  let maxYear = 0;
  let maxYearDict = null;

  for (let dataDict of dictList) {
    let year = dataDict["year"] || 0;

    // Extract the year from the given integer range
    year = year % 10000;

    if (year > maxYear) {
      maxYear = year;
      maxYearDict = dataDict;
    }
  }

  return maxYearDict;
}
const DisplayData = ({ data, compID, o_team }) => {
  const navigation = useNavigate();
  const handleteamclick = ({ val, team_, league }) => {
    const team = val;
    navigation("/team/" + team_, { state: { team, league } });
  };

  let ownTeam = "decoy";
  if (o_team === data["id"]) {
    ownTeam = "ownteam";
  }

  return (
    <tr className={`${ownTeam}`}>
      <td>{data["position"]} </td>
      <td
        className="awayTable  link"
        onClick={() =>
          handleteamclick({
            val: data["id"],
            team_: data["cleanName"],
            league: compID,
          })
        }
      >
        <div className=" awayTable " style={{ marginLeft: "5px" }}>
          <img
            src={Team_image?.[String(data["id"])]?.["image"]}
            width="20"
            height="15"
            alt={data["cleanName"]}
          />{" "}
          {data["cleanName"]}{" "}
        </div>
      </td>

      <td></td>

      <td>{data["matchesPlayed"]}</td>
      <td>{data?.["seasonWins_overall"] || data?.["seasonWins"] || "0"}</td>
      <td>{data?.["seasonDraws_overall"] || data?.["seasonDraws"] || "0"}</td>
      <td>
        {data?.["seasonLosses_overall"] ||
          data?.["seasonLosses_away"] ||
          data?.["seasonLosses_home"] ||
          "0"}
      </td>
      <td>{data["seasonGoals"]}</td>
      <td>{data["seasonConceded"]}</td>
      <td>{data["points"]}</td>
    </tr>
  );
};

function TableMain({
  key,
  leagues_y,
  league,
  leagueYears,
  HandleYearClick,
  HandleleagueClick,
  setJointLeague,
  league_list_,
  Data,
  disData,
  HandleDisClick,
  asking_team,
  raw_league,
  country,
  showYear,
}) {
  const { globalUser } = useContext(GlobalUserContext);
  const navigation = useNavigate();
  ////console.log(leagueYears, league, leagueYears?.[league]);
  const season = leagueYears?.[league]?.["year"];
  const id_ = leagueYears?.[league]?.["id"];
  //setuseleagueName(leagueYears?.[league]?.["year"]);
  ////console.log(leagueYears);
  let leagueName_ = "";
  let leagueimage = "";

  for (let league of raw_league || []) {
    if (!league || !league?.season) continue;

    for (let season of league.season) {
      if (season && season?.id === id_) {
        leagueName_ = league?.league_name;
        leagueimage = league?.image;

        // Once we found our match, there is no point in continuing
        break;
      }
    }

    // If leagueName_ or leagueimage are defined, then we can stop iterations.
    if (leagueName_ && leagueimage) break;
  }

  let leagueToShow = "";
  const handleleagueclick2 = (league_id, league_name, country_name) => {
    const trns_data = {
      l_id: league_id,
      l_n: league_name,
      ctry: country_name,
    };

    navigation("/league/" + league_name, { state: trns_data });
  };
  for (let index = 0; index < Data?.length; index++) {
    const lg = Data?.[index];
    if (lg["_id"] === id_) {
      leagueToShow = lg;
      break;
    }
  }
  ////console.log(leagueToShow);
  const AllGames = leagueToShow?.["all_matches_table_overall"];
  const HomeGames = leagueToShow?.["all_matches_table_home"];
  const AwayGames = leagueToShow?.["all_matches_table_away"];
  const games = {
    AllGames: AllGames,
    HomeGames: HomeGames,
    AwayGames: AwayGames,
  };
  ////console.log(leagueYears)
  /* if (!leagueYears) {
    return null;
  } */
  return (
    <div className="light " style={{ width: "100%" }}>
      <Modal.Dialog className="modalbody" style={{ width: "100%" }}>
        <Modal.Body>
          <div className="margin10down marginleft-10">
            <Row className="align-items-center">
              <Col lg={2} md={3} sm={4} xs={4} className="mb-2">
                <img
                  src={leagueimage}
                  className="link"
                  width="40"
                  height="30"
                  alt={leagueName_}
                  onClick={() =>
                    handleleagueclick2(league, leagueName_, country)
                  }
                />
              </Col>
              <Col md={9} lg={10} sm={8} xs={8} className="mb-2 padyear">
                <DropdownButton
                  title={leagueName_}
                  id="dropdown-basic-button"
                  size="sm"
                  className="small-button2"
                >
                  {Object.keys(league_list_).map((item, index) => (
                    <Dropdown.Item onClick={() => HandleleagueClick(item)}>
                      {league_list_?.[item]?.league_name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
          </div>
          <div className="marginleft-10">
            {showYear ? (
              <Row className="align-items-center">
                <Col lg={2} md={3} sm={4} xs={4} className="mb-2">
                  League Season : {" "}
                </Col>
                <Col
                  lg={10}
                  md={9}
                  sm={8}
                  xs={8}
                  className="mb-2 padyear left_allign"
                >
                  <DropdownButton
                    title={leagueYears?.[league]?.["year"]}
                    id="dropdown-basic-button"
                    size="sm"
                    className="small-button"
                  >
                    {leagues_y?.season_list
                      .sort((a, b) => {
                        return (
                          parseInt(b.year.split("/")[0]) -
                          parseInt(a.year.split("/")[0])
                        );
                      })
                      .map((item, index) => (
                        <Dropdown.Item
                          onClick={() => {
                            HandleYearClick(item, league);
                            //setJointLeague(item, league);
                            
                          }}
                        >
                          {item?.year}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                </Col>
              </Row>
            ) : null}
          </div>
          <Nav
            justify
            variant="underline"
            defaultActiveKey="##"
            className="me-auto padh6"
          >
            <Nav.Item>
              <Nav.Link onClick={() => HandleDisClick("AllGames", id_)}>
                All
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => HandleDisClick("HomeGames", id_)}>
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => HandleDisClick("AwayGames", id_)}>
                Away
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Modal.Body>
      </Modal.Dialog>
      <div className="scroll-table">
        <Table
          className="mainFont standard_table "
          striped
          hover
          style={{ width: "100%" }}
          variant={"light"}
        >
          <thead>
            <tr>
              <th>P</th>
              <th>Team</th>
              <th></th>
              <th>MP</th>
              <th>W</th>
              <th>D</th>
              <th>L</th>
              <th>GF</th>
              <th>GA</th>
              <th>Pts</th>
            </tr>
          </thead>
          <tbody>
            {games[disData[id_]]?.map((item, index) => (
              <DisplayData data={item} compID={id_} o_team={asking_team} />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
function LeagueTables({
  askleague1,
  league_info_list,
  asking_team,
  country,
  showYear,
  leagueYears1,
  disData1,
  
}) {

  
  const { globalUser } = useContext(GlobalUserContext);

  const [key, setKey] = useState(0);
  const [disLeague, setDisLeague] = useState(askleague1);
  const [askleague, setaskleague] = useState(askleague1);
  const [useleagueName,setuseleagueName] = useState(askleague1);
  const [disData, setdisData] = useState(disData1);
  const [leagueYears, setleagueYears] = useState(leagueYears1);
  const [raw_league, setraw_league] = useState();
  const [asking_team2, setasking_team] = useState(asking_team);
  const [League_list, setLeague_list] = useState();
  const [mid, setIsMidScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [small, setIsSmallScreen] = useState(false);
  ////console.log(leagueYears1)
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() =>{
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    if (
      asking_team2 !== null
    ) {
      const get_tmdata = async() =>{
        let options = {
          body: JSON.stringify( {
            team: asking_team2,
            league:disLeague,
          } ),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },
  
          seconds: 2 * 60 * 60,
        };
        let url = "/team_table";
        let indexdb = "team_table";
        let SaveKey = "" + asking_team2 + disLeague;
        try{
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          /* const res = await axios.post(
            `${API_URL}/team_table`,
            JSON.stringify( {
              team: asking_team2,
              league:disLeague,
            } ),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = JSON.parse(res?.data)
          ////console.log(fix_data)
          if(res?.status==="success"){
            setLeague_list(fix_data?.league_table)
            setraw_league(fix_data?.raw_league)
            setLoading(false)
            
            
          }else{
            Errorlog("loaderror","error loading statistics","from league stats")
          }
        }catch (error){
          Errorlog(error,"error loading statistics","from league stats")
          ////console.log(error)
        }
        
      }
      get_tmdata()
  
    }
  },[disLeague, globalUser?.user?.dataMode])

  if(!League_list || loading){
    return(
      <Spinner />
    )
  }
  

  /* useEffect(() => {
    setKey((prevKey) => prevKey + 1); // This will cause a re-render
  }, [); */

  ////console.log(globalUser.user.states.dataMode);

  const HandleYearClick = (item, league) => {
    ////console.log("clicked me before", item);
    const prev_val = { ...leagueYears };
    const prev_data = { ...disData };
    
    if (item) {
      prev_data[String(item.id)] = "AllGames";
      prev_val[league] = item;
      ////console.log("was here")
    }
    
    ////console.log(prev_val,league,item,item?.id);
    
    setdisData(prev_data);
    setaskleague(parseInt(league));
    //setuseleagueName(prev_data?.[league]?.["year"]);
    setDisLeague(parseInt(item?.["id"]))
    ////console.log(disData);
    setleagueYears(prev_val);

    ////console.log("clicked me after", leagueYears);
  };
  const HandleDisClick = (item, league) => {
    const prev_val = { ...disData };
    //const prev_data = { ...leagueYears };
    prev_val[league] = item;
    setdisData(prev_val);
    
  };

  const HandleleagueClick = (league) => {

    const prev_val = { ...leagueYears };
    const prev_data = { ...disData };
    
    prev_data[prev_val[league]?.item?.["id"]] = "AllGames";
    setaskleague(parseInt(league));
    setuseleagueName(league);
    setdisData(prev_data);
    setleagueYears(prev_val)
    setDisLeague(parseInt(league))
    //setJointLeague(prev_val[league], league)


    
    
  };
  /* if (leagueYears === {}) {
    return null;
  } */

  return (
    <div>
     
        
          <TableMain
            key={key}
            leagues_y={league_info_list[useleagueName]}
            league={useleagueName}
            HandleYearClick={HandleYearClick}
            leagueYears={leagueYears}
            league_list_={league_info_list}
            Data={League_list}
            //setJointLeague={setJointLeague}
            disData={disData}
            setDisLeague={setDisLeague}
            HandleDisClick={HandleDisClick}
            HandleleagueClick={HandleleagueClick}
            //setuseleagueName={setuseleagueName}
            asking_team={asking_team}
            raw_league={raw_league}
            country={country}
            showYear={showYear}
          />
    
     
      
    </div>
  );
}
export default LeagueTables;
