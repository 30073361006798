import React, { useRef, useState } from "react";
import "../css/ThemeCss.css";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBAlert,
} from "mdb-react-ui-kit";
//import { MDBAlert } from "mdb-ui-kit";

const checkScreenSize = () => {
  setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
  setIsSmallScreen(window.innerWidth < 770);
};
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

function CopyButton({
  type,
  data,
  setisclickedPreShare,
  isclickedShare,
  setisclickedShare,
  header,
}) {
  const [basicSuccess, setBasicSuccess] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const textRef = useRef(null);

  const handleClick = () => {
    if (textRef.current) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(`${WEB}/ShowTickets?type=${type}&ticketID=${data}`)
          .then(() => alert("Copied to clipboard"))
          .catch((err) => console.error("Could not copy text: ", err));
          setisclickedShare(() => false)
      } else {
        ////console.log("Clipboard API is not available");
      }
    }
  };
  const data_m = {
    type: type,
    ticketID: data,
  };
  ////console.log(type, data, isclickedShare);

  return (
    <>
      <MDBModal
        animationDirection="right"
        open={isclickedShare}
        tabIndex="-1"
        onClose={() => setisclickedShare(false)}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>
                {!data
                  ? "Error in sharing"
                  : header
                  ? header
                  : "Share your ticket"}
              </MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => setisclickedShare(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {!data ? (
                <div>
                  there must have been some internal server error while trying
                  to create your ticket. please check your games and try agin.
                </div>
              ) : (
                <div>
                  <div
                    className=" center_div center_sides"
                    style={{ position: "relative" }}
                  >
                    <div className="horizontal_scrol">{`${WEB}/ShowTickets?type=${type}&ticketID=${data}`}</div>
                  </div>
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => setisclickedShare(() => false)}
              >
                Close
              </MDBBtn>
              <MDBBtn
                outline
                ref={!data ? null : textRef}
                color="success"
                onClick={() => {
                  handleClick();
                  if (typeof setisclickedPreShare === "function") {
                    setisclickedPreShare(true);
                  }
                  if (!data) {
                    setBasicSuccess?.(() => true);
                  }
                }}
              >
                {!data ? "Try again" : "Copy Link"}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
export function ShareProfileCopy({
 
  data,
  isclickedShare,
  setisclickedShare,
  header,
}) {
  const [basicSuccess, setBasicSuccess] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const textRef = useRef(null);
  const [placementExample, setPlacementExample] = useState(false);

  const handleClick = () => {
    if (textRef.current) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(`${WEB}/publicProfile?publicProfile=${data}`)
          .then(() => alert("Copied to clipboard"))
          .catch((err) => console.error("Could not copy text: ", err));
          setisclickedShare(() => false)
      } else {
        ////console.log("Clipboard API is not available");
      }
    }
  };

  ////console.log(type, data, isclickedShare);

  return (
    <>
    {/* <MDBAlert open={placementExample} color='primary' autohide position={"top-center"} delay={2000} appendToBody>
        Show me wherever you want!
      </MDBAlert> */}
      <MDBModal
        animationDirection="right"
        open={isclickedShare}
        tabIndex="-1"
        onClose={() => setisclickedShare(false)}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>
                {!data
                  ? "Error in sharing"
                  : header
                  ? header
                  : "Copy link and post to any social media network to direct friends and followers to your Profile!"}
              </MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => setisclickedShare(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {!data ? (
                <div>
                  There must have been some internal server error while trying
                  to create share this profile. Please verify if this profile is active and try again.
                </div>
              ) : (
                <div>
                  <div
                    className=" center_div center_sides"
                    style={{ position: "relative" }}
                  >
                    <div className="horizontal_scrol">{`${WEB}/publicProfile?publicProfile=${data}`}</div>
                  </div>
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => setisclickedShare(() => false)}
              >
                Close
              </MDBBtn>
              <MDBBtn
                outline
                ref={!data ? null : textRef}
                color="success"
                onClick={() => {
                  handleClick();
                  
                }}
              >
                {!data ? "Try again" : "Copy Link"}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

function CopyButt({ type, data, setisclickedPreShare }) {
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const textRef = useRef(null);

  const handleClick = () => {
    if (textRef.current) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textRef.current.textContent)
          .then(() => alert("Copied to clipboard"))
          .catch((err) => console.error("Could not copy text: ", err));
      } else {
        ////console.log("Clipboard API is not available");
      }
    }
  };
  const data_m = {
    type: type,
    ticketID: data,
  };
  return (
    <div>
      <div
        className=" center_div center_sides"
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            width: "300px",
            marginTop: "25px",
            marginLeft: isSmallScreen ? "-125px" : "-40px",
            transform: "translate(-50%, 0)",
            zIndex: 999,
            backgroundColor: "white",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
          ref={textRef}
        >
          <div className="horizontal_scrol">{`${WEB}/ShowTickets?type=${type}&ticketID=${data}`}</div>
          <div className="center_div center_sides">
            <div
              className="inactiveButton link forceblack"
              onClick={() => setisclickedPreShare(() => true)}
            >
              &nbsp;Close&nbsp;
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div
              className="smartTableButton link forceblack"
              onClick={() => {
                handleClick();
                setisclickedPreShare(() => true);
              }}
            >
              &nbsp; Copy Link &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CopyButton;
