import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import ComparismDisplay from "../ChartComponents/ComparismChart";
import DisplayValue from "../ChartComponents/ValueDisplay";
import Team_image from "../../images/team_images.json";
import Spinner from "../Spinner";
import { Errorlog } from "../Utils";
import "../../css/Matchstats.css";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;

function getColorCode(value) {
  const valueNormalized = value / 100; // Normalize value to range 0-1
  // Hue range for green to red (reversed)
  const hue = (valueNormalized * 120) / 360.0;
  const lightness = 0.5; // Fixed lightness for all colors
  const saturation = 0.8; // Fixed saturation for all colors

  const rgbColor = hlsToRgb(hue, lightness, saturation).map((x) =>
    Math.round(255 * x)
  );

  function rgbToHex(rgb) {
    return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }

  return rgbToHex(rgbColor);
}
function hlsToRgb(h, l, s) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r, g, b];
}
const Strip = (stri) => {
  return stri?.slice(0, 11);
};
function GetPercentatge(value, strings) {
  let tot = "";
  let values = "";
  if (typeof strings === "string") {
    tot = strings?.split("");
    values = value / tot?.length;
  } else {
    values = value / strings;
  }

  const semval = Math.round(values * 100);
  let out = `${semval} %`;
  if (out === "NaN %") {
    out = "- %";
  }
  return out;
}
function Getcolor(value, strings) {
  let tot = "";
  let values = "";
  ////console.log(value, strings);
  if (typeof strings === "string") {
    tot = strings.split("");
    values = value / tot?.length;
  } else {
    values = value / strings;
  }
  const semval = Math.round(values * 100);

  const out = getColorCode(semval);
  if (semval > 50) {
    return out;
  } else {
    const oppval = Math.round(100 - semval);
    const opp = getColorCode(oppval);
    return [out, opp];
  }
}
function averageOccurrenceType(occurrences) {
  /**
   * Calculate the average occurrence type from a dictionary where keys represent occurrences
   * and values represent the number of occurrences.
   *
   * @param {Object} occurrences - Dictionary of occurrences and their counts.
   * @returns {number} The average occurrence type.
   */
  let totalSum = 0
  let totalOccurrences = 0.1
  if (occurrences){
    totalSum = Object.entries(occurrences && occurrences)?.reduce(
      (sum, [key, value]) => sum + parseFloat(key) * value,
      0
    );
  
    totalOccurrences = Object.values(occurrences).reduce(
      (total, count) => total + count,
      0
    );
  
    if (totalOccurrences === 0) {
      return 0; // Avoid division by zero if the dictionary is empty
    }
  }
  

  return Math.round(totalSum / totalOccurrences);
}

export default function MatchesStatistics({
  leagueId,
  match,
  Tab1,
  Tab2,
  half,
  teamA,
  teamB,
  
}) {

  const [useleague, setleague] = useState(leagueId);
  const [usematch, setmatch] = useState(match);
  const [loading, setLoading] = useState(true);
  const [Data_, setdata] = useState();
  const [numgames1,setnumgames1] = useState()
  ////console.log(leagueId, match)

  useEffect(() =>{
      const get_mdata = async() =>{
        let options = {
          body: JSON.stringify( {
            match: usematch,
            league:useleague,
          } ),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },
  
          seconds: 2 * 60 * 60,
        };
        let url = "/match_stat";
        let indexdb = "match_stat";
        let SaveKey = "" + usematch + useleague;
        try{
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          /* const res = await axios.post(

            `${API_URL}/match_stat`,
            JSON.stringify( {
              match: usematch,
              league:useleague,
            } ),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = JSON.parse(res?.data);
          ////console.log(fix_data)
          if(res?.status==="success"){
            setdata(fix_data?.data?.STATISTICS)
            
            setLoading(false)
            
          }else{
            Errorlog("loaderror","error loading statistics","from league stats")
          }
        }catch (error){
          Errorlog(error,"error loading statistics","from league stats")
          ////console.log(error)
        }
        
      }
      get_mdata()
  
    },[])
    ////console.log(Data_)
  if(!Data_ || loading){
    return(
      <Spinner />
    )
  }
  
  ////console.log(Tab1,Tab2, half, teamA, teamB);
  let halfs = "ft";
  let gh1 = "";
  let gh2 = "";
  let ch1 = "";
  let ch2 = "";
  let coh1 = "";
  let coh2 = "";
  if (half === "Full Time") {
    halfs = "ft";
    gh1 = "1.5";
    gh2 = "2.5";
    ch1 = "2.5";
    ch2 = "5.5";
    coh1 = "4.5";
    coh2 = "9.5";
  } else if (half === "First Half") {
    halfs = "ht";
    gh1 = "0.5";
    gh2 = "1.5";
    ch1 = "1.5";
    ch2 = "2.5";
    coh1 = "3.5";
    coh2 = "5.5";
  } else if (half === "Second Half") {
    halfs = "2h";
    gh1 = "0.5";
    gh2 = "1.5";
    ch1 = "1.5";
    ch2 = "2.5";
    coh1 = "3.5";
    coh2 = "5.5";
  }
  const match_name = `${Math.min(teamA, teamB)}_${Math.max(teamA, teamB)}`;
  let tab = "";
  const Data = Data_[Tab1]
  ////console.log(Data_?.[Tab1])
  const Indata = Data?.[Tab2]?.[0] ?? Data?.[Tab2];
  ////console.log(Data?.[Tab2])
  const numgames = Indata?.["new_" + match_name]?.cards?.foul_average?.count;
  const WDL = {
    homeID: teamA,
    awayID: teamB,
    homename: Indata?.home_name,
    awayname: Indata?.away_name,
    home_val:
      Indata?.["new_" + teamA]?.wdl?.team_wdl_trail?.["count_win_" + halfs],
    away_val:
      Indata?.["new_" + teamB]?.wdl?.team_wdl_trail?.["count_win_" + halfs],
    text: "Num Games",
    number: Data_?.[Tab1]?.["lenght"]
  };

  const Goals = {
    homeID: teamA,
    awayID: teamB,
    homename: Indata?.home_name,
    awayname: Indata?.away_name,
    home_val:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + teamA]?.goals?.["over_team_" + halfs]
      ),
    away_val:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + teamB]?.goals?.["over_team_" + halfs]
      ),
    text: "Av Goals " + halfs,
    number:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + match_name]?.goals?.["over_" + halfs]
      ),
  };
  const Corners = {
    homeID: teamA,
    awayID: teamB,
    homename: Indata?.home_name,
    awayname: Indata?.away_name,
    home_val:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + teamA]?.corners?.["over_team_" + halfs]
      ),
    away_val:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + teamB]?.corners?.["over_team_" + halfs]
      ),
    text: "Av Corners " + halfs,
    number:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + match_name]?.corners?.["over_" + halfs]
      ),
  };
  const Cards = {
    homeID: teamA,
    awayID: teamB,
    homename: Indata?.home_name,
    awayname: Indata?.away_name,
    home_val:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + teamA]?.cards?.["over_team_" + halfs]
      ),
    away_val:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + teamB]?.cards?.["over_team_" + halfs]
      ),
    text: "Av Cards " + halfs,
    number:
      Indata &&
      averageOccurrenceType(
        Indata?.["new_" + match_name]?.cards?.["over_" + halfs]
      ),
  };
  ////console.log(Data&&Data)
  return (
    <div>
      <Modal.Dialog>
        <Modal.Body>
          <div className="toplabel"> Win Draw Lose Statistics </div>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div>
                <ComparismDisplay
                  datain={WDL}
                  text={"Winner Statistics " + half}
                />
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + match_name]?.wdl?.["any" + halfs]?.[
                            "draw"
                          ],
                          Indata?.["new_" + match_name]?.wdl?.match_count
                        )}
                        dis_text={"Draw percentage " + halfs}
                        colorcode={Getcolor(
                          Indata?.["new_" + match_name]?.wdl?.["any" + halfs]?.[
                            "draw"
                          ],
                          Indata?.["new_" + match_name]?.wdl?.match_count
                        )}
                      />
                    </div>
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamB]?.wdl?.team_draw_either_half
                            ?.deh_yes,
                            Indata?.["new_" + teamB]?.wdl?.match_count
                        )}
                        dis_text={"Draw Either Half"}
                        colorcode={Getcolor(
                          Indata?.["new_" + teamB]?.wdl?.team_draw_either_half
                            ?.deh_yes,
                            Indata?.["new_" + teamB]?.wdl?.match_count
                        )}
                      />
                    </div>
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamA]?.wdl?.team_win_either_half
                            ?.weh_yes,
                            Indata?.["new_" + teamA]?.wdl?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamA)]?.name) + " WEH"
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamA]?.wdl?.team_win_either_half
                            ?.weh_yes,
                            Indata?.["new_" + teamA]?.wdl?.match_count
                        )}
                      />
                    </div>
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamB]?.wdl?.team_win_either_half
                            ?.weh_yes,
                            Indata?.["new_" + teamB]?.wdl?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamB)]?.name) + " WEH"
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamB]?.wdl?.team_win_either_half
                            ?.weh_yes,
                            Indata?.["new_" + teamB]?.wdl?.match_count
                        )}
                      />
                    </div>
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Body>
          <div className="toplabel"> Goals Statistics </div>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <ComparismDisplay
                  datain={Goals}
                  text={"Average Goals " + half}
                />
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamA]?.goals?.[
                            "over_team_" + halfs
                          ]?.[gh1],
                          Indata?.["new_" + teamA]?.goals?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamA)]?.name) +
                          " OV " +
                          gh1 +
                          " G"
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamA]?.goals?.[
                            "over_team_" + halfs
                          ]?.[gh1],
                          Indata?.["new_" + teamA]?.goals?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamB]?.goals?.[
                            "over_team_" + halfs
                          ]?.[gh1],
                          Indata?.["new_" + teamB]?.goals?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamB)]?.name) +
                          " OV " +
                          gh1 +
                          " G"
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamB]?.goals?.[
                            "over_team_" + halfs
                          ]?.[gh1],
                          Indata?.["new_" + teamB]?.goals?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + match_name]?.goals?.[
                            "over_" + halfs
                          ]?.[gh2],
                          Indata?.["new_" + match_name]?.goals?.match_count
                        )}
                        dis_text={"Match Goals OV " + gh2}
                        colorcode={Getcolor(
                          Indata?.["new_" + match_name]?.goals?.[
                            "over_" + halfs
                          ]?.[gh2],
                          Indata?.["new_" + match_name]?.goals?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamA]?.wdl?.team_score?.[
                            "count_" + halfs
                          ],
                          Indata?.["new_" + teamA]?.wdl?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamA)]?.name) + " Score"
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamA]?.wdl?.team_score?.[
                            "count_" + halfs
                          ],
                          Indata?.["new_" + teamA]?.wdl?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      {" "}
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + match_name]?.wdl?.bts?.bts_yes,
                          Indata?.["new_" + match_name]?.wdl?.match_count
                        )}
                        dis_text={"Both Teams Score"}
                        colorcode={Getcolor(
                          Indata?.["new_" + match_name]?.wdl?.bts?.bts_yes,
                          Indata?.["new_" + match_name]?.wdl?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamB]?.wdl?.team_score?.[
                            "count_" + halfs
                          ],
                          Indata?.["new_" + teamB]?.wdl?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamB)]?.name) + " Score"
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamB]?.wdl?.team_score?.[
                            "count_" + halfs
                          ],
                          Indata?.["new_" + teamB]?.wdl?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Body>
          <div className="toplabel"> Cards Statistics </div>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <ComparismDisplay
                  datain={Cards}
                  text={"Average Cards " + half}
                />
              </div>
            </Col>

            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamA]?.cards?.[
                            "over_team_" + halfs
                          ]?.[ch1],
                          Indata?.["new_" + teamA]?.cards?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamA)]?.name) +
                          " OV " +
                          ch1
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamA]?.cards?.[
                            "over_team_" + halfs
                          ]?.[ch1],
                          Indata?.["new_" + teamA]?.cards?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>

            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamB]?.cards?.[
                            "over_team_" + halfs
                          ]?.[ch1],
                          Indata?.["new_" + teamB]?.cards?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamB)]?.name) +
                          " OV " +
                          ch1
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamB]?.cards?.[
                            "over_team_" + halfs
                          ]?.[ch1],
                          Indata?.["new_" + teamB]?.cards?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + match_name]?.cards?.[
                            "over_" + halfs
                          ]?.[ch2],
                          Indata?.["new_" + match_name]?.cards?.match_count
                        )}
                        dis_text={"Match Cards OV " + ch2}
                        colorcode={Getcolor(
                          Indata?.["new_" + match_name]?.cards?.[
                            "over_" + halfs
                          ]?.[ch2],
                          Indata?.["new_" + match_name]?.cards?.match_count
                        )}
                      />{" "}
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Body>
          <div className="toplabel"> Corners Statistics </div>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <ComparismDisplay
                  datain={Corners}
                  text={"Average Corners " + half}
                />
              </div>
            </Col>

            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamA]?.corners?.[
                            "over_team_" + halfs
                          ]?.[coh1],
                          Indata?.["new_" + teamA]?.corners?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamA)]?.name) +
                          " OV " +
                          coh1
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamA]?.corners?.[
                            "over_team_" + halfs
                          ]?.[coh1],
                          Indata?.["new_" + teamA]?.corners?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + teamB]?.corners?.[
                            "over_team_" + halfs
                          ]?.[coh1],
                          Indata?.["new_" + teamB]?.corners?.match_count
                        )}
                        dis_text={
                          Strip(Team_image?.[String(teamB)]?.name) +
                          " OV " +
                          coh1
                        }
                        colorcode={Getcolor(
                          Indata?.["new_" + teamB]?.corners?.[
                            "over_team_" + halfs
                          ]?.[coh1],
                          Indata?.["new_" + teamB]?.corners?.match_count
                        )}
                      />
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4}>
              <div>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div>
                      <DisplayValue
                        dis_value={GetPercentatge(
                          Indata?.["new_" + match_name]?.corners?.[
                            "over_" + halfs
                          ]?.[coh2],
                          Indata?.["new_" + match_name]?.corners?.match_count
                        )}
                        dis_text={"Match Corners OV " + coh2}
                        colorcode={Getcolor(
                          Indata?.["new_" + match_name]?.corners?.[
                            "over_" + halfs
                          ]?.[coh2],
                          Indata?.["new_" + match_name]?.corners?.match_count
                        )}
                      />{" "}
                    </div>{" "}
                  </Modal.Body>
                </Modal.Dialog>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}
