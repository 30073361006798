import React from "react";
import axios from "axios";
export async function compressImage(sourceImgElem, quality, outputFormat){
    var canvas = document.createElement('canvas');
    var width = await sourceImgElem.naturalWidth;
    var height = await sourceImgElem.naturalHeight;

    // set the canvas dimensions same as image
    canvas.width = width;
    canvas.height = height;

    // draw the image onto canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(sourceImgElem, 0, 0, width, height);

    return canvas.toDataURL(outputFormat || "image/jpeg", quality || 0.5);
}

// usage example:
/* var imgElem = document.getElementById('my-image'); // replace 'my-image' with your image element's id
var compressedDataUrl = compressImage(imgElem, 0.5, "image/jpeg");

await axios.post(`${API_URL}/saveUserImage`, {
    email: globalUser?.user?.email,
    image: compressedDataUrl,
    type_: "profile"
}); */