import React, { useContext } from "react";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { useState, useEffect } from "react";
import { MDBInput, MDBSelect } from "mdb-react-ui-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import { CallError } from "./Utils";
import Team_images from "../images/team_images.json";
import Markets from "./ChartComponents/OddsMarket";

export default function CollectOptions(props) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const dataDefault = {
    options: {
      half: "",
      type: "",
      btype: "",
      optns: "",
      custOpt: "",
      optns_list: [],
    },
  };
  const [matchoptions, SetMathOptions] = useState(dataDefault);
  const [useActiveTicket, setUseActiveTicket] = useState();
  const [isCustom, setIsCustom] = useState(false);
  const [matchOptn, SetMathOption] = useState({})
  
  let homeTeam = props?.data && props?.data?.["home_data"];
  let awayTeam = props?.data && props?.data?.["away_data"];
  let data = {}
  if (!homeTeam || !awayTeam) {
    homeTeam = {
      cleanName: props?.data?.["home_name"],
      id: props?.data?.["homeID"],
      image: Team_images?.[String(props?.data?.["homeID"])]?.image,
    };
    awayTeam = {
      cleanName: props?.data?.["away_name"],
      id: props?.data?.["awayID"],
      image: Team_images?.[String(props?.data?.["awayID"])]?.image,
    };

    data = {
      home_data: homeTeam,
      away_data: awayTeam,
      match: props?.data,
      match_id: props?.data?.id,
    };
  }else{
    data = props?.data
  }
  ////console.log()
  useEffect(() => {
    const prevglobal = { ...globalUser };
    props.setisPopOpen("");
    let statuses = prevglobal?.user?.Tickets?.state;
    let activeTicket = "";
    if (statuses && typeof statuses === "object") {
      for (
        let index = 0;
        index < Object.keys(statuses && statuses)?.length;
        index++
      ) {
        const element = Object.keys(statuses && statuses)[index];
        if (statuses?.[element]) {
          activeTicket = element;
          setUseActiveTicket(() => element);
          break;
        }
      }
    }
    
    if(activeTicket ==""){
      props?.setIsTicketError(true)
      ////console.log(activeTicket)
    }
    try{
      const tciketsMain = [...globalUser?.user?.Tickets?.[activeTicket]?.matches];
      ////console.log(tciketsMain)
      for (let index = 0; index < tciketsMain?.length; index++) {
        const element = tciketsMain?.[index];
        if (element?.match_id === data?.match_id) {
          SetMathOptions({ options: element?.options });
          break;
        } else {
          SetMathOptions(dataDefault);
        }
      }
      
    }catch{
      props?.setIsTicketError(true)
    }
    
  }, [props?.index]);
  


  

  ////console.log(props.data);
  const handleTicketAdd = (optns) => {
    const currTime = Math.floor(new Date().getTime() / 1000);
    
    if (data?.match?.date_unix < currTime) {
      props?.setIsStarted(() => true);
    } else {
      props?.setIsStarted(() => false);
      
      const prevglobal = { ...globalUser };
      let statuses = prevglobal?.user?.Tickets?.state;
      let activeTicket = "";
      if (statuses && typeof statuses === "object") {
        for (
          let index = 0;
          index < Object.keys(statuses && statuses)?.length;
          index++
        ) {
          const element = Object.keys(statuses && statuses)[index];
          if (statuses?.[element]) {
            activeTicket = element;
          }
        }
      }
      ////console.log(useActiveTicket);
      const new_match = data;
      let isexist = -1;
      const prevMatch = prevglobal?.["user"]?.["Tickets"]?.[activeTicket]?.["matches"];
      if(!prevMatch){
        props?.setIsTicketError(true)
      }else{
        for (let index = 0; index < prevMatch?.length; index++) {
          const element = prevMatch?.[index];
          if (element?.match_id == new_match?.match_id) {
            isexist = index;
            break;
          }
        }
        ////console.log(isexist);
        if (isexist !== -1) {
          prevMatch[isexist]["options"] = matchoptions?.options;
          prevglobal["user"]["Tickets"][activeTicket]["matches"] = prevMatch;
          updateGlobalUser(() => prevglobal);
          let ckUser = UpdateLocal({
            action: "save",
            data: prevglobal,
          });
        } else {
          new_match["options"] = optns ?? {}
          prevglobal["user"]["Tickets"][activeTicket]["matches"].push(new_match);
          updateGlobalUser(() => prevglobal);
          let ckUser = UpdateLocal({
            action: "save",
            data: prevglobal,
          });
        }
      }
      
    }
  };

  ////console.log(props?.data?.id);
  return (
    <div className="center_div_distributed usemaxSpace center_straight ">
    
      <div className="usemaxSpace margin-10down ">
      <Markets matchID={props?.data?.match_id} data={data} setIsStarted={props?.setIsStarted} setIsTicketError={props?.setIsTicketError}/>
      </div>
      
      <div className="center_div_distributed center_sides usemaxSpace">
        
        <MDBBtn
          color="warning"
          size="sm"
          onClick={() => props.setisPopOpen(false)}
        >
          Cancel
        </MDBBtn>
      </div>
    </div>
  );
}
