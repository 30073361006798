import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";

import Team_image from "../../images/team_images.json";
import "../../css/LeaguesTable.css";
import { Errorlog } from "../Utils";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
const DisplayData = ({ data, compID, o_team1,o_team2 }) => {
  const navigation = useNavigate();
  const handleteamclick = ({ val, team_, league }) => {
    const team = val;
    navigation("/team/" + team_, { state: { team, league } });
  };

  let ownTeam = "decoy";
  let game = false
  if (o_team1 === data["id"] || o_team2 === data["id"] ) {
    game=true
    ownTeam = "ownteam";
  }
  ////console.log(data);
  return (
    <tr className={ownTeam} style={{ backgroundColor: "yellow" }}>
      <td>{game? <div className="smartTableButton">{data["position"]}</div>: <div>{data["position"]}</div>} </td>
      <td
        className="LhomeOR link"
        onClick={() =>
          handleteamclick({
            val: data["id"],
            team_: data["cleanName"],
            league: compID,
          })
        }
      >
        <img
          src={Team_image?.[String(data["id"])]?.["image"]}
          width="20"
          height="15"
          alt={data["cleanName"]}
        />{" "}
        &nbsp;
        {data["cleanName"]}{" "}
      </td>

      <td></td>

      <td className="preds">{data["matchesPlayed"]}</td>
      <td className="preds">
        {data?.["seasonWins_overall"] || data?.["seasonWins"] || "0"}
      </td>
      <td className="preds">
        {data?.["seasonDraws_overall"] || data?.["seasonDraws"] || "0"}
      </td>
      <td className="preds">
        {data?.["seasonLosses_overall"] ||
          data?.["seasonLosses_away"] ||
          data?.["seasonLosses_home"] ||
          "0"}
      </td>
      <td className="preds">{data["seasonGoals"]}</td>
      <td className="preds">{data["seasonConceded"]}</td>
      <td className="preds">{data["points"]}</td>
    </tr>
  );
};

function TableMain({ Data, disData, asking_team1, asking_team2 }) {
  const navigation = useNavigate();
  const { globalUser } = useContext(GlobalUserContext);

  ////console.log(Data?.["all_matches_table_overall"]);

  const AllGames = Data?.[disData];
  /* const HomeGames = leagueToShow?.["all_matches_table_home"];
  const AwayGames = leagueToShow?.["all_matches_table_away"]; */
  //console.log(AllGames);
  return (
    <div className="light">
      <Table
        className={`outer-border-light ${window.innerWidth < 770 &&"scroll-table-x"}`}
        striped
        hover
        variant="light"
      >
        <thead>
          <tr>
            <th>P</th>
            <th className="LhomeOR link">Team</th>
            <th></th>

            <th className="preds">MP</th>
            <th className="preds">W</th>

            <th className="preds">D</th>
            <th className="preds">L</th>
            <th className="preds">GF</th>
            <th className="preds">GA</th>
            <th className="preds">Pts</th>
          </tr>
        </thead>
        <tbody>
          {AllGames?.map((item, index) => (
            <DisplayData
              key={index}
              data={item}
              o_team1={asking_team1}
              o_team2={asking_team2}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
function LeagueTablesMainSmall(props) {
  const { globalUser } = useContext(GlobalUserContext);
  const [sType, setSType] = useState();
  const [disData, setdisData] = useState("all_matches_table_overall");

  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);

  ////console.log(leagueYears, league, leagueYears?.[league]);
  ////console.log(props?.askleague);
  useEffect(() => {
    const get_tmdata = async () => {
      try {

        let options = {
          body: JSON.stringify({
            league_id: parseInt(props?.askleague)
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },

          seconds: 2 * 60 * 60,
        };
        let url = "/league_tables_simple";
        let indexdb = "league_tables_simple";
        let SaveKey = props?.askleague;
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/league_tables_simple`,
          JSON.stringify({
            league_id: parseInt(props?.askleague),
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        ////console.log(res)
        const firstDict = res;
        if (firstDict?.status === "success" && firstDict.data) {
          ////console.log("first dict", firstDict?.data);
          setData(JSON.parse(firstDict?.data));
          setLoading(false);
        } else {
          throw new Error("Unsuccessful request");
        }
      } catch (error) {
        ////console.log(error)
        Errorlog(error, "error loading statistics", "from team stats");
      }
    };
    get_tmdata();
  }, [props?.askleague]);

  if (!Data || loading) {
    <Spinner />;
  }
  function handleClick(item) {
    setdisData(() => "all_matches_table_" + item);
    setSType(item);
  }

  //console.log(Data, disData);
  return (
    <div>
      <div className="center_div center_sides allpad">
        <div
          className={`statTable smallbuton link  forceblack ${
            sType === "overall" ? "smartTableButton bold" : "inactiveButton"
          }`}
          onClick={() => {
            handleClick("overall");
          }}
        >
          All
        </div>
        <div className="allpad">
          <div
            className={`statTable smallbuton link  forceblack ${
              sType === "home" ? "smartTableButton bold" : "inactiveButton"
            }`}
            onClick={() => {
              handleClick("home");
            }}
          >
            Home
          </div>
        </div>

        <div
          className={`statTable smallbuton link  forceblack ${
            sType === "away" ? "smartTableButton bold" : "inactiveButton"
          }`}
          onClick={() => {
            handleClick("away");
          }}
        >
          Away
        </div>
      </div>
      <TableMain
        disData={disData}
        Data={Data}
        asking_team1={props?.teamA}
        asking_team2={props?.teamB}
      />
    </div>
  );
}
export default LeagueTablesMainSmall;
