import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Button, Col, Container, Form, Row, Card } from "react-bootstrap";
import axios from "axios";
import img1 from "../images/AboutImages/1.png";
import img2 from "../images/AboutImages/2.png";
import img3 from "../images/AboutImages/3.png";
import img4 from "../images/AboutImages/4.png";
import img5 from "../images/AboutImages/5.png";
import img6 from "../images/AboutImages/6.png";
import img8 from "../images/AboutImages/8.png";
import img9 from "../images/AboutImages/9.png";
import img11 from "../images/AboutImages/11.png";
import img12 from "../images/AboutImages/12.png";
import img13 from "../images/AboutImages/13.png";
import img14 from "../images/AboutImages/14.png";
import img15 from "../images/AboutImages/15.png";
import { ToastContainer, toast } from "react-toastify";
import EntryHeader from "./EntryHeader";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import FooterAll from "./Footer";
import { FaWeight } from "react-icons/fa";
import img7 from "../images/AboutImages/7.png";
import { TbBusinessplan } from "react-icons/tb";
import { TfiStatsUp } from "react-icons/tfi";
import bgimage from "../images/analysispic.png";
import mainbg from "../images/Playfromthesidelines.jpg";
import { DisplayAbout } from "./AboutUs";
import { DiaplayHoTo, FAQs } from "./HowItworks";
import { Subscription } from "./Pricing";
import { ContactUsSub } from "./ContactUS";
import { Location } from "./indexPage";

const API_URL = process.env.REACT_APP_API_URL;

function Welcome() {
  const navigation = useNavigate();
  const defaultForm = { email: "", password: "" };
  const [formData, setFormData] = useState(defaultForm);
  const [showFooter, setShowFooter] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const about = useRef(null);
  const howitworks = useRef(null);
  const sub = useRef(null);
  const contact = useRef(null);
  const [curr_location, setcurr_location] = useState(Location());
  const faq = useRef(null);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    
    
  };

  ////console.log("Contact Us", globalUser);
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        ckUser["data"]["user"]["user"]["curr_Country"] = curr_location
        updateGlobalUser(ckUser.data.user);
        setUserInfo(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  if (globalUser?.user?.firstname !== "Guest" && globalUser?.user ) {
    navigation("/", { state: { userinfo: globalUser?.user } });
  }
  ////console.log(userInfo)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  /* useEffect(() => {
    function handleScroll() {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowFooter(isAtBottom);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); */
  const executeScroll = (myRef) => myRef.current.scrollIntoView();
  const handlesubclick = (ref) => {
    if (ref === "about") {
      executeScroll(about);
    } else if (ref === "howitworks") {
      executeScroll(howitworks);
    } else if (ref === "sub") {
      executeScroll(sub);
    } else if (ref === "contact") {
      executeScroll(contact);
    } else if (ref === "faq") {
      executeScroll(faq);
    }
  };
  const handleSubClickmain = (plan) => {
    ////console.log(globalUser);
    if (globalUser?.user?.firstname === "Guest" || !globalUser) {
      navigation("/signin", {
        state: { plan: plan },
      });
    } else {
      navigation("/payments", {
        state: { plan: plan, userinfo: userInfo },
      });
    }
  };
  const listImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img11,
    img12,
    img13,
    img14,
    img15,
  ];

  const handleSubmit = async (event) => {};
  /* "linear-gradient(to Bottom, rgba(11, 38, 71, 0.959) 40%, white 60%)", */
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <EntryHeader
        handlenav={handlesubclick}
        radioValue={radioValue}
        setRadioValue={setRadioValue}
      />

      <div
        style={{
          backgroundImage: `url(${mainbg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "auto",
        }}
      >
        <Container
          style={{
            fontFamily: "serif",
            marginTop: "2%",
            width: "100%",
            height: "100%",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              marginTop: "2%",
              background:
                "linear-gradient(to Bottom, rgba(11, 38, 71, 0.959) 40%, white 60%)",

              width: "100%",
              height: "100%",
              borderTopLeftRadius: "1%",
              borderTopRightRadius: "1%",
              borderBottomLeftRadius: "1%",
              borderBottomRightRadius: "1%",
            }}
          >
            <Row>
              <div
                style={{
                  fontSize: isSmallScreen ? "25px": isMidScreen ? "40px":"70px",
                  color: "white",

                  marginTop: "3%",
                  marginLeft: "5%",
                }}
              >
                Power of Analytics <TfiStatsUp scale={"inherit"} color="orange" />
              </div>
              <div
                style={{
                  fontSize: isSmallScreen ? "30px": isMidScreen ? "45px":"75px",
                  color: "white",
                  fontWeight: "bold",
                  marginLeft: isSmallScreen ? "25px": isMidScreen ? "65px":"100px",
                }}
              >
                <TbBusinessplan scale={"inherit"} color="green" /> Boost Your Earnings
              </div>
              <div
                style={{
                  fontSize: isSmallScreen ? "12px": isMidScreen ? "15px":"25px",
                  color: "white",
                  fontWeight: "bold",
                  marginLeft: isSmallScreen ? "20px": isMidScreen ? "45px":"60px",
                }}
              >
                You will never place a bet blindly ever again.
              </div>
              <div className="center_div">
              <div
                onClick={() => handlesubclick("sub")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "green",
                  fontSize: isSmallScreen ? "20px":"35px",
                  width:"50%",
                  height: "35%",
                  marginTop: "30px",

                  marginBottom: "30px",
                  
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  color: "white",
                  cursor: "pointer",
                }}
                onMouseOver={(e) => {
                  e.target.style.color = "orange";
                }} // change color on hover
                onMouseOut={(e) => {
                  e.target.style.color = "white";
                }} // revert color after hover
              >
                Free Trial
              </div>
              </div>
            </Row>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card style={{ width: "60rem", border: "none" }}>
                <Card.Header
                  variant="top"
                  style={{
                    background: "pink",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  Detailed analysis
                </Card.Header>
                <Card.Img variant="bottom" src={img7} />
              </Card>
            </div>
          </div>
        </Container>
      </div>
      <div ref={about}>
        <DisplayAbout
          globalUser={globalUser}
          listImages={listImages}
          handleSubClick={handlesubclick}
          loggedin={true}
        />
      </div>

      <div ref={howitworks}>
        <Container>
          <DiaplayHoTo handleSubClick={handlesubclick} loggedin={true} />
        </Container>
      </div>

      <div ref={sub}>
        <Subscription handleSubClick={handleSubClickmain} />
      </div>
      <div ref={contact}>
        <ContactUsSub />
      </div>
      <div ref={faq}>
        <Container>
          <FAQs />
        </Container>
      </div>

      <FooterAll page={"Guest"} />
    </div>
  );
}

export default Welcome;
