import React, { useState, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './GetCroppedImage'
import { MDBBtn } from 'mdb-react-ui-kit'

 const ImageCrop = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [showZoomHint, setShowZoomHint] = useState(false)
  const [showMultiTouchHint, setShowMultiTouchHint] = useState(false)
  const [removeTouchAction, setRemoveTouchAction] = useState(false)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const zoomTimeoutRef = useRef()
  const touchTimeoutRef = useRef()

  useEffect(() => {
    clearTimeout(zoomTimeoutRef.current)
    clearTimeout(touchTimeoutRef.current)
  }, [])

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    ////console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onWheelRequest = (e) => {
    // require the CTRL/⌘ key to be able to zoom with wheel
    if (e.ctrlKey || e.metaKey) {
      setShowZoomHint(false)
      return true
    }
    setShowZoomHint(true)
    clearTimeout(zoomTimeoutRef.current)
    zoomTimeoutRef.current = setTimeout(() => setShowZoomHint(false), 2000)
    return false
  }
  const onTouchRequest = (e) => {
    // require 2 fingers to be able to interact with the image
    if (e.touches.length > 1) {
      setShowMultiTouchHint(false)
      setRemoveTouchAction(true)
      return true
    }
    setShowMultiTouchHint(true)
    setRemoveTouchAction(false)
    clearTimeout(touchTimeoutRef.current)
    touchTimeoutRef.current = setTimeout(
      () => setShowMultiTouchHint(false),
      2000
    )
    return false
  }
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        props?.useImage,
        croppedAreaPixels,
        
      )
      ////console.log('donee', { croppedImage })
      props.getcropped(croppedImage)
      props?.setShowProfileCropper("result")
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div>
      <div className="crop-container">
        <Cropper
          image={props?.useImage}
          crop={crop}
          zoom={zoom}
          aspect={props?.scale}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onWheelRequest={onWheelRequest}
          onTouchRequest={onTouchRequest}
          classes={
            removeTouchAction && { containerClassName: 'removeTouchAction' }
          }
        />
        {showZoomHint && (
          <div className="zoom-hint">
            <p>Use ⌘ + scroll (or ctrl + scroll) to zoom the image</p>
          </div>
        )}
        {showMultiTouchHint && (
          <div className="touch-hint">
            <p>Use 2 fingers to interact with the image</p>
          </div>
        )}
      </div>
      <div className="controls">
        <input
          type="range"
          value={zoom}
          style={{color:"blue"}}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        /> <div className='bold allpad'>Zoom</div>
      </div>
      <div className="center_div allpad" onClick={showCroppedImage}><MDBBtn>Crop image</MDBBtn></div>
    </div>
  )
}
export default ImageCrop