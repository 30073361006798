import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import HomeDisplay from "./TeampastMatches_Home";
import AwayDisplay from "./TeampastMatches_Away";
import Team_image from "../../images/team_images.json";
import { convertUnixToTime } from "../Utils";

import "../../css/Teamstats.css";
import { Errorlog } from "../Utils";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;

function DisplayPastGames({ Tab, Tab2, team }) {
  const { globalUser } = useContext(GlobalUserContext);
  const [monthCount, setMonthCount] = useState(10);
  const [useHeader, setuseHeader] = useState();
  const [useTdata, setuseTdata] = useState();
  const [useteam, setTeam] = useState(team);
  const [loading, setLoading] = useState(true);
  const [Data, setdata] = useState();
  const [mid, setIsMidScreen] = useState(false);
  const [small, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1200 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  ////console.log(Tab, Tab2);

  

  useEffect(() => {
    //setFixture(() => {});
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    if (team !== null) {
      const get_tmdata = async () => {
        let options = {
          body: JSON.stringify({
            team: useteam,
            tab: Tab,
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },
  
          seconds: 2 * 60 * 60,
        };
        let url = "/team_past";
        let indexdb = "team_past";
        let SaveKey = "" + useteam + Tab;

        try {
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);

          /* const res = await axios.post(
            `${API_URL}/team_past`,
            JSON.stringify({
              team: useteam,
              tab: Tab,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = res;
          ////console.log(res);
          if (fix_data.status === "success") {
            setdata(fix_data?.data);
            //console.log(fix_data?.data)
            setLoading(false);
          } else {
            Errorlog(
              "loaderror",
              "error loading statistics",
              "from league stats"
            );
          }
        } catch (error) {
          Errorlog(error, "error loading statistics", "from league stats");
          ////console.log(error);
        }
      };
      get_tmdata();
    }
  }, []);

  if (!Data || loading) {
    return <Spinner />;
  }
  function MultiLeague({ Data_, date_, small, mid }) {
    const navigation = useNavigate();
    const DisplayData = ({ Indata }) => {
      const WinnerButton = ({ button_, text_ }) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: button_,
                padding: "3px 7px",
                fontSize: "11px",
              }}
            >
              {text_}
            </Button>
          </div>
        );
      };
      ////console.log(homeTeam);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };
      let t_d = {};
      let rescolor = ""
      if (Tab2 === "Win-Draw-Lose") {
        t_d = Indata?.win_tab;
        rescolor = ""
      } else if (Tab2 === "Goals") {
        t_d = Indata?.goals_tab;
      } else if (Tab2 === "Cards") {
        t_d = Indata?.cards_tab;
      } else if (Tab2 === "Corners") {
        t_d = Indata?.corners_tab;
      }else if (Tab2 === "WDL-Cards") {
        t_d = Indata?.card_win_tab;
        rescolor = "_card"
      }
      else if (Tab2 === "WDL-Corners") {
        t_d = Indata?.corner_win_tab;
        rescolor = "_corner"
      }
      const getBorder = (field) => {
        if (t_d?.outcome === "loss" && field === "loss") {
          return "solid 2px";
        } else if (t_d?.outcome === "draw" && field === "draw") {
          return "solid 2px";
        } else if (t_d?.outcome === "win" && field === "win") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_goal = (field) => {
        if (t_d?.g_outcome === "over" && field === "overg") {
          return "solid 2px";
        } else if (t_d?.g_outcome === "under" && field === "underg") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "score" && field === "score") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "no_score" && field === "noscore") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_cc = (field) => {
        if (t_d?.c_outcome === "under" && field === "under") {
          return "solid 2px";
        } else if (t_d?.c_outcome === "over" && field === "over") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const handlematchlick = (match_id) => {
        navigation("/match/" + match_id, { state: match_id });
      };
      return (
        <>
          <td className="dates">
            {small
              ? convertUnixToTime(Indata && Indata["date_unix"], "short")
              : convertUnixToTime(Indata && Indata["date_unix"], "")}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </>
          )}

          <td
            className="homeOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["homeID"],
                team_: Indata?.["home_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            <div className="right_content center_straight usemaxSpace">
              <div
                className="center_straight center_sides"
                style={{ maxWidth: window.innerWidth < 770 ? "90px" : "" }}
              >
                <div
                  className="noBorders"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  {Indata?.["home_name"]} &nbsp;
                </div>
                <div>
                  <img
                    src={Team_image?.[String(Indata?.["homeID"])]?.["image"]}
                    width="20"
                    height="15"
                    alt={Indata?.["home_name"]}
                  />
                </div>
              </div>
            </div>
          </td>

          {Indata?.status === "complete" ? (
            <td
              className="statsOR link"
              onClick={() => handlematchlick(Indata && Indata["id"])}
            >
              {Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                ? `${Indata?.home_goals} : ${Indata?.away_goals}`
                : Tab2 === "Cards " || Tab2 === "WDL-Cards" 
                ? `${t_d?.h_card ?? Indata?.team_a_cards_num ?? "-"} : ${
                    t_d?.a_card ?? Indata?.team_b_cards_num ?? "-"
                  }`
                : Tab2 === "Corners" || Tab2 === "WDL-Corners"
                ? `${t_d?.h_corner ?? Indata?.team_a_corners ?? "-"} : ${
                    t_d?.a_corner ?? Indata?.team_b_corners ?? "-"
                  }`
                : null}
            </td>
          ) : (
            <td
              className="statsOR link"
              onClick={() => handlematchlick(Indata && Indata["id"])}
            >
              {" "}
              -:-
            </td>
          )}
          <td
            className="awayOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["awayID"],
                team_: Indata?.["away_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
           <div
              className="center_straight center_sides"
              style={{ maxWidth: window.innerWidth < 770 ? "90px" : "" }}
            >
              <div>
                <img
                  src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                  width="20"
                  height="15"
                  alt={Indata?.["away_name"]}
                />
              </div>

              <div
                className="noBorders"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                  width: "100%",
                  overflowWrap: "break-word",
                }}
              >
                &nbsp;
                {Indata?.["away_name"]}{" "}
              </div>
            </div>
          </td>
          {small ? (
            <>
              <td className="link">
                <IoIosArrowDropdownCircle size={20} color="gray" />
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td></td>
              {Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" ||Tab2 === "WDL-Corners"? (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["win_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder("win"),
                      }}
                    >
                      {t_d?.["win%"] ? t_d?.["win%"] : "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["draw_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder("draw"),
                      }}
                    >
                      {t_d?.["draw%"] ? t_d?.["draw%"] : "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["loss_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder("loss"),
                      }}
                    >
                      {t_d?.["loss%"] ? t_d?.["loss%"] : "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>

                  <td className="preds">
                    <WinnerButton
                      button_={Indata?.["but_color"+rescolor]}
                      text_={Indata?.["butt_text"+rescolor]}
                    />
                  </td>
                </>
              ) : Tab2 === "Goals" ? (
                <>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["over_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("overg"),
                      }}
                    >
                      {t_d?.["over1.5%"] ? t_d?.["over1.5%"] : "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["under_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("underg"),
                      }}
                    >
                      {t_d?.["under1.5%"] ? t_d?.["under1.5%"] : "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["score_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("score"),
                      }}
                    >
                      {t_d?.["score%"] ? t_d?.["score%"] : "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["no_score_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("noscore"),
                      }}
                    >
                      {t_d?.["no_score%"] ? t_d?.["no_score%"] : "- %"}
                    </div>
                  </td>
                </>
              ) : Tab2 === "Cards" ? (
                <>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["over_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("over"),
                      }}
                    >
                      {t_d?.["over2.5%"] ? t_d?.["over2.5%"] : "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["under_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("under"),
                      }}
                    >
                      {t_d?.["under2.5%"] ? t_d?.["under2.5%"] : "- %"}
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["over_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("over"),
                      }}
                    >
                      {t_d?.["over3.5%"] ? t_d?.["over3.5%"] : "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: t_d?.["under_colo"],
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("under"),
                      }}
                    >
                      {t_d?.["under3.5%"] ? t_d?.["under3.5%"] : "- %"}
                    </div>
                  </td>
                </>
              )}
            </>
          )}
        </>
      );
    };

    const DisplayDrop = ({ Indata }) => {
      ////console.log(data);
      const WinnerButton = ({ button_, text_ }) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: button_,
                padding: "3px 7px",
                fontSize: "11px",
              }}
            >
              {text_}
            </Button>
          </div>
        );
      };
      ////console.log(homeTeam);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };
      let t_d = {};
      let rescolor = ""
      if (Tab2 === "Win-Draw-Lose") {
        t_d = Indata?.win_tab;
        rescolor = ""
      } else if (Tab2 === "Goals") {
        t_d = Indata?.goals_tab;
      } else if (Tab2 === "Cards") {
        t_d = Indata?.cards_tab;
      } else if (Tab2 === "Corners") {
        t_d = Indata?.corners_tab;
      }else if (Tab2 === "WDL-Cards") {
        t_d = Indata?.card_win_tab;
        rescolor = "_card"
      }
      else if (Tab2 === "WDL-Corners") {
        t_d = Indata?.corner_win_tab;
        rescolor = "_corner"
      }
      const getBorder = (field) => {
        if (t_d?.outcome === "loss" && field === "loss") {
          return "solid 2px";
        } else if (t_d?.outcome === "draw" && field === "draw") {
          return "solid 2px";
        } else if (t_d?.outcome === "win" && field === "win") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_goal = (field) => {
        if (t_d?.g_outcome === "over" && field === "overg") {
          return "solid 2px";
        } else if (t_d?.g_outcome === "under" && field === "underg") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "score" && field === "score") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "no_score" && field === "noscore") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_cc = (field) => {
        if (t_d?.c_outcome === "under" && field === "under") {
          return "solid 2px";
        } else if (t_d?.c_outcome === "over" && field === "over") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      /* const ownleague = Object.keys(Lines)[0];
      const Lines_ = Lines?.[ownleague];
      let t_d1 = {};
      if (Tab2 === "Win-Draw-Loose") {
        t_d1 = ["Win%", "Draw%", "Loss%", "Result"];
      } else if (Tab2 === "Goals") {
        t_d1 = ["Overer 1.5%", "Under 1.5%", "To Score%", "No Score%"];
      } else if (Tab2 === "Cards") {
        t_d1 = ["Over 2.5%", "Under 2.5%"];
      } else if (Tab2 === "Corners") {
        t_d1 = ["Over 3.5%", "Under 3.5%"];
      } */
      return (
        <div>
          <div className="center_div_distributed margin-30down">
            <div>
              <div>Time</div>
              <div>
                {convertUnixToTime(Indata && Indata["date_unix"], "time")}
              </div>
            </div>
            {Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" ||Tab2 === "WDL-Corners"? (
              <>
                <div>
                  <div>Win%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["win_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("win"),
                    }}
                  >
                    {t_d?.["win%"] ? t_d?.["win%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Draw%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["draw_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("draw"),
                    }}
                  >
                    {t_d?.["draw%"] ? t_d?.["draw%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Loss%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["loss_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("loss"),
                    }}
                  >
                    {t_d?.["loss%"] ? t_d?.["loss%"] : "- %"}
                  </div>
                </div>
                <div className="center_div center_straight">
                  <div>Result</div>
                  <div>
                    <WinnerButton
                      button_={Indata?.["but_color"+rescolor]}
                      text_={Indata?.["butt_text"+rescolor]}
                    />
                  </div>
                </div>
              </>
            ) : Tab2 === "Goals" ? (
              <>
                <div>
                  <div>Ov1.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["over_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("overg"),
                    }}
                  >
                    {t_d?.["over1.5%"] ? t_d?.["over1.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Un1.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["under_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("underg"),
                    }}
                  >
                    {t_d?.["under1.5%"] ? t_d?.["under1.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Score%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["score_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("score"),
                    }}
                  >
                    {t_d?.["score%"] ? t_d?.["score%"] : "- %"}
                  </div>
                </div>
                <div className="center_div center_straight">
                  <div>NoScore</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["no_score_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("noscore"),
                    }}
                  >
                    {t_d?.["no_score%"] ? t_d?.["no_score%"] : "- %"}
                  </div>
                </div>
              </>
            ) : Tab2 === "Cards" ? (
              <>
                <div>
                  <div>Ov2.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["over_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("over"),
                    }}
                  >
                    {t_d?.["over2.5%"] ? t_d?.["over2.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Un2.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["under_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("under"),
                    }}
                  >
                    {t_d?.["under2.5%"] ? t_d?.["under2.5%"] : "- %"}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>Ov3.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["over_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("over"),
                    }}
                  >
                    {t_d?.["over3.5%"] ? t_d?.["over3.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Un3.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["under_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("under"),
                    }}
                  >
                    {t_d?.["under3.5%"] ? t_d?.["under3.5%"] : "- %"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    };

    const MLines = ({ Lines }) => {
      ////console.log(Lines);
      const ownleague = Object.keys(Lines)[0];
      const Lines_ = Lines?.[ownleague];
      let t_d = {};
      const [isdropdownClick, setisdropdownClick] = useState(false);

      const handleDropclick = (index) => {
        // if the row was already open, close it
        if (isdropdownClick === index) {
          setisdropdownClick(null);
        } else {
          // otherwise, open the clicked row
          setisdropdownClick(index);
        }
      };
      if (Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" ||Tab2 === "WDL-Corners") {
        t_d = ["Win%", "Draw%", "Loss%", "Result"];
      } else if (Tab2 === "Goals") {
        t_d = ["Overer 1.5%", "Under 1.5%", "To Score%", "No Score%"];
      } else if (Tab2 === "Cards") {
        t_d = ["Over 2.5%", "Under 2.5%"];
      } else if (Tab2 === "Corners") {
        t_d = ["Over 3.5%", "Under 3.5%"];
      }
      return (
        <div>
          <Table
            className="outer-border-light"
            striped
            hover
            size={small || mid || window.innerWidth < 1400 ? "sm" : 8}
            variant="light"
          >
            <thead>
              <tr>
                <th>Date</th>
                {small ? null : (
                  <>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </>
                )}
                <th className="homeOR">Home</th>
                <th className="statsOR">Stats</th>{" "}
                <th className="awayOR">Away</th>
                {small ? (
                  <>
                    <th>Optns</th>
                  </>
                ) : (
                  <>
                    <th></th>
                    <th></th>
                    {Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" ||Tab2 === "WDL-Corners" ? (
                      <>
                        <th className="preds">Win%</th>{" "}
                        <th className="preds">Draw%</th>
                        <th className="preds">Loss%</th>
                        <th></th>
                        <th></th>
                        <th className="preds">Result</th>
                      </>
                    ) : Tab2 === "Goals" ? (
                      <>
                        <th></th>
                        <th className="preds">Ov1.5%</th>
                        <th className="preds">Un1.5%</th>
                        <th></th>
                        <th className="preds">Score%</th>
                        <th className="preds">NoScore%</th>
                      </>
                    ) : Tab2 === "Cards" ? (
                      <>
                        <th></th>
                        <th></th>
                        <th className="preds">Ov2.5%</th>

                        <th></th>
                        <th></th>
                        <th className="preds">Un2.5%</th>
                      </>
                    ) : (
                      <>
                        <th></th>
                        <th></th>
                        <th className="preds">Ov3.5%</th>

                        <th></th>
                        <th></th>
                        <th className="preds">Un3.5%</th>
                      </>
                    )}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Lines.sort((a, b) => b.date_unix - a.date_unix).map((item, index) => (
                <React.Fragment key={index}>
                  <tr onClick={() => handleDropclick(index)}>
                    <DisplayData Indata={item} />
                  </tr>
                  {isdropdownClick === index ? (
                    <tr colSpan="100%">
                      <td colSpan="100%">
                        <DisplayDrop Indata={item} />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      );
    };
    const Ob_Data_ = Object.keys(Data_)[0];
    ////console.log(Data_);
    return (
      <div>
        {small || mid ? (
          <div>
            {Object.keys(Data_).map((item, index) => (
              <div key={item}>
                <div className="tableheader">
                  <DisplayLeague lg={Data_[item]} date={date_} />
                </div>
                <Modal.Body>
                  <MLines Lines={Data_[item]} />
                </Modal.Body>
              </div>
            ))}
          </div>
        ) : (
          <Container>
            {Object.keys(Data_).map((item, index) => (
              <div key={item}>
                <div className="tableheader">
                  <DisplayLeague lg={Data_[item]} date={date_} />
                </div>
                <Modal.Body>
                  <MLines Lines={Data_[item]} />
                </Modal.Body>
              </div>
            ))}
          </Container>
        )}
      </div>
    );
  }
  function DisplayLeague({ lg, date }) {
    function formatMonthYear(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const [year, month] = dateString.split(".");
      const monthInWords = months[Number(month) - 1];

      return `${monthInWords}, ${year}`;
    }
    ////console.log(lg);
    return (
      <div>
        <div className="allpad tableFontSize mainFont">
          <Row className="center_div">
            <Col md={1} sm={2} xs={3} lg={1}>
              <img
                src={lg?.[0]?.["lg_image"]}
                width="30"
                height="25"
                alt={lg?.[0]?.["league_name"]}
              />
            </Col>
            <Col md={2} sm={3} xs={4} lg={2} className="left_allign">
              {lg?.[0]?.["league_name"]}{" "}
            </Col>

            <Col md={9} sm={7} xs={5} lg={9} className="left_allign">
              <div className="usemaxSpace">{formatMonthYear(date)}</div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  function Matches({ type, monthCount }) {
    const matches = Data?.past_games[type];
    //const keysArray = Object.keys(g_past_date);
    ////console.log(g_past_date[matches[0]]);
    ////console.log(Object.keys(matches));
    return (
      <div>
        <Modal.Dialog className="rounded-3">
          {matches &&
            Object.keys(matches)
              .sort()
              .reverse()
              .map((item, index) => {
                if (index < monthCount) {
                  return (
                    <div key={index}>
                      <MultiLeague
                        Data_={matches[item]}
                        date_={item}
                        small={small}
                        mid={mid}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
        </Modal.Dialog>
      </div>
    );
  }
  const HandleSeeMore = (val) => {
    //let currValue = monthCount;

    if (val === "seemore" && monthCount > 9) {
      setMonthCount(monthCount + 5);
    }
    if (val === "seeless" && monthCount > 14) {
      setMonthCount(monthCount - 5);
    }
  };
  function SeeMore() {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <Container className="justify-center">
          <Nav style={{ display: "flex", justifyContent: "space-between" }}>
            <NavLink onClick={() => HandleSeeMore("seeless")}>
              {"< See Less"}
            </NavLink>
            <NavLink onClick={() => HandleSeeMore("seemore")}>
              {"See More >"}
            </NavLink>
          </Nav>
        </Container>
      </div>
    );
  }

  return (
    <div>
      {small || mid ? (
        <div>
          <Matches type={Tab} monthCount={monthCount} /> <SeeMore />
        </div>
      ) : (
        <Container>
          <Matches type={Tab} monthCount={monthCount} /> <SeeMore />
        </Container>
      )}
    </div>
  );
}
export default DisplayPastGames;
