import { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Button,
  Breadcrumb,
  Col,
  Form,
  Card,
  Row,
} from "react-bootstrap";
import IndexHeader from "./IndexHeaders";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import React from "react";
import { BsCashCoin } from "react-icons/bs";
import PasswordChecklist from "react-password-checklist";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const Rookie_afPRice = process.env.REACT_APP_R_AFF_PRICE;
const Rookie_worPrice = process.env.REACT_APP_R_WORLD_PRICE;
const Enthusiast_afPRice = process.env.REACT_APP_E_AFF_PRICE;
const Enthusiast_worPrice = process.env.REACT_APP_E_WORLD_PRICE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBInput,
  MDBTextArea,
  MDBPopconfirm,
  MDBPopconfirmMessage,
} from "mdb-react-ui-kit";
import { MDBNavbar } from "mdb-react-ui-kit";
import { IoIosLogOut } from "react-icons/io";
import { MdOutlineSubscriptions } from "react-icons/md";
import { MdPayment } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { CiUser } from "react-icons/ci";
import { MDBFileUpload } from "mdb-react-file-upload";

import FooterAll from "./Footer";
import "../css/Profile.css";
import { FaEdit, FaRegSave } from "react-icons/fa";
import { validatePhone } from "./SignUp";
import { pl } from "date-fns/locale";
import care from "../images/unisex.jpg";
import bgimage from "../images/uniback.jpg";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const STRIPE_PROTAL = process.env.REACT_APP_STRIP_PORTAL;
const GEO_KEY = process.env.REACT_APP_GEOLOCATION;

const moment = require("moment");

import { useGeolocated } from "react-geolocated";
import Spinner, { SpinnerCustom } from "./Spinner";
import { InfoTag } from "./DisplayInfo";
import { SideNavPage } from "./Utils";
import FooterNav from "./SmallScreenNav";

function convertUnixToFormattedTime(unixTime) {
  // Convert the UNIX timestamp to a Moment.js object
  const time = moment.unix(unixTime);

  // Format the time
  const formattedTime = time.format("dddd [at] ha, MMMM Do, YYYY");

  return formattedTime;
}

////console.log(convertUnixToFormattedTime(1712025620));
function formatDate2(dateString) {
  const date = moment(dateString);
  return (
    date.format("dddd") +
    " at " +
    date.format("ha") +
    ", " +
    date.format("MMMM Do, YYYY")
  );
}
export function newdate() {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1; // Month index starts from 0
  let day = date.getDate();

  month = month < 10 ? `0${month}` : month; // Add leading zero if needed
  day = day < 10 ? `0${day}` : day; // Add leading zero if needed

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function renewalDate(numMonths) {
  const date = new Date();
  date.setMonth(date.getMonth() + numMonths); // Add specified number of months

  const year = date.getFullYear();
  let month = date.getMonth() + 1; // Month index starts from 0
  let day = date.getDate();

  month = month < 10 ? `0${month}` : month; // Add leading zero if needed
  day = day < 10 ? `0${day}` : day; // Add leading zero if needed

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
function UpdateChangesLocal(data, oldDict) {
  for (let key in data) {
    if (oldDict.hasOwnProperty(key)) {
      oldDict[key] = data[key];
    }
  }
  oldDict.updatedDate = new Date();

  return oldDict;
}

const handleSubChange = async (
  action,
  globalUser,
  navigate,
  HandleLogout,
  setIsSubscribed
) => {
  const subChange = {
    user_name: globalUser?.user?.firstname,
    user_email: [globalUser?.user?.email],
    subject: "Subscription Data Changed",
    send_mail: "security@betinformatics.com",
    sub_name: "Bet Informatics Security",
    message_type: "Datachange",
    user_message: "Your Subscription ",
    links: [`${WEB}/signin`],
  };
  const querry = {
    auth: globalUser?.user?.auth?._id,
    email: globalUser?.user?.email,
    action: action,
  };
  if (action === "cancel") {
    setIsSubscribed("No");
    subChange.user_message =
      "Your Subscription has been Cancelled and will not auto-renew when it expires. If you did not perfrom this action, please login to your account to correct this to avoid service interuption.";
  } else {
    setIsSubscribed("Yes");
    subChange.user_message =
      "Your Subscription has been Activated and will auto-renew  by the end of your current subscription. If you did not perfrom this action, please login to your account to correct this to avoid being charged. After a sucessful charge of your payment method, refunds are not possible.";
  }
  ////console.log(querry);
  try {
    const res = await axios
      .post(`${API_URL}/editSubscription`, querry)
      .catch((error) => {
        //throw new Error('Server is unavailable, please try again later');
      });
    ////console.log(res);
    if (res.data.status === "updated") {
      ////console.log(res.data);
      toast.success("Subscription " + action);
      UpdateLocal({ action: "save", data: { user: res?.data?.userData } });
      const res_ = await axios
        .post(`${API_URL}/send-mail`, subChange)
        .catch((error) => {
          //throw new Error('Server is unavailable, please try again later');
        });
    } else if (res.data.reason === "oldSession") {
      HandleLogout({ reason: "oldSession" });
    }
  } catch {
    toast.error("Sorry! its not you its us.. please try again");
  }
};
export default function ProfilePage() {
  ////console.log(userinfo?.email);
  const navigate = useNavigate();
  const location = useLocation();

  const userinfo = location.state?.userinfo || {};
  const dis_item = location.state?.data || "Profile Data";
  const [itemDisplay, setItemDisplay] = useState(dis_item);
  const [issubscribed, setIsSubscribed] = useState("Yes");
  const [isChanged, setIsChanged] = useState(false);
  const [userSubdetail, setUserSubDetail] = useState("");
  const [userProfile, setUserProfile] = useState();
  const [userBackground, setUserBackground] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [formData_, setFormData_] = useState();
  const [formDataSub, setFormDataSub] = useState();
  const defaultpass = {
    oldPassword: "",
    confirmOldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const defaultTipstar = {
    tipstarName: "",
    tipstarPhrase: "",
  };
  const [formDataPass_, setFormDataPass_] = useState(defaultpass);

  const neededInfo = [
    "firstname",
    "lastname",
    "phonenumber",
    "email",
    "address1",
    "address2",
    "dateofbirth",
    "country",
    "creationDate",
    "subscription",
    "updatedDate",
    "tipstarDetails",
    "city",
    "auth",
    "state",
    "zip",
    "dataMode",
  ];
  const tempInfo = {};
  neededInfo.forEach((key) => {
    if (userinfo.hasOwnProperty(key)) {
      tempInfo[key] = userinfo[key];
    }
  });
  ////console.log(userinfo);
  tempInfo["name"] = "user";
  tempInfo["page"] = "UserProfile";
  const userData = { user: tempInfo };
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  const [country, setCountry] = useState("");

  ////console.log(globalUser, userinfo);
  useEffect(() => {
    const fetchData = async () => {
      checkScreenSize();
      window.addEventListener("resize", checkScreenSize);
      let ckUser;
      if (Object.keys(userinfo).length === 0) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
      } else {
        ckUser = await UpdateLocal({
          action: "save",
          data: userData,
        });
      }
      /* const tipstarDetail = await axios
        .post(`${API_URL}/gettipstarInfo`, {
          email: userData?.user?.email || globalUser?.user?.email,
          action: "get",
        })
        .catch((error) => {
          //throw new Error('Server is unavailable, please try again later');
        });

      if (tipstarDetail?.status == "success") {
        ////console.log("got it", tipstarDetail);
        setFormTipstar(tipstarDetail?.data?.data);
      } */

      if (
        (ckUser && ckUser.result === "saved") ||
        (ckUser && ckUser.result === "retrieved" && ckUser.data !== undefined)
      ) {
        if (ckUser && ckUser.result === "saved") {
          updateGlobalUser(userData);
          const default_form = {
            firstname: userData?.user?.firstname,
            lastname: userData?.user?.lastname,
            phonenumber: userData?.user?.phonenumber,
            dateofbirth: userData?.user?.dateofbirth,
            email: userData?.user?.email,
            address1: userData?.user?.address1,
            address2: userData?.user?.address2,
            country: userData?.user?.country,
            city: userData?.user?.city,
            dataMode: userData?.user?.dataMode,
            state: userData?.user?.state,
            zip: userData?.user?.zip,
            creationDate: userData?.user?.creationDate,
            updatedDate: userData?.user?.updatedDate,
          };

          const res_usersub = await axios
            .post(`${API_URL}/getSubscription`, {
              email: userData?.user?.email,
            })
            .catch((error) => {
              //throw new Error('Server is unavailable, please try again later');
            });

          ////console.log(res_usersub);
          if (res_usersub?.data?.status === "success") {
            setUserSubDetail(res_usersub?.data?.userData);
          }
          setFormDataSub({ subscription: userData?.user?.subscription });

          setFormData_(default_form);
        } else if (ckUser && ckUser.data && ckUser.data.user) {
          updateGlobalUser(ckUser.data.user);
          const default_form = {
            firstname: globalUser?.user?.firstname,
            lastname: globalUser?.user?.lastname,
            phonenumber: globalUser?.user?.phonenumber,
            dateofbirth: globalUser?.user?.dateofbirth,
            email: globalUser?.user?.email,
            address1: globalUser?.user?.address1,
            address2: globalUser?.user?.address2,
            country: globalUser?.user?.country,
            city: globalUser?.user?.city,
            dataMode: globalUser?.user?.dataMode,
            state: globalUser?.user?.state,
            zip: globalUser?.user?.zip,
            creationDate: globalUser?.user?.creationDate,
            updatedDate: globalUser?.user?.updatedDate,
          };
          const res_usersub = await axios
            .post(`${API_URL}/getSubscription`, {
              email: globalUser?.user?.email  ?? userData?.user?.email,
            })
            .catch((error) => {
              //throw new Error('Server is unavailable, please try again later');
            });
          ////console.log(res_usersub);
          if (res_usersub?.data?.status === "success") {
            setUserSubDetail(res_usersub?.data?.userData);
          }
          setFormDataSub({ subscription: globalUser?.user?.subscription });

          setFormData_(default_form);
          /* const tipstarDetail = await axios
            .post(`${API_URL}/gettipstarInfo`, {
              email: globalUser?.user?.email,
              action: "get",
            })
            .catch((error) => {
              //throw new Error('Server is unavailable, please try again later');
            });
          ////console.log("got it", tipstarDetail);
          if (tipstarDetail?.status == "success") {
            setFormTipstar(tipstarDetail?.data?.data);
          } */
        } else {
          toast.error("An error occured");
        }

        ////console.log("userData saved");
      } else {
        toast.info("You need to Login to access your profile");
      }
    };

    fetchData();
  }, [itemDisplay, issubscribed, setIsChanged]);

  /* UpdateLocal({ action: "delete", data: { rmKeys: ["undefined"] } }).then(
    (result) => {
      ////console.log(result);
    }
  ); */
  useEffect(() => {
    //console.log(globalUser?.user?.email  ?? userData?.user?.email);
    async function fetchBackground() {
      try {
        const response = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email  ?? userData?.user?.email}_background`
        ).catch((error) => {
          "";
        });
        if (!response.ok) {
          //throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob to a string
        setUserBackground(base64data);
        //console.log(base64data)
      } catch (error) {
        //console.error(error);
      }
      try {
        const response2 = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response2.ok) {
          //throw new Error(`HTTP error! status: ${response2.status}`);
        }
        const blob2 = await response2.blob();
        const base64data2 = await blob2.text(); // Convert the blob to a string
        setUserProfile(base64data2);
        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [globalUser?.user?.email]);
  const HandleLogout = (props) => {
    ////console.log(props);
    if (props?.reason === "oldSession") {
      toast.info("log in again to update subscription");
      UpdateLocal({ action: "delete", data: { rmKeys: ["user"] } }).then(
        (result) => {
          toast.success("You are logged out");
          updateGlobalUser("");
          navigate("/signin");
        }
      );
    } else {
      UpdateLocal({ action: "delete", data: { rmKeys: ["user"] } }).then(
        (result) => {
          toast.success("You are logged out");
          updateGlobalUser("");
          navigate("/welcome");
        }
      );
    }
  };
  const handleSubClick = (plan) => {
    navigate("/payments", {
      state: { plan: plan, userinfo: globalUser },
    });
  };
  if (!globalUser) {
    return null;
  }
  function PaymentDetails() {
    return (
      <div>
        <Container>
          <div className="margin-30down">
            <Row>
              <Col lg="3" sm="10">
                <div style={{ fontSize: "20px" }}>Name On Card :</div>
              </Col>
              <Col>
                <div style={{ fontSize: "20px" }}>
                  {userSubdetail?.name_on_Card}
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="3" sm="10">
                <div style={{ fontSize: "20px" }}>Card Type :</div>
              </Col>
              <Col>
                <div style={{ fontSize: "20px" }}>
                  {userSubdetail?.card_type}
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="3" sm="10">
                <div style={{ fontSize: "20px" }}>Finance Type :</div>
              </Col>
              <Col>
                <div style={{ fontSize: "20px" }}>{userSubdetail?.funding}</div>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col lg="3" sm="10">
                <div style={{ fontSize: "20px" }}>Card Number :</div>
              </Col>
              <Col>
                <div style={{ fontSize: "20px" }}>
                  {" "}
                  *****{userSubdetail?.last4}
                </div>
              </Col>
            </Row>
          </div>
          <div className="divorangeButton link">
            <a
              style={{ textDecoration: "none" }}
              href={`${STRIPE_PROTAL}${globalUser?.user?.email}`}
            >
              Update your payment details
            </a>
          </div>
        </Container>
      </div>
    );
  }
  function ShowSubscription() {
    const plansbasic = {
      title: "Rookie",
      type: "One month",
      subtype: "$29.99 due after 3 days trial",
      price: "$29.99",
      paymentAmount: 29.99,
      afsubtype: "N15,000 due after 3 days trial",
      afprice: "N15,000",
      afpaymentAmount: 15000,
      WorldstripePrice: Rookie_worPrice,
      AfristripePrice: Rookie_afPRice,
      features: [
        "Past and future game Analytics",
        "Advanced Statistics",
        "All game options including Cards and Corners",
        "Best Option Search page",
        "Automatic subscription renewal",
      ],
      styles_top: {
        background: "rgba(123, 216, 240, 0.808)",
        width: isSmallScreen ? "20rem" : "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "20px" },
    };
    const free = {
      title: "Free",
      subtype: "Free",
      afsubtype: "$0 due today",
      afprice: "-",

      price: "$-",
      features: [
        "Match, Team and League History Analytics",
        "Easy acess tickets for research tracking",
        "Daily games on demand with probablities and current form",
        "Free forever",
      ],
      styles_top: {
        background: "rgba(123, 216, 240, 0.808)",
        width: "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "20px" },
    };
    const planmid = {
      title: "Enthusiast",
      type: "Three months",
      price: "$24.99",
      subtype: "$74.97 due after 3 days trial",
      paymentAmount: 74.97,
      afprice: "N10,000",
      afsubtype: "N30,000 due after 3 days trial",
      afpaymentAmount: 30000,
      WorldstripePrice: Enthusiast_worPrice,
      AfristripePrice: Enthusiast_afPRice,
      features: [
        "Past and future game Analytics",
        "Advanced Statistics",
        "All game options including Cards and Corners",
        "Best Option Search page",
        "Automatic subscription renewal",
        "Renews after three months",
      ],
      styles_top: {
        background: "rgba(6, 194, 241, 0.808)",
        width: isSmallScreen ? "20rem" : "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "12px" },
    };
    /* const planpro = {
      title: "Professional",
      type: "Three months",
      subtype: "$95.97 due after 3 days trial",
      price: "$31.99",
      paymentAmount: 95.97,
      afprice: "N15,000",
      afsubtype: "N45,000 due after 3 days trial",
      afpaymentAmount: 45000,
      WorldstripePrice: "price_1OfpSKG6BtEwIzwZcOkfhMUa",
      AfristripePrice: "price_1OfqfVG6BtEwIzwZJLDoKNhd",
      features: [
        "Past and future game Analytics",
        "Advanced Statistics",
        "All game options including Cards and Corners",
        "Best Option Search page",
        "Daily Game alart in E-mail/Social Media",
        "Automatic subscription renewal",
        "Renews after three months",
      ],
      styles_top: {
        background: "rgba(3, 63, 131, 0.808)",
        width: "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "6px" },
    }; */
    const listOfPlans = [free];
    let curr_planprice = "";
    let curr_sub = "";
    let trial = "false";
    var currtime = Math.floor(Date.now() / 1000);
    for (let index = 0; index < listOfPlans.length; index++) {
      const plan_ = listOfPlans[index];
      if (
        plan_?.WorldstripePrice === userSubdetail?.current_product ||
        plan_?.AfristripePrice === userSubdetail?.current_product
      ) {
        curr_planprice = plan_?.price;
        curr_sub = `${plan_?.title}`;
        trial = `${userSubdetail?.tria_end > currtime ? "true" : "false"}`;
      }
    }

    ////console.log(listOfPlans);
    const DisplayOtherSub = ({ plan }) => {
      return (
        <div className="center_div">
          <Card
            className="h-100"
            style={{
              border: "none",
              width: isSmallScreen ? "20rem" : "25rem",
              background: "rgba(222, 234, 250, 0.959)",
              fontFamily: "serif",
            }}
          >
            <Card.Body
              variant="top"
              style={{ ...plan?.styles_top, height: "60px" }}
            >
              {plan.title}
            </Card.Body>
            <Card.Body variant="primary">
              <Card.Title
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "40px", fontWeight: "bold" }}>
                  {plan.price}
                </div>
                <div>/month</div>
              </Card.Title>
              <Card.Subtitle
                className="mb-2 text-muted"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "13px",
                }}
              >
                {plan.type}
              </Card.Subtitle>

              <Card.Text>
                {plan.features.map((feature, idx) => (
                  <li
                    key={idx}
                    style={{
                      ...plan?.styles_bottom,
                      listStyleType: "none",
                      paddingLeft: "1em",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "10px",
                        color: "green",
                      }}
                    >
                      ✓
                    </span>{" "}
                    {/* This will be your custom dot */}
                    {feature}
                  </li>
                ))}
              </Card.Text>

              <Card.Body>
                <div className="text-center">
                  <Card.Text style={{ fontSize: "13px" }}>
                    {plan.subtype}
                  </Card.Text>
                  <Card.Link
                    variant="bottom"
                    href="#"
                    className="btn btn-primary me-2"
                  >
                    <a
                      className="forcewhite"
                      href={`${STRIPE_PROTAL}${globalUser?.user?.email}`}
                    >
                      Upgrade Plan
                    </a>
                  </Card.Link>
                </div>
              </Card.Body>
            </Card.Body>
          </Card>
        </div>
      );
    };
    ////console.log(userSubdetail);
    return (
      <div>
        <Container>
          <div>
            <div className="center_sides center_div_distributed ">
              <div>
                <div style={{ fontSize: "20px" }}>Current Subscription :</div>
              </div>
              <div>
                <div style={{ fontSize: "20px" }}>{curr_sub}</div>
              </div>
              {trial === "true" ? (
                <div>
                  <div className="timerfontgreen" style={{ fontSize: "20px" }}>
                    {" "}
                    Trial Active
                  </div>
                </div>
              ) : null}
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="center_sides center_div_distributed "
            >
              <div>
                <div style={{ fontSize: "20px" }}>Subscription Price :</div>
              </div>
              <div>
                <div style={{ fontSize: "20px" }}>{curr_planprice}</div>
              </div>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="center_sides center_div_distributed "
            >
              <div>
                <div style={{ fontSize: "20px" }}>Renewal Date :</div>
              </div>
              <div>
                <div style={{ fontSize: "20px" }}>
                  {userSubdetail?.renewaldate
                    ? convertUnixToFormattedTime(userSubdetail?.renewaldate)
                    : "Free Forever"}
                </div>
              </div>
              {userSubdetail?.cancelled === "true" ? (
                <div>
                  <div className="timerfontred" style={{ fontSize: "20px" }}>
                    {" "}
                    Will not renew
                  </div>
                </div>
              ) : null}
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="center_sides center_div_distributed "
            >
              <div lg="4" sm="8">
                <div style={{ fontSize: "20px" }}>Subscription Status :</div>
              </div>
              <div>
                <div
                  className={`status-message ${
                    curr_sub === "Free"
                      ? "active"
                      : userSubdetail?.status
                      ? "active"
                      : "inactive"
                  }`}
                >
                  {curr_sub === "Free"
                    ? "active"
                    : userSubdetail?.renewaldate
                    ? "Active"
                    : "Inactive"}
                </div>
              </div>
            </div>

            
          </div>
        </Container>
      </div>
    );
  }
  function SavePasswordAndSecurity({ defaultpass, formDataPass_ }) {
    const [formDataPass, setFormDataPass] = useState(formDataPass_);
    const [validPass, setvalidPass] = useState(false);
    const handleChangePass = (event) => {
      const { name, value } = event.target;
      setFormDataPass((prevData2) => ({
        ...prevData2,
        [name]: value,
      }));
    };
    const PassChange = {
      user_name: formData_?.firstname,
      user_email: [formData_?.email],
      subject: "Password Changed",
      send_mail: "security@betinformatics.com",
      sub_name: "Bet Informatics Security",
      message_type: "Datachange",
      user_message:
        "Your password haave just been changed. Disregard this message if this is you. If this is not you and have been locked out of your account, you can reset your password via the forget password on our login page.",
      links: [`${WEB}/signin`],
    };
    const handleSubmit = async () => {
      let isvalid = false;
      let passData = " ";
      ////console.log(formDataPass, validPass);
      if (validPass) {
        if (
          formDataPass?.oldPassword === formDataPass?.confirmOldPassword &&
          formDataPass?.oldPassword !== "" &&
          formDataPass?.confirmOldPassword !== ""
        ) {
          if (
            formDataPass?.newPassword === formDataPass?.confirmNewPassword &&
            formDataPass?.newPassword !== "" &&
            formDataPass?.confirmNewPassword !== ""
          ) {
            if (formDataPass?.newPassword !== formDataPass?.oldPassword) {
              passData = {
                password: formDataPass?.oldPassword,
                email: globalUser?.user?.email,
                newPassword: formDataPass?.newPassword,
              };
              isvalid = true;
            } else {
              toast.error("new password cannot be thesame with old password");
            }
          } else {
            toast.error("new passwords does not match or its empty");
          }
        } else {
          toast.error("old passwords does not match or its emply");
        }

        if (isvalid) {
          try {
            const pass_val = await axios.post(
              `${API_URL}/update_password`,
              passData
            );

            if (pass_val.data.status === "pass_incorrect") {
              toast.error("incorrect old password, please check and try again");
            } else {
              toast.success("password updated");
              setFormDataPass(defaultpass);
              setIsChanged(true);
              const res_ = await axios.post(`${API_URL}/send-mail`, PassChange);
            }
          } catch {
            toast.error("Its not you its us. please try again");
          }
        }
      } else {
        toast.error("new password not valid");
      }
    };
    return (
      <div>
        <Container>
          <div>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="oldPasword">
                  <Form.Label>
                    <h6>Old Password</h6>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="oldPassword"
                    placeholder="Old Password"
                    value={formDataPass?.oldPassword}
                    onChange={handleChangePass}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="confirmOldPassword">
                  <Form.Label>
                    <h6>Confirm Old Password</h6>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmOldPassword"
                    placeholder="Confirm Old Password"
                    value={formDataPass?.confirmOldPassword}
                    onChange={handleChangePass}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: "Blue",
                  }}
                >
                  Enter New Password
                </div>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="newPassword">
                  <Form.Label>
                    <h6>New Password</h6>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                    value={formDataPass?.newPassword}
                    onChange={handleChangePass}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="confirmNewPassword">
                  <Form.Label>
                    <h6>Confirm New Password</h6>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    value={formDataPass?.confirmNewPassword}
                    onChange={handleChangePass}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                    }}
                    minLength={5}
                    value={formDataPass.newPassword}
                    valueAgain={formDataPass?.confirmNewPassword}
                    onChange={(isValid) => {
                      setvalidPass(isValid);
                    }}
                  />
                </div>
              </Row>

              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  variant="button"
                  type="submit"
                  className="link"
                  onClick={() => handleSubmit()}
                  style={{
                    background: "green",
                    fontSize: "2.5vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    color: "white",
                    borderBottomRightRadius: "10px",
                    height: "6vh",
                    width: "50%",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "orange";
                  }} // change color on hover
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                >
                  <FaRegSave style={{ scale: "inherit" }} /> &nbsp; Save
                </div>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
  function SaveProfileDetail() {
    const [formData, setFormData] = useState(formData_);
    const [error, setError] = useState({});
    const [showModal, setShowModal] = useState(false);
    //const [password_, setPassword_] = useState();
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const PasswordPrompt = () => {
      ////console.log(formData);
      const passdata = { pass: "" };
      const [password_1, setPassword_1] = useState(passdata);

      const handleChange = (event) => {
        const { name, value } = event.target;
        setPassword_1((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      const handleSubmit2 = (event) => {
        event.preventDefault({ cancelable: true });
      };
      return (
        <div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "25px",
                color: "green",
                fontWeight: "bold",
              }}
            >
              Please enter your password to save data, "click Save"
            </div>

            <Form classname="submit" onSubmit={handleSubmit2}>
              <Form.Group as={Col} controlId="formGridpass">
                <Form.Control
                  type="password"
                  name="pass"
                  value={password_1?.pass}
                  placeholder="password"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Form>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "25px",
                marginTop: "20px",
              }}
            >
              <div
                onClick={() => setShowModal(false)}
                className="link"
                style={{
                  background: "orange",
                  width: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Cancel
              </div>{" "}
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div
                className="link"
                style={{
                  background: "green",
                  width: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => handleSubmit(password_1)}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      );
    };

    const ValidateFields = (form) => {
      let newError = { ...error };
      let valid = false;
      const vphone = validatePhone(form?.phonenumber);
      if (vphone !== false && form?.phonenumber) {
        valid = true;
        delete newError.phonenumber;
        setError(newError);
      } else {
        setError({
          ...newError,
          phonenumber: "Must be 10 digits",
        });
        return "Must be 10 digits";
      }
      return valid;
    };
    const handleSubmit = async (password_) => {
      const formvalid = ValidateFields(formData);
      const ProfDataChange = {
        user_name: formData?.firstname,
        user_email: [formData?.email],
        subject: "Profile Data Changed",
        send_mail: "security@betinformatics.com",
        sub_name: "Bet Informatics Security",
        message_type: "Datachange",
        user_message:
          "Some personal data in your profile have just been changed, disregard this message if this is you. If this is not you, please secure your account by changing your password.",
        links: [`${WEB}/userprofile`],
      };
      if (formvalid === true) {
        //const userInput = prompt("Please enter your password to save");

        const passData = { password: password_?.pass, email: formData?.email };
        if (password_) {
          try {
            const res_val = await axios.post(`${API_URL}/val_user`, passData);

            if (res_val?.data?.status === true) {
              try {
                const output = UpdateChangesLocal(
                  formData,
                  res_val?.data?.userinfo
                );

                const res = await axios.post(`${API_URL}/usersUpdate`, output);

                setShowModal(false);
                toast.success("Data Updated for " + formData.firstname);
                const userinfo = res_val?.data?.userinfo;
                const res_ = await axios.post(
                  `${API_URL}/send-mail`,
                  ProfDataChange
                );
                navigate("/userprofile", {
                  state: { userinfo },
                });
                setIsChanged(true);
              } catch (error) {
                console.error(error);
                toast.error("A server side error occured");
              }
            } else {
              toast.error("Password is not correct, please try again");
            }
          } catch {
            toast.error("something is wrong, please retry action");
          }
        } else {
          toast.info(
            "Data was not saved, Please enter password to save changes"
          );
        }
      } else {
        toast.error(formvalid);
      }

      /* try {
        const res = await axios.post(`${API_URL}/users`, formData);
        toast.success("Data Updated for " + formData.firstname);
        const userinfo = res.data.userData;
        navigate("/userprofile", { state: { userinfo } });
      } catch (error) {
        ////console.log(error);
      } */
    };
    const handleSubmit2 = (event) => {
      event.preventDefault();
    };
    return (
      <div>
        <Container>
          <div>
            <Form onSubmit={handleSubmit2}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstName">
                  <Form.Label>
                    <h6>First Name</h6>
                  </Form.Label>
                  <Form.Control
                    type="firstname"
                    name="firstname"
                    placeholder="First Name"
                    value={formData?.firstname}
                    onChange={handleChange}
                    required
                    readOnly
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>
                    <h6>Last Name</h6>
                  </Form.Label>
                  <Form.Control
                    type="lastname"
                    name="lastname"
                    placeholder="Last Name"
                    value={formData?.lastname}
                    onChange={handleChange}
                    required
                    readOnly
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridDOB">
                  <Form.Label>
                    <h6>Date of Birth</h6>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="dateofbirth"
                    placeholder="Date Of Birth"
                    value={formData?.dateofbirth}
                    onChange={handleChange}
                    required
                    readOnly
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPhoneno">
                  <Form.Label>
                    <h6>Phone Number</h6>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phonenumber"
                    placeholder="Phone Number"
                    value={formData?.phonenumber}
                    onChange={handleChange}
                    className={error.phonenumber ? "is-invalid" : ""}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>
                    <h6>Email</h6>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={formData?.email}
                    onChange={handleChange}
                    required
                    readOnly={globalUser?.user?.verifyEmail}
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>
                  <h6>Address</h6>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address1"
                  placeholder="Current Address"
                  value={formData?.address1}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Row className="mb-2">
                <Form.Group
                  as={Col}
                  className="mb-2"
                  controlId="formGridAddress2"
                >
                  <Form.Label>
                    <h6>Apt No/Suit No</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Apt No/Suit No"
                    value={formData?.address2}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-2"
                  controlId="formGridCountry"
                >
                  <Form.Label>
                    <h6>Country</h6>
                  </Form.Label>
                  <CountryDropdown
                    classes="form-control"
                    value={formData?.country}
                    onChange={(val) =>
                      handleChange({
                        target: {
                          name: "country",
                          value: val,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>
                    <h6>City</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData?.city}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    <h6>State</h6>
                  </Form.Label>
                  <RegionDropdown
                    classes="form-control"
                    country={formData?.country}
                    value={formData?.state}
                    onChange={(val) =>
                      handleChange({
                        target: {
                          name: "state",
                          value: val,
                        },
                      })
                    }
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>
                    <h6>Zip</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    placeholder="Zip code"
                    value={formData?.zip}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              {/* <Row>
                <Form.Group className="mb-3" controlId="datamode">
                  <Form.Label>
                    <h6>Prefered Data Mode</h6>
                  </Form.Label>
                  <Form.Select
                    name="datamode"
                    value={formData?.dataMode}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose Data Mode...</option>
                    <option>high</option>
                    <option>low</option>
                  </Form.Select>
                </Form.Group>
              </Row> */}
              <Row className="mb-2">
                <Form.Group as={Col} className="mb-2" controlId="createdDate">
                  <Form.Label>
                    <h6>Created Date</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="created Date"
                    value={formatDate2(formData?.creationDate)}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-2" controlId="UpdatedDate">
                  <Form.Label>
                    <h6>Last Updated Date</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="UpdatedDate"
                    placeholder="Last Updated Date"
                    value={formatDate2(formData?.updatedDate)}
                    readOnly
                  />
                </Form.Group>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showModal === false ? (
                  <>
                    <div
                      className="link"
                      onClick={() => {
                        //handleSubmit(formData);
                        setShowModal(true);
                      }}
                      style={{
                        background: "green",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        color: "white",
                        borderBottomRightRadius: "10px",
                        height: "6vh",
                        width: "50%",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.color = "orange";
                      }} // change color on hover
                      onMouseOut={(e) => {
                        e.target.style.color = "white";
                      }}
                    >
                      <FaRegSave style={{ scale: "inherit" }} /> &nbsp; Save
                    </div>{" "}
                  </>
                ) : (
                  <>
                    <PasswordPrompt />
                  </>
                )}
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    );
  }

  function ReferralCode() {
    const [referral, setreferral] = useState();
    const [loading, setLoading] = useState(false);
    const [iscodeerror, setISCodeError] = useState(false);
    useEffect(() => {
      const fetchData = async () => {
        setLoading(() => true);
        setISCodeError(() => false);
        try {
          const res = await axios.post(`${API_URL}/create_promo_code`, {
            email: globalUser?.user?.email,
          });

          ////console.log(res);
          if (res?.data?.status === "success") {
            setreferral(() => res?.data);
            setLoading(() => false);
          } else if (res?.data?.status === "failed") {
            setISCodeError(() => true);
            setLoading(() => false);
          }
          ////console.log(globalUser);
        } catch (error) {
          console.error(error);
          // handle error appropriately, either set error state or show notification, etc.
        }
      };
      fetchData();
    }, []);

    const textRef = useRef(null);

    const handleClick = () => {
      if (textRef.current) {
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(referral?.code?.referral_code?.code)
            .then(() => alert("Copied to clipboard"))
            .catch((err) => console.error("Could not copy text: ", err));
        } else {
          ////console.log("Clipboard API is not available");
        }
      }
    };

    if (!referral && loading) {
      return <Spinner />;
    }
    ////console.log(referral, iscodeerror);
    return (
      <div>
        {iscodeerror ? (
          <>
            <div className="center_div center_straight margin-50down">
              <div className="center_div allpad">
                You cannot generate a referral code beacause your account is not
                eligible
              </div>
              <div className="center_div allpad">
                You must have a valid paid subcription to get a referral link.
              </div>
              <div
                className="center_div allpad"
                onClick={() => navigate("/pricing")}
              >
                <div className="divorangeButton allpad link forceblack">
                  Subscribe
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="center_div center_straight">
              {loading ? (
                <Spinner />
              ) : (
                <div className="largeheadfont greenlabel">
                  {referral?.code?.referral_code?.code}{" "}
                </div>
              )}
              <div
                ref={textRef}
                onClick={() => handleClick()}
                className="divgreorgButton allpad link forceblack"
              >
                Copy code
              </div>
            </div>
            <div className="flex center_straight margin30down">
              <div className="center_div midfont bold"> Referral Earnings </div>

              <div className="flex center_sides allpad">
                <div>Total Referrals :</div>&nbsp;
                <div>
                  {referral?.earnings ? referral?.earnings?.t_refferals : 0}
                </div>
              </div>
              <div className="flex center_sides allpad">
                <div>Total Earnings :</div>
                <div className="center_div center_sides">
                  &nbsp;
                  <div>$</div>
                  <div>
                    {referral?.earnings ? referral?.earnings?.t_earnings : 0}
                  </div>
                </div>
              </div>
              <div className="flex center_sides allpad">
                <div>Paid Out :</div>&nbsp;
                <div className="center_div center_sides">
                  <div>$</div>
                  <div>
                    {referral?.earnings ? referral?.earnings?.paid_out : 0}
                  </div>
                </div>
              </div>
              <div className="flex center_sides allpad">
                <div>Balance :</div>&nbsp;
                <div className="center_div center_sides">
                  <div>$</div>
                  <div>
                    {referral?.earnings ? referral?.earnings?.balance : 0}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  const CollectTipsterDetails = () => {
    const [formTipstar, setFormTipstar] = useState();
    useEffect(() => {
      const callFunc = async () => {
        const tipstarDetail = await axios
          .post(`${API_URL}/gettipstarInfo`, {
            email: globalUser?.user?.email,
            action: "get",
          })
          .catch((error) => {
            //throw new Error('Server is unavailable, please try again later');
          });
        ////console.log(tipstarDetail);
        if (tipstarDetail?.data?.status == "success") {
          ////console.log("got it", tipstarDetail);
          setFormTipstar(tipstarDetail?.data?.data);
        }
      };
      callFunc();
    }, [globalUser]);

    useEffect(() => {
      if (formTipstar?.tipstarName !== "") {
        const timer = setTimeout(() => {
          const cllfunc = async () => {
            try {
              const res = await axios.post(`${API_URL}/gettipstarInfo`, {
                email: globalUser?.user?.email,
                action: "save",
                data: formTipstar,
              });
              ////console.log(res, formTipstar);
              if (res?.data?.status === "success") {
                ////console.log(res);
              }
            } catch (error) {
              console.error(error);
              // handle error appropriately, either set error state or show notification, etc.
            }
          };
          cllfunc();
        }, 2000); // Change this value to the amount of time you want the message to display for in milliseconds.

        return () => clearTimeout(timer);
      }
    }, [formTipstar]);
    ////console.log(formTipstar);
    if (!formTipstar) {
      return null;
    }
    ////console.log(formTipstar);
    return (
      <div className="flex align-content-start center_straight">
        <div className="flex align-content-start allpad">
          <div className="allpad">Tipstar name :</div>
          <div className="allpad flex center_sides">
            <form>
              <MDBInput
                type="text"
                name="tipstarName"
                label={isSmallScreen ? "Tipster Name" : "Ticket display name"}
                size="sm"
                value={formTipstar && formTipstar?.tipstarName}
                onChange={(e) => {
                  setFormTipstar((prev) => ({
                    ...prev,
                    tipstarName: e.target.value,
                  }));
                }}
              />
            </form>{" "}
            <div>
              <InfoTag
                tooltipContent={
                  "The name that displays on your shared tickets. If this is empty or does not exists, your first name shows up"
                }
              />
            </div>
          </div>
        </div>
        <div className="flex allpad ">
          <div className="allpad">Tipstar phrase :</div>
          <div className="allpad flex center_sides">
            <form>
              <MDBTextArea
                name="tipstarPhrase"
                value={formTipstar && formTipstar?.tipstarPhrase}
                label={
                  isSmallScreen ? "Tipster phrase" : "Ticket display phrase"
                }
                id="tipstarphrase"
                rows="{4}"
                onChange={(e) => {
                  setFormTipstar((prev) => ({
                    ...prev,
                    tipstarPhrase: e.target.value,
                  }));
                }}
              />
            </form>{" "}
            <div>
              <InfoTag
                tooltipContent={
                  "this is the slogan that accompanies your ticket to make it uniqe. This also makes followers remember your tips."
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleTipstarData = () => {};
  ////console.log(globalUser);
  return (
    <div>
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen} />}
      <IndexHeader
        radioValue={radioValue}
        itemDisplay={setItemDisplay}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen}
        isLogedin={true}
        issubscribed={issubscribed}
        setIsOpen={setIsOpen}
      />
      <div classname="useMaxSpace">
        <MDBContainer fluid className="usemaxSpace">
          <MDBRow className="justify-content-center">
            <MDBCol>
              <div
                className="usemaxSpace"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  minHeight: "300px",
                }}
              >
                <MDBNavbar expand="lg" light bgColor="light"></MDBNavbar>
                <section className="text-center  usemaxSpace">
                  <div
                    className=" bg-image usemaxSpace"
                    style={{
                      height: isSmallScreen ? "100px" : "200px",
                      backgroundImage: `url(${userBackground ?? bgimage })`,
                      zIndex: "-1",
                    }}
                  ></div>

                  <div
                    className="pb-0"
                    style={{
                      backgroundColor: "background-color: hsl(0, 0%, 98%)",
                    }}
                  >
                    <MDBContainer>
                      <div
                        className={`${
                          isSmallScreen
                            ? "center_div center_straight usemaxSpace"
                            : "d-flex justify-content-between align-items-center usemaxSpace"
                        }`}
                      >
                        <div
                          className={`${
                            isSmallScreen ? "margin-10down" : null
                          }`}
                        >
                          <img
                            src={userProfile ?? care
                            }
                            className="img-fluid rounded-circle float-none float-md-start me-4 mb-3 link"
                            alt=""
                            style={{
                              width: "168px",
                              marginTop: "-90px",
                              border: "4px solid hsl(0, 0%, 98%)",
                            }}
                          />
                          <div className=" link" style={{ zIndex: "-1" }}>
                            <MDBPopconfirm
                              btnClassName="me-1"
                              placement="bottom"
                              confirmBtnText="Edit"
                              onConfirm={() => navigate("/editImages")}
                              size="sm"
                              btnChildren={
                                <div>
                                  <FaEdit
                                    size={30}
                                    className="link forcewhite"
                                  />
                                </div>
                              }
                            >
                              <MDBPopconfirmMessage size="sm">
                                Edit Images
                              </MDBPopconfirmMessage>
                            </MDBPopconfirm>
                          </div>
                        </div>
                        <div>

                        
                        <div
                          className={`${
                            isSmallScreen
                              ? "margin-10down center_div center_sides"
                              : "center_div center_sides"
                          }`}
                        >
                          <MDBBtn
                            rounded
                            color="secondary"
                            active
                            onClick={() =>
                              navigate("/publicProfile", {
                                state: {
                                  publicProfile:
                                    globalUser?.user?.publicProfile,
                                },
                              })
                            }
                          >
                            <div style={{ textTransform: "none" }}>
                              Public Profile
                            </div>
                          </MDBBtn>{" "}
                          &nbsp;&nbsp;&nbsp;
                          <MDBBtn
                            rounded
                            color="secondary"
                            active
                            onClick={() => navigate("/myMessages")}
                          >
                            <div style={{ textTransform: "none" }}>
                              Messages
                            </div>
                          </MDBBtn>{" "}
                          &nbsp;&nbsp;&nbsp;
                          <MDBBtn rounded>
                            <div style={{ textTransform: "none" }}>
                              Followers
                            </div>
                          </MDBBtn>
                        </div>
                        <div className="center_div_distributed center_sides allpad">
                        <div className="bold">
                          {userSubdetail?.ticketCount?.length ?? 0}{" "}
                          {" Tickets"}
                        </div>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <div className="bold">
                          {userSubdetail?.followers?.length ?? 0}{" "}
                          {" Followers"}
                        </div>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <div className="bold">
                          {userSubdetail?.following?.length ?? 0}{" "}
                          {" Following"}
                        </div>
                      </div>
                        </div>
                      </div>
                      <MDBRow>
                        <MDBCol>
                          <CollectTipsterDetails />
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </div>
                </section>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <Container style={{ marginBottom: "30px", fontFamily: "serif" }}>
        <div>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col>
                    <div className="light">
                      <Breadcrumb className="rounded-3 p-3 mb-4">
                        <Breadcrumb.Item>
                          <a href="/">Home</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <a href="/userprofile">User</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>User Profile</Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <Card className="mb-4">
                      <Card.Body className="text-center">
                        <CiUser size={100} />

                        <p className="text-muted mb-1">
                          {globalUser.user && globalUser.user.email}
                        </p>
                        <p className="text-muted mb-1">
                          {globalUser.user && globalUser.user.firstname}
                        </p>
                      </Card.Body>
                    </Card>

                    <MDBCard className="mb-4 mb-lg-0">
                      <MDBCardBody className="p-0">
                        <MDBListGroup flush className="rounded-3">
                          <MDBListGroupItem
                            className="d-flex link justify-content-between align-items-center p-3"
                            onClick={() => setItemDisplay("Profile Data")}
                            style={{ color: "black" }}
                          >
                            <ImProfile style={{ scale: "inherit" }} />
                            <MDBCardText>Profile Data</MDBCardText>
                          </MDBListGroupItem>
                          <MDBListGroupItem
                            className="d-flex link justify-content-between align-items-center p-3"
                            onClick={() =>
                              setItemDisplay("Password and Security")
                            }
                            style={{ color: "black" }}
                          >
                            <RiLockPasswordLine style={{ scale: "inherit" }} />
                            <MDBCardText>Password and Security</MDBCardText>
                          </MDBListGroupItem>
                          <MDBListGroupItem
                            className="d-flex link justify-content-between align-items-center p-3"
                            onClick={() => setItemDisplay("Subscriptions")}
                            style={{ color: "black" }}
                          >
                            <MdOutlineSubscriptions
                              style={{ scale: "inherit" }}
                            />
                            <MDBCardText>Subscriptions</MDBCardText>
                          </MDBListGroupItem>
                          <MDBListGroupItem
                            className="d-flex link justify-content-between align-items-center p-3"
                            onClick={() => setItemDisplay("Payment Details")}
                            style={{ color: "black" }}
                          >
                            <MdPayment style={{ scale: "inherit" }} />
                            <MDBCardText>Payment Details</MDBCardText>
                          </MDBListGroupItem>
                          <MDBListGroupItem
                            className="d-flex link justify-content-between align-items-center p-3"
                            onClick={() => setItemDisplay("Referral Code")}
                            style={{ color: "black" }}
                          >
                            <BsCashCoin style={{ scale: "inherit" }} />
                            <MDBCardText>Referral Code</MDBCardText>
                          </MDBListGroupItem>
                          <MDBListGroupItem
                            onClick={() => HandleLogout()}
                            className="d-flex link justify-content-between align-items-center p-3"
                            style={{ color: "red" }}
                          >
                            <IoIosLogOut style={{ scale: "inherit" }} />
                            <MDBCardText>Logout</MDBCardText>
                          </MDBListGroupItem>
                        </MDBListGroup>
                      </MDBCardBody>
                    </MDBCard>
                  </Col>
                  <Col>
                    <Row>
                      <Container>
                        <div>
                          <Card>
                            <Card.Header
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "30px",
                                fontWeight: "bold",
                              }}
                            >
                              {itemDisplay}
                            </Card.Header>
                          </Card>
                        </div>
                      </Container>
                    </Row>
                    <Row>
                      <Container>
                        <div>
                          <Card>
                            <Card.Body>
                              {itemDisplay === "Profile Data" ? (
                                <SaveProfileDetail />
                              ) : itemDisplay === "Password and Security" ? (
                                <SavePasswordAndSecurity
                                  defaultpass={defaultpass}
                                  formDataPass_={formDataPass_}
                                />
                              ) : itemDisplay === "Subscriptions" ? (
                                <ShowSubscription />
                              ) : itemDisplay === "Payment Details" ? (
                                <PaymentDetails />
                              ) : itemDisplay === "Referral Code" ? (
                                <ReferralCode />
                              ) : null}
                            </Card.Body>
                          </Card>
                        </div>
                      </Container>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </Container>

      <FooterAll page={"userprofile"} />
      <FooterNav />
    </div>
  );
}

{/* <Row style={{ marginTop: "40px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  color: "green",
                  fontWeight: "bold",
                  marginBottom: "40px",
                }}
              >
                Upgrade Your Subscription
              </div>
            </Row> */}
            {/* <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {curr_sub === "Rookie" ? (
                <>
                  <Col>
                    <div
                      style={{
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DisplayOtherSub plan={planmid} />
                    </div>
                  </Col>
                  
                </>
              ) : curr_sub === "Enthusiast" ? (
                <>
                  
                </>
              ) : !formDataSub?.subscription ? (
                <>
                  <Col>
                    <div
                      style={{
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DisplayOtherSub plan={plansbasic} />
                    </div>
                  </Col>{" "}
                  <Col>
                    <div
                      style={{
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DisplayOtherSub plan={planmid} />
                    </div>
                  </Col>{" "}
                  
                </>
              ) : (
                <>
                  <div>You are on our Highest Plan, Happy Winning!</div>
                </>
              )}
            </Row>
            <Row>
              <div
                className="link"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "orange",
                  fontSize: "20px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                {" "}
                <a
                  style={{ textDecoration: "none" }}
                  href={`${STRIPE_PROTAL}${globalUser?.user?.email}`}
                >
                  Manage Subscription
                </a>
              </div>
            </Row> */}
            {/* {issubscribed === "Yes" ? (
              <Row>
                <div
                  onClick={() =>
                    handleSubChange(
                      "cancel",
                      globalUser,
                      navigate,
                      HandleLogout,
                      setIsSubscribed
                    )
                  }
                  className="link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "orange",
                    fontSize: "20px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  {" "}
                  Cancel Subscription
                </div>
              </Row>
            ) : issubscribed === "No" ? (
              <Row>
                <div
                  onClick={() =>
                    handleSubChange(
                      "activate",
                      globalUser,
                      navigate,
                      HandleLogout,
                      setIsSubscribed
                    )
                  }
                  className="link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "green",
                    fontSize: "20px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  {" "}
                  Activate Subscription
                </div>
              </Row>
            ) : null} */}