import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";

export default function LgHeaderGoals({ goals, type }) {
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []);
  function getColorForNoGoal(data, name) {
    // Find the object with the given name
    const noGoal = data.find((item) => item.name === name);

    if (!noGoal) {
      // If the name is not found, return a default color
      return "gray";
    }

    // Get the value of "No Goals"
    const noGoalValue = noGoal.value;

    // Find the maximum and minimum values in the data
    const max = Math.max(...data.map((item) => item.value));
    const min = Math.min(...data.map((item) => item.value));

    if (noGoalValue === max) {
      // If "No Goals" has the highest value, return green
      return "green";
    } else if (noGoalValue === min) {
      // If "No Goals" has the lowest value, return red
      return "red";
    } else {
      // Otherwise, return yellow
      return "orange";
    }
  }
  const centerData = "Avg" + " Goals ";
  const centerData2 = Math.round(goals?.[type]?.average)
  const under = "Un" + String(goals?.[type]?.measure) + ".5" + " Goals";
  const over = "Ov" + String(goals?.[type]?.measure) + ".5" + " Goals";
  // Sample data
  const data = [
    { name: under, value: goals?.[type]?.under },
    { name: over, value: goals?.[type]?.over },
    { name: "No Goals", value: goals?.[type]?.zero },
  ];
  const COLORS = [
    getColorForNoGoal(data, under),
    getColorForNoGoal(data, over),
    getColorForNoGoal(data, "No Goals"),
  ];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const scy = cx + 40;
    return (
      <g>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: "14Px", fontFamily: "serif" }}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text
          x={cx}
          y={cy}
          textAnchor="middle"
          fill="grey"
          style={{ fontSize: "14Px", fontFamily: "serif" }}
        >
          {centerData}
        </text>
        <text
          x={cx}
          y={scy}
          textAnchor="middle"
          fill="grey"
          style={{ fontSize: "14Px", fontFamily: "serif" }}
        >
          {centerData2}
        </text>
      </g>
    );
  };
  const customLegendStyle = {
    fontSize: "12px", // Change the font size to your desired value
    fontFamily: "serif", // Change the font family to your desired font
    justifyContent: "center",
  };
  ////console.log(goals, type);
  return (
    <div>
      <PieChart width={180} height={216}>
        <Pie
          data={data}
          cx={isMidScreen ? 77 : 90}
          cy={isMidScreen? 100:108}
          innerRadius={33}
          outerRadius={63}
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend wrapperStyle={customLegendStyle} />
      </PieChart>
    </div>
  );
}
