import React from "react";
import { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  useNavigate,
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import { newdate, renewalDate } from "./UserProfile";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import FooterAll from "./Footer";
import EntryHeader from "./EntryHeader";

const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const STRIP_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(STRIP_KEY);

function Payments() {
  const navigation = useNavigate();
  const location = useLocation();
  const plan = location?.state?.plan || " ";
  const userdat_ = location?.state?.userinfo || " ";
  const defaultForm = {};
  const [formData, setFormData] = useState(defaultForm);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [stateuserinfo, setstateinfo] = useState(plan);
  const [userdat, setuserdat] = useState(userdat_);
  const [userInfo, setUserInfo] = useState();

  ////console.log("in signin", globalUser, userdat, plan);
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        setUserInfo(ckUser.data.user);
      }
      if (plan !== " ") {
        const data = ckUser.data.user;
        data.user.intentplan = plan;
        UpdateLocal({
          action: "save",
          data: data,
        });
        updateGlobalUser(data);
        ////console.log(data);
      } else {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.post(`${API_URL}/create-checkout-session`, {
        stripe_price:
          globalUser?.user?.currCountry === "Nigeria"
            ? globalUser?.user?.intentplan?.AfristripePrice
            : globalUser?.user?.intentplan?.WorldstripePrice,
        email: globalUser?.user?.email,
      });
      ////console.log(res);
      if (res && res?.data?.status === "success") {
        setClientSecret(res?.data?.clientSecret);
      }
    };
    fetchData();
  }, [updateGlobalUser]);
  if (!plan || !userdat) {
    return null;
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  ////console.log(userdat);
  const handleSubscribe = async () => {
    const usersub = {
      user: userdat,
      subData: {
        price: stateuserinfo.price,
        subtype: stateuserinfo.title,
        paymentamount: stateuserinfo.paymentAmount,
        status: "active",
        renewalPermission: "active",
        paymentDate: newdate(),
        renewalDate:
          stateuserinfo?.title === "Rookie" ? renewalDate(1) : renewalDate(3),
      },
    };
    ////console.log(usersub);
    try {
      const res = await axios.post(`${API_URL}/updateUsersSub`, usersub);
      ////console.log(res);
      if (res.data.status === "updated") {
        toast.success("Subscription successful");
        const oldstate = userInfo;
        oldstate.user.subscription = usersub.subData;

        UpdateLocal({ action: "save", data: oldstate });

        navigation("/", { state: { userinfo: oldstate?.user } });
      } else {
        toast.error("some kind of error occurred, try again");
      }
    } catch (error) {
      //console.error(error);
      toast.error(
        "An error occurred while communicating with the server. login to try again"
      );
      navigation("/signin");
    }
  };

  ////console.log(globalUser);
  return (
    <div className="light">
      <IndexHeader />

      <Container
        className="margin30down "
        variant="light"
      >
        {globalUser?.user?.subscription?.status === "active" ? (
          <div className="center_div center_straight margin-50down">
            <div className="center_div margin-50down">Already subscribed</div>{" "}
            <div className="center_div center_sides margin-50down">
              <div className="allpad">Manage your subscription here : </div>
              <div className="divgreorgButton link forceblack allpad">
                {" "}
                {"My Subscriptions"}
                
              </div>
            </div>
          </div>
        ) : (
          <div id="checkout">
            {clientSecret && (
              <EmbeddedCheckoutProvider
                className=""
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            )}
          </div>
        )}
        {/* <div className="d-flex justify-content-between align-items-center">
          <Button onClick={() => handleSubscribe()}>Subscribe</Button>
        </div> */}
      </Container>
      <FooterAll />
      <ToastContainer position="bottom-left" />
    </div>
  );
}

export default Payments;
