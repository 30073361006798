import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EmptyHeader from "./Emptyheader";
import axios from "axios";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import FooterAll from "./Footer";
import "../css/ThemeCss.css";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
export default function PromptVerifyEmail() {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [linkMessage, setLinkMessage] = useState("Link active");

  const location = useLocation();
  const user = location?.state?.userinfo || null;
  const [usedata, setUseData] = useState({ user: user });
  const [count, setCount] = useState(360);
  const navigation = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (user) {
        setUseData({ user: user });
      } else if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
        setUseData(ckUser.data.user);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (count > 0) {
      const id = setInterval(() => {
        setCount((currentCount) => currentCount - 1);
      }, 1000);

      // Clean up function is returned by useEffect which clears the interval.
      return () => clearInterval(id);
    } else {
      setLinkMessage("Link expired");
    }
  }, [count]);
  ////console.log(usedata);
  const resendEmail = async () => {
    let now = new Date();
    let senttime = Math.floor(now.getTime() / 1000);
    let fexptime = new Date(now.getTime() + 6 * 60000);
    let exptime = Math.floor(fexptime.getTime() / 1000);
    const datatoencode = {
      user_name: usedata?.user?.firstname,
      user_email: [usedata?.user?.email],
      senttime: senttime,
      exptime: exptime,
      session: usedata?.user?.auth?._id,
    };
    const encoder = await axios.post(`${API_URL}/pushtemp`, {
      type: "save",
      tostore: datatoencode,
    });
    const Emaildata = {
      user_name: usedata?.user?.firstname,
      user_email: [usedata?.user?.email],
      subject: "Email Verification",
      send_mail: "info@betinformatics.com",
      sub_name: "Bet Informatics",
      message_type: "emailVerification",
      links: [`${WEB}/verifyEmail?data=${encoder?.data?.savedData}`],
    };
    ////console.log(Emaildata);
    setCount(360);
    setLinkMessage("Link active");
    const res = await axios.post(`${API_URL}/send-mail`, Emaildata);
  };

  return (
    <div
      style={{
        background: "white",
        fontFamily: "serif",
      }}
    >
      <EmptyHeader />

      <Container className="appear_center ">
        <div>
          <div className="center_div infoFont margin-30down">
            Verification Email have been sent to your email &nbsp;
            <a href="https://mail.google.com/mail/u/0/">
              {globalUser?.user?.email}{" "}
            </a>
            .
          </div>
          <div className="center_div infoFont margin-30down">
            If the verification email is not in your inbox, check spam folders.
          </div>
          <div className="center_div infoFont margin-30down">
            Cant find link it or has expired? Click resend button below.
          </div>
          <div className="center_div infoFont margin-30down">
            <Row>
              <Col>
                <div
                  className="link smallbuton  divgreenButton"
                  onClick={() => resendEmail()}
                >
                  Resend Link
                </div>
              </Col>
              <Col>
                <div
                  className="link smallbuton  divorangeButton"
                  onClick={() => navigation("/userprofile")}
                >
                  Change Email
                </div>
              </Col>
            </Row>
          </div>
          <div className="center_div infoFont margin-30down">
            Sent link would expire in &nbsp;{" "}
            <span className="timerfont">{count}</span>&nbsp; seconds: &nbsp;
            {linkMessage === "Link expired" ? (
              <span className="redlabel">{linkMessage}</span>
            ) : (
              <span className="greenlabel">{linkMessage}</span>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
