import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Button,
  NavDropdown,
  Table,
  Container,
  Nav,
  Row,
  NavLink,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import Spinner from "./Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import { Errorlog } from "./Utils";
import Tickets from "./UserTickets";

import "../css/LeagueNav.css";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { CatchedStoraged } from "./manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
function LeagueNav({
  islgedin,
  issubed,
  setticketactive,
  setShows,
  setleague = () => {},
}) {
  const [countryCount, setCountryCount] = useState(50);
  const [leagueCount, setLeagueCount] = useState(7);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const [leagues2, setleague2] = useState();
  // In your component state definition:
  const [countryClickedIndex, setCountryClickedIndex] = useState();

  useEffect(() => {
    const get_lndata = async () => {
      let options = {
        
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 24 * 60 * 60,
        },

        seconds: 24 * 60 * 60,
      };
      let url = "/league_nav";
      let indexdb = "league_nav";
      let SaveKey = "All" 
      try {
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        
        /* const res = await axios
          .get(`${API_URL}/league_nav`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch((error) => {}); */

        ////console.log(res)
        const firstDict = res;
        if (firstDict?.status === "success") {
          ////console.log(JSON.parse(firstDict?.league_nav));
          setData(JSON.parse(firstDict?.league_nav));
          setLoading(false);
        } else {
          throw new Error("Unsuccessful request");
        }
      } catch {
        //Errorlog("error","error league nav","from team stats")
      }
    };
    get_lndata();
  }, [setCountryClickedIndex]);

  const getLn = async (country) => {
    let options = {
        body: JSON.stringify({ country: country }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "max-age=" + 24 * 60 * 60,
      },

      seconds: 24 * 60 * 60,
    };
    let url = "/league_nav_simple";
    let indexdb = "league_nav_simple";
    let SaveKey = "" + country
    try {
      
      const res = await CatchedStoraged(url, options, SaveKey, indexdb);
     /*  const res = await axios
      
        .post(
          `${API_URL}/league_nav_simple`,
          JSON.stringify({ country: country }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {}); */

      ////console.log(res)
      const firstDict = res;
      if (firstDict?.status === "success") {
        ////console.log(JSON.parse(firstDict?.league_nav));

        const data = JSON.parse(firstDict?.league_nav)
          
        
        return data?.country_info?.[country]?.league_info
      } else {
        throw new Error("Unsuccessful request");
      }
    } catch {
      //Errorlog("error","error league nav","from team stats")
    }
  };

  if (!data || loading) {
    <Spinner />;
  }
  const handleNavClick = async(country)=>{
    ////console.log(country)
    const d = await getLn(country)
    ////console.log(d)
    if (d){
      ////console.log(d)
      setleague2(d);
    }else
    ""
  }
  ////console.log(data);
  // Then in your map function:
  const top = data?.top_team;
  const other = data?.country_info;
  ////console.log(top);
  const HandleSeeMore = (val) => {
    //let currValue = monthCount;

    if (val === "seemore" && countryCount > 49) {
      setCountryCount(countryCount + 50);
    }
    if (val === "seeless" && countryCount > 99) {
      setCountryCount(countryCount - 50);
    }
  };
  const NavLeague = (league_id, league_name, country_name) => {
    const trns_data = {
      l_id: league_id,
      l_n: league_name,
      ctry: country_name,
    };
    setleague(league_id);
    navigation("/league/" + league_name, { state: trns_data });
  };
  const HandleSeeMoreLg = (val) => {
    //let currValue = monthCount;

    if (val === "seemore" && leagueCount > 6) {
      setLeagueCount(leagueCount + 7);
    }
    if (val === "seeless" && leagueCount > 13) {
      setLeagueCount(leagueCount - 7);
    }
  };
  function SeeMore({ sender }) {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <Container style={{ width: "100%", fontSize: "10px" }}>
          <Row className="justify-content-between align-items-center">
            <Col
              md={6}
              sm={5}
              xs={4}
              className="mb-2"
              onClick={
                sender === "country"
                  ? () => HandleSeeMore("seeless")
                  : () => HandleSeeMoreLg("seeless")
              }
            >
              <div className="divorangeButton tinybuton">{"< Less"}</div>
            </Col>
            <Col
              md={6}
              sm={7}
              xs={8}
              className="mb-2"
              onClick={
                sender === "country"
                  ? () => HandleSeeMore("seemore")
                  : () => HandleSeeMoreLg("seemore")
              }
            >
              <div className="divgreenButton tinybuton">{"More >"}</div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  const ShowLeagues = ({ country }) => {
    useEffect(() => {
    
      
    },[setleague2])

    ////console.log(country);
    const leagues = leagues2;
    ////console.log(leagues2);
    return (
      <div>
        <Container>
          {leagues &&
            Object.keys?.(leagues)?.map((item, index) => {
              if (index < leagueCount) {
                return (
                  <>
                    <Row
                      key={item}
                      className="containerBg align-items-center"
                      onClick={() =>{
                        NavLeague(
                          leagues?.[item]?.season?.id,
                          leagues?.[item]?.league_name,
                          leagues?.[item]?.country
                        );
                        handleNavClick(leagues?.[item]?.country,)

                      }
                        
                      }
                    >
                      <Col md={3} sm={5} className="mb-2">
                        {
                          <img
                            src={leagues?.[item]?.image}
                            width="20"
                            height="15"
                            alt={leagues?.[item]?.league_name}
                          />
                        }
                      </Col>
                      <Col md={9} sm={7} className="mb-2">
                        {item}
                      </Col>
                    </Row>
                  </>
                );
              } else {
                return null;
              }
            })}
        </Container>
        <SeeMore sender={"leagues"} />
      </div>
    );
  };
  if (!countryCount || !leagueCount) {
    return null;
  }
  ////console.log(other);
  return (
    <div className="align-items-center w-100">
      <div className="comp_top">
        <Container>
          {countryClickedIndex?.clicker === "other"
            ? countryClickedIndex !== null && (
                <>
                  <h6>My Leagues</h6>
                  <Row className=" justify-content-center align-items-center">
                    <Col md={3} sm="5" className="mb-2">
                      {other && (
                        other && <ReactCountryFlag
                          countryCode={
                            other?.[countryClickedIndex?.item]?.iso || other?.[countryClickedIndex?.item]?.county_iso
                          }
                          svg
                          style={{
                            fontSize: "2em",
                            lineHeight: "2em",
                          }}
                          aria-label={
                            other &&
                            other?.[countryClickedIndex?.item]?.country
                          }
                        />
                      )}
                    </Col>
                    <Col md={9} sm="7" className="mb-2">
                      <h6>
                        {
                          other?.[countryClickedIndex?.item]?.country || other?.[countryClickedIndex?.item]?.country}
                      </h6>
                    </Col>
                  </Row>
                  {leagues2 && <ShowLeagues
                    country={
                      other && other?.[countryClickedIndex?.item]?.country
                    }
                    
                  />}
                </>
              )
            : countryClickedIndex !== null && (
                <>
                  <h6>My Leagues</h6>
                  <Row className=" justify-content-center align-items-center">
                    <Col md={3} sm={5} xs={4} className="mb-2">
                      {other && (
                        <ReactCountryFlag
                          countryCode={
                            other &&
                            other?.[countryClickedIndex?.item]?.iso 
                          }
                          svg
                          style={{
                            fontSize: "2em",
                            lineHeight: "2em",
                          }}
                          aria-label={
                            other &&
                            other?.[countryClickedIndex?.item]?.country_info
                              ?.country
                          }
                        />
                      )}
                    </Col>
                    <Col md={9} sm={7} xs={8} className="mb-2">
                      <h6>
                        {other &&
                          other?.[countryClickedIndex?.item]?.country}
                      </h6>
                    </Col>
                  </Row>
                  <ShowLeagues
                    country={
                      other && other?.[countryClickedIndex?.item]?.country
                    }
                    funct={getLn}
                  />
                </>
              )}
        </Container>
      </div>
      <div className="comp_top">
        <Container>
          <Row className=" justify-content-center align-items-center">
            <Col>
              <h6>Top Leagues</h6>
            </Col>
          </Row>
          {top &&
            Object.keys(top).map((item, index) => (
              <Row
                key={index}
                className="containerBg align-items-center"
                onClick={
                  () => {
                    setCountryClickedIndex({
                      index: index,
                      item: top?.[item]?.country,
                      clicker: "top",
                    });
                    handleNavClick(top?.[item]?.country)}
                }
              >
                <Col md={3} sm={5} xs={4} className="mb-2">
                  {top && (
                    <ReactCountryFlag
                      countryCode={top?.[item]?.county_iso}
                      svg
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label={top?.[item]?.league_name}
                    />
                  )}
                </Col>
                <Col md={9} sm={7} xs={8} className="mb-2">
                  {item}
                </Col>
              </Row>
            ))}
        </Container>
      </div>
      <div>
        <Tickets
          islgedin={islgedin}
          issubed={issubed}
          setticketactive={setticketactive}
          setShows={setShows}
        />
      </div>

      <div className="comp_top">
        <Container>
          <Row className=" justify-content-center align-items-center">
            <Col>
              <h6>Countries</h6>
            </Col>
            {other &&
              Object.keys(other)
                .sort()
                .map((item, index) => {
                  return (
                    <div key={index} className="containerBg align-items-center">
                      <Row
                        key={index}
                        className="containerBg align-items-center"
                        onClick={
                          () =>{
                            setCountryClickedIndex({
                              index: index,
                              item: item,
                              clicker: "other",
                            });
                            handleNavClick(item)}
                        }
                      >
                        <Col md={3} sm={4} xs={4} className="mb-2">
                          {top && (
                            <ReactCountryFlag
                              countryCode={other?.[item]?.iso}
                              svg
                              style={{
                                fontSize: "2em",
                                lineHeight: "2em",
                              }}
                              aria-label={other?.[item]?.country}
                            />
                          )}
                        </Col>
                        <Col md={9} sm={8} xs={8} className="mb-2">
                          {item}
                        </Col>
                      </Row>
                      {countryClickedIndex?.index === index &&
                        countryClickedIndex?.clicker === "other" && (
                          <ShowLeagues
                            country={
                              other &&
                              other?.[countryClickedIndex?.item]?.country
                            }
                          />
                        )}
                    </div>
                  );
                })}
          </Row>
        </Container>
        <SeeMore sender={"country"} />
      </div>
    </div>
  );
}

export default LeagueNav;
