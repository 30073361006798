import React, { useContext, useEffect, useState } from "react";
import { BiHomeSmile } from "react-icons/bi";
import { CgFeed } from "react-icons/cg";
import { TiMessages } from "react-icons/ti";
import { RiFileList3Line } from "react-icons/ri";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import care from "../images/unisex.jpg";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { googleLogout } from "@react-oauth/google";
import { IoStatsChartOutline } from "react-icons/io5";
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;
const FooterNav = (props) => {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const navigation = useNavigate();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (windowWidth >= 1000) {
    return null;
  }
  const HandleLogout = () => {
    UpdateLocal({ action: "delete", data: { rmKeys: ["user"] } }).then(
      (result) => {
        //setIsLogedin?.(() => false);
        //setIsSubscribed?.(() => false);
        updateGlobalUser({});
        googleLogout()
        navigation("/");
      }
    );
  };
  const UserLoggedIn = () => {
    const [authorProfile, setUserProfile] = useState();
    useEffect(() => {
      ////console.log(useMatches,useMatches?.ticket_meta?.author);
      async function fetchBackground() {
        try {
          const response2 = await fetch(
            `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`
          );
          if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
          }
          const blob2 = await response2.blob();
          const base64data2 = await blob2.text(); // Convert the blob to a string
          setUserProfile(base64data2);
          ////console.log("finalValue");
        } catch (error) {
          //console.error(error);
        }
      }
      fetchBackground();
    }, [globalUser?.user?.email]);

    return (
      <div className="center_div center_straight">
        <img
          src={authorProfile ?? globalUser?.user?.profile ?? care}
          className="rounded-circle"
          alt=""
          style={{
            width: "40px",
            border: "1px solid hsl(0, 0%, 98%)",
          }}
        />

        <NavDropdown
          title={globalUser.user && globalUser.user.firstname}
          id="basic-nav-dropdown"
          className="align-items-center custom_drop_new"
        >
          <NavDropdown.Item
            onClick={() => {
              if (typeof props?.itemDisplay === "function") {
                props?.itemDisplay("Profile Data");
              }
              navigation("/userprofile");
            }}
          >
            Profile
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigation("/myMessages")}>
            Messages
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={()=> navigation("/publicProfile", {
                                state: {
                                  publicProfile:
                                  globalUser?.user?.publicProfile ?? globalUser?.user?.email,
                                }})}
          >
            Tickets
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigation("/predictions")}>
            Prediction Feed
          </NavDropdown.Item>
          <NavDropdown.Item>Followers</NavDropdown.Item>

          <NavDropdown.Item
            onClick={() => {
              if (typeof props?.itemDisplay === "function") {
                props.itemDisplay("Subscriptions");
              }
              navigation("/userProfile", {
                state: { data: "Subscriptions" },
              });
            }}
          >
            Supscriptions
          </NavDropdown.Item>
          <NavDropdown.Item onClick={HandleLogout}>Logout</NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  };
  const GuestLoginSignup = () => {
    return (
      <div className="center_div center_sides">
        <div className=" smartTableButton link forceblack">
          <div
            className=" allpad"
            onClick={() => navigation("/SignIn")}
          >
            Login
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div className=" smartTableButton link forceblack">
          <div
            className="allpad"
            onClick={() => navigation("/Signup")}
          >
            Signup
          </div>
        </div>
      </div>
    );
  };
  const RightTabHeader = () => {
    return (
      <div className="">
        {globalUser?.user && globalUser?.user?.firstname ? (
          globalUser?.user?.firstname === "Guest" ? ( // Use a nested ternary operator for conditional rendering
            <GuestLoginSignup />
          ) : (
            <UserLoggedIn /> // Add JSX elements to render in the "else" block
          )
        ) : (
          <GuestLoginSignup />
        )}
      </div>
    );
  };
//console.log(globalUser)
  return (
    <div
      style={{ width: "100%", position: "fixed", bottom: 0 }}
      className="mainFont"
    >
    {globalUser?.user && globalUser?.user?.firstname !=="Guest"? <>
      <div className="center_div_distributed center_sides webButton allpad">
        <div className="center_div center_straight link forceblack">
          <div onClick={()=> navigation("/")}>
            <BiHomeSmile size={30} />
          </div>
        </div>
        
        <div className="center_div center_straight link forceblack">
          <div onClick={()=> navigation("/myMessages")}>
            <TiMessages size={30} />
          </div>
        </div>
        <div className="center_div center_straight link forceblack">
          <div onClick={()=> navigation("/predictions")}>
            <RiFileList3Line size={30} />
          </div>
        </div>
        <div className="center_div center_straight link forceblack">
          <div onClick={()=> navigation("/publicProfile", {
                                state: {
                                  publicProfile:
                                  globalUser?.user?.publicProfile ?? globalUser?.user?.email,
                                }})}>
            <IoStatsChartOutline size={30} />
          </div>
        </div>
        <div className="center_div center_straight">
          <div>
            <RightTabHeader />
          </div>
        </div>
      </div>
    </> : <>
    <div className="center_div_distributed center_sides webButton allpad">
        <div className="center_div center_straight link forceblack">
          <div onClick={()=> navigation("/")}>
            <BiHomeSmile size={30} />
          </div>
        </div>
        
        
        <div className="center_div center_straight link forceblack">
          <div onClick={()=> navigation("/predictions")}>
            <RiFileList3Line size={30} />
          </div>
        </div>
        
        <div className="center_div center_straight">
          <div>
            <RightTabHeader />
          </div>
        </div>
      </div>
    </>}
      
    </div>
  );
};

export default FooterNav;
