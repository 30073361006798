import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  Button,
  Col,
  Container,
  Form,
  Row,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import FooterAll from "./Footer";
import EntryHeader from "./EntryHeader";
import { BsPatchQuestion } from "react-icons/bs";
import { RiQuestionAnswerLine } from "react-icons/ri";
import Fixture from "../images/fixtures.png";
import MatchStats from "../images/MatchStats.png";
import DataMode from "../images/DataMode.png";
import Tickets from "../images/Tickets.png";
import { SideNavPage } from "./Utils";
import FooterNav from "./SmallScreenNav";

const API_URL = process.env.REACT_APP_API_URL;

function HowTo() {
  const navigation = useNavigate();
  const defaultForm = { email: "", password: "" };
  const [formData, setFormData] = useState(defaultForm);
  const [showFooter, setShowFooter] = useState(false);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  ////console.log("Contact Us", globalUser);
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    function handleScroll() {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowFooter(isAtBottom);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {};

  return (
    <div className="light">
    {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
        isLogedin={true}
        issubscribed={true}
        />

      <Container>
        <div className="paragrph_font">
          <div className="header_font_size center_div">
            <div
              style={{
                fontSize: isSmallScreen ? "25px" : isMidScreen ? "40" : "70px",
              }}
            >
              How to read Tables and Charts
            </div>
          </div>
          <div>
            <p className="largeheadfont">
              <div
                style={{
                  fontSize: isSmallScreen
                    ? "20px"
                    : isMidScreen
                    ? "30"
                    : "50px",
                }}
              >
                Headers
              </div>
            </p>
            <div className="center_div">
              <img
                src={Fixture}
                alt="Fixture sample"
                style={{ width: "100%" }}
              />
            </div>
            <ul>
              <li>
                <p>
                  <strong>Home:</strong> This means the team playing from their
                  home base, typically called host of the match.
                </p>
              </li>

              <li>
                <p>
                  <strong>Away:</strong> This is the teams playing away from
                  home, typically calle dthe Guest team.
                </p>
              </li>
              <li>
                <p>
                  <strong>Form:</strong> This is the number that shows the
                  performance of a team. This is calculated for the current
                  season and does not reflect performance from past seasons. The
                  overall score is weighted against the possible score a team
                  can have in a specific home league. If the match is a cup or
                  turnamant, the score is based on each team's individual
                  performance of their primary home league. Cup/ Turnament
                  performance are not factored into this score.
                </p>
              </li>
              <li>
                <p>
                  <strong>H_Win:</strong> This percentage is calculated from all
                  the encountered both teams have had. This depicts the
                  percentatge of time the home team won the match.
                </p>
              </li>
              <li>
                <p>
                  <strong>Draw:</strong> This percentage is calculated from all
                  the encountered both teams have had. This depicts the
                  percentatge of time the match ended in a draw
                </p>
              </li>
              <li>
                <p>
                  <strong>A_win:</strong> This percentage is calculated from all
                  the encountered both teams have had. This depicts the
                  percentatge of time the away team won the match.{" "}
                </p>
              </li>
              <li>
                <p>
                  <strong>O_2.5G%:</strong> This percentage is calculated from
                  all the encountered both teams have had. This depicts the
                  percentatge of time the match ended in more than 2 goals
                  total.
                </p>
              </li>
              <li>
                <p>
                  <strong>BTS%:</strong> This percentage is calculated from all
                  the encountered both teams have had. This depicts the
                  percentatge of time both teams scored in the match.
                </p>
              </li>

              <li>
                <p>
                  <strong>O_3.5Cards%:</strong> This percentage is calculated
                  from all the encountered both teams have had. This depicts the
                  percentatge of time the match has over 3 cards awarded in the
                  match.
                </p>
              </li>
              <li>
                <p>
                  <strong>O_8.5Corner%:</strong> This percentage is calculated
                  from all the encountered both teams have had. This depicts the
                  percentatge of time the match has over 8 corners awarded in
                  the match.
                </p>
              </li>
              <li>
                <p>
                  <strong>Note:</strong> This does not take into account the
                  home/away advantage, you can get that from clicking on the
                  stats to view the indepth statistics of the match.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p className="largeheadfont">
              <div
                style={{
                  fontSize: isSmallScreen
                    ? "20px"
                    : isMidScreen
                    ? "30"
                    : "50px",
                }}
              >
                Tickets
              </div>
            </p>
            <div className="center_div">
              <img src={Tickets} alt="Match Stats" style={{ width: "100%" }} />
            </div>
            <ul>
              <li>
                <p>
                  Tickets enable you to easily add games that you have
                  researched for asy tracking. This feature would remove the need to write
                  down your games manually while researching on our website.You can add and remove games
                  easily. You can also select the bet options that you
                  researched.
                </p>
                <p>
                  Tickets must be activated before you can add games to it. Once
                  ativated, you would see the ticket at the top of your screen.
                  Only the activated ticket shows up and you can only activate
                  one at a time.
                </p>
                <p>
                  To display the games added or to make an edits, just clik the
                  ticket at the top. 
                </p>
                <p>
                  You have to save your ticket if you want to still access its
                  content when you logout from your account.
                </p>
              </li>
              <li>
                <p>
                  <strong>Adding Tickets :</strong> You must be logged in to add
                  tickets. Clic the add ticket button and give it a name. If you
                  do not have a subscription, you can only add one ticket, if
                  you do, you can add up to three
                </p>
              </li>
              <li>
                <p>
                  <strong>Selecting Options :</strong> You have to click the
                  edit button, to activate tht line for edit. For the option
                  dropdown to be populated, you need to select at least one
                  type/half/bettype first.
                </p>
              </li>

              <li>
                <p>
                  <strong>Removing Tickets :</strong> Click the menu button, the
                  select remove from the drpdown options
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p className="largeheadfont">
              <div
                style={{
                  fontSize: isSmallScreen
                    ? "20px"
                    : isMidScreen
                    ? "30"
                    : "50px",
                }}
              >
                Statistics
              </div>
            </p>
            <div className="center_div">
              <img
                src={MatchStats}
                alt="Match Stats"
                style={{ width: "100%" }}
              />
            </div>
            <ul>
              <li>
                <p>
                  <strong>1. Form:</strong> This is the number that shows the
                  performance of a team. This is calculated for the current
                  season and does not reflect performance from past seasons. The
                  overall score is weighted against the possible score a team
                  can have in a specific home league. If the match is a cup or
                  turnamant, the score is based on each team's individual
                  performance of their primary home league. Cup/ Turnament
                  performance are not factored into this score.
                </p>
              </li>

              <li>
                <p>
                  <strong>2 Last 5 Games:</strong> This shows the high level
                  result for last five games of the team.
                </p>
              </li>
              <li>
                <p>
                  <strong>3. First level Sort:</strong> This range of buttons
                  control other sort buttons below. This selects the high level
                  data for satatistical diaplay.
                </p>
              </li>
              <li>
                <p>
                  <strong>4. Second level Sort:</strong> The range of buttons at
                  this level takes into account the data provided from the
                  higher level sort, it controls the data going into the lower
                  level sort.
                </p>
              </li>
              <li>
                <p>
                  <strong>5. Third level Sort:</strong> The range of buttons at
                  this level controls the final output display. It takes into
                  account all above sort values to produce the final agrigated
                  displays.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p className="largeheadfont">
              <div
                style={{
                  fontSize: isSmallScreen
                    ? "20px"
                    : isMidScreen
                    ? "30"
                    : "50px",
                }}
              >
                Data Modes
              </div>
            </p>
            <div className="center_div">
              <img src={DataMode} alt="Match Stats" />
            </div>
            <ul>
              <li>
                <p>
                  <strong>High :</strong> If high mode is selected, the site
                  loads all the data available for that screen, this also
                  incudes all the charts, graphs and statistical displays for
                  all possible seasons that is needed for the screen. In this
                  case, the load time for this mode can be really high, up to 30
                  seconds depending on the size of the data. Once the screen
                  loads, analaysis can be done on that screesn accross all
                  possible seasons very fast with no interuption. This can also
                  be data bandwith consuming.
                </p>
              </li>

              <li>
                <p>
                  <strong>Low:</strong> This mode is useful for anyone only
                  interested in fast lookup, quick analysis and current season
                  anaysis. This has very fast page load time of max 5 seconds.
                  This is highly recommended as it is fast and data bandwith
                  efficient.{" "}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <FooterNav />
      <FooterAll page={"Abour us"} />
      
    </div>
  );
}

export default HowTo;
