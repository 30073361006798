import { access } from "fs";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
function convertValues(a, b) {
  let aa = "";
  let bb = "";
  if (a === 0 && b === 0) {
    aa = 1;
  } else {
    aa = a;
  }
  if (b === 0 && a === 0) {
    bb = 1;
  } else {
    bb = b;
  }
  const total = aa + bb;
  const aPercentage = Math.round((aa / total) * 100);
  const bPercentage = Math.round((bb / total) * 100);
  let acolor = "";
  let bcolor = "";
  if (aPercentage > 50) {
    acolor = "green";
    bcolor = "orange";
  } else if (aPercentage < 50) {
    acolor = "orange";
    bcolor = "green";
  } else if (aPercentage === 50) {
    if (a > 0 && b === 0) {
      acolor = "green";
      bcolor = "orange";
    } else if (b > 0 && a === 0) {
      bcolor = "green";
      acolor = "orange";
    } else if (b > 0 && a > 0) {
      bcolor = "green";
      acolor = "green";
    } else {
      acolor = "grey";
      bcolor = "grey";
    }
  }
  let a_ = "";
  let b_ = "";
  if (a === -1 || b === -1) {
    a_ = 0;
    b_ = 0;
  } else {
    a_ = a;
    b_ = b;
  }
  return {
    ap: aPercentage,
    bp: bPercentage,
    ac: acolor,
    bc: bcolor,
    a: a,
    b: b,
  };
}

export default function DisplayMatchDetails({ Data,footy }) {
  const [half, sethalf] = useState("Full Time");
  ////console.log(Data);
  useEffect(() => {}, [half]);
  ////console.log(Data)
  const DisplayValue = ({ doc }) => {
    ////console.log(doc);
    return (
      <div
        className="mainFont midfont"
        style={{ maxWidth: "95%", margin: "auto" }}
      >
        <div className="margin30down">
          <div className="center_div_distributed bold center_sides ">
            <div className="shiftright">{doc?.data?.a}</div>
            <div>{doc?.name}</div>
            <div className="shiftleft">{doc?.data?.b}</div>
          </div>
          <div className="compare_container comparebox ">
            <div className="compare_container comparebox ">
              <div
                className=""
                style={{
                  width: `${doc?.data?.bp}%`,
                  background: "grey",
                }}
              ></div>
              <div
                className=""
                style={{
                  width: `${doc?.data?.ap}%`,
                  background: doc?.data?.ac,
                }}
              ></div>
            </div>
            <div className="compare_container comparebox ">
              <div
                className=""
                style={{
                  width: `${doc?.data?.bp}%`,
                  background: doc?.data?.bc,
                }}
              ></div>
              <div
                className=""
                style={{
                  width: `${doc?.data?.ap}%`,
                  background: "grey",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  function handleClick(item) {
    sethalf(item);
  }
  function MatchNav() {
    ////console.log(half)
    return (
      <div className="">
        <div className="center_div center_sides ">
          <div
            className={`statTable smallbuton link forceblack ${
              half === "Full Time" ? "smartTableButton bold" : "inactiveButton"
            }`}
            onClick={() => handleClick("Full Time")}
          >
            Full Time
          </div>

          <div className="allpad">
            <div
              className={`statTable smallbuton link  forceblack ${
                half === "First Half"
                  ? "smartTableButton bold"
                  : "inactiveButton"
              }`}
              onClick={() => handleClick("First Half")}
            >
              First Half
            </div>
          </div>

          <div
            className={`statTable smallbuton link forceblack ${
              half === "Second Half"
                ? "smartTableButton bold"
                : "inactiveButton"
            }`}
            onClick={() => handleClick("Second Half")}
          >
            Second Half
          </div>
        </div>
      </div>
    );
  }

  let usehalf = "";
  let agoals = "";
  let bgoals = "";

  if (half === "Full Time") {
    usehalf = "";
    agoals = "homeGoalCount";
    bgoals = "awayGoalCount";
  } else if (half === "First Half") {
    usehalf = "fh_";
    agoals = "ht_goals_team_a";
    bgoals = "ht_goals_team_b";
  } else if (half === "Second Half") {
    usehalf = "2h_";
    agoals = "goals_2hg_team_a";
    bgoals = "goals_2hg_team_b";
  }

  const data2run = [
    {
      name: "Goals",
      data: convertValues(footy?.[agoals] ?? Data?.[agoals] ?? 0, footy?.[bgoals] ?? Data?.[bgoals] ?? 0),
    },
    {
      name: "Ball Possession",
      data: convertValues(
        footy?.team_a_possession ?? Data?.team_a_possession ?? 0,
        footy?.team_b_possession ?? Data?.team_b_possession ?? 0
      ),
    },
    {
      name: "Total Shots",
      data: convertValues(footy?.team_a_shots ??  Data?.team_a_shots ?? 0, footy?.team_b_shots ?? Data?.team_b_shots ?? 0),
    },
    {
      name: "Shots on Target",
      data: convertValues(
        footy?.team_a_shotsOnTarget ?? Data?.team_a_shotsOnTarget ?? 0,
        footy?.team_b_shotsOnTarget ?? Data?.team_b_shotsOnTarget ?? 0
      ),
    },
    {
      name: "Corner Kicks ",
      data: convertValues(
        footy?.["team_a_" + usehalf + "corners"] ?? Data?.["team_a_" + usehalf + "corners"] ?? 0,
        footy?.["team_b_" + usehalf + "corners"] ?? Data?.["team_b_" + usehalf + "corners"] ?? 0
      ),
    },
    {
      name: "Cards Total ",
      data: convertValues(
        footy?.["team_a_" + usehalf + "cards_num"] ?? Data?.["team_a_" + usehalf + "cards_num"] ??
        footy?.["team_a_" + usehalf + "cards"] ?? Data?.["team_a_" + usehalf + "cards"] ??
          0,
          footy?.["team_b_" + usehalf + "cards_num"] ?? Data?.["team_b_" + usehalf + "cards_num"] ??
          footy?.["team_b_" + usehalf + "cards"] ?? Data?.["team_b_" + usehalf + "cards"] ??
          0
      ),
    },
  ];
  return (
    <div>
      <div className="center_div sidepad">
        <MatchNav />
      </div>

      <div className="margin-20down">
        {data2run.map((item, index) => {
          return <DisplayValue key={index} doc={item} />;
        })}
      </div>
    </div>
  );
}
