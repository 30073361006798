import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ScatterChart,
} from "recharts";

export default function ScatterPlot({ data, side, half, type }) {
  const navigation = useNavigate();
  const data2display = data?.["line_chart"]?.[side];
  ////console.log(data2display);
  let halfs = "ft";
  if (half === "Full Time") {
    halfs = "ft";
  } else if (half === "First Half") {
    halfs = "ht";
  } else if (half === "Second Half") {
    halfs = "h2";
  } else {
    halfs = "eh";
  }

  const GettoolTip = ({ tooltipData, label }) => {
    let data2use = "";
    for (let index = 0; index < tooltipData.length; index++) {
      if (Object.keys(tooltipData[index])[0] === label) {
        data2use = tooltipData?.[index]?.[label];
      }
    }

    const dis_name = Object.keys(data2use)[0];
    ////console.log(data2use);
    /* const tooltiplabel = toolchartdict?.[type]?.goalsTool
      ?.slice(4)
      .slice(0, -1); */
    ////console.log(tooltiplabel);
    ////console.log(date, tooltipData);
    return (
      <div>
        <Modal.Dialog>
          <Modal.Body className="tooltipbg">
            <Container className="justify-content-between align-items-center myContainer">
              <Row>
                <Col className="justify-content-between align-items-center myContainer">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <h6>{data2use?.date}</h6>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <h6> {dis_name}</h6>
              </div>
              <div>
                <Row>
                  <Col>
                    <h5>{data2use?.match?.homeName}</h5>
                  </Col>
                  <Col>
                    <h5>{data2use?.match?.["score_ft"]}</h5>
                  </Col>
                  <Col>
                    <h5>{data2use?.match?.awayName}</h5>
                  </Col>
                </Row>
              </div>
            </Container>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label, index }) => {
    const tooltipData = halfs && data2display?.[0]?.[halfs]?.tool_tip;

    if (active && payload && payload.length) {
      return (
        <div>
          <GettoolTip label={label} tooltipData={tooltipData} />
        </div>
      );
    }

    return null;
  };
  const handlematchlick = (team_meta, index, tool_tip) => {
    const match_id = team_meta?.[index]?.match_id;
    const label_ = Object.keys(tool_tip?.[index])[0];
    const team2 = tool_tip?.[index]?.[label_]?.match?.awayID;
    const team1 = tool_tip?.[index]?.[label_]?.match?.homeID;
    const seas = " ";
    navigation("/match/" + match_id, {
      state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
    });
  };

  const Renderscatter = (mainData) => {
    const chartData = halfs && data2display?.[0]?.[halfs]?.chart;
    const team_meta = data2display?.[0]?.[halfs]?.team_meta;
    const tool_tip = data2display?.[0]?.[halfs]?.tool_tip;
    ////console.log(data2display);
    const d2r = 1;
    return (
      <div className="divmargin">
        <div style={{ height: "300px", width: "100%" }}>
          <ResponsiveContainer>
            <BarChart
              width={1000}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 10,
                left: -30,
                bottom: 5,
              }}
            >
              <CartesianGrid />
              <XAxis
                dataKey="day"
                tick={{ fontSize: 12, fontFamily: "serif" }}
              />
              <YAxis tick={{ fontSize: 12, fontFamily: "serif" }} />
              <Tooltip
                content={<CustomTooltip />}
                offset={10}
                allowEscapeViewBox={{ x: false, y: true }}
              />

              <Bar dataKey={type} barSize={30} barGap={0} barCategoryGap={0}>
                {chartData &&
                  chartData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.colo}
                      className="link"
                      onClick={() =>
                        handlematchlick(team_meta, index, tool_tip)
                      }
                    />
                  ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };
  let capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <div>
      <div style={{ flex: "6", alignSelf: "flex-start" }}>
        <Modal.Dialog className="rounded-3">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <h6> {capitalizedType} Performance Trend</h6>
          </div>

          <Modal.Body>
            <Renderscatter />
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </div>
  );
}
