import React, { useState, useEffect, useRef, useContext } from "react";
import {
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBBadge,
  MDBInfiniteScroll,
} from "mdb-react-ui-kit";
import IndexHeader from "../IndexHeaders";
import { RiMenuFoldFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import care from "../../images/unisex.jpg";
import EmojiPicker from "emoji-picker-react";
import { BsSend } from "react-icons/bs";
import { io } from "socket.io";
import _ from "lodash";
import Linkify from "linkify-react";
import { Link } from "react-router-dom";
import { Errorlog, compareEmailsGetNames, convertUnixToTime } from "../Utils";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;

const RenderChats = (props) => {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [useChat, setUseChat] = useState([]);
  const [useChatAll, setUseChatAll] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isClickEmoji, setIsClickEmoji] = useState(false);
  const [ownerImage, setOwnerImage] = useState();
  const [loading, setLoading] = useState(false);
  const [visitorImage, setVisitorImage] = useState();
  const [currIndex, setCurrIndex] = useState();
  const [trigerSend, setTrigerSend] = useState();
  const [itemIndex, setItemIndex] = useState(0);
  const [upscrol,setUpScrol] =useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);
  const infiniteRef = useRef < HTMLDivElement > null;
  const containerRef = useRef(null);
  const [inputChat, setInputChat] = useState(globalUser?.user?.currChat || "");

  const [event, setevent] = useState(
    compareEmailsGetNames(globalUser?.user?.email, props?.visitor?.email)
  );
  const currTime = Math.floor(new Date().getTime() / 1000);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 1000);
  };
 

  const endOfMessagesRef = useRef(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView();
  };
  

  
  useEffect(()=>{
    
    if(!upscrol){
      //console.log("upscrolling",upscrol)
      scrollToBottom()
    }
  },[useChat])
  useEffect(() => {
    async function fetchBackground() {
      try {
        const response1 = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${props?.visitor?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response1.ok) {
          setVisitorImage(null);
          throw new Error(`HTTP error! status: ${response1.status}`);
        } else {
          const blob2 = await response1.blob();
          const base64data2 = await blob2.text(); // Convert the blob to a string
          setVisitorImage(base64data2);
        }

        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }
      setLoading(true);
      try {
        const res = await axios
          .post(
            `${API_URL}/getMessages`,
            JSON.stringify({
              owner: globalUser?.user?.email,
              visitor: props?.visitor?.email,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch((error) => {
            "";
          });
        ////console.log("loaded fetchdata", res);
        const fix_data = res.data;
        ////console.log("loaded fetchdata", fix_data?.status);

        if (fix_data && fix_data.status === "success") {
          setUseChat(fix_data?.data);
          setUseChatAll(fix_data?.dates)
          setUpScrol(false)
          setItemIndex(0)
          ////console.log(fix_data)
          setevent(
            compareEmailsGetNames(
              globalUser?.user?.email,
              props?.visitor?.email
            )
          );

          ////console.log(fix_data?.data);
        } else {
          setUseChat([]);
          setUseChatAll([])
          setUpScrol(false)
          setItemIndex(0)
          setevent(
            compareEmailsGetNames(
              globalUser?.user?.email,
              props?.visitor?.email
            )
          );
        }
        setLoading(false);
        
      } catch (error) {
        //console.log(error);
        Errorlog(error, "error loading chats", "from message stats");
      }
    }
    fetchBackground();

    
  }, [props?.visitor?.email, globalUser?.user?.email]);

  useEffect(() => {
    ////console.log(event);
    setUpScrol(false)
    props.socketInstance.on(event, (data) => {
      ////console.log("got the message successfully", data, props?.visitor);
      handleNewMessage(data);
      //setUseChat([...useChat, data?.chatMessage]);
    });

    return () => {
      props?.socketInstance?.off(event);
    };
  }, [props?.socketInstance]);

  useEffect(() => {
    setUpScrol(false)
    props.socketInstance.on(event, (data) => {
      ////console.log("got the message successfully", data);
      handleNewMessage(data);
      //setUseChat([...useChat, data?.chatMessage]);
    });
    return () => {
      props?.socketInstance?.off(event);
    };
  }, [trigerSend]);

  useEffect(() => {
    setUpScrol(false)
    props.socketInstance.on(event, (data) => {
      ////console.log("got the message successfully", data);
      handleNewMessage(data);
      //setUseChat([...useChat, data?.chatMessage]);
    });
    return () => {
      props?.socketInstance?.off(event);
    };
  }, [event]);

  ////console.log(event,useChat);

  const handleNewMessage = (data) => {
    
    var today = new Date(Date.now());
    var current_date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");
    //let current_date = Date.now() / 1000;

    let oldChat = [...useChat];
    
    let updated = false;
    for (let index = 0; index < useChat?.length; index++) {
      const element = useChat[index];

      if (element?.date === current_date) {
        updated = true;
        oldChat[index]["data"]?.push(data?.chatMessage);
        oldChat[index]["data"] = [...new Set(oldChat[index]["data"])];
        setUseChat((prev) => {
          let updatedChat = [...prev];
          updatedChat[index]["data"] = [
            ...new Set(updatedChat[index]["data"].concat([data?.chatMessage])),
          ];
          return updatedChat;
        });
        break;
      }
    }
    if (!updated) {
      oldChat = { data: [data?.chatMessage], date: current_date };
      setUseChat((prev) => {
        let updatedChat = [...prev];
        updatedChat = [...new Set(updatedChat.concat([oldChat]))];
        for (let index = 0; index < updatedChat.length; index++) {
          const element = updatedChat[index];
          if (element?.date === updatedChat[index + 1]?.date) {
            if (element?.data[0] === updatedChat[index + 1]?.data[0]) {
              updatedChat.splice(index + 1, 1);
              index--;
            } else {
              updatedChat[index]["data"].push(updatedChat[index + 1]?.data[0]);
              updatedChat.splice(index + 1, 1);
              index--;
            }
          }
        }
        return updatedChat;
      });
    }
    /* if (current_date in oldChat) {
      oldChat[current_date].push(data?.chatMessage);
      setUseChat(oldChat);
    } else {
      oldChat[current_date] = [data?.chatMessage];
      setUseChat(oldChat);
    } */
   setUpScrol((prev) => false)
  };
  
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    ////console.log(globalUser?.user?.email, StorageBucket);
    async function fetchBackground() {
      try {
        const response2 = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }
        const blob2 = await response2.blob();
        const base64data2 = await blob2.text(); // Convert the blob to a string
        setOwnerImage(base64data2);
        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
    
  }, [globalUser?.user?.email]);

  
  const callFunc = async () => {
    const tipstarDetail = await axios
      .post(`${API_URL}/gettipstarInfo`, {
        email: globalUser?.user?.email,
        action: "get",
      })
      .catch((error) => {
        //throw new Error('Server is unavailable, please try again later');
      });
    ////console.log(tipstarDetail);
    if (tipstarDetail?.data?.status == "success") {
      ////console.log("got it", tipstarDetail);
      return tipstarDetail?.data?.data;
    }
  };
  const handleSendClick = async () => {
    
    const formTipstar = await callFunc();

    const currTime = Date.now() / 1000;
    const ownerName = formTipstar?.tipstarName ?? globalUser?.user?.firstname;
    const ownerMessahe = {
      time: currTime,
      chatMessage: inputChat,
      name: ownerName ?? "null",
      email: globalUser?.user?.email ?? "null",
      viewed: false,
    };
    
    setInputChat("");
    ////console.log(currIndex);
    
    try {
      props.socketInstance.emit("message", {
        owner: ownerMessahe,
        visitor: props?.visitor,
        time: currTime,
      });
      setTrigerSend(() => !trigerSend);
    } catch {
      //console.log("error while pinging backend");
    }

    /* updateGlobalUser((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          ["chatMessage"]: inputChat,
        },
      })); */
      setUpScrol((prev) => false)
  };
  const handleEmojiInput = (emoji) => {
    ////console.log(emoji)
    setInputChat((prev) => `${prev}${emoji?.emoji}`);
  };
  
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      // Calculate if user has scrolled to the top of the container
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop === 0 && !loading) {
        setUpScrol(true)
        loadMoreData(); // Load more data when scrolling upwards and not already loading
      }
    };

    // Add scroll event listener to the container
    container.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up event listener when component unmounts
      container.removeEventListener('scroll', handleScroll);
    };
  }, [loading]);
  const fetchNewData = async(day1,day2) =>{
    try {
      const res = await axios
        .post(
          `${API_URL}/getOneMessages`,
          JSON.stringify({
           "day1": day1,
            "day2": day2,
            "coll": event,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {
          "";
        });
      ////console.log("loaded fetchdata", res);
      const fix_data = res.data;
      ////console.log("loaded fetchdata", fix_data?.status);

      if (fix_data && fix_data.status === "success") {
        ////console.log(fix_data?.data)
        
        return fix_data?.data
      }
        
      
    } catch (error) {
      //console.log(error);
      Errorlog(error, "error loading old chats", "from message stats");
    }
  }
  const loadMoreData = async () => {
    
    if (loading) return;
    
    ////console.log("new message triggered",itemIndex,useChatAll?.sort((a, b) => new Date(b).getTime() - new Date(a).getTime()))
    
    setItemIndex(previndex => {
      if (previndex > useChatAll?.length - 1) {
        return;
      }
      const fetchAndUpdate = async () => {
        let temuseChatAll = [...useChatAll?.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())]
        try{
          let newdata = await fetchNewData(temuseChatAll?.[previndex],temuseChatAll?.[previndex + 1])
          let newchat = [ newdata?.[temuseChatAll?.[previndex]],newdata?.[temuseChatAll?.[previndex+ 1 ]]]
          
    
          setUseChat((prev)=>[...prev, newchat?.[0],newchat?.[1]]);
        }catch (error){
          //console.log(error)
          return;
        }
        
        
      };
    
      fetchAndUpdate();
      return previndex + 2;
    })

    ////console.log(newdata[temuseChatAll[itemIndex]],newdata[temuseChatAll[itemIndex + 1 ]])
    /* const oldUsechat = [...useChat, newdata[temuseChatAll[itemIndex]],newdata[temuseChatAll[itemIndex + 1 ]]] */
    
    
    
    
    
    //
    ////console.log("i was here to trigger scroll");
  };
  ////console.log(useChat,itemIndex,upscrol)
  const renderLink = ({ attributes, content }) => {
    const { href, ...props } = attributes;
    //console.log(href, props,content)
    return (
      <Link to={href} {...props} style={{color: 'orange'}}>

        {content}
      </Link>
    );
  };
  return (
    <div key={props?.index} >
      <section
      ref={containerRef}
        className="inner-content overflow-scroll noscrolbar margin-50down"
        style={{ height: isSmallScreen ? "500PX" : "650px" }}
        key={props?.index}
      >
        <div
          className="d-flex sticky-top center_straight margin-10down"
          style={{ zIndex: isSmallScreen ? 888 : "inherit" }}
        >
          <div className="warningDisplay left_div center_sides allpad">
            <div className="position-relative allpad">
              <MDBBtn
                color="light"
                className="shadow-0 p-0 me-3 d-block d-lg-none "
                style={{ minWidth: "14px" }}
                onClick={() => props?.setBasicOpen(!props?.basicOpen)}
              >
                <RiMenuFoldFill size={35} />
              </MDBBtn>
              {props?.triggerNew?.status && !props?.basicOpen && (
                <MDBBadge
                  color="danger"
                  pill
                  className="position-absolute top-0 p-1"
                >
                  {props?.triggerNew?.status ? props?.triggerNew?.count : null}
                </MDBBadge>
              )}
            </div>

            <img
              src={visitorImage ?? care}
              className="rounded-circle me-2"
              style={{ width: "30px", height: "30px" }}
              alt={props?.visitor?.name}
            />

            <span>{props?.visitor?.name}</span>
          </div>

          {!isSmallScreen && <hr />}
        </div>
        
          {useChat
            ?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime())
            ?.map((OItem, OIndex) => {
              return (
                <div key={OIndex} >
                {loading && <div className="d-flex justify-content-center midfainttext">Loading...</div>}
                  <div className="d-flex justify-content-center midfainttext">
                    {OItem?.date}
                  </div>
                  {OItem?.data
                    ?.sort((c, d) => c?.time - d?.time)
                    ?.map((item, index) => {
                      return (
                        <>
                          {item?.email === props?.visitor?.email ? (
                            <div
                              key={index}
                              className="d-flex justify-content-start w-100 "
                              ref={endOfMessagesRef}
                            >
                              <img
                                src={visitorImage ?? care}
                                className="rounded-circle me-2"
                                style={{ width: "30px", height: "30px" }}
                                alt={props?.visitor?.name}
                              />
                              <p
                                className="p-1 bg-light  shadow-1 shadow-1 rounded_div"
                                style={{ maxWidth: "533px" }}
                              >
                              <Linkify options={{ render: renderLink }}>
                              {item?.chatMessage}
                              </Linkify>
                                
                                <small className="float-end text-dark mt-4">
                                  {convertUnixToTime(item?.time, "time")}
                                </small>
                              </p>
                            </div>
                          ) : (
                            <div
                              key={index}
                              className="d-flex justify-content-end"
                              ref={endOfMessagesRef}
                            >
                              <p
                                className="p-1 bg-primary shadow-1-strong text-white rounded_div"
                                style={{ maxWidth: "533px" }}
                              >
                                <Linkify options={{ render: renderLink }}>
                              {item?.chatMessage}
                              </Linkify>
                                <small className="float-end mt-4">
                                  {convertUnixToTime(item?.time, "time")}
                                </small>
                              </p>
                              <img
                                src={
                                  ownerImage ??
                                  globalUser?.user?.profile ??
                                  care
                                }
                                className="rounded-circle ms-2"
                                style={{ width: "30px", height: "30px" }}
                                alt={item?.name}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              );
            })}
      </section>
      <section
        id="message-input"
        className=" inner-content center_div messageBoxstyle allpad shiftleft bg-light "
      >
        <MDBCardBody className="">
          <div className="d-flex">
            <div className="center_div">
              <MDBBtn
                className="px-3"
                size="sm"
                onClick={() => setIsClickEmoji(() => !isClickEmoji)}
              >
                <i className="fas fa-plus-circle"></i>
              </MDBBtn>
            </div>

            <div className="w-100 mx-2">
              <MDBInput
                type="text"
                label="Enter message"
                name="chat"
                value={inputChat ?? ""}
                onChange={(e) => {
                  setInputChat((prev) => e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // Call the function you want to trigger here
                    e.preventDefault(); // Prevents form submission in case this input is inside a <form>
                    handleSendClick();
                  }
                }}
              />
            </div>

            <MDBBtn className="" size="sm" onClick={() => handleSendClick()}>
              <div className="center_div">
                <BsSend size={25} />
              </div>
            </MDBBtn>
          </div>
          {isClickEmoji && (
            <EmojiPicker
              onEmojiClick={(e) => {
                handleEmojiInput(e), setIsClickEmoji(() => false);
              }}
            />
          )}
        </MDBCardBody>
      </section>
    </div>
  );
};

export default RenderChats;
