import React from "react";
import EmptyHeader from "./Emptyheader";
import axios from "axios";

import { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../css/ThemeCss.css";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

////console.log(WEB);
export default function GetEmail() {
  const [clickedVerify, setClickedVerify] = useState(false);
  const [needData, setNeedData] = useState();
  const [email, setEmail] = useState({ email: "" });
  const [validEmail, setValidEmail] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [isemail, setIsemail] = useState("");
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, [setClickedVerify]);

  const handleVerify = async (type) => {
    let resverify = "";
    ////console.log(email);
    if (email?.email) {
      resverify = await axios.post(`${API_URL}/CheckEmail`, email);
    } else {
      resverify = {};
    }

    ////console.log(resverify);
    if (resverify?.data?.response === "failed") {
      setValidEmail(false);
    } else if (resverify?.data?.response === "success") {
      setNeedData(resverify?.data?.savedData);
      const retdata = resverify?.data?.savedData;
      setValidEmail(true);
      let now = new Date();
      let senttime = Math.floor(now.getTime() / 1000);
      let fexptime = new Date(now.getTime() + 6 * 60000);
      let exptime = Math.floor(fexptime.getTime() / 1000);
      const datatoencode = {
        user_name: retdata?.firstname,
        user_email: [retdata?.email],
        senttime: senttime,
        exptime: exptime,
        session: retdata?.auth?._id,
      };
      const encoder = await axios.post(`${API_URL}/pushtemp`, {
        type: "save",
        tostore: datatoencode,
      });
      const Emaildata = {
        user_name: retdata?.firstname,
        user_email: [retdata?.email],
        subject: "Password Reset",
        send_mail: "security@betinformatics.com",
        sub_name: "Bet Informatics Security",
        message_type: "passwordreset",
        links: [`${WEB}/passwordreset?data=${encoder?.data?.savedData}`],
      };
      const sent = await axios.post(`${API_URL}/send-mail`, Emaildata);
      setValidEmail(true);
      if(type === "resend"){
        setIsResend(true)
      }else{
        setIsResend(false)
      }
      
      setIsemail(retdata?.email)
    } else {
      setValidEmail(false);
    }
    /* 
    if (resverify?.data?.response === "success") {
      setCurrUser(resverify?.data?.savedData);
      updateGlobalUser({ user: resverify?.data?.savedData });
      const ckUser = await UpdateLocal({
        action: "save",
        data: { user: resverify?.data?.savedData },
      });
    }
    setClickedVerify(true);
     */
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setIsResend(false)
    setEmail((prevData2) => ({
      ...prevData2,
      [name]: value,
    }));
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();
  };
  return (
    <div
      style={{
        background: "white",
        fontFamily: "serif",
      }}
    >
      <EmptyHeader />

      <Container className="appear_center ">
        <div>
          <div
            className="center_div margin-50down bold"
            style={{
              fontSize: isSmallScreen ? "25px" : isMidScreen ? "35px" : "50px",
            }}
          >
            {" "}
            Can't remember your password?
          </div>
          {clickedVerify ? (
            validEmail ? (
              <>
                <div className="center_div flex_column  margin-30down">
                  <div> Password reset email have been sent to your email</div>
                  <div className="link">{isemail}</div>.
                </div>
                {isSmallScreen? <>
                  <div className="center_div flex_column infoFont ">
                    <div>
                    Log into your email and click the reset
                    </div>
                    <div>
                    password button to reset password.
                    </div>
                  </div>
                </> : <>
                <div className="center_div infoFont margin-30down">
                  Log into your email and click the reset password button to
                  reset password.
                </div>
                </>}
                
              </>
            ) : (
              <div className="center_div infoFont margin-30down">
                Email not found in our database, check email properly and try
                again.
              </div>
            )
          ) : (
            <div className="center_div infoFont margin-30down">
              Enter the email associated with your account.
            </div>
          )}
            {isResend ? <div style={{color:"red"}}>Password reset email have been resent to your email</div> :null}
          <div>
            <Form onSubmit={handleSubmit2}>
              <Form.Group as={Col} controlId="email">
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  value={email?.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Form>
          </div>
          <div className=" margin30down">
            {clickedVerify && validEmail ? (
              <Row>
                <Col>
                  <div
                    className="link smallbuton  divorangeButton"
                    onClick={() => {
                      setClickedVerify(true);
                      handleVerify("resend");
                    }}
                  >
                    Resend Link
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <div
                    className="link smallbuton  divgreenButton"
                    onClick={() => {
                      setClickedVerify(true);
                      handleVerify("first");
                    }}
                  >
                    Send Link
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
