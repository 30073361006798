import React from "react";
import { Spinner as Loader } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const SpinnerStyle = {
  position: "absolute",
  top: "calc(50% - 1rem)",
  left: "calc(50% - 1rem)",
};

const Spinner = () => (
  <Loader style={SpinnerStyle} animation="grow" variant="primary" />
);

export const SpinnerCustom = ({ myImage }) => {
  //s////console.log(myImage);
  const spinnerStyle = {
    width: "150px",
    height: "150px",
    position: "absolute",
    top: "calc(50% - 8rem)",
    left: "calc(50% - 1rem)",
    backgroundImage: `url(${myImage})`,
    backgroundSize: "cover",
    animation: "blinker 1s linear infinite",
  };

  return <div style={spinnerStyle}></div>;
};
/* export const SpinnerCustomMatch = ({ image1, image2 }) => {
  ////console.log(image1, image2);
  const spinnerStyle = {
    width: "150px",
    height: "150px",
    position: "absolute",
    top: "calc(50% - 8rem)",
    left: "calc(50% - 1rem)",
    backgroundImage: `url(${image1}), V , url(${image2})`,
    backgroundPosition: "center", // Center the images
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    animation: "blinker 1s linear infinite",
  };

  return <div style={spinnerStyle}></div>;
}; */
export const SpinnerCustomMatch = ({ image1, image2 }) => {
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []);

  const spinnerStyle = {
    position: "absolute",
    top: "calc(50% - 8rem)",
    left: "calc(50% - 10rem)",
    backgroundPosition: "center", // Center the images
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    animation: "blinker 1s linear infinite",
    justifyContent: "center",
  };
  return (
    <div style={spinnerStyle}>
      <div className="center_div_distributed center_sides">
        <div >
          <img
            src={image1 && image1}
            alt="Team-a"
            
          />
        </div>
        
          <div
            style={{
              fontFamily: "serif",
              fontWeight: "bolder",
              fontSize: "20px",
              
            }}
            className="allpad"
          > &nbsp;
            V
          </div>
     
        <div >
          <img
            src={image2 && image2}
            alt="Team-b"
            
          />
        </div>
      </div>
    </div>
  );
};
export default Spinner;
