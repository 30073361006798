import React from "react";

import { IoMdFootball } from "react-icons/io";
import { TfiBasketball } from "react-icons/tfi";
import { MdSportsTennis } from "react-icons/md";
import { FaTableTennisPaddleBall } from "react-icons/fa6";
import { GiHockey } from "react-icons/gi";
import { GiAmericanFootballHelmet } from "react-icons/gi";
import { PiVolleyball } from "react-icons/pi";
const SportIcon = ({sport}) => {
  return (
    <div>
      {sport === "Football" ? (
        <>
          <IoMdFootball />
        </>
      ) : sport === "Basketball" ? (
        <>
          <TfiBasketball />
        </>
      ) : sport === "Tennis" ? (
        <><MdSportsTennis/></>
      ) : sport === "Table Tennis" ? (
        <><FaTableTennisPaddleBall/></>
      ) : sport === "Hockey" ? (
        <><GiHockey/></>
      ) : sport === "American Football" ? (
        <><GiAmericanFootballHelmet/></>
      ) : sport === "Volleyball" ? (
        <><PiVolleyball/></>
      ) :(
        <>?</>
      )}
    </div>
  );
};
export default SportIcon;
