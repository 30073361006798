import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
/* import IndexHeader from "../components/IndexHeaders";
import IndexSubheads from "../components/IndexSubheader"; */
import Main from "./Main";
import { UserInfoContext } from "../components/contextRouters/UserInfoContext";
import "../css/ThemeCss.css";
/* import NavigationApp from "./NavigationApp";
import IndexPage from "../components/indexPage"; */
const host = process.env.REACT_APP_WEBSITE;
import { Buffer } from 'buffer';


function App() {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem('visitedBefore');
    window.process = {
      ...window.process,
    };
    window.Buffer = Buffer;
    if (!isFirstVisit) {
      setShowBanner(false);
    }
  }, []);

  const handleCloseBanner = () => {
    setShowBanner(false);
    localStorage.setItem('visitedBefore', true);
  };

  return (
    <div className="App">
      <Main />
      {showBanner && (
        <div className="notice-banner mainFont midfont" id="notice-banner" style={{zIndex:"999999"}}>
          <p>By using this website you agree to our <a className="link" href={`${host}/TAC`}>terms of operation</a> and our <a className="link" href={`${host}/privacypolicy`}>privacy policy</a>.</p>
          <button id="close-banner" onClick={handleCloseBanner}>Close</button>
        </div>
      )}
    </div>
  );
}

export default App;
