import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import { InfoTag } from "../DisplayInfo";
import { Errorlog } from "../Utils";
import axios from "axios";
import oddsType from "./oddsType.json";
import Spinner from "../Spinner";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
const footy = process.env.REACT_APP_FOOTYSPORTS;
const footyRoot = process.env.REACT_APP_FOOTY_ROOT;

export default function MatchPredictions(props) {
  const [useFixture, setFixture] = useState({});
  const [loading, setLoading] = useState(true);
  const [statsToShow, setstatsToShow] = useState("All");
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  //console.log(props);
  useEffect(() => {

    const fetchData = async () => {
      checkScreenSize();
      window.addEventListener("resize", checkScreenSize);
      let options = {
        body: JSON.stringify({ match: props?.MatchID, type_: statsToShow }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60 * 60,
        },

        seconds: 2 * 60 * 60,
      };
      let url = "/matchPrediction";
      let indexdb = "matchPrediction";
      let SaveKey = "" + props?.MatchID + statsToShow;
      if (!props?.MatchID) {
        return null;
      }
      setLoading(true);
      try {
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/matchPrediction`,
          JSON.stringify({ match: props?.MatchID, type_: statsToShow }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */

        const fixdata = JSON.parse(res?.data);
        if (res?.status === "success") {
          setFixture(fixdata?.data?.[statsToShow]);
        }
        //console.log(fixdata);
      } catch (error) {
        Errorlog(error, "error loading statistics", "from match stats");
      }
    };

    fetchData();
  }, [props?.MatchID, statsToShow]);

  const handleClick = (item) => {
    setstatsToShow(item);
  };
  function PredNav() {
    ////console.log(half)
    let half;
    return (
      <div className="">
        <div className="center_div center_sides ">
          <div
            className={`statTable smallbuton link forceblack ${
              statsToShow === "All" ? "smartTableButton bold" : "inactiveButton"
            }`}
            onClick={() => handleClick("All")}
          >
            Overall
          </div>

          <div className="allpad">
            <div
              className={`statTable smallbuton link  forceblack ${
                statsToShow === props?.teamA
                  ? "smartTableButton bold"
                  : "inactiveButton"
              }`}
              onClick={() => handleClick(props?.teamA)}
            >
              Home Biased
            </div>
          </div>

          <div
            className={`statTable smallbuton link forceblack ${
              statsToShow === props?.teamB
                ? "smartTableButton bold"
                : "inactiveButton"
            }`}
            onClick={() => handleClick(props?.teamB)}
          >
            Away Biased
          </div>
        </div>
      </div>
    );
  }
  //console.log(useFixture)
  const DisplayPredictions = () => {
    return (
      <div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Win Draw Lose
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                  <th scope="col"><div className="center_div"> Half </div></th>
                    <th scope="col"><div className="center_div">Home </div></th>
                    <th scope="col"><div className="center_div">Draw</div></th>
                    <th scope="col"><div className="center_div">Away</div></th>
                    <th scope="col"><div className="center_div">Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"FT"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.home_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.home_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.draw_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.draw_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.away_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.away_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"HT"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.home_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.home_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.draw_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.draw_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.away_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.away_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"2H"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.home_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.home_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.draw_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.draw_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.away_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.away_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        
        
        <div className=" margin-20down">
          <div className="center_div bold">
            Double Chance - Any To Win (1 2)
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    <th scope="col"><div className="center_div"> 1 2 - FT </div></th>
                    <th scope="col"><div className="center_div">1 2 - HT</div></th>
                    <th scope="col"><div className="center_div">1 2 - 2H</div></th>
                    <th scope="col"><div className="center_div">Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.Any_win_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.Any_win_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.Any_win_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.Any_win_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.Any_win_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.Any_win_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={4}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Either Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                  <th scope="col"><div className="center_div"> H/A/D</div></th>
                    <th scope="col"><div className="center_div"> Yes</div></th>
                    <th scope="col"><div className="center_div">No</div></th>
                    
                    <th scope="col"><div className="center_div">Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"Home"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.hweh?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.hweh?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.hweh_no?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.hweh_no?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"Away"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.aweh?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.aweh?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.aweh_no?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.aweh_no?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                 
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"Draw"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.deh?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.deh?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.deh_no?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.deh_no?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                    
                  </tr>
                    <tr>
                    <td colSpan={4}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
       
        
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Goals Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div"> Under 2.5</div></th>
                    <th scope="col"><div className="center_div">Over 3.5</div></th>
                    <th scope="col"><div className="center_div">Under 3.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov2_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un2_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov3_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov3_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un3_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un3_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Goals - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov1_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un1_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov2_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov2_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un2_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un2_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Goals - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov1_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un1_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov2_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov2_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un2_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un2_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        <div className=" margin-20down">
          <div className="center_div bold">
            Team To Score
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                  <th scope="col"><div className="center_div"> Type</div></th>
                    <th scope="col"><div className="center_div">Yes</div></th>
                    <th scope="col"><div className="center_div"> No</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"BTS"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.bts?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.bts?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.bts_n?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.bts_n?.score}
                        </div>
                      </div>
                    </td>
                    
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"Home"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_score_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_score_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_score_no_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_score_no_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"Away"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_score_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_score_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_score_no_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_score_no_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        <div className=" margin-20down">
          <div className="center_div bold">
            Home Team Goals Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_ov1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_ov1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_un1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_un1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_ov2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_ov2_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_un2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_un2_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Home Team Goals - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_ov0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_ov0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_un0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_un0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_ov1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_ov1_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_un1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_un1_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Home Goals Goals - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_ov0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_ov0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_un0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_un0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_ov1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_ov1_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_un1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_un1_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        <div className=" margin-20down">
          <div className="center_div bold">
            Away Team Goals Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_ov1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_ov1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_un1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_un1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_ov2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_ov2_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_un2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_un2_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Away Team Goals - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_ov0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_ov0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_un0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_un0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_ov1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_ov1_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_un1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_un1_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
            Away Goals Goals - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_ov0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_ov0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_un0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_un0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_ov1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_ov1_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_un1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_un1_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        {/* starting Cards */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Cards - Win Draw Lose
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                  <th scope="col"><div className="center_div"> Half </div></th>
                    <th scope="col"><div className="center_div">Home </div></th>
                    <th scope="col"><div className="center_div">Draw</div></th>
                    <th scope="col"><div className="center_div">Away</div></th>
                    <th scope="col"><div className="center_div">Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"FT"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_home_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_home_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_draw_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_draw_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_away_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_away_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"HT"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_home_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_home_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_draw_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_draw_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_away_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_away_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"2H"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_home_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_home_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_draw_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_draw_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.card_away_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.card_away_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        {/* Card over and under */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Cards Under/Over - Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 3.5</div></th>
                    <th scope="col"><div className="center_div"> Under 3.5</div></th>
                    <th scope="col"><div className="center_div">Over 5.5</div></th>
                    <th scope="col"><div className="center_div">Under 5.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov3_Ca_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov3_Ca_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un3_Ca_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un3_Ca_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov5_Ca_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov5_Ca_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un5_Ca_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un5_Ca_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Match Cards Under/Over - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov1_Ca_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov1_Ca_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un1_Ca_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un1_Ca_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov2_Ca_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov2_Ca_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un2_Ca_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un2_Ca_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Match Cards Under/Over - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov1_Ca_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov1_Ca_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un1_Ca_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un1_Ca_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov2_Ca_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov2_Ca_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un2_Ca_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un2_Ca_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        {/* Home Card over and under */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Home Cards Under/Over - Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_ov1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_ov1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_un1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_un1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_ov2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_ov2_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_un2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_un2_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Home Cards Under/Over - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_ov0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_ov0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_un0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_un0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_ov1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_ov1_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_un1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_un1_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Home Cards Under/Over - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_ov0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_ov0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_un0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_un0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_ov1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_ov1_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Ca_un1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Ca_un1_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        {/* Away Card over and under */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Away Cards Under/Over - Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div"> Under 1.5</div></th>
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div">Under 2.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_ov1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_ov1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_un1_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_un1_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_ov2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_ov2_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_un2_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_un2_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Away Cards Under/Over - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_ov0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_ov0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_un0_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_un0_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_ov1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_ov1_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_un1_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_un1_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Away Cards Under/Over - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 0.5</div></th>
                    <th scope="col"><div className="center_div"> Under 0.5</div></th>
                    <th scope="col"><div className="center_div">Over 1.5</div></th>
                    <th scope="col"><div className="center_div">Under 1.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_ov0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_ov0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_un0_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_un0_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_ov1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_ov1_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Ca_un1_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Ca_un1_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>



        {/* starting Corners */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Corners - Win Draw Lose
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                  <th scope="col"><div className="center_div"> Half </div></th>
                    <th scope="col"><div className="center_div">Home </div></th>
                    <th scope="col"><div className="center_div">Draw</div></th>
                    <th scope="col"><div className="center_div">Away</div></th>
                    <th scope="col"><div className="center_div">Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"FT"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_home_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_home_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_draw_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_draw_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_away_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_away_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"HT"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_home_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_home_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_draw_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_draw_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_away_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_away_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              "",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"2H"}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_home_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_home_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_draw_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_draw_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.corner_away_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.corner_away_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        {/* Corner over and under */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Match Corners Under/Over - Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 8.5</div></th>
                    <th scope="col"><div className="center_div"> Under 8.5</div></th>
                    <th scope="col"><div className="center_div">Over 9.5</div></th>
                    <th scope="col"><div className="center_div">Under 9.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov8_Co_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov8_Co_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un8_Co_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un8_Co_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov9_Co_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov9_Co_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un9_Co_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un9_Co_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Match Corners Under/Over - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 4.5</div></th>
                    <th scope="col"><div className="center_div"> Under 4.5</div></th>
                    <th scope="col"><div className="center_div">Over 5.5</div></th>
                    <th scope="col"><div className="center_div">Under 5.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov4_Co_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov4_Co_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un4_Co_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un4_Co_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov5_Co_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov5_Co_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.un4_Co_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.un4_Co_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Match Corners Under/Over - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 4.5</div></th>
                    <th scope="col"><div className="center_div"> Under 4.5</div></th>
                    <th scope="col"><div className="center_div">Over 5.5</div></th>
                    <th scope="col"><div className="center_div">Under 5.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov4_Co_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov4_Co_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov5_Co_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov5_Co_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov5_Co_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov5_Co_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.ov5_Co_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.ov5_Co_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        {/* Home Corner over and under */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Home Corners Under/Over - Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 4.5</div></th>
                    <th scope="col"><div className="center_div"> Under 4.5</div></th>
                    <th scope="col"><div className="center_div">Over 5.5</div></th>
                    <th scope="col"><div className="center_div">Under 5.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_ov4_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_ov4_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_un4_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_un4_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_ov5_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_ov5_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_un5_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_un5_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Home Corners Under/Over - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div"> Under 2.5</div></th>
                    <th scope="col"><div className="center_div">Over 3.5</div></th>
                    <th scope="col"><div className="center_div">Under 3.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_ov2_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_ov2_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_un2_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_un2_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_ov3_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_ov3_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_un3_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_un3_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Home Corners Under/Over - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div"> Under 2.5</div></th>
                    <th scope="col"><div className="center_div">Over 3.5</div></th>
                    <th scope="col"><div className="center_div">Under 3.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_ov2_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_ov2_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_un2_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_un2_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_ov3_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_ov3_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.h_Co_un3_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.h_Co_un3_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>

        {/* Away Corner over and under */}
        <div className=" margin-20down">
          <div className="center_div bold">
            Away Corners Under/Over - Full Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 4.5</div></th>
                    <th scope="col"><div className="center_div"> Under 4.5</div></th>
                    <th scope="col"><div className="center_div">Over 5.5</div></th>
                    <th scope="col"><div className="center_div">Under 5.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_ov4_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_ov4_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_un4_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_un4_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_ov5_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_ov5_ft?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_un5_ft?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_un5_ft?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Away Corners Under/Over - Half Time
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div"> Under 2.5</div></th>
                    <th scope="col"><div className="center_div">Over 3.5</div></th>
                    <th scope="col"><div className="center_div">Under 3.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_ov2_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_ov2_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_un2_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_un2_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_ov3_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_ov3_ht?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_un3_ht?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_un3_ht?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
        <div className=" margin-20down">
          <div className="center_div bold">
          Away Corners Under/Over - Second Half
          </div>
          <div className="center_div">
            <div style={{ width: "90%" }}>
              <MDBTable hover>
                <MDBTableHead>
                  <tr>
                    
                    <th scope="col"><div className="center_div">Over 2.5</div></th>
                    <th scope="col"><div className="center_div"> Under 2.5</div></th>
                    <th scope="col"><div className="center_div">Over 3.5</div></th>
                    <th scope="col"><div className="center_div">Under 3.5</div></th>
                    <th scope="col"><div className="center_div"> Prediction</div></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_ov2_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_ov2_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_un2_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_un2_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_ov3_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_ov3_2h?.score}
                        </div>
                      </div>
                    </td>
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:
                              useFixture?.a_Co_un3_2h?.color,

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {useFixture?.a_Co_un3_2h?.score}
                        </div>
                      </div>
                    </td>
                    
                    <td className="formTable">
                      <div className="center_div">
                        <div
                          style={{
                            display: "flex",
                            padding: "0px",
                            fontSize:25,
                            width: "50px",
                            height: "50px",
                            background:"grey",

                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"-"}
                        </div>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    
                    <td colSpan={5}><div className="center_div">The greener the score, the greater the possibility of outcome. Prediction is the safest pick.</div></td>
                    
                    
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="center_div sidepad margin-30down">
        <PredNav />
      </div>
      <div>
        <DisplayPredictions />
      </div>
    </div>
  );
}
