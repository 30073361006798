import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/HeaderStyles.css";
import { ToastContainer, toast } from "react-toastify";
import { Themes } from "./indexPage";
import {
  Nav,
  Image,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Navbar,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as Icon } from "../images/user.svg";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import Spinner from "./Spinner";

function EmptyHeader(props) {
  const navigation = useNavigate();

  return (
    <div
      className="navbar_font "
      style={{ position: "sticky", top: 0, zIndex: 1000 }}
    >
      <Container>
        <Navbar expand="lg" sticky="top" className="m-auto">
          <Navbar>
            <Container>
              <Nav.Link onClick={() => navigation("/")}>
                <Logo
                  alt="Bet Informatics"
                  style={{ maxWidth: "10rem", maxHeight: "2rem" }}
                />
              </Nav.Link>
            </Container>
          </Navbar>
        </Navbar>
      </Container>
    </div>
  );
}

export default EmptyHeader;
