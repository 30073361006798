import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Modal, Table, Nav, Col, Container } from "react-bootstrap";
import styled from "styled-components";

import { GlobalUserContext } from "../contextRouters/UserInfoContext";

import Spinner from "../Spinner";
import LeagueStatisticsWDL from "../ChartComponents/LeagueStatisticsChartWinners";
import "../../css/LeagueStatsTableWinners.css";
import Team_image from "../../images/team_images.json";
import { IoIosArrowDropdownCircle } from "react-icons/io";

function DisplayForm({ trail, gh, small, mid }) {
  let hist = 0;
  let final_string = "";
  ////console.log(trail);
  if (gh === 0) {
    hist = trail?.length;
  } else if (gh === 1) {
    hist = 16;
  } else if (gh === 2) {
    hist = 11;
  } else if (gh === 3) {
    hist = 6;
  }

  if (trail?.length > hist) {
    final_string = trail?.slice(-hist);
  } else {
    final_string = trail; // If trail.length <= hist, use the original string
  }

  return (
    <div>
      <Row className="Row">
        {final_string
          ?.split("")
          ?.reverse()
          ?.slice(0, small ? 4 : mid ? 6 : 8) // This will only take the first 6 elements
          ?.map((char, index) => (
            <div key={index} className={char}>
              {char.toUpperCase()}
            </div>
          ))}
      </Row>
    </div>
  );
}

function GetRankingDictwdl(team_data, option, homw_away, htft, gh) {
  const dataArray = Object.entries(team_data && team_data);
  let end = "";
  let side = "";
  let sideleter = "";
  let hist = "";
  let wdl = "";
  if (htft === 0) {
    end = "ft";
  } else if (htft === 1) {
    end = "ht";
  } else if (htft === 2) {
    end = "2h";
  }
  if (gh === 0) {
    hist = "all";
  } else if (gh === 1) {
    hist = "15g";
  } else if (gh === 2) {
    hist = "10g";
  } else if (gh === 3) {
    hist = "5g";
  }
  if (homw_away === 0) {
    side = "_";
    sideleter = "all";
  } else if (homw_away === 2) {
    side = "_away_";
    sideleter = "away";
  } else if (homw_away === 1) {
    side = "_home_";
    sideleter = "home";
  }
  if (option === "Winners") {
    wdl = "win";
  } else if (option === "Lossers") {
    wdl = "loss";
  } else if (option === "Tiers") {
    wdl = "draw";
  }
  // Sort the array based on the difference between "val" in "game1" and "game2"
  dataArray.sort((a, b) => {
    ////console.log(a[1]?.numbers?.[sideleter]);
    const diffA =
      a[1]?.numbers?.[sideleter]?.[hist]?.new?.[
        "new_wdl" + side + "trail_stats"
      ]?.team_wdl_trail?.["count_" + wdl + "_" + end] -
      a[1]?.numbers?.[sideleter]?.[hist]?.old?.[
        "old_wdl" + side + "trail_stats"
      ]?.team_wdl_trail?.["count_" + wdl + "_" + end];
    const diffB =
      b[1]?.numbers?.[sideleter]?.[hist]?.new?.[
        "new_wdl" + side + "trail_stats"
      ]?.team_wdl_trail?.["count_" + wdl + "_" + end] -
      b[1]?.numbers?.[sideleter]?.[hist]?.old?.[
        "old_wdl" + side + "trail_stats"
      ]?.team_wdl_trail?.["count_" + wdl + "_" + end];
    ////console.log(diffA, diffB);
    return diffB - diffA; // Sort in ascending order; use diffB - diffA for descending
  });

  // Create a ranked object
  const rankedData = {};
  dataArray.forEach((item, index) => {
    const key = (index + 1).toString();
    rankedData[key] = item[0];
  });

  return rankedData;
}

export default function DiaplayWinDrawLoose({
  data,
  aha,
  distype,
  half,
  history,
  ranktype,
  teamA,
  teamB,
  small,
  mid,
}) {
  ////console.log(data);

  const { globalUser } = useContext(GlobalUserContext);

  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1200 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []);
  const DisplayData = ({
    teamData,
    pos,
    tID,
    option,
    homw_away,
    htft,
    gh,
    teamA,
    teamB,
  }) => {
    let end = "";
    let side = "";
    let sideleter = "";
    let hist = "";
    let wdl = "";
    if (htft === 0) {
      end = "ft";
    } else if (htft === 1) {
      end = "ht";
    } else if (htft === 2) {
      end = "2h";
    }
    if (gh === 0) {
      hist = "all";
    } else if (gh === 1) {
      hist = "15g";
    } else if (gh === 2) {
      hist = "10g";
    } else if (gh === 3) {
      hist = "5g";
    }
    if (homw_away === 0) {
      side = "_";
      sideleter = "all";
    } else if (homw_away === 2) {
      side = "_away_";
      sideleter = "away";
    } else if (homw_away === 1) {
      side = "_home_";
      sideleter = "home";
    }
    if (option === "Winners") {
      wdl = "win";
    } else if (option === "Lossers") {
      wdl = "loss";
    } else if (option === "Tiers") {
      wdl = "draw";
    }
    const navigation = useNavigate();
    const handleteamclick = ({ val, team_, league }) => {
      const team = val;
      navigation("/team/" + team_, { state: { team, league } });
    };
    // Define your styled component outside of your function component.
    const tID2 = teamData?._id.split("_").pop();
    let condition =
      String(tID2 && tID2) === String(teamA && teamA) ||
      String(tID2 && tID2) === String(teamB && teamB)
        ? "Skyblue"
        : "transparent";
    
    return (
      <>
        <td>
          <div
            style={{
              backgroundColor: condition,
              display: "flex",
              width: "20px",
              height: "20px",
              justifyContent: "center",
            }}
          >
            {pos}
          </div>
        </td>
        <td
          className="LhomeOR link"
          onClick={() =>
            handleteamclick({
              val: parseInt(tID2),
              team_: teamData?.numbers?.team_meta?.team_name,
              league: teamData?.numbers?.team_meta?.team_league,
            })
          }
        >
          <img
            src={Team_image?.[tID2]?.["image"]}
            width="20"
            height="15"
            alt={teamData?.numbers?.team_meta?.team_name}
          />{" "}
          {teamData?.numbers?.team_meta?.team_name}{" "}
        </td>
        {isSmallScreen || isMidScreen ? (
          <></>
        ) : (
          <>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </>
        )}

        <td>
          {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
            "new_mclns" + side + "trail_stats"
          ]?.["shots"]?.["game_count"] ||
            0 -
              teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                "old_mclns" + side + "trail_stats"
              ]?.["shots"]?.["game_count"] ||
            0}
        </td>
        <td>
          {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
            "new_wdl" + side + "trail_stats"
          ]?.team_wdl_trail?.["count_win_" + end] ||
            0 -
              teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                "old_wdl" + side + "trail_stats"
              ]?.team_wdl_trail?.["count_win_" + end] ||
            0}
        </td>
        {small ? (
          <>
            <td className="link">
              <IoIosArrowDropdownCircle size={20} color="gray" />
            </td>
          </>
        ) : (
          <>
            <td>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new_wdl" + side + "trail_stats"
              ]?.team_wdl_trail?.["count_draw_" + end] ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old_wdl" + side + "trail_stats"
                  ]?.team_wdl_trail?.["count_draw_" + end] ||
                0}
            </td>
            <td>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new_wdl" + side + "trail_stats"
              ]?.team_wdl_trail?.["count_loss_" + end] ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old_wdl" + side + "trail_stats"
                  ]?.team_wdl_trail?.["count_loss_" + end] ||
                0}
            </td>
            <td>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new" + side + "trail_stats"
              ]?.trail_team_goal_Count ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old" + side + "trail_stats"
                  ]?.trail_team_goal_Count ||
                0}
            </td>
            <td>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new" + side + "trail_stats"
              ]?.trail_match_goal_Count ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old" + side + "trail_stats"
                  ]?.trail_match_goal_Count ||
                0 -
                  (teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                    "new" + side + "trail_stats"
                  ]?.trail_team_goal_Count ||
                    0 -
                      teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                        "old" + side + "trail_stats"
                      ]?.trail_team_goal_Count ||
                    0)}
            </td>
            <td style={{ textAlign: "center" }}>
              {parseInt(
                teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                  "new_mclns" + side + "trail_stats"
                ]?.["possession"]?.["poss_Ppg"] ?? 0
              )}
            </td>
            <td style={{ textAlign: "center" }}>
              {parseInt(
                teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                  "new_mclns" + side + "trail_stats"
                ]?.["attacks"]?.["da_att_Ppg"] ?? 0
              )}
            </td>
            <td>
              <DisplayForm
                trail={
                  teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                    "new_wdl" + side + "trail_stats"
                  ]?.team_wdl_trail?.["trail_" + end]
                }
                gh={gh}
                small={isSmallScreen}
                mid={isMidScreen}
              />
            </td>
          </>
        )}
      </>
    );
  };

  const DisplayDrop = ({
    teamData,
    pos,
    tID,
    option,
    homw_away,
    htft,
    gh,
    teamA,
    teamB,
  }) => {
    ////console.log(data);

    let end = "";
    let side = "";
    let sideleter = "";
    let hist = "";
    let wdl = "";
    if (htft === 0) {
      end = "ft";
    } else if (htft === 1) {
      end = "ht";
    } else if (htft === 2) {
      end = "2h";
    }
    if (gh === 0) {
      hist = "all";
    } else if (gh === 1) {
      hist = "15g";
    } else if (gh === 2) {
      hist = "10g";
    } else if (gh === 3) {
      hist = "5g";
    }
    if (homw_away === 0) {
      side = "_";
      sideleter = "all";
    } else if (homw_away === 2) {
      side = "_away_";
      sideleter = "away";
    } else if (homw_away === 1) {
      side = "_home_";
      sideleter = "home";
    }
    if (option === "Winners") {
      wdl = "win";
    } else if (option === "Lossers") {
      wdl = "loss";
    } else if (option === "Tiers") {
      wdl = "draw";
    }
    const navigation = useNavigate();
    const handleteamclick = ({ val, team_, league }) => {
      const team = val;
      navigation("/team/" + team_, { state: { team, league } });
    };
    // Define your styled component outside of your function component.

    let condition =
      String(tID && tID) === String(teamA && teamA) ||
      String(tID && tID) === String(teamB && teamB)
        ? "Skyblue"
        : "transparent";
    return (
      <div>
        <div className="center_div_distributed "></div>

        <div className="center_div_distributed margin-30down">
          <div>
            <div>D</div>
            <div>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new_wdl" + side + "trail_stats"
              ]?.team_wdl_trail?.["count_draw_" + end] ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old_wdl" + side + "trail_stats"
                  ]?.team_wdl_trail?.["count_draw_" + end] ||
                0}
            </div>
          </div>
          <div>
            <div>L</div>
            <div>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new_wdl" + side + "trail_stats"
              ]?.team_wdl_trail?.["count_loss_" + end] ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old_wdl" + side + "trail_stats"
                  ]?.team_wdl_trail?.["count_loss_" + end] ||
                0}
            </div>
          </div>
          <div>
            <div>GF</div>
            <div>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new" + side + "trail_stats"
              ]?.trail_team_goal_Count ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old" + side + "trail_stats"
                  ]?.trail_team_goal_Count ||
                0}
            </div>
          </div>
          <div>
            <div>GA</div>
            <div>
              {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                "new" + side + "trail_stats"
              ]?.trail_match_goal_Count ||
                0 -
                  teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                    "old" + side + "trail_stats"
                  ]?.trail_match_goal_Count ||
                0 -
                  (teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                    "new" + side + "trail_stats"
                  ]?.trail_team_goal_Count ||
                    0 -
                      teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                        "old" + side + "trail_stats"
                      ]?.trail_team_goal_Count ||
                    0)}
            </div>
          </div>
          <div className="center_div center_straight">
            <div>APS AV</div>
            <div>
              {parseInt(
                teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                  "new_mclns" + side + "trail_stats"
                ]?.["possession"]?.["poss_Ppg"] ?? 0
              )}
            </div>
          </div>
          <div className="center_div center_straight">
            <div>DATT AV</div>
            <div>
              {parseInt(
                teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                  "new_mclns" + side + "trail_stats"
                ]?.["attacks"]?.["da_att_Ppg"] ?? 0
              )}
            </div>
          </div>
        </div>
        <div className=" ">
          <div className="center_div ">
            <div className="bold">{"<-- Form <--"}</div>
          </div>
          <div className="center_div ">
            <div>
              <DisplayForm
                trail={
                  teamData?.numbers?.[sideleter]?.[hist]?.new?.[
                    "new_wdl" + side + "trail_stats"
                  ]?.team_wdl_trail?.["trail_" + end]
                }
                gh={gh}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  function DisplayTable(params) {
    const [isdropdownClick, setisdropdownClick] = useState(false);

    const handleDropclick = (index) => {
      // if the row was already open, close it
      if (isdropdownClick === index) {
        setisdropdownClick(null);
      } else {
        // otherwise, open the clicked row
        setisdropdownClick(index);
      }
    };
    const Ranking =
      data &&
      Object.entries(GetRankingDictwdl(data, ranktype, aha, half, history));
    ////console.log(Ranking);
    return (
      <div className="nav_scroller">
        <Modal.Dialog>
          <Modal.Body>
            <Table
              className={`${globalUser?.user?.theme?.outerTableboarder  } `}
              striped
              hover
              variant="light"
            >
              <thead className="th">
                <tr>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        width: "20px",
                        height: "20px",
                        justifyContent: "center",
                      }}
                    >
                      P
                    </div>
                  </th>
                  <th>Team</th>

                  {isSmallScreen || isMidScreen ? (
                    <></>
                  ) : (
                    <>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </>
                  )}

                  <th>MP</th>
                  <th>W</th>
                  {small ? (
                    <>
                      <th>Optns</th>
                    </>
                  ) : (
                    <>
                      <th>D</th>
                      <th>L</th>
                      <th>GF</th>
                      <th>GA</th>
                      <th style={{ textAlign: "center" }}>APS AV</th>
                      <th style={{ textAlign: "center" }}>DATT AV</th>
                      <th style={{ textAlign: "center" }}>{"<-- Form <--"}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data &&
                  Ranking.map(([key, value]) => (
                    <React.Fragment key={key}>
                      <tr
                        className="custom-row-style"
                        onClick={() => handleDropclick(key)}
                      >
                        <DisplayData
                          teamData={data?.[value]}
                          pos={key}
                          tID={value}
                          option={ranktype}
                          homw_away={aha}
                          htft={half}
                          gh={history}
                          teamA={teamA}
                          teamB={teamB}
                        />
                      </tr>
                      {isdropdownClick === key ? (
                        <tr colSpan="100%">
                          <td colSpan="100%">
                            <DisplayDrop
                              teamData={data?.[value]}
                              pos={key}
                              tID={value}
                              option={ranktype}
                              homw_away={aha}
                              htft={half}
                              gh={history}
                              teamA={teamA}
                              teamB={teamB}
                            />
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }

  return (
    <div>
      {distype === "Table" ? (
        <div>
          <DisplayTable />
        </div>
      ) : (
        <LeagueStatisticsWDL
          data={data}
          half={half}
          ranktype={ranktype}
          lgNav={"null"}
          hist={history}
          ranking={
            data &&
            Object.entries(
              GetRankingDictwdl(data, ranktype, aha, half, history)
            )
          }
        />
      )}
    </div>
  );
}
