import {
  MDBAlert,
  MDBBtn,
  MDBCardBody,
  MDBInput,
  MDBToast,
  MDBTooltip,
} from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { CiFaceSmile } from "react-icons/ci";
import { FaRegComment } from "react-icons/fa";
import { InfoTag } from "../DisplayInfo";
import EmojiPicker from "emoji-picker-react";
import { SaveComments } from "./SaveComments";

export default function CollectComments(props) {
  const [isClickEmoji, setIsClickEmoji] = useState(false);
  const [inputChat, setInputChat] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const wrapperRef = useRef(null);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (isSaved) {
      const timer = setTimeout(() => {
        setIsSaved(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isSaved]);
  useEffect(() => {
    const data = "";
  }, [props?.owner?.email]);
  const handleEmojiInput = (emoji) => {
    ////console.log(emoji)
    setInputChat((prev) => `${prev}${emoji?.emoji}`);
  };
  const handleSendClick = (e) => {
    //console.log(props);
    const currTime = Date.now() / 1000;
    const needinfo = {
      ownerDetails: props?.paticipant ?? "",
      type: props?.commentType ?? "comment",
      parentDetails: props?.parent ?? "",
      secondParent: props?.secondParent ?? "",
      message: !e ? inputChat : e.target.innerHTML,
      page: props?.page,
      replyOwner: props?.owner ?? "",
      targetPage: props?.targetPage,
      targetObject: props?.targetObject,
      likes: [],
      chilren: [],
      time: currTime,
    };
    //console.log(needinfo)
    const res = SaveComments(needinfo, props?.socketInstance);

    //console.log(needinfo ,res)
    if (res === "success") {
      setIsSaved(() => true);
      setInputChat("");
      props?.setReloadChat?.((prev)=>{
        if(prev === "false"){
          return "true"
        }else{
          return "false"
        }
      })
      if(props?.commentType === "reply"){
        props?.setCommentType?.((prev) => "comment")
      }
      
    }
  };
  useEffect(() => {
    //console.log(props?.commentType)
    if (props?.commentType === "reply") {
      let name = `<a href="/publicProfile/">@${props?.replyContent?.name}</a>`;
      setInputChat((prev) => name + " " +prev);
    } else {
      setInputChat((prev) => {
        // Use regex to match the specific HTML tag pattern and replace it with an empty string
        let updatedText = prev.replace(/<a href="\/publicProfile\/">.*?<\/a>/, '');
        return updatedText;
      });
    }
  }, [props?.commentType]);
  const HandleInput = (e) => {
    //console.log(e.target.innerHTML);
    setInputChat(e.target.innerHTML);
  };
  ///{props?.commentType === "reply" && <a href="/publicProfile/">{props?.commentType === "reply" ? "@" + props?.replyContent?.name : ""}</a>}
  ////console.log(props?.commentType,props?.replyContent)
  return (
    <div className="usemaxSpace allpad">
      <div className="">
        <div className="d-flex">
          <div className="center_div">
            <MDBBtn
              className="px-2"
              size="sm"
              onClick={() => setIsClickEmoji(() => !isClickEmoji)}
            >
              <CiFaceSmile size={20} />
            </MDBBtn>
          </div>

          <div className="w-100 mx-2" ref={wrapperRef}>
            <div
              className="inputTextDiv"
              contentEditable
              onBlur={(e) => setInputChat((prev) => e.target.innerHTML)}
              dangerouslySetInnerHTML={{ __html: inputChat }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // Call the function you want to trigger here
                  e.preventDefault(); // Prevents form submission in case this input is inside a <form>
                  HandleInput(e);
                  setInputChat((prev) => e.target.innerHTML);
                  handleSendClick(e);
                  e.target.innerHTML = ""

                }
              }}
            ></div>
            {/* <MDBInput
              type="text"
              label={
                isSaved ? (
                  <MDBAlert
                    color="light"
                    autohide
                    position="top-center"
                    delay={3000}
                    open={isSaved}
                    containerRef={wrapperRef}
                  >
                    Comment shared!!
                  </MDBAlert>
                ) : (
                  "Share your thoughts on this ticket"
                )
              }
              name="chat"
              value={inputChat ?? ""}
              onChange={(e) => {
                setInputChat((prev) => e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // Call the function you want to trigger here
                  e.preventDefault(); // Prevents form submission in case this input is inside a <form>
                  handleSendClick();
                }
              }}
            /> */}
          </div>

          <MDBBtn className="" size="sm" onClick={() => {
            handleSendClick(null)}}>
            <div className="center_div" style={{ textTransform: "none" }}>
              <MDBTooltip tag="div" title="Share your thoughts!">
                <div>
                  <FaRegComment size={20} />
                </div>
              </MDBTooltip>
            </div>
          </MDBBtn>
        </div>
        {isClickEmoji && (
          <EmojiPicker
            onEmojiClick={(e) => {
              handleEmojiInput(e), setIsClickEmoji(() => false);
            }}
          />
        )}
      </div>
    </div>
  );
}
