import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../css/LeagueSummaryMixedChart.css";
import Team_image from "../../images/team_images.json";

export default function LeagueStatsChartOvUn({
  data,
  ranktype,
  lgNav,
  hist,
  half,
  ranking,
}) {
  const navigation = useNavigate();

  const toolchartdict = {};
  toolchartdict["Goal Scorers"] = {
    goalschart: [
      "day",
      "1h_gf",
      "2h_ga",
      "2h_gf",
      "1h_ga",
      "team_SOTarget",
      "opponent_SOTarget",
    ],
    goalsTool: [
      "homeID",
      "awayID",
      "homeName",
      "awayName",
      "score",
      "shots",
      "d_attack",
      "possession",
      "matchID",
    ],
  };
  toolchartdict["card recievers"] = {
    goalschart: [
      "day",
      "1h_ca_f",
      "2h_ca_a",
      "2h_ca_f",
      "1h_ca_a",
      "team_foul",
      "opponent_foul",
    ],
    goalsTool: [
      "homeID",
      "awayID",
      "homeName",
      "awayName",
      "foul",
      "cards",
      "d_attacks",
      "possession",
      "matchID",
    ],
  };
  toolchartdict["corner winners"] = {
    goalschart: [
      "day",
      "1h_co_f",
      "2h_co_a",
      "2h_co_f",
      "1h_co_a",
      "team_GoalKicks",
      "opponent_GoalKicks",
    ],
    goalsTool: [
      "homeID",
      "awayID",
      "homeName",
      "awayName",
      "corners",
      "offsides",
      "goalkicks",
      "throwins",
      "matchID",
    ],
  };
  let type = "";
  if (ranktype === "Goals") {
    type = "Goal Scorers";
  } else if (ranktype === "Corners") {
    type = "corner winners";
  } else if (ranktype === "Cards") {
    type = "card recievers";
  }
  const RenderTeam = ({ own_team, rank }) => {
    let gh = "";
    if (hist === 0) {
      gh = "all";
    } else if (hist === 1) {
      gh = "15g";
    } else if (hist === 2) {
      gh = "10g";
    } else if (hist === 3) {
      gh = "5g";
    }

    let TeamDataChart = data?.[own_team]?.charts?.[gh]?.[type]?.chart;
    //const chartlabel = Object.keys(data?.[own_team]?.[type]?.chart);
    ////console.log(toolchartdict?.[type]);
    const tooltipData = data?.[own_team]?.charts?.[gh]?.[type]?.tooltip;
    class CustomDot extends React.Component {
      handlematchlick = (payload) => {
        ////console.log(payload);
        const day = payload?.day;
        const match_id = tooltipData?.[day]?.match?.matchID;
        const team2 = tooltipData?.[day]?.match?.awayID;
        const team1 = tooltipData?.[day]?.match?.homeID;
        const seas = " ";
        navigation("/match/" + match_id, {
          state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
        });
      };
      render() {
        const { cx, cy, payload } = this.props;
        if (payload?.day) {
          return (
            <circle
              cx={cx}
              cy={cy}
              r={3}
              fill="grey"
              className="link"
              stroke="none"
              onClick={() => this.handlematchlick(payload)}
            />
          );
        }
      }
    }

    const handlematchlick = (data) => {
      const day = data?.day;
      const match_id = tooltipData?.[day]?.match?.matchID;
      const team2 = tooltipData?.[day]?.match?.awayID;
      const team1 = tooltipData?.[day]?.match?.homeID;
      const seas = " ";
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };
    const GettoolTip = ({ date, own_team, tooltipData }) => {
      tooltipData = tooltipData?.[date];

      const tooltiplabel = toolchartdict?.[type]?.goalsTool
        ?.slice(4)
        .slice(0, -1);
      ////console.log(tooltiplabel);
      ////console.log(date, tooltipData);
      return (
        <div>
          <Modal.Dialog>
            <Modal.Body className="tooltipbg">
              <Container className="justify-content-between align-items-center myContainer">
                <Row>
                  <Col>
                    <h6>{tooltipData?.date}</h6>
                  </Col>
                </Row>

                {tooltiplabel.map((item, index) => {
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <h6> {item}</h6>
                      </div>
                      <Row>
                        <Col>
                          <h5>{tooltipData?.match?.homeName}</h5>
                        </Col>
                        <Col>
                          <h5>{tooltipData?.match?.[item]}</h5>
                        </Col>
                        <Col>
                          <h5>{tooltipData?.match?.awayName}</h5>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Container>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      );
    };
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div>
            <GettoolTip
              date={label}
              tooltipData={tooltipData}
              own_team={own_team}
            />
          </div>
        );
      }

      return null;
    };
    const handleteamclick = ({ val, team_, league }) => {
      const team_info = {
        team: val,
        league: league,
        data: "lgNav",
      };

      navigation("/team/" + team_, { state: team_info });
    };
    const tID2 = data?.[own_team]._id.split("_").pop();
    return (
      <div className="divmargin mainFont bold">
        <Row className="justify-content-between align-items-center">
          <Col md={3} className="mb-2">
            <Row className="justify-content-between align-items-center">
              <Col md={2} className="mb-3">
                {" "}
              </Col>
              <Col md={2} className="mb-3" style={{ paddingRight: "10px" }}>
                <div>{rank}</div>
              </Col>
              <Col md={8} className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={Team_image?.[tID2]?.["image"]}
                    className="link"
                    onClick={() =>
                      handleteamclick({
                        val: own_team,
                        team_: data?.[own_team]?.numbers?.team_meta?.team_name,
                        league:
                          data?.[own_team]?.numbers?.team_meta?.team_league,
                      })
                    }
                    alt={data?.[own_team]?.numbers?.team_meta?.team_name}
                    style={{
                      width: "100px", // or any value appropriate for your design
                      height: "100px", // keep aspect ratio if not defined
                    }}
                  />
                  <h6>{""}</h6>
                  <div>{data?.[own_team]?.numbers?.team_meta?.team_name}</div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={9} className="">
            <div style={{ width: "100%", height: "200px" }}>
              <ResponsiveContainer>
                <ComposedChart
                  data={TeamDataChart}
                  barGap={1}
                  margin={{
                    top: 20,
                    right: -20,
                    bottom: 5,
                    left: -30,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey={toolchartdict?.[type]?.goalschart?.[0]}
                    tick={{ fontSize: 12, fontFamily: "serif" }}
                  />
                  <YAxis
                    yAxisId="left"
                    tick={{ fontSize: 12, fontFamily: "serif" }}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{ fontSize: 12, fontFamily: "serif" }}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    offset={10}
                    allowEscapeViewBox={{ x: false, y: true }}
                  />
                  {half === 0 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[1]}
                      stackId="team"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#05a3a89d"
                    />
                  ) : half === 1 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[1]}
                      stackId="team"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#05a3a89d"
                    />
                  ) : null}
                  {half === 0 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[3]}
                      stackId="team"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#02500f9d"
                    />
                  ) : half === 2 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[3]}
                      stackId="team"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#02500f9d"
                    />
                  ) : null}
                  {half === 0 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[4]}
                      stackId="opp"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#ee91069d"
                    />
                  ) : half === 1 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[4]}
                      stackId="opp"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#ee91069d"
                    />
                  ) : null}

                  {half === 0 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[2]}
                      stackId="opp"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#ee3c06af"
                    />
                  ) : half === 2 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={toolchartdict?.[type]?.goalschart?.[2]}
                      stackId="opp"
                      barSize={20}
                      className="link"
                      onClick={(data) => handlematchlick(data)}
                      fill="#ee3c06af"
                    />
                  ) : null}

                  <Line
                    yAxisId="right"
                    type="monotone"
                    dot={<CustomDot />}
                    dataKey={toolchartdict?.[type]?.goalschart?.[5]}
                    stroke="#19ee06af"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dot={<CustomDot />}
                    dataKey={toolchartdict?.[type]?.goalschart?.[6]}
                    stroke="#1c55cfaf"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  ////console.log(data,ranking)
  return (
    <div className="divmarginb">
      <div style={{ flex: "6", alignSelf: "flex-start" }}>
        <Modal.Dialog className="rounded-3">
          <div
            className="center_div bold mainFont"
          >
            Bar and Line chart {type}
          </div>
          <div
            className="center_div_distributed tiny_font center_sides nav_scroller noyscrol mainFont"
          >
            <div className="nowrap"> Bars: Team | Opponent</div> &nbsp;&nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="team1st" /> &nbsp;
              <div className="nowrap"> Team 1st Half {type.split(" ")[0]}</div>
            </div>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="team2nd" /> &nbsp;
              <div className="nowrap"> Team 2nd Half {type.split(" ")[0]}</div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="opp1st" /> &nbsp;
              <div className="nowrap"> Opponent 1st Half {type.split(" ")[0]}</div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="opp2nd" /> &nbsp;
              <div className="nowrap"> Opponent 2nd Half {type.split(" ")[0]}</div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="line1" /> &nbsp;
              <div className="nowrap"> {toolchartdict?.[type]?.goalschart?.[5]}</div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="line2" /> &nbsp;
              <div className="nowrap"> {toolchartdict?.[type]?.goalschart?.[6]} </div>
            </div>
            &nbsp;&nbsp;
          </div>
          <Modal.Body>
            <div>
              {data &&
                ranking.map(([key, value], index) => (
                  <div key={index}>
                    <Modal.Dialog className="rounded-3">
                      <Modal.Body>
                        <RenderTeam key_={key} own_team={value} rank={key} />
                      </Modal.Body>
                    </Modal.Dialog>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </div>
  );
}
