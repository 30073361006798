import { MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import DicplayComments from "./DicplayComments";
import CollectComments from "./CommentsInput";
import BottomAverts from "../AdvertsComponents/BottomAdvertsBadges";
import FooterAll from "../Footer";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";



const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;
export default function EnterCommentsAnonymous (props) {
    const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
    const [loading, setLoading] = useState(true);
  const location = useLocation();
  const userinfo = location.state?.userinfo || {};
  
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [reloadChat, setReloadChat] = useState("false");
  const [commentType, setCommentType] = useState("comment");
  const [replyContent, setReplyContent] = useState(props?.ObjectItem?.originalOwner);
  const [socketInstance, setSocketInstance] = useState();
  const tic_data = location?.state || null;
  ////console.log(tic_data, location);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const socket2 = io(BackSocket, {
        auth: {
          token: BackSocketToken,
        },
      });
      setSocketInstance(socket2);
},[])
    //console.log(props)
    return (
        <div>
            <MDBCard
          alignment="center"
          className="usemaxSpace margin-50down"
          style={{ height: isSmallScreen ? "500px" : "650px" }}
        >
          <MDBCardHeader className="usemaxSpace">
            <div style={{ width: isSmallScreen ? "100%" : "50%" }}>
              <div className="usemaxSpace center_div">
                <div className=" center_div_distributed usemaxSpace">
                  <div className="center_div center_sides">
                    <div className="allpad">Comments Section</div>
                  </div>
                  {props?.ObjectItem?.comments ? (
                    <div className="center_div center_sides">
                      <div className="center_div center_sides">
                        <div>{props?.ObjectItem?.comments ?? "No"}</div>
                        &nbsp;
                        <div>
                          {"Comment"}
                          {props?.ObjectItem?.comments > 1 ? "s" : ""}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="center_div center_sides">
                      <div className="center_div center_sides">
                        <div>Be the first to comment !!</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </MDBCardHeader>
          <MDBCardBody
            className="overflow-scroll noscrolbar "
            style={{ position: "relative", overflow: "hidden" }}
          >
            <DicplayComments
              socketInstance={socketInstance}
              originalOwner={props?.ObjectItem?.originalOwner}
              userData={globalUser}
              targetObject={props?.ObjectItem?.targetObject}
              targetDb={props?.ObjectItem?.targetDB}
              setCommentType={setCommentType}
              setReplyContent={setReplyContent}
              reloadChat={reloadChat}
            />
          </MDBCardBody>
          <MDBCardFooter>
            <CollectComments
              socketInstance={socketInstance}
              owner={replyContent}
              parent={{ site: props?.ObjectItem?.site, object: props?.ObjectItem?.object , type:props?.ObjectItem?.mainType}}
              paticipant={{
                name:
                  globalUser?.user?.tipstarDetails?.tipstarName ??
                  globalUser?.user?.firstname,
                email: globalUser?.user?.email,
              }}
              userData={globalUser}
              targetPage={props?.ObjectItem?.targetPage}
              targetObject={props?.ObjectItem?.targetObject}
              commentType={commentType}
              replyContent={replyContent}
              setReloadChat={setReloadChat}
              setCommentType={setCommentType}
            />
          </MDBCardFooter>
        </MDBCard>
        <div className="center_div margin-50down">
          <BottomAverts />
        </div>
       
        </div>
    )
}