import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyHeader from "./Emptyheader";
import axios from "axios";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import FooterAll from "./Footer";
import "../css/ThemeCss.css";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

////console.log(WEB);
export default function VerifyEmail() {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [clickedVerify, setClickedVerify] = useState(false);
  const [needData, setNeedData] = useState();
  const [expeired, setExpired] = useState(false);
  const [userinfo, setCurrUser] = useState();
  const navigation = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      let params = new URLSearchParams(window.location.search);
      let data = params.get("data");
      const decoder = await axios.post(`${API_URL}/pushtemp`, {
        type: "get",
        _id: data,
      });
      if (decoder?.data?.response === "success") {
        const userdata = decoder?.data?.savedData;
        let firstname = userdata?.user_name;
        let email = userdata?.user_email?.[0];
        let exptime = userdata?.exptime;
        let senttime = userdata?.senttime;
        let session = userdata?.session;
        let now = new Date();
        let currentTime = Math.floor(now.getTime() / 1000);
        if (currentTime > exptime) {
          setExpired(true);
        } else {
          setNeedData({
            email: email,
            firstname: firstname,
            auth: session,
          });
        }
        ////console.log(firstname, email, exptime, session);
      }
    };
    fetchData();
  }, [setClickedVerify]);

  const handleVerify = async () => {
    const Emaildata = {
      user_name: needData?.firstname,
      user_email: [needData?.email],
      subject: "Email Verified",
      send_mail: "info@betinformatics.com",
      sub_name: "Bet Informatics",
      message_type: "emailThanks",
      links: [`${WEB}/pricing`],
    };
    ////console.log(needData);
    const resverify = await axios.post(`${API_URL}/verifyEmail`, needData);
    if (resverify?.data?.response === "success") {
      setCurrUser(resverify?.data?.savedData);
      updateGlobalUser({ user: resverify?.data?.savedData });
      const ckUser = await UpdateLocal({
        action: "save",
        data: { user: resverify?.data?.savedData },
      });
    }
    setClickedVerify(true);
    const res = await axios.post(`${API_URL}/send-mail`, Emaildata);
  };

  return (
    <div
      style={{
        background: "white",
        fontFamily: "serif",
      }}
    >
      <EmptyHeader />

      {clickedVerify ? (
        <Container className="appear_center ">
          <div>
            <div className="center_div infoFont margin-30down">
              Thank your for verifying your email.
            </div>
            <div className="">
              <div
                className="link smallbuton  divorangeButton"
                onClick={() => navigation("/", { state: { userinfo } })}
              >
                Go to Home
              </div>
            </div>
          </div>
        </Container>
      ) : expeired ? (
        <Container className="appear_center ">
          <div>
            <div className="center_div infoFont margin-30down">
              Link expired, Log in to resend link
            </div>
          </div>
        </Container>
      ) : (
        <Container className="appear_center ">
          <div>
            <div className="center_div infoFont margin-30down">
              Verify your email by clicking the button below.
            </div>
            <div className="">
              <div
                className="link smallbuton  divgreenButton"
                onClick={() => handleVerify()}
              >
                Verify
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
