import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Modal, Table, Container, Nav, Col } from "react-bootstrap";

import { GlobalUserContext } from "../contextRouters/UserInfoContext";

import Spinner from "../Spinner";
import LeagueStatsChartOvUn from "../ChartComponents/LeagueStatChartOverUnder";
import "../../css/LeagueStatsTableWinners.css";
import Team_image from "../../images/team_images.json";

function DisplayForm({ trail, gh }) {
  let hist = 0;
  let final_string = "";
  ////console.log(trail);
  if (gh === 0) {
    hist = trail.length;
  } else if (gh === 1) {
    hist = 16;
  } else if (gh === 2) {
    hist = 11;
  } else if (gh === 3) {
    hist = 6;
  }

  if (trail.length > hist) {
    final_string = trail.slice(-hist);
  } else {
    final_string = trail; // If trail.length <= hist, use the original string
  }

  return (
    <div>
      <Row className="Row">
        {final_string
          .split("")
          .reverse()
          .slice(0, 8) // This will only take the first 6 elements
          .map((char, index) => (
            <div key={index} className={char}>
              {char.toUpperCase()}
            </div>
          ))}
      </Row>
    </div>
  );
}

function GetRankingDictwdl(
  team_data,
  option,
  homw_away,
  htft,
  gh,
  mtype,
  measure
) {
  ////console.log(team_data)
  const dataArray = Object.entries(team_data && team_data);
  let end = "";
  let side = "";
  let sideleter = "";
  let hist = "";
  let wdl = "";
  let opt = "";
  if (htft === 0) {
    end = "ft";
  } else if (htft === 1) {
    end = "ht";
  } else if (htft === 2) {
    end = "2h";
  }
  if (gh === 0) {
    hist = "all";
  } else if (gh === 1) {
    hist = "15g";
  } else if (gh === 2) {
    hist = "10g";
  } else if (gh === 3) {
    hist = "5g";
  }
  if (homw_away === 0) {
    side = "_";
    sideleter = "all";
  } else if (homw_away === 2) {
    side = "_away_";
    sideleter = "away";
  } else if (homw_away === 1) {
    side = "_home_";
    sideleter = "home";
  }
  if (option === "Goals") {
    wdl = "goal";
    opt = "";
  } else if (option === "Corners") {
    wdl = "corner";
    opt = "_corner";
  } else if (option === "Cards") {
    wdl = "card";
    opt = "_card";
  }
  // Sort the array based on the difference between "val" in "game1" and "game2"
  dataArray.sort((a, b) => {
    ////console.log(a[1]?.numbers?.[sideleter]);
    const diffA =
      a[1]?.numbers?.[sideleter]?.[hist]?.new?.[
        "new" + opt + side + "trail_stats"
      ]?.[mtype + "_team_" + end]?.[measure] -
      a[1]?.numbers?.[sideleter]?.[hist]?.old?.[
        "old" + opt + side + "trail_stats"
      ]?.[mtype + "_team_" + end]?.[measure];
    const diffB =
      b[1]?.numbers?.[sideleter]?.[hist]?.new?.[
        "new" + opt + side + "trail_stats"
      ]?.[mtype + "_team_" + end]?.[measure] -
      b[1]?.numbers?.[sideleter]?.[hist]?.old?.[
        "old" + opt + side + "trail_stats"
      ]?.[mtype + "_team_" + end]?.[measure];
    ////console.log(diffA, diffB);
    return diffB - diffA; // Sort in ascending order; use diffB - diffA for descending
  });

  // Create a ranked object
  const rankedData = {};
  ////console.log(dataArray)
  dataArray.forEach((item, index) => {
    const key = (index + 1).toString();
    rankedData[key] = item[0];
  });
  ////console.log(rankedData)
  return rankedData;
}

export default function DiaplayOverUnder({
  data,
  aha,
  distype,
  half,
  history,
  ranktype,
  small,
  mid,
}) {
  ////console.log(data);
  const [displayTypeToShoGoals, setdisplayTypeToShowGoals] = useState([
    "1.5",
    "over",
  ]);
  const [displayTypeToShoCards, setdisplayTypeToShowCards] = useState([
    "2.5",
    "over",
  ]);
  const [displayTypeToShoCorners, setdisplayTypeToShowCorners] = useState([
    "2.5",
    "over",
  ]);

  const [goalunderover, setgoalunderover] = useState("over");
  const { globalUser } = useContext(GlobalUserContext);
  const DisplayData = ({
    teamData,
    pos,
    tID,
    option,
    homw_away,
    htft,
    gh,
    mtype,
    measure,
  }) => {
    let end = "";
    let side = "";
    let sideleter = "";
    let hist = "";
    let wdl = "";
    let opt = "";
    let sof = "";
    let sof_1 = "";
    let att = "";
    if (htft === 0) {
      end = "ft";
    } else if (htft === 1) {
      end = "ht";
    } else if (htft === 2) {
      end = "2h";
    }
    if (gh === 0) {
      hist = "all";
    } else if (gh === 1) {
      hist = "15g";
    } else if (gh === 2) {
      hist = "10g";
    } else if (gh === 3) {
      hist = "5g";
    }
    if (homw_away === 0) {
      side = "_";
      sideleter = "all";
    } else if (homw_away === 2) {
      side = "_away_";
      sideleter = "away";
    } else if (homw_away === 1) {
      side = "_home_";
      sideleter = "home";
    }
    if (option === "Goals") {
      wdl = "goal";
      opt = "";
      sof = "_mclns";
      sof_1 = ["shots", "t_Psontpg"];
      att = [
        ["attacks", "attack_Ppg"],
        ["attacks", "da_att_Ppg"],
      ];
    } else if (option === "Corners") {
      wdl = "corners";
      opt = "_corner";
      sof = "_mclns";
      sof_1 = ["offsides", "team_Pofpg"];
      att = [
        ["goal-kicks", "team_Pgkpg"],
        ["throwins", "thro_Ppg"],
      ];
    } else if (option === "Cards") {
      wdl = "card";
      opt = "_card";
      sof = "_card";
      sof_1 = ["foul_average", "foul_av"];
      att = [
        ["attacks", "attack_Ppg"],
        ["attacks", "da_att_Ppg"],
      ];
    }
    const navigation = useNavigate();
    const handleteamclick = ({ val, team_, league }) => {
      const team = val;
      navigation("/team/" + team_, { state: { team, league } });
    };
    const tID2 = teamData?._id.split("_").pop();
    
    return (
      <tr className="tr" style={{ backgroundColor: "yellow" }}>
        <td>
            {pos}
           </td>
        <td
          className="LhomeOR link"
          onClick={() =>
            handleteamclick({
              val: parseInt(tID2),
              team_: teamData?.numbers?.team_meta?.team_name,
              league: teamData?.numbers?.team_meta?.team_league,
            })
          }
        >
          <img
            src={Team_image?.[tID2]?.["image"]}
            width="20"
            height="15"
            alt={teamData?.numbers?.team_meta?.team_name}
          />{" "}
          {teamData?.numbers?.team_meta?.team_name}{" "}
        </td>

        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{ textAlign: "center" }}>
          {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
            "new_mclns" + side + "trail_stats"
          ]?.["shots"]?.["game_count"] -
            teamData?.numbers?.[sideleter]?.[hist]?.old?.[
              "old_mclns" + side + "trail_stats"
            ]?.["shots"]?.["game_count"]}
        </td>
        <td style={{ textAlign: "center" }}>
          {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
            "new" + opt + side + "trail_stats"
          ]?.[mtype + "_team_" + end]?.[measure] -
            teamData?.numbers?.[sideleter]?.[hist]?.old?.[
              "old" + opt + side + "trail_stats"
            ]?.[mtype + "_team_" + end]?.[measure]}
        </td>
        <td style={{ textAlign: "center" }}>
          {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
            "new" + opt + side + "trail_stats"
          ]?.["trail_team_" + wdl + "_Count"] -
            teamData?.numbers?.[sideleter]?.[hist]?.old?.[
              "old" + opt + side + "trail_stats"
            ]?.["trail_team_" + wdl + "_Count"]}
        </td>
        <td style={{ textAlign: "center" }}>
          {teamData?.numbers?.[sideleter]?.[hist]?.new?.[
            "new" + opt + side + "trail_stats"
          ]?.["trail_match_" + wdl + "_Count"] -
            teamData?.numbers?.[sideleter]?.[hist]?.old?.[
              "old" + opt + side + "trail_stats"
            ]?.["trail_match_" + wdl + "_Count"] -
            (teamData?.numbers?.[sideleter]?.[hist]?.new?.[
              "new" + opt + side + "trail_stats"
            ]?.["trail_team_" + wdl + "_Count"] -
              teamData?.numbers?.[sideleter]?.[hist]?.old?.[
                "old" + opt + side + "trail_stats"
              ]?.["trail_team_" + wdl + "_Count"])}
        </td>
        <td style={{ textAlign: "center" }}>
          {parseInt(
            teamData?.numbers?.[sideleter]?.[hist]?.new?.[
              "new" + sof + side + "trail_stats"
            ]?.[sof_1[0]]?.[sof_1[1]]
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          {parseInt(
            teamData?.numbers?.[sideleter]?.[hist]?.new?.[
              "new_mclns" + side + "trail_stats"
            ]?.["possession"]?.["poss_Ppg"]
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          {parseInt(
            teamData?.numbers?.[sideleter]?.[hist]?.new?.[
              "new_mclns" + side + "trail_stats"
            ]?.[att[0][0]]?.[att[0][1]]
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          {parseInt(
            teamData?.numbers?.[sideleter]?.[hist]?.new?.[
              "new_mclns" + side + "trail_stats"
            ]?.[att[1][0]]?.[att[1][1]]
          )}
        </td>
      </tr>
    );
  };
  function DisplayTable({ ovtype }) {
    let header = "";
    let mtype = "";
    if (ovtype === "Goals") {
      header = [
        "OPTCNT",
        "GL/FR",
        "GL/AG",
        "SHTG AV",
        "APS AV",
        "ATT AV",
        "DATT AV",
      ];
      mtype = displayTypeToShoGoals[0];
    } else if (ovtype === "Cards") {
      header = [
        "OPTCNT",
        "CAD/F",
        "CAD/A",
        "FOWL AV",
        "APS AV",
        "ATT AV",
        "DATT AV",
      ];
      mtype = displayTypeToShoCards[0];
    } else if (ovtype === "Corners") {
      header = [
        "OPTCNT",
        "CON/F",
        "CON/A",
        "OFSD AV",
        "APS AV",
        "GKs AV",
        "TRWN AV",
      ];
      mtype = displayTypeToShoCorners[0];
    }
    ////console.log(goalunderover, mtype);
    const Ranking =
      data &&
      Object.entries(
        GetRankingDictwdl(
          data,
          ranktype,
          aha,
          half,
          history,
          goalunderover,
          mtype
        )
      );
    ////console.log(Ranking);
    return (
      <div>
        <Modal.Dialog>
          <Modal.Body>
            <Table
              className={`outer-border-light ${small ?"scroll-table-x" :null} `}
              striped
              hover
              variant="light"
            >
              <thead className="th">
                <tr>
                  <th>P</th>
                  <th>Team</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>

                  <th style={{ textAlign: "center" }}>MP</th>
                  <th style={{ textAlign: "center" }}>{header[0]}</th>
                  <th style={{ textAlign: "center" }}>{header[1]}</th>
                  <th style={{ textAlign: "center" }}>{header[2]}</th>
                  <th style={{ textAlign: "center" }}>{header[3]}</th>
                  <th style={{ textAlign: "center" }}>{header[4]}</th>
                  <th style={{ textAlign: "center" }}>{header[5]}</th>
                  <th style={{ textAlign: "center" }}>{header[6]}</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  Ranking.map(([key, value]) => (
                    <DisplayData
                      teamData={data?.[value]}
                      pos={key}
                      tID={value}
                      option={ranktype}
                      homw_away={aha}
                      htft={half}
                      gh={history}
                      mtype={goalunderover}
                      measure={mtype}
                    />
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
  const handleNavClickgoals = (num, type) => {
    setdisplayTypeToShowGoals([num, type]);
  };
  const handleNavClickcards = (num, type) => {
    setdisplayTypeToShowCards([num, type]);
  };
  const handleNavClickcorners = (num, type) => {
    setdisplayTypeToShowCorners([num, type]);
  };
  const handleNavClickUNtype = (type) => {
    setgoalunderover(type);
  };
  function GoalsOptions({ half }) {
    //This handles the display of sublinks for different modal footer
    let ovun = "";
    if (half === 0) {
      ovun = ["0.5", "1.5", "2.5", "3.5", "4.5"];
    } else if (half === 1) {
      ovun = ["0.5", "1.5", "2.5"];
    } else if (half === 2) {
      ovun = ["0.5", "1.5", "2.5"];
    }
    return (
      <div className="mainFont margin-5down">
        <div className="left_div center_sides nav_scroller noyscrol">
          {ovun.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                displayTypeToShoGoals[0] === item  ? "div_active link forceblack"
                      : "dormant link forceblack"
              }`}
              onClick={() => handleNavClickgoals(item, goalunderover)}
            >
              {small ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  function CardsOptions({ half }) {
    //This handles the display of sublinks for different modal footer
    let ovun = "";
    if (half === 0) {
      ovun = ["0.5", "1.5", "2.5", "3.5", "4.5"];
    } else if (half === 1) {
      ovun = ["0.5", "1.5", "2.5"];
    } else if (half === 2) {
      ovun = ["0.5", "1.5", "2.5"];
    }

    return (
      <div className="mainFont margin-5down">
        <div className="left_div center_sides nav_scroller noyscrol">
          {ovun.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                displayTypeToShoCards[0] === item ? "div_active link forceblack"
                      : "dormant link forceblack"
              }`}
              onClick={() => handleNavClickcards(item, goalunderover)}
            >
              {small ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  function CornersOptions({ half }) {
    //This handles the display of sublinks for different modal footer
    let ovun = "";
    if (half === 0) {
      ovun = ["2.5", "3.5", "4.5", "5.5", "6.5", "7.5"];
    } else if (half === 1) {
      ovun = ["0.5", "1.5", "2.5", "3.5", "4.5"];
    } else if (half === 2) {
      ovun = ["0.5", "1.5", "2.5", "3.5", "4.5"];
    }

    return (
      <div className="mainFont margin-5down">
        <div className="left_div center_sides nav_scroller noyscrol">
          {ovun.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                displayTypeToShoCorners[0] === item ? "div_active link forceblack"
                      : "dormant link forceblack"
              }`}
              onClick={() => handleNavClickcorners(item, goalunderover)}
            >
              {small ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  function UNType({ Links_ }) {
    //This handles the display of sublinks for different modal footer

    return (
      <div className="mainFont margin-5down">
        <div className="left_div center_sides nav_scroller noyscrol">
          <div
            className={`breadNav_light_second_small sidepad ${goalunderover === "over" ? "div_active link forceblack"
                      : "dormant link forceblack"}`}
            onClick={() => handleNavClickUNtype("over")}
          >
            {small ? (
                    <div className="tiny_font nowrap">{"Over"}</div>
                  ) : (
                    <>{"Over"}</>
                  )}
          </div>
          <div
            className={`breadNav_light_second_small sidepad ${goalunderover === "under" ? "div_active link forceblack"
                      : "dormant link forceblack"}`}
            onClick={() => handleNavClickUNtype("under")}
          >
            {small ? (
                    <div className="tiny_font nowrap">{"Under"}</div>
                  ) : (
                    <>{"Under"}</>
                  )}
          </div>
        </div>
      </div>
    );
  }
  let mtype = "";
  if (ranktype === "Goals") {
    mtype = displayTypeToShoGoals[0];
  } else if (ranktype === "Cards") {
    mtype = displayTypeToShoCards[0];
  } else if (ranktype === "Corners") {
    mtype = displayTypeToShoCorners[0];
  }
  return (
    <div>
      {distype === "Table" ? (
        <div>
          <div className=" margin-10down">
            <Row>
              <Col sm={6} xs={6} md={5} lg={4} className="">
                <UNType />
              </Col>
              {ranktype === "Goals" ? (
                <Col sm={6} xs={6} md={5} lg={4} className="">
                  <GoalsOptions half={half} />
                </Col>
              ) : ranktype === "Corners" ? (
                <Col sm={6} xs={6} md={5} lg={4} className="">
                  <CornersOptions half={half} />
                </Col>
              ) : (
                <Col sm={6} xs={6} md={5} lg={4} className="">
                  <CardsOptions half={half} />
                </Col>
              )}
            </Row>
          </div>
          <DisplayTable ovtype={ranktype} />
        </div>
      ) : (
        <div>
          <div className=" margin-10down">
            <Row>
              <Col sm={6} xs={6} md={5} lg={4} className="">
                <UNType />
              </Col>
              {ranktype === "Goals" ? (
                <Col sm={6} xs={6} md={5} lg={4} className="">
                  <GoalsOptions half={half} />
                </Col>
              ) : ranktype === "Corners" ? (
                <Col sm={6} xs={6} md={5} lg={4} className="">
                  <CornersOptions half={half} />
                </Col>
              ) : (
                <Col sm={6} xs={6} md={5} lg={4} className="">
                  <CardsOptions half={half} />
                </Col>
              )}
            </Row>
          </div>
          <LeagueStatsChartOvUn
            data={data}
            ranktype={ranktype}
            lgNav={"null"}
            hist={history}
            half={half}
            ranking={
              data &&
              Object.entries(
                GetRankingDictwdl(
                  data,
                  ranktype,
                  aha,
                  half,
                  history,
                  goalunderover,
                  mtype
                )
              )
            }
          />
        </div>
      )}
    </div>
  );
}
