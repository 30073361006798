import React from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./css/index.css";
import App from "./js/App";
import { UserInfoContext } from "./components/contextRouters/UserInfoContext";
import { Buffer } from 'buffer';
window.Buffer = Buffer;
const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserInfoContext>
      <App />
    </UserInfoContext>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(); */
