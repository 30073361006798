import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";

import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";

import Team_image from "../../images/team_images.json";
import { convertUnixToTime } from "../Utils";

import "../../css/Teamstats.css";
import { Errorlog } from "../Utils";
import { IoPlayForward } from "react-icons/io5";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;

function LeagueFixtures(props) {
  const { globalUser } = useContext(GlobalUserContext);
  const [monthCount, setMonthCount] = useState(3);
  const [loading, setLoading] = useState(true);
  const [Data, setdata] = useState();
  const [mid, setIsMidScreen] = useState(false);
  const [small, setIsSmallScreen] = useState(false);
  const [sType, setSType] = useState("future");

  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1200 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  ////console.log(Tab, Tab2);
  useEffect(() => {
    //setFixture(() => {});
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    if (props?.askleague !== null) {
      //console.log(props?.askleague);
      const get_tmdata = async () => {

        let options = {
          body: JSON.stringify({
            league_id: props?.askleague,
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },

          seconds: 2 * 60 * 60,
        };
        let url = "/league_fixtures";
        let indexdb = "league_fixtures";
        let SaveKey = props?.askleague;

        try {
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          
          /* const res = await axios.post(
            `${API_URL}/league_fixtures`,
            JSON.stringify({
              league_id: props?.askleague,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = JSON.parse(res?.data);
          //console.log(fix_data);
          if (res?.status === "success") {
            setdata(fix_data);
            setLoading(false);
          } else {
            Errorlog(
              "loaderror",
              "error loading statistics",
              "from league stats"
            );
          }
        } catch (error) {
          Errorlog(error, "error loading statistics", "from league stats");
          ////console.log(error);
        }
      };
      get_tmdata();
    }
  }, []);

  if (!Data || loading) {
    return <Spinner />;
  }
  function MultiLeague({ Data_, date_, small, mid }) {
    const navigation = useNavigate();
    const DisplayData = ({ Indata }) => {
      const WinnerButton = ({ button_, text_ }) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: button_,
                padding: "3px 7px",
                fontSize: "11px",
              }}
            >
              {text_}
            </Button>
          </div>
        );
      };
      ////console.log(homeTeam);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };

      const handlematchlick = (match_id) => {
        navigation("/match/" + match_id, { state: match_id });
      };
      const handlepredklick = (match_id) => {

        navigation("/match/" + match_id, { state: {match_id:match_id,tab:"PREDICTIONS" } });
      };
      return (
        <>
          <td className="dates">
            {small
              ? convertUnixToTime(Indata && Indata["date_unix"], "short")
              : convertUnixToTime(Indata && Indata["date_unix"], "")}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </>
          )}

          <td
            className="homeOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["homeID"],
                team_: Indata?.["home_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            <div className="right_content center_straight usemaxSpace">
              <div
                className="center_straight center_sides"
                style={{ maxWidth: window.innerWidth < 770 ? "90px" : "" }}
              >
                <div
                  className="noBorders"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  {Indata?.["home_name"]} &nbsp;
                </div>
                <div>
                  <img
                    src={Team_image?.[String(Indata?.["homeID"])]?.["image"]}
                    width="20"
                    height="15"
                    alt={Indata?.["home_name"]}
                  />
                </div>
              </div>
            </div>
          </td>

          {Indata?.status === "complete" ? (
            <td
              className="statsOR link"
              onClick={() => handlematchlick(Indata && Indata["id"])}
            >
              {`${Indata?.homeGoalCount} : ${Indata?.awayGoalCount}`}
            </td>
          ) : (
            <td
              className="statsOR link"
              onClick={() => handlematchlick(Indata && Indata["id"])}
            >
              {" "}
              -:-
            </td>
          )}
          <td
            className="awayOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["awayID"],
                team_: Indata?.["away_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            <div
              className="center_straight center_sides"
              style={{ maxWidth: window.innerWidth < 770 ? "90px" : "" }}
            >
              <div>
                <img
                  src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                  width="20"
                  height="15"
                  alt={Indata?.["away_name"]}
                />
              </div>

              <div
                className="noBorders"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                  width: "100%",
                  overflowWrap: "break-word",
                }}
              >
                &nbsp;
                {Indata?.["away_name"]}{" "}
              </div>
            </div>
          </td>

          <td></td>
          <td></td>
          <td>
            <div className="center_div center_sides link forceblack" onClick={() => handlepredklick(Indata && Indata["id"])}>
              <div>{small ? "details" : "match details"}</div>{" "}
              <div className="marginleft-10">
                <IoPlayForward size={25} style={{ color: "green" }} />
              </div>
            </div>
          </td>
        </>
      );
    };

    const DisplayDrop = ({ Indata }) => {
      ////console.log(data);
      const WinnerButton = ({ button_, text_ }) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: button_,
                padding: "3px 7px",
                fontSize: "11px",
              }}
            >
              {text_}
            </Button>
          </div>
        );
      };
      ////console.log(homeTeam);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };
      let t_d = {};
      if (Tab2 === "Win-Draw-Lose") {
        t_d = Indata?.win_tab;
      } else if (Tab2 === "Goals") {
        t_d = Indata?.goals_tab;
      } else if (Tab2 === "Cards") {
        t_d = Indata?.cards_tab;
      } else if (Tab2 === "Corners") {
        t_d = Indata?.corners_tab;
      }
      const getBorder = (field) => {
        if (t_d?.outcome === "loss" && field === "loss") {
          return "solid 2px";
        } else if (t_d?.outcome === "draw" && field === "draw") {
          return "solid 2px";
        } else if (t_d?.outcome === "win" && field === "win") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_goal = (field) => {
        if (t_d?.g_outcome === "over" && field === "overg") {
          return "solid 2px";
        } else if (t_d?.g_outcome === "under" && field === "underg") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "score" && field === "score") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "no_score" && field === "noscore") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_cc = (field) => {
        if (t_d?.c_outcome === "under" && field === "under") {
          return "solid 2px";
        } else if (t_d?.c_outcome === "over" && field === "over") {
          return "solid 2px";
        } else {
          return "";
        }
      };

      return (
        <div>
          <div className="center_div_distributed margin-30down">
            <div>
              <div>Time</div>
              <div>
                {convertUnixToTime(Indata && Indata["date_unix"], "time")}
              </div>
            </div>
            {Tab2 === "Win-Draw-Lose" ? (
              <>
                <div>
                  <div>Win%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["win_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("win"),
                    }}
                  >
                    {t_d?.["win%"] ? t_d?.["win%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Draw%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["draw_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("draw"),
                    }}
                  >
                    {t_d?.["draw%"] ? t_d?.["draw%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Loss%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["loss_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("loss"),
                    }}
                  >
                    {t_d?.["loss%"] ? t_d?.["loss%"] : "- %"}
                  </div>
                </div>
                <div className="center_div center_straight">
                  <div>Result</div>
                  <div>
                    <WinnerButton
                      button_={Indata?.["but_color"]}
                      text_={Indata?.["butt_text"]}
                    />
                  </div>
                </div>
              </>
            ) : Tab2 === "Goals" ? (
              <>
                <div>
                  <div>Ov1.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["over_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("overg"),
                    }}
                  >
                    {t_d?.["over1.5%"] ? t_d?.["over1.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Un1.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["under_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("underg"),
                    }}
                  >
                    {t_d?.["under1.5%"] ? t_d?.["under1.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Score%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["score_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("score"),
                    }}
                  >
                    {t_d?.["score%"] ? t_d?.["score%"] : "- %"}
                  </div>
                </div>
                <div className="center_div center_straight">
                  <div>NoScore</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["no_score_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("noscore"),
                    }}
                  >
                    {t_d?.["no_score%"] ? t_d?.["no_score%"] : "- %"}
                  </div>
                </div>
              </>
            ) : Tab2 === "Cards" ? (
              <>
                <div>
                  <div>Ov2.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["over_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("over"),
                    }}
                  >
                    {t_d?.["over2.5%"] ? t_d?.["over2.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Un2.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["under_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("under"),
                    }}
                  >
                    {t_d?.["under2.5%"] ? t_d?.["under2.5%"] : "- %"}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>Ov3.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["over_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("over"),
                    }}
                  >
                    {t_d?.["over3.5%"] ? t_d?.["over3.5%"] : "- %"}
                  </div>
                </div>
                <div>
                  <div>Un3.5%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: t_d?.["under_colo"],
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("under"),
                    }}
                  >
                    {t_d?.["under3.5%"] ? t_d?.["under3.5%"] : "- %"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    };

    const MLines = ({ Lines }) => {
      ////console.log(Lines);;
      let t_d = {};
      const [isdropdownClick, setisdropdownClick] = useState(false);

      const handleDropclick = (index) => {
        // if the row was already open, close it
        if (isdropdownClick === index) {
          setisdropdownClick(null);
        } else {
          // otherwise, open the clicked row
          setisdropdownClick(index);
        }
      };

      return (
        <div>
          <Table
            className="outer-border-light"
            striped
            hover
            size={
              props?.small || props?.mid || window.innerWidth < 1400 ? "sm" : 8
            }
            variant="light"
          >
            <thead>
              <tr>
                <th>Date</th>
                {props?.small ? null : (
                  <>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </>
                )}
                <th className="homeOR">Home</th>
                <th className="statsOR">Stats</th>{" "}
                <th className="awayOR">Away</th>
                <th></th>
                <th></th>
                <th className="center_div">Predictions</th>
              </tr>
            </thead>
            <tbody>
              {Lines.sort((a, b) => b.date_unix - a.date_unix).map(
                (item, index) => (
                  <tr onClick={() => handleDropclick(index)}>
                    <DisplayData Indata={item} />
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      );
    };

    //console.log(Data_);
    return (
      <div>
        <div className="center_div">{"Game Week : " + date_}</div>
        <div>
          <Modal.Body>
            <MLines Lines={Data_} />
          </Modal.Body>
        </div>
      </div>
    );
  }

  function Matches({ type, monthCount }) {
    const matches = Data?.[type];
    //const keysArray = Object.keys(g_past_date);
    ////console.log(g_past_date[matches[0]]);
    ////console.log(Object.keys(matches));
    return (
      <div>
        <Modal.Dialog className="rounded-3">
          {type === "future" ? (
            <>
              {matches &&
                Object.keys(matches)
                  .sort((a, b) => parseInt(a) - parseInt(b))
                  .map((item, index) => {
                    if (index <= monthCount) {
                      return (
                        <div key={index}>
                          <MultiLeague
                            Data_={matches[item]}
                            date_={item}
                            small={props?.small}
                            mid={props?.mid}
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
            </>
          ) : (
            <>
              {matches &&
                Object.keys(matches)
                  .sort((a, b) => parseInt(b) - parseInt(a))
                  .map((item, index) => {
                    if (index <= monthCount) {
                      return (
                        <div key={index}>
                          <MultiLeague
                            Data_={matches[item]}
                            date_={item}
                            small={props?.small}
                            mid={props?.mid}
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
            </>
          )}
        </Modal.Dialog>
      </div>
    );
  }
  const HandleSeeMore = (val) => {
    //let currValue = monthCount;

    if (val === "seemore" && monthCount > 2) {
      setMonthCount(monthCount + 3);
    }
    if (val === "seeless" && monthCount > 5) {
      setMonthCount(monthCount - 3);
    }
  };
  function SeeMore() {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <Container className="justify-center">
          <Nav style={{ display: "flex", justifyContent: "space-between" }}>
            <NavLink onClick={() => HandleSeeMore("seeless")}>
              {"< See Less"}
            </NavLink>
            <NavLink onClick={() => HandleSeeMore("seemore")}>
              {"See More >"}
            </NavLink>
          </Nav>
        </Container>
      </div>
    );
  }
  function handleClick(item) {
    //setdisData( item);
    setSType(item);
  }

  return (
    <div className="usemaxSpace flex center_straight">
    <div className="center_div allpad">
      <div style={{ width: "50%" }} className="center_div">
        
          <div className="center_div_distributed usemaxSpace">
            <div
              className={` breadNav_light link forceblack ${
                sType === "future"
                  ? "smartTableButton link "
                  : "inactiveButton"
              }`}
              onClick={() => {
                handleClick("future");
              }}
            >
              Future
            </div>
            <div className="">
              <div
                className={` breadNav_light link forceblack ${
                  sType === "past"
                    ? "smartTableButton "
                    : "inactiveButton"
                }`}
                onClick={() => {
                  handleClick("past");
                }}
              >
                Completed
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {small || mid ? (
          <div>
            <Matches type={sType} monthCount={monthCount} /> <SeeMore />
          </div>
        ) : (
          <Container>
            <Matches type={sType} monthCount={monthCount} /> <SeeMore />
          </Container>
        )}
      </div>
    </div>
  );
}
export default LeagueFixtures;
