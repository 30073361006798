import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { InfoTag } from "../DisplayInfo";
import { Errorlog } from "../Utils";
import axios from "axios";
import oddsType from "./oddsType.json";
import Spinner from "../Spinner";
import Team_images from "../../images/team_images.json";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import { OilBarrel, PeopleRounded } from "@mui/icons-material";
const API_URL = process.env.REACT_APP_API_URL;
const footy = process.env.REACT_APP_FOOTYSPORTS;
const footyRoot = process.env.REACT_APP_FOOTY_ROOT;

const UnivDisplay = (props) => {
  const ThreeFields = (props) => {
    //const [selected, setSelected] = useState(props?.selected);
    useEffect(() => {}, [props?.selected]);

    ////console.log(props.selected);
    ////console.log(props?.selected?.index, props?.outcomes?.desc);
    return (
      <div
        key={props?.index}
        className="margin-10down usemaxSpace"
        style={{ minWidth: "385px" }}
      >
        <div className="flex center_sides">
          <div className="sidepad">{props?.outcomes?.desc}</div>
          <div>
            <InfoTag
              tooltipContent={props?.outcomes?.marketGuide}
              scale={10}
              width={"200px"}
            />
          </div>
        </div>
        <div className="usemaxSpace flex center_sides tiny_font ">
          <div style={{ minWidth: "34%" }}>
            <div className="allpad">
              <div
                className={`${
                  props?.selected?.status &&
                  props?.selected?.index === props?.outcomes?.desc &&
                  props?.selected?.index2 ===
                    props?.outcomes?.outcomes?.[0]?.desc
                    ? "Odds_button_selected"
                    : "Odds_button"
                } link forcewhite bold allpad`}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => {
                  props?.handleOddsClick(
                    props?.outcomes?.desc,
                    props?.outcomes?.outcomes?.[0]?.desc,
                    props?.outcomes?.outcomes?.[0]?.odds,
                    props?.outcomes?.id,
                    props?.outcomes?.outcomes?.[0]?.id,
                    props?.outcomes?.specifier,
                    props?.status,
                  );
                }}
              >
                <div> {props?.outcomes?.outcomes?.[0]?.desc}</div>
                <div>{props?.outcomes?.outcomes?.[0]?.odds}</div>
              </div>
            </div>
          </div>
          <div style={{ minWidth: "34%" }}>
            <div className="allpad">
              <div
                className={`${
                  props?.selected?.status &&
                  props?.selected?.index === props?.outcomes?.desc &&
                  props?.selected?.index2 ===
                    props?.outcomes?.outcomes?.[1]?.desc
                    ? "Odds_button_selected"
                    : "Odds_button"
                } link forcewhite bold allpad`}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() =>
                  props?.handleOddsClick(
                    props?.outcomes?.desc,
                    props?.outcomes?.outcomes?.[1]?.desc,
                    props?.outcomes?.outcomes?.[1]?.odds,
                    props?.outcomes?.id,
                    props?.outcomes?.outcomes?.[1]?.id,
                    props?.outcomes?.specifier,
                    props?.status,
                  )
                }
              >
                <div> {props?.outcomes?.outcomes?.[1]?.desc}</div>
                <div>{props?.outcomes?.outcomes?.[1]?.odds}</div>
              </div>
            </div>
          </div>
          <div style={{ minWidth: "34%" }}>
            <div className="allpad">
              <div
                className={`${
                  props?.selected?.status &&
                  props?.selected?.index === props?.outcomes?.desc &&
                  props?.selected?.index2 ===
                    props?.outcomes?.outcomes?.[2]?.desc
                    ? "Odds_button_selected"
                    : "Odds_button"
                } link forcewhite bold allpad`}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() =>
                  props?.handleOddsClick(
                    props?.outcomes?.desc,
                    props?.outcomes?.outcomes?.[2]?.desc,
                    props?.outcomes?.outcomes?.[2]?.odds,
                    props?.outcomes?.id,
                    props?.outcomes?.outcomes?.[2]?.id,
                    props?.outcomes?.specifier,
                    props?.status,
                  )
                }
              >
                <div> {props?.outcomes?.outcomes?.[2]?.desc}</div>
                <div>{props?.outcomes?.outcomes?.[2]?.odds}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const TwoFields = (props) => {
    useEffect(() => {}, [props?.selected]);
    return (
      <div
        key={props?.id}
        className="margin-10down"
        style={{ minWidth: "385px" }}
      >
        <div className="flex center_sides">
          <div className="sidepad">{props?.outcomes?.desc}</div>
          <div>
            <InfoTag
              tooltipContent={props?.marketGuide}
              scale={props?.isSmallScreen ? 40 : 10}
              width={props?.isSmallScreen ? "300px" : "500px"}
            />
          </div>
        </div>
        {props?.outcomes?.outcomes?.length === 2 ? (
          <>
            <div className="usemaxSpace flex center_sides tiny_font ">
              <div style={{ minWidth: "50%" }}>
                <div className="allpad">
                  <div
                    className={`${
                      props?.selected?.status &&
                      props?.selected?.index === props?.outcomes?.desc &&
                      props?.selected?.index2 ===
                        props?.outcomes?.outcomes?.[0]?.desc
                        ? "Odds_button_selected"
                        : "Odds_button"
                    } link forcewhite bold allpad`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      props?.handleOddsClick(
                        props?.outcomes?.desc,
                        props?.outcomes?.outcomes?.[0]?.desc,
                        props?.outcomes?.outcomes?.[0]?.odds,
                        props?.outcomes?.id,
                        props?.outcomes?.outcomes?.[0]?.id,
                        props?.outcomes?.specifier,
                        props?.status,
                      )
                    }
                  >
                    <div> {props?.outcomes?.outcomes?.[0]?.desc}</div>
                    <div>{props?.outcomes?.outcomes?.[0]?.odds}</div>
                  </div>
                </div>
              </div>
              <div style={{ minWidth: "50%" }}>
                <div className="allpad">
                  <div
                    className={`${
                      props?.selected?.status &&
                      props?.selected?.index === props?.outcomes?.desc &&
                      props?.selected?.index2 ===
                        props?.outcomes?.outcomes?.[1]?.desc
                        ? "Odds_button_selected"
                        : "Odds_button"
                    } link forcewhite bold allpad`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      props?.handleOddsClick(
                        props?.outcomes?.desc,
                        props?.outcomes?.outcomes?.[1]?.desc,
                        props?.outcomes?.outcomes?.[1]?.odds,
                        props?.outcomes?.id,
                        props?.outcomes?.outcomes?.[1]?.id,
                        props?.outcomes?.specifier,
                        props?.status,
                      )
                    }
                  >
                    <div> {props?.outcomes?.outcomes?.[1]?.desc}</div>
                    <div>{props?.outcomes?.outcomes?.[1]?.odds}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {Array.from(
              { length: (props?.outcomes?.outcomes.length - 2) / 2 },
              (_, i) => props?.outcomes?.outcomes.slice(i * 2 + 2, i * 2 + 4)
            ).map((pair, index) => (
              <div
                key={index}
                className="usemaxSpace flex center_sides tiny_font "
              >
                <div style={{ minWidth: "50%" }}>
                  <div className="allpad">
                    <div
                      className={`${
                        props?.selected?.status &&
                        props?.selected?.index === props?.outcomes?.desc &&
                        props?.selected?.index2 === pair?.[0]?.desc
                          ? "Odds_button_selected"
                          : "Odds_button"
                      } link forcewhite bold allpad`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() =>
                        props?.handleOddsClick(
                          props?.outcomes?.desc,
                          pair?.[0]?.desc,
                          pair?.[0]?.odds,
                          props?.outcomes?.id,
                          pair?.[0]?.id,
                          props?.outcomes?.specifier,
                          props?.status,
                        )
                      }
                    >
                      <div> {pair?.[0]?.desc}</div>
                      <div>{pair?.[0]?.odds}</div>
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: "50%" }}>
                  <div className="allpad">
                    <div
                      className={`${
                        props?.selected?.status &&
                        props?.selected?.index === props?.outcomes?.desc &&
                        props?.selected?.index2 === pair?.[1]?.desc
                          ? "Odds_button_selected"
                          : "Odds_button"
                      } link forcewhite bold allpad`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() =>
                        props?.handleOddsClick(
                          props?.outcomes?.desc,
                          pair?.[1]?.desc,
                          pair?.[1]?.odds,
                          props?.outcomes?.id,
                          pair?.[0]?.id,
                          props?.outcomes?.specifier,
                          props?.status,
                        )
                      }
                    >
                      <div> {pair?.[1]?.desc}</div>
                      <div>{pair?.[1]?.odds}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };
  let allmkt = props?.exOdds?.markets;
  let someMarkets = false;
  for (let index = 0; index < allmkt.length; index++) {
    const element = allmkt[index];
    if (props?.optList.includes(element?.desc)) {
      someMarkets = true;
      break;
    }
  }
  return (
    <div>
      {someMarkets ? (
        <>
          {props?.exOdds?.markets?.map((item, index) => {
            return props?.optList.includes(item?.desc) ? (
              item?.outcomes.length === 3 ? (
                <div key={index}>
                  <ThreeFields
                    outcomes={item}
                    key={index}
                    status={props?.exOdds?.matchStatus}
                    handleOddsClick={props?.handleOddsClick}
                    selected={props?.selected}
                    setSelected={props?.setSelected}
                  />
                </div>
              ) : (
                <div key={index}>
                  <TwoFields
                    outcomes={item}
                    key={index}
                    status={props?.exOdds?.status}
                    handleOddsClick={props?.handleOddsClick}
                    selected={props?.selected}
                    setSelected={props?.setSelected}
                  />
                </div>
              )
            ) : null;
          })}
        </>
      ) : (
        <>
          <div className="center_div">
            Markets for this category are not available at this time
          </div>
        </>
      )}
    </div>
  );
};
export default function Markets(props) {
  //console.log("Entered market", props);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [half, setHalf] = useState("ft");
  const [gtype, setGType] = useState("Win-Draw-Lose");
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [exOdds, setExOdds] = useState();
  const [loading, setLoading] = useState(true);
  const [isOdds, setIsOdds] = useState(true);
  const [optList, SetOptList] = useState(oddsType?.["Win-Draw-Lose"]);
  const [isDelayed, setIsDelayed] = useState(true);
  const [selected, setSelected] = useState({
    index: "",
    index2: "",
    status: false,
  });
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelayed(false);
    }, 10000); // delay in ms

    return () => clearTimeout(timer); // clear timeout if component is unmounted
  }, [isOdds]);
  useEffect(() => {
    setLoading(true);
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      /* if (!props?.matchID || !props?.Data?.id) {
        return null;
      }  */
      setLoading(true);
      try {
        const res = await axios.post(
          `${API_URL}/externalOdds`,
          JSON.stringify({
            match: props?.data?.match_id ?? props?.matchID ?? props?.Data?.id,
            time: props?.data?.match?.date_unix ?? props?.Data?.date_unix,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //console.log(res);
        const fixdata = JSON.parse(res?.data?.data);
        if (res?.data?.status === "success") {
          //console.log(fixdata?.matchStatus);
          if (fixdata?.matchStatus === "Ended") {
            setIsOdds(false);
            setLoading(true);
          } else {
            setExOdds(fixdata);
            setLoading(false);
            setIsOdds(true);
          }
        } else {
          setIsOdds(false);
          setLoading(true);
        }
        //console.log(fixdata);
      } catch (error) {
        Errorlog(error, "error loading statistics", "from match stats");
      }

      const prevglobal = { ...globalUser };
      let statuses = prevglobal?.user?.Tickets?.state;
      let activeTicket = "";
      if (statuses && typeof statuses === "object") {
        for (
          let index = 0;
          index < Object.keys(statuses && statuses)?.length;
          index++
        ) {
          const element = Object.keys(statuses && statuses)[index];
          if (statuses?.[element]) {
            activeTicket = element;
          }
        }
      }
      const prevMatch = globalUser?.user?.Tickets?.[activeTicket]?.matches && [...globalUser?.user?.Tickets?.[activeTicket]?.matches];
      let found = false;
      for (let index = 0; index < prevMatch?.length; index++) {
        const element = prevMatch?.[index];
        //console.log(props, prevMatch);
        if (
          element?.match_id === props?.matchID ||
          element?.match_id === props?.Data?.id
        ) {
          let [top, sub] = element?.options?.descptn.split("~ ");
          top = top.split("(")[1].split(")")[0];
          //console.log(element?.options?.descptn);
          if (top && sub) {
            let data_opt = { index: top, index2: sub, status: true };

            setSelected(data_opt);
          } else {
            let data_opt = { index: "", index2: "", status: false };

            setSelected(data_opt);
          }
          found = true;
          break;
        }
      }
      if (!found) {
        let data_opt = { index: "", index2: "", status: false };

        setSelected(data_opt);
      }
      //console.log(found);
    };

    fetchData();
  }, [props?.matchID]);
  //console.log(selected);
  function handleClick(item) {
    setGType(item);
    SetOptList(oddsType?.[item]);
  }
  function handlehalfClick(item) {
    setHalf(item);
  }
  function OptTypes() {
    ////console.log(half)
    return (
      <div className="">
        <div
          className="center_div center_sides  mainFont"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <div
            className={`statTable  link sidepad forceblack allpad ${
              gtype === "Win-Draw-Lose"
                ? "tickettopCurve_dark bold"
                : "tickettopCurve"
            }`}
            onClick={() => handleClick("Win-Draw-Lose")}
          >
            Win-Draw-Lose
          </div>

          <div
            className={`statTable sidepad link allpad forceblack ${
              gtype === "Goals" ? "tickettopCurve_dark bold" : "tickettopCurve"
            }`}
            onClick={() => handleClick("Goals")}
          >
            Goals
          </div>

          <div
            className={`statTable sidepad link allpad forceblack ${
              gtype === "Cards" ? "tickettopCurve_dark bold" : "tickettopCurve"
            }`}
            onClick={() => handleClick("Cards")}
          >
            Cards
          </div>

          <div
            className={`statTable sidepad link allpad forceblack ${
              gtype === "Corners"
                ? "tickettopCurve_dark bold"
                : "tickettopCurve"
            }`}
            onClick={() => handleClick("Corners")}
          >
            Corners
          </div>
        </div>
      </div>
    );
  }

  const DisplayWDL = () => {
    return (
      <div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"1 X 2 " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Choose who will win the match"}
                scale={10}
                width={"200px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "D" : "Draw"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "A" : "Away"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Double Chance " + half}</div>
            <div>
              <InfoTag
                tooltipContent={
                  "Two chances. Home to win or draw, Any of the teams to win, Away to win or Draw"
                }
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H/D" : "Home/Draw"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{isSmallScreen ? "H/A" : "Home/Away"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "A/D" : "Away/Draw"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Win Either Half"}</div>
            <div>
              <InfoTag
                tooltipContent={
                  "Which Team would win at least one of the halfs"
                }
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{isSmallScreen ? "A" : "Away Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Win Either Half No"}</div>
            <div>
              <InfoTag
                tooltipContent={"Which Team would win none of the halfs"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{isSmallScreen ? "A" : "Away Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Win Either both Halfs"}</div>
            <div>
              <InfoTag
                tooltipContent={
                  "Which Team would win both first half and second half"
                }
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{isSmallScreen ? "A" : "Away Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Win Either Both Halfs No"}</div>
            <div>
              <InfoTag
                tooltipContent={
                  "Which Team would win Not win both of the halfs"
                }
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{isSmallScreen ? "A" : "Away Team"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Draw either Halfs"}</div>
            <div>
              <InfoTag
                tooltipContent={"There wwould be at least one half Draw"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"Yes"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"No"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DisplayGoals = () => {
    return (
      <div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Goals " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"0.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"1.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"2.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"3.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"4.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Both Teams To Score"}</div>
            <div>
              <InfoTag
                tooltipContent={
                  "Will both teams score at least one goal in the match?"
                }
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"Yes"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"No"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Goals Home Team " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"0.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"1.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"2.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"3.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"4.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Goals Away Team " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"0.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"1.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"2.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"3.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"4.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Home Team To Score"}</div>
            <div>
              <InfoTag
                tooltipContent={"Will Home team sore at least one goal?"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"Yes"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"No"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Away Team To Score"}</div>
            <div>
              <InfoTag
                tooltipContent={"Will Away team score at least one goal?"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"Yes"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "50%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"No"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DisplayCorners = () => {
    return (
      <div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"1 X 2 Corners " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Choose who will get the most corners"}
                scale={10}
                width={"200px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "D" : "Draw"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "A" : "Away"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Corners " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "6.5" : "2.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "7.5" : "3.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "8.5" : "4.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "9.5" : "5.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "10.5" : "6.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "11.5" : "7.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Corners Home Team " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "3.5" : "1.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "4.5" : "2.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "5.5" : "3.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "6.5" : "4.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "7.5" : "5.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Corners Away Team " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "3.5" : "1.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "4.5" : "2.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "5.5" : "3.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "6.5" : "4.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "7.5" : "5.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const DisplayCards = () => {
    return (
      <div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"1 X 2 Cards " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Choose who will get the most corners"}
                scale={10}
                width={"200px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font ">
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "H" : "Home"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "D" : "Draw"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "34%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {isSmallScreen ? "A" : "Away"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Cards " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "2.5" : "0.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "3.5" : "1.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "4.5" : "2.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "5.5" : "3.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad">
                  {" "}
                  {half === "ft" ? "6.5" : "4.5"}
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Cards Home Team " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"0.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"1.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"2.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"3.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"4.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-10down">
          <div className="flex center_sides">
            <div className="sidepad">{"Total Cards Away Team " + half}</div>
            <div>
              <InfoTag
                tooltipContent={"Total goals to be scored"}
                scale={isSmallScreen ? 40 : 10}
                width={isSmallScreen ? "300px" : "500px"}
              />
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"0.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"1.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"2.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"3.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="usemaxSpace flex center_sides tiny_font inactiveButton ">
            <div style={{ minWidth: "16%" }}>
              <div className="allpad center_div usemaxSpace">
                <div className=" bold allpad"> {"4.5"}</div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className="Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div> {"under"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: "42%" }}>
              <div className="allpad">
                <div
                  className=" Odds_button link forcewhite bold allpad"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{"over"}</div>
                  <div>1.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleDeleteMatches = () => {
    const prevglobal = globalUser["user"]["Tickets"] && { ...globalUser };
    let statuses = prevglobal?.user?.Tickets?.state;
    let activeTicket = "";
    if (statuses && typeof statuses === "object") {
      for (
        let index = 0;
        index < Object.keys(statuses && statuses)?.length;
        index++
      ) {
        const element = Object.keys(statuses && statuses)[index];
        if (statuses?.[element]) {
          activeTicket = element;
        }
      }
    }
    const prevMatch = globalUser?.user?.Tickets?.[activeTicket]?.matches && [...globalUser?.user?.Tickets?.[activeTicket]?.matches];
    //console.log(prevMatch)
    for (let index = 0; index < prevMatch?.length; index++) {
      const element = prevMatch?.[index];
      if (
        element?.match_id === props?.matchID ||
        element?.match_id === props?.Data?.id
      ) {
        //console.log(element?.matchID,props?.matchID,props?.Data?.id)
        prevMatch.splice(index, 1);
        break;
      }
    }
    if (prevglobal && prevglobal["user"]["Tickets"][activeTicket]["matches"]){
      prevglobal["user"]["Tickets"][activeTicket]["matches"] = prevMatch;
      //setUseMatches(() => prevMatch);
      updateGlobalUser(() => prevglobal);
      let ckUser = UpdateLocal({
        action: "save",
        data: prevglobal,
      });
    }
    
  };
  const handleTicketAdd = (optns, data) => {
    const currTime = Math.floor(new Date().getTime() / 1000);

    /* if (data?.match?.date_unix < currTime) {
      props?.setIsStarted(() => false);
    } else { */
    props?.setIsStarted(() => false);

    const prevglobal = { ...globalUser };
    //console.log(globalUser)
    let statuses = prevglobal?.user?.Tickets?.state;
    let activeTicket = "";
    if (statuses && typeof statuses === "object") {
      for (
        let index = 0;
        index < Object.keys(statuses && statuses)?.length;
        index++
      ) {
        const element = Object.keys(statuses && statuses)[index];
        if (statuses?.[element]) {
          activeTicket = element;
        }
      }
    }
    ////console.log(useActiveTicket);
    const new_match = data;
    let isexist = -1;
    const prevMatch =
      prevglobal?.["user"]?.["Tickets"]?.[activeTicket]?.["matches"];
    if (!prevMatch) {
      props?.setIsTicketError(true);
    } else {
      for (let index = 0; index < prevMatch?.length; index++) {
        const element = prevMatch?.[index];
        if (element?.match_id == new_match?.match_id) {
          isexist = index;
          break;
        }
      }
      ////console.log(isexist);
      if (isexist !== -1) {
        //console.log(prevMatch);
        prevMatch[isexist]["options"] = optns;
        prevglobal["user"]["Tickets"][activeTicket]["matches"] = prevMatch;
        if(!prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]){
          prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]={}
        }
        prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]["bcode"] =
          "";
        prevglobal["user"]["Tickets"][activeTicket]["booking_Info"][
          "ShareURL"
        ] = "";
        updateGlobalUser(() => prevglobal);
        let ckUser = UpdateLocal({
          action: "save",
          data: prevglobal,
        });
      } else {
        //console.log(data, optns);
        new_match["options"] = optns ?? {};
        if(!prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]){
          prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]={}
        }
        prevglobal["user"]["Tickets"][activeTicket]["matches"].push(new_match);
        prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]["bcode"] =
          "";
        prevglobal["user"]["Tickets"][activeTicket]["booking_Info"][
          "ShareURL"
        ] = "";
        updateGlobalUser(() => prevglobal);
        let ckUser = UpdateLocal({
          action: "save",
          data: prevglobal,
        });
      }
    }
  };

  useEffect(() => {}, [selected, setSelected]);
  const handleOddsClick = (top, sub, odd, marketID, outcomesID, specifier,status) => {
    const desc = {
      descptn: "(" + top + ")" + " ~ " + sub,
      odds: odd,
      canEdit:true,
      status:status,
      bookie_info: {
        eventId: exOdds?.eventId,
        gameId: exOdds?.gameId,
        sport: exOdds?.sport,
        marketId: marketID,
        outcomeId: outcomesID,
        specifier: specifier ?? " ",
      },
    };

    let homeTeam = props?.data && props?.data?.["home_data"];
    let awayTeam = props?.data && props?.data?.["away_data"];
    let data = {};
    if (!homeTeam || !awayTeam) {
      homeTeam = {
        cleanName: props?.data?.["home_name"],
        id: props?.data?.["homeID"],
        image: Team_images?.[String(props?.data?.["homeID"])]?.image,
      };
      awayTeam = {
        cleanName: props?.data?.["away_name"],
        id: props?.data?.["awayID"],
        image: Team_images?.[String(props?.data?.["awayID"])]?.image,
      };

      data = {
        home_data: homeTeam,
        away_data: awayTeam,
        match: props?.data,
        match_id: props?.data?.id,
      };
    } else {
      data = props?.data;
    }

    if (!selected?.status && top) {
      handleTicketAdd(desc, data);

      let out = { index: top, index2: sub, status: true };
      setSelected(out);
    } else if (selected?.status && top) {
      if (top === selected?.index && sub === selected?.index2) {
        let out = { index: top, index2: sub, status: false };
        setSelected(out);
        handleDeleteMatches();
      } else {
        let out = { index: top, index2: sub, status: true };
        setSelected(out);
        handleTicketAdd(desc, data);
      }
    }
    //console.log(selected);
  };
  ////console.log(optList);
  return (
    <div>
      <MDBCard className="" style={{ maxHeight: "650px" }}>
        {exOdds?.status === 1 ? (
          <div className="center_div center_sides bold">
            {" "}
            <div className=" allpad timerfontgreen">Live Odds</div>{" "}
            <div className=" center_div center_sides allpad">
              Match Status: &nbsp;{" "}
              <div
                className="nobgbutton forcewhite"
                style={{ backgroundColor: "red" }}
              >
                {" "}
                &nbsp;{exOdds?.matchStatus}
                {"'"} - {exOdds?.playedSeconds}
                {"' "}&nbsp;
              </div>
            </div>{" "}
            <div className="allpad center_div center_sides">
              {" "}
              Score : &nbsp;{" "}
              <div className=" Odds_button_selected">
                {" "}
                &nbsp;{exOdds?.setScore}&nbsp;
              </div>
            </div>
          </div>
        ) : null}
        <MDBCardHeader>
          <OptTypes />
        </MDBCardHeader>
        <MDBCardBody className="flex scrol_div">
          {loading ? (
            !isOdds && isDelayed ? (
              <div className="center_div">
                There is no market for this match or it has ended!
              </div>
            ) : (
              <MDBSpinner
                className="me-2"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            )
          ) : (
            <div className=" usemaxSpace shiftleft">
              <UnivDisplay
                optList={optList}
                exOdds={exOdds}
                handleOddsClick={handleOddsClick}
                selected={selected}
                setSelected={setSelected}
                isSmallScreen={isSmallScreen}
              />
            </div>
          )}
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}
