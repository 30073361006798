import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import LineChartDefault from "../ChartComponents/TeamStatistics_Linechart";
import TeamPieChart from "../ChartComponents/TeamStatistics_Piechart";
import ScatterPlot from "../ChartComponents/TeamStatistics_ScatterPlot";

import Team_image from "../../images/team_images.json";

import "../../css/Teamstats.css";
import { Errorlog } from "../Utils";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
function convertUnixToTime(unixTime) {
  // Create a new Date object using the unixTime (in milliseconds)
  const date = new Date(unixTime * 1000);

  const options = {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

export default function TeamStatistics({
  side,
  half,
  type,
  league,
  team,
}) {
  const [useleague, setleague] = useState(league);
  const [useteam, setTeam] = useState(team);
  const [loading, setLoading] = useState(true);
  const [Data_, setdata] = useState();
  const [mid, setIsMidScreen] = useState(false);
  const [small, setIsSmallScreen] = useState(false);
  
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
////console.log(league,type,small,mid)
  useEffect(() =>{
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    if (
      team !== null
    ) {
      
      const get_tmdata = async() =>{
        let options = {
          body: JSON.stringify( {
            team: useteam,
            tab:type,
            league:useleague,
          } ),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },
  
          seconds: 2 * 60 * 60,
        };
        let url = "/team_stats";
        let indexdb = "team_stats";
        let SaveKey = "" + useteam + type + useleague;
        try{

          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          /* const res = await axios.post(
            `${API_URL}/team_stats`,
            JSON.stringify( {
              team: useteam,
              tab:type,
              league:useleague,
            } ),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = res;
          ////console.log(res)
          if(fix_data.status==="success"){
            setdata(fix_data?.data)
            setLoading(false)
            
          }else{
            Errorlog("loaderror","error loading statistics","from league stats")
          }
        }catch (error){
          Errorlog(error,"error loading statistics","from league stats")
          ////console.log(error)
        }
        
      }
      get_tmdata()
  
    }
  },[])

  if(!Data_ || loading){
    return(
      <Spinner />
    )
  }
  ////console.log(Data_, side, half, type, league);
  const datatouse = Data_?.STATISTICS?.[type]?.[league];
  //console.log(datatouse);
  return (
    <div>
      <Modal.Dialog>
        <Modal.Body>
          {type === "Win-Draw-Lose" ||type === "WDL-Cards"||type === "WDL-Corners" ? (
            <LineChartDefault data={datatouse} side={side} half={half} />
          ) : type === "Goals" ? (
            <ScatterPlot
              data={datatouse}
              side={side}
              half={half}
              type={"goals"}
            />
          ) : type === "Cards" ? (
            <ScatterPlot
              data={datatouse}
              side={side}
              half={half}
              type={"cards"}
            />
          ) : type === "Corners" ? (
            <ScatterPlot
              data={datatouse}
              side={side}
              half={half}
              type={"corners"}
            />
          ) : null}
        </Modal.Body>
      </Modal.Dialog>
      <div>
        <TeamPieChart
          Pie_data={datatouse?.pie_chart}
          side={side}
          half={half}
          type={type}
          small={small}
          mid={mid}
        />
      </div>
    </div>
  );
}
