import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
} from "recharts";

const getColor = (name) => {
  if (name === "win") return "green";
  if (name === "loss") return "red";
  if (name === "draw") return "orange";
};

export default function LineChartDefault({ data, side, half }) {
  let oldv = 0;
  const [useface, setuseface] = useState(oldv);
  const navigation = useNavigate();
  const data2display = data?.["line_chart"]?.[side];

  let halfs = "ft";
  if (half === "Full Time") {
    halfs = "ft";
  } else if (half === "First Half") {
    halfs = "ht";
  } else if (half === "Second Half") {
    halfs = "h2";
  } else {
    halfs = "eh";
  }
  ////console.log(data?.["line_chart"], halfs, side);

  const GettoolTip = ({ tooltipData, label }) => {
    let data2use = "";
    for (let index = 0; index < tooltipData.length; index++) {
      if (Object.keys(tooltipData[index])[0] === label) {
        data2use = tooltipData?.[index]?.[label];
      }
    }

    const dis_name = Object.keys(data2use)[0];
    ////console.log(data2use);
    /* const tooltiplabel = toolchartdict?.[type]?.goalsTool
      ?.slice(4)
      .slice(0, -1); */
    ////console.log(tooltiplabel);
    ////console.log(date, tooltipData);
    return (
      <div>
        <Modal.Dialog>
          <Modal.Body className="tooltipbg">
            <Container className="justify-content-between align-items-center myContainer">
              <Row>
                <Col className="justify-content-between align-items-center myContainer">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <h6>{data2use?.date}</h6>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <h6> {dis_name}</h6>
              </div>
              <div>
                <Row>
                  <Col>
                    <h5>{data2use?.match?.homeName}</h5>
                  </Col>
                  <Col>
                    <h5>{data2use?.match?.["score_ft"]}</h5>
                  </Col>
                  <Col>
                    <h5>{data2use?.match?.awayName}</h5>
                  </Col>
                </Row>
              </div>
            </Container>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  };
  const CustomTooltip = ({ active, payload, label, index }) => {
    const tooltipData = data2display?.[0]?.[halfs]?.tool_tip;

    if (active && payload && payload.length) {
      return (
        <div>
          <GettoolTip label={label} tooltipData={tooltipData} />
        </div>
      );
    }

    return null;
  };

  class CustomizedDot extends React.Component {
    handlematchlick = (team_meta, index, tool_tip) => {
      const match_id = team_meta?.[index]?.match_id;
      const label_ = Object.keys(tool_tip?.[index])[0];
      const team2 = tool_tip?.[index]?.[label_]?.match?.awayID;
      const team1 = tool_tip?.[index]?.[label_]?.match?.homeID;
      const seas = " ";
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };
    render() {
      const { cx, cy, value, data, index, team_meta, tool_tip, payload } =
        this.props;

      if (data[index]?.colo === "red") {
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            height={20}
            className="link"
            fill="red"
            viewBox="0 0 1024 1024"
            onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
          >
            <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
          </svg>
        );
      } else if (data[index]?.colo === "yellow") {
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            height={20}
            className="link"
            fill="orange"
            viewBox="0 0 1024 1024"
            onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
          >
            <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
          </svg>
        );
      }

      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          className="link"
          fill="green"
          viewBox="0 0 1024 1024"
          onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
  }
  class CustomizedDot2 extends React.Component {
    handlematchlick = (team_meta, index, tool_tip) => {
      const match_id = team_meta?.[index]?.match_id;
      const label_ = Object.keys(tool_tip?.[index])[0];
      const team2 = tool_tip?.[index]?.[label_]?.match?.awayID;
      const team1 = tool_tip?.[index]?.[label_]?.match?.homeID;
      const seas = " ";
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };

    render() {
      const { cx, cy, value, data, index, team_meta, tool_tip, payload } =
        this.props;
      /* ////console.log(team_meta); */
      if (data[index]?.colo1 === "red") {
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            height={20}
            className="link"
            fill="red"
            viewBox="0 0 1024 1024"
            onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
          >
            <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
          </svg>
        );
      } else if (data[index]?.colo1 === "yellow") {
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            height={20}
            className="link"
            fill="orange"
            viewBox="0 0 1024 1024"
            onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
          >
            <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
          </svg>
        );
      }

      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          className="link"
          fill="green"
          viewBox="0 0 1024 1024"
          onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
  }
  class CustomizedDot3 extends React.Component {
    handlematchlick = (team_meta, index, tool_tip) => {
      const match_id = team_meta?.[index]?.match_id;
      const label_ = Object.keys(tool_tip?.[index])[0];
      const team2 = tool_tip?.[index]?.[label_]?.match?.awayID;
      const team1 = tool_tip?.[index]?.[label_]?.match?.homeID;
      const seas = " ";
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };
    render() {
      const { cx, cy, value, data, index, team_meta, tool_tip, payload } =
        this.props;

      if (data[index]?.colo2 === "red") {
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            height={20}
            className="link"
            fill="red"
            viewBox="0 0 1024 1024"
            onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
          >
            <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
          </svg>
        );
      } else if (data[index]?.colo2 === "yellow") {
        return (
          <svg
            x={cx - 10}
            y={cy - 10}
            width={20}
            className="link"
            height={20}
            fill="orange"
            viewBox="0 0 1024 1024"
            onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
          >
            <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
          </svg>
        );
      }

      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          className="link"
          fill="green"
          viewBox="0 0 1024 1024"
          onClick={() => this.handlematchlick(team_meta, index, tool_tip)}
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
  }
  const RenderChart = (mainData) => {
    const chartData = data2display?.[0]?.[halfs]?.chart;
    const team_meta = data2display?.[0]?.[halfs]?.team_meta;
    const tool_tip = data2display?.[0]?.[halfs]?.tool_tip;

    const d2r = 1;
    return (
      <div className="usemaxSpace">
        {half === "Either Half" ? (
          <div style={{ height: "300px", width: "100%" }}>
            <ResponsiveContainer>
              <LineChart
                width={1000}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: -27,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="day"
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                />
                <YAxis
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                  tickFormatter={(value) => {
                    if (value === 1) {
                      return "Loss";
                    } else if (value === 3) {
                      return "Draw";
                    } else if (value === 5) {
                      return "Win";
                    } else {
                      return value; // Use the original value if it doesn't fall into any range
                    }
                  }}
                  interval={0} // Ensure the ticks are spaced evenly
                  ticks={[1, 3, 5]}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  offset={10}
                  allowEscapeViewBox={{ x: false, y: true }}
                />

                <Line
                  type="monotone"
                  dataKey="ft_w1"
                  stroke="brown"
                  dot={(props) => (
                    <CustomizedDot2
                      {...props}
                      data={chartData}
                      key={"colo1"}
                      team_meta={team_meta}
                      tool_tip={tool_tip}
                    />
                  )}
                />

                <Line
                  type="monotone"
                  dataKey="ft_w2"
                  stroke="pink"
                  dot={(props) => (
                    <CustomizedDot3
                      {...props}
                      data={chartData}
                      key={"colo2"}
                      team_meta={team_meta}
                      tool_tip={tool_tip}
                    />
                  )}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div style={{ height: "300px", width: "100%" }}>
            <ResponsiveContainer>
              <LineChart
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: -30,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="day"
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                />
                <YAxis tick={{ fontSize: 12, fontFamily: "serif" }} />
                <Tooltip
                  content={<CustomTooltip />}
                  offset={10}
                  allowEscapeViewBox={{ x: false, y: true }}
                />

                <Line
                  type="monotone"
                  dataKey="ft_w"
                  stroke="#8884d8"
                  dot={(props) => (
                    <CustomizedDot
                      {...props}
                      data={chartData}
                      team_meta={team_meta}
                      tool_tip={tool_tip}
                    />
                  )}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="usemaxSpace">
      <Modal.Dialog className="rounded-3 center_div usemaxSpace">
        <div className="center_div margin10down">
          <h6> Winning Performance Trend</h6>
        </div>

        <Modal.Body className="rounded-3 center_div usemaxSpace">
          <RenderChart />
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}
