import React, { useEffect } from "react";
import axios from "axios";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBContainer,
  MDBSideNav,
  MDBSideNavItem,
  MDBSideNavMenu,
  MDBSideNavLink,
  MDBIcon,
} from "mdb-react-ui-kit";
import { FcInfo } from "react-icons/fc";
import { VscPass } from "react-icons/vsc";
import { ReactComponent as Logo } from "../images/logo.svg";
import { RiErrorWarningLine } from "react-icons/ri";
import LeagueNav from "./LeagueSideBar";
import ShowTicket from "./ShowTicketGames";
import { Link, useNavigate } from "react-router-dom";
import "../css/ThemeCss.css";
import { Console } from "console";
import Tickets from "./UserTickets";

//const fs = require('graceful-fs');
const moment = require("moment");
const ServerName = process.env.REACT_APP_SERVER_NAME;
const API_URL = process.env.REACT_APP_API_URL;
const logpath = process.env.REACT_APP_LOG_PATH;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;

export function compareEmailsGetNames(email1, email2) {
  let name1 = email1?.split("@")[0].toLowerCase();
  let name2 = email2?.split("@")[0].toLowerCase();

  let maxname = name1?.localeCompare(name2) >= 0 ? name1 : name2;
  let minname = name1?.localeCompare(name2) <= 0 ? name1 : name2;

  let outname = "";

  if (maxname === name1) {
    outname = `${email1}_${email2}`;
  } else {
    outname = `${email2}_${email1}`;
  }

  if (outname !== "") {
    return outname;
  } else {
    return "unknown names";
  }
}

export async function Errorlog(type, error, function_type) {
  const date = moment().format("YYYY-MM-DD");
  const time = moment().format("HH:mm:ss");
  const filePath = `${logpath}/error-${date}.log`;

  const errorMessage = `${time} - TYPE: ${type}, FUNCTION: ${function_type}, ERROR: ${error}\n`;

  try {
    res = await axios
      .post(`${API_URL}/logerror_backend`, {
        error: errorMessage,
        directory: "logs",
      })
      .catch((error) => {
        //throw new Error('Server is unavailable, please try again later');
      });
    //fs.appendFileSync(filePath, errorMessage);
  } catch (e) {}
}

export function timestampDifference(timestamp1, timestamp2) {
  // Convert Unix timestamps to milliseconds
  let t1 = new Date(timestamp1 * 1000);
  let t2 = new Date(timestamp2 * 1000);

  // Calculate the difference in milliseconds
  let difference_ms = Math.abs(t2 - t1);

  // Determine the appropriate unit for the difference
  if (difference_ms < 1000) {
    return `${difference_ms} mills`;
  } else if (difference_ms < 60 * 1000) {
    return `${Math.floor(difference_ms / 1000)} s`;
  } else if (difference_ms < 60 * 60 * 1000) {
    return `${Math.floor(difference_ms / (60 * 1000))} min`;
  } else if (difference_ms < 24 * 60 * 60 * 1000) {
    return `${Math.floor(difference_ms / (60 * 60 * 1000))} hr`;
  } else if (difference_ms < 7 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(difference_ms / (24 * 60 * 60 * 1000))} dy`;
  } else if (difference_ms < 30 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(difference_ms / (7 * 24 * 60 * 60 * 1000))} wk`;
  } else if (difference_ms < 365 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(difference_ms / (30 * 24 * 60 * 60 * 1000))} mon`;
  } else {
    return `${Math.floor(difference_ms / (365 * 24 * 60 * 60 * 1000))} yr`;
  }
}
function AlartError(sendingFunction, error, script) {
  email_data = {
    sub_subject: sendingFunction,
    message: error,
    subject: ServerName,
    email: "FrontendBug@betinformatics.com",
    header: script,
  };
}
function convertUnixTime(unixTime) {
  // Unix time must be greater than this to be in milliseconds
  ////console.log(unixTime)
  const threshold = 100000000000;
  if (unixTime > threshold) {
      // If unix time is in milliseconds
      return new Date(unixTime);
  } else {
      // If unix time is in seconds
      return new Date(unixTime * 1000);
  }
}

export function convertUnixToTime(unixTime, type) {
  // Create a new Date object sing the unixTime (in milliseconds)
  const date = convertUnixTime(unixTime);

  const options = {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const options2 = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  };
  const options3 = {
    hour: "2-digit",
    minute: "2-digit",
  };
  const options4 = {
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  if (type === "short") {
    return date.toLocaleDateString("en-US", options2);
  } else if (type === "time") {
    return date.toLocaleTimeString("en-US", options3);
  } else if (type === "shortest") {
    return date.toLocaleDateString("en-US", options4);
  } else if (type === "") {
    return formattedDate;
  } else {
    return formattedDate;
  }
}

export function CallInfo({
  header,
  data1,
  data2,
  data3,
  funcchange,
  funcuser,
}) {
  ////console.log();

  return (
    <>
      <MDBModal
        animationDirection="right"
        open={funcuser}
        tabIndex="-1"
        onClose={() => funcchange(false)}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>{header}</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => funcchange(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-3 text-center">
                  <FcInfo size={50} />
                </div>

                <div className="col-9">
                  {data1 && (
                    <p>
                      <div>{data1}</div>
                    </p>
                  )}
                  {data2 && (
                    <p>
                      <div>{data2}</div>
                    </p>
                  )}
                  {data3 && (
                    <p>
                      <div>{data3}</div>
                    </p>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => funcchange(() => false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
export function CallSuccess({
  header,
  data1,
  data2,
  data3,
  funcchange,
  funcuser,
}) {
  ////console.log();

  return (
    <>
      <MDBModal
        animationDirection="right"
        open={funcuser}
        tabIndex="-1"
        onClose={() => funcchange(false)}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>{header}</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => funcchange(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-3 text-center">
                  <VscPass size={50} style={{ color: "green" }} />
                </div>

                <div className="col-9">
                  {data1 && (
                    <p>
                      <div>{data1}</div>
                    </p>
                  )}
                  {data2 && (
                    <p>
                      <div>{data2}</div>
                    </p>
                  )}
                  {data3 && (
                    <p>
                      <div>{data3}</div>
                    </p>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => funcchange(() => false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export function CallError({
  header,
  data1,
  data2,
  data3,
  funcchange,
  funcuser,
}) {
  ////console.log(header,data1,funcuser);

  return (
    <>
      <MDBModal
        animationDirection="left"
        open={funcuser}
        tabIndex="-1"
        onClose={() => funcchange(false)}
      >
        <MDBModalDialog position="top">
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>{header}</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => funcchange(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-3 text-center">
                  <RiErrorWarningLine size={50} style={{ color: "red" }} />
                </div>

                <div className="col-9">
                  {data1 && (
                    <p>
                      <div>{data1}</div>
                    </p>
                  )}
                  {data2 && (
                    <p>
                      <div>{data2}</div>
                    </p>
                  )}
                  {data3 && (
                    <p>
                      <div>{data3}</div>
                    </p>
                  )}
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => funcchange(() => false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export function SelectTicket(props) {
  ////console.log(header,data1,funcuser);

  return (
    <>
      <MDBModal
        animationDirection="left"
        open={props?.funcuser}
        tabIndex="-1"
        onClose={() => props?.funcchange(false)}
      >
        <MDBModalDialog position="top">
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>{"Select your Ticket"}</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => props?.funcchange(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <Tickets 
                  islgedin={props?.islgedin}
                  issubed={props?.issubed}
                  setticketactive={props?.setticketactive}
                  setShows={props?.setShows}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => props?.funcchange(() => false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export function SideLeagueNavModal(props) {
  ////console.log("sive namv modal",props?.islgedin);

  return (
    <div className="left_div">
      <MDBModal
        animationDirection="left"
        open={props?.funcuser}
        tabIndex="-1"
        className="modal-side modal-top-left"
        //width="50%"
        onClose={() => props?.funcchange(false)}
      >
        <MDBModalDialog
          position="left"
          className="modal-dialog-scrollable modal-side modal-top-left"
          side
          style={{ width: "70%" }}
        >
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>{"Leagues"}</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => props?.funcchange(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="modal-side modal-top-left">
                <LeagueNav
                  islgedin={props?.islgedin}
                  issubed={props?.issubed}
                  setticketactive={props?.setticketactive}
                  setShows={props?.setShows}
                  data={props?.data}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => props?.funcchange(() => false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}

export function ShowTicketDetailsModal(props) {
  ////console.log("showTickets");

  return (
    <div className="usemaxSpace">
      <MDBModal
        animationDirection="right"
        open={props?.funcuser}
        tabIndex="-1"
        scrollable
        className="modal-xl"
        width="100%"
        nonInvasive={true}
        onClose={() => props?.funcchange(false)}
      >
        <MDBModalDialog position="top-left" middle style={{ width: "100%" }}>
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>{"Ticket Details"}</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={() => props?.funcchange(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mainFont">
                <ShowTicket />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                outline
                color="info"
                onClick={() => props?.funcchange(() => false)}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

export function SideNavPage(props) {
  const navigation = useNavigate();
  //console.log(props?.isOpen);
  return (
    <div style={{ height: "inherit" }}>
      <MDBSideNav
        small
        backdrop={false}
        open={props?.isOpen}
        absolute
        style={{ zIndex: "999999" }}
      >
        <div
          className="ripple d-flex justify-content-center py-4 mb-3 "
          style={{ borderBottom: "2px solid #f5f5f5" }}
          data-mdb-ripple-color="primary"
        >
          <Logo
            alt="Bet Informatics"
            style={{ maxWidth: "10rem", maxHeight: "2rem" }}
          />
          <MDBBtn
            size="sm"
            outline
            color="primary"
            onClick={() => props?.setIsOpen(!props?.isOpen)}
          >
            Close
          </MDBBtn>
        </div>
        <div className="center_div center_straight mainFont bold">
          <div style={{ width: "70%" }}>
            <div className="center_div center_straight">
              <div className="usemaxSpace allpad link forceblack" onClick={() => navigation("/")}>
                <div className="webButton usemaxSpace center_div allpad">
                  {" "}
                  Fixtures
                </div>
              </div>
              <div className="usemaxSpace allpad link forceblack" onClick={() => navigation("/Statistics")}>
                {" "}
                <div className="webButton usemaxSpace center_div allpad"> Statistics</div>
              </div>
              <div className="usemaxSpace allpad link forceblack " onClick={() => navigation("/predictions")}> 
                {" "}
                <div className="webButton usemaxSpace center_div allpad"> Predictions</div>
              </div>
              {/* <div className="usemaxSpace allpad link forceblack " onClick={() => navigation("/Pricing")}>
                {" "}
                <div className="webButton usemaxSpace center_div allpad"> Pricing</div>
              </div> */}
              <div className="usemaxSpace allpad link forceblack " onClick={() => navigation("/About us")}>
                {" "}
                <div className="webButton usemaxSpace center_div allpad"> About us</div>
              </div>
              <div className="usemaxSpace allpad link forceblack " onClick={() => navigation("/Contact us")}>
                {" "}
                <div className="webButton usemaxSpace center_div allpad"> Contact</div>
              </div>
              <div className="usemaxSpace allpad link forceblack" onClick={() => navigation("/howto")}>
                {" "}
                <div className="webButton usemaxSpace center_div allpad"> How to BI</div>
              </div>
            </div>
          </div>
        </div>
      </MDBSideNav>
    </div>
  );
}

export const GetImagesCloud = async(email,type)=>{
  try {
    const response2 = await fetch?.(
      `https://storage.googleapis.com/${StorageBucket}/${email}_${type}`
    ).catch((error) => {
      "";
    });
    if (!response2.ok) {
      throw new Error(`HTTP error! status: ${response2.status}`);
    }
    const blob2 = await response2.blob();
    const base64data2 = await blob2.text(); // Convert the blob to a string
    return base64data2;
    ////console.log("finalValue");
  } catch (error) {
    //console.error(error);
  }
}
