import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import DisplayValue, { DisplayValueSmall } from "./ValueDisplay";
import Team_image from "../../images/team_images.json";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";

export default function ComparismDisplay({ datain, text }) {
  ////console.log(datain);
  return (
    <div className="">
      <Modal.Dialog>
        <Modal.Body>
          <div className="levl2label removespace-17down">{text}</div>

          <div className="removespace-17down">
            <Row className="center_div ">
              <Col md={4} lg={4} sm={4} xs={4} className="">
                <div className="center_div center_straight">
                  <Row>
                    <Col>
                      <div>
                        <img
                          src={Team_image?.[String(datain?.homeID)]?.image}
                          width="100"
                          height="80"
                          alt={datain?.homename}
                        />{" "}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div
                        style={{
                          fontFamily: "serif",
                          fontSize: "40px",
                          fontWeight: "bold",
                        }}
                      >
                        {datain?.home_val}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={4} lg={4} sm={4} xs={4} className="">
                <div className="center_div">
                  <DisplayValueSmall
                    dis_value={datain?.number}
                    dis_text={datain?.text}
                  />
                </div>
              </Col>
              <Col md={4} lg={4} sm={4} xs={4} className="">
                <div className="center_div center_straight">
                  <Row>
                    <Col>
                      <div st>
                        <img
                          src={Team_image?.[String(datain?.awayID)]?.image}
                          width="100"
                          height="80"
                          alt={datain?.awayname}
                        />{" "}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div
                        style={{
                          fontFamily: "serif",
                          fontSize: "40px",
                          fontWeight: "bold",
                        }}
                      >
                        {datain?.away_val}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}
