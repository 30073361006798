/* eslint-disable no-shadow */
import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

export default function RenderPercentatge({ dis_value }) {
  const RADIAN = Math.PI / 180;
  const data = [
    { name: "A", value: 20, color: "red" },
    { name: "B", value: 20, color: "rgb(240, 100, 90)" },
    { name: "C", value: 20, color: "orange" },
    { name: "A", value: 20, color: "rgb(120, 240, 132)" },
    { name: "A", value: 20, color: "green" },
  ];
  const cx = 125;
  const cy = 150;
  const iR = 50;
  const oR = 120;
  const value = dis_value;

  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="yellow"
        fill={color}
      />,
      <text
        x={xp + 11}
        y={yp + 11}
        fontSize="14"
        fill="white"
        fontWeight="bold"
        textAnchor="middle"
        dy={-15}
      >
        {value + "%"}
      </text>,
    ];
  };
  return (
    <div
      className="center_div center straight"
      style={{ width: "100%", height: "216px" }}
    >
      <ResponsiveContainer>
        <PieChart width={300} height={216}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
            margin={{
              top: 20,
              bottom: 20,
            }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, "#d0d000")}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
