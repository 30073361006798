import React from "react";
import { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  useNavigate,
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import { newdate, renewalDate } from "./UserProfile";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import FooterAll from "./Footer";
const API_URL = process.env.REACT_APP_API_URL;
export default function PaymentReturn() {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const navigation = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    fetch(`${API_URL}/session-status?session_id=${sessionId}`)
    .then((res) => {
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        return res.json();
    })
    .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
    })
    .catch((error) => {
        console.error('Error:', error)
    });
  }, []);

  if (status === "open") {
    return <Navigate to="/payments" />;
  }

  if (status === "complete") {
    return (
      <div>
      <IndexHeader />
        <Container className="appear_center mainFont">
          <div className="center_div flex_column">
            <div>
              <section id="success">
                <p>
                  We appreciate your business! A confirmation email will be sent
                  to {customerEmail}. If you have any questions, please email{" "}
                  <a href="mailto:info@betinformatics.com">
                    info@betinformatics.com
                  </a>
                  .
                </p>
              </section>
            </div>
            <div>
              <div
                className="link smallbuton  divgreenButton margin30down"
                onClick={() => navigation("/")}
              >
                Go to Home
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
  return null;
}
