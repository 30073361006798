import React, { useCallback, useRef } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBInfiniteScroll,
} from "mdb-react-ui-kit";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import axios from "axios";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import FooterAll from "../Footer";
import IndexHeader from "../IndexHeaders";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import PreviewTicket from "./TicketPreview";

import "../../css/ThemeCss.css";

import LeagueNav from "../LeagueSideBar";
import IndexSubheads from "../IndexSubheader";
import FooterNav from "../SmallScreenNav";
import { SideNavPage } from "../Utils";
import { Spinner } from "react-bootstrap";
import { SpinnerCustom } from "../Spinner";

const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

export const Themes = {
  light: {
    background: "light",
    outerTableboarder: "outer-border-light",
    stripeTable: "striped-table-light",
    h6: "_h6-light",
  },
  dark: {
    background: "dark",
    outerTableboarder: "outer-border-dark",
    stripeTable: "striped-table-dark",
    h6: "_h6-dark",
  },
};
export const dataMode = ["all", "current"];
export const Location = () => {
  const [country, setCountry] = useState("Nigeria");
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const longitude = coords.longitude;
      const latitude = coords.latitude;
      if (!globalUser?.user?.curr_country) {
        getCountry(latitude, longitude).then((country) => {
          ////console.log(country);
          setCountry(country);
        });
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]); // added dependencies to make sure effect runs whenever these values change

  if (!country) {
    return null;
  }

  ////console.log(country);
  return country; // used {} to ensure country state value is displayed, not the string 'country'
};

const getCountry = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://api-bdc.net/data/reverse-geocode?latitude=${latitude}&longitude=${longitude}&localityLanguage=en&key=${REACT_APP_BIGDATA}`
    );

    return response?.data?.countryName;
  } catch (error) {
    //console.error(error);
  }
};

export default function Predictions() {
  const observer = useRef(null);
  const basicContainer = useRef(null);
  const [loading, setLoading] = useState(true);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const location = useLocation();
  const userinfo = location.state?.userinfo || {};
  const [isLogedin, setIsLogedin] = useState(false);

  const [issubscribed, setIsSubscribed] = useState(false);
  const [curr_location, setcurr_location] = useState("Location()");
  const [userInfo, setUserInfo] = useState(userinfo);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);

  const [collapseOpened, setCollapseOpened] = useState("accordionCollapse1");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigation = useNavigate();

  const tic_data = location?.state?.ticketInfo || null;
  /* const [useTickets, setUseTickets] = useState();
  const [useTicketsAll, setUseTicketsAll] = useState(); */
  const [useLike, setUseLike] = useState();
  const [useHeart, setUseHeart] = useState();
  const [useViews, setUseviews] = useState();
  const [useType, setuseType] = useState();
  const [isclickTicket, setisclickTicket] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [useTicketID, setuseTicketID] = useState();
  const PreviewTicketLazy = React.lazy(() => import("./TicketPreview"));
  const [ticType, setTicType] = useState("All");

  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  const user = {
    name: "user",
    firstname: "Guest",
    email:
      String.fromCharCode(
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97
      ) + "@betinfoguest.com",
    page: "indexPage",
    theme: Themes?.light,
    dataMode: "low",
    states: {
      theme: { radioValue },
      dataMode: 0,
    },
  };
  const neededInfo = [
    "firstname",
    "lastname",
    "phonenumber",
    "email",
    "address1",
    "address2",
    "dateofbirth",
    "country",
    "creationDate",
    "updatedDate",
    "subscription",
    "verifyEmail",
    "isadmin",
    "city",
    "auth",
    "Tickets",
    "state",
    "policy",
    "Terms",
    "zip",
    "dataMode",
  ];
  const tempInfo = {};
  neededInfo.forEach((key) => {
    if (userinfo?.firstname) {
      if (userinfo.hasOwnProperty(key)) {
        tempInfo[key] = userinfo[key];
      }
    } else {
      if (user.hasOwnProperty(key)) {
        tempInfo[key] = user[key];
      }
    }
  });
  tempInfo["name"] = "user";
  tempInfo["page"] = "index";
  tempInfo["theme"] = Themes?.light;
  //tempInfo["currCountry"] = Demo()
  const userData = { user: tempInfo };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      let ckUser;
      if (Object.keys(userinfo).length === 0) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log("get");
      } else if (
        Object.keys(globalUser).length === 0 &&
        Object.keys(userinfo).length !== 0
      ) {
        ////console.log("get since global is zero and userinfo is not");
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log(ckUser);
        if (
          ckUser?.data &&
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.firstname !== "Guest" &&
          ckUser?.data?.user?.user
        ) {
          ("do nothing");
          ////console.log("i did nothing in duel");
        } else {
          ////console.log("from local is guest using from login");
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
          setIsLogedin(true);
          if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "inactive"
          ) {
            ////console.log("inactive");
            setIsSubscribed(false);
          } else if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "active"
          ) {
            setIsSubscribed(true);
            ////console.log("active");
          } else {
            setIsSubscribed(false);
            ////console.log("nosub");
          }
        }
      } else {
        ////console.log("save");
        if (
          globalUser &&
          globalUser?.user &&
          globalUser?.user?.firstname !== "Guest"
        ) {
          ("do nothing");
          ////console.log("did nothing");
        } else {
          ////console.log("somehow i skipped this,", globalUser);
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
        }

        setIsLogedin(true);
        if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "inactive"
        ) {
          ////console.log("inactive");
          setIsSubscribed(false);
        } else if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
          ////console.log("active");
        } else {
          setIsSubscribed(false);
          ////console.log("nosub");
        }
      }

      ////console.log(userData);
      if (
        (ckUser && ckUser.result === "saved") ||
        (ckUser && ckUser.result === "retrieved" && ckUser.data !== undefined)
      ) {
        if (ckUser && ckUser.result === "saved") {
          userData["user"]["currCountry"] = curr_location;
          updateGlobalUser(userData);
          ////console.log("wwwasss here in saved to global with user data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "view_ticket",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          setUserInfo(userData);
        } else if (ckUser && ckUser.data && ckUser.data.user) {
          ckUser["data"]["user"]["user"]["currCountry"] = curr_location;

          // *** update login and subs *****
          if (
            (ckUser.data.user &&
              ckUser.data.user?.user?.firstname === "Guest" &&
              ckUser.data.user?.user) ||
            (ckUser.data.user && Object.keys(ckUser.data.user).length === 0)
          ) {
            setIsLogedin(false);
            setIsSubscribed(false);
          } else if (
            ckUser.data.user &&
            ckUser.data.user?.user?.firstname !== "Guest"
          ) {
            if (
              ckUser.data.user &&
              !ckUser.data.user?.user?.subscription &&
              ckUser.data.user?.user
            ) {
              ////console.log(ckUser.data.user);
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              if (
                ckUser.data.user &&
                ckUser.data.user?.user?.subscription?.status === "inactive"
              ) {
                setIsLogedin(true);
                setIsSubscribed(false);
              } else {
                setIsLogedin(true);
                setIsSubscribed(true);
              }
            }
          }
          // *** End update login and subs *****
          updateGlobalUser(ckUser.data.user);
          ////console.log("wwwasss here in saved to global with chuser data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "view_ticket",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          userData["user"]["currCountry"] = curr_location;
          setUserInfo(userData);
        } else {
          toast.error("An error occured");
        }
      } else {
        userData["user"]["currCountry"] = curr_location;
        ////console.log(" saved to global user with user data logged out");

        updateGlobalUser(userData);
        setUserInfo(userData);
        UpdateLocal({ action: "save", data: userData });
        //toast.info("You are logged out");
      }
    };

    fetchData();
    if (globalUser && Object.keys(globalUser).length === 0) {
      return;
    }
    ////console.log("save");
    if (
      (globalUser &&
        globalUser?.user?.firstname === "Guest" &&
        globalUser?.user) ||
      (globalUser && Object.keys(globalUser).length === 0)
    ) {
      setIsLogedin(false);
      setIsSubscribed(false);
    } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
      if (globalUser && !globalUser?.user?.subscription && globalUser?.user) {
        ////console.log(globalUser);
        setIsLogedin(true);
        setIsSubscribed(false);
      } else {
        if (
          globalUser &&
          globalUser?.user?.subscription?.status === "inactive"
        ) {
          setIsLogedin(true);
          setIsSubscribed(false);
        } else {
          setIsLogedin(true);
          setIsSubscribed(true);
        }
      }
    }
  }, [curr_location]);

  function TicketNav() {
    const [useTickets, setUseTickets] = useState();
    const [useTicketsAll, setUseTicketsAll] = useState();
    const infiniteRef = useRef < HTMLDivElement > null;
    const [itemIndex, setItemIndex] = useState(0);
    const [justifyActive, setJustifyActive] = useState(ticType);
    const [lastIndex, setLastIndex] = useState(0);
    //const [ticType, setTicType] = useState("All");
    const handleJustifyClick = (value) => {
      if (value === justifyActive) {
        return;
      }

      setJustifyActive(value);
      setTicType(value);
    };
    useEffect(() => {
      checkScreenSize();
      window.addEventListener("resize", checkScreenSize);
      let res = "";
      const fetchData = async () => {
        try {
          res = await axios
            .post(
              `${API_URL}/get_active_tickets`,
              JSON.stringify({ type_: ticType }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .catch((error) => {
              ////console.log(error);
            });
        } catch (error) {
          ////console.log(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
        ////console.log(res);
        if (res?.data?.status === "success") {
          /* const ticketList = res?.data?.data;
          const ticketLenght = ticketList?.length;
          const chunk = Math.round(ticketLenght / 5);
          const stateBasket = [];
          for (let index = 0; index < chunk; index++) {
            ////console.log("entered");
            const ticketsChunk = res?.data?.data
              ?.sort((a, b) => b?.tic_time - a?.tic_time)
              ?.slice(index * 5, 5 * (index + 1));
  
            stateBasket.push({
              load: false,
              tickets: ticketsChunk,
            });
          }
          ////console.log(stateBasket, chunk, ticketList.length);
          stateBasket[0]["load"] = true;
          stateBasket[1]["load"] = true; */
          setUseTickets(
            res?.data?.data
              ?.sort((a, b) => b?.tic_time - a?.tic_time)
              ?.slice(0, 5)
          );
          setUseTicketsAll(
            res?.data?.data?.sort((a, b) => b?.tic_time - a?.tic_time)?.slice(5)
          );
          setLoading(false);
        }
      };

      fetchData();
    }, [setTicType]);
    if (!useTickets) {
      return null;
    }
    ////console.log(useTickets, useTicketsAll);

    const handleScroll = () => {
      if (itemIndex > useTicketsAll.length - 1) {
        return;
      }

      setUseTickets([
        ...useTickets,
        useTicketsAll[itemIndex],
        useTicketsAll[itemIndex + 1],
      ]);

      setItemIndex(itemIndex + 2);
    };
    if (loading) {
      return <SpinnerCustom />;
    }
    return (
      <div className="">
      <div className="center_div sidepad">
      <div className="">
        <div className="center_div center_sides ">
          <div
            className={`statTable smallbuton link forceblack ${
              justifyActive === "All" ? "smartTableButton bold" : "inactiveButton"
            }`}
            onClick={() => handleJustifyClick("All")}
          >
            All
          </div>

          <div className="allpad">
            <div
              className={`statTable smallbuton link  forceblack ${
                justifyActive === "Incomplete"
                  ? "smartTableButton bold"
                  : "inactiveButton"
              }`}
              onClick={() => handleJustifyClick("Incomplete")}
            >
              Incomplete
            </div>
          </div>

          <div
            className={`statTable smallbuton link forceblack ${
              justifyActive === "Complete"
                ? "smartTableButton bold"
                : "inactiveButton"
            }`}
            onClick={() => handleJustifyClick("Complete")}
          >
            Complete
          </div>
        </div>
      </div>
      </div>
      
        {/* <MDBTabs pills justify className="mb-3 ">
          s
          <MDBTabsItem className="allpad">
            <MDBTabsLink
              onClick={() => handleJustifyClick("All")}
              active={justifyActive === "All"}
              className="midFont"
            >
              All Tickets
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem className="allpad">
            <MDBTabsLink
              onClick={() => handleJustifyClick("Incomplete")}
              active={justifyActive === "Incomplete"}
            >
              Incomplete Tickets
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem className="allpad">
            <MDBTabsLink
              onClick={() => handleJustifyClick("Complete")}
              active={justifyActive === "Complete"}
            >
              Complete Tickets
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs> */}

        <MDBTabsContent ref={basicContainer}>
          <MDBTabsPane
            open={justifyActive === "All"}
            //style={{ height: "1500px" }}
            className="noscrolbar"
          >
            <MDBInfiniteScroll
              infiniteScrollRef={infiniteRef}
              className="container list-group noscrolbar"
              style={{ maxHeight: "1400px", overflowY: "scroll" }}
              onInfiniteScroll={handleScroll}
            >
              {useTickets
                ?.sort((a, b) => b?.tic_time - a?.tic_time)
                ?.map((item, index) => (
                  <div className="allpad usemaxSpace">
                    <PreviewTicket
                      key={index}
                      userData={globalUser}
                      type={item?.type}
                      ticketID={item?.ticketID}
                    />
                  </div>
                ))}
            </MDBInfiniteScroll>
          </MDBTabsPane>
          <MDBTabsPane
            open={justifyActive === "Incomplete"}
            className="noscrolbar"
          >
            <MDBInfiniteScroll
              infiniteScrollRef={infiniteRef}
              className="container list-group noscrolbar"
              style={{ maxHeight: "1500px", overflowY: "scroll" }}
              onInfiniteScroll={handleScroll}
            >
              {useTickets
                ?.sort((a, b) => b?.tic_time - a?.tic_time)
                ?.map((item, index) => (
                  <div className="allpad usemaxSpace">
                    <PreviewTicket
                      key={index}
                      userData={globalUser}
                      type={item?.type}
                      ticketID={item?.ticketID}
                    />
                  </div>
                ))}
            </MDBInfiniteScroll>
          </MDBTabsPane>
          <MDBTabsPane
            open={justifyActive === "Complete"}
            className="noscrolbar"
          >
            <MDBInfiniteScroll
              infiniteScrollRef={infiniteRef}
              className="container list-group noscrolbar"
              style={{ maxHeight: "1500px", overflowY: "scroll" }}
              onInfiniteScroll={handleScroll}
            >
              {useTickets
                ?.sort((a, b) => b?.tic_time - a?.tic_time)
                ?.map((item, index) => (
                  <div className="allpad usemaxSpace">
                    <PreviewTicket
                      key={index}
                      userData={globalUser}
                      type={item?.type}
                      ticketID={item?.ticketID}
                    />
                  </div>
                ))}
            </MDBInfiniteScroll>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    );
  }

  return (
    <div>
      <div>
        {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen} />}
        <div className="usmaxSpace">
          <div md="">
            <IndexHeader
              setisclickTicket={setisclickTicket}
              isclickTicket={isclickTicket}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isLogedin={isLogedin}
        issubscribed={issubscribed}
            />
            {isMidScreen || isSmallScreen ? (
              <IndexSubheads
                sidenavOpen={sidenavOpen}
                setSidenavOpen={setSidenavOpen}
                islgedin={isLogedin}
                issubed={issubscribed}
                type={"league"}
              />
            ) : null}
          </div>
        </div>

        <MDBContainer style={{ height: "100%" }}>
          <div
            className="flex center_sides mainFont"
            style={{ height: "100%" }}
          >
            {isSmallScreen || isMidScreen ? null : (
              <div
                className="center_div"
                style={{ width: "30%", height: "100%" }}
              >
                <LeagueNav islgedin={isLogedin} issubed={issubscribed} />
              </div>
            )}
            &nbsp;
            <div
              className="usemaxSpace scrol_div flex center_straight noscrolbar"
              style={{ height: "100%" }}
            >
              <div>
                <TicketNav />
              </div>
            </div>
          </div>
        </MDBContainer>
        <FooterAll />
        <FooterNav />
      </div>
    </div>
  );
}
