import { useState, useContext, useEffect } from "react";
import { Container, Button, Col, Form, Row } from "react-bootstrap";
import IndexHeader from "./IndexHeaders";
import axios, { all } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PasswordChecklist from "react-password-checklist";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/signup.css";
import FooterAll from "./Footer";
import { IoIosLogIn } from "react-icons/io";
import { FaGoogle } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import EntryHeader from "./EntryHeader";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import IndexSubheads from "./IndexSubheader";
import { SideNavPage } from "./Utils";
const google_login = process.env.REACT_APP_GOOGLE_LOGIN;
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
export function validatePhone(number) {
  // Check if the input is a number and convert to string for regex test
  const str = typeof number === "number" ? number.toString() : number;

  // Regular Expression to match US phone numbers
  // Matches 3 digits followed by 3 digits followed by 4 digits
  const regex = /^\d{10}$/;

  return regex.test(str);
}
export function validateAge(birthDate) {
  const today = new Date();
  const birth = new Date(birthDate);

  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  // If born in the same year but future month, or same month but future day, subtract a year from age
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age >= 21;
}
export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}
function SignUp() {
  const default_form = {
    firstname: "",
    lastname: "",
    phonenumber: "",
    dateofbirth: new Date(),
    email: "",
    password: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    dataMode: "low",
    passwordagain: "",
    verifyEmail: false,
    Terms: false,
    policy: false,
    creationDate: new Date(),
    updatedDate: new Date(),
    state: "",
    zip: "",
  };
  const [formData, setFormData] = useState(default_form);
  const [validPass, setvalidPass] = useState(false);
  const [error, setError] = useState({});
  const location = useLocation();
  const clickedSub = location.state?.plan || null;
  const [useProcess, setUseProcess] = useState(clickedSub);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const navigate = useNavigate();
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [Shows, setShows] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);




  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
    ////console.log("update state:", error);
  }, [error]);
  /* const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; */
  const handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData({ ...formData, [event.target.name]: value });
  };
  const UpdateUser =async(token)=>{
    try {
      const res = await axios
        .post(`${API_URL}/AutoLogin`, JSON.stringify({token:token}), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});

        //console.log(res)
        if(res?.data?.status === true){
          const userData = {user:res?.data?.userinfo}
          userData["user"]["theme"] = {
            background: "light",
            outerTableboarder: "outer-border-light",
            stripeTable: "striped-table-light",
            h6: "_h6-light",
          },
        updateGlobalUser(userData);
        UpdateLocal({ action: "save", data: userData });
        }
        navigate("/");
    } catch (error) {
      //console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }

  }
  const handleLogInClick = () => {
    navigate("/Signin");
  };

  const check_dup_user = async (event) => {
    try {
      const res = await axios.post(`${API_URL}/val_em_ph`, formData);
      ////console.log(res);
      return res;
    } catch (error) {
      ////console.log(error);
    }
  };
  ////console.log(validPass);

  const ValidateFields = (form) => {
    let valid = false;
    let newError = { ...error };
    ////console.log(form);
    const vphone = validatePhone(form?.phonenumber);
    if (form?.firstname) {
      valid = true;

      delete newError.firstname;
      setError(newError);
    } else {
      setError({
        ...error,
        firstname: "Firstname is Mandatory",
      });
      return error?.firstname;
    }

    if (form?.lastname) {
      valid = true;

      delete newError.lastname;
      setError(newError);
    } else {
      setError({
        ...newError,
        lastname: "Lastname is Mandatory",
      });
      return newError?.lastname;
    }
    if (validateAge(form?.dateofbirth) !== false && form?.dateofbirth) {
      valid = true;

      delete newError.dateofbirth;
      setError(newError);
    } else {
      setError({
        ...newError,
        dateofbirth: "DOB is invalid",
      });
      return newError?.dateofbirth;
    }

    if (vphone !== false && form?.phonenumber) {
      valid = true;
      delete newError.phonenumber;
      setError(newError);
    } else {
      setError({
        ...newError,
        phonenumber: "Must be 10 digits",
      });
      return "Must be 10 digits";
    }
    if (validateEmail(form?.email) !== false && form?.email !== "") {
      valid = true;
      delete newError?.email;
      setError(newError);
    } else {
      setError({
        ...newError,
        email: "format example@web.com",
      });
      return "format example@web.com";
    }
    if (form?.Terms) {
      valid = true;
      delete newError?.Terms;
      setError(newError);
    } else {
      setError({
        ...newError,
        Terms: "You must agree to our Terms and Conditions to sign up",
      });
      return "You must agree to our Terms and Conditions to sign up";
    }
    if (form?.policy) {
      valid = true;
      delete newError?.policy;
      setError(newError);
    } else {
      setError({
        ...newError,
        policy: "You must agree to our Data Privacy Policy to sign up",
      });
      return "You must agree to our Data Privacy Policy to sign up";
    }

    if (validPass) {
      valid = true;
      delete newError?.password;
      setError(newError);
    } else {
      setError({
        ...newError,
        password: "password invalid",
      });
      return "password invalid";
    }
    return valid;
  };

  const handleSubmit = async (event) => {
    // Add your signup logic here
    /////console.log(formData);
    const validPre = await check_dup_user();
    const validData = validPre.data;
    const valid = validData.status;
    const allFields = ValidateFields(formData);
    const welcome_data = {
      user_name: formData?.firstname,
      user_email: [formData?.email],
      subject: "Welcome To Bet Informatics",
      send_mail: "info@betinformatics.com",
      sub_name: "Bet Informatics",
      message_type: "welcome",
      links: [],
    };
    ////console.log(allFields);
    ////console.log(error);
    if (valid === true) {
      if (allFields === true) {
        try {
          const res = await axios.post(`${API_URL}/users`, formData);
          let userinfo = "";
          let msg_res = "";
          ////console.log(res);
          if (res?.data?.status === "success") {
            //toast.success("Welcome to Bet Informatics" + formData.firstname);
            userinfo = res.data.userData;
            msg_res = await axios.post(`${API_URL}/send-mail`, welcome_data);
          } else {
            //toast.error("its not you, its us, plase try again");
            navigate("/signin");
          }

          if (!useProcess && res?.data?.status === "success") {
            let now = new Date();
            let senttime = Math.floor(now.getTime() / 1000);
            let fexptime = new Date(now.getTime() + 6 * 60000);
            let exptime = Math.floor(fexptime.getTime() / 1000);
            const datatoencode = {
              user_name: userinfo?.firstname,
              user_email: [userinfo?.email],
              senttime: senttime,
              exptime: exptime,
              session: userinfo?.auth?._id,
            };
            const encoder = await axios.post(`${API_URL}/pushtemp`, {
              type: "save",
              tostore: datatoencode,
            });

            const Emaildata = {
              user_name: userinfo?.firstname,
              user_email: [userinfo?.email],
              subject: "Email Verification",
              send_mail: "info@betinformatics.com",
              sub_name: "Bet Informatics",
              message_type: "emailVerification",
              links: [`${WEB}/verifyEmail?data=${encoder?.data?.savedData}`],
            };
            if (userinfo?.verifyEmail) {
              navigate("/userprofile", { state: { userinfo } });
            } else {
              navigate("/promptverifyemail", {
                state: { plan: useProcess, userinfo: userinfo },
              });
              ////console.log(Emaildata);
              const res = await axios.post(`${API_URL}/send-mail`, Emaildata);
            }
          } else if (res?.data?.status === "success") {
            navigate("/payments", {
              state: { plan: useProcess, userinfo: userinfo },
            });
            const ckUser = await UpdateLocal({
              action: "save",
              data: { user: userinfo },
            });
          }
        } catch (error) {
          ////console.log(error);
          //toast.error("something is wrong, check your data and try again");
        }
        setFormData(default_form);
      } else {
        //toast.error(allFields);
      }

      ////console.log("Data collected", formData);
    } else {
      //toast.error("Phone number or Email already used");
    }
  };

  return (
    <div
      style={{
        background: "rgba(11, 38, 71, 0.959)",
      }}
    >
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
      />
      {isMidScreen || isSmallScreen ? (
        <IndexSubheads
          sidenavOpen={sidenavOpen}
          setSidenavOpen={setSidenavOpen}
          setticketactive={setticketactive}
          setShows={setShows}
          type={"match"}
        />
      ) : null}

      <Container
        className="d-flex align-items-center justify-content-center"
        variant="light"
        style={{
          background: "white",
          marginTop: "5%",
          fontFamily: "serif",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          marginBottom: "30px",
          height: "98%",
          width: isSmallScreen ? "97%" : "80%",
        }}
      >
        <Container
          className="margin-10down margin10down d-flex align-items-center justify-content-center"
          style={{
            border: "4px solid orange",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            width: isSmallScreen ? "97%" : "80%",
          }}
        >
          <div style={{ height: "95%", width: isSmallScreen ? "98%" : "90%" }}>
            <div
              className="margin30down"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                fontSize: isSmallScreen ? "20px" : "30px",
                color: "green",
                fontWeight: "bold",
              }}
            >
              <FaRegIdCard style={{ scale: "inherit" }} /> &nbsp; Join Bet
              Informatics
            </div>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstName">
                  {/* <Form.Label>
                  <h6>First Name</h6>
                </Form.Label> */}
                  <Form.Control
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    value={formData.firstname}
                    onChange={handleChange}
                    className={error.firstname ? "is-invalid" : ""}
                    required
                  />
                  {error.firstname && (
                    <div style={{ color: "red" }}>!! {error.firstname} </div>
                  )}
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  {/* <Form.Label>
                  <h6>Last Name</h6>
                </Form.Label> */}
                  <Form.Control
                    type="lastname"
                    name="lastname"
                    placeholder="Last Name"
                    value={formData.lastname}
                    onChange={handleChange}
                    className={error.lastname ? "is-invalid" : ""}
                    required
                  />
                  {error.lastname && (
                    <div style={{ color: "red" }}>!! {error.lastname} </div>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridDOB">
                  {/* <Form.Label>
                  <h6>Date of Birth</h6>
                </Form.Label> */}
                  <Form.Control
                    type="date"
                    name="dateofbirth"
                    placeholder="Date Of Birth"
                    value={formData.dateofbirth}
                    onChange={handleChange}
                    className={error.dateofbirth ? "is-invalid" : ""}
                    required
                  />
                  {error.dateofbirth && (
                    <div style={{ color: "red" }}>!! {error.dateofbirth} </div>
                  )}
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPhoneno">
                  {/* <Form.Label>
                  <h6>Phone Number</h6>
                </Form.Label> */}
                  <Form.Control
                    type="tel"
                    name="phonenumber"
                    placeholder="Phone Number"
                    value={formData.phonenumber}
                    onChange={handleChange}
                    required
                    className={error.phonenumber ? "is-invalid" : ""}
                  />
                  {error.phonenumber && (
                    <div style={{ color: "red" }}>!! {error.phonenumber} </div>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  {/* <Form.Label>
                  <h6>Email</h6>
                </Form.Label> */}
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleChange}
                    className={error.email ? "is-invalid" : ""}
                    required
                  />
                  {error.email && (
                    <div style={{ color: "red" }}>!! {error.email} </div>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formGridPassword">
                  {/*  <Form.Label>
                  <h6>Password</h6>
                </Form.Label> */}
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className={error.password ? "is-invalid" : ""}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="passwordagain">
                  {/*  <Form.Label>
                  <h6>Password</h6>
                </Form.Label> */}
                  <Form.Control
                    type="password"
                    name="passwordagain"
                    placeholder="Password"
                    value={formData.passwordagain}
                    onChange={handleChange}
                    className={error.password ? "is-invalid" : ""}
                    required
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: isSmallScreen ? "8px" : "12px",
                    }}
                    minLength={5}
                    value={formData.password}
                    valueAgain={formData.passwordagain}
                    onChange={(isValid) => {
                      setvalidPass(isValid);
                    }}
                  />
                </div>
              </Row>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                {/* <Form.Label>
                <h6>Address</h6>
              </Form.Label> */}
                <Form.Control
                  type="text"
                  name="address1"
                  placeholder="Street Address"
                  value={formData.address1}
                  onChange={handleChange}
                  className={error.address1 ? "is-invalid" : ""}
                  required
                />
              </Form.Group>
              <Row className="mb-2">
                <Form.Group
                  as={Col}
                  className="mb-2"
                  controlId="formGridAddress2"
                >
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Apt/Suit"
                    value={formData.address2}
                    onChange={handleChange}
                    className={error.address2 ? "is-invalid" : ""}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-2"
                  controlId="formGridCountry"
                >
                  <CountryDropdown
                    classes="form-control"
                    value={formData.country}
                    onChange={(val) =>
                      handleChange({
                        target: {
                          name: "country",
                          value: val,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                  {/* <Form.Label>
                  <h6>City</h6>
                </Form.Label> */}
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    className={error.city ? "is-invalid" : ""}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  {/* <Form.Label>
                  <h6>State</h6>
                </Form.Label> */}
                  {/* <Form.Select
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className={error.state ? "is-invalid" : ""}
                    required
                  /> */}
                  <RegionDropdown
                    classes="form-control"
                    country={formData.country}
                    value={formData.state}
                    onChange={(val) =>
                      handleChange({
                        target: {
                          name: "state",
                          value: val,
                        },
                      })
                    }
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                  {/* <Form.Label>
                  <h6>Zip</h6>
                </Form.Label> */}
                  <Form.Control
                    type="text"
                    name="zip"
                    placeholder="Zip code"
                    value={formData.zip}
                    className={error.zip ? "is-invalid" : ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              {/* <Row>
                <Form.Group className="mb-3" controlId="dataMode">
                  
                  <Form.Select
                    name="dataMode"
                    value={formData.dataMode}
                    onChange={handleChange}
                    className={error.dataMode ? "is-invalid" : ""}
                    required
                  >
                    <option value="">Choose Data Mode...</option>
                    <option>high</option>
                    <option>low</option>
                  </Form.Select>
                </Form.Group>
              </Row> */}
              <Form.Group className="mb-3 align-items-center" id="Terms">
                {error.Terms && (
                  <div style={{ color: "red" }}>!! {error.Terms} </div>
                )}
                <Form.Check
                  type="checkbox"
                  label={
                    <span style={{ verticalAlign: "middle" }}>
                      Agree to our{" "}
                      <a className="link" onClick={() => navigate("/TAC")}>
                        Terms and Conditions
                      </a>
                    </span>
                  }
                  name="Terms"
                  onChange={handleChange}
                  checked={formData.Terms === true}
                  className={error.Terms ? "is-invalid" : ""}
                  style={{ display: "flex", alignItems: "center" }}
                />
              </Form.Group>
              <Form.Group className="mb-3 align-items-center" id="Policy">
                {error.policy && (
                  <div style={{ color: "red" }}>!! {error.policy} </div>
                )}
                <Form.Check
                  type="checkbox"
                  label={
                    <span style={{ verticalAlign: "middle" }}>
                      Agree to our data{" "}
                      <a
                        className="link"
                        onClick={() => navigate("/privacypolicy")}
                      >
                        privacy ploicy
                      </a>
                    </span>
                  }
                  name="policy"
                  onChange={handleChange}
                  className={error.policy ? "is-invalid" : ""}
                  checked={formData.policy === true}
                  style={{ display: "flex", alignItems: "center" }}
                />
              </Form.Group>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  variant="button"
                  type="submit"
                  className="link"
                  onClick={() => handleSubmit()}
                  style={{
                    background: "green",
                    fontSize: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    color: "white",
                    borderBottomRightRadius: "10px",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "orange";
                  }} // change color on hover
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                >
                  <FaRegIdCard style={{ scale: "inherit" }} /> &nbsp; Sign Up
                </div>
              </Row>

              <div
                style={{
                  marginTop: "10px",

                  marginBottom: "10px",
                  height: "1px", // This defines the thickness of your line
                  backgroundColor: "grey", // This defines the color of your line
                  width: "100%", // This defines the length of your line
                }}
              />
              
                  <div className="margin-10down usemaxSpace center_div">
                    <GoogleOAuthProvider clientId={google_login}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          UpdateUser(credentialResponse?.credential)
                        }}
                        onError={() => {
                          //console.log("Login Failed");
                        }}
                      />
                    </GoogleOAuthProvider>
                  </div>
              
              <div className="center_div margin-30down">
                
                <Row className=" usemaxSpace">
                  <Col md={8}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Already Have an Account?
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <div
                        variant="button"
                        type="submit"
                        className="link"
                        onClick={() => handleLogInClick()}
                        style={{
                          background: "rgba(6, 172, 184, 0.76)",

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          color: "white",
                          borderBottomRightRadius: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.target.style.color = "orange";
                        }} // change color on hover
                        onMouseOut={(e) => {
                          e.target.style.color = "white";
                        }}
                      >
                        <IoIosLogIn style={{ scale: "inherit" }} /> &nbsp; Login
                        Here
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Container>
      </Container>

      <FooterAll page={"signin"} />
    </div>
  );
}

export default SignUp;
