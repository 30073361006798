import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import Team_image from "../../images/team_images.json";
import { Errorlog, convertUnixToTime } from "../Utils";
import "../../css/Teamstats.css";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { CatchedStoraged } from "../manageStorage/CatchedData";

const API_URL = process.env.REACT_APP_API_URL;

function removeDuplicatesByKey(arrayOfDicts, key) {
  const uniqueDicts = [];

  // Filtering out duplicates based on the specified key
  arrayOfDicts.forEach((dict) => {
    if (!uniqueDicts.some((uniqueDict) => uniqueDict[key] === dict[key])) {
      uniqueDicts.push(dict);
    }
  });

  return uniqueDicts;
}
function getColorCode(value) {
  ////console.log(value)
  const valueNormalized = value / 100; // Normalize value to range 0-1
  // Hue range for green to red (reversed)
  const hue = (valueNormalized * 120) / 360.0;
  const lightness = 0.5; // Fixed lightness for all colors
  const saturation = 0.8; // Fixed saturation for all colors

  const rgbColor = hlsToRgb(hue, lightness, saturation).map((x) =>
    Math.round(255 * x)
  );

  function rgbToHex(rgb) {
    return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }

  return rgbToHex(rgbColor);
}

// Helper function to convert HLS to RGB
function hlsToRgb(h, l, s) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r, g, b];
}

function GetPercentatge(value, strings) {
  let tot = "";
  let values = "";
  if (typeof strings === "string") {
    tot = strings.split("");
    values = value / tot.length;
  } else {
    values = value / strings;
  }

  const semval = Math.round(values * 100);
  let out = `${semval} %`;
  if (out === "NaN %") {
    out = "- %";
  }
  return out;
}
function Getcolor(value, strings) {
  let tot = "";
  let values = "";
  if (typeof strings === "string") {
    tot = strings.split("");
    values = value / tot.length;
  } else {
    values = value / strings;
  }
  
  const semval = Math.round(values * 100);
  const out = getColorCode(semval);
  return out;
}
const GetHalfWin = (homeID, awayID, homeVal, awayVal) => {
  if (homeVal > awayVal) {
    return homeID;
  } else if (homeVal < awayVal) {
    return awayID;
  } else {
    return -1;
  }
};
export default function DisplayMatchHistory({
  Tab2,
  bias,
  half,
  teamA,
  teamB,
  small,
  mid,
}) {
  const { globalUser } = useContext(GlobalUserContext);
  const [monthCount, setMonthCount] = useState(10);
  const [Data,setData] = useState([])
  const [loading,setLoading] = useState(false)

  ////console.log(Tab2,half,bias,teamA,teamB)

  useEffect(() =>{
    const get_mdata = async() =>{
      let min = Math.min(teamA,teamB)
      let max = Math.max(teamB,teamA)
      let options = {
        body: JSON.stringify( {
          match: ""+min+"_"+max,
          type: bias,
        } ),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60 * 60,
        },

        seconds: 2 * 60 * 60,
      };
      let url = "/match_hist";
      let indexdb = "match_hist";
      let SaveKey = ""+min+"_"+max + bias;
      try{
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/match_hist`,
          JSON.stringify( {
            match: ""+min+"_"+max,
            type: bias,
          } ),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        const fix_data = JSON.parse(res?.data);
        //console.log(fix_data)
        if(res?.status==="success"){
          setData(fix_data?.data?.["H2H HISTORY"]?.[bias])
          setLoading(false)
          
        }else{
          Errorlog("loaderror","error loading statistics","from league stats")
        }
      }catch (error){
        Errorlog(error,"error loading statistics","from league stats")
        ////console.log(error)
      }
      
    }
    get_mdata()

  },[])


  ////console.log(Data)
  function MultiLeague({ monthCount }) {
    const navigation = useNavigate();
    const DisplayData = ({ Indata }) => {
      const WinnerButton = ({ team_ ,status}) => {
        return (
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {status !== "complete" ? status === "incomplete" ? "Pend" : "Susp" : team_ !== -1 ? (
              <>
                <img
                  src={Team_image?.[String(team_)]?.["image"]}
                  width="22"
                  height="17"
                  alt={Indata?.["home_name"]}
                />
                {" Wins"}
              </>
            ) : (
              "Draw"
            )}
          </div>
        );
      };
      ////console.log(Indata);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };
      let t_d = {};
      let wdlType 
      let wdlpick
      let winnerman
      ////console.log(Indata)
      if (Tab2 === "Win-Draw-Lose") {
        t_d = Indata?.win_tab;
        wdlType = "wdl"
        wdlpick = "team_wdl_trail"
        
      } else if (Tab2 === "Goals") {
        t_d = Indata?.goals_tab;
      } else if (Tab2 === "Cards") {
        t_d = Indata?.cards_tab;
      } else if (Tab2 === "Corners") {
        t_d = Indata?.corners_tab;
      }else if (Tab2 === "WDL-Cards") {
        t_d = Indata?.corners_tab;
        wdlType = "card_wdl"
        wdlpick = "team_card_wdl_trail"
        
      }
      else if (Tab2 === "WDL-Corners") {
        t_d = Indata?.corners_tab;
        wdlType = "corner_wdl"
        wdlpick = "team_corner_wdl_trail"
      }
      let halfs = "ft";
      if (half === "Full Time") {

        if (Tab2 === "Win-Draw-Lose") {
          if(Indata?.homeGoalCount > Indata?.awayGoalCount 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.homeGoalCount < Indata?.awayGoalCount){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
          
        }else if (Tab2 === "WDL-Cards") {
          if(Indata?.team_a_cards_num > Indata?.team_b_cards_num 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_cards_num < Indata?.team_b_cards_num){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }else if (Tab2 === "WDL-Corners") {
          if(Indata?.team_a_corners > Indata?.team_b_corners 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_corners < Indata?.team_b_corners){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }
        halfs = "ft";
      } else if (half === "First Half") {
        if (Tab2 === "Win-Draw-Lose") {
          if(Indata?.ht_goals_team_a > Indata?.ht_goals_team_b 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.ht_goals_team_a < Indata?.ht_goals_team_b){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
          
        }else if (Tab2 === "WDL-Cards") {
          if(Indata?.team_a_fh_cards> Indata?.team_b_fh_cards 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_fh_cards < Indata?.team_b_fh_cards){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }else if (Tab2 === "WDL-Corners") {
          if(Indata?.team_a_fh_corners > Indata?.team_b_fh_corners 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_fh_corners < Indata?.team_b_fh_corners){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }
        halfs = "ht";
      } else if (half === "Second Half") {
        if (Tab2 === "Win-Draw-Lose") {
          if(Indata?.goals_2hg_team_a > Indata?.goals_2hg_team_b 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.goals_2hg_team_a < Indata?.goals_2hg_team_b){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
          
        }else if (Tab2 === "WDL-Cards") {
          if(Indata?.team_a_2h_cards> Indata?.team_b_2h_cards 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_2h_cards < Indata?.team_b_2h_cards){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }else if (Tab2 === "WDL-Corners") {
          if(Indata?.team_a_2h_corners > Indata?.team_b_2h_corners 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_2h_corners < Indata?.team_b_2h_corners){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }
        halfs = "2h";
      }
      const match_name = `${Math.min(teamA, teamB)}_${Math.max(teamA, teamB)}`;
      ////console.log(match_name);
      const getBorder = (field) => {
        if (winnerman === Indata?.homeID && field === "home") {
          return "solid 2px";
        } else if (winnerman === Indata?.awayID && field === "away") {
          return "solid 2px";
        } else if (winnerman === -1 && field === "draw") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      
      const getBorder_goal = (field) => {
        if (t_d?.g_outcome === "over" && field === "overg") {
          return "solid 2px";
        } else if (t_d?.g_outcome === "under" && field === "underg") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "score" && field === "score") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "no_score" && field === "noscore") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_cc = (field) => {
        if (t_d?.c_outcome === "under" && field === "under") {
          return "solid 2px";
        } else if (t_d?.c_outcome === "over" && field === "over") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      let gh2 = "";

      let ch2 = "";

      let coh2 = "";
      if (half === "Full Time") {
        gh2 = "2.5";

        ch2 = "5.5";

        coh2 = "9.5";
      } else if (half === "First Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
      } else if (half === "Second Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
      }
      const handlematchlick = (match_id, team1, team2, seas) => {
        navigation("/match/" + match_id, {
          state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
        });
      };
      return (
        <>
          <td className="dates">
            {small
              ? convertUnixToTime(Indata && Indata["date_unix"], "short")
              : convertUnixToTime(Indata && Indata["date_unix"], "")}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td className="awayTable td_ link">
                <div>
                  {Indata?.league_detail && (
                    <ReactCountryFlag
                      countryCode={Indata?.league_detail?.country_iso}
                      svg
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label={Indata?.league_detail?.country}
                    />
                  )}{" "}
                  {Indata?.league_detail?.league_name}
                </div>
              </td>
              <td></td>
              <td></td>{" "}
            </>
          )}

          <td
            className="homeOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["homeID"],
                team_: Indata?.["home_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            {Indata?.winningTeam === Indata?.homeID ? (
              <>
                <div
                  className="center_straight bold center_sides"
                  style={{ maxWidth: window.innerWidth < 770 ? Tab2 === "Win-Draw-Lose" ?"60px" : "80px" : "" }}
                >
                  <div
                    className="noBorders"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      wordWrap: "break-word",
                      width: "100%",
                      overflowWrap: "break-word",
                    }}
                  >
                    {Indata?.["home_name"]} &nbsp;
                  </div>
                  <div>
                    <img
                      src={Team_image?.[String(Indata?.["homeID"])]?.["image"]}
                      width="20"
                      height="15"
                      alt={Indata?.["home_name"]}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="center_straight center_sides"
                  style={{ maxWidth: window.innerWidth < 770 ? Tab2 === "Win-Draw-Lose" ?"60px" : "80px" : "" }}
                >
                  <div
                    className="noBorders"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      wordWrap: "break-word",
                      width: "100%",
                      overflowWrap: "break-word",
                    }}
                  >
                    {Indata?.["home_name"]}&nbsp;
                  </div>
                  <div>
                    <img
                      src={Team_image?.[String(Indata?.["homeID"])]?.["image"]}
                      width="20"
                      height="15"
                      alt={Indata?.["home_name"]}
                    />
                  </div>
                </div>
              </>
            )}
          </td>

          {Indata?.status === "complete" ? (
            <td
              className="statsOR link"
              onClick={() =>
                handlematchlick(
                  Indata && Indata["id"],
                  Indata?.["homeID"],
                  Indata?.["awayID"],
                  Indata?.season
                )
              }
            >
              {halfs === "ft"
                ? Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                  ? `${Indata?.homeGoalCount ?? "-"} : ${
                      Indata?.awayGoalCount ?? "-"
                    }`
                  : Tab2 === "Cards" || Tab2 === "WDL-Cards"
                  ? `${Indata?.team_a_cards_num ?? "-"} : ${
                      Indata?.team_b_cards_num ?? "-"
                    }`
                  : Tab2 === "Corners" || Tab2 === "WDL-Corners"
                  ? `${Indata?.team_a_corners ?? "-"} : ${
                      Indata?.team_b_corners ?? "-"
                    }`
                  : null
                : halfs === "ht"
                ? Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                  ? `${Indata?.ht_goals_team_a ?? "-"} : ${
                      Indata?.ht_goals_team_b ?? "-"
                    }`
                  : Tab2 === "Cards" || Tab2 === "WDL-Cards"
                  ? `${Indata?.team_a_fh_cards ?? "-"} : ${
                      Indata?.team_b_fh_cards ?? "-"
                    }`
                  : Tab2 === "Corners" ||Tab2 === "WDL-Corners"
                  ? `${Indata?.team_a_fh_corners ?? "-"} : ${
                      Indata?.team_b_fh_corners ?? "-"
                    }`
                  : null
                : Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                ? `${Indata?.goals_2hg_team_a ?? "-"} : ${
                    Indata?.goals_2hg_team_b ?? "-"
                  }`
                : Tab2 === "Cards" || Tab2 === "WDL-Cards"
                ? `${Indata?.team_a_2h_cards ?? "-"} : ${
                    Indata?.team_b_2h_cards ?? "-"
                  }`
                : Tab2 === "Corners" || Tab2 === "WDL-Corners"
                ? `${Indata?.team_a_2h_corners ?? "-"} : ${
                    Indata?.team_b_2h_corners ?? "-"
                  }`
                : null}
            </td>
          ) : (
            <td
              className="statsOR link"
              onClick={() =>
                handlematchlick(
                  Indata && Indata["id"],
                  Indata?.["homeID"],
                  Indata?.["awayID"],
                  Indata?.season
                )
              }
            >
              {" "}
              -:-
            </td>
          )}
          <td
            className="awayOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["awayID"],
                team_: Indata?.["away_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            {Indata?.winningTeam === Indata?.awayID ? (
              <div
                className="center_straight bold center_sides"
                style={{ maxWidth: window.innerWidth < 770 ? Tab2 === "Win-Draw-Lose" ?"60px" : "80px" : "" }}
              >
                <div>
                  <img
                    src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                    width="20"
                    height="15"
                    alt={Indata?.["away_name"]}
                  />
                </div>

                <div
                  className="noBorders"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  &nbsp;
                  {Indata?.["away_name"]}{" "}
                </div>
              </div>
            ) : (
              <>
            
              <div
                className="center_straight center_sides"
                style={{ maxWidth: window.innerWidth < 770 ? Tab2 === "Win-Draw-Lose" ?"60px" : "80px" : "" }}
              >
                <div>
                  <img
                    src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                    width="20"
                    height="15"
                    alt={Indata?.["away_name"]}
                  />
                </div>

                <div
                  className="noBorders"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  &nbsp;
                  {Indata?.["away_name"]}{" "}
                </div>
              </div>
              </>
            )}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td></td>
            </>
          )}

          {Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" || Tab2 === "WDL-Corners" ? (
            <>
              {small ? null : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background:
                          Getcolor(
                            Indata?.["old_" + Indata?.homeID]?.[wdlType]
                              ?.[wdlpick]?.["count_win_" + halfs],
                            Indata?.["old_" + Indata?.homeID]?.[wdlType]
                              ?.[wdlpick]?.["trail_" + halfs]
                          ) ?? "- %",
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder("home"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + Indata?.homeID]?.[wdlType]
                          ?.[wdlpick]?.["count_win_" + halfs],
                        Indata?.["old_" + Indata?.homeID]?.[wdlType]
                          ?.[wdlpick]?.["trail_" + halfs]
                      )}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + Indata?.homeID]?.[wdlType]
                            ?.[wdlpick]?.["count_draw_" + halfs],
                          Indata?.["old_" + Indata?.homeID]?.[wdlType]
                            ?.[wdlpick]?.["trail_" + halfs]
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder("draw"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + Indata?.homeID]?.[wdlType]
                          ?.[wdlpick]?.["count_draw_" + halfs],
                        Indata?.["old_" + Indata?.homeID]?.[wdlType]
                          ?.[wdlpick]?.["trail_" + halfs]
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + Indata?.awayID]?.[wdlType]
                            ?.[wdlpick]?.["count_win_" + halfs],
                          Indata?.["old_" + Indata?.awayID]?.[wdlType]
                            ?.[wdlpick]?.["trail_" + halfs]
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder("away"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + Indata?.awayID]?.[wdlType]
                          ?.[wdlpick]?.["count_win_" + halfs],
                        Indata?.["old_" + Indata?.awayID]?.[wdlType]
                          ?.[wdlpick]?.["trail_" + halfs]
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </>
              )}

              <td className="preds">
                
                  <WinnerButton team_={winnerman}  status={Indata?.status} />
                
                  
              </td>
              {small ? (
                <td className="link">
                  <IoIosArrowDropdownCircle size={20} color="gray" />
                </td>
              ) : null}
            </>
          ) : Tab2 === "Goals" ? (
            <>
              {small ? (
                <>
                  <td className="link">
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                </>
              ) : (
                <>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.goals?.[
                            "over_" + halfs
                          ]?.[gh2],
                          Indata?.["old_" + match_name]?.goals?.match_count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("overg"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.goals?.[
                          "over_" + halfs
                        ]?.[gh2],
                        Indata?.["old_" + match_name]?.goals?.match_count
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.goals?.[
                            "under_" + halfs
                          ]?.[gh2],
                          Indata?.["old_" + match_name]?.goals?.match_count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("underg"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.goals?.[
                          "under_" + halfs
                        ]?.[gh2],
                        Indata?.["old_" + match_name]?.goals?.match_count
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.wdl?.bts?.bts_yes,
                          Indata?.["old_" + match_name]?.wdl?.match_count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("score"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.wdl?.bts?.bts_yes,
                        Indata?.["old_" + match_name]?.wdl?.match_count
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.wdl?.bts?.bts_no,
                          Indata?.["old_" + match_name]?.wdl?.match_count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_goal("noscore"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.wdl?.bts?.bts_no,
                        Indata?.["old_" + match_name]?.wdl?.match_count
                      ) ?? "- %"}
                    </div>
                  </td>
                </>
              )}
            </>
          ) : Tab2 === "Cards" ? (
            <>
              {small ? (
                <td className="link">
                  <IoIosArrowDropdownCircle size={20} color="gray" />
                </td>
              ) : (
                <>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.cards?.[
                            "over_" + halfs
                          ]?.[ch2],
                          Indata?.["old_" + match_name]?.cards?.foul_average
                            ?.count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("over"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.cards?.[
                          "over_" + halfs
                        ]?.[ch2],
                        Indata?.["old_" + match_name]?.cards?.foul_average
                          ?.count
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.cards?.[
                            "under_" + halfs
                          ]?.[ch2],
                          Indata?.["old_" + match_name]?.cards?.foul_average
                            ?.count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("under"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.cards?.[
                          "under_" + halfs
                        ]?.[ch2],
                        Indata?.["old_" + match_name]?.cards?.foul_average
                          ?.count
                      ) ?? "- %"}
                    </div>
                  </td>
                </>
              )}
            </>
          ) : (
            <>
              {small ? (
                <td className="link">
                  <IoIosArrowDropdownCircle size={20} color="gray" />
                </td>
              ) : (
                <>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.corners?.[
                            "over_" + halfs
                          ]?.[coh2],
                          Indata?.["old_" + match_name]?.corners?.match_count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("over"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.corners?.[
                          "over_" + halfs
                        ]?.[coh2],
                        Indata?.["old_" + match_name]?.corners?.match_count
                      ) ?? "- %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        height: "20px",
                        background: Getcolor(
                          Indata?.["old_" + match_name]?.corners?.[
                            "under_" + halfs
                          ]?.[coh2],
                          Indata?.["old_" + match_name]?.corners?.match_count
                        ),
                        alignItems: "center",
                        justifyContent: "center",
                        border: getBorder_cc("under"),
                      }}
                    >
                      {GetPercentatge(
                        Indata?.["old_" + match_name]?.corners?.[
                          "under_" + halfs
                        ]?.[coh2],
                        Indata?.["old_" + match_name]?.corners?.match_count
                      ) ?? "- %"}
                    </div>
                  </td>
                </>
              )}
            </>
          )}
        </>
      );
    };

    const DisplayDrop = ({ Indata }) => {
      ////console.log(data);
      let t_d = {};
      let wdlType 
      let wdlpick
      let winnerman
      ////console.log(Indata)
      if (Tab2 === "Win-Draw-Lose") {
        t_d = Indata?.win_tab;
        wdlType = "wdl"
        wdlpick = "team_wdl_trail"
        
      } else if (Tab2 === "Goals") {
        t_d = Indata?.goals_tab;
      } else if (Tab2 === "Cards") {
        t_d = Indata?.cards_tab;
      } else if (Tab2 === "Corners") {
        t_d = Indata?.corners_tab;
      }else if (Tab2 === "WDL-Cards") {
        t_d = Indata?.corners_tab;
        wdlType = "card_wdl"
        wdlpick = "team_card_wdl_trail"
        
      }
      else if (Tab2 === "WDL-Corners") {
        t_d = Indata?.corners_tab;
        wdlType = "corner_wdl"
        wdlpick = "team_corner_wdl_trail"
      }
      let halfs = "ft";
      if (half === "Full Time") {

        if (Tab2 === "Win-Draw-Lose") {
          if(Indata?.homeGoalCount > Indata?.awayGoalCount 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.homeGoalCount < Indata?.awayGoalCount){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
          
        }else if (Tab2 === "WDL-Cards") {
          if(Indata?.team_a_cards_num > Indata?.team_b_cards_num 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_cards_num < Indata?.team_b_cards_num){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }else if (Tab2 === "WDL-Corners") {
          if(Indata?.team_a_corners > Indata?.team_b_corners 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_corners < Indata?.team_b_corners){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }
        halfs = "ft";
      } else if (half === "First Half") {
        if (Tab2 === "Win-Draw-Lose") {
          if(Indata?.ht_goals_team_a > Indata?.ht_goals_team_b 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.ht_goals_team_a < Indata?.ht_goals_team_b){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
          
        }else if (Tab2 === "WDL-Cards") {
          if(Indata?.team_a_fh_cards> Indata?.team_b_fh_cards 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_fh_cards < Indata?.team_b_fh_cards){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }else if (Tab2 === "WDL-Corners") {
          if(Indata?.team_a_fh_corners > Indata?.team_b_fh_corners 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_fh_corners < Indata?.team_b_fh_corners){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }
        halfs = "ht";
      } else if (half === "Second Half") {
        if (Tab2 === "Win-Draw-Lose") {
          if(Indata?.goals_2hg_team_a > Indata?.goals_2hg_team_b 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.goals_2hg_team_a < Indata?.goals_2hg_team_b){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
          
        }else if (Tab2 === "WDL-Cards") {
          if(Indata?.team_a_2h_cards> Indata?.team_b_2h_cards 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_2h_cards < Indata?.team_b_2h_cards){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }else if (Tab2 === "WDL-Corners") {
          if(Indata?.team_a_2h_corners > Indata?.team_b_2h_corners 
          ){
            winnerman = Indata?.homeID
          }else if(Indata?.team_a_2h_corners < Indata?.team_b_2h_corners){
            winnerman = Indata?.awayID
          }else{
            winnerman = -1
          }
        }
        halfs = "2h";
      }
      const match_name = `${Math.min(teamA, teamB)}_${Math.max(teamA, teamB)}`;
      ////console.log(match_name);
      const getBorder = (field) => {
        if (winnerman === Indata?.homeID && field === "home") {
          return "solid 2px";
        } else if (winnerman === Indata?.awayID && field === "away") {
          return "solid 2px";
        } else if (winnerman === -1 && field === "draw") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_goal = (field) => {
        if (t_d?.g_outcome === "over" && field === "overg") {
          return "solid 2px";
        } else if (t_d?.g_outcome === "under" && field === "underg") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "score" && field === "score") {
          return "solid 2px";
        } else if (t_d?.score_outcome === "no_score" && field === "noscore") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const getBorder_cc = (field) => {
        if (t_d?.c_outcome === "under" && field === "under") {
          return "solid 2px";
        } else if (t_d?.c_outcome === "over" && field === "over") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      let gh2 = "";

      let ch2 = "";

      let coh2 = "";
      if (half === "Full Time") {
        gh2 = "2.5";

        ch2 = "5.5";

        coh2 = "9.5";
      } else if (half === "First Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
      } else if (half === "Second Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
      }

      return (
        <div>
          <div className="center_div_distributed margin-30down">
            <div>
              <div>Time</div>
              <div>
                {convertUnixToTime(Indata && Indata["date_unix"], "time")}
              </div>
            </div>
            <div>
              <div>League</div>
              <div>
                <div>
                  {Indata?.league_detail && (
                    <ReactCountryFlag
                      countryCode={Indata?.league_detail?.country_iso}
                      svg
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label={Indata?.league_detail?.country}
                    />
                  )}{" "}
                  {Indata?.league_detail?.league_name}
                </div>
              </div>
            </div>
            {Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" || Tab2 === "WDL-Corners" ? (
              <>
                <div>
                  <div>Win%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background:
                        Getcolor(
                          Indata?.["old_" + Indata?.homeID]?.[wdlType]
                            ?.[wdlpick]?.["count_win_" + halfs],
                          Indata?.["old_" + Indata?.homeID]?.[wdlType]
                            ?.[wdlpick]?.["trail_" + halfs]
                        ) ?? "- %",
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("home"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + Indata?.homeID]?.[wdlType]?.[wdlpick]?.[
                        "count_win_" + halfs
                      ],
                      Indata?.["old_" + Indata?.homeID]?.[wdlType]?.[wdlpick]?.[
                        "trail_" + halfs
                      ]
                    )}
                  </div>
                </div>
                <div>
                  <div>Draw%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + Indata?.homeID]?.[wdlType]
                          ?.[wdlpick]?.["count_draw_" + halfs],
                        Indata?.["old_" + Indata?.homeID]?.[wdlType]
                          ?.[wdlpick]?.["trail_" + halfs]
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("draw"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + Indata?.homeID]?.[wdlType]?.[wdlpick]?.[
                        "count_draw_" + halfs
                      ],
                      Indata?.["old_" + Indata?.homeID]?.[wdlType]?.[wdlpick]?.[
                        "trail_" + halfs
                      ]
                    ) ?? "- %"}
                  </div>
                </div>
                <div>
                  <div>A_Win%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + Indata?.awayID]?.[wdlType]
                          ?.[wdlpick]?.["count_win_" + halfs],
                        Indata?.["old_" + Indata?.awayID]?.[wdlType]
                          ?.[wdlpick]?.["trail_" + halfs]
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder("away"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + Indata?.awayID]?.[wdlType]?.[wdlpick]?.[
                        "count_win_" + halfs
                      ],
                      Indata?.["old_" + Indata?.awayID]?.[wdlType]?.[wdlpick]?.[
                        "trail_" + halfs
                      ]
                    ) ?? "- %"}
                  </div>
                </div>
              </>
            ) : Tab2 === "Goals" ? (
              <>
                <div>
                  <div>{"Ov" + gh2 + "%"}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.goals?.[
                          "over_" + halfs
                        ]?.[gh2],
                        Indata?.["old_" + match_name]?.goals?.match_count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("overg"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.goals?.["over_" + halfs]?.[
                        gh2
                      ],
                      Indata?.["old_" + match_name]?.goals?.match_count
                    ) ?? "- %"}
                  </div>
                </div>
                <div>
                  <div>{"Un" + gh2 + "%"}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.goals?.[
                          "under_" + halfs
                        ]?.[gh2],
                        Indata?.["old_" + match_name]?.goals?.match_count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("underg"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.goals?.[
                        "under_" + halfs
                      ]?.[gh2],
                      Indata?.["old_" + match_name]?.goals?.match_count
                    ) ?? "- %"}
                  </div>
                </div>
                <div>
                  <div>BTS_Y%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.wdl?.bts?.bts_yes,
                        Indata?.["old_" + match_name]?.wdl?.match_count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("score"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.wdl?.bts?.bts_yes,
                      Indata?.["old_" + match_name]?.wdl?.match_count
                    ) ?? "- %"}
                  </div>
                </div>
                <div className="center_div center_straight">
                  <div>BTS_N%</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.wdl?.bts?.bts_no,
                        Indata?.["old_" + match_name]?.wdl?.match_count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_goal("noscore"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.wdl?.bts?.bts_no,
                      Indata?.["old_" + match_name]?.wdl?.match_count
                    ) ?? "- %"}
                  </div>
                </div>
              </>
            ) : Tab2 === "Cards" ? (
              <>
                <div>
                  <div>{"Ov" + ch2 + "%"}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.cards?.[
                          "over_" + halfs
                        ]?.[ch2],
                        Indata?.["old_" + match_name]?.cards?.foul_average
                          ?.count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("over"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.cards?.["over_" + halfs]?.[
                        ch2
                      ],
                      Indata?.["old_" + match_name]?.cards?.foul_average?.count
                    ) ?? "- %"}
                  </div>
                </div>
                <div>
                  <div>{"Un" + ch2 + "%"}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.cards?.[
                          "under_" + halfs
                        ]?.[ch2],
                        Indata?.["old_" + match_name]?.cards?.foul_average
                          ?.count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("under"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.cards?.[
                        "under_" + halfs
                      ]?.[ch2],
                      Indata?.["old_" + match_name]?.cards?.foul_average?.count
                    ) ?? "- %"}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>{"Ov" + coh2 + "%"}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.corners?.[
                          "over_" + halfs
                        ]?.[coh2],
                        Indata?.["old_" + match_name]?.corners?.match_count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("over"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.corners?.[
                        "over_" + halfs
                      ]?.[coh2],
                      Indata?.["old_" + match_name]?.corners?.match_count
                    ) ?? "- %"}
                  </div>
                </div>
                <div>
                  <div>{"Ov" + coh2 + "%"}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "40px",
                      height: "20px",
                      background: Getcolor(
                        Indata?.["old_" + match_name]?.corners?.[
                          "under_" + halfs
                        ]?.[coh2],
                        Indata?.["old_" + match_name]?.corners?.match_count
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      border: getBorder_cc("under"),
                    }}
                  >
                    {GetPercentatge(
                      Indata?.["old_" + match_name]?.corners?.[
                        "under_" + halfs
                      ]?.[coh2],
                      Indata?.["old_" + match_name]?.corners?.match_count
                    ) ?? "- %"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    };

    const MLines = ({ Lines_ }) => {
      const [isdropdownClick, setisdropdownClick] = useState(false);

      const handleDropclick = (index) => {
        // if the row was already open, close it
        if (isdropdownClick === index) {
          setisdropdownClick(null);
        } else {
          // otherwise, open the clicked row
          setisdropdownClick(index);
        }
      };

      const Lines = removeDuplicatesByKey(Lines_, "date_unix");
      let t_d = {};
      if (Tab2 === "Win-Draw-Loose") {
        t_d = ["Win%", "Draw%", "Loss%", "Result"];
      } else if (Tab2 === "Goals") {
        t_d = ["Overer 2.5%", "Under 2.5%", "To Score%", "No Score%"];
      } else if (Tab2 === "Cards") {
        t_d = ["Over 4.5%", "Under 4.5%"];
      } else if (Tab2 === "Corners") {
        t_d = ["Over 8.5%", "Under 8.5%"];
      }

      let gh2 = "";

      let ch2 = "";

      let coh2 = "";
      if (half === "Full Time") {
        gh2 = "2.5";

        ch2 = "5.5";

        coh2 = "9.5";
      } else if (half === "First Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
      } else if (half === "Second Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
      }
      let items_ = [];

      return (
        <div>
          <Table
            className="outer-border-light"
            striped
            hover
            variant="light"
          >
            <thead>
              <tr>
                <th>Date</th>
                {small ? null : (
                  <>
                    <th></th>
                    <th>League</th>
                    <th></th>
                    <th></th>
                  </>
                )}
                <th className="homeOR">Home</th>
                <th className="statsOR">Stats</th>{" "}
                <th className="awayOR">Away</th>
                {small ? null : (
                  <>
                    <th></th>
                    <th></th>
                  </>
                )}
                {Tab2 === "Win-Draw-Lose" || Tab2 === "WDL-Cards" || Tab2 === "WDL-Corners" ? (
                  <>
                    {small ? null : (
                      <>
                        <th className="preds">H_Win%</th>{" "}
                        <th className="preds">Draw%</th>
                        <th className="preds">A_Win%</th>
                        <th></th>
                        <th></th>
                      </>
                    )}

                    <th className="preds">Result</th>
                    {small ? <th>Optns</th> : null}
                  </>
                ) : Tab2 === "Goals" ? (
                  <>
                    {small ? (
                      <th>Optns</th>
                    ) : (
                      <>
                        <th></th>
                        <th className="preds">{"Ov" + gh2 + "%"}</th>
                        <th className="preds">{"Un" + gh2 + "%"}</th>
                        <th></th>
                        <th className="preds">BTS_Y%</th>
                        <th className="preds">BTS_N%</th>
                      </>
                    )}
                  </>
                ) : Tab2 === "Cards" ? (
                  <>
                    {small ? (
                      <th>Optns</th>
                    ) : (
                      <>
                        <th></th>
                        <th></th>
                        <th className="preds">{"Ov" + ch2 + "%"}</th>

                        <th></th>
                        <th></th>
                        <th className="preds">{"Un" + ch2 + "%"}</th>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {small ? (
                      <th>Optns</th>
                    ) : (
                      <>
                        <th></th>
                        <th></th>
                        <th className="preds">{"Ov" + coh2 + "%"}</th>

                        <th></th>
                        <th></th>
                        <th className="preds">{"Un" + coh2 + "%"}</th>
                      </>
                    )}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Lines.sort((a, b) => b.date_unix - a.date_unix).map(
                (item, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => handleDropclick(index)}>
                      <DisplayData Indata={item} />
                    </tr>
                    {isdropdownClick === index ? (
                      <tr colSpan="100%">
                        <td colSpan="100%">
                          <DisplayDrop Indata={item} />
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                )
              )}
            </tbody>
          </Table>
        </div>
      );
    };
    const Ob_Data_ = Object.keys(Data)[0];
    ////console.log(Data_);
    if (Tab2 === "Corners") {
      for (let gm = 0; gm < Data?.length; gm++) {
        const element = Data?.[gm];
        if (element?.["corner_fh_count"] === -1) {
          delete Data?.[gm]
        }
      }
    } else if (Tab2 === "Cards") {
      for (let gm = 0; gm < Data?.length; gm++) {
        const element = Data?.[gm];
        if (element?.["team_a_cards_num"] === -1) {
          delete Data?.[gm]
        }
      }
      
    }

    return (
      <div>
        <Modal.Body>
          <MLines Lines_={Data} />
        </Modal.Body>
      </div>
    );
  }

  const HandleSeeMore = (val) => {
    //let currValue = monthCount;

    if (val === "seemore" && monthCount > 9) {
      setMonthCount(monthCount + 5);
    }
    if (val === "seeless" && monthCount > 14) {
      setMonthCount(monthCount - 5);
    }
  };
  function SeeMore() {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <Container className="justify-center">
          <Nav style={{ display: "flex", justifyContent: "space-between" }}>
            <NavLink onClick={() => HandleSeeMore("seeless")}>
              {"< See Less"}
            </NavLink>
            <NavLink onClick={() => HandleSeeMore("seemore")}>
              {"See More >"}
            </NavLink>
          </Nav>
        </Container>
      </div>
    );
  }
  return (
    <div>
      <MultiLeague monthCount={monthCount} />
    </div>
  );
}
