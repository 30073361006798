import { Routes, Route, BrowserRouter } from "react-router-dom"; // Corrected import statement
import React, { useState } from "react";
/* Import your components correctly based on the file paths */
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import Pricing from "../components/Pricing";
import IndexPage from "../components/indexPage";
import ProfilePage from "../components/UserProfile";
import GetStaticCoLg from "../components/DataAdmin";
import GetTeamPage from "../components/sportComponents/TeamStats";
import LeagueStats from "../components/sportComponents/LeagueStats";
import MatchStats from "../components/sportComponents/MatchStats";
import DisplayRankings from "../components/sportComponents/StatisticsRanking";
import ContactUs from "../components/ContactUS";
import AboutUs from "../components/AboutUs";
import DisplayTickets from "../components/TicketComponents/ViewTickets";
import Payments from "../components/Payment";
import Welcome from "../components/Entrypage";
import HowItWorks from "../components/HowItworks";
import HowTo from "../components/Howto";
import TermsAndConditions from "../components/TermsAndConditions";
import PrivacyPolicy from "../components/PrivacyPolicy";
import VerifyEmail from "../components/VerifyEmail";
import PromptVerifyEmail from "../components/PromptEmailVerify";
import GetEmail from "../components/ResetPassEmail";
import ResetPassword from "../components/ResetPassMain";
import AdminLogin from "../components/AdminLogin";
import PaymentReturn from "../components/PaymentReturn";
import Predictions from "../components/TicketComponents/Predictions";
import ThankYou from "../components/ThankYou";
import ImageUpload from "../components/ImageComponents/UploadImages";
import MessageApp from "../components/MessagesCharts/Messages";
import PublicProfile from "../components/TipstarComponents/PublicProfile"

//import { GlobalUserContext } from "../components/contextRouters/UserInfoContext";

const Main = () => {
  return (
    <Routes>
      <Route exact path="/" element={<IndexPage />} />
      <Route exact path="/signin" element={<SignIn />} />

      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/dataAdmin" element={<GetStaticCoLg />} />
      <Route exact path="/admin" element={<AdminLogin />} />
      <Route exact path="/pricing" element={<IndexPage />} />
      <Route exact path="/userprofile" element={<ProfilePage />} />
      <Route exact path="/team/*" element={<GetTeamPage />} />
      <Route exact path="/league/*" element={<LeagueStats />} />
      <Route exact path="/match/*" element={<MatchStats />} />
      <Route exact path="/Statistics/*" element={<DisplayRankings />} />
      <Route exact path="/About us/*" element={<AboutUs />} />
      <Route exact path="/Contact us/*" element={<ContactUs />} />
      <Route exact path="/payments/*" element={<Payments />} />
      <Route exact path="/welcome/*" element={<Welcome />} />
      <Route exact path="/HowItWorks/*" element={<HowItWorks />} />
      <Route exact path="/howto/*" element={<HowTo />} />
      <Route exact path="/TAC" element={<TermsAndConditions />} />
      <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route exact path="/verifyEmail" element={<VerifyEmail />} />
      <Route exact path="/promptverifyemail" element={<PromptVerifyEmail />} />
      <Route exact path="/getemail" element={<GetEmail />} />
      <Route exact path="/passwordreset" element={<ResetPassword />} />
      <Route exact path="/paymentreturn" element={<PaymentReturn />} />
      <Route exact path="/thank_you" element={<ThankYou />} />
      <Route exact path="/ShowTickets" element={<DisplayTickets />} />
      <Route exact path="/predictions" element={<Predictions />} />
      <Route exact path="/editImages" element={<ImageUpload />} />
      <Route exact path="/myMessages" element={<MessageApp />} />
      <Route exact path="/publicProfile" element={<PublicProfile />} />

    </Routes>
  );
};

export default Main;
