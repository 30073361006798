import React, { useEffect, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
// Info tag (your tooltip)
export function InfoTag({ tooltipContent,scale,width }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  },[])
  if(!scale){
    scale = 50
  }
  if(!width){
    width = "500px"
  }
  ////console.log(tooltipContent)
  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchStart={() => setShowTooltip(true)}
      onTouchEnd={() => setShowTooltip(false)}
      style={{ position: "relative" }}
    >
      <span>
        <IoIosInformationCircleOutline />
      </span>{" "}
      {/* Your info icon here */}
      {showTooltip && (
        <div
          className=""
          style={{
            position: "absolute",
            top: "100%",
            left: "50%",
            width: width,
            transform: `translate(-${scale}%, 0)`,
            zIndex: 9999999,
            backgroundColor: "gray",
            backgroundColor: "rgba(255, 255, 255, 1)",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
}
