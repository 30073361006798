import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Form } from "react-bootstrap";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { useState, useEffect } from "react";
import { NavDropdown, Container } from "react-bootstrap";
import { debounce, getToggleButtonGroupUtilityClass } from "@mui/material";
import Switch from "@mui/material/Switch";
import { IoScaleOutline, IoTicketOutline } from "react-icons/io5";
import "../css/ThemeCss.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import { CiSaveDown2 } from "react-icons/ci";
import { AiOutlineClear } from "react-icons/ai";
import { Errorlog, convertUnixToTime } from "./Utils";
import { MdDeleteOutline, MdSportsSoccer } from "react-icons/md";
import League_images from "../images/league_images.json";
import Team_images from "../images/team_images.json";
import { FaEdit } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { stringify } from "jsonstream-next";
import { InfoTag } from "./DisplayInfo";
import CopyButton from "./CopyLinkButton";
import Team_image from "../images/team_images.json";
import { CallError } from "./Utils";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import {
  MDBInput,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBSelect,
  MDBTable,
  MDBTableHead,
  useClipboard,
} from "mdb-react-ui-kit";
import { FcInfo } from "react-icons/fc";
import { VscAccount, VscSave } from "react-icons/vsc";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTableBody,
} from "mdb-react-ui-kit";
import Spinner from "./Spinner";
import SportIcon from "./ChartComponents/SportIcons";
import { truncate } from "fs";

const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

export function TicketModal({
  topRightModal,
  isEdditerror_num,
  toggleOpen,
  setTopRightModal,
}) {
  let reptext = "";
  let reptext2 = false;
  ////console.log(isEdditerror_num, "dfdr");
  if (typeof isEdditerror_num === "string") {
    reptext =
      "you have at least one of your matches as custom options, therefore we cannot calculate the total odds.";
    reptext2 =
      "you can add booking codes to make sure your viewers can get the correct odds from the bookie";
  } else {
    reptext = "There is probably an error for some of the ticket matches";
  }

  return (
    <>
      <MDBModal
        animationDirection="right"
        open={topRightModal}
        tabIndex="-1"
        onClose={() => setTopRightModal(false)}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader className="bg-info text-white">
              <MDBModalTitle>Ticket Error</MDBModalTitle>
              <MDBBtn
                color="none"
                className="btn-close btn-close-white"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-3 text-center">
                  <FcInfo size={50} />
                </div>

                <div className="col-9">
                  <p>{reptext}</p>
                  {reptext2 && <p>{reptext2}</p>}
                  <p>Check line {isEdditerror_num}</p>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn outline color="info" onClick={toggleOpen}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default function ShowTicket(props) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const default_form = {
    isCompetition: "",
    isPrivate: "",
    isFollowers: "",
    bookie_name: "SportyBet",
    bcode: "",
    country:"",
    ShareURL: "",
    ticketOdd: "",
    ticket_name: "",
    ticketStake: 100,
    ticketPotWin: "",
  };
  const [formData, setFormData] = useState(default_form);
  const [useMatches, setUseMatches] = useState([]);

  const [activeTicket, setactiveTicket] = useState();
  const [issaved, setissaved] = useState(false);
  const [useCompetition, setUseCompetition] = useState(false);
  const [usePrivate, setUsePrivate] = useState(false);
  const [useFollowers, setUsefollowers] = useState(false);
  const [isEddit, setIsEddit] = useState(false);
  const [isEdditerror_num, setIsEdditerror_num] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isTicketting, setIsTicketting] = useState(false);
  const [isoddpossible, setIsOddPossible] = useState(true);
  const [useodd, setUseOdd] = useState({ odd: "0.00" });
  const navigation = useNavigate();
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isnoticket, setisnoticket] = useState(false);
  const [canEditAll, setCanEditAll] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [newsharedTicket, setNewSharedTicket] = useState();
  const [isclickedShare, setisclickedShare] = useState();
  const [isclickedPreShare, setisclickedPreShare] = useState();
  const [currentMatches, setcurrentMatches] = useState({});
  const [matchoptions, SetMathOptions] = useState();
  const [ticketOdds, SetTicketOdds] = useState();
  const [potentialWin, SetPotentialWin] = useState();
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 1000);
  };

  const [topRightModal, setTopRightModal] = useState(false);

  const setIsEdditerror = (num) => {
    if (num) {
      setTopRightModal(!topRightModal), setIsEdditerror_num(num);
    }
  };
  function roundToTwoDecimalPlaces(num) {
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const prevglobal = { ...globalUser };
    //console.log(globalUser);

    let statuses = prevglobal?.user?.Tickets?.state;
    let activetic = "";
    if (statuses) {
      for (let index = 0; index < Object.keys(statuses).length; index++) {
        const element = Object.keys(statuses)[index];
        if (statuses?.[element]) {
          setactiveTicket(element);
          activetic = element;
        }
      }
    }

    const matches = globalUser?.user?.Tickets?.[activetic]?.matches;

    let lines = {};
    let totalodds = 0.0;

    for (let index = 0; index < matches?.length; index++) {
      const element = matches[index];
      lines["" + index] = { options: element?.options };
      const odds = parseFloat(element?.options?.odds) || 0;
      if (totalodds === 0.0) {
        totalodds = 1.0;
      }
      totalodds = (totalodds * odds).toFixed(2);
    }

    console.log("from use effect", globalUser);

    SetTicketOdds(totalodds);
    let prevtcData = formData;
    prevtcData["ticketOdd"] = totalodds;
    prevtcData["ticketStake"] =
      globalUser?.user?.Tickets?.[activetic]?.booking_Info?.ticketStake ??
      prevtcData["ticketStake"];
    prevtcData["ticketPotWin"] = roundToTwoDecimalPlaces(
      prevtcData["ticketStake"] * totalodds
    );
    SetPotentialWin(
      roundToTwoDecimalPlaces(prevtcData["ticketStake"] * totalodds)
    );
    prevtcData["bcode"] =
      globalUser?.user?.Tickets?.[activetic]?.booking_Info?.bcode;
    prevtcData["bookie_name"] =
      globalUser?.user?.Tickets?.[activetic]?.booking_Info?.bookie_name ??
      "SportyBet";
      prevtcData["country"] =
      globalUser?.user?.Tickets?.[activetic]?.booking_Info?.country ??
      "ng";
    prevtcData["ShareURL"] =
      globalUser?.user?.Tickets?.[activetic]?.booking_Info?.ShareURL;

    /* prevtcData["ticketStake"] = prevtcData["ticketStake"]
    prevtcData["ticketPotWin"] = prevtcData["ticketPotWin"] */

    setFormData(prevtcData);
    SetMathOptions(() => lines);
    setUseMatches(() => matches);
  }, [isoddpossible, globalUser]);

  useEffect(() => {
    if (issaved) {
      const timer = setTimeout(() => {
        setissaved(false);
        setIsOddPossible(false);
      }, 2000); // Change this value to the amount of time you want the message to display for in milliseconds.

      return () => clearTimeout(timer);
    }
  }, [issaved]);

  ////console.log(globalUser?.user?.Tickets?.[activeTicket]?.matches,activeTicket,useMatches);

  useEffect(() => {
    let matches = globalUser?.user?.Tickets?.[activeTicket]?.matches;
    const prevglobal = { ...globalUser };
    //console.log("running update ticket", matches);
    const updateMatches = async () => {
      for (let index = 0; index < matches?.length; index++) {
        const element = matches[index];
        if (element["options"]["status"] !== "Ended") {
          let matchId = element?.match?.id;
          if (matchId === "") {
            matchId = element["options"]["bookie_info"]["eventId"];
          }
          let time_ = element?.match?.date_unix;
          try {
            const res = await axios.post(
              `${API_URL}/externalOddsStatus`,
              JSON.stringify({
                match: matchId,
                time: time_,
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //console.log(res);

            const singleData = JSON.parse(res?.data?.data);
            if (res?.data?.status === "success") {
              //console.log(singleData);
              matches[index]["options"]["status"] = singleData?.status;
              matches[index]["options"]["score"] = singleData?.score;
              matches[index]["options"]["elpaseTime"] = singleData?.elpaseTime;
            }
          } catch (error) {
            Errorlog(
              error,
              "error loading statistics",
              "from Show ticket Games"
            );
          }
        }

        prevglobal["user"]["Tickets"][activeTicket]["booking_Info"][
          "ticketStake"
        ] = formData?.ticketStake;
        prevglobal["user"]["Tickets"][activeTicket]["booking_Info"][
          "ticketPotWin"
        ] = formData?.ticketPotWin;
        prevglobal["user"]["Tickets"][activeTicket]["matches"] = matches;
        //console.log("updated ticket");
      }
      setUseMatches(matches);
      updateGlobalUser(prevglobal);
      let ckUser = UpdateLocal({
        action: "save",
        data: prevglobal,
      });
    };
    updateMatches();
  }, [activeTicket]);

  if (!globalUser) {
    return null;
  }
  const GameLine = ({
    data,

    handleDeleteMatches,

    matchoptions,
  }) => {
    const [matchoptions2, SetMathOptions2] = useState(matchoptions);
    ////console.log(data)

    const showOptions = (match_id) => {
      navigation("/match/" + match_id, {
        state: { match_id: match_id, tab: "MARKETS" },
      });
    };

    const InsertMatches = ({
      data,
      index,
      handleDeleteMatches,
      matchoptions,
    }) => {
      let homeTeam = data && data?.["home_data"];
      let awayTeam = data && data?.["away_data"];
      if (!homeTeam || !awayTeam) {
        homeTeam = {
          cleanName: data?.["home_name"],
          id: data?.["homeID"],
          image: Team_images?.[String(data?.["homeID"])]?.image,
        };
        awayTeam = {
          cleanName: data?.["away_name"],
          id: data?.["awayID"],
          image: Team_images?.[String(data?.["awayID"])]?.image,
        };

        data = {
          home_data: homeTeam,
          away_data: awayTeam,
          match: data,
          match_id: data?.id,
        };
      }
      ////console.log(data)
      /* const homeGoals = JSON?.parse?.(
        data && ata?.["match"] && data?.["match"]?.["homeGoals"]
      );
      const awayGoals = JSON?.parse?.(
        data && data["match"] && data["match"]?.["awayGoals"]
      ); */

      ////console.log(index, isEddit);
      let isNewtral = "false";
      if (
        data["match"]?.["h_win"] === "0%" &&
        data["match"]?.["draw"] === "0%" &&
        data["match"]?.["a_win"] === "0%"
      ) {
        isNewtral = "true";
      }
      data["match"]["h_win"];

      const matchStatus = data && data["match"] && data["match"]["status"];
      const match_league =
        data && data["match"] && data["match"]?.["competition_id"];
      ////console.log(homeTeam);
      const homeWDL =
        data && data["match"] && data["match"]?.["teama_form_num"];
      const awayWDL =
        data && data["match"] && data["match"]?.["teamb_form_num"];
      const season_ = data && data["match"] && data["match"]?.["season"];
      const handleteamclick = ({ val, team_, league, edit }) => {
        if (edit) {
          const team_info = {
            team: val,
            league: league,
          };
          /* window.open("/team/" + team_ + "?team=" + val + "&league=" + league + "&data=" + useFixture?.league_nav); */
          navigation("/team/" + team_, { state: team_info });
        }
      };

      const handlematchlick = (match_id, team1, team2, seas, edit) => {
        // /////console.log(team1, team2, match_id);
        /* window.open("/match/" + match_id+ "?match_id=" + match_id + "&team1=" + team1 + "&team2=" + team2 + "&seas=" + seas); */
        if (edit) {
          navigation("/match/" + match_id, {
            state: {
              match_id: match_id,
              team1: team1,
              team2: team2,
              seas: seas,
            },
          });
        }
      };
      ////console.log(issubscribed,isLogedin)
      return (
        <MDBTableBody>
          {isSmallScreen ? (
            <>
              <tr>
                <td className="formTable">
                  <div>
                    <div className="center_div">
                      <SportIcon
                        sport={data?.options?.bookie_info?.sport?.name}
                      />{" "}
                      &nbsp;
                      {convertUnixToTime(
                        data && data["match"] && data["match"]["date_unix"],
                        "short"
                      )}
                    </div>
                    {data?.options?.elpaseTime !== "" ? (
                      <>
                        <div
                          className="center_div center_sides nobgbutton buttonBorders-blue forcered"
                          style={{ backgroundColor: "rgb(238, 220, 160)" }}
                        >
                          &nbsp;
                          <div>
                            {data?.options?.status}
                            {"' "}
                          </div>{" "}
                          <div>
                            {data?.options?.status}
                            {"'"}
                          </div>
                          &nbsp;
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="center_div">
                          {convertUnixToTime(
                            data && data["match"] && data["match"]["date_unix"],
                            "time"
                          )}
                        </div>
                      </>
                    )}

                    <div className="center_div">
                      {League_images?.[
                        data && data["match"] && data["match"]["competition_id"]
                      ]?.["country"] ??
                        data?.options?.bookie_info?.sport?.category?.name}{" "}
                    </div>
                    <div className="center_div">
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          wordWrap: "break-word",
                          maxWidth: "90px",
                          overflowWrap: "break-word",
                        }}
                      >
                        {League_images?.[
                          data &&
                            data["match"] &&
                            data["match"]?.["competition_id"]
                        ]?.["name"] ??
                          data?.options?.bookie_info?.sport?.category
                            ?.tournament?.name}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="formTable">
                  <div
                    className="center_div center_straight"
                    onClick={() =>
                      handlematchlick(
                        data?.match_id,
                        homeTeam?.["id"],
                        awayTeam?.["id"],
                        season_ && season_,
                        data?.options?.canEdit
                      )
                    }
                  >
                    <div>
                      <div
                        className="homeTable link forceblack"
                        onClick={() =>
                          handleteamclick({
                            val: homeTeam?.["id"],
                            team_: homeTeam?.["cleanName"],
                            league: match_league && match_league,
                            edit: data?.options?.canEdit,
                          })
                        }
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          wordWrap: "break-word",
                          width: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {homeTeam["cleanName"]}
                      </div>
                    </div>
                    {data?.options?.score !== "" ? (
                      <>
                        <div className=" allpad-2 inactiveButtonGreen">
                          {data?.options?.score}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" allpad">- VS -</div>
                      </>
                    )}

                    <div>
                      <div
                        className="awayTable link forceblack"
                        onClick={() =>
                          handleteamclick({
                            val: awayTeam["id"],
                            team_: awayTeam["cleanName"],
                            league: match_league,
                            edit: data?.options?.canEdit,
                          })
                        }
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          wordWrap: "break-word",
                          width: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {awayTeam["cleanName"]}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="formTable link forceblack">
                  <div
                    className=" inactiveButtonGreen center_div center_straight "
                    onClick={() =>
                      data?.options?.canEdit && showOptions(data?.match_id)
                    }
                  >
                    <div>{data?.options?.descptn} </div>{" "}
                    {data?.options?.status === "Ended" ? (
                      <>
                        <div>
                          {" "}
                          <div className="center_div center_sides">
                            <div
                              className="nobgbutton forcewhite"
                              style={{ backgroundColor: "red" }}
                            >
                              {" "}
                              &nbsp; Fin' &nbsp;
                            </div>{" "}
                            &nbsp; <div>{data?.options?.odds}</div>
                          </div>
                        </div>
                      </>
                    ) : data?.options?.status === "Not start" ? (
                      <>
                        <div>{data?.options?.odds}</div>
                      </>
                    ) : (
                      <>
                        <div>
                          {" "}
                          <div className="center_div center_sides pad">
                            <div className="Odds_button timerfontwhite">
                              {" "}
                              &nbsp; Live' &nbsp;
                            </div>{" "}
                            &nbsp; <div>{data?.options?.odds}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td className="formTable">
                  <div
                    className="link forceblack"
                    onClick={() => handleDeleteMatches(index)}
                  >
                    <MdDeleteOutline size={25} color="red" />
                  </div>
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td className="formTable">
                  <div className="center_div">
                    <SportIcon
                      sport={data?.options?.bookie_info?.sport?.name}
                    />{" "}
                    &nbsp;
                    {convertUnixToTime(
                      data && data["match"] && data["match"]["date_unix"],
                      "short"
                    )}
                  </div>
                </td>
                <td className="formTable">
                  {data?.options?.elpaseTime !== "" ? (
                    <>
                      <div
                        className="center_div center_sides nobgbutton buttonBorders-blue forcered"
                        style={{ backgroundColor: "rgb(238, 220, 160)" }}
                      >
                        &nbsp;
                        <div>{data?.options?.status}</div> -{" "}
                        <div>
                          {data?.options?.elpaseTime}
                          {"'"}
                        </div>
                        &nbsp;
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="center_div">
                        {convertUnixToTime(
                          data && data["match"] && data["match"]["date_unix"],
                          "time"
                        )}
                      </div>
                    </>
                  )}
                </td>
                <td className="formTable">
                  <div className="center_div">
                    {League_images?.[
                      data && data["match"] && data["match"]?.["competition_id"]
                    ]?.["country"] ??
                      data?.options?.bookie_info?.sport?.category?.name}{" "}
                  </div>
                </td>
                <td className="formTable">
                  <div className="center_div">
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        wordWrap: "break-word",
                        maxWidth: "90px",
                        overflowWrap: "break-word",
                      }}
                    >
                      {League_images?.[
                        data &&
                          data["match"] &&
                          data["match"]?.["competition_id"]
                      ]?.["name"] ??
                        data?.options?.bookie_info?.sport?.category?.tournament
                          ?.name}
                    </div>
                  </div>
                </td>
                <td className="formTable">
                  <div className="center_div center_sides">
                    <div>
                      <div
                        className="homeTable link forceblack"
                        onClick={() =>
                          handleteamclick({
                            val: homeTeam["id"],
                            team_: homeTeam["cleanName"],
                            league: match_league,
                            edit: data?.options?.canEdit,
                          })
                        }
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          wordWrap: "break-word",
                          width: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {homeTeam["cleanName"]}
                      </div>
                    </div>
                    <div
                      className=" allpad link forceblack"
                      onClick={() =>
                        handlematchlick(
                          data?.match_id,
                          homeTeam["id"],
                          awayTeam["id"],
                          season_ && season_,
                          data?.options?.canEdit
                        )
                      }
                    >
                      {data?.options?.score !== "" ? (
                        <>
                          <div className=" allpad-2 inactiveButtonGreen">
                            {data?.options?.score}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className=" allpad">- VS -</div>
                        </>
                      )}
                    </div>
                    <div>
                      <div
                        className="awayTable link forceblack"
                        onClick={() =>
                          handleteamclick({
                            val: awayTeam["id"],
                            team_: awayTeam["cleanName"],
                            league: match_league,
                            edit: data?.options?.canEdit,
                          })
                        }
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          wordWrap: "break-word",
                          width: "100%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {awayTeam["cleanName"]}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="formTable link forceblack ">
                  <div
                    className=" inactiveButtonGreen center_div center_straight "
                    onClick={() =>
                      data?.options?.canEdit && showOptions(data?.match_id)
                    }
                  >
                    <div>{data?.options?.descptn} </div>{" "}
                    {data?.options?.status === "Ended" ? (
                      <>
                        <div>
                          {" "}
                          <div className="center_div center_sides">
                            <div
                              className="nobgbutton forcewhite"
                              style={{ backgroundColor: "red" }}
                            >
                              {" "}
                              &nbsp; Fin' &nbsp;
                            </div>{" "}
                            &nbsp; <div>{data?.options?.odds}</div>
                          </div>
                        </div>
                      </>
                    ) : data?.options?.status === "Not start" ? (
                      <>
                        <div>{data?.options?.odds}</div>
                      </>
                    ) : (
                      <>
                        <div>
                          {" "}
                          <div className="center_div center_sides">
                            <div className="Odds_button timerfontwhite">
                              {" "}
                              &nbsp; Live' &nbsp;
                            </div>{" "}
                            &nbsp; <div>{data?.options?.odds}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td className="formTable">
                  <div
                    className="link forceblack"
                    onClick={() => handleDeleteMatches(index)}
                  >
                    <MdDeleteOutline size={25} color="red" />
                  </div>
                </td>
              </tr>
            </>
          )}
        </MDBTableBody>
      );
    };

    return (
      <div className="usemaxSpace allpad" style={{ width: "100%" }}>
        {isSmallScreen ? (
          <>
            <div className="usemaxSpace">
              <div>
                <MDBTable hover>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">
                        <div className="center_div"> Info </div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Match</div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Optns</div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Edit</div>
                      </th>
                    </tr>
                  </MDBTableHead>
                  {data.map((item, index) => {
                    return (
                      <InsertMatches
                        key={index}
                        data={item}
                        index={index}
                        handleDeleteMatches={handleDeleteMatches}
                        matchoptions={matchoptions}
                      />
                    );
                  })}
                </MDBTable>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="usemaxSpace">
              <div>
                <MDBTable hover>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">
                        <div className="center_div"> Date </div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Time </div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Country</div>
                      </th>
                      <th scope="col">
                        <div className="center_div">League</div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Match</div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Option</div>
                      </th>
                      <th scope="col">
                        <div className="center_div">Edit</div>
                      </th>
                    </tr>
                  </MDBTableHead>
                  {data.map((item, index) => {
                    return (
                      <InsertMatches
                        key={index}
                        data={item}
                        index={index}
                        handleDeleteMatches={handleDeleteMatches}
                        matchoptions={matchoptions}
                      />
                    );
                  })}
                </MDBTable>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleDeleteMatches = (index) => {
    const prevMatch = [...globalUser?.user?.Tickets?.[activeTicket]?.matches];
    const prevglobal = { ...globalUser };
    prevMatch.splice(index, 1);
    prevglobal["user"]["Tickets"][activeTicket]["matches"] = prevMatch;
    prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]["bcode"] = "";
    prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]["ShareURL"] =
      "";
    //prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]["ticketStake"] = 100;
    //prevglobal["user"]["Tickets"][activeTicket]["booking_Info"]["ticketPetWin"] ="";

    setUseMatches(prevMatch);
    updateGlobalUser(prevglobal);
    let ckUser = UpdateLocal({
      action: "save",
      data: prevglobal,
    });
  };
  console.log(globalUser);
  const handleSave = async () => {
    try {
      const res = await axios.post(`${API_URL}/save_ticket`, {
        email: globalUser?.user?.email,
        ticketNumber: activeTicket,
        matches: globalUser?.user?.Tickets?.[activeTicket]?.matches,
        booking_info: globalUser?.user?.Tickets?.[activeTicket]?.booking_Info,
      });

      if (res && res?.data?.status === "success") {
        setissaved((prev) => !prev);

        const prevMatch = [
          ...globalUser?.user?.Tickets?.[activeTicket]?.matches,
        ];
        const prevglobal = { ...globalUser };

        setUseMatches(() => prevMatch);
        updateGlobalUser(() => prevglobal);
        let ckUser = UpdateLocal({
          action: "save",
          data: prevglobal,
        });
      }

      ////console.log(globalUser);
    } catch (error) {
      console.error(error);
      // handle error appropriately, either set error state or show notification, etc.
    }
    ////console.log(globalUser);
  };
  const clearTicket = async () => {
    try {
      const res = await axios.post(`${API_URL}/clear_ticket`, {
        email: globalUser?.user?.email,
        ticketNumber: activeTicket,
        type: "clear",
      });

      if (res && res?.data?.status === "success") {
        const prevMatch = [
          ...globalUser?.user?.Tickets?.[activeTicket]?.matches,
        ];
        const prevglobal = { ...globalUser };
        prevglobal["user"]["Tickets"][activeTicket]["matches"] = [];
        setUseMatches(() => prevMatch);
        updateGlobalUser(() => prevglobal);
        let ckUser = UpdateLocal({
          action: "save",
          data: prevglobal,
        });
      }

      ////console.log(globalUser);
    } catch (error) {
      console.error(error);
      // handle error appropriately, either set error state or show notification, etc.
    }
    ////console.log(globalUser);
  };
  //console.log(formData);
  const Handleshare = async (old_form, type) => {
    const currTime = Math.floor(new Date().getTime() / 1000);
    let matches = globalUser?.user?.Tickets?.[activeTicket]?.matches;
    let started = false;
    for (let mat = 0; mat < matches.length; mat++) {
      const element = matches[mat];
      if (element?.["options"]["status"] === "Ended") {
        setIsStarted(() => "" + (mat + 1));
        started = true;
        break;
      }
    }
    if (started) {
      ("");
    } else {
      const need_data = {
        matches: globalUser?.user?.Tickets?.[activeTicket]?.matches,
        firstname: globalUser?.user?.firstname,
        user_email: globalUser?.user?.email,
        share_time: Math.floor(new Date().getTime() / 1000),
        iscompetition: old_form?.isCompetition,
        isprivate: old_form?.isPrivate,
        isfollowers: old_form?.isFollowers,
        bookie_name: old_form?.bookie_name ?? "SportyBet",
        country: old_form?.country ?? "ng",
        ShareURL: old_form?.ShareURL,
        bcode: old_form?.bcode ?? "",
        bcode_url: old_form?.ShareURL,
        ticket_name: old_form?.ticket_name,
        ticketOdd: old_form?.ticketOdd,
        ticketStake: old_form?.ticketStake,
        ticketPotWin: old_form?.ticketPotWin,
      };
      ////console.log(need_data, type, "here");
      try {
        let res = "";
        if (type === "public") {
          res = await axios
            .post(`${API_URL}/share_public`, JSON.stringify(need_data), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {
              ////console.log(error);
            });
        } else {
          ("");
        }
        ////console.log(res)
        const ticketDict = res?.data;

        if (ticketDict?.status === "success") {
          ////console.log(res);

          const data = ticketDict?.data;

          setNewSharedTicket(data?.ticketID);
          setIsTicketting(false);
          return data?.ticketID;
        } else {
          throw new Error("Unsuccessful request");
        }
      } catch {
        //Errorlog("error","error league nav","from team stats")
      }
    }
  };

  const handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event?.target?.checked
        : event?.target?.value;
    ////console.log(value);
    const old_form = { ...formData };
    old_form[event?.target?.name] = value;
    /* setFormData(old_form);
    SetPotentialWin(old_form?.ticketOdd * old_form?.ticketStake) */

    ////console.log(old_form);
    if (old_form?.["isCompetition"]) {
      setUseCompetition(() => true);
    } else {
      setUseCompetition(() => false);
    }
    if (old_form?.["isFollowers"]) {
      setUsefollowers(() => true);
    } else {
      setUsefollowers(() => false);
    }
    if (old_form?.["isPrivate"]) {
      setUsePrivate(() => true);
    } else {
      setUsePrivate(() => false);
    }
    ////console.log(old_form, event.target.label);
  };
  const handlelinechanges = (index, val) => {
    const prevm = { ...globalUser };
    prevm.user.Tickets[activeTicket].matches[index].options =
      val[index]?.options;
    updateGlobalUser(() => prevm);
    let ckUser = UpdateLocal({
      action: "save",
      data: prevm,
    });
  };

  ////console.log(matchoptions, globalUser);
  let matchoptionsTemp = {};
  if (matchoptions && Object.keys(matchoptions) == 0) {
    const matches = globalUser?.user?.Tickets?.[activeTicket]?.matches;

    let lines = {};
    for (let index = 0; index < matches?.length; index++) {
      const element = matches[index];
      lines["" + index] = { options: element?.options };
    }
    ////console.log(globalUser?.user?.Tickets?.[activeTicket]?.matches,activeTicket,useMatches,matches);
    ////console.log(matches, lines);
    matchoptionsTemp = lines;
  } else {
    matchoptionsTemp = matchoptions;
  }
  const ClickedShare = async (type) => {
    const old_form = { ...formData };
    ////console.log(isclickedPreShare);
    if (!isclickedPreShare) {
      ("");
    } else {
      if (
        globalUser?.user?.Tickets?.[activeTicket]?.matches === currentMatches
      ) {
        if (newsharedTicket) {
          setisclickedShare(() => true);
          setisclickedPreShare(() => false);
          setIsTicketting(false);
        } else {
          ////console.log("already shared");
          setisnoticket(() => true);
          setIsTicketting(false);
        }
      } else {
        const res = await Handleshare(old_form, type);
        setcurrentMatches(globalUser?.user?.Tickets?.[activeTicket]?.matches);
        ////console.log(res);
        setNewSharedTicket(res);
      }
    }
  };
  const GetBookingCode = async (data) => {
    try {
      let res = "";

      res = await axios
        .post(`${API_URL}/BookTicket`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {
          ////console.log(error);
        });

      const bookieData = res?.data;

      if (bookieData?.status === "success") {
        ////console.log(res);

        const form = { ...formData };
        //console.log(bookieData);
        if (bookieData?.bookings?.message == "Success") {
          //console.log("was saved");
          form["bcode"] = bookieData?.bookings?.data?.shareCode;
          form["ShareURL"] = bookieData?.bookings?.data?.shareURL;
          const prevm = { ...globalUser };
          prevm.user.Tickets[activeTicket].booking_Info = {
            bookie_name: formData?.bookie_name,
            country:formData?.country,
            bcode: bookieData?.bookings?.data?.shareCode,
            ShareURL: bookieData?.bookings?.data?.shareURL,
          };
          updateGlobalUser(() => prevm);
          let ckUser = UpdateLocal({
            action: "save",
            data: prevm,
          });
        }
        setFormData(form);
        setIsBooking(false);
        return "bookieData";
      } else {
        throw new Error("Unsuccessful request");
      }
    } catch {
      //Errorlog("error","error league nav","from team sta")
    }
  };
  const LoadBookingCode = async () => {
    let data = {
      bcode: formData?.bcode,
      bookie: formData?.bookie_name ?? "SportyBet",
      country: formData?.country ?? "ng",
    };
    try {
      let res = "";

      res = await axios
        .post(`${API_URL}/LoadBookedTicket`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {
          ////console.log(error);
        });

      const bookieData = res?.data;

      if (bookieData?.status === "success") {
        ////console.log(res);
        let shareURL = "";
        if (formData?.bookie_name === "SportyBet") {
          shareURL =
            "http://www.sportybet.com/"+formData?.country+"/?shareCode=" + formData?.bcode;
        }

        //console.log(bookieData);

        //console.log("was saved");
        const prevm = { ...globalUser };

        if (prevm.user.Tickets[activeTicket]["matches"].length === 0) {
          prevm.user.Tickets[activeTicket].booking_Info = {
            bookie_name: formData?.bookie_name,
            country: formData?.country,
            bcode: formData?.bcode,
            ShareURL: shareURL,
          };
          prevm.user.Tickets[activeTicket]["matches"] = bookieData?.data;
          for (let index = 0; index < bookieData?.data?.length; index++) {
            const element = bookieData?.data?.[index];

            if (!element?.options?.canEdit) {
              setCanEditAll(false);
            }
          }
        } else {
          for (let index = 0; index < bookieData?.data?.length; index++) {
            const element = bookieData?.data?.[index];
            prevm.user.Tickets[activeTicket]["matches"].push(element);
            if (!element?.options?.canEdit) {
              setCanEditAll(false);
            }
          }
        }
        setUseMatches(() => prevm.user.Tickets[activeTicket]["matches"]);
        updateGlobalUser(() => prevm);
        let ckUser = UpdateLocal({
          action: "save",
          data: prevm,
        });

        setIsBooking(false);
        return "bookieData";
      } else {
        throw new Error("Unsuccessful request");
      }
    } catch {
      //Errorlog("error","error league nav","from team sta")
    }
  };
  const loadBook = async (type_) => {
    let matches = {
      matches: globalUser?.user?.Tickets?.[activeTicket]?.matches,
      bookie: "SportyBet",
      country: formData?.country ?? "ng"
    };
    if (type_ === "book") {
      let bookieData = await GetBookingCode(matches);
    } else {
      let bookieData = await LoadBookingCode();
    }
  };

  return (
    <div
      className="mainFont forceblack margin-10down scrol_div no-sidebar noscrolbar"
      style={{ zIndex: "9999", height: "600px" }}
    >
      {isStarted && (
        <CallError
          header={"Match Not Available!!"}
          data1={"At least one of your match have Ended"}
          data2={`Check line ${isStarted}`}
          funcchange={setIsStarted}
          funcuser={isStarted}
        />
      )}
      {isnoticket && (
        <CallError
          header={"Not Shared!!"}
          data1={
            "There was an error in your ticket please modify your ticket and try again"
          }
          data2={`No ticket was shared, threfore no link generated`}
          funcchange={setisnoticket}
          funcuser={isnoticket}
        />
      )}
      {topRightModal && (
        <TicketModal
          topRightModal={topRightModal}
          toggleOpen={setIsEdditerror}
          setTopRightModal={setTopRightModal}
          isEdditerror_num={isEdditerror_num}
        />
      )}

      <div style={{ width: "100%" }}>
        <div className="center_div center_straight" style={{ width: "100%" }}>
          <div className="midfont inactiveButton margin10down">
            &nbsp;{" "}
            {globalUser?.user?.Tickets?.[activeTicket]?.name.toUpperCase()} GAME
            SLIP &nbsp;
          </div>
          {!canEditAll && (
            <div className="center_div redlabel">
              {" "}
              None Soccer sports are not modifyable.{" "}
            </div>
          )}
          {globalUser?.user?.Tickets?.[activeTicket]?.matches?.length > 0 ? (
            <>
              <div style={{ width: "100%" }} className="margin-30down">
                <GameLine
                  data={globalUser?.user?.Tickets?.[activeTicket]?.matches}
                  handleDeleteMatches={handleDeleteMatches}
                  isEddit={isEddit}
                  havndlelineSave={handlelinechanges}
                />
              </div>
            </>
          ) : (
            <div className="center_div center_straight margin-50down margin30down">
              <div className="center_div margin-20down margin10down midfont bold">
                {" "}
                Empty Slip
              </div>
              <div className=" ">
                You can load a booking/betslip code or add games from the
                fixtures/statistics page.{" "}
              </div>
            </div>
          )}

          <div
            className="left_div center_sides removespave-10top margin-10down "
            style={{ width: isSmallScreen ? "100%" : "70%" }}
          >
            <div
              className="center_div_distributed center_sides"
              style={{ width: "100%" }}
            >
              <div className="allpad link forceblack">
                <div className=" divorangeButton allpad">
                  {globalUser?.user?.Tickets?.[activeTicket]?.matches?.length >
                  0 ? (
                    <div
                      onClick={() => {
                        loadBook("book");
                        setIsBooking(true);
                      }}
                    >
                      {isBooking ? (
                        <>
                          {" "}
                          <Spinner /> Booking...{" "}
                        </>
                      ) : (
                        "Book"
                      )}
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        loadBook("load");
                        setIsBooking(true);
                      }}
                    >
                      {isBooking ? (
                        <>
                          {" "}
                          <Spinner /> Loading...{" "}
                        </>
                      ) : (
                        "Load"
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex center_sides usemaxSpace ">
                <form>
                  <MDBSelect
                    data={[
                      { text: "SportyBet", value: "SportyBet" },
                      { text: "", value: 2 },
                    ]}
                    type="text"
                    name="bookie_name"
                    maxLength="12"
                    label={isSmallScreen ? "bookie" : "bookie name"}
                    size="sm"
                    onChange={(e) => {
                      let prev = { ...formData };
                      prev["bookie_name"] = e.value;
                      setFormData(prev);
                    }}
                  />
                </form>{" "}
                &nbsp;&nbsp;
                <form>
                  <MDBInput
                    type="text"
                    size="sm"
                    maxLength="12"
                    label={isSmallScreen ? "code" : "booking code"}
                    name="bcode"
                    value={formData?.bcode}
                    onChange={(e) => {
                      //console.log(e);
                      let prev = { ...formData };
                      prev["bcode"] = e.target.value;
                      setFormData(prev);
                    }}
                  />
                </form>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className="flex center_sides usemaxSpace ">
                <form>
                  <MDBInput
                    type="text"
                    name="ticket_name"
                    label={isSmallScreen ? "Name" : "Ticket display name"}
                    size="sm"
                    maxLength="12"
                    value={formData?.ticket_name}
                    onChange={(e) => {
                      let prev = { ...formData };
                      prev["ticket_name"] = e.target.value;
                      setFormData(prev);
                    }}
                  />
                </form>
                <div className="center_div center_sides usemaxSpace">
                  <div
                    className="inactiveButton center_div center_sides allpad"
                    style={{}}
                  >
                    <div>Odds:</div> &nbsp;<div>{ticketOdds}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="center_div center_sides removespave-10top margin-10down "
            style={{ width: isSmallScreen ? "100%" : "70%" }}
          >
            <div className="center_div center_sides " style={{ width: "100%" }}>
            <form>
                  <MDBSelect
                    data={[
                      { text: "Nigeria", value: "ng" },
                      { text: "Ghana", value: "gh" },
                      { text: "Brazil", value: "int" },
                      { text: "Zambia", value: "zm" },
                      { text: "Tanzania", value: "tz" },
                    ]}
                    type="text"
                    name="country"
                    maxLength="12"
                    label={isSmallScreen ? "country" : "country"}
                    size="sm"
                    onChange={(e) => {
                      let prev = { ...formData };
                      prev["country"] = e.value;
                      setFormData(prev);
                    }}
                  />
                </form>{" "}
            </div>
            <div className="center_div center_sides " style={{ width: "100%" }}>
            
              <form className="center_div ">
                <div className="center_div margin20down">$ &nbsp;</div>
                <MDBInput
                  type="text"
                  name="ticketStake"
                  label={isSmallScreen ? "Stake" : "Stake"}
                  size="sm"
                  maxLength="12"
                  value={formData?.ticketStake}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prev) => {
                      let old = prev;
                      old.ticketStake = e.target.value;
                      old.ticketPotWin = roundToTwoDecimalPlaces(
                        e.target.value * old.ticketOdd
                      );
                      SetPotentialWin(
                        roundToTwoDecimalPlaces(e.target.value * old.ticketOdd)
                      );
                      return old;
                    });
                  }}
                />
              </form>
              <div className="center_div center_sides usemaxSpace">
                <div
                  className="inactiveButton center_div center_sides allpad"
                  style={{}}
                >
                  
                  <div>{isSmallScreen ? "Pot Win :":"Potential Win :"}</div> &nbsp;
                  <div className="bold">${potentialWin}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {useCompetition || usePrivate || useFollowers ? (
              <div style={{ color: "red" }}>
                Options are not available at this time !! You can only share
                publicly. Please uncheck all options.
              </div>
            ) : null}
          </div>
          <div
            className="center_div_distributed center_sides"
            style={{ width: isSmallScreen ? "100%" : "50%" }}
          >
            <Form
              className="center_div_distributed center_sides"
              style={{ width: "100%" }}
            >
              <div
                className="center_div_distributed center_sides"
                style={{ width: "100%" }}
              >
                <div>
                  <Form.Group
                    className="mb-3 align-items-center"
                    id="isCompetition"
                  >
                    <Form.Check
                      type="checkbox"
                      label={
                        <div className=" center_div center_sides">
                          <div className=" center_div center_sides">
                            <div>Share to Competiion </div>&nbsp;
                            <div>
                              <InfoTag
                                tooltipContent={
                                  "You can share this ticket to any open competitions. Sharing to advanced competitions is by qualification only. Check Competitions Info to learn more"
                                }
                                scale={isSmallScreen ? 35 : 50}
                                width={"400px"}
                              />
                            </div>
                          </div>
                        </div>
                      }
                      name="isCompetition"
                      onChange={handleChange}
                      checked={formData.isCompetition === true}
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group
                    className="mb-3 align-items-center"
                    id="isPrivate"
                  >
                    <Form.Check
                      type="checkbox"
                      label={
                        <span style={{ verticalAlign: "middle" }}>
                          <div className=" center_div center_sides">
                            <div>Private </div>&nbsp;
                            <div>
                              <InfoTag
                                tooltipContent={
                                  "If you mark a ticket as private, you can generate a link but only you and people that subscribe to you can view the ticket"
                                }
                                scale={isSmallScreen ? 60 : 50}
                                width={"400px"}
                              />
                            </div>
                          </div>
                        </span>
                      }
                      name="isPrivate"
                      onChange={handleChange}
                      checked={formData.isPrivate === true}
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group
                    className="mb-3 align-items-center"
                    id="isFollowers"
                  >
                    <Form.Check
                      type="checkbox"
                      label={
                        <span style={{ verticalAlign: "middle" }}>
                          <div className=" center_div center_sides">
                            <div>Followers </div> &nbsp;
                            <div>
                              <InfoTag
                                tooltipContent={
                                  "If you mark a ticket as Followers, you can generate a link but only you and people that follow to you can view the ticket"
                                }
                                scale={isSmallScreen ? 95 : 50}
                                width={"400px"}
                              />
                            </div>
                          </div>
                        </span>
                      }
                      name="isFollowers"
                      onChange={handleChange}
                      checked={formData.isFollowers === true}
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
          <div
            className="center_div_distributed center_sides"
            style={{ width: isSmallScreen ? "100%" : "60%" }}
          >
            <div className="center_div_distributed center_sides ">
              <div className="allpad">
                <MDBPopconfirm
                  placement="top"
                  size="sm"
                  btnClassName=" divblueButton forceblack"
                  cancelBtnText={
                    <div style={{ textTransform: "none" }}>No</div>
                  }
                  confirmBtnText={
                    <div style={{ textTransform: "none" }}>Yes</div>
                  }
                  btnChildren={
                    <div className="center_div center_sides">
                      <div style={{ textTransform: "none" }}>Clear</div> &nbsp;{" "}
                      <div>
                        <AiOutlineClear />
                      </div>
                      &nbsp;{" "}
                    </div>
                  }
                  onConfirm={() => clearTicket()}
                >
                  <MDBPopconfirmMessage>
                    Clear entire ticket?
                  </MDBPopconfirmMessage>
                </MDBPopconfirm>
              </div>
              <InfoTag
                tooltipContent={"clear entire ticket"}
                scale={isSmallScreen ? 30 : 50}
                width={"200px"}
              />
            </div>

            <div className=" center_div center_sides ">
              <div className="divorangeButton allpad">
                &nbsp;
                <div className="link forceblack " onClick={() => handleSave()}>
                  Save
                </div>
                &nbsp;{" "}
                <div>
                  <CiSaveDown2 />
                </div>
                &nbsp;{" "}
              </div>{" "}
              &nbsp;{" "}
              {issaved && (
                <div className=" center_div center_sides">
                  <div style={{ color: "green" }}> ticket saved </div>{" "}
                  <div>
                    <FaCheck size={25} color="green" />
                  </div>{" "}
                </div>
              )}
              <InfoTag
                tooltipContent={"Saves ticket to database"}
                scale={isSmallScreen ? 40 : 50}
                width={"200px"}
              />
            </div>

            <div className="center_div_distributed center_sides">
              <div className="divgreenButton link forceblack">
                &nbsp;
                <div className="marginright-10">
                  {useCompetition ? (
                    <NavDropdown
                      title={
                        <div
                          className="center_div_distributed center_sides"
                          onClick={() => {
                            Handleshare();
                          }}
                        >
                          &nbsp;
                          <div>Choose Competition</div>
                          &nbsp;
                          <div>
                            <FaExternalLinkAlt />
                          </div>
                          &nbsp;{" "}
                        </div>
                      }
                      className="align-items-center navbar_some"
                      id="dropdown-basic-button"
                      drop="right"
                    >
                      <NavDropdown.Item onClick={() => ""}>
                        <div className="center_div center_sides">
                          <div>2 Odds Competition</div>&nbsp;
                          <div>
                            <InfoTag
                              tooltipContent={
                                "You can add any soccer game options not less than 2 odds on the ticket for this competition"
                              }
                            />
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => ""}>
                        <div className="center_div center_sides">
                          <div>Over and GG</div>&nbsp;
                          <div>
                            <InfoTag
                              tooltipContent={
                                "You can only add over goals and GG games on the ticket for this competition"
                              }
                            />
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => ""}>
                        <div className="center_div center_sides">
                          <div>Draw</div>&nbsp;
                          <div>
                            <InfoTag
                              tooltipContent={
                                "You can only add draw option on the tickets for this competition"
                              }
                            />
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => ""}>
                        <div className="center_div center_sides">
                          <div>Cards</div>&nbsp;
                          <div>
                            <InfoTag
                              tooltipContent={
                                "You can only add over/under yellow card options on the tickets for this competion"
                              }
                            />
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => ""}>
                        <div className="center_div center_sides">
                          <div>Corners</div> &nbsp;
                          <div>
                            <InfoTag
                              tooltipContent={
                                "You can only add over/under corners options on the tickets for this competition"
                              }
                            />
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <NavDropdown
                      title={
                        isTicketting ? (
                          <>
                            <div>
                              <Spinner />
                              <div>Generating Link ...</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="left_div allpad"
                              onClick={() => {
                                setisclickedPreShare(true);
                                setisclickedShare(false);
                              }}
                            >
                              &nbsp;
                              <div>Share</div>
                              &nbsp;
                              <div>
                                <FaExternalLinkAlt />
                              </div>
                              &nbsp;{" "}
                            </div>
                          </>
                        )
                      }
                      className="align-items-center navbar_some dropdown-menu-right"
                      id="dropdown-basic-button"
                    >
                      <NavDropdown.Item>
                        <div
                          className="left_div"
                          onClick={() => {
                            setIsTicketting(true);
                            setisclickedShare(true);
                            setisclickedPreShare(false);
                            ClickedShare("public");
                          }}
                        >
                          &nbsp;
                          <div>Share Publicly</div>
                          &nbsp;
                          <div>
                            <FaExternalLinkAlt />
                          </div>
                          &nbsp;{" "}
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <div>Cancel</div>
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </div>
              </div>
              {!isclickedPreShare && isclickedShare && newsharedTicket ? (
                <CopyButton
                  type={"public"}
                  data={newsharedTicket}
                  setisclickedShare={setisclickedShare}
                  isclickedShare={isclickedShare}
                  setisclickedPreShare={setisclickedPreShare}
                />
              ) : null}
              &nbsp;
              <InfoTag
                tooltipContent={
                  useCompetition
                    ? "Post tickets to competitions you are qualified for. You can only post to advanced competitions if you are qualified."
                    : "You are sharing this ticket Publicly!! This will generate a link for you to share this ticket externaly to your social media or any other platform. Sharing your ticket externally also shares your ticket on this platform for other users to engage, like and follow you."
                }
                scale={isSmallScreen ? 95 : 50}
                width={"400px"}
              />
            </div>
          </div>
        </div>
        <div className="horizontal_line allpad" />
      </div>
    </div>
  );
}
