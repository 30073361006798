import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import "../css/ThemeCss.css";
import FooterAll from "./Footer";
import { IoIosLogIn } from "react-icons/io";
import { FaGoogle } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import userlog from "../images/user.svg";
import AdminHeader from "./AdminHeader";

const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

function AdminLogin() {
  const navigation = useNavigate();
  const defaultForm = { key: ""};
  const [formData, setFormData] = useState(defaultForm);
  const location = useLocation();
  const clickedSub = location.state?.plan || null;
  const [useProcess, setUseProcess] = useState(clickedSub);
  const [showFooter, setShowFooter] = useState(false);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);

  ////console.log("in signin", globalUser);
  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  if (globalUser?.user?.firstname && globalUser?.user?.firstname !== "Guest" && globalUser?.user?.isadmin !=="true") {
    navigation("/");
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSignUpClick = () => {
    if (!useProcess) {
      navigation("/Signup");
    } else {
      navigation("/Signup", {
        state: { plan: useProcess },
      });
    }
  };
  const handleInstagramLoginClick = () => {
    if (!useProcess) {
      navigation("/Signup");
    } else {
      navigation("/Signup", {
        state: { plan: useProcess },
      });
    }
  };
  const handleGoogleLoginClick = () => {
    if (!useProcess) {
      navigation("/Signup");
    } else {
      navigation("/Signup", {
        state: { plan: useProcess },
      });
    }
  };
  const handleLoginClick = async (event) => {
    if (formData.key === "" ) {
      toast.error("Enter access key to login");
      return; // Add a return statement here
    }

    try {
      const res = await axios.post(`${API_URL}/val_admin`, formData);
      const data = res.data;
      ////console.log(data.userinfo);
      const userinfo = data.userinfo;

      if (data.status === "true") {
        navigation("/admin_users",{state:{isadmin:data?.data}})
      }else{
        toast.error("Access key not valid, contact Admin")
      }
            
    } catch (error) {
      ////console.log(error);
    }
  };

  const handleForgetPass = () => {
    navigation("/getemail");
  };

  return (
    <div
      style={{
        background: "rgba(11, 38, 71, 0.959)",
      }}
    >
      <AdminHeader />
      <Container
        className="d-flex align-items-center justify-content-center"
        variant="light"
        style={{
          background: "white",
          marginTop: "5%",
          fontFamily: "serif",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          marginBottom: "30px",
          height: "80vh",
        }}
      >
        <div style={{}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={userlog}
              alt="profile"
              style={{ width: "50%", height: "50%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
           Welcome {globalUser?.user?.firstname}, enter access key 
          </div>
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLoginClick();
              }
            }}
          >
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formkey"
            >
              <Form.Control
                type="password"
                placeholder="Access Key"
                name="key"
                value={formData.key}
                onChange={handleChange}
                required
              />
            </Form.Group>

            

            <Row>
              <Col>
                <div
                  className="link"
                  onClick={() => handleLoginClick()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginClick();
                    }
                  }}
                  style={{
                    background: "green",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    color: "white",
                    borderBottomRightRadius: "10px",
                    height: "4vh",
                    width: "100%",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "orange";
                  }} // change color on hover
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                >
                  <IoIosLogIn style={{ scale: "inherit" }} />
                  &nbsp; Login
                </div>
              </Col>
            </Row>
          </Form>
          <div style={{ marginBottom: "40px" }}></div>
        </div>
      </Container>
      <ToastContainer position="bottom-left" />
      <FooterAll page={"signin"} />
    </div>
  );
}

export default AdminLogin;
