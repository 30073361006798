import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../css/LeagueSummaryChart.css";
import Team_image from "../../images/team_images.json";
const getColor = (name) => {
  if (name === "win") return "green";
  if (name === "loss") return "red";
  if (name === "draw") return "orange";
};
export default function LeagueStatisticsWDL({
  data,
  ranktype,
  lgNav,
  hist,
  half,
  ranking,
}) {
  ////console.log(ranking)
  const [teamCount, setTeamCount] = useState(3);
  const [teamCountl, setTeamCountl] = useState(3);
  const navigation = useNavigate();

  const RenderTeam = ({ own_team, rank }) => {
    ////console.log(data, own_team);
    let gh = "";
    if (hist === 0) {
      gh = "all";
    } else if (hist === 1) {
      gh = "15g";
    } else if (hist === 2) {
      gh = "10g";
    } else if (hist === 3) {
      gh = "5g";
    }
    let type = "";
    if (
      ranktype === "Winners" ||
      ranktype === "Lossers" ||
      ranktype === "Tiers"
    ) {
      type = "winners";
    }
    ////console.log(ranktype, own_team, gh, type);
    let TeamDataChart = data?.[own_team]?.charts?.[gh]?.[type]?.chart;
    ////console.log(TeamDataChart, own_team);
    const tooltipData = data?.[own_team]?.charts?.[gh]?.[type]?.tooltip;

    const GettoolTip = ({ date, own_team, tooltipData }) => {
      tooltipData = tooltipData?.[date];
      ////console.log(date, tooltipData);
      return (
        <div>
          <Modal.Dialog>
            <Modal.Body className="tooltipbg">
              <Container className="justify-content-between align-items-center myContainer">
                <Row>
                  <Col>
                    <h6>{tooltipData?.date}</h6>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Match</h6>
                </div>
                <Row>
                  <Col>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.score}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Possessions</h6>
                </div>

                <Row>
                  <Col>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.possession}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Dangerous Attacks</h6>
                </div>

                <Row>
                  <Col>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.attack}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Shots on Goal</h6>
                </div>

                <Row>
                  <Col>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.shots}</h5>
                  </Col>
                  <Col>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      );
    };
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div>
            <GettoolTip
              date={label}
              tooltipData={tooltipData}
              own_team={own_team}
            />
          </div>
        );
      }

      return null;
    };
    const handleteamclick = ({ val, team_, league }) => {
      const team_info = {
        team: val,
        league: league,
        data: lgNav,
      };

      navigation("/team/" + team_, { state: team_info });
    };
    const handlematchlick = (day) => {
      const match_id = tooltipData?.[day]?.match?.matchID;
      const team2 = tooltipData?.[day]?.match?.awayID;
      const team1 = tooltipData?.[day]?.match?.homeID;
      const seas = " ";
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };
    const tID2 = data?.[own_team]._id.split("_").pop();
    return (
      <div className="divmargin mainFont bold">
        <Row className="justify-content-between align-items-center">
          <Col md={3} className="mb-2">
            <Row className="justify-content-between align-items-center">
              <Col md={2} className="mb-3">
                {" "}
              </Col>
              <Col md={2} className="mb-3" style={{ paddingRight: "10px" }}>
                <div>{rank}</div>
              </Col>
              <Col md={8} className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={Team_image?.[tID2]?.["image"]}
                    className="link"
                    onClick={() =>
                      handleteamclick({
                        val: own_team,
                        team_: data?.[own_team]?.numbers?.team_meta?.team_name,
                        league:
                          data?.[own_team]?.numbers?.team_meta?.team_league,
                      })
                    }
                    alt={data?.[own_team]?.numbers?.team_meta?.team_name}
                    style={{
                      width: "100px", // or any value appropriate for your design
                      height: "100px", // keep aspect ratio if not defined
                    }}
                  />
                  <h6>{""}</h6>
                  <div>{data?.[own_team]?.numbers?.team_meta?.team_name}</div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={9}>
            <div style={{ width: "100%", height: "200px" }}>
              <ResponsiveContainer>
                <BarChart
                  width={900}
                  height={150}
                  data={TeamDataChart}
                  barGap={0}
                  margin={{
                    top: 10,
                    right: 15,
                    bottom: 5,
                    left: -25,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="day"
                    tick={{ fontSize: 12, fontFamily: "serif" }}
                  />
                  <YAxis
                    tick={{ fontSize: 12, fontFamily: "serif" }}
                    tickFormatter={(value) => {
                      if (value >= 1 && value <= 2) {
                        return "Loss";
                      } else if (value > 2 && value <= 4) {
                        return "Draw";
                      } else if (value > 4 && value <= 6) {
                        return "Win";
                      } else {
                        return value; // Use the original value if it doesn't fall into any range
                      }
                    }}
                    interval={0} // Ensure the ticks are spaced evenly
                    ticks={[1, 3, 5]}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    offset={10}
                    allowEscapeViewBox={{ x: false, y: true }}
                  />

                  {half === 0 ? (
                    <>
                      <Bar
                        dataKey="pos1"
                        barSize={10}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        {TeamDataChart?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={getColor(entry.col1)}
                            className="link"
                            onClick={() => handlematchlick(entry.day)}
                          />
                        ))}
                      </Bar>
                      <Bar
                        dataKey="pos2"
                        barSize={10}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        {TeamDataChart?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            className="link"
                            fill={getColor(entry.col2)}
                            onClick={() => handlematchlick(entry.day)}
                          />
                        ))}
                      </Bar>
                    </>
                  ) : half === 2 ? (
                    <Bar
                      dataKey="pos2"
                      barSize={10}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      {TeamDataChart?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          className="link"
                          fill={getColor(entry.col2)}
                          onClick={() => handlematchlick(entry.day)}
                        />
                      ))}
                    </Bar>
                  ) : (
                    <Bar
                      dataKey="pos1"
                      barSize={10}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      {TeamDataChart?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getColor(entry.col1)}
                          className="link"
                          onClick={() => handlematchlick(entry.day)}
                        />
                      ))}
                    </Bar>
                  )}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const HandleSeeMoreTeam = (val, type) => {
    //let currValue = monthCount;
    
    if (val === "seemore" && teamCount > 2 && type === "w") {
      setTeamCount(teamCount + 3);
    } else if (val === "seemore" && teamCountl > 2 && type === "l") {
      setTeamCountl(teamCountl + 3);
    }
    if (val === "seeless" && teamCount > 5 && type === "w") {
      setTeamCount(teamCount - 3);
    } else if (val === "seeless" && teamCountl > 5 && type === "l") {
      setTeamCountl(teamCountl - 3);
    }
  };

  function SeeMore({ sender }) {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <Container style={{ width: "100%" }}>
          <Row className="justify-content-between align-items-center">
            <Col
              md={6}
              className="mb-2"
              onClick={() => HandleSeeMoreTeam("seeless", sender)}
            >
              <h6 className="small-text">{"< Less"}</h6>
            </Col>
            <Col
              md={6}
              className="mb-2"
              onClick={() => HandleSeeMoreTeam("seemore", sender)}
            >
              <h6 className="small-text">{"More >"}</h6>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
////console.log(ranking)
  return (
    <div>
      <div >
        <Modal.Dialog className="rounded-3 mainFont">
          <div className="center_div bold  paragrph_font"> Bar Charts</div>
          <div
            className="center_div_distributed tiny_font center_sides nav_scroller noyscrol"
          >
            <div> Bars: First Half | Second Half</div>{" "}
            &nbsp;&nbsp;<div className="center_div center_sides">
            <div className="rectanglewin" /> &nbsp;<div>Half Win</div></div>
            &nbsp;&nbsp;<div className="center_div center_sides">
            <div className="rectangledraw" /> &nbsp;<div>Half Draw</div></div>
            &nbsp;&nbsp;<div className="center_div center_sides">
            <div className="rectangleloss" /> &nbsp;<div>Half Loss</div></div>
            &nbsp;&nbsp;
          </div>
          <Modal.Body>
            {data &&
              ranking.map(([key, value], index) => (
                <div key={index}>
                  <Modal.Dialog className="rounded-3">
                    <Modal.Body>
                      <RenderTeam key_={key} own_team={value} rank={key} />
                    </Modal.Body>
                  </Modal.Dialog>
                </div>
              ))}
          </Modal.Body>
          <SeeMore sender={"w"} />
        </Modal.Dialog>
      </div>
    </div>
  );
}
