import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row, Card } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";

import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";

import "../css/Signin.css";
import FooterAll from "./Footer";
import {
  FcDepartment,
  FcFeedback,
  FcAssistant,
  FcContacts,
} from "react-icons/fc";

import care from "../images/callcenteroffice.png";
import EntryHeader from "./EntryHeader";
import { is } from "date-fns/locale";
import FooterNav from "./SmallScreenNav";
import { SideNavPage } from "./Utils";

const API_URL = process.env.REACT_APP_API_URL;

export function ContactUsSub() {
  const defaultForm = {
    email: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    message: "",
    offer: false,
  };
  const [formData, setFormData] = useState(defaultForm);
  const [send, setSend] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {}, [setSend]);
  const navigation = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const HandleMessageSend = async (event) => {
    setSend(true);

    ////console.log(formData);
    const message_data = {
      bulk_info: formData,
      message_type: "feedback",
    };
    const emailreciept = {
      user_name: formData?.firstname,
      user_email: [formData?.email],
      subject: "Message Recieved",
      send_mail: "info@betinformatics.com",
      sub_name: "Bet Informatics",
      message_type: "feedbackreply",
      user_message: formData?.message,
    };
    const res = await axios.post(`${API_URL}/send-mail`, message_data);
    toast.success("We have recieved your message. Thank you!!");

    setFormData(defaultForm);
    var checkbox = document.getElementById("formGridCheckbox");
    checkbox.checked = false;
    const sent = await axios.post(`${API_URL}/send-mail`, emailreciept);
  };

  return (
    <div>
      <Container>
        <div
          style={{
            background:
              "linear-gradient(to Bottom, rgba(11, 38, 71, 0.959) 30%, white 70%)",

            color: "white",
            marginTop: "40px",
            marginBottom: "40px",
            borderTopLeftRadius: "1%",
            borderTopRightRadius: "1%",
            borderBottomLeftRadius: "1%",
            borderBottomRightRadius: "1%",
          }}
        >
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                alignItems: "center",
                fontFamily: "serif",
                fontSize: "50px",
                fontWeight: "bolder",
              }}
            >
              Contact us
            </div>
          </Row>
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                alignItems: "center",
                fontFamily: "serif",
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "70px",
              }}
            >
              We are here to answer all your questions.
            </div>
          </Row>

          <div className= {`usemaxSpace ${isSmallScreen ? "center_straight":  "center_sides"} center_div`} >
            <div
              className="  "
              style={{
                fontFamily: "serif",

              }}
            >
              <Card style={{ width: "", border: "none", margin: "10px" }}>
                <Card.Title style={{ marginLeft: "30px", marginTop: "30px" }}>
                  <FcDepartment size={80} />
                </Card.Title>
                <Card.Title>
                  <div
                    style={{
                      marginLeft: "30px",
                      marginTop: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </div>
                </Card.Title>
                <Card.Body style={{ marginLeft: "10px" }}>
                  <Card.Text style={{ fontWeight: "bold" }}>
                    BetInformatics USA, LLC.{" "}
                  </Card.Text>
                  <Card.Text style={{ marginTop: "-15px" }}>
                    Attn: Managing Partners
                  </Card.Text>

                  <Card.Text
                    style={{ marginTop: "-15px", marginBottom: "20px" }}
                  >
                    Lansing, Michigan 48911
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div
              className=" "
              style={{
                fontFamily: "serif",
              }}
            >
              <Card style={{ width: "", border: "none", margin: "10px" }}>
                <Card.Title style={{ marginLeft: "30px", marginTop: "30px" }}>
                  <FcContacts size={80} />
                </Card.Title>
                <Card.Title>
                  <div
                    style={{
                      marginLeft: "30px",
                      marginTop: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    Contacts
                  </div>
                </Card.Title>
                <Card.Body style={{ marginBottom: "0px" }}>
                  <Card.Text
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    <FcAssistant size={40} /> Care Line: +1 (989) 403-5530
                  </Card.Text>
                  <Card.Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <FcFeedback size={40} /> Care Email: Info@betinformatics.com
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div
            style={{
              fontFamily: "serif",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Row style={{ width: "1000px" }}>
              <Card
                style={{
                  width: "100%", // 1000px create CSS to auto adjust
                  border: "none",
                  marginBottom: "10px",
                  overflow: "auto",
                }}
              >
                <Card.Title>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    {isSmallScreen ? "NEED MORE INFORMATION?" : "NEED MORE INFORMATION OR ASK SPECIFIC QUESTIONS?"}
                  </div>
                </Card.Title>
                <Card.Title>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      color: "green",
                      marginBottom: "-10px",
                    }}
                  >
                    we reply to emails in 24hrs
                  </div>
                </Card.Title>
                <Card.Body>
                  <Form id="myForm">
                    <Row style={{ marginTop: "5px" }}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2" style={{ color: "black" }}>
                          First Name:
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            value={formData.firstname}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2" style={{ color: "black" }}>
                          Last name:
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            value={formData.lastname}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Row>

                    <Row style={{ marginTop: "10px" }}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2" style={{ color: "black" }}>
                          Email:
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2" style={{ color: "black" }}>
                          Phone Number:
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            name="phonenumber"
                            placeholder="Phone Number"
                            value={formData.phonenumber}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2" style={{ color: "black" }}>
                          Enter your message:
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="textbox"
                            name="message"
                            as="textarea"
                            rows={10}
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          name="offer"
                          id="formGridCheckbox"
                          defaultChecked={false}
                          value={formData.offer}
                          label="Get offers and promotions in Email"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              offer: e.target.checked,
                            }); // saves whether the checkbox is checked or not
                          }}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                  <div
                    type="submit"
                    id="submitButton"
                    style={{
                      background: "orange",
                      width: "120px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => HandleMessageSend()}
                  >
                    SEND
                  </div>
                </Card.Body>

                <Card.Body style={{ fontStyle: "italic" }}>
                  <Card.Text
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-15px",
                      fontSize: isSmallScreen? "10px" : "",
                    }}
                  >
                    {" "}
                    * Names and Email are mandatory fields.
                  </Card.Text>

                  <Card.Text
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-15px",
                      fontSize: isSmallScreen? "10px" : "",
                    }}
                  >
                    {" "}
                    * Data provided would be treated confidentially. Read our
                    data &nbsp;
                    <a
                      className="link"
                      onClick={() => navigation("/privacypolicy")}
                    >
                      privacy policy.
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}

function ContactUs() {
  const navigation = useNavigate();
  const defaultForm = { email: "", text: "", offer: false };

  const [showFooter, setShowFooter] = useState(false);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isOpen, setIsOpen] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);

  ////console.log("Contact Us", globalUser);
  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    function handleScroll() {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowFooter(isAtBottom);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="light">
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
        <IndexHeader setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
        isLogedin={true}
        issubscribed={true}
        />
      
      <div
        style={{
          backgroundImage: `url(${care})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          overflow: "auto",
        }}
      >
        {" "}
        <ContactUsSub />
      </div>

      <FooterAll page={"ContactUs"} />
      <FooterNav />
    </div>
  );
}

export default ContactUs;
