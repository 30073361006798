import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect, useContext } from "react";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { GoogleLogin, GoogleOAuthProvider, googleLogout} from "@react-oauth/google";
const google_login = process.env.REACT_APP_GOOGLE_LOGIN;
const API_URL = process.env.REACT_APP_API_URL;
export default function FooterAll({ page,setIslogedInGoogle}) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const HandleLogout = () => {
    UpdateLocal({ action: "delete", data: { rmKeys: ["user"] } }).then(
      (result) => {
        googleLogout()
        toast.success("You are logged out");
      }
    );
  };
  ////console.log(globalUser, globalUser?.user?.firstname);
  const UpdateUser =async(token)=>{
    try {
      const res = await axios
        .post(`${API_URL}/AutoLogin`, JSON.stringify({token:token}), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});

        //console.log(res)
        if(res?.data?.status === true){
          const userData = {user:res?.data?.userinfo}
          userData["user"]["theme"] = {
            background: "light",
            outerTableboarder: "outer-border-light",
            stripeTable: "striped-table-light",
            h6: "_h6-light",
          },
        updateGlobalUser(userData);
        UpdateLocal({ action: "save", data: userData });
        }
        
    } catch (error) {
      //console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }

  }
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted"
      style={{ marginTop: "30px" }}
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>
            <div style={{ fontSize: "20px", fontFamily: "serif" }}>
              Get connected with us on social networks:
            </div>
          </span>
        </div>

        <div>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="facebook-f" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="twitter" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="google" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="instagram" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="linkedin" />
          </a>
          {/* <a href="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="github" />
          </a> */}
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon color="secondary" icon="gem" className="me-3" />
                BetInformatics
              </h6>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                Best Sports analytics website designed specifically to help
                punters analyse games before betting.
              </div>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Sevices</h6>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <a href="/" className="text-reset">
                  Sports predictions
                </a>
              </div>

              <a href="/" className="text-reset">
                <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                  Sport analytics
                </div>
              </a>

              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <a href="/" className="text-reset">
                  Value bets
                </a>
              </div>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <a href="/privacypolicy" className="text-reset">
                  Privacy Policy
                </a>
              </div>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <a href="/TAC" className="text-reset">
                  TAC
                </a>
              </div>
              
              {globalUser?.user?.firstname !== "Guest" ? (
                <>
                  <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                    <a href="/userprofile" className="text-reset">
                      Subscriptions
                    </a>
                  </div>
                  <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                    <a href="/" className="text-reset" onClick={HandleLogout}>
                      Logout
                    </a>
                  </div>{" "}
                  
                </>
              ) :<div className="margin-10down usemaxSpace center_div">
                    <GoogleOAuthProvider clientId={google_login}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          
                          UpdateUser(credentialResponse?.credential)
                        }}
                        onError={() => {
                          //console.log("Login Failed");
                        }}
                        useOneTap
                      />
                    </GoogleOAuthProvider>
                  </div>}
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <MDBIcon color="secondary" icon="home" className="me-2" />
                Detroit, MI 48911, US
              </div>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <MDBIcon color="secondary" icon="envelope" className="me-3" />
                Info@betinformatics.com
              </div>
              <div style={{ fontSize: "18px", fontFamily: "serif" }}>
                <MDBIcon color="secondary" icon="phone" className="me-3" /> +1
                (989) 403-5530
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{
          fontSize: "18px",
          fontFamily: "serif",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
      >
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="/">
          BetInformatics.com
        </a>
      </div>
      <ToastContainer position="bottom-left" />
    </MDBFooter>
  );
}
