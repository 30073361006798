import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyHeader from "./Emptyheader";
import axios from "axios";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import FooterAll from "./Footer";
import { FaRegSave } from "react-icons/fa";
import PasswordChecklist from "react-password-checklist";
import "../css/ThemeCss.css";
import { isValid } from "date-fns";
import { existsSync } from "fs";
import { ToastContainer, toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

////console.log(WEB);
export default function ResetPassword() {
  const defaultpass = {
    newPassword: "",
    confirmNewPassword: "",
  };
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [clickedVerify, setClickedVerify] = useState(false);
  const [needData, setNeedData] = useState();
  const [expeired, setExpired] = useState(false);
  const [userinfo, setCurrUser] = useState();
  const [formDataPass, setFormDataPass] = useState(defaultpass);
  const [validPass, setvalidPass] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  const navigation = useNavigate();
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      let params = new URLSearchParams(window.location.search);
      let data = params.get("data");
      const decoder = await axios.post(`${API_URL}/pushtemp`, {
        type: "get",
        _id: data,
      });
      if (decoder?.data?.response === "success") {
        const userdata = decoder?.data?.savedData;
        let firstname = userdata?.user_name;
        let email = userdata?.user_email?.[0];
        let exptime = userdata?.exptime;
        let senttime = userdata?.senttime;
        let session = userdata?.session;
        let now = new Date();
        let currentTime = Math.floor(now.getTime() / 1000);
        if (currentTime > exptime) {
          setExpired(true);
        } else {
          setNeedData({
            email: email,
            firstname: firstname,
            auth: session,
          });
        }
        ////console.log(firstname, email, exptime, session);
      }
    };
    fetchData();
  }, [setClickedVerify]);
  const handleChangePass = (event) => {
    const { name, value } = event.target;
    setFormDataPass((prevData2) => ({
      ...prevData2,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleSubmit2 = async () => {
    if (validPass) {
      const PassChange = {
        user_name: needData?.firstname,
        user_email: [needData?.email],
        subject: "Password Changed",
        send_mail: "security@betinformatics.com",
        sub_name: "Bet Informatics Security",
        message_type: "Datachange",
        user_message:
          "Your password haave just been changed. Disregard this message if this is you. If this is not you, then you need to secure your email first then reset your password from your email becuase this password was changed with access to your email",
        links: [`${WEB}/signin`],
      };

      const passData = {
        email: needData?.email,
        auth: needData?.auth,
        pass: formDataPass?.newPassword,
      };
      try {
        const pass_val = await axios.post(
          `${API_URL}/reset_password`,
          passData
        );

        if (pass_val?.data?.status === "updated") {
          setFormDataPass(defaultpass);
          const res_ = await axios.post(`${API_URL}/send-mail`, PassChange);
          toast.success("password updated successfully");
        } else {
          toast.error("its not you its us, please try the process again");
        }
      } catch {
        toast.error("its not you its us, please try the process again");
      }
    } else {
      toast.error("Password not valid");
    }
  };
  return (
    <div
      style={{
        background: "white",
        fontFamily: "serif",
      }}
    >
      <EmptyHeader />

      <Container className="appear_center ">
        <div>
          <div className={`center_div margin-50down ${isSmallScreen ? "midfont": "largeheadfont"}`}>
            {" "}
            Password reset for {needData?.firstname}?
          </div>
          {expeired ? (
            <div className="center_div infoFont margin-30down">
              Link Expired
            </div>
          ) : (
            <div className="center_div infoFont margin-30down">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "20px",
                      color: "Blue",
                    }}
                  >
                    Enter New Password
                  </div>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="newPassword">
                    <Form.Label>
                      <h6>New Password</h6>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      value={formDataPass?.newPassword}
                      onChange={handleChangePass}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="confirmNewPassword">
                    <Form.Label>
                      <h6>Confirm New Password</h6>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Confirm New Password"
                      value={formDataPass?.confirmNewPassword}
                      onChange={handleChangePass}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                      ]}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                      }}
                      minLength={5}
                      value={formDataPass.newPassword}
                      valueAgain={formDataPass?.confirmNewPassword}
                      onChange={(isValid) => {
                        setvalidPass(isValid);
                      }}
                    />
                  </div>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="link"
                    onClick={() => handleSubmit2()}
                    style={{
                      background: "green",
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      color: "white",
                      borderBottomRightRadius: "10px",
                      height: "6vh",
                      width: "50%",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = "orange";
                    }} // change color on hover
                    onMouseOut={(e) => {
                      e.target.style.color = "white";
                    }}
                  >
                    <FaRegSave style={{ scale: "inherit" }} /> &nbsp; Save
                  </div>
                </Row>
              </Form>
            </div>
          )}

          <div className="">
            <div
              className="link smallbuton  divorangeButton"
              onClick={() => navigation("/", { state: { userinfo } })}
            >
              Go to Home
            </div>
          </div>
        </div>
      </Container>
      <ToastContainer position="bottom-center" />
    </div>
  );
}
