import React, { useState } from "react";
import { io } from "socket.io-client";


const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;

export const SaveComments = (data) => {
  //console.log(data,data?.targetPage,BackSocket,BackSocketToken)
  if (!data || !BackSocket || !BackSocketToken) {
      //console.log("Invalid arguments");
      return "error";
  }

  
  const currTime = Date.now() / 1000;
  const socket = io(BackSocket, {
      auth: {
        token: BackSocketToken,
      },
  });
const dataMain = data.targetPage
  try {
      socket.emit("comments", {
          data: data,
          time: currTime,
      });
      //socket.disconnect();
      return "success"
  } catch (e) {
      //console.log("error while pinging backend", e);
      return "error";
  }   
}

export const LikeComments = (data,socket) => {
  //console.log(data,data?.targetPage,BackSocket,BackSocketToken)
  if (!data || !BackSocket || !BackSocketToken) {
      //console.log("Invalid arguments");
      return "error";
  }

  
  const currTime = Date.now() / 1000;
  
const dataMain = data.targetPage
  try {
      
      socket.emit("comments", {
          data: data,
          time: currTime,
      });
      //socket.disconnect();
      return "success"
  } catch (e) {
      //console.log("error while pinging backend", e);
      return "error";
  }   
}