import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  NavLink,
  Button,
  Container,
  DropdownButton,
  Dropdown,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import { SpinnerCustom } from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import DisplayPastGames from "./TeampastMatches";
import LeagueTables from "./LeagueTables";
import DisplaySummary from "./TeamSummary";
import LeagueNav from "../LeagueSideBar";
import TeamStatistics from "./TeamStaisticsrouter";
import Team_image from "../../images/team_images.json";
import FooterAll from "../Footer";
import FooterNav from "../SmallScreenNav";

import "../../css/SingleTeamPage.css";
import { Errorlog, SideNavPage } from "../Utils";
import ShowTicket from "../ShowTicketGames";
import IndexSubheads from "../IndexSubheader";
import {
  CatchedStoraged,
  GetCatchedPages,
  SaveCatchedPages,
} from "../manageStorage/CatchedData";

const API_URL = process.env.REACT_APP_API_URL;

function GetTeamPage() {
  const [useFixture, setFixture] = useState({});

  const [loading, setLoading] = useState(true);
  const [showFirstSbar, setshowFirstSbar] = useState({
    target: "SUMMARY",
    status: true,
  });
  const [showSecondSbar, setshowSecondSbar] = useState({
    target: "Full Time",
    status: true,
  });
  const [showThirdSbar, setshowThirdSbar] = useState({
    target: "All",
    status: false,
  });
  const [statsToShow, setstatsToShow] = useState("All");
  const [statsToShow2, setstatsToShow2] = useState("Full Time");
  const [statsToShow3, setstatsToShow3] = useState("All");

  const location = useLocation();
  const team = location.state?.team || " "; // Initialize with an empty object if undefined
  const league = location.state?.league || " ";
  const lagueNavData = location.state?.data || " ";

  /* const urlParams = new URLSearchParams(window.location.search);
  //s////console.log(urlParams)
  const team = urlParams.get('team') || " ";
  const league = String(urlParams.get('league')) || " ";
  const lagueNavData = urlParams.get('data') || " "; */

  const [useSideNav, setUseSideNav] = useState(lagueNavData);
  const [useStatistics, setuseStatistics] = useState();
  const [askleague, setaskleague] = useState(String(league));
  const [auxAskleague, setauxAskleague] = useState(String(league));
  const [showYear, setshowYear] = useState();
  const [jointLeague, setJointLeague] = useState(String(league));
  const [useleagueName, setuseleagueName] = useState();
  const [activeElement, setActiveElement] = useState(0);
  const [activeElement1, setActiveElement1] = useState(0);
  const [activeElement2, setActiveElement2] = useState(0);
  const [activeElement3, setActiveElement3] = useState(0);
  const [showTableLegend, setShowTableLegend] = useState(false);
  const [leagueYears, setleagueYears] = useState();
  const [disData, setdisData] = useState();
  //const [useSecondTab, setuseSecondTab] = useState("Win-Draw-Lose");
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [userInfo, setUserInfo] = useState(globalUser);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isGosClose, setisGosClose] = useState(false);
  const [isoptions, setIsOptions] = useState("Cards/Corners");
  const [is1X2, setIs1X2] = useState(true);
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const [Shows, setShows] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
    if (window.innerWidth < 1360) {
      setisGosClose(true);
    } else {
      setisGosClose(false);
    }
  };

  useEffect(() => {
    const getcatched = async () => {
      const pageData = await GetCatchedPages("TeamStats");
      //console.log(pageData,);
      if (typeof pageData === "string") {
        ("");
      } else if (typeof pageData === "object") {
        // I hope you didn't set it to 0 seconds

        if (String(league) === String(pageData?.leagueID)) {
          //console.log("was here in catched");
          setstatsToShow2(pageData?.setstatsToShow2);
          setstatsToShow3(pageData?.setstatsToShow3);
          setshowFirstSbar(pageData?.setshowFirstSbar);
          setshowSecondSbar(pageData?.setshowSecondSbar);
          setstatsToShow(pageData?.setstatsToShow);
          setshowYear(pageData?.setshowYear);
          setActiveElement(pageData?.setActiveElement);
          setActiveElement1(pageData?.setActiveElement1);
          setActiveElement2(pageData?.setActiveElement2);
          setActiveElement3(pageData?.setActiveElement3);
        }
      }
    };
    getcatched();
  }, []);

  const SaveCatched = async (props) => {
    const Data2Save = {
      leagueID: auxAskleague,
      setstatsToShow2: props?.setstatsToShow2 ?? statsToShow2,
      setstatsToShow3: props?.setstatsToShow3 ?? statsToShow3,
      setshowFirstSbar: props?.setshowFirstSbar ?? showFirstSbar,
      setshowSecondSbar: props?.setshowSecondSbar ?? showSecondSbar,
      setstatsToShow: props?.setstatsToShow ?? statsToShow,
      setshowYear: props?.setshowYear ?? showYear,
      setActiveElement: props?.setActiveElement ?? activeElement,
      setActiveElement1: props?.setActiveElement1 ?? activeElement1,
      setActiveElement2: props?.setActiveElement2 ?? activeElement2,
      setActiveElement3: props?.setActiveElement3 ?? activeElement3,
    };

    const pageData = await SaveCatchedPages("TeamStats", Data2Save);
    //console.log(pageData, Data2Save);
  };

  const computeStateYear = (league_years, askleague) => {
    const state_year = {};
    const dis_data = {};
    ////console.log(askleague)
    let asking_league_ = "";
    const extractMaxYear = (yearList) => {
      let maxYear = -Infinity;
      let maxYearString = "";

      for (let yearString of yearList) {
        let years = yearString.split("/");
        let firstYear = years[0] !== "" ? parseInt(years[0]) : -Infinity;
        let secondYear =
          years[1] !== "" && years[1] !== undefined
            ? parseInt(years[1])
            : -Infinity;
        let yearMax = Math.max(firstYear, secondYear);

        if (yearMax > maxYear) {
          maxYear = yearMax;
          maxYearString = yearString;
        } else if (yearMax === maxYear && secondYear !== -Infinity) {
          maxYearString = yearString;
        }
      }

      return maxYearString;
    };

    let season_string = [];
    ////console.log(league_years)
    const curr_league = league_years?.[askleague]?.season_list;
    for (let index = 0; index < curr_league?.length; index++) {
      season_string.push(curr_league?.[index]?.year);
    }
    ////console.log(curr_league,season_string)
    const currentHighestYear = extractMaxYear(season_string);
    /* ////console.log(Object.keys(league_years)); */
    for (let lg = 0; lg < Object.keys(league_years).length; lg++) {
      const league = league_years?.[Object.keys(league_years)[lg]]?.season_list;

      let max = 0;
      let max_y = "";
      let ask_candidate = false;
      for (let item = 0; item < league?.length; item++) {
        const c_id = league?.[item]["id"];
        if (c_id > max) {
          max = c_id;
          max_y = league?.[item]?.["year"];
          if (
            String(c_id) === String(askleague) &&
            max_y === currentHighestYear
          ) {
            ////console.log(String(c_id), String(asking_league));
            asking_league_ = c_id;
          }
        }
      }
      /* if (ask_candidate === true) {
        asking_league_ = String(max);
        ////console.log("assigned");
      } */
      state_year[String(max)] = { year: max_y, id: max };

      dis_data[String(max)] = "AllGames";
    }
    ////console.log(asking_league_,currentHighestYear)
    ////console.log(state_year, dis_data);
    ////console.log(state_year);
    return [state_year, dis_data, asking_league_];
  };

  // Inside your component

  ////console.log(globalUser, userInfo);

  useEffect(() => {
    const fetchData = async () => {
      let ckUser;
      if (!globalUser.user) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
      }
      if (ckUser && ckUser.result === "retrieved") {
        updateGlobalUser(ckUser?.data?.user);
        setUserInfo(ckUser?.data?.user);
        if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "inactive"
        ) {
          setIsSubscribed(false);
        } else if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
          setIsLogedin(false);
        }
      } else {
        if (
          (globalUser &&
            globalUser?.user?.firstname === "Guest" &&
            globalUser?.user) ||
          (globalUser && Object.keys(globalUser).length === 0)
        ) {
          setIsLogedin(false);
          setIsSubscribed(false);
        } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
          if (
            globalUser &&
            !globalUser?.user?.subscription &&
            globalUser?.user
          ) {
            ////console.log(globalUser);
            setIsLogedin(true);
            setIsSubscribed(false);
          } else {
            if (
              globalUser &&
              globalUser?.user?.subscription?.status === "inactive"
            ) {
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              setIsLogedin(true);
              setIsSubscribed(true);
            }
          }
        }
      }
      //***removing supscription****
      setIsSubscribed(true);
    };

    fetchData();
  }, []);
  ////console.log(globalUser, userInfo);

  useEffect(() => {
    let stateYear = "";
    const get_tmdata = async () => {
      let options = {
        body: JSON.stringify({
          team: team,
          league: auxAskleague,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60 * 60,
        },

        seconds: 2 * 60 * 60,
      };
      let url = "/team_header";
      let indexdb = "team_header";
      let SaveKey = "" + auxAskleague + team;
      try {
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        const res = await CatchedStoraged(url, options, SaveKey, indexdb);

        /* const res = await axios.post(
          `${API_URL}/team_header`,
          JSON.stringify({
            team: team,
            league: auxAskleague,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        ////console.log(res, team, askleague)
        const firstDict = res;
        if (firstDict?.status === "success" && firstDict.data) {
          ////console.log("first dict", firstDict?.data);
          setFixture(firstDict?.data);
          stateYear = computeStateYear(
            firstDict?.data?.league_years,
            auxAskleague
          );
          ////console.log(stateYear)
          setLoading(false);
          setshowYear(true);
          setleagueYears(stateYear[0]);
          setdisData(stateYear[1]);
          setaskleague(stateYear[2]);
          SaveCatched({
            setshowYear: true,
            setleagueYears: stateYear[0],
            setdisData: stateYear[1],
            setaskleague: stateYear[2],
          });
        } else {
          throw new Error("Unsuccessful request");
        }
      } catch (error) {
        ////console.log(error)
        Errorlog(error, "error loading statistics", "from team stats");
      }
    };
    get_tmdata();
  }, []);

  function FirstLayerSubLinks({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const PastLinks = ["All", "Home", "Away", "Wins", "Draws", "Losses"];
    const StatLinks = [
      "Win-Draw-Lose",
      "WDL-Cards",
      "WDL-Corners",
      "Goals",
      "Cards",
      "Corners",
    ];
    const FutureLinks = ["Team", "Match", "Opponent"];
    let weeks = [];
    for (let index = 1; index < 39; index++) {
      weeks.push("Week " + index);
    }

    let iter_link = [];
    if (Links_ && Links_ === "PAST GAMES") {
      iter_link = PastLinks;
    }
    if (Links_ && Links_ === "STATISTICS") {
      iter_link = StatLinks;
    }
    if (Links_ && Links_ === "NEXT GAMES STATS") {
      iter_link = FutureLinks;
    }
    if (Links_ && Links_ === "COMMENTS") {
      iter_link = weeks;
    }
    let iter_link2 = [];
    return (
      <div className="mainFont margin-5down">
        {isSmallScreen || isMidScreen ? (
          <div>
            <div className=" left_div center_sides nav_scroller noscrolbar">
              {iter_link.map((item, index) =>
                Links_ === "STATISTICS" || Links_ === "PAST GAMES" ? (
                  <div
                    key={index}
                    className={`breadNav_light_second_small sidepad usemaxSpace ${
                      activeElement1 === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      setstatsToShow(item);
                      setstatsToShow3("All");
                      handleNavClick2(0);
                      handleNavClick3(0);
                      if (Links_ === "PAST GAMES") {
                        setstatsToShow2("Win-Draw-Lose");
                        SaveCatched({setstatsToShow2:"Win-Draw-Lose", setstatsToShow:item , setstatsToShow3:"All" })
                      } else {
                        setstatsToShow2("Full Time");
                        SaveCatched({setstatsToShow2:"Full Time", setstatsToShow:item, setstatsToShow3:"All"})
                      }
                      
                      if (Links_ === "STATISTICS" || Links_ === "PAST GAMES") {
                        showSecondSbar_({
                          target: { target: item, status: true },
                        });
                        
                        handleNavClick1(index);
                      }
                      
                    }}
                  >
                    <div className="tiny_font nowrap">{item}</div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`breadNav_light_second_small sidepad nav_scroller noscrolbar ${
                      activeElement1 === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      setstatsToShow(item);
                      SaveCatched({setstatsToShow:item})
                      handleNavClick1(index);
                    }}
                  >
                    <div className="tiny_font nowrap">{item}</div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <Container className="cont_ad margin-5down">
            <div className=" left_div center_sides nav_scroller noscrolbar">
              {iter_link.map((item, index) =>
                Links_ === "STATISTICS" || Links_ === "PAST GAMES" ? (
                  <div
                    key={index}
                    className={`breadNav_light_second_large sidepad ${
                      activeElement1 === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      setstatsToShow(item);
                      setstatsToShow3("All");
                      handleNavClick2(0);
                      handleNavClick3(0);
                      if (Links_ === "PAST GAMES") {
                        setstatsToShow2("Win-Draw-Lose");
                        SaveCatched({setstatsToShow2:"Win-Draw-Lose", setstatsToShow:item , setstatsToShow3:"All" })
                      } else {
                        setstatsToShow2("Full Time");
                        SaveCatched({setstatsToShow2:"Full Time", setstatsToShow:item , setstatsToShow3:"All" })
                      }
                      
                      if (Links_ === "STATISTICS" || Links_ === "PAST GAMES") {
                        showSecondSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClick1(index);
                      }
                    }}
                  >
                    <div className="nowrap">{item}</div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`breadNav_light_second_large sidepad ${
                      activeElement1 === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      setstatsToShow(item);
                      SaveCatched({setstatsToShow:item  })
                      handleNavClick1(index);
                    }}
                  >
                    <div className="nowrap">{item}</div>
                  </div>
                )
              )}
            </div>
          </Container>
        )}
      </div>
    );
  }
  function SecondLayerSubLinks({ Links_ }) {
    let StatSublink = ["Full Time", "First Half", "Second Half", "Either Half"];

    if (
      statsToShow === "Cards" ||
      statsToShow === "Goals" ||
      (statsToShow === "Corners" && Links_ === "STATISTICS")
    ) {
      StatSublink = ["Full Time", "First Half", "Second Half"];
    }
    if (Links_ === "PAST GAMES") {
      StatSublink = [
        "Win-Draw-Lose",
        "WDL-Cards",
        "WDL-Corners",
        "Goals",
        "Cards",
        "Corners",
      ];
    }
    //console.log(activeElement2);
    return (
      <div className="margin-5down">
        {isSmallScreen || isMidScreen ? (
          <div className=" left_div  center_sides nav_scroller noscrolbar">
            {StatSublink.map((item, index) => (
              <div
                key={index}
                className={`breadNav_light_second_small sidepad ${
                  activeElement2 === index
                    ? "div_active link forceblack"
                    : "dormant link forceblack"
                }`}
                onClick={() => {
                  setstatsToShow2(item);
                  SaveCatched({setstatsToShow2:item  })
                  handleNavClick2(index);
                }}
              >
                <div className="tiny_font nowrap">{item}</div>
              </div>
            ))}
          </div>
        ) : (
          <Container className="cont_ad margin-5down">
            <div className="left_div midfont center_sides nav_scroller noscrolbar">
              {StatSublink.map((item, index) => (
                <div
                  key={index}
                  className={`breadNav_light_second_large button_div sidepad ${
                    activeElement2 === index
                      ? "div_active link forceblack"
                      : "dormant link forceblack"
                  }`}
                  onClick={() => {
                    setstatsToShow2(item);
                    SaveCatched({setstatsToShow2:item  })
                    handleNavClick2(index);
                  }}
                >
                  <div className="nowrap">{item}</div>
                </div>
              ))}
            </div>
          </Container>
        )}
      </div>
    );
  }

  function ThirdLayerSubLinks({ Links_ }) {
    const StatSublink = ["All", "Home", "Away"];
    const league_list_ =
      useFixture?.league_years?.[String(league)]?.season_list;
    let leagueName_ = "";
    for (let index = 0; index < league_list_?.length; index++) {
      const element = league_list_?.[index];
      if (String(element?.id) === jointLeague) {
        leagueName_ = element?.year;
        setuseleagueName(leagueName_);
      }

      /* let iter_link2 = [];
      if (Links_ && Links_ === "PAST GAMES") {
        iter_link2 = StatSublink;
      } */
    }
    return (
      <div className="mainFont margin-5down">
        {isSmallScreen || isMidScreen ? (
          <div>
            <div className=" left_div center_sides">
              {StatSublink.map((item, index) => (
                <div
                  key={index}
                  className={`breadNav_light_second_small sidepad ${
                    activeElement3 === index
                      ? "div_active link forceblack"
                      : "dormant link forceblack"
                  }`}
                  onClick={() => {
                    setstatsToShow3(item);
                    SaveCatched({setstatsToShow3:item  })
                    handleNavClick3(index);
                  }}
                >
                  <div className="tiny_font">{item}</div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Container className="cont_ad margin-5down">
            <div>
              <div className=" left_div midfont center_sides ">
                {StatSublink.map((item, index) => (
                  <div
                    key={index}
                    className={`breadNav_light_second_large button_div ${
                      activeElement3 === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      setstatsToShow3(item);
                      SaveCatched({setstatsToShow3:item  })
                      handleNavClick3(index);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </Container>
        )}
      </div>
    );
  }
  const HandleYearClick = (item, league) => {
    ////console.log("clicked me before", item);
    ////console.log(item?.id, league);
    setJointLeague(item?.id);
    /////console.log(jointLeague);
    const league_list_ =
      useFixture?.league_years?.[String(league)]?.season_list;
    let leagueName_ = "";
    for (let index = 0; index < league_list_.length; index++) {
      const element = league_list_[index];
      if (String(element?.id) === String(item?.id)) {
        leagueName_ = element?.year;
        setuseleagueName(leagueName_);
      }

      ////console.log("clicked me after", leagueYears);
    }
  };
  const showFirstSbar_ = ({ target }) => {
    setshowFirstSbar(target);
    SaveCatched({setshowFirstSbar:target})
    ////console.log("reset showfirstbar", target);
  };
  const showSecondSbar_ = ({ target }) => {
    setshowSecondSbar(target);
    SaveCatched({setshowSecondSbar:target})
    ////console.log("reset showfirstbar", target);
  };
  const showThirdSbar_ = ({ target }) => {
    setshowThirdSbar(target);
    SaveCatched({setshowThirdSbar:target})
    ////console.log("reset showfirstbar", target);
  };

  function BreadLinks() {
    /* const leagues = useFixture?.data?.team_info?.league_info;
    const league = GetLeagueName(leagues, lg); */
    ////console.log(league, lg);
    const team_navs = [
      useFixture?.head_data?.team_country,
      useFixture?.league_years?.[league]?.league_name,
      useFixture?.head_data?.team_name,
    ];
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        {isSmallScreen || isMidScreen ? (
          <div>
            <div className=" left_div smallFont center_sides">
              {team_navs.map((item, index) => (
                <div key={index} className="breadNav_light">
                  <div className="tiny_font">
                    {item}
                    {"  >"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Container className="justify-center">
            <div className=" left_div center_sides">
              {team_navs.map((item, index) => (
                <div key={index} className="breadNav_light">
                  {item}
                  {"  >"}
                </div>
              ))}
            </div>
          </Container>
        )}
      </div>
    );
  }
  function FooterNav2(params) {
    // this  handles the fotter of the modal display
    //const team_navs = ["SUMMARY", "PAST GAMES", "STATISTICS", "PREDICTIONS"];
    const team_navs = ["SUMMARY", "PAST GAMES", "STATISTICS", "LEAGUE TABLES"];
    const league_list_ =
      useFixture?.league_years?.[String(league)]?.season_list;
    let leagueName_ = "";
    for (let index = 0; index < league_list_?.length; index++) {
      const element = league_list_?.[index];
      if (String(element?.id) === jointLeague) {
        leagueName_ = element?.year;
        setuseleagueName(leagueName_);
      }

      /* let iter_link2 = [];
      if (Links_ && Links_ === "PAST GAMES") {
        iter_link2 = StatSublink;
      } */
    }
    return (
      <div>
        {isSmallScreen || isMidScreen ? (
          <div>
            <hr />
            <div
              className=" center_div_distributed center_sides overflow-scroll noscrolbar"
              style={{
                marginTop: "-12px",
                whiteSpace: "nowrap",
              }}
            >
              {team_navs.map((item, index) => {
                if (item === "SUMMARY") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      } smallFont`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: false },
                        });
                        showThirdSbar_({
                          target: { target: item, status: false },
                        });
                        handleNavClick(index);
                      }}
                    >
                      <div className="tiny_font">{item}</div>
                    </div>
                  );
                } else if (item === "PAST GAMES") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        showThirdSbar_({
                          target: { target: item, status: false },
                        });
                        handleNavClick(index);
                        setstatsToShow("All");
                        setstatsToShow2("Win-Draw-Lose");
                        setstatsToShow3("All");
                        handleNavClick1(0);
                        handleNavClick2(0);
                        handleNavClick3(0);
                        SaveCatched({setstatsToShow:"All",setstatsToShow2:"Win-Draw-Lose",setstatsToShow3:"All"})
                      }}
                    >
                      <div className="tiny_font">{item}</div>
                    </div>
                  );
                } else if (item === "STATISTICS") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        showThirdSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClick(index);
                        setstatsToShow("Win-Draw-Lose");
                        setstatsToShow2("Full Time");
                        setstatsToShow3("All");
                        handleNavClick1(0);
                        handleNavClick2(0);
                        handleNavClick3(0);
                        SaveCatched({setstatsToShow:"Win-Draw-Lose",setstatsToShow2:"Full Time",setstatsToShow3:"All"})
                      }}
                    >
                      <div className="tiny_font">{item}</div>
                    </div>
                  );
                } else if (item === "NEXT GAMES STATS") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClick(index);
                        setstatsToShow("Team");
                        setstatsToShow2("Win-Draw-Lose");
                        setstatsToShow3("All");
                        handleNavClick1(0);
                        handleNavClick2(0);
                        handleNavClick3(0);
                        SaveCatched({setstatsToShow:"Team",setstatsToShow2:"Win-Draw-Lose",setstatsToShow3:"All"})
                      }}
                    >
                      <div className="tiny_font">{item}</div>
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className={`breadNav_light ${
                      activeElement === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      showFirstSbar_({
                        target: { target: item, status: true },
                      });
                      showThirdSbar_({
                        target: { target: item, status: false },
                      });
                      handleNavClick(index);
                      setstatsToShow("All");
                      setstatsToShow2("Win-Draw-Lose");
                      handleNavClick1(0);
                      handleNavClick2(0);
                      SaveCatched({setstatsToShow:"All",setstatsToShow2:"Win-Draw-Lose"})
                    }}
                  >
                    <div className="tiny_font">{item}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Container className="justify-center">
            <hr />
            <div
              className=" center_div_distributed center_sides overflow-scroll noscrolbar"
              style={{
                marginTop: "-12px",
                whiteSpace: "nowrap",
              }}
            >
              {team_navs.map((item, index) => {
                if (item === "SUMMARY") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: false },
                        });
                        showThirdSbar_({
                          target: { target: item, status: false },
                        });
                        handleNavClick(index);
                      }}
                    >
                      {item}
                    </div>
                  );
                } else if (item === "PAST GAMES") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        showThirdSbar_({
                          target: { target: item, status: false },
                        });
                        handleNavClick(index);
                        setstatsToShow("All");
                        setstatsToShow2("Win-Draw-Lose");
                        setstatsToShow3("All");
                        handleNavClick1(0);
                        handleNavClick2(0);
                        handleNavClick3(0);
                        SaveCatched({setstatsToShow:"All",setstatsToShow2:"Win-Draw-Lose",setstatsToShow3:"All"})
                      }}
                    >
                      {item}
                    </div>
                  );
                } else if (item === "STATISTICS") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        showThirdSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClick(index);
                        setstatsToShow("Win-Draw-Lose");
                        setstatsToShow2("Full Time");
                        setstatsToShow3("All");
                        handleNavClick1(0);
                        handleNavClick2(0);
                        handleNavClick3(0);
                        SaveCatched({setstatsToShow:"Win-Draw-Lose",setstatsToShow2:"Full Time",setstatsToShow3:"All"})
                      }}
                    >
                      {item}
                    </div>
                  );
                } else if (item === "NEXT GAMES STATS") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElement === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClick(index);
                        setstatsToShow("Team");
                        setstatsToShow2("Win-Draw-Lose");
                        setstatsToShow3("All");
                        handleNavClick1(0);
                        handleNavClick2(0);
                        handleNavClick3(0);
                        SaveCatched({setstatsToShow:"Team",setstatsToShow2:"Win-Draw-Lose",setstatsToShow3:"All"})
                      }}
                    >
                      {item}
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className={`breadNav_light ${
                      activeElement === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      showFirstSbar_({
                        target: { target: item, status: true },
                      });
                      showThirdSbar_({
                        target: { target: item, status: false },
                      });
                      handleNavClick(index);
                      setstatsToShow("All");
                      setstatsToShow2("Win-Draw-Lose");
                      handleNavClick1(0);
                      handleNavClick2(0);
                      SaveCatched({setstatsToShow:"All",setstatsToShow2:"Win-Draw-Lose"})
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </Container>
        )}
      </div>
    );
  }

  function TeamHead() {
    // this displays the main modal head of the teams
    const country = useFixture?.head_data?.team_country;
    const handleLegend = () => {
      if (showTableLegend) {
        setShowTableLegend(false);
      } else if (!showTableLegend) {
        setShowTableLegend(true);
      }
    };
    const HandleleagueClick = (Item) => {
      //console.log(Item);
      if (Item?.id) {
        setuseleagueName(() => Item?.year);
        setauxAskleague(() => Item.id);
        setJointLeague(() => Item.id);
      }
    };
    //console.log("teamstat", useFixture, askleague, 24556);
    return (
      <div className="margin-5down mainFont ">
        <Modal.Dialog className="rounded-3">
          <BreadLinks />

          <Modal.Body>
            {isSmallScreen || isMidScreen ? (
              <div>
                <div className="left_div center_sides">
                  <div className="mb-2 allpad">
                    <img
                      src={Team_image[String(team)]["image"]}
                      width="120"
                      height="100"
                      alt={useFixture?.team_info?.team_name}
                    />
                  </div>
                  <div className="">
                    {useFixture?.head_data?.team_name}
                    <h6>
                      {country && (
                        <ReactCountryFlag
                          countryCode={useFixture?.head_data?.country_iso}
                          svg
                          style={{
                            fontSize: "2em",
                            lineHeight: "2em",
                          }}
                          aria-label={country}
                        />
                      )}{" "}
                      {useFixture?.head_data?.team_country}
                    </h6>
                    <div className="center_div center_sides">
                      <div className="">Season :</div>
                      <div className="allpad">
                        <DropdownButton
                          title={useleagueName}
                          id="dropdown-basic-button"
                          size="xsm"
                          className=" small-button_2 sidepad_main"
                        >
                          {useFixture?.league_years?.[
                            askleague
                          ]?.season_list.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => HandleleagueClick(item)}
                            >
                              {item?.year}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Container>
                <div className="left_div">
                  <div md={3} sm={6} className="allpad">
                    <img
                      src={Team_image?.[String(team)]?.["image"]}
                      alt={useFixture?.team_info?.team_name}
                    />
                  </div>
                  <div md={9} sm={6} className="margin30down">
                    {useFixture?.head_data?.team_name}
                    <h6>
                      {country && (
                        <ReactCountryFlag
                          countryCode={useFixture?.head_data?.country_iso}
                          svg
                          style={{
                            fontSize: "2em",
                            lineHeight: "2em",
                          }}
                          aria-label={country}
                        />
                      )}{" "}
                      {useFixture?.head_data?.team_country}
                    </h6>
                    <div className="center_div center_sides">
                      <div className="">Season :</div>
                      <div className="allpad">
                        <DropdownButton
                          title={useleagueName}
                          id="dropdown-basic-button"
                          size="xsm"
                          className=" small-button_2 sidepad_main"
                        >
                          {useFixture?.league_years?.[
                            askleague
                          ]?.season_list.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => HandleleagueClick(item)}
                            >
                              {item?.year}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            )}
          </Modal.Body>
          <div>
            <FooterNav2 />
          </div>
        </Modal.Dialog>
      </div>
    );
  }

  function RouteStats({ Links_ }) {
    if (Links_ && Links_ === "PAST GAMES") {
      return (
        <DisplayPastGames
          Tab={statsToShow}
          Tab2={statsToShow2}
          small={isSmallScreen}
          mid={isMidScreen}
          team={team}
        />
      );
    }
    if (Links_ && Links_ === "SUMMARY") {
      ////console.log(useFixture);
      return (
        <DisplaySummary small={isSmallScreen} mid={isMidScreen} team={team} />
      );
    }
    if (Links_ && Links_ === "STATISTICS") {
      //console.log(useleagueName);

      return (
        <div>
          {!issubscribed ? (
            <div>
              <div className="margin30down mainFont">
                <div className="">
                  <div className="center_div infoFont margin-30down">
                    Subscribe to get access to our Advanced Statistics
                  </div>
                  <div className="center_div infoFont margin-30down">
                    We have done all the calculations so you do not have to
                  </div>
                  <div
                    className="link smallbuton  divorangeButton"
                    onClick={() => navigation("/pricing")}
                  >
                    Subscribe
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <TeamStatistics
                side={statsToShow3}
                half={statsToShow2}
                type={statsToShow}
                league={useleagueName}
                small={isSmallScreen}
                mid={isMidScreen}
                team={team}
              />
            </>
          )}
        </div>
      );
    }
    if (Links_ && Links_ === "LEAGUE TABLES") {
      return (
        <div className="">
          <LeagueTables
            League_list={league_lists}
            askleague1={auxAskleague}
            setaskleague={setauxAskleague}
            setJointLeague={HandleYearClick}
            setuseleagueName1={setuseleagueName}
            league_info_list={league_info_list}
            asking_team={team}
            raw_league={useFixture?.raw_league}
            country={useFixture?.head_data?.team_country}
            showYear={showYear}
            leagueYears1={leagueYears}
            disData1={disData}
            setleagueYears1={setleagueYears}
            setdisData={setdisData}
          />
        </div>
      );
    }
  }
  const handleNavClick = (id) => {
    setActiveElement(id);
    SaveCatched({setActiveElement:id})
  };
  const handleNavClick1 = (id) => {
    setActiveElement1(id);
    SaveCatched({setActiveElement1:id})
  };
  const handleNavClick2 = (id) => {
    setActiveElement2(id);
    SaveCatched({setActiveElement2:id})
  };
  const handleNavClick3 = (id) => {
    setActiveElement3(id);
    SaveCatched({setActiveElement3:id})
  };
  const league_lists = useFixture?.league_table;
  const league_info_list = useFixture?.league_years;
  ////console.log(league_info_list, useFixture);
  if (
    !useFixture ||
    !globalUser ||
    !userInfo?.user?.dataMode ||
    !league_info_list
  ) {
    return <SpinnerCustom myImage={Team_image?.[String(team)]?.["image"]} />;
  }
  const countryClick = {
    index: "",
    item: useFixture?.head_data?.team_country,
    clicker: "other",
  };
  ////console.log(useleagueName);
  const handleLegend = () => {
    if (showTableLegend) {
      setShowTableLegend(false);
    } else if (!showTableLegend) {
      setShowTableLegend(true);
    }
  };
  return (
    <div className="light">
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen} />}
      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        loading={loading}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen}
        isLogedin={isLogedin}
        issubscribed={issubscribed}
        setIsOpen={setIsOpen}
      />
      {isMidScreen || isSmallScreen ? (
        <IndexSubheads
          sidenavOpen={sidenavOpen}
          setSidenavOpen={setSidenavOpen}
          islgedin={isLogedin}
          issubed={issubscribed}
          setticketactive={setticketactive}
          setShows={setShows}
          type={"teams"}
        />
      ) : null}
      {isMidScreen || isSmallScreen ? (
        <div>
          {/* {isMidScreen || isSmallScreen ? (
            <div className="allpad">
              <Button
                className="webButton margin10down"
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              >
                Top Leagues
              </Button>
            </div>
          ) : null} */}
          {(isDropdownVisible && isMidScreen) ||
          (isDropdownVisible && isSmallScreen) ? (
            <Row>
              <Col md="6" sm="11" className="usemaxSpace center_div">
                <div className="allpad">
                  <Container className="cont_ad">
                    <LeagueNav
                      setticketactive={setticketactive}
                      setShows={setShows}
                      data={useSideNav}
                      countryClick={countryClick}
                      islgedin={isLogedin}
                      issubed={issubscribed}
                    />
                  </Container>
                </div>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <div className="allpad">
                <div className="light">
                  {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows}/>
                        </div>
                      )} */}
                  <div className="cont_ad">
                    {loading ? <Spinner /> : useFixture && <TeamHead />}

                    {showFirstSbar?.status === true && (
                      <FirstLayerSubLinks Links_={showFirstSbar?.target} />
                    )}

                    {showThirdSbar?.status === true && (
                      <ThirdLayerSubLinks Links_={showThirdSbar?.target} />
                    )}
                    {showSecondSbar?.status === true &&
                      showFirstSbar &&
                      ["STATISTICS", "PAST GAMES"].includes(
                        showFirstSbar.target
                      ) && (
                        <SecondLayerSubLinks Links_={showFirstSbar?.target} />
                      )}
                  </div>

                  <div className="cont_ad">
                    {(showFirstSbar?.status === true && (
                      <RouteStats Links_={showFirstSbar?.target} />
                    )) ||
                      (showFirstSbar?.target === "SUMMARY" && (
                        <RouteStats Links_={showFirstSbar?.target} />
                      ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Container>
          <Row>
            <Col md={3}>
              <div
                style={{ flex: "1", alignSelf: "flex-start" }}
                className="light"
              >
                <Container className="cont_ad">
                  <LeagueNav
                    setticketactive={setticketactive}
                    setShows={setShows}
                    data={useSideNav}
                    countryClick={countryClick}
                    islgedin={isLogedin}
                    issubed={issubscribed}
                  />
                </Container>
              </div>
            </Col>
            <Col md={9}>
              <div style={{ flex: "3.5", alignSelf: "flex-start" }}>
                <div className="light">
                  {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows}/>
                        </div>
                      )} */}
                  <Container className="cont_ad">
                    {loading ? <Spinner /> : useFixture && <TeamHead />}

                    {showFirstSbar?.status === true && (
                      <FirstLayerSubLinks Links_={showFirstSbar?.target} />
                    )}

                    {showThirdSbar?.status === true && (
                      <ThirdLayerSubLinks Links_={showThirdSbar?.target} />
                    )}
                    {showSecondSbar?.status === true &&
                      showFirstSbar &&
                      ["STATISTICS", "PAST GAMES"].includes(
                        showFirstSbar.target
                      ) && (
                        <SecondLayerSubLinks Links_={showFirstSbar?.target} />
                      )}
                  </Container>

                  <Container className="cont_ad">
                    {(showFirstSbar?.status === true && (
                      <RouteStats Links_={showFirstSbar?.target} />
                    )) ||
                      (showFirstSbar?.target === "SUMMARY" && (
                        <RouteStats Links_={showFirstSbar?.target} />
                      ))}
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <FooterAll />
      <FooterNav />
    </div>
  );
}

export default GetTeamPage;
