import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Stack,
} from "react-bootstrap";
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "../../css/LeagueSummaryMixedChart.css";
import Team_image from "../../images/team_images.json";

export default function MixedBarChart({
  data,
  league,
  lgNav,
  type,
  toolchartdict,
}) {
  const [teamCount, setTeamCount] = useState(3);
  const [teamCountl, setTeamCountl] = useState(3);
  const navigation = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []);

  const RenderTeam = ({ Team_, rank }) => {
    const own_team = parseInt(Object.keys(Team_)[0]);
    const TeamDataChart = data?.[own_team]?.[type]?.chart;
    const chartlabel = Object.keys(data?.[own_team]?.[type]?.chart);
    ////console.log(TeamDataChart);
    const tooltipData = data?.[own_team]?.[type]?.tooltip;
    class CustomDot extends React.Component {
      handlematchlick = (payload) => {
        ////console.log(payload);
        const day = payload?.day;
        const match_id = tooltipData?.[day]?.match?.matchID;
        const team2 = tooltipData?.[day]?.match?.awayID;
        const team1 = tooltipData?.[day]?.match?.homeID;
        const seas = " ";
        navigation("/match/" + match_id, {
          state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
        });
      };
      render() {
        const { cx, cy, payload } = this.props;
        if (payload?.day) {
          return (
            <circle
              cx={cx}
              cy={cy}
              r={3}
              fill="grey"
              className="link"
              stroke="none"
              onClick={() => this.handlematchlick(payload)}
            />
          );
        }
      }
    }

    const handlematchlick = (data) => {
      const day = data?.day;
      const m_id = tooltipData?.[day]?.match?.matchID;
      navigation("/match/" + m_id, { state: m_id });
    };

    const GettoolTip = ({ date, own_team, tooltipData }) => {
      tooltipData = tooltipData?.[date];

      const tooltiplabel = toolchartdict?.[type]?.goalsTool
        ?.slice(4)
        .slice(0, -1);
      ////console.log(tooltiplabel);
      ////console.log(date, tooltipData);
      return (
        <div>
          <div>
            <div className="tooltipbg">
              <Container className="justify-content-between align-items-center myContainer">
                <div>
                  <div>
                    <h6>{tooltipData?.date}</h6>
                  </div>
                </div>

                {tooltiplabel.map((item, index) => {
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <h6> {item}</h6>
                      </div>
                      <div className="center_div_distributed center_sides">
                        <div>
                          <h5>{tooltipData?.match?.homeName}</h5>
                        </div>
                        <div>
                          <h5>{tooltipData?.match?.[item]}</h5>
                        </div>
                        <div>
                          <h5>{tooltipData?.match?.awayName}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Container>
            </div>
          </div>
        </div>
      );
    };

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div>
            <GettoolTip
              date={label}
              tooltipData={tooltipData}
              own_team={own_team}
            />
          </div>
        );
      }

      return null;
    };
    const handleteamclick = ({ val, team_, league }) => {
      const team_info = {
        team: val,
        league: league,
        data: lgNav,
      };

      navigation("/team/" + team_, { state: team_info });
    };

    return (
      <div className="mainFont bold">
        <div>{rank}</div>
        <div
          className={`center_div ${
            !isSmallScreen ? "center_sides" : "center_straight"
          }`}
        >
          <div>
            <div className="center_div center_straight">
              <div>
                <img
                  src={Team_image?.[own_team]?.["image"]}
                  className="link"
                  onClick={() =>
                    handleteamclick({
                      val: own_team,
                      team_: data?.[own_team]?.winners?.team_meta?.team_name,
                      league: league,
                    })
                  }
                  alt={data?.[own_team]?.winners?.team_meta?.team_name}
                  style={{
                    width: "100px", // or any value appropriate for your design
                    height: "100px", // keep aspect ratio if not defined
                  }}
                />
              </div>

              <div className="center_div">
                {data?.[own_team]?.winners?.team_meta?.team_name}
              </div>
            </div>
          </div>

          <div style={{ width: "100%", height: "180px" }}>
            <ResponsiveContainer>
              <ComposedChart
                width={600}
                height={200}
                data={TeamDataChart}
                barGap={1}
                margin={{
                  top: 20,
                  right: -30,
                  bottom: 5,
                  left: -30,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={toolchartdict?.[type]?.goalschart?.[0]}
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                />
                <YAxis
                  yAxisId="left"
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  offset={10}
                  allowEscapeViewBox={{ x: false, y: true }}
                />

                <Bar
                  yAxisId="left"
                  dataKey={toolchartdict?.[type]?.goalschart?.[1]}
                  stackId="team"
                  barSize={20}
                  className="link"
                  onClick={(data) => handlematchlick(data)}
                  fill="#05a3a89d"
                />
                <Bar
                  yAxisId="left"
                  dataKey={toolchartdict?.[type]?.goalschart?.[3]}
                  stackId="team"
                  barSize={20}
                  className="link"
                  onClick={(data) => handlematchlick(data)}
                  fill="#02500f9d"
                />
                <Bar
                  yAxisId="left"
                  dataKey={toolchartdict?.[type]?.goalschart?.[4]}
                  stackId="opp"
                  barSize={20}
                  className="link"
                  onClick={(data) => handlematchlick(data)}
                  fill="#ee91069d"
                />
                <Bar
                  yAxisId="left"
                  dataKey={toolchartdict?.[type]?.goalschart?.[2]}
                  stackId="opp"
                  barSize={20}
                  className="link"
                  onClick={(data) => handlematchlick(data)}
                  fill="#ee3c06af"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  className="link"
                  dot={<CustomDot />}
                  dataKey={toolchartdict?.[type]?.goalschart?.[5]}
                  stroke="#19ee06af"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  className="link"
                  dot={<CustomDot />}
                  dataKey={toolchartdict?.[type]?.goalschart?.[6]}
                  stroke="#1c55cfaf"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  };
  const HandleSeeMoreTeam = (val, type) => {
    //let currValue = monthCount;
    //console.log("Was here",val, type,teamCount,teamCountl);
    if (val === "seemore" && teamCount > 2 && type === "w") {
      setTeamCount(teamCount + 3);
    } else if (val === "seemore" && teamCountl > 2 && type === "l") {
      setTeamCountl(teamCountl + 3);
    }
    if (val === "seeless" && teamCount > 5 && type === "w") {
      setTeamCount(teamCount - 3);
    } else if (val === "seeless" && teamCountl > 5 && type === "l") {
      setTeamCountl(teamCountl - 3);
    }
  };

  function SeeMore({ sender }) {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div className="usemaxSpace">
        <div className="center_div_distributed center_sides">
          <div
            className="link forceblack"
            onClick={() => HandleSeeMoreTeam("seeless", sender)}
          >
            <div className=" smallbuton">{"< Less"}</div>
          </div>
          <div
            className="link forceblack"
            onClick={() => HandleSeeMoreTeam("seemore", sender)}
          >
            <div className=" smallbuton">{"More >"}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className=" mainFont bottomdivLine">
      <div className="margin-30down">
        <div>
          <div>
            <div>
              <div className="center_div bold  paragrph_font">Top {type}</div>

              <div className="usemaxSpace">
                {Object.keys(data?.[type]).map((item, index) => {
                  if (index < teamCount) {
                    return (
                      <>
                        <div key={index} className="rounded-3">
                          <div>
                            <RenderTeam
                              
                              Team_={data?.[type]?.[item]}
                              rank={item}
                            />
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="allpad">
              <SeeMore sender={"w"} />
            </div>
          </div>
          {/* <div>
            <div style={{ flex: "6", alignSelf: "flex-start" }}>
              <div>
                <div className="center_div bold  paragrph_font">
                  {" "}
                  Bottom {type}
                </div>
                <div>
                  <div>
                    {Object.keys(data?.[type])
                      .reverse()
                      .map((item, index) => {
                        if (index < teamCountl) {
                          return (
                            <>
                              <div className="rounded-3">
                                <div>
                                  <RenderTeam
                                    key={item}
                                    Team_={data?.[type]?.[item]}
                                    rank={index + 1}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>
                </div>
                <div className="allpad">
                  <SeeMore sender={"l"} />
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div
          className={`margin10down ${
            isSmallScreen ? null : "center_div_distributed center_Sides"
          }`}
        >
          <div>
            <div className="center_div">
              <div className="team1st" />
              T1 fh {type.split(" ")[0]}
            </div>
          </div>

          <div>
            <div className="center_div">
              <div className="team2nd" />
              T2 2h {type.split(" ")[0]}
            </div>
          </div>

          <div>
            <div className="center_div">
              {" "}
              <div className="opp1st" />
              Opp fh {type.split(" ")[0]}
            </div>
          </div>
          <div>
            <div className="center_div">
              {" "}
              <div className="opp2nd" />
              Opp 2h {type.split(" ")[0]}
            </div>
          </div>
          <div>
            <div className="center_div">
              <div className="line1" />
              {toolchartdict?.[type]?.goalschart?.[5]}
            </div>
          </div>
          <div>
            <div className="center_div">
              <div className="line2" />
              {toolchartdict?.[type]?.goalschart?.[6]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
