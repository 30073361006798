import React, { useState } from "react";

export const GlobalUserContext = React.createContext();

export const UserInfoContext = ({ children }) => {
  const [globalUser, setGlobalUser] = useState({});
  const updateGlobalUser = (newUser) => {
    setGlobalUser(newUser);
  };
  return (
    <GlobalUserContext.Provider value={{ globalUser, updateGlobalUser }}>
      {children}
    </GlobalUserContext.Provider>
  );
};
