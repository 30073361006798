import React, { useRef } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  useClipboard,
} from "mdb-react-ui-kit";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBPopconfirm,
  MDBPopconfirmMessage,
} from "mdb-react-ui-kit";

import { useNavigate, useLocation, useMatch } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";

import { UpdateLocal } from "../manageStorage/MaintainLocal";
import "../../css/ThemeCss.css";
import { FiThumbsUp } from "react-icons/fi";
import { IoHeart, IoNavigateCircleOutline } from "react-icons/io5";
import { convertUnixToTime } from "../Utils";
import { FcApproval } from "react-icons/fc";
import { FaRegComment, FaShare } from "react-icons/fa";
import CopyButton from "../CopyLinkButton";
import care from "../../images/unisex.jpg";
import bgimage from "../../images/uniback.jpg";
import { MdOutlineChecklistRtl, MdOutlineContentCopy } from "react-icons/md";
import sportyImage from "../../images/sportyBet.png";
import { MdEditNotifications } from "react-icons/md";
import { io } from "socket.io-client";
import { MdNotificationAdd } from "react-icons/md";
import CollectComments from "../CommentComponents/CommentsInput";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;
export default function PreviewTicket({ userData, ticketID, type }) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const userinfo = location.state?.userinfo || {};
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isFollowingTicket, setIsFollowingTicket] = useState(false);
  const [isFollowingTipstar, setIsFollowingTipstar] = useState(false);
  const [socketInstance, setSocketInstance] = useState();
  const navigation = useNavigate();

  const tic_data = location?.state?.ticketInfo || null;
  const [useMatches, setUseMatches] = useState({});
  const [useLike, setUseLike] = useState();
  const [useHeart, setUseHeart] = useState();
  const [useViews, setUseviews] = useState();
  const [useFollow, setUseFollow] = useState();
  const [useFollowAuthor, setUseFollowAuthor] = useState();
  const [useType, setuseType] = useState(type);
  const [useTicketID, setuseTicketID] = useState(ticketID);
  const [isclickedShare, setisclickedShare] = useState(false);
  const [authorProfile, setUserProfile] = useState();
  const textRef = useRef(null);
  const [authorBackground, setUserBackground] = useState();

  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  useEffect(() => {}, [useLike, useHeart]);
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      let res = "";
      ////console.log(ticketID);
      const queryData = {
        ticketID: useTicketID,
        type: useType,
      };

      try {
        res = await axios
          .post(`${API_URL}/get_tickets_min`, JSON.stringify(queryData), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch((error) => {});
      } catch (error) {
        alert(error.message);
        //Errorlog("error in daily fixtures", error, "fetch search data");
      }
      ////console.log(res)
      if (res?.data?.status === "success") {
        setUseMatches(res?.data?.data?.ticket_meta);
        ////console.log(res?.data?.data?.ticket_meta);
        setUseHeart(() => new Set(res?.data?.data?.ticket_meta?.hearts));
        setUseLike(() => new Set(res?.data?.data?.ticket_meta?.likes));
        setUseviews(() => new Set(res?.data?.data?.ticket_meta?.views));
        setUseFollow(() => new Set(res?.data?.data?.ticket_meta?.followers));
        setUseFollowAuthor(
          () => new Set(res?.data?.data?.ticket_meta?.authorFollowers)
        );
        if (
          res?.data?.data?.ticket_meta?.authorFollowers?.includes(
            userData?.user?.email
          )
        ) {
          setIsFollowingTipstar(true);
        }
        if (
          res?.data?.data?.ticket_meta?.followers?.includes(
            userData?.user?.email
          )
        ) {
          setIsFollowingTicket(true);
        }
      }
    };
    fetchData();
  }, [userData?.user]);
  useEffect(() => {
    // Make sure BackSocket and BackSocketToken are defined before creating socket instance
    if (BackSocket && BackSocketToken) {
      const socket2 = io(BackSocket, {
        auth: {
          token: BackSocketToken,
        },
      });

      // Error handling
      socket2.on("connect_error", (err) => {
        //console.log(err instanceof Error);
        //console.log(err.message);
      });

      setSocketInstance(socket2);
    }
  }, [BackSocket, BackSocketToken]); // Updated dependencies array

  useEffect(() => {
    ////console.log(globalUser?.user?.email, StorageBucket);
    async function fetchBackground() {
      try {
        const response = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${useMatches?.author}_background`
        ).catch((error) => {
          "";
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob to a string
        setUserBackground(base64data);
      } catch (error) {
        //console.error(error);
      }
      try {
        const response2 = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${useMatches?.author}_profile`
        ).catch((error) => {
          "";
        });
        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }
        const blob2 = await response2.blob();
        const base64data2 = await blob2.text(); // Convert the blob to a string
        setUserProfile(base64data2);
        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [useMatches?.author]);
  ////console.log(useMatches);
  if (!useMatches && !userData) {
    return null;
  }
  const handleCopyClick = () => {
    if (textRef.current) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(useMatches?.booking_code)
          .then(() => alert("Copied to clipboard"))
          .catch((err) => console.error("Could not copy text: ", err));
      } else {
        ////console.log("Clipboard API is not available");
      }
    }
  };
  const handleteamclick = ({ val, team_, league }) => {
    const team_info = {
      team: val,
      league: league,
    };
    /* window.open("/team/" + team_ + "?team=" + val + "&league=" + league + "&data=" + useFixture?.league_nav); */
    navigation("/team/" + team_, { state: team_info });
  };
  const handleLike = async () => {
    let oldlikes = new Set();
    let newlikes = new Set();
    let queryData = {};
    try {
      queryData = {
        type: useType,
        ticketID: useTicketID,
        liker: userData?.user?.email,
      };
      oldlikes.add(userData?.user?.email);

      if (useLike) {
        newlikes = new Set([...useLike]);
        newlikes.add(oldlikes[0]);
        setUseLike(() => new Set(newlikes));
      } else {
        newlikes = oldlikes;
        setUseLike(() => newlikes);
      }
      const res = await axios
        .post(`${API_URL}/add_like`, JSON.stringify(queryData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});
    } catch (error) {
      ////console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }
  };
  const handleHeart = async () => {
    let oldhearts = new Set();
    let newhearts = new Set();
    let queryData = {};
    try {
      queryData = {
        type: useType,
        ticketID: useTicketID,
        heartr: userData?.user?.email,
      };
      oldhearts.add(userData?.user?.email);

      if (useHeart) {
        newhearts = new Set([...useHeart]);
        newhearts.add(oldhearts[0]);
        setUseHeart(() => new Set(newhearts));
      } else {
        newhearts = oldhearts;
        setUseHeart(() => newhearts);
      }
      const res = await axios
        .post(`${API_URL}/add_heart`, JSON.stringify(queryData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});
    } catch (error) {
      ////console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }
  };
  let ticstatus = "";
  let unixTimeNow = Math.floor(Date.now() / 1000);
  let minutesToAdd = 150; // three 150 minutes
  let secondsToAdd = minutesToAdd * 60; // convert minutes to seconds

  const ticketLast = useMatches?.last_match_time + secondsToAdd;
  if (ticketLast > unixTimeNow) {
    ticstatus = "Incomplete";
  } else {
    ticstatus = "Complete";
  }
  const handleTicketClick = () => {
    const ticketInfo = {
      type: useType,
      ticketID: useTicketID,
    };
    navigation("/ShowTickets", { state: ticketInfo });
  };
  const HandleReshare = () => {
    setisclickedShare(() => true);
  };
  ////console.log(useMatches,userData);
  const handleMessageClick = (data) => {
    const currTime = Math.floor(new Date().getTime() / 1000);
    const partner = {
      time: currTime + 1,
      name: data?.name,
      email: data?.author,
    };
    navigation("/myMessages", { state: partner });
  };

  ////console.log(useMatches)

  const handleFollowTicketClick = () => {
    let ticketType;
    if (useMatches?.private_ticket !== "") {
      ticketType = "private";
    } else if (useMatches?.followers_only !== "") {
      ticketType = "followers";
    } else {
      ticketType = "public";
    }

    if (socketInstance) {
      socketInstance.emit("followTicket", {
        data: {
          type: ticketType,
          ticketID: useTicketID,
          state: !isFollowingTicket,
        },
        liker: userData?.user?.email,
      });
      setIsFollowingTicket((prev) => !prev);
    }
  };
  const handleFollowTipstartClick = () => {
    if (socketInstance) {
      socketInstance.emit("followTipster", {
        data: {
          email: useMatches?.author,
          state: !isFollowingTipstar,
        },
        liker: userData?.user?.email,
      });
      setIsFollowingTipstar((prev) => !prev);
    }
  };

  return (
    <div className=" usemaxSpace">
      <MDBCard alignment="center" className="mainFont usemaxSpace">
        {isclickedShare ? (
          <CopyButton
            type={useType}
            data={useTicketID}
            setisclickedShare={setisclickedShare}
            isclickedShare={isclickedShare}
            setisclickedPreShare={"setisclickedPreShare"}
            header={"Reshare Ticket"}
          />
        ) : null}
        <MDBCardHeader>
          <MDBRow
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: `url(${authorBackground ?? bgimage})`,
            }}
          >
            <MDBCol
              lg="6"
              md="8"
              className={`mb-2  pt-2 flex ${
                isSmallScreen ? "center_straight" : "center_sides"
              }`}
            >
              <div className="center_div">
                <img
                  src={authorProfile ?? care}
                  className="rounded-circle float-none float-md-start me-4 mb-3 link"
                  alt=""
                  style={{
                    width: "150px",

                    border: "1px solid hsl(0, 0%, 98%)",
                  }}
                  onClick={() =>
                    navigation("/publicProfile", {
                      state: {
                        publicProfile:
                          useMatches?.publicProfile ?? useMatches?.author,
                      },
                    })
                  }
                />
              </div>
              <div
                className="highlightdiv allpad"
                style={{ minWidth: !isSmallScreen ? "300px" : null }}
              >
                <h1
                  className=" midfont_exl bold link forceblack"
                  onClick={() =>
                    navigation("/publicProfile", {
                      state: {
                        "publicProfile":
                          useMatches?.publicProfile ?? useMatches?.author,
                      },
                    })
                  }
                >
                  {useMatches?.name}
                </h1>
                <p className="text-muted">{useMatches?.phrase}</p>
                <p className="text-muted">
                  <div className="center_div center_sides">
                    <div>
                      <FcApproval />
                    </div>
                    <div>New Member</div>
                  </div>
                </p>
              </div>
            </MDBCol>
            <MDBCol
              lg="6"
              md="8"
              className="mb-4 mb-md-0 text-center text-lg-end allpad"
            >
              {useMatches?.author === userData?.user?.email ? null : userData
                  ?.user?.email ? (
                <>
                  <MDBBtn
                    rounded
                    color="secondary"
                    active
                    onClick={() => handleMessageClick(useMatches)}
                  >
                    <div style={{ textTransform: "none" }}>Message</div>
                  </MDBBtn>
                  &nbsp;&nbsp;&nbsp;
                  <MDBBtn rounded>
                    <div
                      onClick={() => handleFollowTipstartClick()}
                      style={{ textTransform: "none" }}
                    >
                      {isFollowingTipstar ? "Following" : "Follow Tips"}
                    </div>
                  </MDBBtn>
                </>
              ) : (
                <>
                  <MDBPopconfirm
                    btnClassName="me-1 "
                    placement="top"
                    btnChildren={
                      <div style={{ textTransform: "none" }}>Message</div>
                    }
                    color="secondary"
                  >
                    <MDBPopconfirmMessage>
                      You are not logged in.{" "}
                      <a className="link" onClick={() => navigation("/SignIn")}>
                        Login
                      </a>{" "}
                      to send messages?
                    </MDBPopconfirmMessage>
                  </MDBPopconfirm>
                  &nbsp;&nbsp;&nbsp;
                  <MDBPopconfirm
                    btnClassName="me-1 "
                    placement="top"
                    btnChildren={
                      <div style={{ textTransform: "none" }}>Follow Tips</div>
                    }
                  >
                    <MDBPopconfirmMessage>
                      You are not logged in.{" "}
                      <a className="link" onClick={() => navigation("/SignIn")}>
                        Login
                      </a>{" "}
                      to follow this tipstar?
                    </MDBPopconfirmMessage>
                  </MDBPopconfirm>
                </>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody>
          {useMatches?.booking_code && (
            <div>
              <div className="center_div center_sides">
                <div className="nobgbutton buttonBorders-blue allpad-2">
                  {useMatches?.bookie_name === "SportyBet" ? (
                    <div>
                      <img
                        className=""
                        src={sportyImage}
                        alt={useMatches?.bookie_name}
                        width="60"
                        height="25"
                      />
                    </div>
                  ) : (
                    <div>?</div>
                  )}
                </div>
                <div
                  className="allpad"
                  onClick={() => (window.location.href = useMatches?.ShareURL)}
                >
                  <div className="inactiveButton allpad-2 buttonBorders-blue link forceblack">
                    {useMatches?.booking_code}
                  </div>
                </div>
                <div
                  ref={textRef}
                  onClick={() => {
                    handleCopyClick();
                  }}
                  className="allpad link "
                >
                  <MdOutlineContentCopy size={25} />{" "}
                </div>
              </div>
            </div>
          )}

          <MDBRow style={{ background: "#d7dfe0" }} className="">
            <MDBCol size="6">
              <div className="center_div center_sides allpad">
                <div>No of games :</div>&nbsp;&nbsp;
                <div>{useMatches?.ngames}</div>
              </div>
            </MDBCol>
            <MDBCol size="6">
              <div className="center_div center_sides allpad">
                <div>Total Odds:</div>&nbsp;&nbsp;
                <div>{useMatches?.t_odds}</div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ background: "" }} className="">
            <MDBCol size="6">
              <div
                className={`center_div ${
                  isSmallScreen ? "center_straight" : "center_sides allpad"
                }`}
              >
                <div style={{ marginRight: "2px" }}>Ticket Status : </div>
                <div> {ticstatus}</div>
              </div>
            </MDBCol>
            <MDBCol size="6">
              <div className="center_div center_sides allpad">
                <div style={{ marginRight: "2px" }}>Result:</div>
                <div
                  className={
                    useMatches?.status === "won"
                      ? "timerfontgreen"
                      : useMatches?.status === "lost"
                      ? "timerfontred "
                      : null
                  }
                >
                  {useMatches?.status}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ background: "#d7dfe0" }} className="">
            <MDBCol size="6">
              <div
                className={`center_div ${
                  isSmallScreen ? "center_straight" : "center_sides allpad"
                }`}
              >
                <div style={{ marginRight: "2px" }}>Created time :</div>
                <div>{convertUnixToTime(useMatches?.created_time, "")}</div>
              </div>
            </MDBCol>
            <MDBCol size="6">
              <div
                className={`center_div ${
                  isSmallScreen ? "center_straight" : "center_sides allpad"
                }`}
              >
                <div style={{ marginRight: "2px" }}>last game time :</div>
                <div>{convertUnixToTime(useMatches?.last_match_time, "")}</div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        <MDBCardFooter className="forcegrey mainFont">
          <div>
            <div className="center_div_distributed center_sides ">
              <div
                className={
                  isSmallScreen
                    ? "flex center_straight marginright-10"
                    : "center_div marginright-10"
                }
              >
                <div className={isSmallScreen ? "center_div" : "marginright-5"}>
                  {useLike?.size}
                </div>{" "}
                <div>likes</div>
              </div>
              <div
                className={
                  isSmallScreen
                    ? "flex center_straight marginright-10"
                    : "center_div marginright-10"
                }
              >
                <div className={isSmallScreen ? "center_div" : "marginright-5"}>
                  {useHeart?.size}{" "}
                </div>{" "}
                <div>hearts</div>
              </div>
              <div
                className={
                  isSmallScreen
                    ? "flex center_straight marginright-10"
                    : "center_div marginright-10"
                }
              >
                <div className={isSmallScreen ? "center_div" : "marginright-5"}>
                  {useViews?.size}{" "}
                </div>
                <div>views</div>
              </div>
              <div
                className={
                  isSmallScreen
                    ? "flex center_straight marginright-10"
                    : "center_div marginright-10"
                }
                onClick={() => handleTicketClick()}
              >
                <div className={isSmallScreen ? "center_div" : "marginright-5"}>
                  {useMatches?.comments == 0 ? "no" : useMatches?.comments}{" "}
                </div>
                <div>{useMatches?.comments > 1 ? " comments" : "comment"}</div>
              </div>
              <div
                className={
                  isSmallScreen
                    ? "flex center_straight marginright-10"
                    : "center_div marginright-10"
                }
              >
                <div className={isSmallScreen ? "center_div" : "marginright-5"}>
                  {useFollow?.size}{" "}
                </div>
                <div>{useFollow?.size > 1 ? "tailers" : "tailer"}</div>
              </div>
            </div>
            <div className="horizontal_line_black allpad" />
            <div className="center_div_distributed center_sides">
              <div
                className="center_div center_straight  allpad link forceblack"
                onClick={() => handleLike()}
              >
                <div>
                  <FiThumbsUp style={{ color: "blue" }} />
                </div>{" "}
                <div>{" Like"}</div>
              </div>{" "}
              <div
                className="center_div center_straight  allpad link forceblack"
                onClick={() => handleHeart()}
              >
                <div>
                  <IoHeart style={{ color: "red" }} />
                </div>
                <div>{" Heart"}</div>
              </div>
              <div
                className="center_div center_straight  allpad link forceblack"
                onClick={() => handleFollowTicketClick()}
              >
                {isFollowingTicket ? (
                  <MdEditNotifications size={30} style={{ color: "orange" }} />
                ) : (
                  <MdNotificationAdd size={30} style={{ color: "green" }} />
                )}{" "}
                {isFollowingTicket ? "Tailing" : "Tail"}
              </div>{" "}
              <div
                className="center_div center_straight  allpad link forceblack"
                onClick={() => HandleReshare()}
              >
                <FaShare size={20} style={{ color: "skyblue" }} /> {"Share "}
              </div>{" "}
              <div
                className="center_div center_straight  allpad link forceblack"
                onClick={() => handleTicketClick()}
              >
                <IoNavigateCircleOutline size={20} style={{ color: "green" }} />
                {"View Ticket "}
              </div>
            </div>
            <div className="horizontal_line_black allpad" />
            <CollectComments
              owner={{ name: useMatches?.name, email: useMatches?.author }}
              parent={{ site: "/ShowTickets", object: useTicketID, type: type }}
              paticipant={{
                name:
                  userData?.user?.tipstarDetails?.tipstarName ??
                  userData?.user?.firstname,
                email: userData?.user?.email,
              }}
              userData={userData}
              targetPage={"/ShowTickets"}
              targetObject={ticketID}
            />
          </div>
        </MDBCardFooter>
      </MDBCard>
    </div>
  );
}
