import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  Button,
  Col,
  Container,
  Form,
  Row,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import FooterAll from "./Footer";
import EntryHeader from "./EntryHeader";
import { BsPatchQuestion } from "react-icons/bs";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { isSameMinute } from "date-fns";
import FooterNav from "./SmallScreenNav";
import { SideNavPage } from "./Utils";

const API_URL = process.env.REACT_APP_API_URL;

export function DiaplayHoTo({ handleSubClick, loggedin }) {
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    
    
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  },[])
  const navigation = useNavigate();
  const handlesubclick = () => {
    navigation("/pricing");
  };
  const handlehowtoclick = () => {
    navigation("/howto");
  };

  return (
    <div
      style={{
        fontFamily: "serif",
      }}
    >
      <Row>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            marginTop: "50px",
            fontSize: "50px",
            fontWeight: "bold",
            color: "rgba(3, 43, 53, 0.959)",
          }}
        >
          How It Works
        </div>
      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row style={{ marginTop: "20px" }}>
            <Col md={12} lg={6}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Choose a Subscription
                </div>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    maxWidth: "85%",
                  }}
                >
                  Subscription process is very simple, you would be done in few
                  steps. We offer very affordable subscriptions because we value
                  our users and are commited to helping people make better
                  betting decisions.
                </div>
              </Row>
            </Col>
            <Col>
              <Row>
                <div
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Analyze Top Probablities
                </div>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    maxWidth: "85%",
                    
                  }}
                >
                  Use our tool to analyze options and probabilities already
                  calculated and estimated based on historical data. The primary
                  aim of our website is to deploy all tools necessary in helping
                  its users perform indepth analysis.{" "}
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <Row>
                <div
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  Pick Best Options
                </div>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    maxWidth: "85%",
                    
                  }}
                >
                  Pick the best options based on our percentatge probabilities,
                  your analysis and/or your best judgement. Our percentage
                  probablities are computed in such a way that it removes all
                  biases and would return probabilities based on historical
                  data. If its a percentage > 80% then there is a high change of
                  occurence.
                </div>
              </Row>
            </Col>
            <Col>
              <Row>
                <div
                  style={{
                    fontSize: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  Smile to the Bank
                </div>
              </Row>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    maxWidth: "85%",
                  }}
                >
                  Place your Bet on any Bookie of your choice and Win!
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              fontSize: isSmallScreen ? "17px":"27px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <Col style={{ maxWidth: "35%" }}>
              <div
                onClick={() => {
                  loggedin ? handleSubClick("sub") : handlesubclick();
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "green",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  color: "white",
                  cursor: "pointer",
                }}
                onMouseOver={(e) => {
                  e.target.style.color = "orange";
                }} // change color on hover
                onMouseOut={(e) => {
                  e.target.style.color = "white";
                }} // revert color after hover
              >
                {isSmallScreen || isMidScreen ? "3 Days Trial" :"Start 3 Days Trial Now"}
              </div>
            </Col>

            <Col style={{ maxWidth: "40%" }}>
              <div
                onClick={() => handlehowtoclick()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "orange",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  cursor: "pointer",
                  color: "black",
                }}
                onMouseOver={(e) => {
                  e.target.style.color = "#551A8B";
                }} // change color on hover
                onMouseOut={(e) => {
                  e.target.style.color = "black";
                }} // revert color after hover
              >
                {isSmallScreen || isMidScreen ? "Chart Help":"How to Read Charts and KPIs"}
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
}
const Questions = {};
export function FAQs() {
  const navigation = useNavigate();
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    
    
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  },[])
  return (
    <div style={{ fontFamily: "serif" }}>
      <Row>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            marginTop: "50px",
            fontSize: "50px",
            fontWeight: "bold",
            color: "rgba(3, 43, 53, 0.959)",
          }}
        >
          FAQs
        </div>
      </Row>
      <div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; What is Bet
                Informatics?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col  md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col  md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Bet Informatics is a Sport Analytics website dedicated to
                  providing its users all the possible tools needed to perform
                  indept analysis on a Sport, League, Match or Team. We make
                  sure you never place a bet blindly ever again.{" "}
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Is Bet Informatics
                Legit?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Bet informatics is a legal website that abide by all rules
                  there is when it comes to Sport Betting, Internet Privacy and
                  User Transparency.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Can I trust the
                Analysis from Bet Informatics?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  We have well qualified data analyst and software engineers
                  working round the clock to make sure the results we provide
                  are correct,accurate and effective. We also make sure our
                  users can easily read our charts. Our data and calculations
                  can be compared with other sport websites anytime. To learn
                  more on how to use our charts you can check{" "}
                  <a className="link" onClick={() => navigation("/howto/")}>
                    here{" "}
                  </a>
                  .
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Where does Bet
                Informatics get their data from?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  We cruurently partner with FootyStats.org for all our raw
                  sport data. The agrrigations, statistics, and predictions, are
                  calculated solely by BetInformatics.com
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; How are Bet
                Informatics predictions generated?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  We train AI models for each sport, league, team, match and bet
                  options. Each prediction is a result gotten from multiple AI
                  algorithms which are aggrigated together for optimal result.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; What sports and bet
                options does Bet Informatics cover?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Currently, We cover only Soccer Analysis, other sports would
                  be added soon. We offer analytics for most betoptions like
                  1X2, Goals, BTS, WEHs, Cards, Corners, Possessions etc.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; What Competitions
                does Bet Informatics cover?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  For Each Sport, We cover all major and minor leagues, cups,
                  internatiuonal competitions which are offered by our Data our
                  data providers.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; How do I subscribe
                to Bet Informatics
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  You can click{" "}
                  <a className="link" onClick={() => navigation("/pricing")}>
                    {" "}
                    here
                  </a>{" "}
                  to check out our subscription plans with three days trial.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Can I cancel my Bet
                informatics Subscription?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Yes, you can cancel your subscription anytime. Click{" "}
                  <a
                    className="link"
                    onClick={() => navigation("/userprofile")}
                  >
                    here
                  </a>{" "}
                  to go to your profile and cancel your subscription. If your
                  are not logged in please log in{" "}
                  <a className="link" onClick={() => navigation("/signin")}>
                    here
                  </a>
                  .
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Can I get a refund
                after Bet Informatics subscription payments?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Yes, our website is entirely free to use, no subscription needed. When you subscribe to a tipster, if it is a standard subscription (managed by betinformatics.com), the tips are prorated. If you do not make the agreed profit (or there is a negative margin) by the end of agreed period, you can request a full refund. If you make profit but but not the agreed profit margin, your subscription would roll over to next period. If the agreed profit margin is reached according to the supscription terms, there is no refund. If it is a custom subscription, refund policy is set by the tipster.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Can I upgrade my Bet
                Informatics subscription?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Yes you can. If you are on a one month plan, you would be
                  charged full amount for the new upgrade of which your new
                  feature starts immidiately. If you are in a three month plan,
                  you would be chaged for upgrade of the remaining days in your
                  durrent plan. Also, your upgrade features start immediately.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; Can I downgrade my
                Bet Informatics subscription?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Yes you can. Typically you would contact us{" "}
                  <a className="link" onClick={() => navigation("/contact us")}>
                    here
                  </a>{" "}
                  by sending us a message to change your subscription type. You
                  woud be charged the new subscription price in the next billing
                  cycle. You would continue the current subscription till the
                  new subscription kicks in.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BsPatchQuestion size={30} /> &nbsp; &nbsp; How long does Bet
                Informatics support response take?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={1}>
                  <RiQuestionAnswerLine size={30} />
                </Col>
                <Col md={11} style={{ marginLeft: isSmallScreen || isMidScreen ? "":"-30px" }}>
                  Normally, in 24 hours you would hear back from us. If the
                  questions/comment does not require a reply from us, we would
                  not reply. Remember that there are actual Human reading your
                  messages, plase be nice.
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
function HowItWorks() {
  
  const defaultForm = { email: "", password: "" };
  const [isOpen, setIsOpen] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
 

  
  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  


  return (
    <div className="light">
     {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
        <IndexHeader setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}/>
      
      <div style={{ background: "white" }}>
        <Container>
          <DiaplayHoTo />
          <FAQs />
        </Container>
      </div>

      <FooterAll page={"HowItWorks"} />
      <FooterNav />
    </div>
  );
}

export default HowItWorks;
