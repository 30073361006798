import { Card, Container, Row, Col, ListGroup } from "react-bootstrap";
import IndexHeader from "./IndexHeaders";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import FooterAll from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import EntryHeader from "./EntryHeader";
import bgimage from "../images/cupwinners.png";
import baller from "../images/baller.png";
import { SideNavPage } from "./Utils";
import FooterNav from "./SmallScreenNav";
const Rookie_afPRice = process.env.REACT_APP_R_AFF_PRICE;
const Rookie_worPrice = process.env.REACT_APP_R_WORLD_PRICE;
const Enthusiast_afPRice = process.env.REACT_APP_E_AFF_PRICE;
const Enthusiast_worPrice = process.env.REACT_APP_E_WORLD_PRICE;

export function Subscription({ handleSubClick }) {
  const navigation = useNavigate();
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    
    
  };
 

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      ////console.log("inside indexpage_useeffect", ckUser?.data?.user);
      if (
        ckUser &&
        ckUser?.result === "retrieved" &&
        ckUser?.data &&
        ckUser?.data?.user
      ) {
        updateGlobalUser(ckUser?.data?.user);
        setUserInfo(ckUser?.data?.user);
      } else {
        setUserInfo("Guest");
      }
    };

    fetchData();
  }, []);
  if(!userInfo){
    return null
  }
  ////console.log(userInfo)
  const plans = [
    {
      title: "Free",
      subtype: "Free",
      afsubtype: "$0 due today",
      afprice: "-",
      
      price: "$-",
      features: [
        "Match, Team and League History Analytics",
        "Easy acess tickets for research tracking",
        "Daily games on demand with probablities and current form",
        "Free forever",
      ],
      styles_top: {
        background: "rgba(123, 216, 240, 0.808)",
        width: "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "20px" },
    },
    {
      title: "Rookie",
      type: "One month",
      subtype: "$29.99 due after 3 days trial",
      price: "$29.99",
      paymentAmount: 29.99,
      afsubtype: "N15,000 due after 3 days trial",
      afprice: "N15,000",
      afpaymentAmount: 15000,
      WorldstripePrice: Rookie_worPrice,
      AfristripePrice: Rookie_afPRice,
      features: [
        "Past and future game Analytics",
        "Advanced Statistics",
        "All game options including Cards and Corners",
        "Best Option Search page",
        "Automatic subscription renewal",
      ],
      styles_top: {
        background: "rgba(123, 216, 240, 0.808)",
        width: "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "20px" },
    },
    {
      title: "Enthusiast",
      type: "Three months",
      price: "$24.99",
      subtype: "$74.97 due after 3 days trial",
      paymentAmount: 74.97,
      afprice: "N10,000",
      afsubtype: "N30,000 due after 3 days trial",
      afpaymentAmount: 30000,
      WorldstripePrice: Enthusiast_worPrice ,
      AfristripePrice: Enthusiast_afPRice,
      features: [
        "Past and future game Analytics",
        "Advanced Statistics",
        "All game options including Cards and Corners",
        "Best Option Search page",
        "Automatic subscription renewal",
        "Renews after three months",
      ],
      styles_top: {
        background: "rgba(6, 194, 241, 0.808)",
        width: "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "12px" },
    },
   /*  {
      title: "Professional",
      type: "Three months",
      subtype: "$95.97 due after 3 days trial",
      price: "$31.99",
      paymentAmount: 95.97,
      afprice: "N15,000",
      afsubtype: "N45,000 due after 3 days trial",
      afpaymentAmount: 45000,
      WorldstripePrice: "price_1OfpSKG6BtEwIzwZcOkfhMUa",
      AfristripePrice: "price_1OfqfVG6BtEwIzwZJLDoKNhd",
      features: [
        "Past and future game Analytics",
        "Advanced Statistics",
        "All game options including Cards and Corners",
        "Best Option Search page",
        "Daily Game alart in E-mail/Social Media",
        "Automatic subscription renewal",
        "Renews after three months",
      ],
      styles_top: {
        background: "rgba(3, 63, 131, 0.808)",
        width: "25rem",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center", // Centers the text
        fontSize: "25px",
        fontWeight: "bolder",
        color: "rgba(241, 245, 250, 0.959)",
      },
      styles_bottom: { paddingBottom: "6px" },
    }, */
  ];

  return (
    <div style={{ marginBottom: "0px" }}>
      <Container
        style={{
          display: "flex",
          marginTop: "100px",
          overflow: "auto",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row
            style={{
              color: "white",
              maxWidth: "65%",
              background: "rgba(3, 33, 70, 0.959)",
              marginBottom: "40px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "serif",
                  fontSize:isSmallScreen? "15px": isMidScreen? "30px":"40px",
                  fontWeight: "bolder",
                }}
              >
                SUBSCRIPTION TYPES
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "serif",
                }}
              >
              
                {isSmallScreen ? "We offer two subscription types at the moment, with just a little differences between them.":"We offer two subscription types at the moment, with just a little differences between them. We provide very affordable pricing to enable our users have easy access to sport analytics."}
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "serif",
                }}
              >
                
              </div>
            </Row>
          </Row>

          <div className={`${isSmallScreen? "center_straight": "center_sides" } center_div`}>
            {plans.map((plan, index) => (
              <div  className="" style={{margin:"10px"}}>
                <Card
                  className="h-100"
                  style={{
                    border: "none",
                    width: "25rem",
                    background: "rgba(222, 234, 250, 0.959)",
                    fontFamily: "serif",
                  }}
                >
                  <Card.Body
                    variant="top"
                    style={{ ...plan?.styles_top, height: "60px" }}
                  >
                    {plan.title}
                  </Card.Body>
                  <Card.Body variant="primary">
                    <Card.Title
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "40px", fontWeight: "bold" }}>
                        {userInfo?.user?.currCountry === "Nigeria" ? plan.afprice:plan.price}
                      </div>
                      <div>/month</div>
                    </Card.Title>
                    <Card.Subtitle
                      className="mb-2 text-muted"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "13px",
                      }}
                    >
                      {plan.type}
                    </Card.Subtitle>

                    <Card.Text>
                      {plan.features.map((feature, idx) => (
                        <li
                          key={idx}
                          style={{
                            ...plan?.styles_bottom,
                            listStyleType: "none",
                            paddingLeft: "1em",
                          }}
                        >
                          <span
                            style={{
                              marginRight: "10px",
                              color: "green",
                            }}
                          >
                            ✓
                          </span>{" "}
                          {/* This will be your custom dot */}
                          {feature}
                        </li>
                      ))}
                    </Card.Text>

                    <Card.Body>
                      <div className="text-center">
                        <Card.Text style={{ fontSize: "13px" }}>
                          {userInfo?.user?.currCountry === "Nigeria" ? plan.afsubtype:plan.subtype}
                        </Card.Text>
                        <Card.Link
                          variant="bottom"
                          href="#"
                          className="btn btn-primary me-2"
                          onClick={() => handleSubClick(plan)}
                        >
                          Select Plan
                        </Card.Link>
                      </div>
                    </Card.Body>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
          <div
            style={{
              maxWidth: isSmallScreen ? "90%" :"70%",
              background: "white",
              marginBottom: "0px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              fontFamily: "serif",
              fontStyle: "italic",
            }}
          >
            <div className="center_div center_straight">
              <div
               
              >
                You must be over the age of 21 to use this website.
              </div>
            
              <div
               
              >
                You are fully and solely responsible for the bets you decide to
                place using this website.
              </div>
            
              <div
                
              >
                All subscriptions are final, there are no refunds after the
                three days trial. Check &nbsp;
                <a className="link" onClick={() => navigation("/TAC")}>
                  {" "}
                  terms and conditions
                </a>{" "}
                
              </div>
            </div>
          </div>
        </Row>
      </Container>
      
    </div>
  );
}
function Pricing() {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [showFooter, setShowFooter] = useState(false);
  const [userInfo, setUserInfo] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      ////console.log("inside indexpage_useeffect", ckUser?.data?.user);
      if (
        ckUser &&
        ckUser?.result === "retrieved" &&
        ckUser?.data &&
        ckUser?.data?.user
      ) {
        updateGlobalUser(ckUser?.data?.user);
        setUserInfo(ckUser?.data?.user);
      } else {
        setUserInfo("Guest");
        ////console.log("user not logged in");
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    function handleScroll() {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowFooter(isAtBottom);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!globalUser) {
    return null;
  }
  const handleSubClick = (plan) => {
    if (globalUser.user.firstname === "Guest") {
      navigation("/signin");
    } else if (!globalUser.user.firstname) {
      navigation("signin");
    } else {
      navigation("/payments", {
        state: { plan: plan, userinfo: userInfo },
      });
    }
  };
  return (
    <div style={{ overflow: "auto" }}>
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
        <IndexHeader setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}/>
  
      <div
        style={{
          overflow: "auto",
          backgroundImage: `url(${bgimage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Subscription handleSubClick={handleSubClick} />
      </div>

      {/* <div
        style={{
          overflow: "auto",
          maxHeight: "50%",
          backgroundImage: `url(${baller})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        sger
      </div> */}

      <FooterAll />
      <FooterNav />
    </div>
  );
}

export default Pricing;
