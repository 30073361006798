import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import care from "../../images/unisex.jpg";
import {
  convertUnixToTime,
  timeDifference,
  timestampDifference,
} from "../Utils";
import axios from "axios";
import DisplayInnerTopComments from "./DisplayInnerTopComments";
import Dicplay2xInnerComments from "./Display2XInnerCompinent";
import { FcLike } from "react-icons/fc";
const API_URL = process.env.REACT_APP_API_URL;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export default function DicplayInnerComments(props) {
  const [useCommentTop, setUseCommentTop] = useState();
  const [authorBackground, setUserBackground] = useState();
  const [displayFull, setDisplayFull] = useState();
  const [event, setevent] = useState();
  const [reloadPResent, setReladPresent]=useState("true")
  const [useliked,setLiked] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      let res;
      ////console.log(queryData);
      if (!props?.commentId || !props?.collection || !props?.db) {
        ("");
      } else {
        let querryData = {
          db: props?.db,
          object: props?.collection,
          _id: props?.commentId,
        };
        try {
          res = await axios
            .post(`${API_URL}/getComments`, JSON.stringify(querryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      }
      if (res?.data?.status === "success") {
        setUseCommentTop(() => res?.data?.data);
        setevent(() => props?.mainData?._id);
      }
    };
    fetchData();
  }, [props?.targetObject, props?.commentId,props?.reloadChat,reloadPResent]);
  useEffect(() => {
    ////console.log(globalUser?.user?.email, StorageBucket);
    async function fetchBackground() {
      try {
        const response = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${useCommentTop?.data?.ownerDetails?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob to a string
        setUserBackground(base64data);
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [useCommentTop?.data?.ownerDetails]);

 /*  useEffect(() => {
    
    this?.props?.socketInstance?.on(String(event), (data) => {
      //console.log("entered for reply here, while listenning at ", String(event));
      handleUpdate(data);
    });
    return () => {
    //console.log("was here but probably not ran",event)
      this?.props?.socketInstance?.off(String(event));
    };
  }, [props?.socketInstance,event]);
  const handleUpdate = (data) => {
    props?.setUseComment((prev) => {
        let comments = prev
        for (let index = 0; index < comments?.length; index++) {
            const element = comments?.[index];
            if(element?.data?.chilren?._id === event){
                comments.data.chilren.push(data?.comments?.data)
                break; 
            }
        }
        return comments
    });
  }; */

  const handleReplyClick = () => {
    let res = { name: "godwin", email: "test@gmail.com" };
    props?.setCommentType((prev) => {
      if (prev === "reply") {
        props?.setReplyContent(props?.originalOwner);
        return "comment";
      } else {
        props?.setReplyContent({
          name: useCommentTop?.data?.ownerDetails?.name,
          email: useCommentTop?.data?.ownerDetails?.email,
          _id: useCommentTop?._id,
        });
        return "reply";
      }
    });
  };

  let querryData = {
    db: props?.targetDb,
    object: props?.targetObject,
  };
  ////console.log(useCommentTop)
  const handleLikeClick = (item,data=null) => {
    //console.log("clicked liked")
    let datause;
    if (data){
        datause=data
    }else{
        datause = useCommentTop
    }
    if(datause  && props?.socketInstance){

        props?.socketInstance.emit("likeComments", {
            data: datause,
            liker: props?.userData?.user?.email,
        });

        setUseCommentTop((prev)=>{
            prev["data"]["likes"].push(props?.userData?.user?.email)

            
            return prev
        })
        setReladPresent((prev)=>{
            if (prev ==="true"){
                return "false"
            }else{
                return "true"
            }
        })

    }
}
  
  if (!props?.commentId || !props?.collection || !props?.db ) {
    return;
  }
  //console.log(props?.userData);
  return (
    <div key={props?.index}>
      {displayFull ? (
        <>
          {props?.mainData?.data?.chilren
            ?.sort((a, b) => b?.time - a?.time)
            ?.map((item, index) => {
              return (
                <>
                  <div key={index}>
                    <DisplayInnerTopComments
                      db={props?.db}
                      collection={props?.collection}
                      commentId={item?.comment_id}
                      index={index}
                      reloadChat={props?.reloadChat}
                      userData={props?.userData}
                      handleLikeClick={handleLikeClick}
                      socketInstance={props?.socketInstance}
                      setCommentType={props?.setCommentType}
                      setReplyContent={props?.setReplyContent}
                      originalOwner={props?.originalOwner}
                    />
                  </div>
                </>
              );
            })}
        </>
      ) : (
        <>
          {props?.rcomments > 1 && (
            <div
              className="link tiny_font bold left_div allpad"
              onClick={() => setDisplayFull(() => true)}
            >
              {"Show " +
                (props?.rcomments - 1) +
                " more" +
                (props?.rcomments > 2 ? " replies" : " reply")}
            </div>
          )}
          <div
            key={""}
            className="d-flex justify-content-start w-100 margin10down"
          >
            <img
              src={authorBackground ?? care}
              className="rounded-circle me-2"
              style={{ width: "30px", height: "30px" }}
              alt={props?.visitor?.name}
            />
            <div style={{ minWidth: "200px" }}>
              <div className="left_div">
                <p
                  className="p-1 bg-light  shadow-1 shadow-1 rounded_div"
                  style={{ maxWidth: "533px" }}
                >
                  <small className="left_div link tiny_font">
                    {useCommentTop?.data?.ownerDetails?.name ?? "spectator"}
                  </small>

                  <small
                    className=" float text-dark mt-4"
                    dangerouslySetInnerHTML={{
                      __html: useCommentTop?.data?.message,
                    }}
                  ></small>
                </p>
              </div>
              <div className=" tiny_font removespave-15top  center_sides">
                <div className="left_div">
                  <div className="">
                    {timestampDifference(
                      useCommentTop?.updated_at,
                      Date.now() / 1000
                    )}
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {useCommentTop?.data?.likes?.includes(props?.userData?.user?.email) || useliked ? <>
              <div className="link"><FcLike /></div>
            
              </> : <div
                className="link"
                onClick={() => {handleLikeClick(props?.index);setLiked(true)}}
              >
                Like
              </div>}
                  &nbsp;&nbsp;&nbsp;
                  <div className="link bold" onClick={() => handleReplyClick()}>
                    Reply
                  </div>
                </div>
                <div className="right_div_main removespave-20top">
                  {useCommentTop?.data?.likes?.length > 0 ? <div className="">
                    {useCommentTop?.data?.likes?.length} {useCommentTop?.data?.likes?.length > 1 ? " likes" : " like"}
                  </div> : null}
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
