import React, { useContext } from "react";

import axios from "axios";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { getToggleButtonGroupUtilityClass } from "@mui/material";
import Switch from "@mui/material/Switch";
import { IoTicketOutline } from "react-icons/io5";
import "../css/ThemeCss.css";
import { LuMenuSquare } from "react-icons/lu";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

export default function DisplayTicket({ islgedin, issubed }) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [isActive, setIsActive] = useState(false);
  const [isTicketNo, setIsTicketNo] = useState("");

  useEffect(() => {
    const tickets = globalUser?.user?.Tickets?.state;
    let curious = false
    if (tickets){
        for (let index = 0; index < Object?.keys(tickets && tickets)?.length; index++) {
            const element = Object?.keys(tickets)[index];
      
            if (tickets?.[element]) {
              setIsActive(() => true);
              curious = true
              setIsTicketNo(() => element);
            }
            
          }
    }
    
    if(!curious){
        setIsActive(() => false);
      }
  }, [globalUser]);
  const HandleTicketClick = () => {};


  return (
    <div>
      {isActive ? <>
        <div
          //onClick={() => HandleTicketClick}
          className="center_div center_sides link forceblack mainFont"
        >
          <div className="allpad">
            {globalUser?.user?.Tickets?.[isTicketNo]?.name}
          </div>
          <div className="divorangeButton">
            &nbsp;
            {
              globalUser?.user?.Tickets?.[isTicketNo]?.matches.length
            }
            &nbsp;
          </div>
        </div>
        </>:<><div className="center_div center_sides link forceblack mainFont">
          Activate Ticket !
        </div></>}
    </div>
  );
}
