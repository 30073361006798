import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import "../css/Signin.css";
import "../css/ThemeCss.css";
import FooterAll from "./Footer";
import { IoIosLogIn } from "react-icons/io";
import { FaGoogle } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import userlog from "../images/user.svg";
import EntryHeader from "./EntryHeader";
import { Location } from "./indexPage";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import IndexSubheads from "./IndexSubheader";
import { SideNavPage } from "./Utils";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const google_login = process.env.REACT_APP_GOOGLE_LOGIN;

function SignIn() {
  const navigation = useNavigate();
  const defaultForm = { email: "", password: "" };
  const [formData, setFormData] = useState(defaultForm);
  const location = useLocation();
  const clickedSub = location.state?.plan || null;
  const [useProcess, setUseProcess] = useState(clickedSub);
  const [showFooter, setShowFooter] = useState(false);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [curr_Country, setCountry] = useState(Location());
  const [isFailPass, setIsFailPass] = useState("false");
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [Shows, setShows] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  ////console.log("in signin", globalUser);
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  if (globalUser?.user?.firstname && globalUser?.user?.firstname !== "Guest") {
    navigation("/");
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSignUpClick = () => {
    if (!useProcess) {
      navigation("/Signup");
    } else {
      navigation("/Signup", {
        state: { plan: useProcess },
      });
    }
  };
  const handleInstagramLoginClick = () => {
    if (!useProcess) {
      navigation("/Signup");
    } else {
      navigation("/Signup", {
        state: { plan: useProcess },
      });
    }
  };
  const handleGoogleLoginClick = () => {
    if (!useProcess) {
      navigation("/Signup");
    } else {
      navigation("/Signup", {
        state: { plan: useProcess },
      });
    }
  };
  const UpdateUser =async(token)=>{
    try {
      const res = await axios
        .post(`${API_URL}/AutoLogin`, JSON.stringify({token:token}), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});

        //console.log(res)
        if(res?.data?.status === true){
          const userData = {user:res?.data?.userinfo}
          userData["user"]["theme"] = {
            background: "light",
            outerTableboarder: "outer-border-light",
            stripeTable: "striped-table-light",
            h6: "_h6-light",
          },
        updateGlobalUser(userData);
        UpdateLocal({ action: "save", data: userData });
        }
        navigation("/");
    } catch (error) {
      //console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }

  }
  const handleLoginClick = async (event) => {
    if (formData.email === "" || formData.password === "") {
      setIsFailPass(() => "empty");
      return; // Add a return statement here
    }

    try {
      const res = await axios.post(`${API_URL}/val_user`, formData);
      const data = res.data;
      ////console.log(data.userinfo);
      let userinfo = data.userinfo;

      if (data.status === true) {
        if (!useProcess) {
          let now = new Date();
          let senttime = Math.floor(now.getTime() / 1000);
          let fexptime = new Date(now.getTime() + 6 * 60000);
          let exptime = Math.floor(fexptime.getTime() / 1000);
          const datatoencode = {
            user_name: userinfo?.firstname,
            user_email: [userinfo?.email],
            senttime: senttime,
            exptime: exptime,
            session: userinfo?.auth?._id,
          };
          const encoder = await axios.post(`${API_URL}/pushtemp`, {
            type: "save",
            tostore: datatoencode,
          });
          ////console.log(encoder);
          const Emaildata = {
            user_name: userinfo?.firstname,
            user_email: [userinfo?.email],
            subject: "Email Verification",
            send_mail: "info@betinformatics.com",
            sub_name: "Bet Informatics",
            message_type: "emailVerification",
            links: [`${WEB}/verifyEmail?data=${encoder?.data?.savedData}`],
          };
          if (userinfo?.verifyEmail) {
            navigation("/", { state: { userinfo } });
          } else {
            const ckUser = await UpdateLocal({
              action: "save",
              data: { user: userinfo },
            });
            navigation("/promptverifyemail", {
              state: { plan: useProcess, userinfo: userinfo },
            });

            const res = await axios.post(`${API_URL}/send-mail`, Emaildata);
          }
        } else {
          userinfo["user"]["curr_Country"] = curr_Country;
          navigation("/payments", {
            state: { plan: useProcess, userinfo: userinfo },
          });
          const ckUser = await UpdateLocal({
            action: "save",
            data: { user: userinfo },
          });
        }
      } else {
        setIsFailPass(() => "fail");
        //toast.error("Email or Password is incorrect");
        return; // Add a return statement here
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  const handleForgetPass = () => {
    navigation("/getemail");
  };
  /* function GoogleSignIn() {
    useEffect(() => {
      // Add the script tag
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/platform.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      // Add the meta tag
      const meta = document.createElement("meta");
      meta.name = "google-signin-client_id";
      meta.content = google_login;
      document.head.appendChild(meta);

      return () => {
        document.body.removeChild(script);
        document.head.removeChild(meta);
      };
    }, []);
    function onSignIn(googleUser) {
      var profile = googleUser.getBasicProfile();
      //console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
      //console.log("Name: " + profile.getName());
      //console.log("Image URL: " + profile.getImageUrl());
      //console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
    }

    return (
      <div>
        <div class="g-signin2" data-onsuccess="onSignIn">
          {" "}
          Login With Google
        </div>
      </div>
    );
  } */

  return (
    <div
      style={{
        background: "rgba(11, 38, 71, 0.959)",
      }}
    >
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
      />
      {isMidScreen || isSmallScreen ? (
        <IndexSubheads
          sidenavOpen={sidenavOpen}
          setSidenavOpen={setSidenavOpen}
          setticketactive={setticketactive}
          setShows={setShows}
          type={"match"}
        />
      ) : null}

      <Container
        className="d-flex align-items-center justify-content-center "
        variant="light"
        style={{
          background: "white",
          marginTop: "5%",
          fontFamily: "serif",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          marginBottom: "30px",
          width: isSmallScreen ? "90%" : "60%",
          height: "90%",
        }}
      >
        <div style={{}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={userlog}
              alt="profile"
              style={{ width: "50%", height: "50%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Member Login
          </div>
          {isFailPass == "fail" ? (
            <div
              className="allpad"
              style={{ color: "red", fontFamily: "serif", fontSize: "13px" }}
            >
              Password or Email not correct
            </div>
          ) : isFailPass == "empty" ? (
            <div
              className="allpad"
              style={{ color: "red", fontFamily: "serif", fontSize: "13px" }}
            >
              {" "}
              Please enter Email and Password
            </div>
          ) : null}
          <Form
            onKeyDown={(e) => {
              setIsFailPass(() => "typing");
              if (e.key === "Enter") {
                handleLoginClick();
              }
            }}
          >
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalEmail"
            >
              <Form.Control
                type="email"
                placeholder="Email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalPassword"
            >
              <Form.Control
                type="password"
                placeholder="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Row>
              <div className="margin-10down">
                Forget password?{" "}
                <span className="link" onClick={() => handleForgetPass()}>
                  {" "}
                  Reset here{" "}
                </span>
              </div>
            </Row>
            <Row>
              <div className="margin-10down usemaxSpace center_div">
                <GoogleOAuthProvider clientId={google_login}>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      
                      UpdateUser(credentialResponse?.credential)
                    }}
                    onError={() => {
                      //console.log("Login Failed");
                    }}
                  />
                  
                </GoogleOAuthProvider>
                
                </div>
            </Row>
            <Row>
              <Col>
                <div
                  className="link"
                  onClick={() => handleLoginClick()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginClick();
                    }
                  }}
                  style={{
                    background: "green",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    color: "white",
                    borderBottomRightRadius: "10px",
                    height: "4vh",
                    width: "100%",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "orange";
                  }} // change color on hover
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                >
                  <IoIosLogIn style={{ scale: "inherit" }} />
                  &nbsp; Login
                </div>
              </Col>
              <Col>
                <div
                  className="link"
                  onClick={() => handleSignUpClick()}
                  style={{
                    background: "rgba(6, 172, 184, 0.76)",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    height: "4vh",
                    width: "100%",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "orange";
                  }} // change color on hover
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                >
                  <FaRegIdCard style={{ scale: "inherit" }} /> &nbsp; Sign Up
                </div>
              </Col>
            </Row>
          </Form>
          <div style={{ marginBottom: "40px" }}></div>
        </div>
      </Container>
      <ToastContainer position="bottom-left" />
      <FooterAll page={"signin"} />
    </div>
  );
}

export default SignIn;
