import React, { useContext, useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { io } from "socket.io-client";
import axios from "axios";
import { MDBBadge, MDBBtn, MDBSideNav } from "mdb-react-ui-kit";
import { RiMenuFoldFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import care from "../images/unisex.jpg";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import { BiNavigation } from "react-icons/bi";

import { GetImagesCloud, timestampDifference } from "./Utils";
import { Spinner } from "react-bootstrap";
import { SpinnerCustom } from "./Spinner";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;

export default function Notifications(props) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [socketInstance, setSocketInstance] = useState();
  const [useNotification, setUseNotification] = useState();
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  useEffect(() => {
    // Make sure BackSocket and BackSocketToken are defined before creating socket instance
    if (BackSocket && BackSocketToken) {
      const socket2 = io(BackSocket, {
        auth: {
          token: BackSocketToken,
        },
      });
      setSocketInstance(socket2);
    }
  }, [BackSocket, BackSocketToken, globalUser?.user]); // Updated dependencies array

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let res;

      let querryData = {
        email: globalUser?.user?.email,
      };
      try {
        res = await axios
          .post(`${API_URL}/getNotifications`, JSON.stringify(querryData), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch((error) => {});
      } catch (error) {
        alert(error.message);
        //Errorlog("error in daily fixtures", error, "fetch search data");
      }

      if (res?.data?.status === "success") {
        setUseNotification(() => res?.data?.data);
        setLoading(false);
      }
    };
    fetchData();
  }, [globalUser?.user?.email]);
  /* useEffect(() => {
    const event = globalUser?.user?.email;
    if (socketInstance) {
      socketInstance.on(event, (data) => {
        ////console.log("got the partner messages", data);
        handleUpdate(data);
        //setUseChat([...useChat, data?.chatMessage]);
      });
    }
    "removeNotice"
    return () => {
      socketInstance?.off(event);
    };
  }, [socketInstance]); */

  const handleUpdate = (data) => {};
  const DisplayIndNotification = (props) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
      async function fetchImage() {
        const img = await GetImagesCloud(props?.usedata?.email, "profile");
        setImage(img);
      }
      fetchImage();
    }, [props?.usedata?.email]);
    //console.log(image);

    const handleRemoveNotice = (email, id) => {
      //console.log(email, id);
      if (socketInstance) {
        socketInstance.emit("removeNotice", {
          email: email,
          id: id,
        });
      }
    };

    return (
      <div>
        <MDBCard>
          <MDBCardBody className="usemaxSpace">
            <MDBCardText>
              <div className="center_div center_sides usemaxSpace">
                <div className="left_div center_straight">
                  <img
                    src={image ?? care}
                    className="rounded-circle float-none float-md-start "
                    alt=""
                    style={{
                      width: "80px",

                      border: "1px solid hsl(0, 0%, 98%)",
                    }}
                  />
                  <div className="center_div tiny_font allpad">
                    {timestampDifference(
                      props?.usedata?.time,
                      Date.now() / 1000
                    ) + " ago"}
                  </div>
                </div>
                <div className="left_div center_straight allpad">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {props?.usedata?.header.split(" ").map((word, index) =>{ 
                      const words = props?.usedata?.header.split(" ");
                      return(
                      <span key={index}>
                        {index === words.length - 1 && word === "lost" ? <b className="timerfontred">{word}</b> : index === words.length - 1 && word === "won" ? <b className="timerfontgreen">{word}</b> : index === words.length - 1 && word === "lost" ? <b className="timerfontred">{word}</b> : index === 0 ? <b>{word}</b> : word} &nbsp;
                      </span>
                    )})}
                  </div>
                  <div className="center_div bold">
                    <div>{props?.usedata?.body}</div>
                  </div>
                </div>
                <div onClick={() => {}}>
                  <MDBBtn
                    size="sm"
                    onClick={() => {
                      handleRemoveNotice(
                        globalUser?.user?.email,
                        props?.usedata?.id
                      );
                      if (props?.usedata?.type === "private_link") {
                        if (props?.usedata?.objectlink === "/match/") {
                          navigation("/match/" + props?.useNavigate?.email, {
                            state: {
                              match_id: props?.useNavigate?.email,
                              team1: team1,
                              team2: team2,
                              seas: seas,
                            },
                          });
                        }
                      } else {
                        window.location.href = props?.usedata?.objectlink;
                      }
                    }}
                  >
                    <div>
                      <BiNavigation size={25} />
                    </div>
                  </MDBBtn>
                </div>
              </div>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
        <br />
      </div>
    );
  };

  const ShowNotification = (props) => {
    const navigation = useNavigate();
    ////console.log(props?.isOpen);
    const handleRemoveNotice = (email, id) => {
      if (socketInstance) {
        socketInstance.emit("removeNotice", {
          email: email,
          id: id,
        });
      }
    };

    return (
      <div>
        <MDBSideNav
          right
          backdrop={false}
          open={props?.basicOpen}
          style={{
            zIndex: 9999999,
            position: "absolute",
            width: "400px",
            maxHeight: "800px",
          }}
        >
          <div
            className="ripple d-flex justify-content-center py-4 mb-3 "
            style={{ borderBottom: "2px solid #f5f5f5" }}
            data-mdb-ripple-color="primary"
          >
            <MDBBtn
              size="sm"
              outline
              color="primary"
              onClick={() => props?.setBasicOpen(false)}
            >
              Close
            </MDBBtn>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="center_div center_sides">
              <div className="allpad">
                <IoMdNotificationsOutline size={25} />
              </div>
              <div className="bold midfont">Notifications</div>
            </div>
          </div>
          <div className="center_div center_straight mainFont tiny_font_md scrol_div">
            {loading ? (
              <Spinner />
            ) : (
              props?.data?.data
                .sort((a, b) => b?.time - a?.time)
                ?.map((item, index) =>
                  item?.email === globalUser?.user?.email ? (
                    handleRemoveNotice(item?.email, item?.id)
                  ) : (
                    <div key={index}>
                      <DisplayIndNotification usedata={item} index={index} />
                    </div>
                  )
                )
            )}
          </div>
        </MDBSideNav>
      </div>
    );
  };

  //console.log(useNotification);

  return (
    <div
      className="allpad "
      onClick={() => props?.setBasicOpen(!props?.basicOpen)}
    >
      {props?.basicOpen && (
        <ShowNotification
          data={useNotification}
          basicOpen={props?.basicOpen}
          setBasicOpen={props?.setBasicOpen}
        />
      )}
      <div className="position-relative ">
        <MDBBtn
          color="light"
          className="shadow-0 p-0 d-block "
          style={{ minWidth: "14px" }}
        >
          <IoMdNotificationsOutline size={25} />{" "}
        </MDBBtn>
        {!props?.basicOpen && !useNotification?.readStatus && (
          <MDBBadge color="danger" pill className="position-absolute top-0 p-1">
            {useNotification?.data?.length}
          </MDBBadge>
        )}
      </div>
    </div>
  );
}
