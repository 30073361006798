import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
} from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";

import Team_image from "../../images/team_images.json";
import "../../css/LeaguesTable.css";
import { Errorlog } from "../Utils";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
function Get_years(league_info_list) {
  const years = {};
  ////console.log(league_info_list);
  for (let lt_1 = 0; lt_1 < league_info_list?.length; lt_1++) {
    const seasons = league_info_list?.[lt_1]["season"];
    const id_season = getDictWithHighestYear(seasons)["id"];
    ////console.log(id_season);
    const year_list = [];
    for (let item = 0; item < seasons.length; item++) {
      const year_joined = String(seasons[item]["year"]);
      const year_split = year_joined.slice(0, 4) + "/" + year_joined.slice(4);
      year_list.push({ year: year_split, id: seasons[item]["id"] });
    }
    years[String([id_season])] = year_list;
  }
  return years;
}
function getDictWithHighestYear(dictList) {
  //this function is used to loop through the season list inside the leagues dict to //get the most recent season and its ID
  if (!dictList || dictList.length === 0) {
    return null;
  }

  let maxYear = 0;
  let maxYearDict = null;

  for (let dataDict of dictList) {
    let year = dataDict["year"] || 0;

    // Extract the year from the given integer range
    year = year % 10000;

    if (year > maxYear) {
      maxYear = year;
      maxYearDict = dataDict;
    }
  }

  return maxYearDict;
}
const DisplayData = ({ data, compID, o_team }) => {
  const navigation = useNavigate();
  const handleteamclick = ({ val, team_, league }) => {
    const team = val;
    navigation("/team/" + team_, { state: { team, league } });
  };

  let ownTeam = "decoy";
  if (o_team === data["id"]) {
    ownTeam = "ownteam";
  }
  ////console.log(data);
  return (
    <tr className={ownTeam} style={{ backgroundColor: "yellow" }}>
      <td>{data["position"]} </td>
      <td
        className="LhomeOR link"
        onClick={() =>
          handleteamclick({
            val: data["id"],
            team_: data["cleanName"],
            league: compID,
          })
        }
      >
        <img
          src={Team_image?.[String(data["id"])]?.["image"]}
          width="20"
          height="15"
          alt={data["cleanName"]}
        />{" "}&nbsp;
        {data["cleanName"]}{" "}
      </td>

      <td></td>

      <td className="preds">{data["matchesPlayed"]}</td>
      <td className="preds">
        {data?.["seasonWins_overall"] || data?.["seasonWins"] || "0"}
      </td>
      <td className="preds">
        {data?.["seasonDraws_overall"] || data?.["seasonDraws"] || "0"}
      </td>
      <td className="preds">
        {data?.["seasonLosses_overall"] ||
          data?.["seasonLosses_away"] ||
          data?.["seasonLosses_home"] ||
          "0"}
      </td>
      <td className="preds">{data["seasonGoals"]}</td>
      <td className="preds">{data["seasonConceded"]}</td>
      <td className="preds">{data["points"]}</td>
    </tr>
  );
};

function TableMain({
  Data,
  league,
  leagueYears,
  disData,
  HandleDisClick,
  asking_team,
  raw_league,
}) {
  const season = leagueYears?.[league]?.["year"];
  const id_ = leagueYears?.[league]?.["id"];
  const navigation = useNavigate();
  const { globalUser } = useContext(GlobalUserContext);


  ////console.log(Data?.["all_matches_table_overall"]);

  const AllGames = Data?.["all_matches_table_overall"];
  /* const HomeGames = leagueToShow?.["all_matches_table_home"];
  const AwayGames = leagueToShow?.["all_matches_table_away"]; */

  if (!leagueYears) {
    return null;
  }
  return (
    <div className="light">
      <Table
        className="outer-border-light"
        striped
        hover
        variant="light"
      >
        <thead>
          <tr>
            <th>P</th>
            <th className="LhomeOR link">Team</th>
            <th></th>

            <th className="preds">MP</th>
            <th className="preds">W</th>
            
            <th className="preds">D</th>
            <th className="preds">L</th>
            <th className="preds">GF</th>
            <th className="preds">GA</th>
            <th className="preds">Pts</th>
          </tr>
        </thead>
        <tbody>
          {AllGames?.map((item, index) => (
            <DisplayData data={item} compID={id_} o_team={asking_team} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
function LeagueTablesMain({
  askleague,
  setaskleague,
  league_info_list,
  asking_team,
  raw_league,
  small,
  mid,
}) {
  const { globalUser } = useContext(GlobalUserContext);
  const [leagueYears, setleagueYears] = useState();
  const [disData, setdisData] = useState();
  
  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);
  
  ////console.log(leagueYears, league, leagueYears?.[league]);
  
  useEffect(() => {
    const get_tmdata = async() =>{

      let options = {
        body: JSON.stringify({
          league_id: askleague,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60 * 60,
        },

        seconds: 2 * 60 * 60,
      };
      let url = "/league_tables";
      let indexdb = "league_tables";
      let SaveKey = askleague;
      try{
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        
        /* const res = await axios.post(
          `${API_URL}/league_tables`,
          JSON.stringify( {
            league_id: askleague,
          } ),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        ////console.log(res)
        const firstDict = res
        if (firstDict?.status === "success" && firstDict.data) {
          ////console.log("first dict", firstDict?.data);
          setData(JSON.parse(firstDict?.data))
          setLoading(false)
         
        } else {
          throw new Error("Unsuccessful request");
        }
       
      
      } catch (error){
        ////console.log(error)
        Errorlog(error,"error loading statistics","from team stats")
      }
    }
    get_tmdata()
},[askleague])

if (!Data || loading){
     <Spinner />
  }


  const computeStateYear = (seasons, askleague) => {
    const state_year = {};
    const dis_data = {};
    let asking_league_ = "";
    /* ////console.log(Object.keys(league_years)); */

    let max = 0;
    let max_y = "";
    let ask_candidate = false;
    for (let item = 0; item < seasons?.length; item++) {
      const c_id = seasons?.[item]["id"];
      if (c_id > max) {
        max = c_id;
        max_y = seasons?.[item]?.["year"];
        if (String(c_id) === String(askleague)) {
          ////console.log(String(c_id), String(asking_league));
          ask_candidate = true;
        }
      }
    }
    if (ask_candidate === true) {
      asking_league_ = String(max);
      ////console.log("assigned");
    } else {
      asking_league_ = askleague;
    }
    state_year[String(max)] = { year: max_y, id: max };

    dis_data[String(max)] = "AllGames";

    return [state_year, dis_data, asking_league_];
  };

  // Inside your component
  useEffect(() => {
    const stateYear = computeStateYear(league_info_list, askleague);

    setleagueYears(stateYear[0]);
    setdisData(stateYear[1]);
    setaskleague(stateYear[2]);
  }, [askleague]);

  const HandleDisClick = (item, league) => {
    const prev_val = { ...disData };
    const prev_data = { ...leagueYears };
    prev_val[league] = item;
    setdisData(prev_val);
    setleagueYears(prev_data);
    setaskleague(league);
  };
  ////console.log(askleague);
  return (
    <div>
      <TableMain
        league={askleague}
        leagueYears={leagueYears}
        Data={Data}
        disData={disData}
        HandleDisClick={HandleDisClick}
        asking_team={asking_team}
        raw_league={raw_league}
      />
    </div>
  );
}
export default LeagueTablesMain;
