import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Row, Container, Modal, Nav, Col } from "react-bootstrap";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "../../css/LeagueSummaryChart.css";
import Team_image from "../../images/team_images.json";
import Spinner from "../Spinner";
import MixedBarChart from "./SummaryMixedBarLineChart";
import { Errorlog } from "../Utils";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;

const getColor = (name) => {
  if (name === "win") return "green";
  if (name === "loss") return "red";
  if (name === "draw") return "orange";
};
export default function SummaryWinners({ league, lgNav }) {
  const [teamCount, setTeamCount] = useState(3);
  const [teamCountl, setTeamCountl] = useState(3);
  const [loading, setLoading] = useState(true);
  const [useLeagueID, setUseLeagueID] = useState(league);
  const [data, setdata] = useState();
  const navigation = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  ////console.log(league, useLeagueID);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    //setFixture(() => {});
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    if (league !== null) {
      const get_lgdata = async () => {
        let options = {
          body: JSON.stringify({
            league_id: useLeagueID,
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },

          seconds: 2 * 60 * 60,
        };
        let url = "/league_summary";
        let indexdb = "league_summary";
        let SaveKey = useLeagueID
        try {
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          /* const res = await axios.post(
            `${API_URL}/league_summary`,
            JSON.stringify({
              league_id: useLeagueID,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = res;
          ////console.log(res);
          ////console.log("loaded fetchdata", fix_data?.status);
          if (fix_data.status === "success") {
            setdata(JSON.parse(fix_data?.data));
            //console.log(JSON.parse(fix_data?.data))
            setLoading(false);
          } else {
            Errorlog(
              "loaderror",
              "error loading statistics",
              "from league stats"
            );
          }
        } catch (error) {
          Errorlog(error, "error loading statistics", "from league stats");
          ////console.log(error);
        }
      };
      get_lgdata();
    }
  }, [league, setUseLeagueID]);

  if (!data || loading) {
    return <Spinner />;
  }
  ////console.log(data);
  const RenderTeam = ({ Team_, rank }) => {
    ////console.log(Team_);
    const own_team = parseInt(Object.keys(Team_)[0]);
    let TeamDataChart = data?.[own_team]?.winners?.chart;
    let TeamDataToolTip = {};
    ////console.log(TeamDataChart, own_team);
    const tooltipData = data?.[own_team]?.winners?.tooltip;

    const GettoolTip = ({ date, own_team, tooltipData }) => {
      tooltipData = tooltipData?.[date];
      ////console.log(date, tooltipData);
      return (
        <div>
          <div>
            <div className="tooltipbg">
              <Container className="justify-content-between align-items-center myContainer">
                <div>
                  <div>
                    <h6>{tooltipData?.date}</h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Match</h6>
                </div>
                <div className="center_div_distributed center_sides">
                  <div>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.score}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Possessions</h6>
                </div>

                <div className="center_div_distributed center_sides">
                  <div>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.possession}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Dangerous Attacks</h6>
                </div>

                <div className="center_div_distributed center_sides">
                  <div>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.attack}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <h6> Shots on Goal</h6>
                </div>

                <div className="center_div_distributed center_sides">
                  <div>
                    <h5>{tooltipData?.match?.homeName}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.shots}</h5>
                  </div>
                  <div>
                    <h5>{tooltipData?.match?.awayName}</h5>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      );
    };
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div>
            <GettoolTip
              date={label}
              tooltipData={tooltipData}
              own_team={own_team}
            />
          </div>
        );
      }

      return null;
    };
    const handleteamclick = ({ val, team_, league }) => {
      const team_info = {
        team: val,
        league: league,
        data: lgNav,
      };

      navigation("/team/" + team_, { state: team_info });
    };
    const handlematchlick = (day) => {
      const match_id = tooltipData?.[day]?.match?.matchID;
      const team2 = tooltipData?.[day]?.match?.awayID;
      const team1 = tooltipData?.[day]?.match?.homeID;
      const seas = " ";
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };
    return (
      <div className="allpad usemaxSpace">
        <div>
          <div>{rank}</div>
        </div>
        <div className={`center_div ${!isSmallScreen? "center_sides":"center_straight"}`}>
             
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              fontWeight: "bold",
              fontFamily: "serif",
              fontSize: "12px",
            }}
          >
            <div>
              <img
                src={Team_image?.[own_team]?.["image"]}
                className="link"
                onClick={() =>
                  handleteamclick({
                    val: own_team,
                    team_: data?.[own_team]?.winners?.team_meta?.team_name,
                    league: league,
                  })
                }
                alt={data?.[own_team]?.winners?.team_meta?.team_name}
                style={{
                  width: "100px", // or any value appropriate for your design
                  height: "100px", // keep aspect ratio if not defined
                }}
              />
            </div>

            <div>
              <div className="center_div">
                {data?.[own_team]?.winners?.team_meta?.team_name}
              </div>
            </div>
          </div>
        </div>
        
          <div style={{ width: "100%", height: "180px" }}>
            <ResponsiveContainer>
              <BarChart
                width={420}
                height={150}
                data={TeamDataChart}
                barGap={1}
                margin={{
                  top: 20,
                  right: 5,
                  bottom: 5,
                  left: -20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="day"
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                />
                <YAxis
                  tick={{ fontSize: 12, fontFamily: "serif" }}
                  tickFormatter={(value) => {
                    if (value >= 1 && value <= 2) {
                      return "Loss";
                    } else if (value > 2 && value <= 4) {
                      return "Draw";
                    } else if (value > 4 && value <= 6) {
                      return "Win";
                    } else {
                      return value; // Use the original value if it doesn't fall into any range
                    }
                  }}
                  interval={0} // Ensure the ticks are spaced evenly
                  ticks={[1, 3, 5]}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  offset={10}
                  allowEscapeViewBox={{ x: false, y: true }}
                />

                <Bar dataKey="pos1" barSize={10} barGap={0} barCategoryGap={0}>
                  {TeamDataChart.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColor(entry.col1)}
                      className="link"
                      onClick={() => handlematchlick(entry.day)}
                    />
                  ))}
                </Bar>
                <Bar dataKey="pos2" barSize={10} barGap={0} barCategoryGap={0}>
                  {TeamDataChart.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      className="link"
                      fill={getColor(entry.col2)}
                      onClick={() => handlematchlick(entry.day)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        
        </div>
      </div>
    );
  };
  const HandleSeeMoreTeam = (val, type) => {
    //let currValue = monthCount;
    ////console.log("Was here");
    if (val === "seemore" && teamCount > 2 && type === "w") {
      setTeamCount(teamCount + 3);
    } else if (val === "seemore" && teamCountl > 2 && type === "l") {
      setTeamCountl(teamCountl + 3);
    }
    if (val === "seeless" && teamCount > 5 && type === "w") {
      setTeamCount(teamCount - 3);
    } else if (val === "seeless" && teamCountl > 5 && type === "l") {
      setTeamCountl(teamCountl - 3);
    }
  };

  function SeeMore({ sender }) {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <div className="center_div_distributed">
          <div
            className="link forceblack"
            onClick={() => HandleSeeMoreTeam("seeless", sender)}
          >
            <div className=" smallbuton">{"< Less"}</div>
          </div>
          <div
            className="link forceblack"
            onClick={() => HandleSeeMoreTeam("seemore", sender)}
          >
            <div className="smallbuton">{"More >"}</div>
          </div>
        </div>
      </div>
    );
  }
  ////console.log(data);
  const displays = ["Goal Scorers", "card recievers", "corner winners"];
  const toolchartdict = {};
  toolchartdict["Goal Scorers"] = {
    goalschart: [
      "day",
      "1h_gf",
      "2h_ga",
      "2h_gf",
      "1h_ga",
      "team_SOTarget",
      "opponent_SOTarget",
    ],
    goalsTool: [
      "homeID",
      "awayID",
      "homeName",
      "awayName",
      "score",
      "shots",
      "d_attack",
      "possession",
      "matchID",
    ],
  };
  toolchartdict["card recievers"] = {
    goalschart: [
      "day",
      "1h_ca_f",
      "2h_ca_a",
      "2h_ca_f",
      "1h_ca_a",
      "team_foul",
      "opponent_foul",
    ],
    goalsTool: [
      "homeID",
      "awayID",
      "homeName",
      "awayName",
      "foul",
      "cards",
      "d_attacks",
      "possession",
      "matchID",
    ],
  };
  toolchartdict["corner winners"] = {
    goalschart: [
      "day",
      "1h_co_f",
      "2h_co_a",
      "2h_co_f",
      "1h_co_a",
      "team_GoalKicks",
      "opponent_GoalKicks",
    ],
    goalsTool: [
      "homeID",
      "awayID",
      "homeName",
      "awayName",
      "corners",
      "offsides",
      "goalkicks",
      "throwins",
      "matchID",
    ],
  };
  const goalsTool = [];
  const cardsChart = [];
  const cardsTool = [];
  const coirnersChart = [];
  const cornersTool = [];
  return (
    <div className="usemaxSpace ">
      <div className="margin-30down usemaxSpace bottomdivLine">
        <div className=" usemaxSpace">
          <div>
            <div>
             <div className="center_div bold  paragrph_font"> Top Winners</div>

              <div className="usemaxSpace">
                {Object.keys(data?.winners).map((item, index) => {
                  if (index < teamCount) {
                    return (
                      <>
                        <div key={index} className="usemaxSpace">
                          <RenderTeam
                            
                            Team_={data?.winners?.[item]}
                            rank={item}
                          />
                        </div>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              
              <SeeMore sender={"w"} />
            </div>
          </div>

          {/* <div>
            <div className="center_div bold  paragrph_font"> Top Loosers</div>

            <div>
              {Object.keys(data?.winners).map((item, index) => {
                if (index < teamCountl) {
                  return (
                    <>
                      <div className="rounded-3">
                        <div>
                          <RenderTeam
                            key={item}
                            Team_={data?.loosers?.[item]}
                            rank={index + 1}
                          />
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <SeeMore sender={"l"} />
          </div> */}
        </div>
        <div className="mainFont margin10down center_div_distributed center_sides">
          <div className="center_div">
            <div>Bars: fh | 2h</div>
          </div>
          <div>
            <div className="center_div">
              <div className="rectanglewin" />
              Half Win{" "}
            </div>
          </div>

          <div>
            <div className="center_div">
              <div className="rectangledraw" />
              Half Draw
            </div>
          </div>

          <div>
            <div className="center_div">
              {" "}
              <div className="rectangleloss" />
              Half Loss
            </div>
          </div>
        </div>
      </div>
      <div>
        {displays.map((item, index) => {
          return (
            <MixedBarChart
              data={data}
              league={league}
              lgNav={lgNav}
              type={item}
              toolchartdict={toolchartdict}
            />
          );
        })}
      </div>
    </div>
  );
}
