import React, { useEffect, useState, useContext } from "react";
import { format, addDays, subDays } from "date-fns";
import { DropdownButton, Dropdown, Container } from "react-bootstrap";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import "../css/index.css";

const DatePicker = ({ fix_date }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const startDate = subDays(new Date(), 7);
  const endDate = addDays(new Date(), 14);

  const dateList = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateList.push(new Date(currentDate)); // Create a new copy of the date
    currentDate = addDays(currentDate, 1);
  }
  useEffect(() => {
    const eventDate = new Date();
    setSelectedDate(eventDate);
    const fix_curr = {
      day: format(eventDate, "yyyy-MM-dd"),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    fix_date?.(fix_curr);
  }, []);
  ////console.log(new Date());

  const handleDateClick = (date) => {
    setSelectedDate(date);
    
    ////console.log(format(date, "yyyy-MM-dd"));
    const fix_curr = {
      day: format(date, "yyyy-MM-dd"),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    fix_date(fix_curr);
  };
  if (!selectedDate) {
    return null;
  }
  return (
    <div>
      <Container>
        <DropdownButton
          id="date-picker-dropdown"
          className="align-items-center date-button"
          size="sm"
          title={
            <>
              <FaRegCalendarAlt />{" "}
              {selectedDate instanceof Date && !isNaN(selectedDate)
                ? format(selectedDate, "E-MMM-dd")
                : "Invalid Date"}
            </>
          }
          onSelect={(eventKey) => {
            const eventDate = new Date(eventKey);
            if (eventDate instanceof Date && !isNaN(eventDate)) {
              handleDateClick(eventDate);
            }
          }}
        >
          {dateList.map((date) =>
            date instanceof Date && !isNaN(date) ? (
              <Dropdown.Item
                key={date.toISOString()}
                eventKey={date.toISOString()}
                className={
                  format(date, "E-MMM-dd") === format(selectedDate, "E-MMM-dd")
                    ? "highlight"
                    : ""
                }
              >
                {format(date, "E-MMM-dd")}
              </Dropdown.Item>
            ) : null
          )}
        </DropdownButton>
      </Container>
    </div>
  );
};

export default DatePicker;
