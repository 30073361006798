import React, { useContext, useEffect, useState } from "react";
import { MDBFileUpload } from "mdb-react-file-upload";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import IndexHeader from "../IndexHeaders";
import FooterAll from "../Footer";
import axios from "axios";
import { MDBBtn, MDBContainer, MDBToast } from "mdb-react-ui-kit";
import ReactCrop, { Crop } from "react-image-crop";
import ImageCrop from "./ImageCropper";
import { getCroppedImg } from "react-image-crop";
import "../../css/ThemeCss.css";
import { Container } from "react-bootstrap";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import { CallError, CallSuccess, SideNavPage } from "../Utils";
import FooterNav from "../SmallScreenNav";
import { CatchedStoragedImages } from "../manageStorage/CatchedData";
import {compressImage} from "./CompressImage"
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
function ImageUpload(props) {
  const [pictureFiles, setPictureFiles] = useState();
  const [backgroundFiles, setBackgroundFiles] = useState();
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [croppedImage, setCroppedImage] = useState();
  const [uploadError, setUploadError] = useState();
  const [uploadSuccess, setUploadSuccess] = useState();
  const [croppedbackImage, setCroppedbackImage] = useState();
  const [showProfileCropper, setShowProfileCropper] = useState("cropper");
  const [showBackgroundCropper, setShowBackgroundCropper] = useState("cropper");
  const [isclickTicket, setisclickTicket] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  ////console.log(croppedImage);
  

  const HandleSaveImages = async(ty, image) => {
    if (ty === "profile") {
      /* const prevGlobal = { ...globalUser };
      prevGlobal.user["profile"] = image;
      updateGlobalUser(() => prevGlobal);
      let ckUser = UpdateLocal({
        action: "save",
        data: prevGlobal,
      }); */
      //console.log(image)
      let loadImg = new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image; // Your base64 string here
  
        img.onload = () => {
          const compressedImg = img && compressImage(img, 0.5, "image/jpeg");
          resolve(compressedImg); // Resolve the promise with the compressedImg
        };
  
        img.onerror = () => {
          reject(new Error('Image Load Error'));
        };
      });
  
      try {
        let newimg = await loadImg; // Wait for the Promise to resolve
     
        const res = await axios.post(`${API_URL}/saveUserImage`, {
          email: globalUser?.user?.email,
          image: newimg,
          type_: "profile"
        });
        setUploadSuccess(true)
        setUploadError(false)

       /*  let url = `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`;
        let indexdb = "profile";
        let SaveKey = "profile"
        
        const response2 = await CatchedStoragedImages(url, "new", SaveKey, indexdb); */
      } catch (error) {
        setUploadSuccess(false)
        setUploadError(true)
        console.error(error);
        
      }

    } else if (ty === "background") {
      let loadImg = new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image; // Your base64 string here
  
        img.onload = () => {
          const compressedImg = img && compressImage(img, 0.5, "image/jpeg");
          resolve(compressedImg); // Resolve the promise with the compressedImg
        };
  
        img.onerror = () => {
          reject(new Error('Image Load Error'));
        };
      });
      try {
        let newimg = await loadImg;
        const res = await axios.post(`${API_URL}/saveUserImage`, {
          email: globalUser?.user?.email,
          image: newimg ,
          type_: "background"
        });
        setUploadSuccess(true)
        setUploadError(false)
        /* let url = `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_background`;
        let indexdb = "background";
        let SaveKey = "background"
        
        const response2 = await CatchedStoragedImages(url, "new", SaveKey, indexdb); */
      } catch (error) {
        setUploadSuccess(false)
        setUploadError(true)
        console.error(error);
        
      }
    }
  };
  
  return (
    <div>
    {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}/>
      
      {uploadError && <CallError funcchange={setUploadError} funcuser={uploadError} data1={"File is too large"} data2={"Try reducing the image size, then try again"}/>}
      {uploadSuccess && <CallSuccess funcchange={setUploadSuccess} funcuser={uploadSuccess} data1={"Success"} data2={"Your image was uploaded"}/>}
      <Container>
        <div>
          <div className="center_div center_straight margin-50down">
            <div className="bold midfont allpad">Change Profile Picture</div>
            {showProfileCropper === "cropper" ? (
              <>
                {
                  <div className="usemaxSpace center_div center_straight">
                    <div style={{ width: isSmallScreen? "100%":"50%" }}>
                      {pictureFiles && (
                        <ImageCrop
                          useImage={pictureFiles}
                          getcropped={setCroppedImage}
                          setShowProfileCropper={setShowProfileCropper}
                          scale={4/3}
                        />
                      )}
                    </div>
                  </div>
                }
              </>
            ) : showProfileCropper === "result" ? (
              <>
                {croppedImage && (
                  <div className="center_div center_straight">
                    <div>How your Profile picture will look</div>
                    <img
                      src={croppedImage}
                      className="rounded-circle float-none float-md-start me-4 mb-3 link"
                      alt=""
                      style={{
                        width: "168px",
                        border: "4px solid hsl(0, 0%, 98%)",
                      }}
                    />
                    <div className="center_div center_sides">
                      <div
                        className=" inactiveButton allpad link forceblack"
                        onClick={() => setShowProfileCropper("cropper")}
                      >
                        Redo
                      </div>{" "}
                      &nbsp; &nbsp; &nbsp;
                      <div
                        className=" smartTableButton allpad link forceblack"
                        onClick={() =>{
                          HandleSaveImages("profile", croppedImage);
                          }
                        }
                      >
                        Save
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <div
              className="center_div center_straight noscrolbar greyborder margin10down"
              style={{ maxWidth: isSmallScreen? "100%":"50%", overflow: "scroll" }}
            >
              <div className="Center_div usemaxSpace">
                <div className="Center_div usemaxSpace">
                  <MDBFileUpload
                    maxFileQuantity={1}
                    mainError="OoPs something wrong happened"
                    maxFileSize="2M"
                    style={{ height: "150px" }}
                    maxSizeError="file too large"
                    acceptedExtensions="image/*"
                    onChange={(files) => {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setPictureFiles(reader.result);
                      };

                      if (files[0]) {
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="center_div center_straight">
            <div className="bold midfont allpad">Change Background Picture</div>
            {showBackgroundCropper === "cropper" ? (
              <>
                {
                  <div className="usemaxSpace center_div center_straight">
                    <div style={{width: isSmallScreen? "100%":"50%"}}>
                      {backgroundFiles && (
                        <ImageCrop
                          useImage={backgroundFiles}
                          getcropped={setCroppedbackImage}
                          setShowProfileCropper={setShowBackgroundCropper}
                          scale={4/2}
                        />
                      )}
                    </div>
                  </div>
                }
              </>
            ) : showBackgroundCropper === "result" ? (
              <>
                {croppedbackImage && (
                  <div className="center_div center_straight">
                    <div>How your background picture will look</div>
                    <img
                      src={croppedbackImage}
                      className="float-none float-md-start me-4 mb-3 link"
                      alt=""
                      style={{width: isSmallScreen? "100%":"50%"}}
                      
                    />
                    <div className="center_div center_sides">
                      <div
                        className=" inactiveButton allpad link forceblack"
                        onClick={() => setShowBackgroundCropper("cropper")}
                      >
                        Redo
                      </div>{" "}
                      &nbsp; &nbsp; &nbsp;
                      <div
                        className=" smartTableButton allpad link forceblack"
                        onClick={() =>{
                          HandleSaveImages("background", croppedbackImage);
                          
                        }
                        }
                      >
                        Save
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <div
              className="center_div center_straight noscrolbar greyborder margin10down"
              style={{ maxWidth:isSmallScreen? "100%":"50%", overflow: "scroll" }}
            >
              <div className="Center_div usemaxSpace">
                <div className="Center_div usemaxSpace">
                  <MDBFileUpload
                    maxFileQuantity={1}
                    mainError="OoPs something wrong happened"
                    maxFileSize="2M"
                    style={{ height: "100px" }}
                    maxSizeError="file too large"
                    acceptedExtensions="image/*"
                    onChange={(files) => {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setBackgroundFiles(reader.result);
                      };

                      if (files[0]) {
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <FooterAll page={"ContactUs"} />
      <FooterNav />
    </div>
  );
}

export default ImageUpload;
