import React from "react";
import { useState, useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/HeaderStyles.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Themes } from "./indexPage";
import DisplayTicket from "./ShowTickets";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  Nav,
  Image,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Navbar,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as Icon } from "../images/user.svg";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import Spinner from "./Spinner";
import { IoMdMenu } from "react-icons/io";
import { SelectTicket, ShowTicketDetailsModal, SideNavPage } from "./Utils";
import care from "../images/unisex.jpg";
import Notifications, { ShowNotification } from "./Notification";
import { googleLogout } from "@react-oauth/google";
import { CatchedStoragedImages } from "./manageStorage/CatchedData";

const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
function IndexHeader({
  setIsLogedin,
  setisclickTicket,
  setIsSubscribed,
  ...props
}) {
  const [loading, setLoading] = useState(props.loading);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const navigation = useNavigate();
  /*  setIsLogedin={setIsLogedin} setIsSubscribed={setIsSubscribed} */
  //const [radioValue, setRadioValue] = useState(props?.radioValue);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [activeElement, setActiveElement] = useState(1);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isGosClose, setisGosClose] = useState(false);
  const [basicOpen, setBasicOpen] = useState();
  const [Shows, setShows] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [useSetTicket, setUseSetTicket] = useState(false);
  
  useEffect(()=>{
    const prevglobal = { ...globalUser };
    let statuses = prevglobal?.user?.Tickets?.state;
    let activeTicket = "";
    if (statuses && typeof statuses === "object") {
      for (
        let index = 0;
        index < Object.keys(statuses && statuses)?.length;
        index++
      ) {
        const element = Object.keys(statuses && statuses)[index];
        if (statuses?.[element]) {
          activeTicket = element;
        }
      }
    }

    if (activeTicket === "") {
      setticketactive(() => false);
    } else {
      setticketactive(() => true)
      setShows(() => true)
    }
  },[globalUser?.user?.Tickets])
  
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 1000);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
    if (window.innerWidth < 1360) {
      setisGosClose(true);
    } else {
      setisGosClose(false);
    }
  };
  ////console.log(globalUser, globalUser?.user?.dataMode);
  const HandleLogout = () => {
    UpdateLocal({ action: "delete", data: { rmKeys: ["user"] } }).then(
      (result) => {
        setIsLogedin?.(() => false);
        setIsSubscribed?.(() => false);
        updateGlobalUser({});
        googleLogout();
        navigation("/");
      }
    );
  };
  /* const handleThemeChange = ({ val }) => {
    //setRadioValue(val);
    ////console.log("in index header", val);

    const themststus = val;
    const currentdata = globalUser;
    currentdata.user.theme = Themes[themststus];
    currentdata.user.states.theme = themststus;
    updateGlobalUser(currentdata);
    UpdateLocal({ action: "save", data: currentdata }).then((result) => {});
    ////console.log("saved from handle theme", currentdata);
    props?.setRadioValue(val);
  }; */
  const handleNavClick = (id) => {
    setActiveElement(id);
  };
  const handleDataModeChange = ({ val, state }) => {
    //setRadioValue(val);
    ////console.log("in index header", val);

    setActiveElement(state);
    const currentdata = globalUser;
    //currentdata.user.dataMode = themststus;
    currentdata.user.dataMode = val;
    updateGlobalUser(currentdata);
    UpdateLocal({ action: "save", data: currentdata }).then((result) => {});
    ////console.log("saved from handle data mode", currentdata);
    props?.setRadioValue(val);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    if (globalUser.user) {
      setLoading(false);
      if (globalUser?.user?.dataMode === "low") {
        setActiveElement(1);
      } else {
        setActiveElement(0);
      }
    }
  }, [globalUser, props?.radioValue, props.loading]);

  /* const radios = [
    { name: "dark", value: "light" },
    { name: "light", value: "dark" },
  ]; */

  const UserLoggedIn = () => {
    //console.log("started images")
    const [authorProfile, setUserProfile] = useState();
    useEffect(() => {
      ////console.log(useMatches,useMatches?.ticket_meta?.author);
      const  fetchBackground = async () => {
        
        /* let url2 = `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`;
        let url = "emails";
        let indexdb = "profile_image";
        let SaveKey = "profile" */
        try {
          /* //console.log("response2",url, "old", SaveKey, indexdb)
          const response2 = await CatchedStoragedImages(url, "old", SaveKey, indexdb);
          //console.log(response2) */
          const response2 = await fetch(
            `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`
          );

          if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
          }
          const blob2 = await response2.blob();
          const base64data2 = await blob2.text(); 
          setUserProfile(base64data2);
          ////console.log("finalValue");
        } catch (error) {
          //console.error(error);
        }
      }
      fetchBackground();
    }, [globalUser?.user?.email]);

    return (
      <div className="center_div center_straight">
        <img
          src={authorProfile ?? globalUser?.user?.profile ?? care}
          className="rounded-circle"
          alt=""
          style={{
            width: "40px",
            border: "1px solid hsl(0, 0%, 98%)",
          }}
        />

        <NavDropdown
          title={globalUser.user && globalUser.user.firstname}
          id="basic-nav-dropdown"
          className="align-items-center custom_drop_new"
        >
          <NavDropdown.Item
            onClick={() => {
              if (typeof props?.itemDisplay === "function") {
                props?.itemDisplay("Profile Data");
              }
              navigation("/userprofile");
            }}
          >
            Profile
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigation("/myMessages")}>
            Messages
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() =>
              navigation("/publicProfile", {
                state: {
                  publicProfile:
                    globalUser?.user?.publicProfile ?? globalUser?.user?.email,
                },
              })
            }
          >
            Tickets
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigation("/predictions")}>
            Prediction Feed
          </NavDropdown.Item>
          <NavDropdown.Item>Followers</NavDropdown.Item>

          <NavDropdown.Item
            onClick={() => {
              if (typeof props?.itemDisplay === "function") {
                props.itemDisplay("Subscriptions");
              }
              navigation("/userProfile", {
                state: { data: "Subscriptions" },
              });
            }}
          >
            Supscriptions
          </NavDropdown.Item>
          <NavDropdown.Item onClick={HandleLogout}>Logout</NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  };

  const GuestLoginSignup = () => {
    return (
      <Nav>
        <Button className="nav_buttons">
          <Nav.Link
            className="nav_link_button"
            onClick={() => navigation("/SignIn")}
          >
            Login
          </Nav.Link>
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button className="nav_buttons">
          <Nav.Link
            className="nav_link_button"
            onClick={() => navigation("/Signup")}
          >
            Signup
          </Nav.Link>
        </Button>
      </Nav>
    );
  };

  const RightTabHeader = () => {
    return (
      <Navbar.Collapse className="justify-content-end">
        {globalUser.user && globalUser.user.firstname ? (
          globalUser.user.firstname === "Guest" ? ( // Use a nested ternary operator for conditional rendering
            <GuestLoginSignup />
          ) : (
            <UserLoggedIn /> // Add JSX elements to render in the "else" block
          )
        ) : (
          <GuestLoginSignup />
        )}
      </Navbar.Collapse>
    );
  };
  return (
    <div
      style={{ width: "100%", position: "sticky", top: 0, zIndex: 1000 }}
      className="navbar_font "
    >
      {props?.isclickTicket ? (
        <ShowTicketDetailsModal
          funcuser={props?.isclickTicket}
          funcchange={setisclickTicket}
        />
      ) : null}
      {useSetTicket ? (
        <SelectTicket
          funcuser={useSetTicket}
          funcchange={setUseSetTicket}
          islgedin={props?.isLogedin}
          issubed={props?.issubscribed}
          setticketactive={setticketactive}
          setShows={setShows}
        />
      ) : null}
      {isSmallScreen ? (
        <div className="center_div_distributed center_sides">
          <div className="left_div center_sides">
            <div
              className="allpad"
              onClick={() => props?.setIsOpen(() => !props?.isOpen)}
            >
              <IoMdMenu size={25} className="webButton" />
            </div>

            <div onClick={() => navigation("/")}>
              <Logo
                alt="Bet Informatics"
                style={{ maxWidth: "10rem", maxHeight: "2rem" }}
              />
            </div>
          </div>

          <div className="center_div center_sides smallfont">
            <div
              onClick={() => {
                if (props?.isLogedin) {
                  setisclickTicket?.((prevIsClickAdd) => !prevIsClickAdd);
                } else {
                  setUseSetTicket((prev) => !prev);
                }
              }}
              onDoubleClick={()=> setUseSetTicket((prev) => !prev)}
              className="allpad "
              style={{ fontSize: "14px" }}
            >
              <DisplayTicket  onDoubleClick={()=> setUseSetTicket((prev) => !prev)}/>
            </div>{" "}
            <div className="allpad">
              <Notifications
                basicOpen={basicOpen}
                setBasicOpen={setBasicOpen}
              />
            </div>
          </div>
        </div>
      ) : (
        <Container>
          <Navbar expand="lg" sticky="top" className="m-auto">
            <Navbar>
              <Container>
                <Nav.Link onClick={() => navigation("/")}>
                  <Logo
                    alt="Bet Informatics"
                    style={{ maxWidth: "10rem", maxHeight: "2rem" }}
                  />
                </Nav.Link>
              </Container>
            </Navbar>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <IoMdMenu />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={() => navigation("/")}>
                  {" "}
                  <div style={{ fontSize: "14px" }}>Fixtures</div>
                </Nav.Link>
                <Nav.Link onClick={() => window.open("/Statistics")}>
                  {" "}
                  <div style={{ fontSize: "14px" }}>Statistics</div>
                </Nav.Link>

                <Nav.Link onClick={() => navigation("/predictions")}>
                  {" "}
                  <div style={{ fontSize: "14px" }}>Predictions</div>
                </Nav.Link>

                {/* <Nav.Link onClick={() => navigation("/Pricing")}>
                  {" "}
                  <div style={{ fontSize: "14px" }}>Pricing</div>
                </Nav.Link> */}

                <Nav.Link onClick={() => navigation("/About us")}>
                  <div style={{ fontSize: "14px" }}>About us</div>
                </Nav.Link>
                <Nav.Link onClick={() => navigation("/Contact us")}>
                  <div style={{ fontSize: "14px" }}>Contact</div>
                </Nav.Link>
                <Nav.Link onClick={() => navigation("/howto")}>
                  <div style={{ fontSize: "14px" }}>How to BI</div>
                </Nav.Link>
                {/* <Nav.Link
                  onClick={() => {
                    navigation("/pricing");
                  }}
                >
                  <div style={{ fontSize: "14px" }}>Pricing</div>
                </Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {(globalUser.user && globalUser.user.page === "signup") ||
                (globalUser.user &&
                  globalUser.user.page === "signin") ? null : (
                  <div className="center_div center_sides smallfont">
                    <div
                      onClick={() => {
                        if (props?.isLogedin) {
                          setisclickTicket?.(
                            (prevIsClickAdd) => !prevIsClickAdd
                          );
                        } else {
                          setUseSetTicket((prev) => !prev);
                        }
                      }}
                      onDoubleClick={()=> setUseSetTicket((prev) => !prev)}
                      className="allpad "
                      style={{ fontSize: "14px" }}
                    >
                      <DisplayTicket onDoubleClick={()=> setUseSetTicket((prev) => !prev)}/>
                    </div>{" "}
                    &nbsp;
                    <div className="allpad">
                      <Notifications
                        basicOpen={basicOpen}
                        setBasicOpen={setBasicOpen}
                      />
                    </div>
                    <div className="allpad">
                      <RightTabHeader />
                    </div>
                  </div>
                )}
              </>
            )}
            <ToastContainer position="bottom-left" />
          </Navbar>
        </Container>
      )}
    </div>
  );
}

export default IndexHeader;
