import React from 'react';
import YouTube from 'react-youtube';

export default function PlayYouTube(props) {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return <div className='center_div'>
    <YouTube videoId={props?.VideoId} opts={opts} onReady={_onReady} />;
    </div>
}