import React from "react";
import { useState, useEffect, useContext } from "react";
import { format, addDays, subDays } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  NavLink,
  Button,
  Container,
  DropdownButton,
  Dropdown,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import LeagueNav from "../LeagueSideBar";
import DisplayRanking from "./DisplayRankedGames";
import Team_image from "../../images/team_images.json";
import FooterAll from "../Footer";
import { Errorlog,SideNavPage  } from "../Utils";
import DatePicker from "../DatePicker";
import ShowTicket from "../ShowTicketGames";
import IndexSubheads from "../IndexSubheader";
import FooterNav from "../SmallScreenNav";
import PlayYouTube from "../VideoPlayer/YouTubePlayer";


const API_URL = process.env.REACT_APP_API_URL;

export default function DisplayRankings() {
  const [useFixture, setFixture] = useState({});

  const [loading, setLoading] = useState(false);
  const [showFirstSbar, setshowFirstSbar] = useState({
    target: "Win-Draw-Lose",
    status: true,
  });
  const [showTopSbar, setshowTopSbar] = useState({
    target: "All",
    status: true,
  });
  const [showSecondSbar, setshowSecondSbar] = useState({
    target: "Full Time",
    status: true,
  });
  const [showThirdSbar, setshowThirdSbar] = useState({
    target: "Home Win",
    status: true,
  });
  const [showForthSbar, setshowForthSbar] = useState({
    target: "Match",
    status: true,
  });
  const [showFifthSbar, setshowFifthSbar] = useState({
    target: "2.5",
    status: true,
  });
  const [statsToShow, setstatsToShow] = useState("Win-Draw-Lose");
  const [statsToShowTop, setstatsToShowTop] = useState("All");
  const [statsToShow4, setstatsToShow4] = useState("Match");
  const [statsToShow5, setstatsToShow5] = useState("Match");
  const [statsToShow2, setstatsToShow2] = useState("Full Time");
  const [statsToShow3, setstatsToShow3] = useState("Home Win");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const MatchID = location.state || " "; // Initialize with an empty object if undefined
  const league = location.state?.league || " ";
  const lagueNavData = location.state?.data || " ";
  const [useSideNav, setUseSideNav] = useState(lagueNavData);
  const [useStatistics, setuseStatistics] = useState();
  const [askleague, setaskleague] = useState(String(league));
  const [jointLeague, setJointLeague] = useState(String(league));
  const [useleagueName, setuseleagueName] = useState();
  const [activeElement, setActiveElement] = useState(0);
  const [activeElementTop, setActiveElementTop] = useState(0);
  const [activeElement4, setActiveElement4] = useState(0);
  const [activeElement5, setActiveElement5] = useState(0);
  const [useTable, setUSeTable] = useState(false);
  const [activeElement2, setActiveElement2] = useState(0);
  const [activeElement3, setActiveElement3] = useState(0);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const navigation = useNavigate();
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isGosClose, setisGosClose] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [useDate, setUseDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [issubscribed, setIsSubscribed] = useState(false);
  const [isLogedin, setIsLogedin] = useState(false);
  const [Shows, setShows] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1200 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
    if (window.innerWidth < 1360) {
      setisGosClose(true);
    } else {
      setisGosClose(false);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      let ckUser;
      if (!globalUser.user) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
      }
      ////console.log(globalUser);
      if (ckUser && ckUser.result === "retrieved") {
        updateGlobalUser(ckUser?.data?.user);
        try {
          axios.post(`${API_URL}/active_users_save`, {
            email: ckUser?.data?.user?.user?.email,
            page: "statistics_ranking",
          });
        } catch (error) {
          ("do noithing");
        }
        if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "inactive"
        ) {
          let currentDate = format(new Date(), "yyyy-MM-dd");

          if (currentDate > useDate?.day) {
            setIsSubscribed(true);
          } else {
            setIsSubscribed(false);
          }
        } else if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
          setIsLogedin(true);
        } else {
          let currentDate = format(new Date(), "yyyy-MM-dd");
          if (currentDate > useDate?.day) {
            setIsSubscribed(true);
          } else {
            setIsSubscribed(false);
          }
          ////console.log(globalUser);
          setIsLogedin(() => false);
        }
      } else {
        if (
          (globalUser &&
            globalUser?.user?.firstname === "Guest" &&
            globalUser?.user) ||
          (globalUser && Object.keys(globalUser).length === 0)
        ) {
          ////console.log(globalUser);
          setIsLogedin(false);

          let currentDate = format(new Date(), "yyyy-MM-dd");

          if (currentDate > useDate?.day) {
            setIsSubscribed(true);
          } else {
            setIsSubscribed(false);
          }
        } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
          if (
            globalUser &&
            !globalUser?.user?.subscription &&
            globalUser?.user
          ) {
            ////console.log(globalUser);
            setIsLogedin(true);
            let currentDate = format(new Date(), "yyyy-MM-dd");
            if (currentDate > useDate?.day) {
              setIsSubscribed(true);
            } else {
              setIsSubscribed(false);
            }
          } else {
            if (
              globalUser &&
              globalUser?.user?.subscription?.status === "inactive"
            ) {
              setIsLogedin(true);
              let currentDate = format(new Date(), "yyyy-MM-dd");
              if (currentDate > useDate?.day) {
                setIsSubscribed(true);
              } else {
                setIsSubscribed(false);
              }
            } else {
              ////console.log(globalUser);
              setIsLogedin(true);
              setIsSubscribed(true);
            }
          }
        }
      }
      //***removing supscription****
      setIsSubscribed(true)
    };

    fetchData();
  }, []);
  ////console.log(globalUser);

  const handleNavClick = (id) => {
    setActiveElement(id);
  };
  const handleNavClickTop = (id) => {
    setActiveElementTop(id);
  };
  const handleNavClick4 = (id) => {
    setActiveElement4(id);
  };
  const handleNavClick5 = (id) => {
    setActiveElement5(id);
  };
  const handleNavClick2 = (id) => {
    setActiveElement2(id);
  };
  const handleNavClick3 = (id) => {
    setActiveElement3(id);
  };
  const showFirstSbar_ = ({ target }) => {
    setshowFirstSbar(target);
    ////console.log("reset showfirstbar", target);
  };
  const showTopbar_ = ({ target }) => {
    setshowTopSbar(target);
    ////console.log("reset showfirstbar", target);
  };
  const showThirdSbar_ = ({ target }) => {
    setshowThirdSbar(target);
    ////console.log("reset showfirstbar", target);
  };
  function unixTimeToFullDate(unixTimestamp) {
    // Multiply by 1000 to convert from seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Get individual components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format the date
    const formattedDate = `${month}.${day}.${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  function TopLayerSubLinks(params) {
    // this  handles the fotter of the modal display
    const team_navs = ["All", "At Home", "At Away"];
    return (
      <div>
        <hr />
        <div
          className=" left_div center_sides nav_scroller noyscrol"
        >
          {team_navs.map((item, index) => {
            return (
              <div
                key={index}
                className={`breadNav_light_second_small sidepad  ${
                  activeElementTop === index ? "div_active link forceblack"
                      : "dormant link forceblack"
                }`}
                onClick={() => {
                  setstatsToShowTop(item);
                  setstatsToShow("Win-Draw-Lose");
                  setstatsToShow2("Full Time");
                  handleNavClick(0);
                  handleNavClick2(0);
                  handleNavClick3(0);
                  setstatsToShow3("Home Win");

                  showTopbar_({
                    target: { target: item, status: true },
                  });
                  showFirstSbar_({
                    target: {
                      target: "Win-Draw-Lose",
                      status: true,
                    },
                  });
                  showThirdSbar_({
                    target: { target: item, status: true },
                  });
                  handleNavClickTop(index);
                }}
              >
                {isSmallScreen ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function FirstLayerSubLinks(params) {
    // this  handles the fotter of the modal display
    const team_navs = ["Win-Draw-Lose","WDL-Cards",
      "WDL-Corners", "Goals", "Cards", "Corners"];
    return (
      <div  >
        <hr />
        <div
          className="left_div center_sides nav_scroller noyscrol margin-5down"
        >
          {team_navs.map((item, index) => {
            return (
              <div
                key={index}
                className={`breadNav_light_second_small sidepad ${
                  activeElement === index ? "div_active link forceblack"
                      : "dormant link forceblack"
                }`}
                onClick={() => {
                  setstatsToShow(item);
                  setstatsToShow2("Full Time");
                  handleNavClick2(0);
                  handleNavClick3(0);
                  if (item === "Win-Draw-Lose") {
                    setstatsToShow3("Home Win");
                  } else if (item === "Goals") {
                    setstatsToShow3("Over Goals Full Time");
                  } else if (item === "Cards") {
                    setstatsToShow3("Over Cards Full Time");
                  } else if (item === "Corners") {
                    setstatsToShow3("Over Corners Full Time");
                  }
                  showFirstSbar_({
                    target: { target: item, status: true },
                  });
                  showThirdSbar_({
                    target: { target: item, status: true },
                  });
                  handleNavClick(index);
                }}
              >
                {isSmallScreen ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  function SecondLayerSubLinks({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const Navs = ["Full Time", "First Half", "Second Half"];

    return (
      <div >
        <div className="left_div center_sides nav_scroller noyscrol margin-5down">
          {Navs.map((item, index) => {
            return (
              <div
                key={index}
                className={`breadNav_light_second_small sidepad ${
                  activeElement2 === index ? "div_active link forceblack"
                      : "dormant link forceblack"
                }`}
                onClick={() => {
                  setstatsToShow2(item);
                  handleNavClick3(0);
                  if (statsToShow === "Goals") {
                    setstatsToShow3("Over " + statsToShow + " " + item);
                  } else if (statsToShow === "Win-Draw-Lose") {
                    setstatsToShow3("Home Win");
                  } else if (statsToShow === "Cards") {
                    setstatsToShow3("Over " + statsToShow + " " + item);
                  } else if (statsToShow === "Corners") {
                    setstatsToShow3("Over " + statsToShow + " " + item);
                  }

                  handleNavClick2(index);
                }}
              >
                {isSmallScreen ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  function ThirdLayerSubLinks({ link1, link2 }) {
    //This handles the display of sublinks for different modal footer
    const Nav1 = [
      "Home Win",
      "Away Win",
      "Draw",
      "Any Team Win",
      "HWin EH",
      "HWin EH No",
      "AWin EH",
      "AWin EH No",
      "D EH",
      "D EH No",
    ];
    const Nav3 = [
      "Home Win",
      "Away Win",
      "Draw",
      
    ];
    const Nav2 = [
      "Over " + link1 + " " + link2,
      "Under " + link1 + " " + link2,
    ];
    let Navs = "";
    if (statsToShow === "Win-Draw-Lose") {
      Navs = Nav1;
    }else if(statsToShow === "WDL-Cards" || statsToShow === "WDL-Corners") {
      Navs = Nav3;
    } else {
      Navs = Nav2;
    }

    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {Navs.map((item, index) => {
            return (
              <div
                key={index}
                className={`breadNav_light_second_small sidepad ${
                  activeElement3 === index ? "div_active link forceblack"
                      : "dormant link forceblack"
                }`}
                onClick={() => {
                  setstatsToShow3(item);
                  handleNavClick3(index);
                }}
              >
                {isSmallScreen ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  function ForthLayerSubLinks({ link1, link2 }) {
    //This handles the display of sublinks for different modal footer
    const Navs1 = ["Match", "Home Team", "Away Team"];
    const Navs2 = [
      "Match",
      "BTS Yes",
      "BTS No",
      "Home Team Goals",
      "Home Team To Score",
      "Away Team Goals",
      "Away Team To Score",
    ];
    let Navs = "";
    if (statsToShow !== "Goals") {
      Navs = Navs1;
    } else if (statsToShow === "Goals") {
      Navs = Navs2;
    }

    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {Navs.map((item, index) => {
            return statsToShow !== "Win-Draw-Lose" && statsToShow !== "WDL-Cards" && statsToShow !== "WDL-Corners"? (
              <>
                <div
                  key={index}
                  className={`breadNav_light_second_small sidepad ${
                    activeElement4 === index ? "div_active link forceblack"
                      : "dormant link forceblack"
                  }`}
                  onClick={() => {
                    setstatsToShow4(item);
                    handleNavClick4(index);
                  }}
                >
                  {isSmallScreen ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
                </div>
              </>
            ) : null;
          })}
        </div>
      </div>
    );
  }

  ////console.log(isLogedin, globalUser);
  return (
    <div className="light">
    {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        loading={loading}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isLogedin={isLogedin}
        issubscribed={issubscribed}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
      />
      {isMidScreen || isSmallScreen ? (
        <IndexSubheads
          sidenavOpen={sidenavOpen}
          setSidenavOpen={setSidenavOpen}
          islgedin={isLogedin}
          issubed={issubscribed}
          setticketactive={setticketactive}
          
          setShows={setShows}
          data={useSideNav}
          type={"league"}
        />
      ) : null}

      {isSmallScreen || isMidScreen ? (
        <div className="allpad">
          {/* {isMidScreen || isSmallScreen ? (
            <div className="allpad">
              <Button
                className="webButton margin10down"
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              >
                Top Leagues
              </Button>
            </div>
          ) : null} */}
          {(isDropdownVisible && isMidScreen) ||
          (isDropdownVisible && isSmallScreen) ? (
            <Row>
              <Col md="6" sm="11" className="usemaxSpace center_div">
                <div className="allpad">
                  <Container className="cont_ad">
                    <LeagueNav
                      setticketactive={setticketactive}
                      setShows={setShows}
                      data={useSideNav}
                      countryClick={""}
                      islgedin={isLogedin}
                      issubed={issubscribed}
                    />
                  </Container>
                </div>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <div style={{}}>
                {/* {isclickTicket && (
                  <div>
                    <ShowTicket show={Shows} />
                  </div>
                )} */}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: "-10px",
                    fontFamily: "serif",
                    fontWeight: "bolder",
                    fontSize: "30px",
                    color: "grey",
                  }}
                >
                  Bet Option Search
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      marginBottom: "-10px",
                      fontFamily: "serif",
                      fontWeight: "bolder",
                      marginTop: "10px",
                      fontSize: "18px",
                      color: "black",
                    }}
                  >
                    <p> Dont know what to bet on? We got you covered! </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      marginBottom: "-10px",
                      fontFamily: "serif",
                      fontWeight: "bolder",
                      marginTop: "10px",
                      fontSize: "18px",
                      color: "black",
                    }}
                  >
                    <p> Search our database for best options</p>
                  </div>
                  <div>
                    <DatePicker fix_date={setUseDate} />
                  </div>
                </div>
                <div className="">
                  <div>
                    {showTopSbar?.status === true && <TopLayerSubLinks />}
                  </div>
                  <div>
                    {showTopSbar?.status === true &&
                      showFirstSbar?.status === true && <FirstLayerSubLinks />}
                  </div>
                  <div>
                    {showTopSbar?.status === true &&
                      showFirstSbar?.status === true && (
                        <SecondLayerSubLinks Links_={showFirstSbar?.target} />
                      )}
                  </div>
                  <div>
                    {showTopSbar?.status === true &&
                      showFirstSbar?.status === true && (
                        <ThirdLayerSubLinks
                          link1={statsToShow}
                          link2={statsToShow2}
                        />
                      )}
                  </div>
                  <div>
                    {showTopSbar?.status === true &&
                      showFirstSbar?.status === true && <ForthLayerSubLinks />}
                  </div>
                </div>
                {issubscribed ? (
                  !loading ? (
                    <DisplayRanking
                      Match={MatchID}
                      date={useDate?.day || useDate}
                      Top={statsToShowTop}
                      Tab2={statsToShow}
                      half={statsToShow2}
                      opt3={statsToShow3}
                      opt4={statsToShow4}
                      small={isSmallScreen}
                      mid={isMidScreen}
                      isGosClose={isGosClose}
                    />
                  ) : (
                    <Spinner />
                  )
                ) : (
                  <div>
                    <div className="margin30down mainFont">
                      <div className="">
                        <div className="center_div infoFont margin-30down">
                          Subscribe to get access to our Advanced Statistics
                        </div>
                        <div className="center_div infoFont margin-30down">
                          We have done all the calculations so you do not have
                          to
                        </div>
                        <div
                          className="link smallbuton  divorangeButton"
                          onClick={() => navigation("/pricing")}
                        >
                          Subscribe
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Container >
          <div className="flex center_sides" >
            <div className="" style={{width:"30%"}}>
              <LeagueNav
                setticketactive={setticketactive}
                
                setShows={setShows}
                data={useSideNav}
                countryClick={""}
                islgedin={isLogedin}
                issubed={issubscribed}
              />
            </div>

            <div>
              {/* {isclickTicket && (
                <div>
                  <ShowTicket show={Shows} />
                </div>
              )} */}
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  marginBottom: "-10px",
                  fontFamily: "serif",
                  fontWeight: "bolder",
                  fontSize: "30px",
                  color: "grey",
                }}
              >
                Bet Option Search
              </div>
              <Container>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: "-10px",
                    fontFamily: "serif",
                    fontWeight: "bolder",
                    marginTop: "10px",
                    fontSize: "18px",
                    color: "black",
                  }}
                >
                  <p> Dont Know what to bet on? We got you covered! </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: "-10px",
                    fontFamily: "serif",
                    fontWeight: "bolder",
                    marginTop: "10px",
                    fontSize: "18px",
                    color: "black",
                  }}
                >
                  <p> Search our database for best options</p>
                </div>
              </Container>
              <div>
                <DatePicker fix_date={setUseDate} />
              </div>
              <div className="light">
                <Container className="">
                  {showTopSbar?.status === true && <TopLayerSubLinks />}
                </Container>
                <Container className="">
                  {showTopSbar?.status === true &&
                    showFirstSbar?.status === true && <FirstLayerSubLinks />}
                </Container>
                <Container className="">
                  {showTopSbar?.status === true &&
                    showFirstSbar?.status === true && (
                      <SecondLayerSubLinks Links_={showFirstSbar?.target} />
                    )}
                </Container>
                <Container className="">
                  {showTopSbar?.status === true &&
                    showFirstSbar?.status === true && (
                      <ThirdLayerSubLinks
                        link1={statsToShow}
                        link2={statsToShow2}
                      />
                    )}
                </Container>
                <Container className="cont_ad">
                  {showTopSbar?.status === true &&
                    showFirstSbar?.status === true && <ForthLayerSubLinks />}
                </Container>
              </div>

              {issubscribed ? (
                !loading ? (
                  <div>
                    <DisplayRanking
                      date={useDate?.day || useDate}
                      Match={MatchID}
                      Top={statsToShowTop}
                      Tab2={statsToShow}
                      half={statsToShow2}
                      opt3={statsToShow3}
                      opt4={statsToShow4}
                      small={isSmallScreen}
                      mid={isMidScreen}
                      isGosClose={isGosClose}
                    />
                  </div>
                ) : (
                  <Spinner />
                )
              ) : (
                <div>
                  <div className="margin30down mainFont">
                    <div className="">
                      <div className="center_div infoFont margin-30down">
                        Subscribe to get access to our Advanced Statistics
                      </div>
                      <div className="center_div infoFont margin-30down">
                        We have done all the calculations so you do not have to
                      </div>
                      <div
                        className="link smallbuton  divorangeButton"
                        onClick={() => navigation("/pricing")}
                      >
                        Subscribe
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <PlayYouTube VideoId={"rddGY4u7G7I"}/>
        </Container>
      )}

      <FooterAll />
      <FooterNav/>
    </div>
  );
}