import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  NavLink,
  Button,
  Container,
  DropdownButton,
  Dropdown,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import LeagueNav from "../LeagueSideBar";
import countryCodeLookup from "country-code-lookup";
import DisplayMatchHistory from "./MatchHistory";
import MatchesStatistics from "./MatchesStatisticsDisplay";
import MatchLeagueStats from "./MatchLeague";
import Team_image from "../../images/team_images.json";
import { SpinnerCustomMatch } from "../Spinner";
import FooterAll from "../Footer";
import { Errorlog, SideNavPage } from "../Utils";
import ShowTicket from "../ShowTicketGames";
import DisplayMatchDetails from "../ChartComponents/MatchDetails";
import IndexSubheads from "../IndexSubheader";
import LeagueTablesMainSmall from "./LeagueTableSimple";
import EnterCommentsAnonymous from "../CommentComponents/AnonymousComment";
import FooterNav from "../SmallScreenNav";
import Markets from "../ChartComponents/OddsMarket";
import MatchPredictions from "../ChartComponents/PredictionMarket";
import { CatchedStoraged } from "../manageStorage/CatchedData";
import PlayYouTube from "../VideoPlayer/YouTubePlayer";

const API_URL = process.env.REACT_APP_API_URL;
const footy = process.env.REACT_APP_FOOTYSPORTS;
const footyRoot = process.env.REACT_APP_FOOTY_ROOT
function removeDuplicatesByKey(arrayOfDicts, key) {
  const uniqueDicts = [];

  // Filtering out duplicates based on the specified key
  arrayOfDicts.forEach((dict) => {
    if (!uniqueDicts.some((uniqueDict) => uniqueDict[key] === dict[key])) {
      uniqueDicts.push(dict);
    }
  });

  return uniqueDicts;
}
function MatchStats(params) {
  const [useFixture, setFixture] = useState({});

  const [loading, setLoading] = useState(true);
  
  const [showSecondSbar, setshowSecondSbar] = useState({
    target: "All",
    status: true,
  });
  const [showThirdSbar, setshowThirdSbar] = useState({
    target: "Win-Draw-Lose",
    status: true,
  });
  
  const [statsToShow1, setstatsToShow1] = useState("All");
  const [statsToShow2, setstatsToShow2] = useState("Full Time");
  const [statsToShow3, setstatsToShow3] = useState("Win-Draw-Lose");
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const location = useLocation();
  const MatchID = location.state?.match_id || location.state || " ";
  const team1 = location?.state?.team1 || " ";
  const seas = location?.state?.seas || " ";
  const team2 = location?.state?.team2 || " "; // Initialize with an empty object if undefined
  const league = location.state?.league || " ";
  const lagueNavData = location.state?.data || " ";
  const tab = location?.state?.tab || null;
  const [statsToShow, setstatsToShow] = useState(tab ?? "MATCH DETAILS");
  const [showFirstSbar, setshowFirstSbar] = useState({
    target: tab ?? "MATCH DETAILS",
    status: tab ? true : false,
  });
    //console.log(statsToShow, showFirstSbar)
  /* const urlParams = new URLSearchParams(window.location.search);
  
  const MatchID = urlParams.get('match_id') || urlParams.get('state') || " ";
  const team1 = urlParams.get('team1') || " ";
  const team2 = urlParams.get('team2') || " ";
  const seas = urlParams.get('seas') || " ";
  const league = String(urlParams.get('league')) || " ";
  const lagueNavData = urlParams.get('data') || " "; */

  const [useSideNav, setUseSideNav] = useState(lagueNavData);
  const [useStatistics, setuseStatistics] = useState();
  const [askleague, setaskleague] = useState(String(league));
  const [jointLeague, setJointLeague] = useState(String(league));
  const [useleagueName, setuseleagueName] = useState();
  const [activeElement, setActiveElement] = useState(tab ? 4 : 0);
  const [activeElement1, setActiveElement1] = useState(0);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [useTable, setUSeTable] = useState(false);
  const [activeElement2, setActiveElement2] = useState(0);
  const [activeElement3, setActiveElement3] = useState(0);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isGosClose, setisGosClose] = useState(false);
  const [footyData, setFootyData] = useState();
  const [Shows, setShows] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [isticketError, setIsTicketError] = useState(false)
  const [ticketactive, setticketactive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const[isStarted, setIsStarted] = useState(false)
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
    if (window.innerWidth < 1360) {
      setisGosClose(true);
    } else {
      setisGosClose(false);
    }
  };

  const navigation = useNavigate();
  /* ////console.log(team1, team2, location); */

  useEffect(() => {

    const fetchData = async () => {
      let options = {
        body: JSON.stringify({ match: MatchID }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60,
        },

        seconds: 2 * 60 ,
      };
      let url = "/realtimematch";
      let indexdb = "realtimematch";
      let SaveKey = MatchID
      if (!MatchID) {
        return null;
      }
      setLoading(true);
      try {
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/realtimematch`,
          JSON.stringify({ match: MatchID }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        
        const fixdata = JSON.parse(res?.data)
        if(res?.status === "success"){
          setFootyData(fixdata)
        }
        //console.log(fixdata)
      } catch (error) {
        Errorlog(error, "error loading statistics", "from match stats");
      }
    };

    fetchData();
  }, [MatchID, setFixture]);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    const fetchData = async () => {

      let options = {
        body: JSON.stringify({ match: MatchID }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60 * 60,
        },

        seconds: 2 * 60 * 60,
      };
      let url = "/match_landing";
      let indexdb = "match_landing";
      let SaveKey = MatchID
      if (!MatchID) {
        return null;
      }
      setLoading(true);
      try {
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/match_landing`,
          JSON.stringify({ match: MatchID }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        const fix_data = res
        ////console.log("loaded fetchdata", fix_data?.status);

        if (fix_data && fix_data.status === "success") {
          setFixture(JSON.parse(fix_data && fix_data.data));
          setLoading(false);
          setUSeTable(true);
          
          ////console.log(JSON.parse(fix_data && fix_data?.data));
        }
      } catch (error) {
        Errorlog(error, "error loading statistics", "from match stats");
      }
    };

    fetchData();
  }, [MatchID, setFixture]);
  useEffect(() => {
    const fetchData = async () => {
      let ckUser;
      if (!globalUser.user) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
      }
      if (ckUser && ckUser.result === "retrieved") {
        updateGlobalUser(ckUser?.data?.user);
        try {
          axios.post(`${API_URL}/active_users_save`, {
            email: ckUser?.data?.user?.user?.email,
            page: "MatchStats",
          });
        } catch (error) {
          ("do noithing");
        }
        if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "inactive"
        ) {
          setIsSubscribed(false);
        } else if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
          setIsLogedin(false);
        }
      } else {
        if (globalUser) {
          if (
            (globalUser &&
              globalUser?.user?.firstname === "Guest" &&
              globalUser?.user) ||
            (globalUser && Object.keys(globalUser).length === 0)
          ) {
            setIsLogedin(false);
            setIsSubscribed(false);
          } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
            if (
              globalUser &&
              !globalUser?.user?.subscription &&
              globalUser?.user
            ) {
              ////console.log(globalUser);
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              if (
                globalUser &&
                globalUser?.user?.subscription?.status === "inactive"
              ) {
                setIsLogedin(true);
                setIsSubscribed(false);
              } else {
                setIsLogedin(true);
                setIsSubscribed(true);
              }
            }
          }
        }
      }
      //***removing supscription****
      setIsSubscribed(true)
    };

    fetchData();
  }, []);

  const teamA = useFixture?.data?.header?.home;
  const teamB = useFixture?.data?.header?.away;
  ////console.log(useFixture)
  const handleNavClick = (id) => {
    setActiveElement(id);
  };
  const handleNavClick1 = (id) => {
    setActiveElement1(id);
  };
  const handleNavClick2 = (id) => {
    setActiveElement2(id);
  };
  const handleNavClick3 = (id) => {
    setActiveElement3(id);
  };
  const showFirstSbar_ = ({ target }) => {
    setshowFirstSbar(target);
    ////console.log("reset showfirstbar", target);
  };
  const showThirdSbar_ = ({ target }) => {
    setshowThirdSbar(target);
    ////console.log("reset showfirstbar", target);
  };
  function MainNav(params) {
    // this  handles the fotter of the modal display
    //const team_navs = ["H2H HISTORY", "STATISTICS", "STANDINGS", "PREDICTION"];
    const team_navs = [
      "MATCH DETAILS",
      "H2H HISTORY",
      "STATISTICS",
      "STANDINGS",
      "PREDICTIONS",
      "MARKETS",
      "FANS PAGE",
    ];
    return (
      <div>
        <hr />
        <div
          className=" left_div  center_sides nav_scroller noyscrol"
          style={{
            marginTop: "-12px",
            whiteSpace: "nowrap",
          }}
        >
          {team_navs.map((item, index) => {
            return (
              <div
                key={index}
                className={`breadNav_light ${
                  activeElement === index
                    ? "div_active link forceblack"
                    : "dormant link forceblack"
                } smallFont`}
                onClick={() => {
                  setstatsToShow(item);
                  handleNavClick1(0);
                  handleNavClick2(0);
                  handleNavClick3(0);
                  if (item === "H2H HISTORY") {
                    setstatsToShow3("Win-Draw-Lose");
                    setstatsToShow2("Full Time");
                  } else if (item === "STATISTICS") {
                    setstatsToShow3("All");
                    setstatsToShow2("Full Time");
                  }
                  if (item === "MATCH DETAILS") {
                    showFirstSbar_({
                      target: { target: item, status: false },
                    });
                    showThirdSbar_({
                      target: { target: item, status: false },
                    });
                  } else {
                    showFirstSbar_({
                      target: { target: item, status: true },
                    });
                    showThirdSbar_({
                      target: { target: item, status: true },
                    });
                  }

                  handleNavClick(index);
                }}
              >
                {isSmallScreen ? (
                  <div className="tiny_font">{item}</div>
                ) : (
                  <>{item}</>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  function unixTimeToFullDate(unixTimestamp) {
    // Multiply by 1000 to convert from seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Get individual components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format the date
    const formattedDate = `${month}.${day}.${year} ${hours}:${minutes}`;

    return formattedDate;
  }
  function FirstLayerSubLinks({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const team_a_name = useFixture?.data?.header?.[teamA]?.team_data?.team_name;
    const team_b_name = useFixture?.data?.header?.[teamB]?.team_data?.team_name;
    const Navs = ["All", team_a_name + " Home", team_b_name + " Home"];

    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides">
          {Navs.map((item, index) => {
            if (Links_ === "H2H HISTORY" || Links_ === "STATISTICS") {
              return (
                <div
                  key={index}
                  className={`breadNav_light_second_small sidepad ${
                    activeElement1 === index
                      ? "div_active link forceblack"
                      : "dormant link forceblack"
                  }`}
                  onClick={() => {
                    setstatsToShow1(item);
                    handleNavClick1(index);
                  }}
                >
                  {isSmallScreen ? (
                    <div className="tiny_font">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
  function SecondLayerSubLinks({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const Navs = ["Full Time", "First Half", "Second Half"];

    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides">
          {Navs.map((item, index) => {
            if (Links_ === "H2H HISTORY" || Links_ === "STATISTICS") {
              return (
                <div
                  key={index}
                  className={`breadNav_light_second_small sidepad ${
                    activeElement2 === index
                      ? "div_active link forceblack"
                      : "dormant link forceblack"
                  }`}
                  onClick={() => {
                    setstatsToShow2(item);
                    handleNavClick2(index);
                  }}
                >
                  {isSmallScreen ? (
                    <div className="tiny_font">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
  function ThirdLayerSubLinks({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const Navs1 = [
      "Win-Draw-Lose",
      "WDL-Cards",
      "WDL-Corners",
      "Goals",
      "Cards",
      "Corners",
    ];
    const Navs2 = ["All", "10 Games", "5 Games"];
    let Navs = "";

    if (statsToShow === "H2H HISTORY") {
      Navs = Navs1;
    } else {
      Navs = Navs2;
    }

    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {Navs.map((item, index) => {
            if (Links_ === "H2H HISTORY" || Links_ === "STATISTICS") {
              return (
                <div
                  key={index}
                  className={`breadNav_light_second_small sidepad ${
                    activeElement3 === index
                      ? "div_active link forceblack"
                      : "dormant link forceblack"
                  }`}
                  onClick={() => {
                    setstatsToShow3(item);
                    handleNavClick3(index);
                  }}
                >
                  {isSmallScreen ? (
                    <div className="tiny_font nowrap">{item}</div>
                  ) : (
                    <>{item}</>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
  function BreadLinks() {
    /* const leagues = useFixture?.data?.team_info?.league_info;
      const league = GetLeagueName(leagues, lg); */
    ////console.log(league, lg);
    const team_navs = [
      useFixture?.data?.header?.League_name?.country,
      useFixture?.data?.header?.League_name?.league_name,
      seas,
    ];
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <div className=" left_div smallFont center_sides">
          {team_navs.map((item, index) => (
            <div key={index} className="breadNav_light">
              {isSmallScreen ? (
                <div className="tiny_font nowrap">
                  {item}
                  {"  >"}
                </div>
              ) : (
                <>
                  {item}
                  {"  >"}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  const handleteamclick = ({ val, team_, league }) => {
    const team = val;
    navigation("/team/" + team_, { state: { team, league } });
  };

  const DisplayTrail = (strings_) => {
    const col = {
      L: "red",
      w: "green",
      D: "orange",
    };
    const ind_col = strings_?.split("");
    return (
      <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
        {ind_col?.slice(0, 5).map((item, index) => (
          <div
            key={index}
            style={{
              fontFamily: "serif",
              fontWeight: "bold",
              fontSize: isSmallScreen ? "10px" : "16px",
              textTransform: "uppercase",
              marginRight: "2px",
              background: col?.[item],
              padding: "3px 6px", // add padding
              border: "none", // remove border
              borderRadius: "5px", // round corners
              color: "#ffffff", // white text
              cursor: "pointer", // change cursor on hover
              display: "inline-block", // arrange horizontally
              textAlign: "center", // center text
            }}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };
  function TeamHead({ teamA, teamB }) {
    // this displays the main modal head of the teams
    const country = useFixture?.header?.[teamA]?.team_country;
    return (
      <div className="margin-10down ">
        <Modal.Dialog>
          <BreadLinks />

          <Modal.Body>
            {isSmallScreen ? (
              <>
                <div className="center_div_distributed center_sides">
                  <div className="left_div center_straight">
                    <div className="center_div">
                      <img
                        src={Team_image?.[String(teamA)]?.["image"]}
                        alt={
                          useFixture?.data?.header?.[teamA]?.team_data
                            ?.team_name
                        }
                        width="90"
                        height="75"
                        className="link"
                        onClick={() =>
                          handleteamclick({
                            val: teamA,
                            team_:
                              useFixture?.data?.header?.[teamA]?.team_data
                                ?.team_name,
                            league: useFixture?.data?.header?.league_id,
                          })
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          width: "25px",
                          height: "20px",
                          background:
                            useFixture?.data?.header?.[teamA]?.form?.[1],
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          fontFamily: "serif",
                          fontSize: "25px",
                        }}
                      >
                        {useFixture?.data?.header?.[teamA]?.form?.[0]}
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                          <div>
                            {DisplayTrail(
                              useFixture?.data?.header?.[teamA]?.form?.[2]
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                      <div style={{width:"95px"}}>
                            <h6
                            className="awayTable"
                            style={{
                              paddingBottom: "0px",
                              fontWeight: "bold",
                            }}
                          >
                            {
                              useFixture?.data?.header?.[teamA]?.team_data
                                ?.team_name
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="">
                      <div className="usemaxSpace center_div tiny_font">
                        {unixTimeToFullDate(useFixture?.data?.header?.date)}
                      </div>
                      <div className="center_div center_sides mainFont">
                        <div className=" largeheadfont bold ">
                          {footyData?.homeGoalCount ?? useFixture?.data?.header?.score?.[0]}
                        </div>
                        <div className="largeheadfont bold allpad">{"-"}</div>

                        <div className=" largeheadfont bold">
                          {footyData?.awayGoalCount ?? useFixture?.data?.header?.score?.[1]}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize:"12px",
                            fontFamily: "serif",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          {footyData?.status ?? useFixture?.data?.header?.status}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right_div_main center_straight">
                    <div className=" center_div center_sides">
                      <div
                        style={{
                          display: "flex",
                          width: "25px",
                          height: "20px",
                          background:
                            useFixture?.data?.header?.[teamB]?.form?.[1],
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          fontFamily: "serif",
                          fontSize: "25px",
                        }}
                      >
                        {useFixture?.data?.header?.[teamB]?.form?.[0]}
                      </div>

                      <img
                        src={Team_image?.[String(teamB)]?.["image"]}
                        width="90"
                        height="75"
                        alt={
                          useFixture?.data?.header?.[teamB]?.team_data
                            ?.team_name
                        }
                        className="link"
                        onClick={() =>
                          handleteamclick({
                            val: teamB,
                            team_:
                              useFixture?.data?.header?.[teamB]?.team_data
                                ?.team_name,
                            league: useFixture?.data?.header?.league_id,
                          })
                        }
                      />
                    </div>
                    <div>
                      <div>
                        {DisplayTrail(
                          useFixture?.data?.header?.[teamB]?.form?.[2]
                        )}
                      </div>

                      <div style={{width:"95px"}}>
                        <h6
                        className="awayTable"
                          style={{
                            paddingBottom: "0px",
                            fontWeight: "bold",
                          }}
                        >
                          {
                            useFixture?.data?.header?.[teamB]?.team_data
                              ?.team_name
                          }
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="center_div_distributed center_sides">
                  <div className="left_div ">
                    <div>
                      <img
                        src={Team_image?.[String(teamA)]?.["image"]}
                        alt={
                          useFixture?.data?.header?.[teamA]?.team_data
                            ?.team_name
                        }
                        className="link"
                        onClick={() =>
                          handleteamclick({
                            val: teamA,
                            team_:
                              useFixture?.data?.header?.[teamA]?.team_data
                                ?.team_name,
                            league: useFixture?.data?.header?.league_id,
                          })
                        }
                      />
                    </div>
                    <div>
                      <div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              width: "50px",
                              height: "40px",
                              background:
                                useFixture?.data?.header?.[teamA]?.form?.[1],
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                              fontFamily: "serif",
                              fontSize: "50px",
                              marginTop: "20px",
                            }}
                          >
                            {useFixture?.data?.header?.[teamA]?.form?.[0]}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div>
                            {DisplayTrail(
                              useFixture?.data?.header?.[teamA]?.form?.[2]
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                      <div style={{width:"130px"}}>
                            <h6
                            className="awayTable"
                            style={{
                              paddingTop: "15px",
                              paddingBottom: "0px",
                              fontWeight: "bold",
                            }}
                          >
                            {
                              useFixture?.data?.header?.[teamA]?.team_data
                                ?.team_name
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="">
                      <div>
                        <h6>
                          {unixTimeToFullDate(useFixture?.data?.header?.date)}
                        </h6>
                      </div>
                      <div className="center_div center_sides mainFont">
                        <div className=" xlfont bold">
                          {footyData?.homeGoalCount ?? useFixture?.data?.header?.score?.[0]}
                        </div>
                        <div className="xlfont bold allpad">{"-"}</div>

                        <div className=" xlfont bold">
                          {footyData?.awayGoalCount ?? useFixture?.data?.header?.score?.[1]}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontFamily: "serif",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                        >
                          {footyData?.status ?? useFixture?.data?.header?.status}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right_div_main">
                    <div className=" center_div center_sides">
                      <div>
                        <div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                width: "50px",
                                height: "40px",
                                background:
                                  useFixture?.data?.header?.[teamB]?.form?.[1],
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                fontFamily: "serif",
                                fontSize: "50px",
                                marginTop: "20px",
                                marginLeft: "80px",
                              }}
                            >
                              {useFixture?.data?.header?.[teamB]?.form?.[0]}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div>
                              {DisplayTrail(
                                useFixture?.data?.header?.[teamB]?.form?.[2]
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div style={{width:"130px"}}>
                            <h6
                            className="awayTable"
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "0px",
                                fontWeight: "bold",
                              }}
                            >
                              {
                                useFixture?.data?.header?.[teamB]?.team_data
                                  ?.team_name
                              }
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div>
                        <img
                          src={Team_image?.[String(teamB)]?.["image"]}
                          alt={
                            useFixture?.data?.header?.[teamB]?.team_data
                              ?.team_name
                          }
                          className="link"
                          onClick={() =>
                            handleteamclick({
                              val: teamB,
                              team_:
                                useFixture?.data?.header?.[teamB]?.team_data
                                  ?.team_name,
                              league: useFixture?.data?.header?.league_id,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <MainNav />
        </Modal.Dialog>
      </div>
    );
  }
  const countryClick = {
    index: "",
    item: useFixture?.head_data?.team_country,
    clicker: "other",
  };
  if (!useFixture) {
    return null;
  }
  function RouteStats({ Links_ }) {
    if (Links_ && Links_ === "H2H HISTORY" && useTable === true) {
      ////console.log(statsToShow, statsToShow1, statsToShow2, statsToShow3);
      const team_a_name =
        useFixture?.data?.header?.[teamA]?.team_data?.team_name;
      const team_b_name =
        useFixture?.data?.header?.[teamB]?.team_data?.team_name;

      let own_t = "";
      if (statsToShow1 === team_a_name + " Home") {
        own_t = teamA;
      } else if (statsToShow1 === team_b_name + " Home") {
        own_t = teamB;
      } else {
        own_t = "All";
      }
      ////console.log(useFixture?.data)
      return (
        <DisplayMatchHistory
          bias={own_t}
          Tab2={statsToShow3}
          half={statsToShow2}
          teamA={teamA}
          teamB={teamB}
          small={window.innerWidth < 770}
          mid={isMidScreen}
        />
      );
    }

    if (Links_ && Links_ === "MATCH DETAILS") {
      
      //console.log(useFixture?.data?.header?.MATCH_DETAILS?.data)
      return (
        <DisplayMatchDetails
          Data={useFixture?.data?.header?.MATCH_DETAILS?.data} footy={footyData}
        />
      );
    }
    if (Links_ && Links_ === "MARKETS") {
      
      //console.log(useFixture?.data?.header?.MATCH_DETAILS?.data)
      return (
        <Markets
          Data={useFixture?.data?.header?.MATCH_DETAILS?.data} data={footyData} setIsStarted={setIsStarted} setIsTicketError={setIsTicketError}
        />
      );
    }
    if (Links_ && Links_ === "STATISTICS" && useTable === true) {
      ////console.log(statsToShow, statsToShow1, statsToShow2, statsToShow3);
      const team_a_name =
        useFixture?.data?.header?.[teamA]?.team_data?.team_name;
      const team_b_name =
        useFixture?.data?.header?.[teamB]?.team_data?.team_name;

      let own_t = "";
      if (statsToShow1 === team_a_name + " Home") {
        own_t = teamA;
      } else if (statsToShow1 === team_b_name + " Home") {
        own_t = teamB;
      } else {
        own_t = "All";
      }
      /* const datal = removeDuplicatesByKey(
        useFixture?.data?.["H2H HISTORY"]?.[own_t],
        "date_unix"
      ); */
      ////console.log(useFixture)
      return (
        <div>
          {!issubscribed ? (
            <div>
              <div className="margin30down mainFont">
                <div className="">
                  <div className="center_div infoFont margin-30down">
                    Subscribe to get access to our Advanced Statistics
                  </div>
                  <div className="center_div infoFont margin-30down">
                    We have done all the calculations so you do not have to
                  </div>
                  <div
                    className="link smallbuton  divorangeButton"
                    onClick={() => navigation("/pricing")}
                  >
                    Subscribe
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <MatchesStatistics
                leagueId={useFixture?.data?.header?.league_id}
                match={useFixture?._id}
                Tab1={own_t}
                Tab2={statsToShow3}
                half={statsToShow2}
                teamA={teamA}
                teamB={teamB}
                small={isSmallScreen}
                mid={isMidScreen}
              />
            </div>
          )}
        </div>
      );
    }
    if (Links_ && Links_ === "STANDINGS" && useTable === true) {
      
      return (
        <div>
          <LeagueTablesMainSmall
            teamA={teamA}
            teamB={teamB}
            askleague={useFixture?.data?.header?.league_id}
            small={isSmallScreen}
            mid={isMidScreen}
          />
        </div>
      );
    }
    if (Links_ && Links_ === "PREDICTIONS" && useTable === true) {
      
      return (
        <div>
          <MatchPredictions
            MatchID={MatchID}
            teamA={teamA}
            teamB={teamB}
          />
        </div>
      );
    }
    if (Links_ && Links_ === "FANS PAGE" && useTable === true) {
      //console.log(useFixture?.data)
      const obj = {
        originalOwner: {name: ""+teamA+"_"+teamB,
                email: useFixture?.data?.header?.MATCH_DETAILS?._id                ,},
        targetObject: String(useFixture?.data?.header?.MATCH_DETAILS?._id ),
        targetDB: "MatchComments",
        site:"/Matches",
        object:String(useFixture?.data?.header?.MATCH_DETAILS?._id),
        targetPage: "/Matches",
        comments: useFixture?.data?.comments,
      }
      return (
        <div>
          <EnterCommentsAnonymous
            ObjectItem={obj}
          />
        </div>
      );
    }
  }
  //console.log(statsToShow)
  return (
    <div className="light">
    {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        loading={loading}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        isLogedin={isLogedin}
        issubscribed={issubscribed}
        setIsOpen={setIsOpen}
      />
      {isMidScreen || isSmallScreen ? (
        <IndexSubheads
          sidenavOpen={sidenavOpen}
          setSidenavOpen={setSidenavOpen}
          islgedin={isLogedin}
          issubed={issubscribed}
          setticketactive={setticketactive}
          setShows={setShows}
          type={"match"}
        />
      ) : null}
      {loading ? (
        <div style={{ height: "600px" }}>
          <SpinnerCustomMatch
            image1={Team_image?.[String(team1)]?.["image"]}
            image2={Team_image?.[String(team2)]?.["image"]}
          />
        </div>
      ) : isMidScreen || isSmallScreen ? (
        <div>
          {(isDropdownVisible && isMidScreen) ||
          (isDropdownVisible && isSmallScreen) ? (
            <div>
              <div className="usemaxSpace center_div">
                <div className="allpad">
                  <Container className="cont_ad">
                    <LeagueNav
                      data={useSideNav}
                      countryClick={countryClick}
                      islgedin={isLogedin}
                      issubed={issubscribed}
                      setticketactive={setticketactive}
                      setShows={setShows}
                    />
                  </Container>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <div className="allpad">
              <div className="">
                {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows}/>
                        </div>
                      )} */}
                {useFixture && <TeamHead teamA={teamA} teamB={teamB} />}
                {showFirstSbar?.status === true && (
                  <FirstLayerSubLinks Links_={showFirstSbar?.target} />
                )}
                {showSecondSbar?.status === true && (
                  <SecondLayerSubLinks Links_={showFirstSbar?.target} />
                )}
                {showThirdSbar?.status === true && (
                  <ThirdLayerSubLinks Links_={statsToShow} />
                )}
              </div>
              <div className="margin-10up">
                {useTable === true && (
                  <RouteStats Links_={showFirstSbar?.target} />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Container>
            <div>
              <div className="flex center_sides close_div ">
                <div style={{ width: "20%" }} className="allpad">
                  <LeagueNav
                    data={useSideNav}
                    countryClick={countryClick}
                    islgedin={isLogedin}
                    issubed={issubscribed}
                    setticketactive={setticketactive}
                    setShows={setShows}
                  />
                </div>

                <div className="usemaxSpace ">
                  {loading ? (
                    <SpinnerCustomMatch
                      image1={Team_image?.[String(team1)]?.["image"]}
                      image2={Team_image?.[String(team2)]?.["image"]}
                    />
                  ) : (
                    useFixture && <TeamHead teamA={teamA} teamB={teamB} />
                  )}

                  {showFirstSbar?.status === true && (
                    <FirstLayerSubLinks Links_={showFirstSbar?.target} />
                  )}

                  {showSecondSbar?.status === true && (
                    <SecondLayerSubLinks Links_={showFirstSbar?.target} />
                  )}
                  {showThirdSbar?.status === true && (
                    <ThirdLayerSubLinks Links_={statsToShow} />
                  )}

                  <div className="">
                    {useTable === true && (
                      <RouteStats Links_={showFirstSbar?.target} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <PlayYouTube VideoId={"kOj5uzPAh9Y"}/>
          </Container>
        </>
      )}

      <FooterAll />
      <FooterNav />
    </div>
  );
}

export default MatchStats;
