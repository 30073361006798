import React, { useRef } from "react";
import {
  MDBCardHeader,
  MDBInput,
  MDBCardBody,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBChart,
  MDBBtn,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBSelect,
} from "mdb-react-ui-kit";
import {
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { io } from "socket.io-client";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import FooterAll from "../Footer";
import IndexHeader from "../IndexHeaders";
import { RxCross2 } from "react-icons/rx";
import sportyImage from "../../images/sportyBet.png";
import { UpdateLocal } from "../manageStorage/MaintainLocal";

import care from "../../images/unisex.jpg";
import bgimage from "../../images/uniback.jpg";
import "../../css/ThemeCss.css";

import {
  capitalizeFirstLetter,
  convertUnixToTime,
  SideNavPage,
} from "../Utils";

import FooterNav from "../SmallScreenNav";

import { Themes } from "../indexPage";
import { roundToNearestMinutes } from "date-fns";
import { ShareProfileCopy } from "../CopyLinkButton";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
} from "recharts";
import { Container, Modal, Row, Col } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;



const PublicProfile = () => {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const location = useLocation();
  const userinfo = location.state?.userinfo || {};
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const [curr_location, setcurr_location] = useState("unknown");
  const [userInfo, setUserInfo] = useState(userinfo);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [isclickedShare, setisclickedShare] = useState(false);
  const [socketInstance, setSocketInstance] = useState();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [oddsPerTicket, setOddsPerTicket] = useState([]);
  const [matchPerTicket, setmatchPerTicket] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigation = useNavigate();
  const textRef = useRef(null);
  const userDataInner = location?.state || null;
  const [useMatches, setUseMatches] = useState({});
  const [useProfileCode, setProfileCode] = useState();
  const [profileData, setProfileData] = useState();
  const [authorProfile, setUserProfile] = useState();
  const [authorBackground, setUserBackground] = useState();
  const [profileDataPersonal, setProfilePersonal] = useState();
  const [isFollowingTipstar, setIsFollowingTipstar] = useState(false);
  const [useFollow, setUseFollow] = useState();
  const [useMonths, setUseMonths] = useState();
  const [useMonth, setUseMonth] = useState("");
  //console.log(userDataInner, location);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    let queryData = "";
    let params = new URLSearchParams(window.location.search);
    //console.log(params);
    let profileCode = params.get("publicProfile", null);
    let email = params.get("email", null);
    setProfileCode(profileCode);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let date = new Date();
    let months = [];

    for (let i = 0; i < 7; i++) {
      months.push({
        text: monthNames[date.getMonth()],
        value: monthNames[date.getMonth()],
      });

      // Subtract one month from the date
      date.setMonth(date.getMonth() - 1);
    }

    setUseMonths(months);
    setUseMonth(months[0]["text"]);

    if (userDataInner) {
      queryData = userDataInner;
      setProfileCode(userDataInner?.publicProfile);
    } else if (profileCode || email) {
      queryData = {
        publicProfile: profileCode,
        email: email,
      };
      setProfileCode(profileCode)
    } else {
      ////console.log("there is no ticket to display");
    }

    const fetchData = async () => {
      let res = "";
      ////console.log(queryData);
      if (queryData === "") {
        {
          ("");
        }
      } else {
        queryData["month"] = months[0]["text"];

        try {
          res = await axios
            .post(`${API_URL}/user_public_profile`, JSON.stringify(queryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      }
      //console.log(res, globalUser);
      if (res?.data?.status === "success") {
        let avOddsPerTicket = [];
        let avNoGamesPerTicket = [];
        let data = res?.data?.data?.[months[0]["text"]];
        setProfileData(data);
        setProfilePersonal({
          email: res?.data?.data?.email,
          followers: res?.data?.data?.followers,
          following: res?.data?.data?.following,
          ticketCount: res?.data?.data?.ticketCount,
          tipstarDetails: res?.data?.data?.tipstarDetails,
          profileID: res?.data?.data?._id,
        });
        let labels = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        for (let index = 0; index < labels?.length; index++) {
          const day = labels[index];
          ////console.log(day,data?.averageOddsTicket?.[day]?.value,data?.averageOddsTicket)
          avOddsPerTicket.push(data?.averageOddsTicket?.[day]?.value);
          avNoGamesPerTicket.push(
            data?.averageNumberOfGamesPerTicket?.[day]?.value
          );
        }
        //console.log(avOddsPerTicket);
        setOddsPerTicket(avOddsPerTicket);
        setmatchPerTicket(avNoGamesPerTicket);
        setUseFollow(() => new Set(res?.data?.data?.ticket_meta?.followers));

        if (res?.data?.data?.followers?.includes(globalUser?.user?.email)) {
          setIsFollowingTipstar(true);
        }

        const socket2 = io(BackSocket, {
          auth: {
            token: BackSocketToken,
          },
        });

        setSocketInstance(socket2);
      }
    };
    fetchData();
  }, [globalUser?.user]);

  useEffect(() => {
    ////console.log(useMatches,useMatches?.ticket_meta?.author);
    async function fetchBackground() {
      try {
        const response = await fetch(
          `https://storage.googleapis.com/${StorageBucket}/${profileDataPersonal?.email}_background`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob to a string
        setUserBackground(base64data);
      } catch (error) {
        //console.error(error);
      }
      try {
        const response2 = await fetch(
          `https://storage.googleapis.com/${StorageBucket}/${profileDataPersonal?.email}_profile`
        );
        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }
        const blob2 = await response2.blob();
        const base64data2 = await blob2.text(); // Convert the blob to a string
        setUserProfile(base64data2);
        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [profileDataPersonal?.email]);
  useEffect(()=>{
    const fetchData = async () => {
      let res = "";
      ////console.log(queryData);
      const  queryData ={
        publicProfile:useProfileCode,
        month:useMonth
      } 
     
        try {
          res = await axios
            .post(`${API_URL}/user_public_profile`, JSON.stringify(queryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      
      //console.log(res, globalUser);
      if (res?.data?.status === "success") {
        let avOddsPerTicket = [];
        let avNoGamesPerTicket = [];
        let data = res?.data?.data?.[useMonth];
        setProfileData(data);
        setProfilePersonal({
          email: res?.data?.data?.email,
          followers: res?.data?.data?.followers,
          tipstarDetails: res?.data?.data?.tipstarDetails,
          following: res?.data?.data?.following,
          ticketCount: res?.data?.data?.ticketCount,
          profileID: res?.data?.data?._id,
        });
        let labels = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        for (let index = 0; index < labels?.length; index++) {
          const day = labels[index];
          avOddsPerTicket.push(data?.averageOddsTicket?.[day]?.value);
          avNoGamesPerTicket.push(
            data?.averageNumberOfGamesPerTicket?.[day]?.value
          );
        }
        //console.log(avOddsPerTicket);
        setOddsPerTicket(avOddsPerTicket);
        setmatchPerTicket(avNoGamesPerTicket);
        setUseFollow(() => new Set(res?.data?.data?.ticket_meta?.followers));

        if (res?.data?.data?.followers?.includes(globalUser?.user?.email)) {
          setIsFollowingTipstar(true);
        }

        //console.log("I ran til the end in second rematch")
      }
    };
    fetchData();
  },[useMonth])
  const user = {
    name: "user",
    firstname: "Guest",
    email:
      String.fromCharCode(
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97
      ) + "@betinfoguest.com",
    page: "indexPage",
    theme: Themes?.light,
    dataMode: "low",
    states: {
      theme: { radioValue },
      dataMode: 0,
    },
  };
  const neededInfo = [
    "firstname",
    "lastname",
    "phonenumber",
    "email",
    "address1",
    "address2",
    "dateofbirth",
    "country",
    "creationDate",
    "updatedDate",
    "subscription",
    "verifyEmail",
    "isadmin",
    "city",
    "auth",
    "Tickets",
    "state",
    "policy",
    "Terms",
    "zip",
    "dataMode",
  ];
  const tempInfo = {};
  neededInfo.forEach((key) => {
    if (userinfo?.firstname) {
      if (userinfo.hasOwnProperty(key)) {
        tempInfo[key] = userinfo[key];
      }
    } else {
      if (user.hasOwnProperty(key)) {
        tempInfo[key] = user[key];
      }
    }
  });
  tempInfo["name"] = "user";
  tempInfo["page"] = "index";
  tempInfo["theme"] = Themes?.light;
  //tempInfo["currCountry"] = Demo()
  const userData = { user: tempInfo };

  useEffect(() => {
    const fetchData = async () => {
      let ckUser;
      if (Object.keys(userinfo).length === 0) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log("get");
      } else if (
        Object.keys(globalUser).length === 0 &&
        Object.keys(userinfo).length !== 0
      ) {
        ////console.log("get since global is zero and userinfo is not");
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log(ckUser);
        if (
          ckUser?.data &&
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.firstname !== "Guest" &&
          ckUser?.data?.user?.user
        ) {
          ("do nothing");
          ////console.log("i did nothing in duel");
        } else {
          ////console.log("from local is guest using from login");
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
          setIsLogedin(true);
          if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "inactive"
          ) {
            ////console.log("inactive");
            setIsSubscribed(false);
          } else if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "active"
          ) {
            setIsSubscribed(true);
            ////console.log("active");
          } else {
            setIsSubscribed(false);
            ////console.log("nosub");
          }
        }
      } else {
        ////console.log("save");
        if (
          globalUser &&
          globalUser?.user &&
          globalUser?.user?.firstname !== "Guest"
        ) {
          ("do nothing");
          ////console.log("did nothing");
        } else {
          ////console.log("somehow i skipped this,", globalUser);
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
        }

        setIsLogedin(true);
        if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "inactive"
        ) {
          ////console.log("inactive");
          setIsSubscribed(false);
        } else if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
          ////console.log("active");
        } else {
          setIsSubscribed(false);
          ////console.log("nosub");
        }
      }

      ////console.log(userData);
      if (
        (ckUser && ckUser.result === "saved") ||
        (ckUser && ckUser.result === "retrieved" && ckUser.data !== undefined)
      ) {
        if (ckUser && ckUser.result === "saved") {
          userData["user"]["currCountry"] = curr_location;
          updateGlobalUser(userData);
          ////console.log("wwwasss here in saved to global with user data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "view_ticket",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          setUserInfo(userData);
        } else if (ckUser && ckUser.data && ckUser.data.user) {
          ckUser["data"]["user"]["user"]["currCountry"] = curr_location;

          // *** update login and subs *****
          if (
            (ckUser.data.user &&
              ckUser.data.user?.user?.firstname === "Guest" &&
              ckUser.data.user?.user) ||
            (ckUser.data.user && Object.keys(ckUser.data.user).length === 0)
          ) {
            setIsLogedin(false);
            setIsSubscribed(false);
          } else if (
            ckUser.data.user &&
            ckUser.data.user?.user?.firstname !== "Guest"
          ) {
            if (
              ckUser.data.user &&
              !ckUser.data.user?.user?.subscription &&
              ckUser.data.user?.user
            ) {
              ////console.log(ckUser.data.user);
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              if (
                ckUser.data.user &&
                ckUser.data.user?.user?.subscription?.status === "inactive"
              ) {
                setIsLogedin(true);
                setIsSubscribed(false);
              } else {
                setIsLogedin(true);
                setIsSubscribed(true);
              }
            }
          }
          // *** End update login and subs *****
          updateGlobalUser(ckUser.data.user);
          ////console.log("wwwasss here in saved to global with chuser data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "public profile",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          userData["user"]["currCountry"] = curr_location;
          setUserInfo(userData);
        } else {
          toast.error("An error occured");
        }
      } else {
        userData["user"]["currCountry"] = curr_location;
        ////console.log(" saved to global user with user data logged out");

        updateGlobalUser(userData);
        setUserInfo(userData);
        UpdateLocal({ action: "save", data: userData });
        //toast.info("You are logged out");
      }
    };

    fetchData();
    if (globalUser && Object.keys(globalUser).length === 0) {
      return;
    }
    ////console.log("save");
    if (
      (globalUser &&
        globalUser?.user?.firstname === "Guest" &&
        globalUser?.user) ||
      (globalUser && Object.keys(globalUser).length === 0)
    ) {
      setIsLogedin(false);
      setIsSubscribed(false);
    } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
      if (globalUser && !globalUser?.user?.subscription && globalUser?.user) {
        ////console.log(globalUser);
        setIsLogedin(true);
        setIsSubscribed(false);
      } else {
        if (
          globalUser &&
          globalUser?.user?.subscription?.status === "inactive"
        ) {
          setIsLogedin(true);
          setIsSubscribed(false);
        } else {
          setIsLogedin(true);
          setIsSubscribed(true);
        }
      }
    }
  }, [curr_location]);
  const handleTicketClick = (useType, useTicketID) => {
    const ticketInfo = {
      type: useType,
      ticketID: useTicketID,
    };
    navigation("/ShowTickets", { state: ticketInfo });
  };
  const handleMessageClick = (data) => {
    const currTime = Math.floor(new Date().getTime() / 1000);
    const partner = {
      time: currTime + 1,
      name: data?.ticket_meta?.name,
      email: data?.ticket_meta?.author,
    };
    navigation("/myMessages", { state: partner });
  };
  const handleFollowTipstartClick = () => {
    if (socketInstance) {
      socketInstance.emit("followTipster", {
        data: {
          email: useMatches?.ticket_meta?.author,
          state: !isFollowingTipstar,
        },
        liker: globalUser?.user?.email,
      });
      setIsFollowingTipstar((prev) => !prev);
    }
  };
  ////console.log(profileData && Object.keys(profileData?.ticketTrend))
  const GettoolTip = ({ tooltipData, label }) => {
    let data2use = "";
    for (let index = 0; index < tooltipData.length; index++) {
      if (Object.keys(tooltipData[index])[0] === label) {
        data2use = tooltipData?.[index]?.[label];
      }
    }

    ////console.log(tooltipData,data2use)
    return (
      <div>
        <Modal.Dialog>
          <Modal.Body className="tooltipbg">
            <Container className="justify-content-between align-items-center myContainer">
              <div className="center_div  bold">
                <h6>{label}</h6>
              </div>

              <div>
                <div className="center_div center_sides allpad bold">
                  <div className="allpad">Total Odds :</div>
                  <div>{data2use?.totalOdds}</div>
                </div>
                <div className="center_div center_sides allpad bold">
                  <div className="allpad">number of Games :</div>
                  <div>{data2use?.numGames}</div>
                </div>
                <div className="center_div center_sides allpad bold">
                  <div className="allpad">Potential Win :</div>
                  <div>
                    {"$ "}
                    {data2use?.margin}
                  </div>
                </div>
                <div className="center_div center_sides allpad bold">
                  <div className="allpad">Outcome :</div>
                  <div style={{ color: data2use?.color }}>
                    {data2use?.color === "red"
                      ? "Lost"
                      : data2use?.color === "green"
                      ? "Won"
                      : "pending"}
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  };
  class CustomizedDot extends React.Component {
    handleTicketClick_inner = (label, tooltipData) => {
      let data2use = "";
      for (let index = 0; index < tooltipData.length; index++) {
        if (Object.keys(tooltipData[index])[0] === label) {
          data2use = tooltipData?.[index]?.[label];
        }
      }
      const ticketInfo = {
        type: "public",
        ticketID: data2use?.ticketID,
      };
      navigation("/ShowTickets", { state: ticketInfo });
    };
    render() {
      const { cx, cy, value, data, index, tool_tip, payload } = this.props;
      ////console.log(data[index]?.colo === "red")
      if (data[index]?.colo === "red") {
        return (
          <text
            x={cx}
            y={cy}
            dy={-4}
            fill={data[index]?.colo}
            fontSize={12}
            textAnchor="middle"
            onClick={() =>
              this.handleTicketClick_inner(data[index]?.min_day, tool_tip)
            }
            className="bold link"
          >
            {isSmallScreen ? (
              <>{"- $"}</>
            ) : (
              <>
                {"-$"}
                {Math.round(data[index]?.margin)}
              </>
            )}
          </text>
        );
      } else if (data[index]?.colo === "green") {
        return (
          <text
            x={cx}
            y={cy}
            dy={-4}
            fill={data[index]?.colo}
            fontSize={10}
            textAnchor="middle"
            className="bold link"
            onClick={() =>
              this.handleTicketClick_inner(data[index]?.min_day, tool_tip)
            }
          >
            {isSmallScreen ? (
              <>{"+$"}</>
            ) : (
              <>
                {"+$"}
                {Math.round(data[index]?.margin)}
              </>
            )}
          </text>
        );
      }

      return (
        <text
          x={cx}
          y={cy}
          dy={-4}
          fill={data[index]?.colo}
          fontSize={10}
          textAnchor="middle"
          className="bold link"
          onClick={() =>
            this.handleTicketClick_inner(data[index]?.min_day, tool_tip)
          }
        >
          {isSmallScreen ? (
            <>{"$"}</>
          ) : (
            <>
              {"$"}
              {data[index]?.margin}
            </>
          )}
        </text>
      );
    }
  }
  const CustomTooltip = ({ active, payload, label, index }) => {
    const tooltipData = profileData?.tootip;

    if (active && payload && payload.length) {
      return (
        <div>
          <GettoolTip label={label} tooltipData={tooltipData} />
        </div>
      );
    }

    return null;
  };
  const ReturnROI = ({ stake, profit }) => {
    const res = Math.round((stake / profit) * 100);
    let totalLoss;
    let ROI;
    if (stake > profit) {
      totalLoss = parseInt(stake) - parseInt(profit);
      ROI = Math.round((totalLoss / stake) * 100);
    }
    if (stake < profit) {
      totalLoss = profit - stake;
      ROI = Math.round((totalLoss / stake) * 100);
    }

    return (
      <div>
        {stake > profit ? (
          <>
            <div className=" redlabel">
              {"Loss : -"}
              {ROI}
              {"%"}
            </div>
          </>
        ) : stake < profit ? (
          <>
            <div className=" greenlabel">
              {"Profit : +"}
              {ROI}
              {"%"}
            </div>
          </>
        ) : (
          <>
            <div className=" ">
              {"Broke Even : "}
              {ROI}
              {"%"}
            </div>
          </>
        )}
      </div>
    );
  };
  //console.log(useMonth, useMonths,useProfileCode,profileData);
  
  return (
    <>
      {isclickedShare && (
        <ShareProfileCopy
          data={profileDataPersonal?.profileID}
          isclickedShare={isclickedShare}
          setisclickedShare={setisclickedShare}
          header={
            "Here is a link you can post on your Social Media Page to direct friends to this profile"
          }
        />
      )}
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen} />}
      <div className="">
        <div
          className="usemaxSpace"
          style={{
            position: "relative",
            overflow: "hidden",
            minHeight: "300px",
          }}
        >
          <div expand="lg" bgColor="light">
            <IndexHeader
              setisclickTicket={setisclickTicket}
              isclickTicket={isclickTicket}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isLogedin={isLogedin}
        issubscribed={issubscribed}
            />
          </div>
          <section className="text-center text-md-start">
            <div
              className="p-5 bg-image"
              style={{
                height: isSmallScreen ? "100px" : "200px",
                backgroundImage: `url(${authorBackground ?? bgimage})`,
                zIndex: "-1",
              }}
            ></div>

            <div
              className="pb-2"
              style={{ backgroundColor: "background-color: hsl(0, 0%, 98%)" }}
            >
              <MDBContainer>
                <div className={`d-flex center_div_distributed ${isSmallScreen  || isMidScreen ? "center_straight":"center_sides"}`}>
                  <div  className="mb-4 mb-md-0 pt-4">
                    <img
                      src={authorProfile ?? care}
                      className="rounded-circle float-none float-md-start me-4 mb-3"
                      alt=""
                      style={{
                        width: "168px",
                        marginTop: "-110px",
                        border: "4px solid hsl(0, 0%, 98%)",
                      }}
                    />
                    <h1 className="fw-bold">
                      {profileDataPersonal?.tipstarDetails?.tipstarName}{" "}
                    </h1>
                    <p className="text-muted">
                      {profileDataPersonal?.tipstarDetails?.tipstarPhrase}
                    </p>
                  </div>
                  <div
                    
                    className="mb-4 mb-md-0 text-center text-lg-end allpad"
                  >
                  <div className="center_div center_straight">
                    <div>
                    {profileDataPersonal?.email === globalUser?.user?.email ? (
                      <>
                        <div>
                          <MDBBtn>
                            <div
                              onClick={() => setisclickedShare((prev) => !prev)}
                              style={{ textTransform: "none" }}
                            >
                              Share Profile
                            </div>
                          </MDBBtn>
                        </div>
                      </>
                    ) : globalUser?.user?.email ? (
                      <>
                        <div className="center_div center_sides">
                          <MDBBtn
                            rounded
                            color="secondary"
                            active
                            onClick={() => handleMessageClick(useMatches)}
                          >
                            <div style={{ textTransform: "none" }}>Message</div>
                          </MDBBtn>
                          &nbsp;&nbsp;&nbsp;
                          <MDBBtn rounded>
                            <div
                              onClick={() => handleFollowTipstartClick()}
                              style={{ textTransform: "none" }}
                            >
                              {isFollowingTipstar ? "Following" : "Follow Tips"}
                            </div>
                          </MDBBtn>
                          <div>
                            &nbsp;&nbsp;&nbsp;
                            <MDBBtn>
                              <div
                                onClick={() =>
                                  setisclickedShare((prev) => !prev)
                                }
                                style={{ textTransform: "none" }}
                              >
                                Share Profile
                              </div>
                            </MDBBtn>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                      <div className={`center_div_distributed center_sides`}>
                        <MDBPopconfirm
                          btnClassName="me-1 "
                          placement="top"
                          size="sm"
                          confirmBtnText={
                            <div
                              onClick={() => navigation("/SignIn")}
                              style={{ textTransform: "none" }}
                            >
                              Login
                            </div>
                          }
                          cancelBtnText={
                            <div style={{ textTransform: "none" }}>Ok</div>
                          }
                          btnChildren={
                            <div style={{ textTransform: "none" }}>Message</div>
                          }
                          color="secondary"
                        >
                          <MDBPopconfirmMessage>
                            You are not logged in.{" "}
                            <a
                              className="link"
                              onClick={() => navigation("/SignIn")}
                            >
                              Login
                            </a>{" "}
                            to send messages?
                          </MDBPopconfirmMessage>
                        </MDBPopconfirm>
                        &nbsp;&nbsp;
                        <MDBPopconfirm
                          btnClassName="me-1 "
                          placement="top"
                          size="sm"
                          confirmBtnText={
                            <div
                              onClick={() => navigation("/SignIn")}
                              style={{ textTransform: "none" }}
                            >
                              Login
                            </div>
                          }
                          cancelBtnText={
                            <div style={{ textTransform: "none" }}>Ok</div>
                          }
                          btnChildren={
                            <div style={{ textTransform: "none" }}>
                              Follow Tips
                            </div>
                          }
                        >
                          <MDBPopconfirmMessage>
                            You are not logged in.{" "}
                            <a
                              className="link"
                              onClick={() => navigation("/SignIn")}
                            >
                              Login
                            </a>{" "}
                            to follow tipstar now!
                          </MDBPopconfirmMessage>
                        </MDBPopconfirm>
                        <div>
                          &nbsp;&nbsp;
                          <MDBBtn size="sm">
                            <div
                              onClick={() => setisclickedShare((prev) => !prev)}
                              style={{ textTransform: "none" }}
                            >
                              Share Profile
                            </div>
                          </MDBBtn>
                        </div></div>
                      </>
                    )}
                    </div>
                    <div className="center_div_distributed center_sides allpad">
                      <div className="bold">
                      {profileDataPersonal?.ticketCount?.length} {" Tickets"}
                      </div> &nbsp;&nbsp;&nbsp;
                      <div className="bold">
                        {profileDataPersonal?.followers?.length} {" Followers"}
                      </div> &nbsp;&nbsp;&nbsp;
                      <div className="bold">
                      {profileDataPersonal?.following?.length} {" Following"}
                      </div>
                    </div>
                  </div>
                    
                  </div>
                </div>
              </MDBContainer>
            </div>
          </section>
        </div>
      </div>
      <MDBContainer>
        <h1 className="h4 text-center py-5 mb-0 bold">
          <div className="center_div center_sides">
            <div className="allpad">Ticket History</div> &nbsp; &nbsp;
            <div>
              {useMonths && <MDBSelect className="shadow-5" size="sm" data={useMonths && useMonths} onChange={(val)=>setUseMonth(val.text)}/>}
            </div>
          </div>
        </h1>

        <section className="mb-4">
          <MDBCard>
            <MDBCardBody>
              <MDBRow>
                <MDBCol md="6" className="mb-4">
                  <MDBChart
                    type="line"
                    data={{
                      labels: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday ",
                      ],
                      datasets: [
                        {
                          label: "Average Odds per ticket",
                          data: oddsPerTicket,
                        },
                      ],
                    }}
                  />
                </MDBCol>

                <MDBCol md="6" className="mb-4">
                  <MDBChart
                    type="bar"
                    data={{
                      labels: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday ",
                      ],
                      datasets: [
                        {
                          label: "Average number of matches per ticket",
                          data: matchPerTicket,
                        },
                      ],
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </section>
        <section className="mb-4">
          <MDBCard>
            <MDBCardBody>
              <div className="center_div_distributed center_sides ">
                <MDBCard>
                  <MDBCardBody>
                    <div className="center_div center_straight">
                      <div className={`center_div ${isSmallScreen ? "tiny_font": "midfont"} bold`}>
                        Total Stakes
                      </div>
                      <div className="center_div  bold">
                        {"$"}
                        {profileData?.all_stakes}
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
                <MDBCard>
                  <MDBCardBody>
                    <div className="center_div center_straight">
                      <div className={`center_div ${isSmallScreen ? "tiny_font": "midfont"} bold`}>Total Revenue</div>
                      <div className="center_div greenlabel bold">
                        {"$"}
                        {profileData?.all_win}
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
                <MDBCard>
                  <MDBCardBody>
                    <div className="center_div center_straight">
                      <div className={`center_div ${isSmallScreen ? "tiny_font": "midfont"} bold`}>ROI</div>
                      <div className="center_div bold">
                        <ReturnROI
                          stake={profileData?.all_stakes}
                          profit={profileData?.all_win}
                        />
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </MDBCardBody>
          </MDBCard>
        </section>

        <section className="mb-4">
          <MDBCard>
            <MDBCardBody>
              <MDBRow>
                <div className="usemaxSpace">
                  <div style={{ height: "300px", width: "100%" }}>
                    <ResponsiveContainer>
                      <LineChart
                        data={profileData?.chart}
                        margin={{
                          top: 5,
                          right: 10,
                          left: -30,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="min_day"
                          tick={{ fontSize: 12, fontFamily: "serif" }}
                        />
                        <YAxis tick={{ fontSize: 12, fontFamily: "serif" }} />
                        <Tooltip
                          content={<CustomTooltip />}
                          offset={10}
                          allowEscapeViewBox={{ x: false, y: true }}
                        />

                        <Line
                          type="monotone"
                          dataKey="line"
                          stroke="#8884d8"
                          dot={(props) => (
                            <CustomizedDot
                              {...props}
                              data={profileData?.chart}
                              tool_tip={profileData?.tootip}
                            />
                          )}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </section>

        <section>
          {isSmallScreen ? (
            <>
              <MDBCard>
                <MDBCardBody>
                  <MDBTable striped hover>
                    <MDBTableHead>
                      <tr>
                        <th scope="col">
                          <div className="center_div">Date </div>
                        </th>
                        <th scope="col">
                          <div className="center_div">N-games</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Odds</div>
                        </th>

                        <th scope="col">
                          <div className="center_div">Stake</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Margin</div>
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {profileData &&
                        Object.keys(profileData?.ticketTrend)
                          ?.sort(
                            (a, b) =>
                              profileData?.ticketTrend?.[b]?.ticketData?.[0] -
                              profileData?.ticketTrend?.[a]?.ticketData?.[0]
                          )
                          ?.map((item, index) => {
                            return (
                              <tr
                                className="link "
                                key={index}
                                onClick={() =>
                                  handleTicketClick(
                                    profileData?.ticketTrend?.[item]?.type,
                                    profileData?.ticketTrend?.[item]?.ticketID
                                  )
                                }
                              >
                                <td>
                                  <div className="center_div">
                                    {convertUnixToTime(
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[0],
                                      "short"
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[1]
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[2]
                                    }
                                  </div>
                                </td>

                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[5]
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="center_div bold"
                                    style={{
                                      color:
                                        profileData?.ticketTrend?.[item]
                                          ?.status === "lost"
                                          ? "red"
                                          : profileData?.ticketTrend?.[item]
                                              ?.status === "won"
                                          ? "green"
                                          : "grey",
                                    }}
                                  >
                                    {profileData?.ticketTrend?.[item]
                                      ?.status === "lost"
                                      ? "- $ "
                                      : profileData?.ticketTrend?.[item]
                                          ?.status === "won"
                                      ? "+ $ "
                                      : "$ "}
                                    {profileData?.ticketTrend?.[item]
                                      ?.status === "lost"
                                      ? profileData?.ticketTrend?.[item]
                                          ?.ticketData?.[5]
                                      : profileData?.ticketTrend?.[item]
                                          ?.status === "won"
                                      ? Math.round(
                                          profileData?.ticketTrend?.[item]
                                            ?.ticketData?.[4]
                                        ) -
                                        profileData?.ticketTrend?.[item]
                                          ?.ticketData?.[5]
                                      : profileData?.ticketTrend?.[item]
                                          ?.ticketData?.[5]}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      <tr></tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </>
          ) : (
            <>
              <MDBCard>
                <MDBCardBody>
                  <MDBTable striped hover>
                    <MDBTableHead>
                      <tr>
                        <th scope="col">
                          <div className="center_div">Date </div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Number of Matches</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Odds</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Bookie</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Bookinng Code</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Stake</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Margin</div>
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {profileData &&
                        Object.keys(profileData?.ticketTrend)
                          ?.sort(
                            (a, b) =>
                              profileData?.ticketTrend?.[b]?.ticketData?.[0] -
                              profileData?.ticketTrend?.[a]?.ticketData?.[0]
                          )
                          ?.map((item, index) => {
                            return (
                              <tr
                                className="link "
                                key={index}
                                onClick={() =>
                                  handleTicketClick(
                                    profileData?.ticketTrend?.[item]?.type,
                                    profileData?.ticketTrend?.[item]?.ticketID
                                  )
                                }
                              >
                                <td>
                                  <div className="center_div">
                                    {convertUnixToTime(
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[0],
                                      "short"
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[1]
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[2]
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {profileData?.ticketTrend?.[item]
                                      ?.bookie === "SportyBet" ? (
                                      <div>
                                        <img
                                          className=""
                                          src={sportyImage}
                                          alt={
                                            profileData?.ticketTrend?.[item]
                                              ?.bookie
                                          }
                                          width="60"
                                          height="25"
                                        />
                                      </div>
                                    ) : (
                                      <div>?</div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[3]
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div className="center_div">
                                    {
                                      profileData?.ticketTrend?.[item]
                                        ?.ticketData?.[5]
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="center_div bold"
                                    style={{
                                      color:
                                        profileData?.ticketTrend?.[item]
                                          ?.status === "lost"
                                          ? "red"
                                          : profileData?.ticketTrend?.[item]
                                              ?.status === "won"
                                          ? "green"
                                          : "grey",
                                    }}
                                  >
                                    {profileData?.ticketTrend?.[item]
                                      ?.status === "lost"
                                      ? "- $ "
                                      : profileData?.ticketTrend?.[item]
                                          ?.status === "won"
                                      ? "+ $ "
                                      : "$ "}
                                    {profileData?.ticketTrend?.[item]
                                      ?.status === "lost"
                                      ? profileData?.ticketTrend?.[item]
                                          ?.ticketData?.[5]
                                      : profileData?.ticketTrend?.[item]
                                          ?.status === "won"
                                      ? Math.round(
                                          profileData?.ticketTrend?.[item]
                                            ?.ticketData?.[4]
                                        ) -
                                        profileData?.ticketTrend?.[item]
                                          ?.ticketData?.[5]
                                      : profileData?.ticketTrend?.[item]
                                          ?.ticketData?.[4]}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      <tr></tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </>
          )}
        </section>
      </MDBContainer>
      <FooterAll />
      <FooterNav />
    </>
  );
};

export default PublicProfile;
