import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
  MDBInfiniteScroll,
} from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import care from "../../images/unisex.jpg";
import { convertUnixToTime } from "../Utils";
import DisplayTopComments from "./DisplayTopComments";
import DicplayInnerComments from "./DisplayInnerComments";
import axios from "axios";
import { io } from "socket.io-client";
const API_URL = process.env.REACT_APP_API_URL;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;

export default function DicplayComments(props) {
  const [useComment, setUseComment] = useState();
  const [useCommentAll, setUseCommentAll] = useState();
  const infiniteRef = useRef < HTMLDivElement > null;
  const [itemIndex, setItemIndex] = useState(0);
  const [event, setevent] = useState();
  useEffect(() => {
    const fetchData = async () => {
      let res;
      ////console.log(queryData);
      if (props?.targetObject === "") {
        ("");
      } else {
        let querryData = {
          db: props?.targetDb,
          object: props?.targetObject,
        };
        try {
          res = await axios
            .post(`${API_URL}/getAllComments`, JSON.stringify(querryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      }
      if (res?.data?.status === "success") {
        setUseComment(() =>
          res?.data?.data
            ?.sort((a, b) => b?.updated_at - a?.updated_at)
            ?.slice(0, 10)
        );
        setUseCommentAll(() =>
          res?.data?.data
            ?.sort((a, b) => b?.updated_at - a?.updated_at)
            ?.slice(10)
        );
        //console.log(res?.data?.data?.length);
        setevent(() => props?.targetObject);
      }
    };
    fetchData();
  }, [props?.targetObject, props.reloadChat]);
  //console.log(props?.reloadChat);
  useEffect(() => {
    
    props?.socketInstance?.on(String(event), (data) => {
      //console.log("entered here, while listenning at ", String(event));
      handleUpdate(data);
    });
    return () => {
      props?.socketInstance?.off(String(event));
    };
  }, [props.socketInstance]);
  const handleUpdate = (data) => {
    setUseComment((prev) => [...prev, data?.comments]);
  };

  const handleScroll = () => {
    if (itemIndex > useCommentAll?.length - 1) {
      return;
    }

    setUseComment([
      ...useComment,
      useCommentAll[itemIndex],
      useCommentAll[itemIndex + 1],
      useCommentAll[itemIndex + 2],
      useCommentAll[itemIndex + 3],
      useCommentAll[itemIndex + 4],
    ]);

    setItemIndex(itemIndex + 5);
  };
  let querryData = {
    db: props?.targetDb,
    object: props?.targetObject,
  };
  ////console.log(useComment);
  if (!props?.reloadChat) {
    return null;
  }
  return (
    <div className="">
    <MDBInfiniteScroll
              infiniteScrollRef={infiniteRef}
              className="div noscrolbar"
              
              onInfiniteScroll={handleScroll}
            >
      {useComment
        ?.sort((a, b) => b?.updated_at - a?.updated_at)
        ?.map((item, index) => {
          return (
            <>
              {item?.data?.chilren ? (
                <>
                  <div className="" key={index}>
                    <div key={index}>
                      <DisplayTopComments
                        db={props?.targetDb}
                        collection={props?.targetObject}
                        commentId={item?._id}
                        index={index}
                        userData={props?.userData}
                        socketInstance={props?.socketInstance}
                        setCommentType={props?.setCommentType}
                        setReplyContent={props?.setReplyContent}
                        originalOwner={props?.originalOwner}
                        reloadChat={props?.reloadChat}
                      />
                    </div>
                    <div className="marginleft-30">
                      <DicplayInnerComments
                        db={props?.targetDb}
                        collection={props?.targetObject}
                        commentId={
                          item?.data?.chilren?.sort(
                            (a, b) => b?.time - a?.time
                          )[0]?.comment_id
                        }
                        rcomments={item?.data?.chilren.length}
                        index={index}
                        mainData={item}
                        userData={props?.userData}
                        socketInstance={props?.socketInstance}
                        setCommentType={props?.setCommentType}
                        setUseComment={setUseComment}
                        setReplyContent={props?.setReplyContent}
                        originalOwner={props?.originalOwner}
                        reloadChat={props?.reloadChat}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div key={index}>
                    <DisplayTopComments
                      db={props?.targetDb}
                      collection={props?.targetObject}
                      commentId={item?._id}
                      index={index}
                      userData={props?.userData}
                      socketInstance={props?.socketInstance}
                      setCommentType={props?.setCommentType}
                      setReplyContent={props?.setReplyContent}
                      originalOwner={props?.originalOwner}
                      reloadChat={props?.reloadChat}
                    />
                  </div>
                </>
              )}
            </>
          );
        })}
        </MDBInfiniteScroll>
    </div>
  );
}
