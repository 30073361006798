import React from "react";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import {
  Row,
  Nav,
  Col,
  Navbar,
  Form,
  Button,
  Dropdown,
  FormControl,
  Container,
} from "react-bootstrap";
import "../css/HeaderStyles.css";
import DatePicker from "./DatePicker";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import Team_image from "../images/team_images.json";
import { Try } from "@mui/icons-material";
import { Errorlog, SideLeagueNavModal } from "./Utils";

export const searchFont = {
  fontSize: "12px",
  color: "grey",
};
const API_URL = process.env.REACT_APP_API_URL;

function search(listOfDicts, searchTerm) {
  // Filter through list of dictionaries
  let results = listOfDicts.filter((item) => {
    // Iterate over each property in the dictionary
    for (let key in item) {
      // If the property starts with the search term, return true
      if (
        item[key].toString().toLowerCase().startsWith(searchTerm.toLowerCase())
      ) {
        return true;
      }
    }

    // If no matches were found in this dictionary item, return false
    return false;
  });

  // Render or return the results
  return results;
}

function IndexSubheads(props) {
  /* this block of code below is to generate names for nidex sub bar */
  const navigation = useNavigate();
  const [searchData, setSearchData] = useState();
  const [league_navs, setleague_navs] = useState();
  const { globalUser } = useContext(GlobalUserContext);
  const [isMidScreen, setIsMidScreen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const fetchData = async () => {
      try {
        const res = await axios
          .get(`${API_URL}/get_search_data`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch((error) => {
            throw new Error("Server is unavailable, please try again later");
          });
        const s_data = res?.data;

        if (s_data && s_data?.status === "success") {
          try {
            setSearchData(JSON.parse(s_data?.data || "{}"));
            setleague_navs(JSON.parse(s_data?.league_nav || "{}"));
          } catch (error) {
            Errorlog("error in parsing JSON", error, "fetch search data");
          }
        }
      } catch (error) {
        Errorlog("error in load search data", error, "fetch search data");
      }
    };

    fetchData();
  }, []);
  ////console.log(searchData);
  const handleteamclick = ({ val, team_, league }) => {
    const team_info = {
      team: val,
      league: league,
      data: league_navs,
    };
    navigation("/team/" + team_, { state: team_info });
  };
  ////console.log(searchData);
  const handlematchlick = (match_id, team1, team2, seas) => {
    // /////console.log(team1, team2, match_id);
    navigation("/match/" + match_id, {
      state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
    });
  };
  function SearchBar() {
    const [value, setValue] = useState("");

    return (
      <Dropdown>
        <Dropdown.Toggle
          as={FormControl}
          id="dropdown-custom-components"
          placeholder="Search Teams or Matches"
          size=""
          style={searchFont}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        <Dropdown.Menu style={{ Width: "110%" }}>
          {searchData
            ?.filter(
              ({ home_name, away_name }) =>
                home_name.toLowerCase().startsWith(value.toLowerCase()) ||
                away_name.toLowerCase().startsWith(value.toLowerCase())
            )
            .slice(0, 5)
            .map((item, index) => (
              <Dropdown.Item key={index}>
                <div className="searchFont ">
                  <div className="center_sides  center_div_distributed usemaxSpace">
                    <div>
                      <div
                        className=" link forceblack center_sides center_straight alligh_center"
                        style={{
                          maxWidth: window.innerWidth < 770 ? "100px" : "100px",
                        }}
                        onClick={() =>
                          handleteamclick({
                            val: item.home_id,
                            team_: item.home_name,
                            league: item.league_id,
                          })
                        }
                      >
                        <div>
                          <img
                            src={Team_image?.[String(item.home_id)]?.image}
                            width="20"
                            height="15"
                            alt={item.home_name}
                          />
                        </div>
                        &nbsp;
                        <div className="hidelargefontleft ">
                          {item.home_name}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="link forceblack allpad"
                        onClick={() =>
                          handlematchlick(
                            item?.match_id,
                            item.home_id,
                            item.away_id,
                            item.season
                          )
                        }
                      >
                        VS
                      </div>
                    </div>
                    <div>
                      <div
                        className="link forceblack center_sides center_straight alligh_center"
                        style={{
                          maxWidth: window.innerWidth < 770 ? "100px" : "100px",
                        }}
                        onClick={() =>
                          handleteamclick({
                            val: item.away_id,
                            team_: item.away_name,
                            league: item.league_id,
                          })
                        }
                      >
                        <div className="hidelargefontleft">
                          {item.away_name}
                        </div>
                        &nbsp;
                        <div>
                          <img
                            src={Team_image?.[String(item.away_id)]?.image}
                            width="20"
                            height="15"
                            alt={item.away_name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {item.home_name} - {item.away_name} */}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  if (!searchData) {
    return null;
  }
  ////console.log(isSmallScreen, isMidScreen);
  return (
    <div className="sub_navbar_font allpad">
      {props?.sidenavOpen && (
        
        <SideLeagueNavModal
          funcchange={props?.setSidenavOpen}
          funcuser={props?.sidenavOpen}
          islgedin={props?.islgedin}
        issubed={props?.issubed}
        setticketactive={props?.setticketactive}
        setShows={props?.setShows}
        setleague={props?.setleague} 
        data={props?.data}
        
        />
        
        
      )}
      <div className="center_div center_sides">
        <div>
          <SearchBar />
        </div>
        &nbsp;&nbsp;&nbsp;
        <div >
          {isMidScreen ? (
            <div className="tickettop allpad link forcewhite" onClick={() => props?.setSidenavOpen(()=>true)}>Leagues</div>
          ) : isSmallScreen ? (
            <div className="tickettop allpad link forcewhite" onClick={() => props?.setSidenavOpen(()=>true)}>Leagues</div>
          ) : null}
        </div>
        
        {/* <div>
          {isMidScreen ? (
            <div className="tickettop allpad">Tickets</div>
          ) : isSmallScreen ? (
            <div className="tickettop allpad">Tickets</div>
          ) : null}
        </div> */}
        {props?.type == "dayfix" && <div>
          <DatePicker fix_date={props.fix_date} />
        </div>}
      </div>
    </div>
  );
}

export default IndexSubheads;
