import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  Button,
  Col,
  Card,
  Container,
  Form,
  Row,
  Carousel,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { TbMoodDollar } from "react-icons/tb";
import { TbUserDollar } from "react-icons/tb";
import { GiPayMoney } from "react-icons/gi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbBusinessplan } from "react-icons/tb";
import { TfiStatsUp } from "react-icons/tfi";
import { TbChartInfographic } from "react-icons/tb";
import { GoHistory } from "react-icons/go";
import "../css/Signin.css";
import FooterAll from "./Footer";
import EntryHeader from "./EntryHeader";
import img1 from "../images/AboutImages/1.png";
import img2 from "../images/AboutImages/2.png";
import img3 from "../images/AboutImages/3.png";
import img4 from "../images/AboutImages/4.png";
import img5 from "../images/AboutImages/5.png";
import img6 from "../images/AboutImages/6.png";
import img7 from "../images/AboutImages/7.png";
import img8 from "../images/AboutImages/8.png";
import img9 from "../images/AboutImages/9.png";
import img11 from "../images/AboutImages/11.png";
import img12 from "../images/AboutImages/12.png";
import img13 from "../images/AboutImages/13.png";
import img14 from "../images/AboutImages/14.png";
import img15 from "../images/AboutImages/15.png";
import { FAQs } from "./HowItworks";
import FooterNav from "./SmallScreenNav";
import { SideNavPage } from "./Utils";

const API_URL = process.env.REACT_APP_API_URL;

export function DisplayAbout({ globalUser, listImages, handleSubClick }) {
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };
  useEffect(()=> {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  },[])
  return (
    <div
      className="about-us"
      style={{
        overflow: "auto",
        background: "white",
        backgroundSize: "cover",
      }}
    >
      <Container style={{ marginBottom: "20px" }}>
        <div
          style={{
            fontFamily: "serif",
          }}
        >
          <Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                marginTop: "30px",
                fontSize: isSmallScreen ? "30px":"50px",
                fontWeight: "bold",
              }}
            >
              About Bet Informatics
            </div>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} style={{ background: "white" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                <GoHistory size={30} />
                &nbsp;&nbsp; Our Story
              </div>

              <p style={{ fontSize: "20px" }}>
                We are a team of data analysts and data scientists in the area
                of Sports betting, dedicated to bringing advanced statistics and
                valuable key performance indicators to its users. These key
                value indicators enable users make the right decisions when
                trying to choose an option to bet on a game.{" "}
              </p>
              <p style={{ fontSize: "20px" }}>
                We found out that most sport prediction sites only offer tips
                without any analytics to back up their picks. We offer advanced
                statistics methods with drill down mechanisims to help analyse
                the best possible outcome between two teams in a game.{" "}
              </p>
              <p style={{ fontSize: "20px" }}>
                We have rich historical data, clearly dipicted to show the trend
                of activities between two teams taking part in a contest. Our
                KPIs help highlight vital strengths of a team and best possible
                options to pick and their chance of occuring again.{" "}
              </p>
            </Col>
            <Col>
              <Row>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    marginBottom: "30px",
                    marginTop: "15px",
                  }}
                >
                  <TbChartInfographic size={30} />
                  &nbsp;&nbsp; Highlights
                </div>
              </Row>
              <Row>
                <Carousel>
                  {listImages.map((imgs_, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={imgs_}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Row>
            </Col>
          </Row>
          {globalUser?.user?.firstname === "Guest" ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <div
                type="submit"
                id="submitButton"
                onClick={() => handleSubClick("sub")}
                style={{
                  background: "green",
                  width: "50%",
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: isSmallScreen ? "15px": isMidScreen ? "20px":"30px",
                  fontWeight: "bold",
                  color: "white",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                Start 3 Days Trial Now
              </div>
            </Row>
          ) : null}
        </div>
        <div
          style={{
            fontFamily: "serif",
            borderTop: "1px solid grey ",
            marginTop: "10px",
          }}
        >
          <Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                marginTop: "50px",
                fontSize: isSmallScreen ? "35px":"50px",
                fontWeight: "bold",
              }}
            >
              Who is this for?
            </div>
          </Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TbUserDollar size={80} />
                      &nbsp;&nbsp; Rookies
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those new to the world of Sport Betting, this website
                      is for you. You would get general knowledge on how each
                      team performs and develop good bet option picking skills.
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TbMoodDollar size={80} />
                      &nbsp;&nbsp; Enthusiasts
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those not new to betting but like to bet on certain
                      options. With our advanced drill down enabled analytics,
                      you can search our database for those options you like to
                      bet on and get matches with high probablities. You can
                      also investigate options of a match before deciding to
                      play them.{" "}
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <TbBusinessplan size={80} />
                      &nbsp;&nbsp; Professionals
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For Professional Sport Punters who have develped a good
                      betting strategy and would like to get a lot of bet
                      options when trying to establish a game plan. Our website
                      would provide straight to the point analytics, you would
                      already see the trend in the data regarding a certain
                      option in a match.
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <TfiStatsUp size={80} />
                      &nbsp;&nbsp; Sport Analysts
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For Sport Lovers or Tipstars who would love to perform
                      deep analysis on a match for the sake of developing a blog
                      post or to provide tips for their fans. Our website is
                      equiped with up-to-date datasets that would answer all
                      your curiosities.
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <GiPayMoney size={80} />
                      &nbsp;&nbsp; Money Loosers
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those that keep loosing money no matter how carefully
                      the choose their pics. Some people make decisions based on
                      a tip given by a random website without analytical
                      backings. Our website would enable you make informed
                      decitions before any stake.
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <GiTakeMyMoney size={80} />
                      &nbsp;&nbsp; Smart Investors
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those looking to develop a passive income via sports
                      betting without going through the stress of analysis. With our professional
                      subscription, you would get daily top pics that you can
                      place bets on with confidence.
                    </div>
                  </Row>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}

function AboutUs() {
  const navigation = useNavigate();
  const defaultForm = { email: "", password: "" };
  const [showFooter, setShowFooter] = useState(false);
  const [formData, setFormData] = useState(defaultForm);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isOpen, setIsOpen] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);

  ////console.log("Contact Us", globalUser);
  useEffect(() => {
    const fetchData = async () => {
      const ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      if (ckUser && ckUser.result === "retrieved" && ckUser.data) {
        updateGlobalUser(ckUser.data.user);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    function handleScroll() {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowFooter(isAtBottom);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {};
  const listImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img11,
    img12,
    img13,
    img14,
    img15,
  ];
  const handleSubClick = (plan) => {
    navigation("/pricing");
  };

  return (
    <div className="light">
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
        <IndexHeader setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
        isLogedin={true}
        issubscribed={true}
        />
      

      {/* <div
        className="about-us"
        style={{
          overflow: "auto",
          background: "white",
          backgroundSize: "cover",
        }}
      >
        <Container style={{ marginBottom: "20px" }}>
          <div
            style={{
              fontFamily: "serif",
            }}
          >
            <Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginTop: "30px",
                  fontSize: "50px",
                  fontWeight: "bold",
                }}
              >
                About Bet Informatics
              </div>
            </Row>
            <Row>
              <Col style={{ background: "white" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  Our Story
                </div>

                <p style={{ fontSize: "20px" }}>
                  We are a team of data analysts and data scientists in the area
                  of Sports betting, dedicated to bringing advanced statistics
                  and valuable key performance indicators to its users. These
                  key value indicators enable users make the right decisions
                  when trying to choose an option to bet on a game.{" "}
                </p>
                <p style={{ fontSize: "20px" }}>
                  We found out that most sport prediction sites only offer tips
                  without any analytics to back up their picks. We offer
                  advanced statistics methods with drill down mechanisims to
                  help analyse the best possible outcome between two teams in a
                  game.{" "}
                </p>
                <p style={{ fontSize: "20px" }}>
                  We have rich historical data, clearly dipicted to show the
                  trend of activities between two teams taking part in a
                  contest. Our KPIs help highlight vital strengths of a team and
                  best possible options to pick and their chance of occuring
                  again.{" "}
                </p>
              </Col>
              <Col>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    Highlights
                  </div>
                </Row>
                <Row>
                  <Carousel>
                    {listImages.map((imgs_, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={imgs_}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Row>
              </Col>
            </Row>
            {globalUser?.user?.firstname === "Guest" ? (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <div
                  type="submit"
                  id="submitButton"
                  onClick={() => handleSubClick()}
                  style={{
                    background: "green",
                    width: "500px",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "white",
                    borderTopLeftRadius: "40px",
                    borderTopRightRadius: "40px",
                    borderBottomLeftRadius: "40px",
                    borderBottomRightRadius: "40px",
                  }}
                >
                  Start 3 Days Trial Now
                </div>
              </Row>
            ) : null}
          </div>
          <div
            style={{
              fontFamily: "serif",
              borderTop: "1px solid grey ",
              marginTop: "10px",
            }}
          >
            <Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginTop: "50px",
                  fontSize: "50px",
                  fontWeight: "bold",
                }}
              >
                Who is this for?
              </div>
            </Row>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "-40px",
                      }}
                    >
                      <TbUserDollar size={80} />
                      &nbsp;&nbsp; Rookies
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those new to the world of Sport Betting, this website
                      is for you. You would get general knowledge on how each
                      team performs and develop good bet option picking skills.
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "-40px",
                      }}
                    >
                      <TbMoodDollar size={80} />
                      &nbsp;&nbsp; Enthusiasts
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those not new to betting but like to bet on certain
                      options. With our advanced drill down enabled analytics,
                      you can search our database for those options you like to
                      bet on and get matches with high probablities. You can
                      also investigate options of a match before deciding to
                      play them.{" "}
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                        marginLeft: "-40px",
                      }}
                    >
                      <TbBusinessplan size={80} />
                      &nbsp;&nbsp; Professionals
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For Professional Sport Punters who have develped a good
                      betting strategy and would like to get a lot of bet
                      options when trying to establish a game plan. Our website
                      would provide straight to the point analytics, you would
                      already see the trend in the data regarding a certain
                      option in a match.
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                        marginLeft: "-40px",
                      }}
                    >
                      <TfiStatsUp size={80} />
                      &nbsp;&nbsp; Sport Analysts
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For Sport Lovers or Tipstars who would love to perform
                      deep analysis on a match for the sake of developing a blog
                      post or to provide tips for their fans. Our website is
                      equiped with up-to-date datasets that would answer all
                      your curiosities.
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                        marginLeft: "-40px",
                      }}
                    >
                      <GiPayMoney size={80} />
                      &nbsp;&nbsp; Money Loosers
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those that keep loosing money no matter how carefully
                      the choose their pics. Some people make decisions based on
                      a tip given by a random website without analytical
                      backings. Our website would enable you make informed
                      decitions before any stake.
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div
                      style={{
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                        marginLeft: "-40px",
                      }}
                    >
                      <GiTakeMyMoney size={80} />
                      &nbsp;&nbsp; Smart Investors
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        maxWidth: "85%",
                      }}
                    >
                      For those looking to develop a passive income via sports
                      betting without going through the stress of going through
                      the stress of analysis. With our professional
                      subscription, you would get daily top pics that you can
                      place bets on with confidence.
                    </div>
                  </Row>
                </Col>
              </Row>
            </Row>
          </div>
        </Container>
      </div> */}
      <DisplayAbout
        globalUser={globalUser}
        listImages={listImages}
        handleSubClick={handleSubClick}
      />
      <div style={{ background: "white" }}>
        {" "}
        <Container>
          <FAQs />
        </Container>
      </div>

      <FooterAll page={"Abour us"} />
      <FooterNav />
    </div>
  );
}

export default AboutUs;
