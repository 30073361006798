import React from "react";
import axios from "axios";
import { openDB, deleteDB, wrap, unwrap } from "idb";
const API_URL = process.env.REACT_APP_API_URL;

const DeleteOldCatch = async (db, time) => {
  const storeNames = db.objectStoreNames;

  for (let i = 0; i < storeNames.length; i++) {
    const tx = db.transaction(storeNames[i], "readwrite");
    const store = tx.objectStore(storeNames[i]);

    // Open a cursor and iterate over all records in the store
    let cursor = await store.openCursor();

    while (cursor) {
      let age = (Date.now() - cursor.value.exptime) / 1000;
      if (age > time) {
        // check if entry is older than 20 minutes
        // The entry is old, delete it
        //console.log("deleted, date:", cursor.value.exptime);
        cursor.delete();
      }

      // Move to next record; if there are no more, 'cursor' will be 'null'
      cursor = await cursor.continue();
    }

    // Finish transaction
    await tx.done;
  }

  return "done";
};

export const CatchedStoraged = async (url, options, savekey, indexdb) => {
  let expiry = 5 * 60; // 5 min default
  if (typeof options === "number") {
    expiry = options;
    options = undefined;
  } else if (typeof options === "object") {
    // I hope you didn't set it to 0 seconds
    expiry = options.seconds || expiry;
  }
  // Use the URL as the cache key to sessionStorage
  let cacheKey = url + "/" + savekey;
  ////console.log(cacheKey, savekey);
  //let cached = localStorage.getItem(cacheKey);
  const db = await openDB(indexdb, 1, {
    upgrade(db) {
      // Create a single store named 'MyObjectStore'
      if (!db.objectStoreNames.contains(url)) {
        db.createObjectStore(url, { keyPath: "id.key", autoIncrement: true });
      }
    },
  });
  const deleted = await DeleteOldCatch(db, expiry);
  ////console.log(deleted)
  const tx = db.transaction(url, "readwrite");

  const store = tx.objectStore(url);
  const catched = await store.get(savekey);
  ////console.log("loading, catched", catched, indexdb);
  const catchedData = catched?.data;
  const whenCached = catched?.["exptime"];
  await tx.done;

  //let whenCached = localStorage.getItem(cacheKey + ":ts");
  //let identifyer = localStorage.getItem(cacheKey + ":id")
  if (catchedData !== null && whenCached !== null) {
    // it was in sessionStorage! Yay!
    // Even though 'whenCached' is a string, this operation
    // works because the minus sign converts the
    // string to an integer and it will work.
    let age = (Date.now() - whenCached) / 1000;
    if (age < expiry) {
      //let response = new Response(new Blob([cached]));
      let data = JSON.parse(catchedData);

      return Promise.resolve(data);
    } else {
      // We need to clean up this old key
      //await store.delete(url,cacheKey);
      //await tx.done;
    }
  }
  return await fetch(`${API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 200) {
        let ct = response.headers.get("Content-Type");
        if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
          // clone the response
          return response.clone().json();
        }
      }
      throw new Error("Not 200 response");
    })
    .then(async (data) => {
      ////console.log("Data:", data); // log the data he
      let content = JSON.stringify(data);
      const db = await openDB(indexdb, 1, {
        upgrade(db) {
          // Create a single store named 'MyObjectStore'
          if (!db.objectStoreNames.contains(url)) {
            db.createObjectStore(url, {
              keyPath: "id.key",
              autoIncrement: true,
            });
          }
        },
      });

      // Then put data (with a unique key per item) into the store
      const tx = db.transaction(url, "readwrite");

      const store = tx.objectStore(url);
      try {
        await store.put({
          id: { key: savekey, catchkey: cacheKey },
          exptime: Date.now(),
          data: content,
        });
      } catch (error) {
        try {
          await store.put(
            {
              id: { key: savekey, catchkey: cacheKey },
              exptime: Date.now(),
              data: content,
            },
            savekey
          );
        } catch (error) {
          console.error(
            "An error occured using the inline key:",
            error.message
          );
        }

        console.error("An error occurred using out of box:", error.message);
      }
      /* await store.put({ "id": {"key":savekey,"catchkey":cacheKey},"exptime":Date.now() , "data": content },savekey); */
      //console.log("created DB");
      await tx.done;
      //await catched.put(content,cacheKey);
      // catched.put(Date.now(),cacheKey + ":ts");
      ////console.log("I returnd data");
      return data; // this will now return the json data, not the response object or Promise
    });
};

export const CatchedStoragedImages = async (url, time, savekey, indexdb) => {
  //console.log("entered", catched, indexdb);
  const db = await openDB(indexdb, 1, {
    upgrade(db) {
      // Create a single store named 'MyObjectStore'
      if (!db.objectStoreNames.contains(url)) {
        db.createObjectStore(url, { keyPath: "id.key", autoIncrement: true });
      }
    },
  });
  //console.log("loading, catched", catched, indexdb);
  const tx = db.transaction(url, "readwrite");
  const store = tx.objectStore(url);
  const catched = await store.get(savekey);
  //console.log("loading, catched", catched, indexdb);
  const catchedData = catched?.data;
  await tx.done;

  //let whenCached = localStorage.getItem(cacheKey + ":ts");
  //let identifyer = localStorage.getItem(cacheKey + ":id")
  if (catchedData !== null) {
    //let response = new Response(new Blob([cached]));
    let data = catchedData;

    return Promise.resolve(data);
  }
  //console.log("entered images ");
  return await fetch(url)
    .then((response) => {
      //console.log("fetching");
      if (response.status === 200) {
        // clone the response
        return response.clone().blob().text();
      }
      throw new Error("Not 200 response");
    })
    .then(async (data) => {
      ////console.log("Data:", data); // log the data he
      const blob2 = await data.blob();
      let content = await blob2.text();
      const db = await openDB(indexdb, 1, {
        upgrade(db) {
          // Create a single store named 'MyObjectStore'
          if (!db.objectStoreNames.contains(url)) {
            db.createObjectStore(url, {
              keyPath: "id.key",
              autoIncrement: true,
            });
          }
        },
      });

      // Then put data (with a unique key per item) into the store
      const tx = db.transaction(url, "readwrite");

      const store = tx.objectStore(url);
      try {
        await store.put({
          id: { key: savekey },
          exptime: Date.now(),
          data: content,
        });
      } catch (error) {
        try {
          await store.put(
            {
              id: { key: savekey },
              exptime: Date.now(),
              data: content,
            },
            savekey
          );
        } catch (error) {
          console.error(
            "An error occured using the inline key:",
            error.message
          );
        }

        console.error("An error occurred using out of box:", error.message);
      }
      /* await store.put({ "id": {"key":savekey,"catchkey":cacheKey},"exptime":Date.now() , "data": content },savekey); */
      //console.log("created DB images");
      await tx.done;
      //await catched.put(content,cacheKey);
      // catched.put(Date.now(),cacheKey + ":ts");
      ////console.log("I returnd data");
      return data; // this will now return the json data, not the response object or Promise
    });
};

export const CatchedStoraged2 = async (url, options, savekey) => {
  let expiry = 5 * 60; // 5 min default
  if (typeof options === "number") {
    expiry = options;
    options = undefined;
  } else if (typeof options === "object") {
    // I hope you didn't set it to 0 seconds
    expiry = options.seconds || expiry;
  }
  // Use the URL as the cache key to sessionStorage
  let cacheKey = url;
  //console.log(cacheKey);
  let cached = localStorage.getItem(cacheKey);
  let whenCached = localStorage.getItem(cacheKey + ":ts");
  let identifyer = localStorage.getItem(cacheKey + ":id");
  if (cached !== null && whenCached !== null) {
    // it was in sessionStorage! Yay!
    // Even though 'whenCached' is a string, this operation
    // works because the minus sign converts the
    // string to an integer and it will work.
    let age = (Date.now() - whenCached) / 1000;
    if (age < expiry) {
      //let response = new Response(new Blob([cached]));
      let data = JSON.parse(cached);
      if (identifyer === savekey) {
        return Promise.resolve(data);
      } else {
        localStorage.removeItem(cacheKey);
        localStorage.removeItem(cacheKey + ":ts");
        localStorage.removeItem(cacheKey + ":id");
        localStorage.clear();
      }
    } else {
      // We need to clean up this old key
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(cacheKey + ":ts");
      localStorage.removeItem(cacheKey + ":id");
      localStorage.clear();
    }
  }
  return await fetch(`${API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 200) {
        let ct = response.headers.get("Content-Type");
        if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
          // clone the response
          return response.clone().json();
        }
      }
      throw new Error("Not 200 response");
    })
    .then((data) => {
      ////console.log("Data:",  data); // log the data he
      let content = JSON.stringify(data);
      localStorage.setItem(cacheKey, content);
      localStorage.setItem(cacheKey + ":ts", Date.now());
      localStorage.setItem(cacheKey + ":id", savekey);
      return data; // this will now return the json data, not the response object or Promise
    });
};

export const GetCatchedPages = async (page) => {
  // Use the URL as the cache key to sessionStorage
  let cacheKey = page;
  let cached = localStorage.getItem(cacheKey);
  if (cached !== null) {
    //let response = new Response(new Blob([cached]));
    let data = JSON.parse(cached);
    return Promise.resolve(data);
  } else {
    // We need to clean up this old key
    return Promise.resolve("Empty");
  }
};
export const SaveCatchedPages = async (page, data) => {
  // Use the URL as the cache key to sessionStorage
  let cacheKey = page;

  let content = JSON.stringify(data);
  localStorage.setItem(cacheKey, content);
  return "Saved"; // this will now return the json data, not the response object or Promise
};
