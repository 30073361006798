import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

export default function DisplayValue({ dis_value, dis_text, colorcode }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const data = [{ name: "Group A", value: 400 }];
  let main_color = "";
  let changeval = "false";
  let new_val = null;
  if (Array.isArray(colorcode)) {
    changeval = "true";
    main_color = colorcode?.[1];
  }

  if (changeval === "true") {
    const preval = parseInt(dis_value.split(" ")[0]);
    new_val = `${100 - preval} %`;
  }

  if (changeval === "true") {
    if (dis_text === "Draw percentage ft") {
      dis_text = "Anyteam Win ft";
    } else if (dis_text === "Draw percentage 2h") {
      dis_text = "Anyteam Win 2h";
    } else if (dis_text === "Draw percentage ht") {
      dis_text = "Anyteam Win ht";
    }
    if (dis_text === "Draw Either Half") {
      dis_text = "DEH NO";
    }

    let words = dis_text.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i] === "WEH") {
        words[i] = "WEH-NO";
      }
    }
    dis_text = words.join(" ");

    words = dis_text.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i] === "OV") {
        words[i] = "UN";
      }
    }
    dis_text = words.join(" ");

    words = dis_text.split(" ");
    if (dis_text.includes("Score")) {
      if (dis_text === "Both Teams Score") {
        dis_text = "BTS - NO";
      } else {
        for (let i = 0; i < words.length; i++) {
          if (words[i] === "Score") {
            words[i] = "No-Score";
          }
        }
        dis_text = words.join(" ");
      }
    }
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { startAngle, endAngle, fill } = props;
    const cx = 120;
    const cy = 108;
    const innerRadius = 70;
    const outerRadius = 95;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={40}
          textAnchor="middle"
          fontFamily="serif"
          fontSize={48}
          fontWeight="bold"
          fill={
            Array.isArray(colorcode)
              ? main_color
              : colorcode
              ? colorcode
              : "green"
          }
        >
          {new_val ?? dis_value ?? 0}
        </text>
        <text
          x={cx}
          y={cy}
          dy={-10}
          textAnchor="middle"
          fontSize={14}
          fontWeight="bold"
          fontFamily="serif"
          fill={fill}
        >
          {dis_text && dis_text}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          endAngle={endAngle}
          fill={
            Array.isArray(colorcode)
              ? main_color
              : colorcode
              ? colorcode
              : "rgb(101, 143, 3)"
          }
        />
      </g>
    );
  };
  return (
    <PieChart width={260} height={216}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        fill="rgb(29, 142, 235)"
        dataKey="value"
        onMouseEnter={onPieEnter}
      />
    </PieChart>
  );
}

export function DisplayValueSmall({ dis_value, dis_text, colorcode }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const data = [{ name: "Group A", value: 400 }];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { startAngle, endAngle, fill } = props;
    const cx = 100;
    const cy = 100;
    const innerRadius = 47;
    const outerRadius = 55;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={27}
          textAnchor="middle"
          fontFamily="serif"
          fontSize={30}
          fontWeight="bold"
          fill={colorcode ?? "green"}
        >
          {dis_value && dis_value}
        </text>
        <text
          x={cx}
          y={cy}
          dy={-10}
          textAnchor="middle"
          fontSize={14}
          fontWeight="bold"
          fontFamily="serif"
          fill={fill}
        >
          {dis_text && dis_text}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          endAngle={endAngle}
          fill={colorcode ?? "rgb(101, 143, 3)"}
        />
      </g>
    );
  };
  return (
    <div>
      <PieChart width={200} height={216}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          fill="rgb(29, 142, 235)"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </div>
  );
}
