import React from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import "../css/Admin.css";

//const API_URL = process.env.REACT_APP_API_URL;
const API_URL_FOOTY = process.env.REACT_APP_STATIC_API;

////console.log(API_URL_FOOTY);

export default function GetStaticCoLg(params) {
  const getData = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/leagues-country`);
    ////console.log(res);
  };

  const Getdaily = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/OneTimeLoad2`);
    ////console.log("data stored to daily database ", res);
  };

  const GetLeagueStats = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_stats`);
    //console.log("data stored to daily database in league stats collection ",res);
  };

  const UpdateLeagueStats = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_stats_update`);
    ////console.log("league stats collection updated", res);
  };

  const UpdateLeagueMatches = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_matches_update`);
    ////console.log("league matches collection updated", res);
  };

  const GetLeagueMatches = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_matches`);
    
  };

  const UpdateLeagueTeams = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_teams_update`);
    ////console.log("league teams collection updated", res);
  };

  const GetLeagueTeams = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_teams`);
    //console.log("data stored to daily database in league teams collection ",res);
  };

  const UpdateLeaguePlayers = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_players_update`);
    ////console.log("league players collection updated", res);
  };

  const GetLeaguePlayers = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_players`);
    //console.log("data stored to daily database in league Players collection ",res);
  };

  const UpdateLeagueTables = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_table_update`);
    ////console.log("league table collection updated", res);
  };

  const GetLeagueTables = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_league_table`);
    
  };

  const UpdatePlayers = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/update_player_details`);
    ////console.log("players collection updated", res);
  };

  const GetPlayers = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_player_details`);
    ////console.log("data stored to daily database in players collection ", res);
  };

  const UpdateTeams = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/update_teams_details`);
    ////console.log("teams collection updated", res);
  };

  const GetTeams = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/load_teams_details`);
    ////console.log("data stored to daily database in teams collection ", res);
  };
  const GetExTeams = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/exact_team_stat`);
    
  };

  const Images = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/SaveImages`);
    ////console.log("images saved to the images folder", res);
  };

  const Test_team = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/test_teams_stat`);
    ////console.log("testing trail stats", res);
  };

  const TrailStats = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/get_trail_tats`);

    
  };

  const league_stats_feed = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/lg_ex_stat`);

    
  };
  const MatchesExtraction = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/match_extraction_per_league`);
    
  };

  const MatchesTrail = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/match_trail_data`);
    
  };
  const RankingMatches = async () => {
    const res = await axios.get(`${API_URL_FOOTY}/OneTimeLoadRank`);
    
  };

  return (
    <div>
      extracted and saved to the Matches Database in the Teams_teams_per_league
      <Container className="butt">
        <Button className="buttButton" onClick={getData}>
          load countries and leagues{" "}
        </Button>{" "}
        {/* Corrected the text */}
        <Button onClick={Getdaily}>Load 21 daily games</Button>{" "}
        {/* Attach onClick event handler directly to the button */}
        <Button onClick={GetLeagueStats}>get League Stats</Button>{" "}
      </Container>
      <Container className="butt">
        <h6> league matches and stats </h6>
        {/* <Button onClick={UpdateLeagueStats}>Update League Stats</Button>{" "} */}
        <Button onClick={GetLeagueMatches}>get League matches</Button>{" "}
        {/* <Button onClick={UpdateLeagueMatches}>Update League matches</Button>{" "} */}
      </Container>
      <Container className="butt">
        <h6> league teams </h6>
        <Button onClick={GetLeagueTeams}>get League teams</Button>{" "}
        {/* <Button onClick={UpdateLeagueTeams}>Update League teams</Button>{" "} */}
      </Container>
      <Container className="butt">
        <h6> league players </h6>
        <Button onClick={GetLeaguePlayers}>get League Players</Button>{" "}
        {/* <Button onClick={UpdateLeaguePlayers}>Update League Players</Button>{" "} */}
      </Container>
      <Container className="butt">
        <h6> League tables </h6>
        <Button onClick={GetLeagueTables}>get League tables</Button>{" "}
        {/* <Button onClick={UpdateLeagueTables}>Update League tables</Button>{" "} */}
      </Container>
      <Container className="butt">
        <h6> players </h6>
        <Button onClick={GetPlayers}>get players</Button>{" "}
        {/* <Button onClick={UpdatePlayers}>Update players</Button>{" "} */}
      </Container>
      <Container className="butt">
        <h6> Teams </h6>
        <Button onClick={GetTeams}>get Teams</Button>{" "}
        <Button onClick={UpdateTeams}>Update teams</Button>{" "}
        <Button onClick={GetExTeams}>get exact stat teams</Button>{" "}
      </Container>
      <Container className="butt">
        <h6> Images matter </h6>
        <Button onClick={Images}>Save Images</Button>
      </Container>
      <Container className="butt">
        <h6> Trailing statistics </h6>
        <Button onClick={TrailStats}>Trail stats</Button>{" "}
        <Button onClick={Test_team}>test trail stats</Button>{" "}
        <Button onClick={league_stats_feed}>exact league stats feed</Button>
      </Container>
      <Container className="butt">
        <h6> Matches statistics and collections </h6>
        <Button onClick={MatchesExtraction}>Matches Extraction</Button>{" "}
        <Button onClick={MatchesTrail}>Matches Trail Data</Button>{" "}
      </Container>
      <Container className="butt">
        <h6> Rankings and searches</h6>
        <Button onClick={RankingMatches}>Ranking Matches</Button>{" "}
      </Container>
    </div>
  );
}
