import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import care from "../../images/unisex.jpg";
import {
  convertUnixToTime,
  timeDifference,
  timestampDifference,
} from "../Utils";
import axios from "axios";
import { FcLike } from "react-icons/fc";
import RenderSocialMediaLink from "./RenderSocialMedia";
import Linkify from "linkify-react";
import { Link } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;
export default function DicplayTopComments(props) {
  const [useCommentTop, setUseCommentTop] = useState();
  const [authorBackground, setUserBackground] = useState();
  const [reloadPResent, setReladPresent] = useState("true");
  const [useliked, setLiked] = useState(false);
  const [isSocialMedia, SetIsSocialMedia] = useState([])
  //useEffect(()=>{},[setUseCommentTop,useCommentTop])
  useEffect(() => {
    const fetchData = async () => {
      let res;
      ////console.log(queryData);
      if (!props?.commentId || !props?.collection || !props?.db) {
        ("");
      } else {
        let querryData = {
          db: props?.db,
          object: props?.collection,
          _id: props?.commentId,
        };
        try {
          res = await axios
            .post(`${API_URL}/getComments`, JSON.stringify(querryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", eor, "fetch search data");
        }
      }
      if (res?.data?.status === "success") {
        setUseCommentTop(() => res?.data?.data);
      }
    };
    fetchData();
  }, [props?.targetObject, props?.commentId, props?.reloadChat, reloadPResent]);
  useEffect(() => {
    async function fetchBackground() {
      try {
        const response = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${useCommentTop?.data?.ownerDetails?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob o a string
        setUserBackground(base64data);
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [useCommentTop?.data?.ownerDetails]);
  const handleReplyClick = () => {
    let res = { name: "godwin", email: "test@gmail.com" };
    props?.setCommentType((prev) => {
      if (prev === "reply") {
        props?.setReplyContent(props?.originalOwner);
        return "comment";
      } else {
        props?.setReplyContent({
          name: useCommentTop?.data?.ownerDetails?.name,
          email: useCommentTop?.data?.ownerDetails?.email,
          _id: useCommentTop?._id,
        });
        return "reply";
      }
    });
  };
  const handleLikeClick = (item) => {
    //console.log("clicked liked");
    if (useCommentTop && props?.socketInstance) {
      //console.log(props?.userData);

      let newSocket;
      if (!props?.socketInstance) {
        newSocket = io(BackSocket, {
          auth: {
            token: BackSocketToken,
          },
        });
      }
      if (props?.socketInstance) {
        props?.socketInstance.emit("likeComments", {
          data: useCommentTop,
          liker: props?.userData?.user?.email ?? newUser?.user.email,
        });
      } else {
        newSocket.emit("likeComments", {
          data: useCommentTop,
          liker: props?.userData?.user?.email ?? newUser?.user.email,
        });
      }

      setUseCommentTop((prev) => {
        //console.log("updated");
        prev["data"]["likes"].push(props?.userData?.user?.email);

        return prev;
      });
      setReladPresent((prev) => {
        if (prev === "true") {
          return "false";
        } else {
          return "true";
        }
      });

      newSocket?.disconnect();
    }
  };

  let querryData = {
    db: props?.targetDb,
    object: props?.targetObject,
  };

  if (!props?.commentId || !props?.collection || !props?.db) {
    return;
  }
  const renderLink = ({ attributes, content }) => {
    const { href, ...props } = attributes;
    //console.log(href, props,content)
    return (
      <Link to={href} {...props}>

        {content.split("").includes("@")?<></>:content}
      </Link>
    );
  };
  return (
    <div key={props?.index}>
      <div key={""} className="d-flex justify-content-start w-100 margin10down">
        <img
          src={authorBackground ?? care}
          className="rounded-circle me-2"
          style={{ width: "30px", height: "30px" }}
          alt={props?.visitor?.name}
        />
        <div style={{ minWidth: "200px" }}>
          <div className="left_div">
          <div className="center_div  center_straight">
            <p
              className="p-1 bg-light  shadow-1 shadow-1 rounded_div"
              style={{ maxWidth: "533px" }}
            >
              <small className="left_div link tiny_font">
                {useCommentTop?.data?.ownerDetails?.name ?? "spectator"}
              </small>
              
              <Linkify options={{ render: renderLink }}>
                <small
                  className=" float text-dark mt-4"
                  
                >{useCommentTop?.data?.message}</small>
              </Linkify>
            </p>
            {/* <p>
              
                <RenderSocialMediaLink text={useCommentTop?.data?.message} />
         
            </p> */}
            </div>
          </div>
          <div className=" tiny_font removespave-15top  center_sides">
            <div className="left_div">
              <div className=" ">
                {timestampDifference(
                  useCommentTop?.updated_at,
                  Date.now() / 1000
                )}
              </div>
              &nbsp;&nbsp;&nbsp;
              {useCommentTop?.data?.likes?.includes(
                props?.userData?.user?.email
              ) || useliked ? (
                <>
                  <div className="link">
                    <FcLike />
                  </div>
                </>
              ) : (
                <div
                  className="link"
                  onClick={() => {
                    handleLikeClick(props?.index);
                    setLiked(true);
                  }}
                >
                  Like
                </div>
              )}
              &nbsp;&nbsp;&nbsp;
              <div className="link bold" onClick={() => handleReplyClick()}>
                Reply
              </div>
            </div>
            <div className="right_div_main removespave-20top">
              <div className="">
                {useCommentTop?.data?.likes?.length > 0 ? (
                  <div className="">
                    {useCommentTop?.data?.likes?.length}{" "}
                    {useCommentTop?.data?.likes?.length > 1
                      ? " likes"
                      : " like"}
                  </div>
                ) : null}
              </div>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
