import React, { useContext } from "react";

import axios from "axios";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { useState, useEffect } from "react";
import {
  
  NavDropdown,
  
} from "react-bootstrap";
import { getToggleButtonGroupUtilityClass } from "@mui/material";
import Switch from "@mui/material/Switch";
import { IoTicketOutline } from "react-icons/io5";
import "../css/ThemeCss.css";
import { LuMenuSquare } from "react-icons/lu";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;

export default function Tickets({ islgedin, issubed,setticketactive}) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [activeElement, setActiveElement] = useState(0);
  const [isLogedin, setIsLogedin] = useState(islgedin);
  const [issubscribed, setIsSubscribed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [TicketList, setTicketList] = useState({});
  const [newName, setInputValue] = useState({});
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
  };
  ////console.log(islgedin, issubed);
  useEffect(() => {
    const fetchData = async () => {
      let ckUser;
      if (!globalUser.user) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log("retiruved from updateLocal for global user")
      }
      if (ckUser && ckUser.result === "retrieved") {
        updateGlobalUser(ckUser?.data?.user);
        try {
          axios.post(`${API_URL}/active_users_save`, {
            email: ckUser?.data?.user?.user?.email,
            page: "MatchStats",
          });
        } catch (error) {
          ("do noithing");
        }
        if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "inactive"
        ) {
          setIsSubscribed(false);
        } else if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
          setIsLogedin(false);
        }
      } else {
        ////console.log("directly from global user")
        if (globalUser){
          if (
            (globalUser &&
              globalUser?.user?.firstname === "Guest" &&
              globalUser?.user) ||
            (globalUser && Object.keys(globalUser).length === 0)
          ) {
            setIsLogedin(false);
            setIsSubscribed(false);
          } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
            if (
              globalUser &&
              !globalUser?.user?.subscription &&
              globalUser?.user
            ) {
              ////console.log(globalUser);
              setIsLogedin(true);
              setTicketList(globalUser?.user?.Tickets);
              setIsSubscribed(false);
            } else {
              if (
                globalUser &&
                globalUser?.user?.subscription?.status === "inactive"
              ) {
                setIsLogedin(true);
                setTicketList(globalUser?.user?.Tickets);
                setIsSubscribed(false);
              } else {
                setIsLogedin(true);
                setTicketList(globalUser?.user?.Tickets);
                setIsSubscribed(true);
              }
            }
          }
        }
        
      }
    };

    fetchData();
  }, [islgedin, issubed]);

  const handleNewTickets = async (name) => {
    const prevTickets = { ...TicketList };
    const prevGlobal = { ...globalUser };
    const res = await axios
      .post(`${API_URL}/new_ticket`, {
        email: globalUser?.user?.email,
        ticketName: name,
      })
      .catch((error) => {});
    ////console.log(name, res);

    if (res && res?.data?.status === "success") {
      prevTickets[res?.data?.ticketNo] = { name: name, matches: [] };
      if ("state" in prevTickets) {
        prevTickets["state"][`${res?.data?.ticketNo}`] = false
      }else{
        prevTickets["state"]={[`${res?.data?.ticketNo}`] : false}
      }
      /* if ("options" in prevTickets) {
        prevTickets["options"][`${res?.data?.ticketNo}`] = {half:"",type:"",btype:"",optns:""}
      }else{
        prevTickets["options"]={[`${res?.data?.ticketNo}`] : {half:"",type:"",btype:"",optns:""}}
      } */
      if ("Tickets" in prevGlobal?.user){
        prevGlobal["user"]["Tickets"][`${res?.data?.ticketNo}`] = { name: name, matches: []}
        if ("state" in prevGlobal?.user?.Tickets){
          prevGlobal["user"]["Tickets"]["state"][`${res?.data?.ticketNo}`] = false
        }else{
          prevGlobal["user"]["Tickets"]["state"] = {[`${res?.data?.ticketNo}`]: false
        };
        }
        /* if ("options" in prevGlobal?.user?.Tickets){
          prevGlobal["user"]["Tickets"]["options"][`${res?.data?.ticketNo}`] = {half:"",type:"",btype:"",optns:""}
        }else{
          prevGlobal["user"]["Tickets"]["options"] = {[`${res?.data?.ticketNo}`]: {half:"",type:"",btype:"",optns:""}
        };
        } */

          

      }else{
        prevGlobal["user"]["Tickets"] = {
          [`${res?.data?.ticketNo}`]: { name: name, matches: [], state:{"1":false, '2':false,"3":false}},
        };
        prevGlobal["user"]["Tickets"]["state"] = {[`${res?.data?.ticketNo}`]: false
        };
        /* prevGlobal["user"]["Tickets"]["options"] = {[`${res?.data?.ticketNo}`]: {half:"",type:"",btype:"",optns:""}
        }; */
      }
      
      setTicketList( () => prevTickets);
      ////console.log(prevGlobal)
      updateGlobalUser(() => prevGlobal);
      let ckUser = UpdateLocal({
        action: "save",
        data: prevGlobal,
      });
      ////console.log(prevTickets);
    }
  };

  const changeName = async (name, ticketNo) => {
    const prevTickets = { ...TicketList };
    const prevGlobal = { ...globalUser };
    const res = await axios
      .post(`${API_URL}/rename_ticket`, {
        email: globalUser?.user?.email,
        ticketNumber: ticketNo,
        ticketName: name,
      })
      .catch((error) => {});
    ////console.log(name, res);

    if (res && res?.data?.status === "success") {
      prevTickets[ticketNo]["name"] = name;
      prevGlobal["user"]["Tickets"][ticketNo]["name"] = name;

      

      setTicketList(() => prevTickets);

      updateGlobalUser(prevGlobal => {
        const newGlobal = { ...prevGlobal };
        newGlobal["user"]["Tickets"][ticketNo]["name"] = name;
        let ckUser = UpdateLocal({
          action: "save",
          data: newGlobal,
        });
        return newGlobal;
      }); 
      //updateGlobalUser(() => prevGlobal);
      let ckUser = UpdateLocal({
        action: "save",
        data: prevGlobal,
      });
      ////console.log(prevTickets,prevGlobal);
    }
  };

  const clearTicket = async (ticketNo, types) => {
    try {
      const res = await axios.post(`${API_URL}/clear_ticket`, {
        email: globalUser?.user?.email,
        ticketNumber: ticketNo,
        type: types,
      });

      if (res && res?.data?.status === "success") {
        const newTickets = { ...TicketList };
        const newGlobal = { ...globalUser };
        if (types === "clear") {
          newTickets[ticketNo]["matches"] = [];
          newGlobal["user"]["Tickets"][ticketNo]["matches"] = [];

        } else {
          delete newTickets[ticketNo];
          delete newGlobal["user"]["Tickets"][ticketNo];
          delete newTickets["state"][ticketNo]
          /* delete newTickets["options"][ticketNo] */
          delete newGlobal["user"]["Tickets"]["state"][ticketNo];
        }
        setTicketList(() => newTickets);
        updateGlobalUser(() => newGlobal);
        let ckUser = UpdateLocal({
          action: "save",
          data: newGlobal,
        });

      }

      ////console.log(globalUser);
    } catch (error) {
      console.error(error);
      // handle error appropriately, either set error state or show notification, etc.
    }
    ////console.log(globalUser);
  };
////console.log(TicketList,globalUser)
  function CreateTickets({ name, ticketNO,index }) {
    const [isClickEdit, setisClickEdit] = useState(false);
    //const [activeTicket, setActiveTicket] = useState({"1":false, '2':false,"0":false})
    const [ischangeName, setischangeName] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [checked, setChecked] = useState(TicketList?.["state"]?.[ticketNO]);

    const handleAddTickets_ = (event) => {
      event.preventDefault(); // Prevent page refresh on form submission
      ////console.log(inputValue); // Log or use inputValue here
      changeName?.(inputValue, ticketNO);
      setInputValue?.(""); // Clear the input field
    };

    const handleClear = (ticketNO_) => {
      ////console.log("was hers for clearance");
      clearTicket?.(ticketNO_, "clear");
    };
    const handleRemove = (ticketNO_) => {
      clearTicket?.(ticketNO_, "remove");
    };
    const handleChange = (event) => {
      setChecked?.(event.target.checked);
      ToggTicket?.(ticketNO,event.target.checked)
      setticketactive?.(true)
    };
    
    return (
      <div>
        <div className="allpad">
          <div className=" center_straight flex_column usemaxSpace">
            <div className="center_div_distributed center_sides ticketside">
              <div>
                <ControlledSwitches key={index} handleChange={handleChange} checked={checked}/>
              </div>
              <div>{name}</div>

              <div>
                <div className="marginright-10">
                  <NavDropdown
                    title={
                      <LuMenuSquare
                        size={20}
                        onClick={() => {
                          setisClickEdit(true);
                        }}
                      />
                    }
                    id="basic-nav-dropdown"
                    className="align-items-center"
                  >
                    <NavDropdown.Item
                      onClick={() =>
                        setischangeName((prevchangeName) => !prevchangeName)
                      }
                    >
                      Rename
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={() => handleClear(ticketNO)}>
                      Clear
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={() => handleRemove(ticketNO)}>
                      Remove
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </div>
            {ischangeName && (
              <div className="center_div allpad">
                <form
                  onSubmit={handleAddTickets_}
                  style={{
                    width: "100%",
                    marginBottom: "1em",
                  }}
                  className="center_div_distributed "
                >
                  <button
                    type="submit"
                    style={{
                      width: "50%",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                    className="center_div allpad"
                  >
                    Save
                  </button>
                  &nbsp;
                  <input
                    type="text"
                    value={inputValue}
                    maxLength="7"
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ width: "100%", maxHeight: "30px" }}
                    className="allpad"
                  />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function AddTickets() {
    const [isClickAdd, setisClickAdd] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleAddTickets = (event) => {
      event.preventDefault(); // Prevent page refresh on form submission
      ////console.log(inputValue); // Log or use inputValue here
      handleNewTickets(inputValue);
      setisClickAdd(false);
      setInputValue(""); // Clear the input field
    };
    return (
      <div>
        <div className=" center_div flex_column usemaxSpace">
          <div
            onClick={() => setisClickAdd((prevIsClickAdd) => !prevIsClickAdd)}
            className="center_div_distributed center_sides"
          >
            <div>
              <IoTicketOutline />
            </div>
            <div>Add tickets</div>
          </div>
          {isClickAdd ? (
            !islgedin ? (
              <div className="center_div tinyfont_error_font">
                {" "}
                login to add ticket{" "}
              </div>
            ) : TicketList &&
              Object.keys(TicketList).length === 2 &&
              !issubed ? (
              <div className="center_div tinyfont_error_font">
                {" "}
                subscribe to add more tickets{" "}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <form
                  onSubmit={handleAddTickets}
                  style={{
                    width: "100%",
                    maxWidth: "100px",
                    marginBottom: "1em",
                  }}
                >
                  <input
                    type="text"
                    value={inputValue}
                    maxLength="7"
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ width: "100%", padding: "0.5em" }}
                  />
                  <button
                    type="submit"
                    style={{ width: "100%", height: "2em", marginTop: "1em" }}
                  >
                    Add
                  </button>
                </form>
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  }
  /* Modal.setAppElement("#yourAppElement"); */
  
  const ControlledSwitches = ({checked,handleChange}) => {

    
    ////console.log(TicketList?.["state"], TicketList?.["state"]?.[ticketNO])
    
    return (
      <div>
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      </div>
    );
  }

  const ToggTicket = async (ticketNo,state) => {
    try {
      const res = await axios.post(`${API_URL}/activate_ticket`, {
        email: globalUser?.user?.email,
        ticketNumber: ticketNo,
        state: state,
      });
      ////console.log(res?.data?.status)
      if (res && res?.data?.status === "success") {
        const newTickets = { ...TicketList };
        const newGlobal = { ...globalUser };
        let currState = newTickets?.state
        ////console.log(state,currState)

        for (let tN = 0; tN < Object.keys(currState).length; tN++) {
          const key = Object.keys(currState)[tN];
          if(key === ticketNo){
            currState[ticketNo] = state
          }else{
            currState[key] = false
          }
          
        }
        ////console.log(state,currState)
        
        newTickets[ticketNo]["state"] = currState;
        newGlobal["user"]["Tickets"]["state"] = currState;
        
        setTicketList(() => newTickets);
        updateGlobalUser(() => newGlobal);
        let ckUser = UpdateLocal({
          action: "save",
          data: newGlobal,
        });

      }

      ////console.log(globalUser);
    } catch (error) {
      console.error(error);
      // handle error appropriately, either set error state or show notification, etc.
    }
    ////console.log(globalUser);

  }

  

  if (!globalUser) {
    return null;
  }
  return (
    <div className="mainFont ">
      <div className="center_div">Tickets</div>
      {TicketList && Object.keys(TicketList).length - 1 > 0 ? (
        <>
          {islgedin &&
            Object.keys(TicketList).map((item, index) => {
              
              return (
                item !== "state" &&<CreateTickets
                  name={TicketList?.[item]?.name}
                  ticketNO={item}
                  key={index}
                  index={index}
                
                />
              );
            })}

          {TicketList &&
          Object.keys(TicketList).length -1 >= 3 &&
          issubed ? null : (
            <div className="allpad">
              <div className="center_div ticketside link forceblack">
                <AddTickets />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="center_div ticketside link forceblack">
          <AddTickets />
        </div>
      )}
    </div>
  );
}

export function TicketDisplay() {}
