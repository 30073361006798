import React from 'react';
import { MDBBadge } from 'mdb-react-ui-kit';

export default function BottomAverts() {

  return (
      <div className='row gx-lg-5'>
        <div className='col-lg-6 mb-5'>
          <div className='d-flex align-items-start'>
            <div className='flex-shrink-0'>
              <MDBBadge light='true' className='p-3 badge-primary rounded-4'>
                <i className='fas fa-share-alt-square fa-xl text-primary fa-fw'></i>
              </MDBBadge>
            </div>
            <div className='flex-grow-1 ms-4'>
              <p className='fw-bold mb-1'>Create and Share</p>
              <p className='text-muted mb-1'>BetInformatics is designed to enable punters and sport lovers create betting tickets which they share to anyone for FREE. You can also share news and other events to your followers.</p>
              <small>
                <a href=''>Learn more</a>
              </small>
            </div>
          </div>
        </div>

        <div className='col-lg-6 mb-5'>
          <div className='d-flex align-items-start'>
            <div className='flex-shrink-0'>
              <MDBBadge light='true' className='p-3 badge-primary rounded-4'>
                <i className='fas fa-chart-line fa-xl text-primary fa-fw'></i>
              </MDBBadge>
            </div>
            <div className='flex-grow-1 ms-4'>
              <p className='fw-bold mb-1'>Do it yourself for FREE</p>
              <p className='text-muted mb-1'>You do not have to pay for tips, you can do your research for free.  </p>
              <small>
                <a href=''>Learn more</a>
              </small>
            </div>
          </div>
        </div>

        <div className='col-lg-6 mb-5'>
          <div className='d-flex align-items-start'>
            <div className='flex-shrink-0'>
              <MDBBadge light='true' className='p-3 badge-primary rounded-4'>
                <i className='fas fa-list fa-xl text-primary fa-fw'></i>
              </MDBBadge>
            </div>
            <div className='flex-grow-1 ms-4'>
              <p className='fw-bold mb-1'>Monthly Competitions</p>
              <p className='text-muted mb-1'>We have various competitions you can join for free to earn cash rewards. Winners of each competition are ranked based on the cummulated ROI of their tickets in the competition.</p>
              <small>
                <a href=''>Learn more</a>
              </small>
            </div>
          </div>
        </div>

        <div className='col-lg-6 mb-5'>
          <div className='d-flex align-items-start'>
            <div className='flex-shrink-0'>
              <MDBBadge light='true' className='p-3 badge-primary rounded-4'>
                <i className='fas fa-briefcase fa-xl text-primary fa-fw'></i>
              </MDBBadge>
            </div>
            <div className='flex-grow-1 ms-4'>
              <p className='fw-bold mb-1'>In-house Tipstar Jobs</p>
              <p className='text-muted mb-1'>Every month, we pick top Tipstars to become our In-house tipstars which comes with a lot of benefits. </p>
              <small>
                <a href=''>Learn more</a>
              </small>
            </div>
          </div>
        </div>

        <div className='col-lg-6 mb-5'>
          <div className='d-flex align-items-start'>
            <div className='flex-shrink-0'>
              <MDBBadge light='true' className='p-3 badge-primary rounded-4'>
                <i className='fas fa-award fa-xl text-primary fa-fw'></i>
              </MDBBadge>
            </div>
            <div className='flex-grow-1 ms-4'>
              <p className='fw-bold mb-1'>Find Great Tipstars</p>
              <p className='text-muted mb-1'>As a bettor You can find greate tipstars with high weekly/monthly ROI and subscribe to their private tickets for a fee. Each time they create a private ticket, you would get a notification in your email.</p>
              <small>
                <a href=''>Learn more</a>
              </small>
            </div>
          </div>
        </div>

        <div className='col-lg-6 mb-5'>
          <div className='d-flex align-items-start'>
            <div className='flex-shrink-0'>
              <MDBBadge light='true' className='p-3 badge-primary rounded-4'>
                <i className='fas fa-hand-holding-usd fa-xl text-primary fa-fw'></i>
              </MDBBadge>
            </div>
            <div className='flex-grow-1 ms-4'>
              <p className='fw-bold mb-1'>Become a Paid Tipstar</p>
              <p className='text-muted mb-1'>As a Tipstar with high ROI, your followers can subscribe to your premium ticket for a fee, the more subscribers you have, the more you smile to the bank every month.</p>
              <small>
                <a href=''>Learn more</a>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
}