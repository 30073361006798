import React from "react";
import {
  PieChart,
  ResponsiveContainer,
  Pie,
  Sector,
  Cell,
  Legend,
} from "recharts";
import {
  Row,
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import DisplayValue from "./ValueDisplay";
import RenderPercentatge from "./PercentageDisplay";
export default function TeamPieChart({
  Pie_data,
  side,
  half,
  type,
  small,
  mid,
}) {
  function getColorForNoGoal(data, name) {
    // Find the object with the given name
    const noGoal = data.find((item) => item.name === name);

    if (!noGoal) {
      // If the name is not found, return a default color
      return "gray";
    }

    // Get the value of "No Goals"
    const noGoalValue = noGoal.value;

    // Find the maximum and minimum values in the data
    const max = Math.max(...data.map((item) => item.value));
    const min = Math.min(...data.map((item) => item.value));
    ////console.log(data.lenght);
    if (data && data?.length > 2) {
      if (noGoalValue === max) {
        // If "No Goals" has the highest value, return green
        return "green";
      } else if (noGoalValue === min) {
        // If "No Goals" has the lowest value, return red
        return "red";
      } else {
        // Otherwise, return yellow
        return "orange";
      }
    } else {
      if (noGoalValue === max) {
        // If "No Goals" has the highest value, return green
        return "green";
      } else if (noGoalValue === min) {
        // If "No Goals" has the lowest value, return red
        return "orange";
      }
    }
  }
  let halfs = "ft";
  if (half === "Full Time") {
    halfs = "ft";
  } else if (half === "First Half") {
    halfs = "ht";
  } else if (half === "Second Half") {
    halfs = "h2";
  } else {
    halfs = "eh";
  }
  ////console.log(Pie_data, side, half, type);
  let options_ = Pie_data?.[side]?.[0];

  let options = options_ && Object.keys(options_);
  let d1 = [];
  let d2 = [];
  //console.log(type);
  if (
    type === "Win-Draw-Lose" ||
    type === "WDL-Cards" ||
    type === "WDL-Corners"
  ) {
    for (let ty = 0; ty < options?.length; ty++) {
      const halfs_option = options_?.[options?.[ty]];
      ////console.log(halfs_option, options?.[ty]);
      const centerData = "Total";
      const centerData2 = halfs_option?.["total"];
      let data = [];
      let COLORS = [];
      let name = [];
      if (options?.[ty] === "ft") {
        name = "Full Time";
      } else if (options?.[ty] === "ht") {
        name = "Half Time";
      } else if (options?.[ty] === "h2") {
        name = "Second Half";
      }

      if (options?.[ty] === "eh") {
        if (type === "Win-Draw-Lose") {
          const data1 = [
            { name: "WEA Yes", value: halfs_option?.["win"]?.["weh_yes"] },
            { name: "WEA No", value: halfs_option?.["win"]?.["weh_no"] },
          ];
          const data2 = [
            { name: "DEA Yes", value: halfs_option?.["draw"]?.["deh_yes"] },
            { name: "DEA No", value: halfs_option?.["draw"]?.["deh_no"] },
          ];
          COLORS = ["green", "orange"];
          d1.push([data1, COLORS, centerData, centerData2, "Win Either half"]);
          d1.push([data2, COLORS, centerData, centerData2, "Draw Either Half"]);
        }
      } else {
        data = [
          { name: "Wins", value: halfs_option?.["win"] },
          { name: "Draws", value: halfs_option?.["draw"] },
          { name: "Losses", value: halfs_option?.["loss"] },
        ];
        COLORS = ["green", "orange", "red"];

        d1.push([data, COLORS, centerData, centerData2, name]);
      }
    }
  }
  if (type === "Goals") {
    ////console.log(options_, halfs);
    options_ = options_?.[halfs];
    options = options_ && Object.keys(options_);
    for (let ty = 0; ty < options?.length; ty++) {
      const halfs_option = options_?.[options?.[ty]];
      ////console.log(halfs_option, options, options?.[ty]);
      const centerData = "Total";
      const centerData2 = halfs_option?.["total"];
      let data = [];
      let COLORS = [];
      if (options?.[ty] === "btts") {
        const centerData2 =
          halfs_option?.["bts_yes"] + halfs_option?.["bts_no"];
        const centerData = "Total";
        const name = "Both Teams To Score";
        data = [
          { name: "BTS Yes", value: halfs_option?.["bts_yes"] },
          { name: "BTS No", value: halfs_option?.["bts_no"] },
        ];
        COLORS = [
          getColorForNoGoal(data, "BTS Yes"),
          getColorForNoGoal(data, "BTS No"),
        ];
        d1.push([data, COLORS, centerData, centerData2, name]);
      } else if (options?.[ty] === "to_score") {
        const centerData2 = halfs_option?.["score"] + halfs_option?.["noScore"];
        const centerData = "Total";
        const name = "Team To Score";
        data = [
          { name: "Scored", value: halfs_option?.["score"] },
          { name: "No Score", value: halfs_option?.["noScore"] },
        ];
        COLORS = [
          getColorForNoGoal(data, "Scored"),
          getColorForNoGoal(data, "No Score"),
        ];
        d1.push([data, COLORS, centerData, centerData2, name]);
      } else if (options?.[ty] === "over_under") {
        let under = "";
        let over = "";
        const totalmatches =
          options_?.["btts"]?.["bts_yes"] + options_?.["btts"]?.["bts_no"];
        const val_ =
          halfs_option?.["under"] + halfs_option?.["over"] - totalmatches;
        const centerData2 = halfs_option?.["g_average"];
        const centerData = "Avg Goals";
        const name = "Goals Stats";
        if (half === "Second Half" || half === "First Half") {
          under = "Un" + "0" + ".5" + " Goals";
          over = "Ov" + "0" + ".5" + " Goals";
        } else {
          under = "Un" + "1" + ".5" + " Goals";
          over = "Ov" + "1" + ".5" + " Goals";
        }
        // Sample data
        data = [
          { name: under, value: halfs_option?.["under"] },
          { name: over, value: halfs_option?.["over"] },
          {
            name: "No Goals",
            value: val_,
          },
        ];
        COLORS = [
          getColorForNoGoal(data, under),
          getColorForNoGoal(data, over),
          getColorForNoGoal(data, "No Goals"),
        ];

        d1.push([data, COLORS, centerData, centerData2, name]);
      }
    }
  }
  if (type === "Cards") {
    ////console.log(options_, halfs);
    options_ = options_?.[halfs];
    options = options_ && Object.keys(options_);
    for (let ty = 0; ty < options?.length; ty++) {
      const halfs_option = options_?.[options?.[ty]];
      ////console.log(halfs_option, options, options?.[ty]);
      const centerData = "Total";
      const centerData2 = halfs_option?.["total"];
      let data = [];
      let COLORS = [];
      if (options?.[ty] === "over_under") {
        let under = "";
        let over = "";
        const centerData2 = halfs_option?.["g_average"];
        const centerData = "Avg Cards";
        const name = "Cards Stats";
        if (half === "Second Half" || half === "First Half") {
          under = "Un" + "1" + ".5" + " Cards";
          over = "Ov" + "1" + ".5" + " Cards";
        } else {
          under = "Un" + "2" + ".5" + " Cards";
          over = "Ov" + "2" + ".5" + " Cards";
        }
        // Sample data
        data = [
          { name: under, value: halfs_option?.["under"] },
          { name: over, value: halfs_option?.["over"] },
        ];
        COLORS = [
          getColorForNoGoal(data, under),
          getColorForNoGoal(data, over),
        ];

        d1.push([data, COLORS, centerData, centerData2, name]);
      }
    }
  }
  if (type === "Corners") {
    ////console.log(options_, halfs);
    options_ = options_?.[halfs];
    options = options_ && Object.keys(options_);
    for (let ty = 0; ty < options?.length; ty++) {
      const halfs_option = options_?.[options?.[ty]];
      ////console.log(halfs_option, options, options?.[ty]);
      const centerData = "Total";
      const centerData2 = halfs_option?.["total"];
      let data = [];
      let COLORS = [];
      if (options?.[ty] === "over_under") {
        let under = "";
        let over = "";
        const centerData2 = halfs_option?.["g_average"];
        const centerData = "Avg Cnrs";
        const name = "Corner Stats";
        if (half === "Second Half" || half === "First Half") {
          under = "Un" + "3" + ".5" + " Corners";
          over = "Ov" + "3" + ".5" + " Corners";
        } else {
          under = "Un" + "5" + ".5" + " corners";
          over = "Ov" + "5" + ".5" + " Corners";
        }
        // Sample data
        data = [
          { name: under, value: halfs_option?.["under"] },
          { name: over, value: halfs_option?.["over"] },
        ];
        COLORS = [
          getColorForNoGoal(data, under),
          getColorForNoGoal(data, over),
        ];

        d1.push([data, COLORS, centerData, centerData2, name]);
      }
    }
  }
  ////console.log(d1, d2);

  const RADIAN = Math.PI / 180;
  const RenderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    centerData,
    centerData2,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const scy = cx + 0;
    return (
      <g>
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: "14Px", fontFamily: "serif" }}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text
          x={cx}
          y={cy}
          textAnchor="middle"
          fill="grey"
          style={{ fontSize: "14Px", fontFamily: "serif" }}
        >
          {centerData}
        </text>
        <text
          x={cx}
          y={scy}
          textAnchor="middle"
          fill="grey"
          style={{ fontSize: "14Px", fontFamily: "serif" }}
        >
          {centerData2}
        </text>
      </g>
    );
  };
  const customLegendStyle = {
    fontSize: "14px", // Change the font size to your desired value
    fontFamily: "serif",
    margin: "10px",
  };

  const DisplayChart = ({
    data_2show,
    colors,
    centerData,
    centerData2,
    options_show,
  }) => {
    return (
      <div style={{ width: "100%", height: "216px" }} className="mainFont">
        <Modal.Dialog>
          <Modal.Body className="">
            <div>
              <div className="margin30down center_div">{options_show}</div>

              <div style={{ width: "100%", height: "216px" }}>
                <ResponsiveContainer>
                  <PieChart
                    margin={{
                      top: -20,
                      bottom: 0,
                    }}
                  >
                    <Pie
                      data={data_2show}
                      cx={108}
                      cy={108}
                      innerRadius={33}
                      outerRadius={70}
                      labelLine={false}
                      label={
                        <RenderCustomizedLabel
                          centerData={centerData}
                          centerData2={centerData2}
                        />
                      }
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data_2show.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colors[index % colors.length]}
                        />
                      ))}
                    </Pie>

                    <Legend wrapperStyle={customLegendStyle} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  };
  //console.log(d1);
  return (
    <div>
      {type === "Win-Draw-Lose" ||
      type === "WDL-Cards" ||
      type === "WDL-Corners" ? (
        <>
          {small ? (
            <div>
              {d1.slice(0, 4).map((item, index) => {
                return (
                  <div
                    className="margin-50down"
                    style={{ width: "100%", height: "216px" }}
                  >
                    <ResponsiveContainer>
                      <Container style={{ width: "65%" }}>
                        <DisplayChart
                          key={index}
                          data_2show={item[0]}
                          colors={item[1]}
                          centerData={item[2]}
                          centerData2={item[3]}
                          options_show={item[4]}
                        />
                      </Container>
                    </ResponsiveContainer>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="margin-50down">
              <Row>
                {d1.slice(0, 4).map((item, index) => {
                  return (
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <DisplayChart
                        key={index}
                        data_2show={item[0]}
                        colors={item[1]}
                        centerData={item[2]}
                        centerData2={item[3]}
                        options_show={item[4]}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}

          <div className="margin-10down">
            {small ? (
              <>
                <div>
                  {d1.slice(4).map((item, index) => {
                    return (
                      <div
                        className="margin-50down"
                        style={{ width: "100%", height: "216px" }}
                      >
                        <ResponsiveContainer>
                          <Container style={{ width: "65%" }}>
                            <DisplayChart
                              key={index}
                              data_2show={item[0]}
                              colors={item[1]}
                              centerData={item[2]}
                              centerData2={item[3]}
                              options_show={item[4]}
                            />
                          </Container>
                        </ResponsiveContainer>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="margin-50down"
                  style={{ width: "100%", height: "216px" }}
                >
                  <ResponsiveContainer>
                    <Container style={{ width: "65%" }}>
                      <Modal.Dialog>
                        <Modal.Body className="mainFont ">
                          <div>
                            <div className="center_div">
                              {"Average Possessions"}
                            </div>

                            <RenderPercentatge
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.possessions?.poss_Ppg
                              )}
                            />
                          </div>
                        </Modal.Body>
                      </Modal.Dialog>
                    </Container>
                  </ResponsiveContainer>
                </div>
                <div
                  className="margin-50down"
                  style={{ width: "100%", height: "216px" }}
                >
                  <ResponsiveContainer>
                    <Container style={{ width: "65%" }}>
                      <Modal.Dialog>
                        <Modal.Body className="mainFont center_div">
                          <div>
                            <div className="center_div">
                              {"Avg Attacks Per Game"}
                            </div>

                            <div>
                              <DisplayValue
                                dis_value={Math.round(
                                  Pie_data?.[side]?.[0]?.ft?.attacks?.attack_Ppg
                                )}
                                dis_text={"Attacks PG"}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal.Dialog>
                    </Container>
                  </ResponsiveContainer>
                </div>
                <div
                  className="margin-50down"
                  style={{ width: "100%", height: "216px" }}
                >
                  <ResponsiveContainer>
                    <Container style={{ width: "65%" }}>
                      <Modal.Dialog>
                        <Modal.Body className="mainFont center_div">
                          <div>
                            <div className="center_div">
                              {"Avg Dangerous Attacks Per Game"}
                            </div>

                            <div>
                              <DisplayValue
                                dis_value={Math.round(
                                  Pie_data?.[side]?.[0]?.ft?.attacks?.da_att_Ppg
                                )}
                                dis_text={"Dang Att PG"}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal.Dialog>
                    </Container>
                  </ResponsiveContainer>
                </div>
              </>
            ) : (
              <Row>
                {d1.slice(4).map((item, index) => {
                  return (
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <DisplayChart
                        key={index}
                        data_2show={item[0]}
                        colors={item[1]}
                        centerData={item[2]}
                        centerData2={item[3]}
                        options_show={item[4]}
                      />
                    </Col>
                  );
                })}

                <Col lg={5} md={5} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body>
                      <div className="margin30down mainFont center_div">
                        {"Average Possessions"}
                      </div>

                      <RenderPercentatge
                        dis_value={Math.round(
                          Pie_data?.[side]?.[0]?.ft?.possessions?.poss_Ppg
                        )}
                      />
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">
                          {"Avg Attacks Per Game"}
                        </div>

                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.attacks?.attack_Ppg
                            )}
                            dis_text={"Attacks PG"}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Dangerous Attacks Per Game"}
                          </div>

                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.attacks?.da_att_Ppg
                              )}
                              dis_text={"Dang Att PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Col>
                </Row>
              </Row>
            )}
          </div>
        </>
      ) : type === "Goals" ? (
        <div className="margin-50down">
          {small ? (
            <>
              <div>
                {d1.slice(0, 4).map((item, index) => {
                  return (
                    <div
                      className="margin-50down"
                      style={{ width: "100%", height: "216px" }}
                    >
                      <ResponsiveContainer>
                        <Container style={{ width: "65%" }}>
                          <DisplayChart
                            key={index}
                            data_2show={item[0]}
                            colors={item[1]}
                            centerData={item[2]}
                            centerData2={item[3]}
                            options_show={item[4]}
                          />
                        </Container>
                      </ResponsiveContainer>
                    </div>
                  );
                })}
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Attacks Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.attacks?.attack_Ppg
                              )}
                              dis_text={"Attacks PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Dangerous Attacks Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.attacks?.da_att_Ppg
                              )}
                              dis_text={"Dang Att PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Shots Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.shots?.t_Pspg
                              )}
                              dis_text={"Shots PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Shots On Target Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.shots?.t_Psontpg
                              )}
                              dis_text={"Shots On Target PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <>
              <Row>
                {d1.slice(0, 4).map((item, index) => {
                  return (
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <DisplayChart
                        key={index}
                        data_2show={item[0]}
                        colors={item[1]}
                        centerData={item[2]}
                        centerData2={item[3]}
                        options_show={item[4]}
                      />
                    </Col>
                  );
                })}
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">
                          {"Avg Attacks Per Game"}
                        </div>
                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.attacks?.attack_Ppg
                            )}
                            dis_text={"Attacks PG"}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">
                          {"Avg Dangerous Attacks Per Game"}
                        </div>
                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.attacks?.da_att_Ppg
                            )}
                            dis_text={"Dang Att PG"}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">{"Avg Shots Per Game"}</div>
                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.shots?.t_Pspg
                            )}
                            dis_text={"Shots PG"}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">
                          {"Avg Shots On Target Per Game"}
                        </div>
                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.shots?.t_Psontpg
                            )}
                            dis_text={"Shots On Target PG"}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
              </Row>
            </>
          )}
        </div>
      ) : type === "Cards" ? (
        <>
          {small ? (
            <>
              <div className="">
                {d1.slice(0, 4).map((item, index) => {
                  return (
                    <div
                      className="margin-50down"
                      style={{ width: "100%", height: "216px" }}
                    >
                      <ResponsiveContainer>
                        <Container style={{ width: "65%" }}>
                          <DisplayChart
                            key={index}
                            data_2show={item[0]}
                            colors={item[1]}
                            centerData={item[2]}
                            centerData2={item[3]}
                            options_show={item[4]}
                          />
                        </Container>
                      </ResponsiveContainer>
                    </div>
                  );
                })}
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Fouls Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.fouls?.foul_av
                              )}
                              dis_text={"Foul PG"}
                            />{" "}
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Card of Opponent Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.avg_against
                              )}
                              dis_text={"Opp Cards PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <>
              <Row>
                {d1.slice(0, 4).map((item, index) => {
                  return (
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <DisplayChart
                        key={index}
                        data_2show={item[0]}
                        colors={item[1]}
                        centerData={item[2]}
                        centerData2={item[3]}
                        options_show={item[4]}
                      />
                    </Col>
                  );
                })}
                <Col lg={4} md={3} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">{"Avg Fouls Per Game"}</div>
                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.fouls?.foul_av
                            )}
                            dis_text={"Foul PG"}
                          />{" "}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
                <Col lg={4} md={3} sm={12} xs={12}>
                  <Modal.Dialog>
                    <Modal.Body className="mainFont center_div">
                      <div className="margin30down">
                        <div className="center_div">
                          {"Avg Card of Opponent Per Game"}
                        </div>
                        <div>
                          <DisplayValue
                            dis_value={Math.round(
                              Pie_data?.[side]?.[0]?.ft?.avg_against
                            )}
                            dis_text={"Opp Cards PG"}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
              </Row>{" "}
            </>
          )}
        </>
      ) : type === "Corners" ? (
        <>
          {small ? (
            <>
              <div className="">
                {d1.slice(0, 4).map((item, index) => {
                  return (
                    <div
                      className="margin-50down"
                      style={{ width: "100%", height: "216px" }}
                    >
                      <ResponsiveContainer>
                        <Container style={{ width: "65%" }}>
                          <DisplayChart
                            key={index}
                            data_2show={item[0]}
                            colors={item[1]}
                            centerData={item[2]}
                            centerData2={item[3]}
                            options_show={item[4]}
                          />
                        </Container>
                      </ResponsiveContainer>
                    </div>
                  );
                })}
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Corners of Opponent Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.avg_against
                              )}
                              dis_text={"Opp Corners PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
              <div
                className="margin-50down"
                style={{ width: "100%", height: "216px" }}
              >
                <ResponsiveContainer>
                  <Container style={{ width: "65%" }}>
                    <Modal.Dialog>
                      <Modal.Body className="mainFont center_div">
                        <div className="margin30down">
                          <div className="center_div">
                            {"Avg Offsides Per Game"}
                          </div>
                          <div>
                            <DisplayValue
                              dis_value={Math.round(
                                Pie_data?.[side]?.[0]?.ft?.offsides?.team_Pofpg
                              )}
                              dis_text={"Offsides PG"}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <Row>
              {d1.slice(0, 4).map((item, index) => {
                return (
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <DisplayChart
                      key={index}
                      data_2show={item[0]}
                      colors={item[1]}
                      centerData={item[2]}
                      centerData2={item[3]}
                      options_show={item[4]}
                    />
                  </Col>
                );
              })}

              <Col lg={4} md={3} sm={12} xs={12}>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div className="margin30down">
                      <div className="center_div">
                        {"Avg Corners of Opponent Per Game"}
                      </div>
                      <div>
                        <DisplayValue
                          dis_value={Math.round(
                            Pie_data?.[side]?.[0]?.ft?.avg_against
                          )}
                          dis_text={"Opp Corners PG"}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal.Dialog>
              </Col>

              <Col lg={4} md={3} sm={12} xs={12}>
                <Modal.Dialog>
                  <Modal.Body className="mainFont center_div">
                    <div className="margin30down">
                      <div className="center_div">
                        {"Avg Offsides Per Game"}
                      </div>
                      <div>
                        <DisplayValue
                          dis_value={Math.round(
                            Pie_data?.[side]?.[0]?.ft?.offsides?.team_Pofpg
                          )}
                          dis_text={"Offsides PG"}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal.Dialog>
              </Col>
            </Row>
          )}
        </>
      ) : null}
    </div>
  );
}
