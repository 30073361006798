import React from "react";
import axios from "axios";

function getDataFromLocalStorage(keys) {
  const data = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const values = localStorage.getItem(key);
    const value = JSON.parse(values);
    if (value !== null) {
      data[key] = value;
    }
  }
  return data;
}

export const UpdateLocal = async ({ action, data }) => {
  /* if (action && action === "save") {
    localStorage.setItem(data.name, JSON.stringify(data));
    return { result: "saved" };
  } */
  if (action && action === "save") {
    const dataOwner = Object.keys(data)[0];
    const prevSaved = localStorage.getItem(data?.user?.name); // Corrected variable name
    ////console.log("presave from previous data", prevSaved);
    ////console.log("data from profile page", data);
    if (!prevSaved) {
      localStorage.setItem(dataOwner, JSON.stringify(data));
      return { result: "saved" };
    } else {
      const existingData = JSON.parse(prevSaved) || {};
      //const parsedData = { ...JSON.parse(prevSaved), ...data };

      if ("user" in existingData && typeof existingData["user"] === "object") {
        // Step 3: If "user" exists, update values or add new key-value pairs
        const userObject = existingData["user"];

        // Iterate over the keys in the updatedUserDict
        for (const key in data?.user) {
          if (data?.user?.hasOwnProperty(key)) {
            // Check if the key exists in the userObject
            if (key in userObject) {
              // If the key exists, update the value
              userObject[key] = data?.user?.[key];
            } else {
              // If the key does not exist, add both the key and value
              userObject[key] = data?.user?.[key];
            }
          }
        }
        existingData["user"] = userObject;
        ////console.log("updated", existingData);
      } else {
        // Step 4: If "user" does not exist, create it with the updatedUserDict
        existingData["user"] = data;
        ////console.log("overritten");
      }

      //parsedData[data.name] = data;
      localStorage.setItem(dataOwner, JSON.stringify(existingData));
      return { result: "saved" };
    }
  }

  if (action && action === "get") {
    const getKeys = data.getKeys;
    const results = getDataFromLocalStorage(getKeys); // getDataFromLocalStorage is not defined
    if (results && Object.keys(results).length > 0) {
      // Check if object is not empty
      ////console.log(results);
      return { result: "retrieved", data: results };
    } else {
      return { result: "empty" };
    }
  }

  if (action && action === "delete") {
    const rmKeys = data.rmKeys;
    rmKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    return { result: "deleted" };
  }
};
