import React, { useState, useEffect, useRef, useContext } from "react";
import { MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import IndexHeader from "../IndexHeaders";
import MessageChatPartners from "./ChatPatners";
import RenderChats from "./ChatC2C";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { io } from "socket.io-client";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import FooterAll from "../Footer";
import FooterNav from "../SmallScreenNav";
import { SideNavPage } from "../Utils";

const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;

const MessageApp = () => {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const location = useLocation();
  const navigation = useNavigate();
  const currPartner = location.state || {}
  const innerWidth = useRef(null);
  const [useChat, setUseChat] = useState({});
  const [useVisitor, setUseVisitor] = useState();
  
  const [USeChatIndex, setUSeChatIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [basicOpen, setBasicOpen] = useState(true);
  const [UseMessagePartners, setUseMessagePartners] = useState([]);
  const [socketInstance, setSocketInstance] = useState();
  const [triggerNew, setTrigerNew] = useState({count:0,status:false});
  const [isclickTicket, setisclickTicket] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const checkScreenSize = () => {
    setIsSmallScreen(() => window.innerWidth < 1000);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    let ckUser="";
    const fetchData = async () => {
      ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      ////console.log("get",ckUser);
      if (
        ckUser?.data &&
        ckUser?.data?.user &&
        ckUser?.data?.user?.user?.firstname !== "Guest" &&
        ckUser?.data?.user?.user
      ) {
        ////console.log("i ran in the inner")
        updateGlobalUser(ckUser?.data?.user);
      }
    };
    fetchData();
    
    const email = ckUser?.data?.user?.user?.email;

    const socket = io(BackSocket, {
      auth: {
        token: BackSocketToken,
      },
    });
    
    setSocketInstance(socket);
  }, []);
  ////console.log(basicOpen, window.innerWidth)
  /* if(!globalUser?.user?.email){
    return null
  } *///
   ////console.log(globalUser)
  if(!socketInstance){
    return null
  }

  return (
    <div >
    {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader 
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
      />

      <MDBContainer className="py-2 margin20down" >
        <section>
          <MDBCard>
            <MDBCardBody style={{ position: "relative", overflow: "hidden" }}>
              <MessageChatPartners
                setUseVisitor={setUseVisitor}
                visitor={useVisitor}
                setUSeChatIndex={setUSeChatIndex}
                setBasicOpen={setBasicOpen}
                basicOpen={basicOpen}
                UseMessagePartners={UseMessagePartners}
                setUseMessagePartners={setUseMessagePartners}
                socketInstance={socketInstance}
                currPartner={currPartner}
                triggerNew={triggerNew}
                setTrigerNew={setTrigerNew}
              />
              <RenderChats
                visitor={useVisitor}
                index={USeChatIndex}
                setBasicOpen={setBasicOpen}
                basicOpen={basicOpen}
                UseMessagePartners={UseMessagePartners}
                setUseMessagePartners={setUseMessagePartners}
                socketInstance={socketInstance}
                useChat={useChat}
                setUseChat={setUseChat}
                triggerNew={triggerNew}
                setTrigerNew={setTrigerNew}
              />
            </MDBCardBody>
          </MDBCard>
        </section>
      </MDBContainer>
      {!isSmallScreen &&<FooterAll />}
      <FooterNav />
    </div>
  );
};

export default MessageApp;
