import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/HeaderStyles.css";
import { ToastContainer, toast } from "react-toastify";
import { Themes } from "./indexPage";
import {
  Nav,
  Image,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Navbar,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as Icon } from "../images/user.svg";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import Spinner from "./Spinner";

function AdminHeader(props) {
  const [loading, setLoading] = useState(props.loading);
  const navigation = useNavigate();
  /* const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false); */

  //const [radioValue, setRadioValue] = useState(props?.radioValue);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [activeElement, setActiveElement] = useState(
    globalUser?.user?.states?.dataMode
  );
  ////console.log(globalUser, globalUser?.user?.states?.dataMode);
  const HandleLogout = () => {
    UpdateLocal({ action: "delete", data: { rmKeys: ["user"] } }).then(
      (result) => {
        updateGlobalUser("");
        navigation("/Welcome");
        toast.success("You are logged out");
      }
    );
  };

  const handleNavClick = (id) => {
    setActiveElement(id);
  };
  const handleDataModeChange = ({ val, state }) => {
    //setRadioValue(val);
    ////console.log("in index header", val);

    const themststus = val;
    const currentdata = globalUser;
    currentdata.user.dataMode = themststus;
    currentdata.user.states.dataMode = state;
    updateGlobalUser(currentdata);
    UpdateLocal({ action: "save", data: currentdata }).then((result) => {});
    ////console.log("saved from handle data mode", currentdata);
    props?.setRadioValue(val);
  };
  useEffect(() => {
    if (globalUser.user) {
      setLoading(false);
      setActiveElement(globalUser?.user?.states?.dataMode);
    }
  }, [globalUser?.user, props?.radioValue, props.loading]);

  /* const radios = [
    { name: "dark", value: "light" },
    { name: "light", value: "dark" },
  ]; */

  const UserLoggedIn = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Icon style={{ maxWidth: "10rem", maxHeight: "2rem" }} />

        <NavDropdown
          title={globalUser.user && globalUser.user.firstname}
          id="basic-nav-dropdown"
          className="align-items-center date-button custom-dropdown"
        >
          <NavDropdown.Item onClick={() => navigation("/userprofile")}>
            Profile
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() =>
              navigation("/userprofile", { state: { data: "Subscriptions" } })
            }
          >
            Supscription
          </NavDropdown.Item>
          <NavDropdown.Item onClick={HandleLogout}>Logout</NavDropdown.Item>
          <NavDropdown.Item>
            <Row>
              <Col>Data : </Col>
              <Col>
                <Nav>
                  {["high", "low"].map((item, index) => (
                    <Nav.Link
                      key={index}
                      className={`breadNav_light ${
                        globalUser && activeElement === index
                          ? "active"
                          : "dormant"
                      }`}
                      onClick={() => {
                        handleNavClick(index);
                        handleDataModeChange({ val: item, state: index });
                      }}
                    >
                      {item}
                    </Nav.Link>
                  ))}
                </Nav>
              </Col>
            </Row>
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  };

  const GuestLoginSignup = () => {
    return (
      <Nav>
        <Button className="nav_buttons">
          <Nav.Link
            className="nav_link_button"
            onClick={() => navigation("/admin")}
          >
            Login
          </Nav.Link>
        </Button>
      </Nav>
    );
  };

  const RightTabHeader = () => {
    return (
      <Navbar.Collapse className="justify-content-end">
        {globalUser.user && globalUser.user.firstname ? (
          globalUser.user.firstname === "Guest" ? ( // Use a nested ternary operator for conditional rendering
            <GuestLoginSignup />
          ) : (
            <UserLoggedIn /> // Add JSX elements to render in the "else" block
          )
        ) : (
          <GuestLoginSignup />
        )}
      </Navbar.Collapse>
    );
  };
  return (
    <div
      className="navbar_font "
      style={{ position: "sticky", top: 0, zIndex: 1000 }}
    >
      <Container>
        <Navbar expand="lg" sticky="top" className="m-auto">
          <Navbar>
            <Container>
              <Nav.Link>
                <Logo
                  alt="Bet Informatics"
                  style={{ maxWidth: "10rem", maxHeight: "2rem" }}
                />
              </Nav.Link>
            </Container>
          </Navbar>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => navigation("/users")}>
                <div style={{ fontSize: "18px" }}> Users</div>
              </Nav.Link>
              <Nav.Link onClick={() => navigation("/accounts")}>
                <div style={{ fontSize: "18px" }}>Accounts</div>
              </Nav.Link>
              <Nav.Link onClick={() => navigation("/expenses")}>
                <div style={{ fontSize: "18px" }}>Expense Log</div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {(globalUser.user && globalUser.user.page === "signup") ||
              (globalUser.user && globalUser.user.page === "signin") ? null : (
                <RightTabHeader />
              )}
            </>
          )}
        </Navbar>
        <ToastContainer position="bottom-left" />
      </Container>
    </div>
  );
}

export default AdminHeader;
