import React from "react";
import { useState, useEffect } from "react";
import { Row, Nav, Col } from "react-bootstrap";
import axios from "axios";

import Spinner from "../Spinner";
import DiaplayWinDrawLoose from "../TableComponents/LeagueStatsTableWinners";
import DiaplayOverUnder from "../TableComponents/LeagueStatsOverUnder";
import "../../css/ThemeCss.css";
import { Errorlog } from "../Utils";
import {
  CatchedStoraged,
  GetCatchedPages,
  SaveCatchedPages,
} from "../manageStorage/CatchedData";

const API_URL = process.env.REACT_APP_API_URL;

export default function LeagueStatisticsDisplay({
  league,
  teamA,
  teamB,
  small,
  mid,
}) {
  const [displayTypeToShow, setdisplayTypeToShow] = useState("Table");
  const [displayTypeToShowahw, setdisplayTypeToShowahw] = useState(0);
  const [displayTypeToShowftfhsf, setdisplayTypeToShowftfhsf] = useState(0);
  const [displayTypeToShowGH, setdisplayTypeToShowGH] = useState(0);

  const [loading, setLoading] = useState(true);
  const [useLeagueID, setUseLeagueID] = useState(league);
  const [data, setdata] = useState();
  const [statsToShow, setstatsToShow] = useState("Winners");
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [activeElementf, setActiveElementf] = useState(0);

  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1200 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  useEffect(() => {
    const getcatched = async () => {
      const pageData = await GetCatchedPages("LeagueStatisticsDisplay");
      //console.log(pageData, useLeagueID);
      if (typeof pageData === "string") {
        ("");
      } else if (typeof pageData === "object") {
        // I hope you didn't set it to 0 seconds

        if (useLeagueID === pageData?.leagueID) {
          //console.log("was here in catched");
          setdisplayTypeToShow(pageData?.setdisplayTypeToShow);
          setdisplayTypeToShowahw(pageData?.setdisplayTypeToShowahw);
          setdisplayTypeToShowftfhsf(pageData?.setdisplayTypeToShowftfhsf);
          setdisplayTypeToShowGH(pageData?.setdisplayTypeToShowGH);
          setstatsToShow(pageData?.setstatsToShow);
          setActiveElementf(pageData?.setActiveElementf);
        }
      }
    };
    getcatched();
  }, []);

  const SaveCatched = async (props) => {
    const Data2Save = {
      leagueID: useLeagueID,

      setdisplayTypeToShow: props?.setdisplayTypeToShow ?? displayTypeToShow,
      setdisplayTypeToShowahw:
        props?.setdisplayTypeToShowahw ?? displayTypeToShowahw,
      setdisplayTypeToShowftfhsf:
        props?.setdisplayTypeToShowftfhsf ?? displayTypeToShowftfhsf,
      setdisplayTypeToShowGH:
        props?.setdisplayTypeToShowGH ?? displayTypeToShowGH,
      setstatsToShow: props?.setstatsToShow ?? statsToShow,
      setActiveElementf: props?.setActiveElementf ?? activeElementf,
    };

    const pageData = await SaveCatchedPages(
      "LeagueStatisticsDisplay",
      Data2Save
    );
    //console.log(pageData, Data2Save);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    //setFixture(() => {});
    if (league !== null) {
      const get_lgdata = async () => {
        let res = {};
        let options = {
          body: JSON.stringify({
            league_id: useLeagueID,
            stat_type: displayTypeToShow,
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },

          seconds: 2 * 60 * 60,
        };
        let url = "/league_statistics";
        let indexdb = "LeagueStats";
        let SaveKey = "" + useLeagueID + displayTypeToShow;
        //console.log(options, SaveKey);
        try {
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          //console.log(res);
          /* const res = await axios.post(
            `${API_URL}/league_statistics`,
            JSON.stringify({
              league_id: useLeagueID,
              stat_type: displayTypeToShow,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = res;
          ////console.log(res);
          ////console.log("loaded fetchdata", fix_data?.status);
          if (res.status === "success") {
            setdata(JSON.parse(fix_data?.data));
            setLoading(false);
          } else {
            Errorlog(
              "loaderror",
              "error loading statistics",
              "from league stats"
            );
          }
        } catch (error) {
          Errorlog(error, "error loading statistics", "from league stats");
          ////console.log(error);
        }
      };
      get_lgdata();
    }
  }, [setUseLeagueID, setdisplayTypeToShow, statsToShow]);

  if (!data || loading) {
    return <Spinner />;
  }

  const handleNavClick = (displayType) => {
    setdisplayTypeToShow(displayType);
    SaveCatched({ setdisplayTypeToShow: displayType });
  };
  const handleNavClickahw = (displayType) => {
    setdisplayTypeToShowahw(displayType);
    SaveCatched({ setdisplayTypeToShowahw: displayType });
  };
  const handleNavClickftfhsf = (displayType) => {
    setdisplayTypeToShowftfhsf(displayType);
    SaveCatched({ setdisplayTypeToShowftfhsf: displayType });
  };
  const handleNavClickGH = (displayType) => {
    setdisplayTypeToShowGH(displayType);
    SaveCatched({ setdisplayTypeToShowGH: displayType });
  };
  function DisplayType({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          <div
            className={`breadNav_light_second_small sidepad ${
              displayTypeToShow === "Table"
                ? "div_active link forceblack"
                : "dormant link forceblack"
            }`}
            onClick={() => handleNavClick("Table")}
          >
            {isSmallScreen ? (
              <div className="tiny_font nowrap">{"Table"}</div>
            ) : (
              <>{"Table"}</>
            )}
          </div>
          <div
            className={`breadNav_light_second_small sidepad ${
              displayTypeToShow === "Chart"
                ? "div_active link forceblack"
                : "dormant link forceblack"
            }`}
            onClick={() => handleNavClick("Chart")}
          >
            {isSmallScreen ? (
              <div className="tiny_font nowrap">{"Chart"}</div>
            ) : (
              <>{"Chart"}</>
            )}
          </div>
        </div>
      </div>
    );
  }

  function FirstLayerSubLinks({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const PlayerLinks = [
      "Top ballers",
      "Top defenders",
      "Most cards recievers",
      "GK clean sheet",
      "Defender clean sheet",
    ];
    const StatLinks = [
      "Winners",
      "Lossers",
      "Tiers",
      "Goals",
      "Corners",
      "Cards",
    ];
    const FutureLinks = [
      "Potential winners",
      "Potential scorrers",
      "Potential Corner crackers",
      "Potential Card recievers",
    ];

    let iter_link = [];
    if (Links_ && Links_ === "PLAYERS") {
      iter_link = PlayerLinks;
    }
    if (Links_ && Links_ === "STATISTICS") {
      iter_link = StatLinks;
    }
    if (Links_ && Links_ === "PREDICTIONS") {
      iter_link = FutureLinks;
    }
    const handleNavClickf = (id) => {
      setActiveElementf(id);
      SaveCatched({ setActiveElementf: id });
    };
    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {iter_link.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                activeElementf === index
                  ? "div_active link forceblack"
                  : "dormant link forceblack"
              }`}
              onClick={() => {
                setstatsToShow(item);
                handleNavClickf(index);
                setdisplayTypeToShowahw(0);
                setdisplayTypeToShowftfhsf(0);
                setdisplayTypeToShow("Table");
                setdisplayTypeToShowGH(0);
                SaveCatched({
                  setdisplayTypeToShowahw: 0,
                  setdisplayTypeToShow: "Table",
                  setstatsToShow: item,
                  setdisplayTypeToShowftfhsf: 0,
                  setdisplayTypeToShowGH: 0,
                });
              }}
            >
              {isSmallScreen ? (
                <div className="tiny_font nowrap">{item}</div>
              ) : (
                <>
                  <div className=" nowrap">{item}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function AllHomeAway({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const ahw = ["All", "Home", "Away"];
    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {ahw.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                displayTypeToShowahw === index
                  ? "div_active link forceblack"
                  : "dormant link forceblack"
              }`}
              onClick={() => {
                handleNavClickahw(index);
              }}
            >
              {isSmallScreen ? (
                <div className="tiny_font nowrap">{item}</div>
              ) : (
                <>{item}</>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  function FhShFt({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const ahw = ["Full-Time", "First-Half", "Second-Half"];
    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {ahw.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                displayTypeToShowftfhsf === index
                  ? "div_active link forceblack"
                  : "dormant link forceblack"
              }`}
              onClick={() => {
                handleNavClickftfhsf(index);
              }}
            >
              {isSmallScreen ? (
                <div className="tiny_font nowrap">{item}</div>
              ) : (
                <>
                  <div className=" nowrap">{item}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  function GameHistory({ Links_ }) {
    //This handles the display of sublinks for different modal footer
    const ahw = ["All-Games", "16-Games", "11-Games", "6-Games"];
    return (
      <div className="mainFont margin-5down">
        <div className=" left_div center_sides nav_scroller noyscrol">
          {ahw.map((item, index) => (
            <div
              key={index}
              className={`breadNav_light_second_small sidepad ${
                displayTypeToShowGH === index
                  ? "div_active link forceblack"
                  : "dormant link forceblack"
              }`}
              onClick={() => {
                handleNavClickGH(index);
              }}
            >
              {isSmallScreen ? (
                <div className="tiny_font nowrap">{item}</div>
              ) : (
                <>{item}</>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function RouteStats({ Links_ }) {
    if (
      (Links_ && Links_ === "Winners") ||
      (Links_ && Links_ === "Lossers") ||
      (Links_ && Links_ === "Tiers")
    ) {
      ////console.log(Links_);
      return (
        <DiaplayWinDrawLoose
          data={data}
          aha={displayTypeToShowahw}
          distype={displayTypeToShow}
          half={displayTypeToShowftfhsf}
          history={displayTypeToShowGH}
          ranktype={Links_}
          teamA={teamA}
          teamB={teamB}
          small={small}
          mid={mid}
        />
      );
    }

    if (
      (Links_ && Links_ === "Goals") ||
      (Links_ && Links_ === "Corners") ||
      (Links_ && Links_ === "Cards")
    ) {
      return (
        <DiaplayOverUnder
          data={data}
          aha={displayTypeToShowahw}
          distype={displayTypeToShow}
          half={displayTypeToShowftfhsf}
          history={displayTypeToShowGH}
          ranktype={Links_}
          small={small}
          mid={mid}
        />
      );
    }
  }
  return (
    <div>
      <div>
        <Row>
          <Col>
            <FirstLayerSubLinks Links_={"STATISTICS"} />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col sm={6} xs={6} md={5} lg={4}>
            <AllHomeAway />
          </Col>
          <Col sm={6} xs={6} md={7} lg={8}>
            <DisplayType />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col sm={6} xs={6} md={5} lg={4}>
            <FhShFt />
          </Col>
          <Col sm={6} xs={6} md={7} lg={8}>
            <GameHistory />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col>
            <RouteStats Links_={statsToShow} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
