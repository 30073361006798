import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import HomeDisplay from "./TeampastMatches_Home";
import AwayDisplay from "./TeampastMatches_Away";
import Team_image from "../../images/team_images.json";

import "../../css/Teamstats.css";
import { Errorlog } from "../Utils";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
function convertUnixToTime(unixTime, type) {
  // Create a new Date object using the unixTime (in milliseconds)
  const date = new Date(unixTime * 1000);

  const options = {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const options2 = {
    month: "2-digit",
    day: "2-digit",
  };
  const options3 = {
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  if (type === "short") {
    return date.toLocaleDateString("en-US", options2);
  } else if (type === "time") {
    return date.toLocaleTimeString("en-US", options3);
  } else if (type === "") {
    return formattedDate;
  }
}
function DisplaySummary({ small, mid, team }) {
  const { globalUser } = useContext(GlobalUserContext);
  const [monthCount, setMonthCount] = useState({ ls: 2, sc: 2 });
  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [useteam, setTeam] = useState(team);

  ////console.log(team)
  useEffect(() => {
    const get_tmdata = async () => {

      let options = {
        body: JSON.stringify({
          team: useteam,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60 * 60,
        },

        seconds: 2 * 60 * 60,
      };
      let url = "/team_summary";
      let indexdb = "team_summary";
      let SaveKey = useteam
      try {

        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/team_summary`,
          JSON.stringify({
            team: useteam,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        ////console.log(res)
        const firstDict = res;
        if (firstDict?.status === "success" && firstDict.data) {
          ////console.log("first dict", firstDict?.data);
          setData(firstDict.data);
          setLoading(false);
        } else {
          throw new Error("Unsuccessful request");
        }
      } catch (error) {
        ////console.log(error)
        Errorlog(error, "error loading statistics", "from team stats");
      }
    };
    get_tmdata();
  }, [small]);

  if (!Data || loading) {
    <Spinner />;
  }
  ////console.log(Data);
  function MultiLeague({ Data_, date_, table, small, mid }) {
    const navigation = useNavigate();
    ////console.log(Data_);
    const DisplayData = ({ Indata }) => {
      const wdl = Indata?.win_tab;
      const WinnerButton = ({ button_, text_ }) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: button_,
                padding: "3px 7px",
                fontSize: "11px",
              }}
            >
              {text_}
            </Button>
          </div>
        );
      };
      
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };
      const getBorder = (field) => {
        if (wdl?.outcome === "loss" && field === "loss") {
          return "solid 2px";
        } else if (wdl?.outcome === "draw" && field === "draw") {
          return "solid 2px";
        } else if (wdl?.outcome === "win" && field === "win") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const handlematchlick = (match_id) => {
        navigation("/match/" + match_id, { state: match_id });
      };
      return (
        <>
          <td className="dates">
            {small
              ? convertUnixToTime(Indata && Indata["date_unix"], "short")
              : convertUnixToTime(Indata && Indata["date_unix"], "")}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </>
          )}

          <td
            className="homeOR link "
            onClick={() =>
              handleteamclick({
                val: Indata?.["homeID"],
                team_: Indata?.["home_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            <div className="right_content center_straight usemaxSpace">
              <div
                className="center_straight center_sides"
                style={{ maxWidth: window.innerWidth < 770 ? "90px" : "" }}
              >
                <div
                  className="noBorders"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  {Indata?.["home_name"]} &nbsp;
                </div>
                <div>
                  <img
                    src={Team_image?.[String(Indata?.["homeID"])]?.["image"]}
                    width="20"
                    height="15"
                    alt={Indata?.["home_name"]}
                  />
                </div>
              </div>
            </div>
          </td>

          {Indata?.["status"] === "complete" ? (
            <td
              className="statsOR link"
              onClick={() => handlematchlick(Indata?.["id"])}
            >
              {Indata?.["home_goals"]} : {Indata?.["away_goals"]}
            </td>
          ) : (
            <td
              className="statsOR link"
              onClick={() => handlematchlick(Indata?.["id"])}
            >
              {" "}
              -:-
            </td>
          )}
          <td
            className="awayOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["awayID"],
                team_: Indata?.["away_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            <div
              className="center_straight center_sides"
              style={{ maxWidth: window.innerWidth < 770 ? "90px" : "" }}
            >
              <div>
                <img
                  src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                  width="20"
                  height="15"
                  alt={Indata?.["away_name"]}
                />
              </div>

              <div
                className="noBorders"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                  width: "100%",
                  overflowWrap: "break-word",
                }}
              >
                &nbsp;
                {Indata?.["away_name"]}{" "}
              </div>
            </div>
          </td>
          {small ? (
            <>
              <td className="link">
                <IoIosArrowDropdownCircle size={20} color="gray" />
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td></td>

              <td className="preds">
                <div
                  style={{
                    display: "flex",
                    width: "35px",
                    height: "20px",
                    background: wdl?.["win_colo"],
                    alignItems: "center",
                    justifyContent: "center",
                    border: getBorder("win"),
                  }}
                >
                  {wdl?.["win%"] ? wdl?.["win%"] : "- %"}
                </div>
              </td>
              <td className="preds">
                <div
                  style={{
                    display: "flex",
                    width: "30px",
                    height: "20px",
                    background: wdl?.["draw_colo"],
                    alignItems: "center",
                    justifyContent: "center",
                    border: getBorder("draw"),
                  }}
                >
                  {wdl?.["draw%"] ? wdl?.["draw%"] : "- %"}
                </div>
              </td>
              <td className="preds">
                <div
                  style={{
                    display: "flex",
                    width: "30px",
                    height: "20px",
                    background: wdl?.["loss_colo"],
                    alignItems: "center",
                    justifyContent: "center",
                    border: getBorder("loss"),
                  }}
                >
                  {wdl?.["loss%"] ? wdl?.["loss%"] : "- %"}
                </div>
              </td>
              <td></td>
              <td></td>

              <td className="preds">
                <WinnerButton
                  button_={Indata?.["but_color"]}
                  text_={Indata?.["butt_text"]}
                />
              </td>
            </>
          )}
        </>
      );
    };

    const DisplayDrop = ({ Indata }) => {
      ////console.log(data);
      const wdl = Indata?.win_tab;
      const WinnerButton = ({ button_, text_ }) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: button_,
                padding: "3px 7px",
                fontSize: "11px",
              }}
            >
              {text_}
            </Button>
          </div>
        );
      };
      ////console.log(homeTeam);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };
      const getBorder = (field) => {
        if (wdl?.outcome === "loss" && field === "loss") {
          return "solid 2px";
        } else if (wdl?.outcome === "draw" && field === "draw") {
          return "solid 2px";
        } else if (wdl?.outcome === "win" && field === "win") {
          return "solid 2px";
        } else {
          return "";
        }
      };
      const handlematchlick = (match_id) => {
        navigation("/match/" + match_id, { state: match_id });
      };
      return (
        <div>
          <div className="center_div_distributed "></div>

          <div className="center_div_distributed margin-30down">
            <div>
              <div>Time</div>
              <div>
                {convertUnixToTime(Indata && Indata["date_unix"], "time")}
              </div>
            </div>
            <div>
              <div>Win%</div>
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background: wdl?.["win_colo"],
                  alignItems: "center",
                  justifyContent: "center",
                  border: getBorder("win"),
                }}
              >
                {wdl?.["win%"] ? wdl?.["win%"] : "- %"}
              </div>
            </div>
            <div>
              <div>Draw%</div>
              <div
                style={{
                  display: "flex",
                  width: "30px",
                  height: "20px",
                  background: wdl?.["draw_colo"],
                  alignItems: "center",
                  justifyContent: "center",
                  border: getBorder("draw"),
                }}
              >
                {wdl?.["draw%"] ? wdl?.["draw%"] : "- %"}
              </div>
            </div>
            <div>
              <div>Loss%</div>
              <div
                style={{
                  display: "flex",
                  width: "30px",
                  height: "20px",
                  background: wdl?.["loss_colo"],
                  alignItems: "center",
                  justifyContent: "center",
                  border: getBorder("loss"),
                }}
              >
                {wdl?.["loss%"] ? wdl?.["loss%"] : "- %"}
              </div>
            </div>
            <div className="center_div center_straight">
              <div>Result</div>
              <div>
                <WinnerButton
                  button_={Indata?.["but_color"]}
                  text_={Indata?.["butt_text"]}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const MLines = ({ Lines }) => {
      const [isdropdownClick, setisdropdownClick] = useState(false);

      const handleDropclick = (index) => {
        // if the row was already open, close it
        if (isdropdownClick === index) {
          setisdropdownClick(null);
        } else {
          // otherwise, open the clicked row
          setisdropdownClick(index);
        }
      };
      if (!Array.isArray(Lines)) {
        Lines = "shit";
      }
      return (
        <div>
          <Table
            className="outer-border-light"
            striped
            hover
            size={small || mid || window.innerWidth < 1400 ? "sm" : 8}
            variant="light"
          >
            <thead>
              <tr>
                <th>Date</th>
                {small ? null : (
                  <>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </>
                )}
                <th className="homeOR">Home</th>
                <th className="statsOR">Stats</th>{" "}
                <th className="awayOR">Away</th>
                {small ? (
                  <th>Optns</th>
                ) : (
                  <>
                    <th></th>
                    <th></th>
                    <th className="preds">Win%</th>{" "}
                    <th className="preds">Draw%</th>{" "}
                    <th className="preds">Loss%</th>
                    <th></th>
                    <th></th>
                    <th className="preds">Result</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Lines === "shit" ? (
                <tr>
                  <td>No data to show</td>{" "}
                </tr>
              ) : (
                Lines.reverse().map((item, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => handleDropclick(index)}>
                      <DisplayData Indata={item} />
                    </tr>
                    {isdropdownClick === index ? (
                      <tr colSpan="100%">
                        <td colSpan="100%">
                          <DisplayDrop Indata={item} />
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        </div>
      );
    };
    //const Ob_Data_ = Object.keys(Data_)[0];
    ////console.log(Data_);
    return (
      <div>
        {small || mid ? (
          <div>
            {table === "next_game" ? (
              <div>
                <div className="tableheader">
                  <DisplayLeague lg={Data_} date={"single"} />
                </div>

                <Modal.Body>
                  <MLines Lines={Data_} />
                </Modal.Body>
              </div>
            ) : (
              Object.keys(Data_).map((item, index) => (
                <div key={item}>
                  <div className="tableheader">
                    <DisplayLeague lg={Data_[item]} date={date_} />
                  </div>
                  <Modal.Body>
                    <MLines Lines={Data_[item]} />
                  </Modal.Body>
                </div>
              ))
            )}
          </div>
        ) : (
          <Container>
            {table === "next_game" ? (
              <div>
                <div className="tableheader">
                  <DisplayLeague lg={Data_} date={"single"} />
                </div>

                <Modal.Body>
                  <MLines Lines={Data_} />
                </Modal.Body>
              </div>
            ) : (
              Object.keys(Data_).map((item, index) => (
                <div key={item}>
                  <div className="tableheader">
                    <DisplayLeague lg={Data_[item]} date={date_} />
                  </div>
                  <Modal.Body>
                    <MLines Lines={Data_[item]} />
                  </Modal.Body>
                </div>
              ))
            )}
          </Container>
        )}
      </div>
    );
  }
  function DisplayLeague({ lg, date }) {
    function formatMonthYear(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const [year, month] = dateString.split(".");
      const monthInWords = months[Number(month) - 1];

      return `${monthInWords}, ${year}`;
    }
    ////console.log(lg);
    return (
      <div>
        {date && date === "single" ? (
          <div className="allpad tableFontSize mainFont">
            <Row className="center_div">
              <Col md={2} sm={2} xs={2} lg={2}>
                <img
                  src={lg?.[0]?.lg_image}
                  width="30"
                  height="25"
                  alt={lg?.[0]?.league_name}
                />
              </Col>
              <Col md={10} sm={10} xs={10} lg={10} className="left_allign">
                <div>{lg?.[0]?.league_name} </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="allpad tableFontSize mainFont">
            <Row className="center_div">
              <Col md={1} sm={2} xs={3} lg={1}>
                <img
                  src={lg?.[0]?.["lg_image"]}
                  width="30"
                  height="25"
                  alt={lg?.[0]?.["league_name"]}
                />
              </Col>
              <Col md={2} sm={3} xs={4} lg={2} className="left_allign">
                {lg?.[0]?.["league_name"]}{" "}
              </Col>

              <Col md={9} sm={7} xs={5} lg={9} className="left_allign">
                <div className="usemaxSpace">{formatMonthYear(date)}</div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }

  function Matches({ monthCount, matches, table, text, owner }) {
    return (
      <div>
        <Container className="d-flex justify-content-center align-items-center ">
          <h5 className="summary_title">{text}</h5>
        </Container>
        <Modal.Dialog className="rounded-3">
          {table === "next_game" ? (
            <div key={Math.random()}>
              <MultiLeague
                Data_={matches}
                table={"next_game"}
                small={small}
                mid={mid}
              />
            </div>
          ) : table === "future_games" ? (
            matches &&
            Object.keys(matches)
              .sort()
              .map((item, index) => {
                if (index < monthCount?.[owner]) {
                  return (
                    <div key={index}>
                      <MultiLeague
                        Data_={matches?.[item]}
                        date_={item}
                        table={"not"}
                        small={small}
                        mid={mid}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })
          ) : (
            matches &&
            Object.keys(matches)
              .sort()
              .reverse()
              .map((item, index) => {
                if (index < monthCount?.[owner]) {
                  return (
                    <div key={index}>
                      <MultiLeague
                        Data_={matches[item]}
                        date_={item}
                        table={"not"}
                        small={small}
                        mid={mid}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })
          )}
        </Modal.Dialog>
      </div>
    );
  }
  const HandleSeeMore = (val, owner) => {
    //let currValue = monthCount;
    const newval = { ...monthCount };
    if (val === "seemore" && monthCount?.[owner] > 1) {
      newval[owner] = newval?.[owner] + 1;
      setMonthCount(newval);
    }
    if (val === "seeless" && monthCount?.[owner] > 2) {
      newval[owner] = newval?.[owner] - 1;
      setMonthCount(newval);
    }
  };
  function SeeMore({ owner }) {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        {small || mid ? (
          <div>
            <Nav style={{ display: "flex", justifyContent: "space-between" }}>
              <NavLink onClick={() => HandleSeeMore("seeless", owner)}>
                <h6>{"< See Less"}</h6>
              </NavLink>
              <NavLink onClick={() => HandleSeeMore("seemore", owner)}>
                <h6>{"See More >"}</h6>
              </NavLink>
            </Nav>
          </div>
        ) : (
          <Container className="justify-center">
            <Nav style={{ display: "flex", justifyContent: "space-between" }}>
              <NavLink onClick={() => HandleSeeMore("seeless", owner)}>
                <h6>{"< See Less"}</h6>
              </NavLink>
              <NavLink onClick={() => HandleSeeMore("seemore", owner)}>
                <h6>{"See More >"}</h6>
              </NavLink>
            </Nav>
          </Container>
        )}
      </div>
    );
  }
  const matches = Data?.summary;
  ////console.log(matches);
  return (
    <div>
      {small || mid ? (
        <div>
          <Matches
            monthCount={monthCount}
            matches={matches?.next_game}
            table={"next_game"}
            text={"Next Match"}
          />
          <Matches
            monthCount={monthCount}
            table={"All"}
            matches={matches?.All}
            text={"Latest Scores"}
            owner={"ls"}
          />
          <SeeMore owner={"ls"} />
          <Matches
            monthCount={monthCount}
            table={"future_games"}
            matches={matches?.future_games}
            text={"Scheduled"}
            owner={"sc"}
          />
          <SeeMore owner={"sc"} />
        </div>
      ) : (
        <Container>
          <Matches
            monthCount={monthCount}
            matches={matches?.next_game}
            table={"next_game"}
            text={"Next Match"}
          />
          <Matches
            monthCount={monthCount}
            table={"All"}
            matches={matches?.All}
            text={"Latest Scores"}
            owner={"ls"}
          />
          <SeeMore owner={"ls"} />
          <Matches
            monthCount={monthCount}
            table={"future_games"}
            matches={matches?.future_games}
            text={"Scheduled"}
            owner={"sc"}
          />
          <SeeMore owner={"sc"} />
        </Container>
      )}
    </div>
  );
}
export default DisplaySummary;
