import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import IndexHeader from "./IndexHeaders";
import axios from "axios";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import FooterAll from "./Footer";
import "../css/ThemeCss.css";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
export default function ThankYou() {
  const navigation = useNavigate();
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  return (
    <div
      style={{
        background: "white",
        fontFamily: "serif",
      }}
    >
      <IndexHeader />

      <Container className="appear_center mainFont">
        <div className="center_div flex_column">
          <div className="bold midfont">Thank you for your Business</div>
          <div
            className="link smallbuton  divgreenButton margin30down"
            onClick={() => navigation("/")}
          >
            Go to Home
          </div>
        </div>
      </Container>
    </div>
  );
}
