import { useState, useEffect, useContext } from "react";
//import React from "react-bootstrap";
import IndexSubheads from "./IndexSubheader";
import { format, addDays, subDays } from "date-fns";
import axios from "axios";
import IndexHeader from "./IndexHeaders";
import { GlobalUserContext } from "./contextRouters/UserInfoContext";
import { UpdateLocal } from "./manageStorage/MaintainLocal";
import { useNavigate, useLocation } from "react-router-dom";
import FooterAll from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import GetFixtures from "./sportComponents/DayFixtures";
import "../css/index.css";
import "../css/ThemeCss.css";
import { useGeolocated } from "react-geolocated";
import FooterNav from "./SmallScreenNav";
import { SideNavPage } from "./Utils";
import { GetCatchedPages, SaveCatchedPages } from "./manageStorage/CatchedData";
import PlayYouTube from "./VideoPlayer/YouTubePlayer";
const API_URL = process.env.REACT_APP_API_URL;
const google_login = process.env.REACT_APP_GOOGLE_LOGIN;
export const Themes = {
  light: {
    background: "light",
    outerTableboarder: "outer-border-light",
    stripeTable: "striped-table-light",
    h6: "_h6-light",
  },
  dark: {
    background: "dark",
    outerTableboarder: "outer-border-dark",
    stripeTable: "striped-table-dark",
    h6: "_h6-dark",
  },
};
export const dataMode = ["all", "current"];
export const Location = () => {
  const [country, setCountry] = useState("Nigeria");
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const longitude = coords.longitude;
      const latitude = coords.latitude;
      if (!globalUser?.user?.curr_country) {
        getCountry(latitude, longitude).then((country) => {
          ////console.log(country);
          setCountry(country);
        });
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]); // added dependencies to make sure effect runs whenever these values change

  if (!country) {
    return null;
  }

  ////console.log(country);
  return country; // used {} to ensure country state value is displayed, not the string 'country'
};

const getCountry = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://api-bdc.net/data/reverse-geocode?latitude=${latitude}&longitude=${longitude}&localityLanguage=en&key=${REACT_APP_BIGDATA}`
    );

    return response?.data?.countryName;
  } catch (error) {
    //console.error(error);
  }
};

function IndexPage(props) {
  const [loading, setLoading] = useState(true);
  const [checkModal, setCheckModal] = useState(false);
  const [fixDate, setFixDate] = useState(null);
  const location = useLocation();
  const userinfo = location.state?.userinfo || {};
  const [currentPage, setCurrentPage] = useState("index");
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const navigation = useNavigate();
  const [curr_location, setcurr_location] = useState("unknown");
  const [isclickTicket, setisclickTicket] = useState(false);
  const [userInfo, setUserInfo] = useState(userinfo);
  const [isOpen, setIsOpen] = useState(false);
  const [islogedInGoogle,setIslogedInGoogle] = useState(false)
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);

  useEffect(() => {
    const getcatched = async () => {
      const pageData = await GetCatchedPages("indexPage");
      if (typeof pageData === "string") {
        ("");
      } else if (typeof pageData === "object") {
        // I hope you didn't set it to 0 seconds
        //console.log("git GetFixtures", pageData)
        setFixDate(pageData?.setFixDate);
      }
      
          
      
    };
    getcatched();
  }, []);

  const SaveCatched = async (props) => {
    const Data2Save = {
      setFixDate: props?.setFixDate,
    };

    const pageData = await SaveCatchedPages(
      "indexPage",
      Data2Save
    );
    //console.log(pageData, Data2Save);
  };
  const user = {
    name: "user",
    firstname: "Guest",
    page: "indexPage",
    theme: Themes?.light,
    dataMode: "low",
    states: {
      theme: { radioValue },
      dataMode: 0,
    },
  };
  const neededInfo = [
    "firstname",
    "lastname",
    "phonenumber",
    "email",
    "address1",
    "address2",
    "dateofbirth",
    "country",
    "creationDate",
    "updatedDate",
    "subscription",
    "background",
    "tipstarDetails",
    "profile",
    "publicProfile",
    "verifyEmail",
    "isadmin",
    "city",
    "auth",
    "Tickets",
    "state",
    "policy",
    "Terms",
    "zip",
    "dataMode",
  ];
  const tempInfo = {};

  neededInfo.forEach((key) => {
    if (userinfo?.firstname) {
      if (userinfo.hasOwnProperty(key)) {
        tempInfo[key] = userinfo[key];
      }
    } else {
      if (user.hasOwnProperty(key)) {
        tempInfo[key] = user[key];
      }
    }
  });
  tempInfo["name"] = "user";
  tempInfo["page"] = "index";
  tempInfo["theme"] = Themes?.light;
  //tempInfo["currCountry"] = Demo()
  const userData = { user: tempInfo };

  useEffect(() => {
    
    const fetchData = async () => {
      let ckUser;
      if (Object.keys(userinfo).length === 0) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log("get");
      } else if (
        Object.keys(globalUser).length === 0 &&
        Object.keys(userinfo).length !== 0
      ) {
        ////console.log("get since global is zero and userinfo is not");
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log(ckUser);
        if (
          ckUser?.data &&
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.firstname !== "Guest" &&
          ckUser?.data?.user?.user
        ) {
          ("do nothing");
          ////console.log("i did nothing in duel");
        } else {
          ////console.log("from local is guest using from login");
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
          setIsLogedin(true);
          if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "inactive"
          ) {
            ////console.log("inactive");
            setIsSubscribed(false);
          } else if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "active"
          ) {
            setIsSubscribed(true);
            ////console.log("active");
          } else {
            setIsSubscribed(false);
            ////console.log("nosub");
          }
        }
      } else {
        ////console.log("save");
        if (
          globalUser &&
          globalUser?.user &&
          globalUser?.user?.firstname !== "Guest"
        ) {
          ("do nothing");
          ////console.log("did nothing");
        } else {
          ////console.log("somehow i skipped this,", globalUser);
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
        }

        setIsLogedin(true);
        if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "inactive"
        ) {
          ////console.log("inactive");
          setIsSubscribed(false);
        } else if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
          ////console.log("active");
        } else {
          setIsSubscribed(false);
          ////console.log("nosub");
        }
      }

      ////console.log(userData);
      if (
        (ckUser && ckUser.result === "saved") ||
        (ckUser && ckUser.result === "retrieved" && ckUser.data !== undefined)
      ) {
        if (ckUser && ckUser.result === "saved") {
          //userData["user"]["currCountry"] = curr_location;
          updateGlobalUser(userData);
          ////console.log("wwwasss here in saved to global with user data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "index",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          setUserInfo(userData);
        } else if (ckUser && ckUser.data && ckUser.data.user) {
          //ckUser["data"]["user"]["user"]["currCountry"] = curr_location;

          // *** update login and subs *****
          if (
            (ckUser.data.user &&
              ckUser.data.user?.user?.firstname === "Guest" &&
              ckUser.data.user?.user) ||
            (ckUser.data.user && Object.keys(ckUser.data.user).length === 0)
          ) {
            setIsLogedin(false);
            setIsSubscribed(false);
          } else if (
            ckUser.data.user &&
            ckUser.data.user?.user?.firstname !== "Guest"
          ) {
            if (
              ckUser.data.user &&
              !ckUser.data.user?.user?.subscription &&
              ckUser.data.user?.user
            ) {
              ////console.log(ckUser.data.user);
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              if (
                ckUser.data.user &&
                ckUser.data.user?.user?.subscription?.status === "inactive"
              ) {
                setIsLogedin(true);
                setIsSubscribed(false);
              } else {
                setIsLogedin(true);
                setIsSubscribed(true);
              }
            }
          }
          // *** End update login and subs *****
          updateGlobalUser(ckUser.data.user);
          ////console.log("wwwasss here in saved to global with chuser data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "index",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          userData["user"]["currCountry"] = curr_location;
          setUserInfo(userData);
        } else {
          toast.error("An error occured");
        }
      } else {
        userData["user"]["currCountry"] = curr_location;
        ////console.log(" saved to global user with user data logged out");

        updateGlobalUser(userData);
        setUserInfo(userData);
        UpdateLocal({ action: "save", data: userData });
        //toast.info("You are logged out");
      }
    };

    fetchData();
    if (globalUser && Object.keys(globalUser).length === 0) {
      return;
    }
    ////console.log("save");
    if (
      (globalUser &&
        globalUser?.user?.firstname === "Guest" &&
        globalUser?.user) ||
      (globalUser && Object.keys(globalUser).length === 0)
    ) {
      setIsLogedin(false);
      setIsSubscribed(false);
    } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
      if (globalUser && !globalUser?.user?.subscription && globalUser?.user) {
        ////console.log(globalUser);
        setIsLogedin(true);
        setIsSubscribed(false);
      } else {
        if (
          globalUser &&
          globalUser?.user?.subscription?.status === "inactive"
        ) {
          setIsLogedin(true);
          setIsSubscribed(false);
        } else {
          setIsLogedin(true);
          setIsSubscribed(true);
        }
      }
    }
  }, [curr_location,islogedInGoogle]);

  const SetLoader = (valu) => {
    setLoading(valu);
  };
  ////console.log("indexheader child global user changed", globalUser);

  function getNextDay(date, day) {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + day);
    return nextDay;
  }
  const days_name = [
    "block1",
    "block2",
    "block3",
    "block4",
    "block5",
    "block6",
    "block7",
    "block8",
  ];
  
  const conv_date = (date_var) => {
    // Extract the year, month, and day from the date object
    var year = date_var.getFullYear();
    var month = ("0" + (date_var.getMonth() + 1)).slice(-2); // Add leading zero for single-digit months
    var day = ("0" + date_var.getDate()).slice(-2); // Add leading zero for single-digit days

    // Format the date as yyyy-mm-dd
    const formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  };
  const updateGlobal_local = (user) => {
    updateGlobalUser(user);
  };
  ////console.log(globalUser)
  useEffect(() => {
    const eventDate = new Date();
    const fix_curr = {
      day: format(eventDate, "yyyy-MM-dd"),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if(!fixDate){
      //console.log("i rewrote fix data")
      setFixDate(fix_curr);
      SaveCatched({setFixDate:fix_curr})
    }
  
    
  }, []);

  const setDatePost = (data) => {
    //console.log("i rewrote fix data")
    setFixDate(data);
    SaveCatched({setFixDate:data})
  };

  if (!fixDate || !globalUser) {
    return null;
  }

  if (checkModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  
  ////console.log("from indx", globalUser);
  //console.log(isOpen);
  return (
    <div className="light">
       
       
              

      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        setIsLogedin={setIsLogedin}
        isLogedin={isLogedin}
        issubscribed={issubscribed}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        setIsSubscribed={setIsSubscribed}
        setCheckModal={setCheckModal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen} />}
      <GetFixtures
        setDatePost={setDatePost}
        fixData={fixDate}
        setLoader={SetLoader}
        loading={loading}
        isLogedin={isLogedin}
        isclickTicket={isclickTicket}
        issubscribed={issubscribed}
        islogedInGoogle={islogedInGoogle}
      />
      
      <FooterAll page={currentPage} setIslogedInGoogle={setIslogedInGoogle}/>
      <FooterNav setIslogedInGoogle={setIslogedInGoogle}/>
    </div>
  );
}
export default IndexPage;
