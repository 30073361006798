import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import care from "../../images/unisex.jpg";
import {
  convertUnixToTime,
  timeDifference,
  timestampDifference,
} from "../Utils";
import axios from "axios";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import DicplayInnerComments from "./DisplayInnerComments";
import { io } from "socket.io-client";
import { FcLike } from "react-icons/fc";
const API_URL = process.env.REACT_APP_API_URL;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;
export default function DicplayInnerTopComments(props) {
  const [useCommentTop, setUseCommentTop] = useState();
  const [authorBackground, setUserBackground] = useState();
  const [reloadPResent, setReladPresent] = useState("true");
  const [newUSerMain, setNewUser]= useState()
  const [useliked,setLiked] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      let res;
      ////console.log(queryData);
      if (!props?.commentId || !props?.collection || !props?.db) {
        ("");
      } else {
        let querryData = {
          db: props?.db,
          object: props?.collection,
          _id: props?.commentId,
        };
        try {
          res = await axios
            .post(`${API_URL}/getComments`, JSON.stringify(querryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      }
      if (res?.data?.status === "success") {
        setUseCommentTop(() => res?.data?.data);
      }
    };
    fetchData();
  }, [props?.targetObject, props?.commentId, props?.reloadChat, reloadPResent]);
  useEffect(() => {
    ////console.log(globalUser?.user?.email, StorageBucket);
    async function fetchBackground() {
      try {
        const response = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${useCommentTop?.data?.ownerDetails?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob to a string
        setUserBackground(base64data);
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [useCommentTop?.data?.ownerDetails]);
  const handleReplyClick = () => {
    let res = { name: "godwin", email: "test@gmail.com" };
    props?.setCommentType((prev) => {
      if (prev === "reply") {
        props?.setReplyContent(props?.originalOwner);
        return "comment";
      } else {
        props?.setReplyContent({
          name: useCommentTop?.data?.ownerDetails?.name,
          email: useCommentTop?.data?.ownerDetails?.email,
          _id: useCommentTop?._id,
        });
        return "reply";
      }
    });
  };
  const fetchData = async () => {
    let ckUser;
    if (!props?.userData?.user?.email) {
      ckUser = await UpdateLocal({
        action: "get",
        data: { getKeys: ["user"] },
      });
      setNewUser(ckUser?.data?.user)
      return ckUser?.data?.user;
    }
    return;
  };
  const handleLikeClick = async (item) => {
    //console.log("clicked liked");
    let newUser = await fetchData();
    let newSocket;
    if (useCommentTop && item === props?.index && props?.socketInstance) {
      
      if (props?.socketInstance) {
        props?.socketInstance.emit("likeComments", {
          data: useCommentTop,
          liker: props?.userData?.user?.email ?? newUser?.user.email,
        });
      } else {
        newSocket.emit("likeComments", {
          data: useCommentTop,
          liker: props?.userData?.user?.email ?? newUser?.user.email,
        });
      }

      setUseCommentTop((prev) => {
        //console.log("updated");
        prev["data"]["likes"].push(props?.userData?.user?.email ?? newUser?.user.email);

        return prev;
      });
      setReladPresent((prev) => {
        if (prev === "true") {
          return "false";
        } else {
          return "true";
        }
      });
    }
    newSocket?.disconnect();
    
  };

  let querryData = {
    db: props?.targetDb,
    object: props?.targetObject,
  };
  //console.log(useCommentTop);

  if (!props?.commentId || !props?.collection || !props?.db) {
    return;
  }
  //console.log(props?.userData);
  return (
    <div key={props?.index}>
      <div key={""} className="d-flex justify-content-start w-100 margin10down">
        <img
          src={authorBackground ?? care}
          className="rounded-circle me-2"
          style={{ width: "30px", height: "30px" }}
          alt={props?.visitor?.name}
        />
        <div style={{ minWidth: "200px" }}>
          <div className="left_div">
            <p
              className="p-1 bg-light  shadow-1 shadow-1 rounded_div"
              style={{ maxWidth: "533px" }}
            >
              <small className="left_div link tiny_font">
                {useCommentTop?.data?.ownerDetails?.name ?? "spectator"}
              </small>

              <small
                className=" float text-dark mt-4"
                dangerouslySetInnerHTML={{
                  __html: useCommentTop?.data?.message,
                }}
              ></small>
            </p>
          </div>
          <div className=" tiny_font removespave-15top  center_sides">
            <div className="left_div">
              <div className="">
                {timestampDifference(
                  useCommentTop?.updated_at,
                  Date.now() / 1000
                )}
              </div>
              &nbsp;&nbsp;&nbsp;
              {useCommentTop?.data?.likes?.includes(props?.userData?.user?.email) || useliked ? <>
              <div className="link"><FcLike /></div>
            
              </> : <div
                className="link"
                onClick={() => {handleLikeClick(props?.index);setLiked(true)}}
              >
                Like
              </div>}
              &nbsp;&nbsp;&nbsp;
              <div className="link bold" onClick={() => handleReplyClick()}>
                Reply
              </div>
            </div>
            <div className="right_div_main removespave-20top">
              <div className="">
                {useCommentTop?.data?.likes?.length > 0 ? (
                  <div className="">
                    {useCommentTop?.data?.likes?.length}{" "}
                    {useCommentTop?.data?.likes?.length > 1
                      ? " likes"
                      : " like"}
                  </div>
                ) : null}
              </div>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
      {useCommentTop?.data?.chilren?.length > 0 ? (
        <>
          <div className="marginleft-30">
            <DicplayInnerComments
              db={props?.db}
              collection={props?.collection}
              commentId={
                useCommentTop?.data?.chilren?.sort(
                  (a, b) => b?.time - a?.time
                )[0]?.comment_id
              }
              index={props?.index}
              rcomments={useCommentTop?.data?.chilren?.length}
              mainData={useCommentTop}
              userData={props?.userData ?? newUSerMain}
              socketInstance={props?.socketInstance ?? newUser?.user.email}
              setCommentType={props?.setCommentType}
              setReplyContent={props?.setReplyContent}
              originalOwner={props?.originalOwner}
              reloadChat={props?.reloadChat}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
