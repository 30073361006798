import React, { useState, useEffect, useRef, useContext } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBContainer,
  MDBInput,
} from "mdb-react-ui-kit";
import IndexHeader from "../IndexHeaders";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import care from "../../images/unisex.jpg";
import EmojiPicker from "emoji-picker-react";
import { BsSend } from "react-icons/bs";
import { Errorlog, convertUnixToTime } from "../Utils";
import { GoDotFill } from "react-icons/go";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;

export default function MessageChatPartners(props) {
  const currTime = Math.floor(new Date().getTime() / 1000);
  ////console.log(currTime)
  /* const messagePatners = [
    {
      time: currTime + 1,
      name: "Anna Doe",
      email: "ezeanigodwinndubuisi@gmail.com",
    },
    {
      time: currTime + 2,
      name: "Alan ,Turing",
      email: "generous456@gmail.com",
    },
    {
      time: currTime + 3,
      name: "Veronica Smith",
      email: "friend@gmail.com",
    },
    {
      time: currTime + 4,
      name: "Tom Johnson",
      email: "ekembo24@gmail.com",
    },
  ]; */
  //messagePatners.push(props?.currPartner)
  const { globalUser } = useContext(GlobalUserContext);
  const location = useLocation();
  const navigation = useNavigate();

  const [backdrop, setBackdrop] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  
  
  const [useRawPartners, setUSeRawPArtners] = useState([]);

  const innerWidth = useRef(null);
  const [visitorImage, setVisitorImage] = useState();
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 1000);
  };

  ////console.log(props?.UseMessagePartners);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    const checkResize = () => {
      /* if (window.innerWidth === innerWidth.current) {
        return;
      } */
      innerWidth.current = window.innerWidth;

      if (window.innerWidth < 1000) {
        ////console.log("Was here")
        props?.setBasicOpen(false);
        setBackdrop(true);
      } else {
        props?.setBasicOpen(true);
        setBackdrop(false);
      }
    };
    checkResize();

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    ////console.log(globalUser?.user?.email, StorageBucket);
    async function fetchBackground() {
      try {
        const response2 = await fetch?.(
          `https://storage.googleapis.com/${StorageBucket}/${globalUser?.user?.email}_profile`
        ).catch((error) => {
          "";
        });
        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }
        const blob2 = await response2.blob();
        const base64data2 = await blob2.text(); // Convert the blob to a string
        setOwnerImage(base64data2);
        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }

      try {
        const res = await axios
          .post(
            `${API_URL}/getMessagesPartners`,
            JSON.stringify({
              owner: globalUser?.user?.email,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch((error) => {
            "";
          });
        //console.log("loaded fetchdata", res.data);
        const fix_data = res.data;
        ////console.log("loaded fetchdata", fix_data?.status);

        if (fix_data && fix_data.status === "success") {
          let dataMain = fix_data?.data?.data || [];
          //console.log(props?.currPartner);
          let found = false;
          if (props?.currPartner) {
            for (let index = 0; index < dataMain?.length; index++) {
              const element = dataMain[index];
              if (element?.email === props?.currPartner?.email) {
                found = true;
              } else if (Object.keys(element).length === 0) {
                dataMain.splice(index, 1);
                index--; // decrement the index as we have modified the array
              } else {
                ("do nothing");
              }
            }
            if (!found) {
              dataMain.push(props?.currPartner);
            }
          }
          const arrayWithIndex = dataMain?.map((value, index) => ({
            value,
            index,
          }));
          ////console.log(arrayWithIndex);

          const sortedArrayWithIndex = arrayWithIndex.sort(
            (a, b) => b.value?.time - a.value?.time
          );
          props?.setUseVisitor(sortedArrayWithIndex[0]?.value);
          props?.setUseMessagePartners(arrayWithIndex);
          setUSeRawPArtners(() => dataMain);

          //console.log(fix_data?.data);
        } else {
          let dataMain = [];
          //console.log(props?.currPartner);
          if (props?.currPartner) {
            dataMain.push(props?.currPartner);
            const arrayWithIndex = dataMain?.map((value, index) => ({
              value,
              index,
            }));
            ////console.log(arrayWithIndex);

            const sortedArrayWithIndex = arrayWithIndex.sort(
              (a, b) => b.value?.time - a.value?.time
            );
            props?.setUseVisitor(sortedArrayWithIndex[0]?.value);
            props?.setUseMessagePartners(arrayWithIndex);
            setUSeRawPArtners(() => dataMain);
          } else {
            ("do absoluthly nathing");
          }
        }
      } catch (error) {
        //console.log(error);
        Errorlog(error, "error loading chats", "from message stats");
      }
    }
    fetchBackground();
  }, []);

  function handleTouch(event) {
    event.preventDefault();
    event.stopPropagation();
  }
  ////console.log(globalUser);

  const RenderImageIcon = (props) => {
    const [useImage, setUSeImage] = useState();
    useEffect(() => {
      async function fetchprofile() {
        try {
          const response2 = await fetch?.(
            `https://storage.googleapis.com/${StorageBucket}/${props?.item.value?.email}_profile`
          ).catch((error) => {
            "";
          });
          if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
          }
          const blob2 = await response2.blob();
          const base64data2 = await blob2.text(); // Convert the blob to a string
          setUSeImage(base64data2);
          ////console.log("finalValue");
        } catch (error) {
          //console.error(error);
        }
      }
      fetchprofile();
    }, []);
    ////console.log(props?.visitor)
    return (
      <MDBSideNavLink key={props?.index} style={{ textDecoration: "none" }}>
        <div
          className={`center_div_distributed ${
            props?.item?.value?.viewed ||
            props?.item?.value?.email === props?.visitor?.email
              ? null
              : "bold"
          }`}
        >
          <img
            src={useImage ?? care}
            className="rounded-circle me-2"
            style={{ width: "30px", height: "30px" }}
            alt={props?.item.value?.name}
          />
          <div className="allpad me-2">{props?.item.value?.name}</div>
          <div
            className={` right_div d-flex ${
              props?.item?.value?.viewed ||
              props?.item?.value?.email === props?.visitor?.email
                ? "smallfainttext"
                : "smallboldtext"
            }`}
          >
            {convertUnixToTime(props?.item?.value?.time, "time")}
          </div>
          {!props?.item?.value?.viewed && (
            <div className="allpad me-2">
              <GoDotFill style={{ color: "#05dae9" }} />{" "}
            </div>
          )}
        </div>
      </MDBSideNavLink>
    );
  };

  useEffect(() => {
    if (!props?.UseMessagePartners) {
      return null;
    }
    const event = globalUser?.user?.email;

    props.socketInstance.on(event, (data) => {
      ////console.log("got the partner messages", data);
      handleUpdate(data);
      //setUseChat([...useChat, data?.chatMessage]);
    });

    return () => {
      props?.socketInstance?.off(event);
    };
  }, [globalUser?.user?.email, props?.socketInstance]);
  ////console.log(props?.trigerNew?.status)
  const handleUpdate = (data) => {
    let current_date = new Date().toISOString().slice(0, 10);
    props?.setTrigerNew((prev) => {
      let prevData = { ...prev };
      if (!data?.updateChat?.viewed) {
        //console.log(data)
        prevData.count++;
        prevData.status = true;
      } else if (prevData.count > 0) {
        prevData.count=0;
        prevData.status = false;
      }
      
      return prevData;
    });


    let oldChat = [...props?.UseMessagePartners];
    /* //console.log(
      "signal pushed to frontend for partners",
      data?.updateChat,
      String(current_date),
      props?.UseMessagePartners
    ); */
    let updat = false;
    if (props?.UseMessagePartners.length === 0) {
      //console.log("usePartners iis empty");
      let newmsgpartner = [data?.updateChat];
      const arrayWithIndex = newmsgpartner?.map((value, index) => ({
        value,
        index,
      }));
      ////console.log(arrayWithIndex);

      const sortedArrayWithIndex = arrayWithIndex.sort(
        (a, b) => b.value?.time - a.value?.time
      );
      
      props?.setUseMessagePartners((prev) => {
        let updated = [...prev];
        if (prev?.length === 0) {
          let updatedChat = [...new Set(updated.concat(arrayWithIndex))];
          for (let index = 0; index < updatedChat.length; index++) {
            const element = updatedChat[index];
            if (
              element?.value?.email === updatedChat[index + 1]?.value?.email
            ) {
              updatedChat.splice(index + 1, 1);
              index--;
            }
          }
          return updatedChat;
        } else {
          let emails = [];
          let values_data = [];
          for (let index = 0; index < updated?.length; index++) {
            if (
              updated?.[index]?.["value"]?.["email"] === data?.updateChat?.email
            ) {
              values_data.push(data?.updateChat);
              emails.push[updated?.[index]?.["value"]?.["email"]];
            } else {
              values_data.push(updated?.[index]?.["value"]);
              emails.push[updated?.[index]?.["value"]?.["email"]];
            }

            if (!emails.includes(data?.updateChat?.email)) {
              values_data.push(data?.updateChat);
            }
          }
          const newvalues = [...new Set(values_data)];
          const arrayWithIndex = newvalues?.map((value, index) => ({
            value,
            index,
          }));
          const sortedArrayWithIndex = arrayWithIndex.sort(
            (a, b) => b.value?.time - a.value?.time
          );

          return sortedArrayWithIndex;
        }
      });
      /* if (props?.UseMessagePartners?.length < 1) {
          //console.log("updated");
          props?.setUseVisitor(sortedArrayWithIndex[0]?.value);
        } */
    } else {
      props?.setUseMessagePartners((prev) => {
        const updated = [...prev];
        let emails = [];
        let values_data = [];
        for (let index = 0; index < updated?.length; index++) {
          if (
            updated?.[index]?.["value"]?.["email"] === data?.updateChat?.email
          ) {
            values_data.push(data?.updateChat);
            emails.push[updated?.[index]?.["value"]?.["email"]];
          } else {
            values_data.push(updated?.[index]?.["value"]);
            emails.push[updated?.[index]?.["value"]?.["email"]];
          }

          if (!emails.includes(data?.updateChat?.email)) {
            values_data.push(data?.updateChat);
          }
        }
        const newvalues = [...new Set(values_data)];
        const arrayWithIndex = newvalues?.map((value, index) => ({
          value,
          index,
        }));
        const sortedArrayWithIndex = arrayWithIndex.sort(
          (a, b) => b.value?.time - a.value?.time
        );

        return sortedArrayWithIndex;
      });
    }

    /* if (current_date in oldChat) {
      oldChat[current_date].push(data?.chatMessage);
      setUseChat(oldChat);
    } else {
      oldChat[current_date] = [data?.chatMessage];
      setUseChat(oldChat);
    } */
  };
  const callFunc = async () => {
    const tipstarDetail = await axios
      .post(`${API_URL}/gettipstarInfo`, {
        email: globalUser?.user?.email,
        action: "get",
      })
      .catch((error) => {
        //throw new Error('Server is unavailable, please try again later');
      });
    ////console.log(tipstarDetail);
    if (tipstarDetail?.data?.status == "success") {
      ////console.log("got it", tipstarDetail);
      return tipstarDetail?.data?.data;
    }
  };

  const handlePartnerClick = async (visitor) => {
    const formTipstar = await callFunc();
    const currTime = Math.floor(new Date().getTime() / 1000);
    const ownerName = formTipstar?.tipstarName ?? globalUser?.user?.firstname;
    const ownerMessahe = {
      time: currTime,
      chatMessage: "",
      name: ownerName ?? "null",
      email: globalUser?.user?.email ?? "null",
    };

    try {
      props.socketInstance.emit("partnerViews", {
        owner: ownerMessahe,
        visitor: visitor,
        time: currTime,
      });
      //setTrigerRead(() => !triggerRead);
    } catch {
      //console.log("error while pinging backend");
    }
  };

  return (
    <section className="inner-sidenav overflow-scroll noscrolbar">
      <MDBSideNav
        absolute
        open={props?.basicOpen}
        backdrop={backdrop}
        getOpenState={(e) => props?.setBasicOpen(e)}
        style={{ zIndex: isSmallScreen ? 1000 : 888 }}
      >
        <div className="p-3">
          <input
            type="search"
            className="form-control rounded"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
          />
        </div>

        <MDBSideNavMenu>
          {props?.UseMessagePartners?.sort(
            (a, b) => b.value?.time - a.value?.time
          )?.map((item, index) => (
            <MDBSideNavItem
              key={index}
              onClick={() => {
                props?.setUseVisitor(item.value);
                props?.setUSeChatIndex(index);
                handlePartnerClick(item.value);
              }}
              onTouchEnd={() => handleTouch}
            >
              {item?.value?.time && (
                <RenderImageIcon
                  index={index}
                  item={item}
                  visitor={props?.visitor}
                />
              )}
            </MDBSideNavItem>
          ))}
        </MDBSideNavMenu>
      </MDBSideNav>
    </section>
  );
}
